import {FETCH_DUMMYWIDGET_DETAILS, FETCH_KPI_SUMMARY} from './DummyWidgetAction'

interface ALPCounter {
	label: string
	color: string
	count: number
}
export interface ALPSummary {
	booked: ALPCounter
	counter: ALPCounter
	failed: ALPCounter
}
export interface dummyWidget {
	loading: boolean
	widgetData: any
	widget: any
	alpSummary: ALPSummary
	errorMsg: string
}

const initialState: dummyWidget = {
	loading: false,
	widgetData: {},
	widget: [],
	alpSummary: {
		booked: {label: 'Booked Loans', color: 'green', count: 0},
		counter: {label: 'Pending Negotiations', color: 'yellow', count: 0},
		failed: {label: 'Failed Requests', color: 'red', count: 0},
	},
	errorMsg: '',
}

export function dummyWidgetReducer(state = initialState, action: any) {
	let errorMsg = 'Unexpected error occurred. Please try again later'
	switch (action.type) {
		case FETCH_DUMMYWIDGET_DETAILS.triggered:
			return {...state, loading: true}
		case FETCH_DUMMYWIDGET_DETAILS.succeeded:
			return {
				...state,
				loading: false,

				widgetData: {
					...state.widgetData,
					widget: action.response.data.widget,
				},
				errorMsg: '',
			}
		case FETCH_DUMMYWIDGET_DETAILS.failed:
			return {...state, errorMsg, loading: false}

		case FETCH_KPI_SUMMARY.triggered:
			return {...state, loading: true}
		case FETCH_KPI_SUMMARY.succeeded:
			const alpSummaryRes = action.response.data
			let alpSummary = state.alpSummary

			if (!!alpSummaryRes) {
				alpSummary.booked.count = alpSummaryRes.booked
				alpSummary.counter.count = alpSummaryRes.counter
				alpSummary.failed.count = alpSummaryRes.failed
			}
			return {
				...state,
				loading: false,
				alpSummary,
				errorMsg: '',
			}
		case FETCH_KPI_SUMMARY.failed:
			return {...state, errorMsg, loading: false}
	}
	return state
}
