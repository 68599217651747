import gql from 'graphql-tag'

export const getAllFilesQuery = gql`
	query {
		assetValues {
			uid
			originalFileName
			isError
			errorList
			entries {
				date
				securityId
				value
				uid
				isError
			}
		}
	}
`
