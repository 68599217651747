import {
	GER_SELECTED_TRADE_DATA,
	ON_QTY_BLUR,
	ON_QTY_FOCUS,
	ON_TRADE_QTY_UPDATE,
	TOGGLE_NEGOTIATION_MODAL,
	TOGGLE_NEGOTIATION_TRADE,
} from './NegotiationModalActions'
import {v4 as uuidv4} from 'uuid'
import Lodash from 'lodash'

import {onTradeQtyUpdate} from './NegotiationModalHelper'

export interface AlpNegotiation {
	showNegotiationModal: boolean
	selectedTradeData: any
	activityGridData: any
	isNegotiation: boolean
}

const initialState: AlpNegotiation = {
	showNegotiationModal: false,
	isNegotiation: false,
	selectedTradeData: null,
	activityGridData: null,
}

export function ALPNegotiationModalReducer(state = initialState, action: any) {
	switch (action.type) {
		case TOGGLE_NEGOTIATION_MODAL:
			return {
				...state,
				showNegotiationModal: !state.showNegotiationModal,
			}

		case TOGGLE_NEGOTIATION_TRADE:
			const {isNegotiation, activityGridData, selectedTradeData} = state
			const activityGridDataCopy = Lodash.cloneDeep(activityGridData)
			activityGridDataCopy[0].entireRowReadOnly = isNegotiation

			if (isNegotiation && activityGridDataCopy.length >= 2) {
				const {maxReqQty, minReqQty, qtyIncrement, requestedQty, bookedQty} = activityGridDataCopy[1]
				let firstRow = {
					...activityGridDataCopy[0],
					rate: (selectedTradeData && selectedTradeData.counterRate) || null,
				}
				const isPartial = activityGridDataCopy.some(a => a.isPartial)
				if (isPartial) {
					firstRow = {
						...firstRow,
						maxReqQty,
						minReqQty,
						qtyIncrement,
						shortfall: maxReqQty - (bookedQty || 0),
					}
				} else {
					firstRow = {
						...firstRow,
						requestedQty,
						shortfall: requestedQty - (bookedQty || 0),
					}
				}
				activityGridDataCopy[0] = firstRow
			}
			return {
				...state,
				isNegotiation: !isNegotiation,
				activityGridData: activityGridDataCopy,
			}
		case ON_TRADE_QTY_UPDATE:
			return {
				...state,
				activityGridData: onTradeQtyUpdate('UPDATE', state.activityGridData, action.payload),
			}
		case ON_QTY_BLUR:
			return {
				...state,
				activityGridData: onTradeQtyUpdate('TRIGGER', state.activityGridData, action.payload),
			}
		case ON_QTY_FOCUS:
			return {
				...state,
				activityGridData: onTradeQtyUpdate('FOCUS', state.activityGridData, action.payload),
			}

		case GER_SELECTED_TRADE_DATA.triggered:
			return {...state, selectedTradeData: null, isLoading: true}
		case GER_SELECTED_TRADE_DATA.failed:
			return {...state, isLoading: false}
		case GER_SELECTED_TRADE_DATA.succeeded:
			const tradeData = action.response && action.response.data
			// tradeData.status = 'COUNTER'
			// tradeData.counterInventoryRefId = 341341

			const counterRate = (tradeData && tradeData.counterRate) || null
			const counterInventoryRefId = tradeData && tradeData.counterInventoryRefId
			const counterReclaimRate = (tradeData && tradeData.counterReclaimRate) || null
			const counterQty = (tradeData && tradeData.counterQty) || null
			let activities = tradeData.activities.map(a => {
				return {
					...a,
					rate: a.rate || null,
					offerRate: a.offerRate || null,
					reclaimRate: a.divRate || null,
					availQty: a.availQty || null,
					index: uuidv4(),
					entireRowReadOnly: true,
					shortfall: a.isPartial ? (a.maxReqQty || 0) - (a.bookedQty || 0) : (a.requestedQty || 0) - (a.bookedQty || 0),
					maxReqQty: a.maxReqQty,
					requestedQty: a.requestedQty,
					status: a.status === 'COUNTER' ? 'COUNTER_RECEIVED' : a.status,

					// status: 'COUNTER_RECEIVED',
				}
			})
			if (tradeData.status === 'COUNTER' && counterInventoryRefId !== null) {
				activities.unshift(activities[0])
				const {isPartial, maxReqQty, bookedQty, requestedQty} = activities[0]
				activities[0] = {
					...activities[0],
					entireRowReadOnly: true,
					index: uuidv4(),
					rate: counterRate,
					offerRate: counterRate,
					reclaimRate: counterReclaimRate,
					status: 'COUNTER',
					inventoryRefId: counterInventoryRefId,
					availQty: counterQty,
					requestId: '',
					modifiedDate: '',
					maxReqQty: Math.min(counterQty, maxReqQty),
					requestedQty: Math.min(counterQty, requestedQty),
					shortfall: isPartial ? (maxReqQty || 0) - (bookedQty || 0) : (requestedQty || 0) - (bookedQty || 0),
				}
			}
			return {
				...state,
				showNegotiationModal: true,
				isNegotiation: false,
				selectedTradeData: tradeData,
				activityGridData: activities,
				isLoading: false,
			}
	}
	return state
}
