import {getLocateClient} from '../../..'
import {createFetchActions} from '../../../services/createActions'
import {fetchLocateDetailsQuery, getLocateRequestEntryDetail} from './LocateRequesterQueries'
import {post} from '../../../services/createAPICall'
import {getLocateBaseUrl} from '../../../services/apiService'

export const FETCH_LOCATE_REQUESTER_DETAILS = createFetchActions('FETCH_LOCATE_REQUESTER_DETAILS')
export const FETCH_LOCATE_REQUEST_ENTRY_DETAIL = createFetchActions('FETCH_LOCATE_REQUEST_ENTRY_DETAIL')
export const SAVE_LOCATE_REQUEST = createFetchActions('SAVE_LOCATE_REQUEST')
export const TRIGGER_LOCATE_ACTION = createFetchActions('TRIGGER_LOCATE_ACTION')
export const REJECT_LOCATE_REQUESTER = createFetchActions('REJECT_LOCATE_REQUESTER')
export const CLEAR_MAIN_GRID_DATA = createFetchActions('CLEAR_MAIN_GRID_DATA')

export const fetchLocateRequesterDetails = () => {
	return function (dispatch) {
		dispatch({
			type: FETCH_LOCATE_REQUESTER_DETAILS.triggered,
		})
		getLocateClient()
			.query({
				query: fetchLocateDetailsQuery(),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: FETCH_LOCATE_REQUESTER_DETAILS.succeeded,
					response,
				})
			})
	}
}

export const saveLocateRequest = (data: any) => (dispatch: any) => {
	return dispatch(
		post(
			SAVE_LOCATE_REQUEST,
			`${getLocateBaseUrl()}/v1/locate/requester/create`,
			data,
			null,
			'Error occured while setting locate data into queue'
		)
	)
}

export const fetchLocateRequestEntryDetail = locateRequestEntryId => {
	return function (dispatch) {
		dispatch({
			type: FETCH_LOCATE_REQUEST_ENTRY_DETAIL.triggered,
		})
		getLocateClient()
			.query({
				query: getLocateRequestEntryDetail(locateRequestEntryId),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: FETCH_LOCATE_REQUEST_ENTRY_DETAIL.succeeded,
					response,
				})
			})
	}
}

export const triggerLocateAction = requestBody => (dispatch: any) => {
	return dispatch(post(TRIGGER_LOCATE_ACTION, `${getLocateBaseUrl()}/v1/locate/action`, requestBody))
}

export const clearGridData = () => {
	return {
		type: CLEAR_MAIN_GRID_DATA,
	}
}
