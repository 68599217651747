import {get} from '../../services/createAPICall'
import {getIAMBaseUrl} from '../../services/apiService'
import {createFetchActions} from '../../services/createActions'

export const VALIDATE_TOKEN = createFetchActions('VALIDATE_TOKEN')

export const validateResetToken = (resetToken: string, errorMessage: string) => (dispatch: any) => {
	return dispatch(
		get(VALIDATE_TOKEN, `${getIAMBaseUrl()}/auth/verify-token?resetToken=${resetToken}`, null, null, errorMessage)
	)
}
