import {getGatewayBaseUrl} from '../../services/apiService'
import {createFetchActions} from '../../services/createActions'
import {get} from '../../services/createAPICall'

export const START_LOADING = createFetchActions('START_LOADING')
export const STOP_LOADING = createFetchActions('STOP_LOADING')
export const RESET_TIME_CHANGE_PASSWORD = createFetchActions('RESET_TIME_CHANGE_PASSWORD')

export const startLoading = () => {
	return {
		type: START_LOADING,
	}
}

export const stopLoading = () => {
	return {
		type: STOP_LOADING,
	}
}

export const changePasswordResetTime = () => (dispatch: any) => {
	return dispatch(get(RESET_TIME_CHANGE_PASSWORD, `${getGatewayBaseUrl()}/auth/resettime`))
}
