import memoizeOne from 'memoize-one'
import isDeepEqual from 'lodash.isequal'
import {v4 as uuidv4} from 'uuid'
import Lodash from 'lodash'
import {IconBrodcast, IconRejectAuctions, IconSave, SentArrow} from '../../../../../styles/svg/svgIcons'
import React from 'react'
import {ArrayUtil, ObjectUtil} from 'helper-util'
import {ActionButtonType, AllowedDurationValue, AuctionMode} from './CreateEditAuctionConstant'
import {RejectBidsConfirmationMsg, ReleaseBidsConfirmationMsg} from './CreateEditAuctionConstant'
import {BpsToggleEligibleFields, BroadcastConfirmationMsg, MandatoryFields} from './CreateEditAuctionConstant'
import {CancelConfirmationMsg, RejectConfirmationMsg} from './CreateEditAuctionConstant'
import {InventorySourceLabel, InventorySourceType, QUEUE} from './CreateEditAuctionConstant'
import {formatWithSeparator} from '../../../../../utils/formatUtils'
import {globalColumnDefinition} from '../../../../../common/AgGrid/AgGridColumn'
import {commaNoDecimal, commaTwoDecimal, noCommaThreeDecimal} from '../../../../../common/AgGrid/AgGridHelper'
import {filterRightAlign} from '../InternalMainConstant'

const sortDropdownOptions = memoizeOne((options: any[], field: string) => {
	if (ArrayUtil.isEmpty(options) || !options) {
		return []
	}
	return options.sort((a, b) => {
		if (a[field] < b[field]) {
			return -1
		}
		if (a[field] > b[field]) {
			return 1
		}
		return 0
	})
}, isDeepEqual)

export const massagePreApprovedInventoryData = (data, details) => {
	return (data || []).positionData.flatMap(clientData => {
		return (clientData.positions || []).flatMap((fund, index) => {
			return (fund.collData || []).flatMap(collDataObj => {
				return (collDataObj.collCurr || []).map(collCurr => {
					return {
						securityName: details.securityName,
						ticker: details.ticker,
						cusip: details.cusip,
						isin: details.isin,
						sedol: details.sedol,
						securityType: details.securityType,
						client: clientData.clientName,
						clientId: clientData.clientId,
						fund: fund.fundName,
						fundId: fund.fundId,
						setlLoc: fund.setlLoc,
						marketCode: fund.marketCode,
						reclaimRate: fund.reclaimRate,
						lendableQty: fund.lendableQty,
						marketPrice: data.aggData.marketPrice,
						sscAvgFee: data.aggData.sscAvgFee,
						sscUtilization: data.aggData.sscUtilization,
						holders:
							data.aggData &&
							data.aggData.holders &&
							Object.entries(data.aggData.holders)
								.flatMap(p => p.join(' - '))
								.join(', '),
						collType: collDataObj.collType,
						collCurr: collCurr,
					}
				})
			})
		})
	})
}

export const massageDiscretionaryInventoryData = (data, details) => {
	return data.setlLocLevel.flatMap(setLoc => {
		return setLoc.reclaimRateLevel.flatMap(reclaimRate => {
			return reclaimRate.collTypeLevel.flatMap(collType => {
				return collType.collCurrLevel.map(collCurr => {
					return {
						collCurr: collCurr.collCurr,
						lendableQty: collCurr.lendableQty,
						collType: collType.collType,
						reclaimRate: reclaimRate.reclaimRate || '0',
						setlLoc: setLoc.setlLoc,
						marketCode: setLoc.marketCode,

						securityName: details.securityName,
						ticker: details.ticker,
						cusip: details.cusip,
						isin: details.isin,
						sedol: details.sedol,
						securityType: details.securityType,
					}
				})
			})
		})
	})
}

export const extractClients = memoizeOne(availabilityResponse => {
	if (availabilityResponse && ArrayUtil.isEmpty(availabilityResponse.positionData)) {
		return []
	}
	return sortDropdownOptions(
		availabilityResponse.positionData.map(posData => {
			return {id: posData.clientId, label: posData.clientName, value: posData.clientId}
		}),
		'value'
	)
}, isDeepEqual)

export const extractFundsBasedOnClients = memoizeOne((availabilityResponse, clientId) => {
	if (availabilityResponse && ArrayUtil.isEmpty(availabilityResponse.positionData)) {
		return []
	}
	const clientsListForFunds = availabilityResponse.positionData.filter(pos => clientId === pos.clientId)
	const fundPositions = (clientsListForFunds || []).flatMap(fund => fund.positions)

	return sortDropdownOptions(
		Lodash.uniq(fundPositions.map(fund => fund.fundId)).map(fundId => {
			const selectedFundDetails = (fundPositions || []).find(fund => fund.fundId === fundId)
			return {id: fundId, label: selectedFundDetails ? selectedFundDetails.fundName : '', value: fundId}
		}),
		'value'
	)
}, isDeepEqual)

const filterClients = memoizeOne((availabilityResponse, clientId) => {
	if (availabilityResponse && ArrayUtil.isEmpty(availabilityResponse.positionData)) {
		return []
	}
	return availabilityResponse.positionData.filter(pos => clientId === pos.clientId)
}, isDeepEqual)

const filterFunds = memoizeOne((availabilityResponse, clientId, funds) => {
	const filteredClientsList = filterClients(availabilityResponse, clientId)
	return filteredClientsList
		? filteredClientsList.flatMap(fund => {
				return (fund.positions || []).filter(funDetail => (funds || []).includes(funDetail.fundId))
		  })
		: []
}, isDeepEqual)

export const extractSetlLocBasedOnFunds = memoizeOne(
	(availabilityResponse, selectedInventorySource, clientId, funds, inventoryData) => {
		const isDiscretionary = selectedInventorySource.name === InventorySourceType.Discretionary
		if (isDiscretionary) {
			if (ArrayUtil.isEmpty(inventoryData)) {
				return []
			}
			return Lodash.uniq((inventoryData || []).map(i => i.setlLoc)).map(setLoc => {
				return {
					key: uuidv4(),
					value: setLoc,
				}
			})
		}
		return sortDropdownOptions(
			Lodash.uniq(filterFunds(availabilityResponse, clientId, funds).flatMap(fund => fund.setlLoc)).map(setLoc => {
				return {
					key: uuidv4(),
					value: setLoc,
				}
			}),
			'value'
		)
	},
	isDeepEqual
)

export const extractReclaimRateBasedOnSetLoc = memoizeOne(
	(availabilityResponse, selectedInventorySource, clientId, funds, setLoc, inventoryData) => {
		const isDiscretionary = selectedInventorySource.name === InventorySourceType.Discretionary
		if (isDiscretionary) {
			if (ArrayUtil.isEmpty(inventoryData)) {
				return []
			}
			return Lodash.uniq((inventoryData || []).filter(d => d.setlLoc === setLoc).map(i => i.reclaimRate)).map(
				reclaimRate => {
					return {
						key: uuidv4(),
						value: reclaimRate,
					}
				}
			)
		}
		return sortDropdownOptions(
			Lodash.uniq(
				filterFunds(availabilityResponse, clientId, funds)
					.filter(fund => fund.setlLoc === setLoc)
					.flatMap(fund => fund.reclaimRate)
			).map(divReclaimRate => {
				return {
					key: uuidv4(),
					value: divReclaimRate,
				}
			}),
			'value'
		)
	},
	isDeepEqual
)
export const extractAvailabilityDetails = memoizeOne(
	(
		inventoryData,
		availabilityResponse,
		selectedInventorySource,
		clientId,
		funds,
		setLoc,
		reclaimRate,
		collType,
		collCurr
	) => {
		const isDiscretionary = selectedInventorySource.name === InventorySourceType.Discretionary
		if (isDiscretionary) {
			if (ArrayUtil.isEmpty(inventoryData)) {
				return []
			}
			return inventoryData.filter(
				d => d.setlLoc === setLoc && d.reclaimRate === reclaimRate && d.collType === collType && d.collCurr === collCurr
			)
		}
		return filterFunds(availabilityResponse, clientId, funds)
			.filter(fund => fund.setlLoc === setLoc)
			.filter(fund => fund.reclaimRate === reclaimRate)
	},
	isDeepEqual
)

export const extractCollType = memoizeOne(
	(availabilityResponse, selectedInventorySource, clientId, funds, setLoc, reclaimRate, inventoryData) => {
		const isDiscretionary = selectedInventorySource.name === InventorySourceType.Discretionary
		if (isDiscretionary) {
			if (ArrayUtil.isEmpty(inventoryData)) {
				return []
			}
			return Lodash.uniq(
				inventoryData.filter(d => d.setlLoc === setLoc && d.reclaimRate === reclaimRate).map(i => i.collType)
			).map(collType => {
				return {
					key: uuidv4(),
					value: collType,
				}
			})
		}

		return sortDropdownOptions(
			Lodash.uniq(
				filterFunds(availabilityResponse, clientId, funds)
					.filter(fund => fund.setlLoc === setLoc && fund.reclaimRate === reclaimRate)
					.flatMap(fund => fund.collData)
					.flatMap(i => i.collType)
			).map(collData => {
				return {
					key: uuidv4(),
					value: collData,
				}
			}),
			'value'
		)
	},
	isDeepEqual
)

export const extractCollCurrency = memoizeOne(
	(availabilityResponse, selectedInventorySource, clientId, funds, setLoc, reclaimRate, selCollType, inventoryData) => {
		const isDiscretionary = selectedInventorySource.name === InventorySourceType.Discretionary
		if (isDiscretionary) {
			if (ArrayUtil.isEmpty(inventoryData)) {
				return []
			}
			return Lodash.uniq(
				(inventoryData || [])
					.filter(d => d.setlLoc === setLoc && d.reclaimRate === reclaimRate && d.collType === selCollType)
					.map(i => i.collCurr)
			).map(collCurr => {
				return {
					key: uuidv4(),
					value: collCurr,
				}
			})
		}

		return sortDropdownOptions(
			Lodash.uniq(
				filterFunds(availabilityResponse, clientId, funds)
					.filter(fund => fund.setlLoc === setLoc && fund.reclaimRate === reclaimRate)
					.flatMap(fund => fund.collData)
					.filter(collData => (collData.collType || []).includes(selCollType))
					.flatMap(fund => fund.collCurr)
			).map(collCurr => {
				return {
					key: uuidv4(),
					value: collCurr,
				}
			}),
			'value'
		)
	},
	isDeepEqual
)

export const resetFields = (field?: string) => {
	let obj: any = {
		auctionQty: 0,
		auctionQtyPctg: 0,
	}
	if (field === 'CollCurrency') {
		obj['selectedCollCurr'] = ''
	} else if (field === 'CollType') {
		obj['selectedCollCurr'] = ''
		obj['selectedCollType'] = ''
	} else if (field === 'ReclaimRate') {
		obj['selectedCollCurr'] = ''
		obj['selectedCollType'] = ''
		obj['selectedReclaimRate'] = ''
	} else if (field === 'SetLoc') {
		obj['selectedCollCurr'] = ''
		obj['selectedCollType'] = ''
		obj['selectedReclaimRate'] = ''
		obj['selectedSetLoc'] = ''
	} else if (field === 'Fund') {
		obj['selectedCollCurr'] = ''
		obj['selectedCollType'] = ''
		obj['selectedReclaimRate'] = ''
		obj['selectedSetLoc'] = ''
		obj['selectedFunds'] = []
	} else if (field === 'Clients') {
		obj['selectedCollCurr'] = ''
		obj['selectedCollType'] = ''
		obj['selectedReclaimRate'] = ''
		obj['selectedSetLoc'] = ''
		obj['selectedFunds'] = []
		obj['selectedClient'] = {id: '', label: ''}
	} else {
		obj = {
			...obj,
			selectedSecurity: null,
			selectedInventorySource: {name: InventorySourceType.Discretionary, value: InventorySourceLabel.Discretionary},
			selectedClient: {id: '', label: ''},
			selectedFunds: [],
			selectedFundsLabel: '',
			selectedSetLoc: '',
			selectedReclaimRate: '',
			selectedCollType: '',
			selectedCollCurr: '',
			auctionQty: 0,
			auctionQtyPctg: 0,
			duration: 0,
			durationMin: 0,
			reservePrice: 0,
			bidIncrement: 1,
			isPartialAllowed: false,
			isTermAllowed: false,
			hideReserve: true,
			isBidAsAll: false,
			selectedBorrower: [],
			borrowerTypeSelectedId: null,
			selectedTradeDate: null,
			isSetTermDate: false,
			isBpsRadioSelected: false,
			isReadOnly: false,
			marketCode: null,
			lendableQty: 0,
			showEligibleOnly: false,
			showConfirmationModal: false,
			showLoadingforConfirmAction: false,
			consolidatedLendableQty: 0,
			confirmationMode: '',
			isTimerEnd: false,
		}
	}
	return obj
}

export const getSelectedBorrowerDetails = memoizeOne((allBorrowersList, selectedBorrower, borrowerTypeSelectedId) => {
	return (allBorrowersList || [])
		.filter(b => (borrowerTypeSelectedId === 'ALL' ? true : (selectedBorrower || []).includes(b.legalEntityExternalId)))
		.map(({legalEntityExternalId, tenantId, groupId, fullName}) => {
			return {
				legalEntityExternalId,
				tenantId,
				groupId,
				fullName,
			}
		})
}, isDeepEqual)

export const checkBpsAndConvert = (isBpsRadioSelected, value) => {
	let fieldValue = Number(value)
	if (isBpsRadioSelected) {
		fieldValue = fieldValue / 100
	}
	return Number(fieldValue.toFixed(3))
}

export const getCreationPayload = memoizeOne((state, availabilityReqId, allBorrowersList, status?) => {
	const {selectedSecurity, selectedInventorySource, selectedClient, selectedFunds, selectedSetLoc} = state
	const {selectedFundsLabel, isBpsRadioSelected} = state
	const {selectedReclaimRate, selectedCollType, selectedCollCurr, reservePrice, consolidatedLendableQty} = state
	const {bidIncrement, isPartialAllowed, hideReserve, isBidAsAll, isTermAllowed, auctionQty, isSetTermDate} = state
	const {duration, durationMin, selectedTradeDate, marketCode, selectedBorrower, borrowerTypeSelectedId} = state
	const isDiscretionary = selectedInventorySource.name === InventorySourceType.Discretionary

	return {
		availabilityReqId: availabilityReqId,
		status,
		borrowerSelection: borrowerTypeSelectedId,
		termDateSelection: !!isSetTermDate,
		clientName: isDiscretionary ? QUEUE : selectedClient.label,
		fundName: isDiscretionary ? QUEUE : selectedFundsLabel,
		borrowerDetails: getSelectedBorrowerDetails(allBorrowersList, selectedBorrower, borrowerTypeSelectedId),
		creationRequest: {
			securityId: selectedSecurity && selectedSecurity.secId,
			inventorySource: selectedInventorySource && selectedInventorySource.value,
			clientId: selectedClient.id,
			fundIds: selectedFunds,
			setlLoc: selectedSetLoc,
			reclaimRate: selectedReclaimRate,
			collType: selectedCollType,
			collCurr: selectedCollCurr,
			auctionType: 'Sealed-Bid',
			auctionQty: auctionQty,
			auctionDuration: `${duration}:${durationMin}:00`,
			reservePrice: checkBpsAndConvert(isBpsRadioSelected, reservePrice),
			bidIncrement: checkBpsAndConvert(isBpsRadioSelected, bidIncrement),
			allowPartial: isPartialAllowed ? 'Y' : 'N',
			hideReserve: hideReserve ? 'Y' : 'N',
			bidAsAllIn: isBidAsAll ? 'Y' : 'N',
			allowTerm: isTermAllowed ? 'Y' : 'N',
			termDate: selectedTradeDate,
			marketCode: Array.isArray(marketCode) ? marketCode[0].marketCode : marketCode || 'US',
			lendableQty: consolidatedLendableQty,
			legalEntityIds:
				borrowerTypeSelectedId === 'ALL'
					? (allBorrowersList || []).map(borrower => borrower.legalEntityExternalId)
					: selectedBorrower,
		},
	}
}, isDeepEqual)

export const getReleaseBidsPayload = memoizeOne((auctionReferenceId, allBidsInAuction) => {
	return {
		auctionReferenceId,
		auctionBids: allBidsInAuction.map(bid => bid.auctionBidId),
	}
}, isDeepEqual)

const getMandatoryFields = state => {
	const {borrowerTypeSelectedId, isSetTermDate, selectedInventorySource} = state

	let mandatoryFields = [...MandatoryFields]
	if (isSetTermDate) {
		mandatoryFields.push('selectedTradeDate')
	}
	if (selectedInventorySource.name === InventorySourceType.PreApprovedClients) {
		mandatoryFields.push(...['selectedClient', 'selectedFunds'])
	}
	if (borrowerTypeSelectedId === 'SELECTIVE') {
		mandatoryFields.push('selectedBorrower')
	}
	return mandatoryFields
}

export const disableStageAndBrodcast = memoizeOne((state, lendableQty) => {
	const {auctionQty, duration, durationMin} = state
	return (
		auctionQty > lendableQty ||
		lendableQty === 0 ||
		(duration === 0 ? durationMin > 59 || durationMin <= 0 : duration < 0) ||
		getMandatoryFields(state).some(field => {
			return (
				state[field] === null ||
				state[field] === undefined ||
				state[field] === 0 ||
				state[field] === '' ||
				(Array.isArray(state[field]) ? ArrayUtil.isEmpty(state[field]) : false)
			)
		})
	)
}, isDeepEqual)

export const getLeftSideActions = memoizeOne((auctionMode, onClickAction, isTimerEnd, isReadOnly): any[] => {
	return auctionMode === AuctionMode.CREATE
		? [
				{
					label: 'Clear Form',
					size: 'sm',
					variant: 'outline-danger',
					onClick: () => onClickAction('CLEAR'),
				},
		  ]
		: auctionMode === AuctionMode.LIVE
		? [
				{
					label: 'Cancel Auction',
					size: 'sm',
					variant: 'outline-danger',
					disabled: isTimerEnd,
					onClick: () => onClickAction(ActionButtonType.CANCEL),
				},
		  ]
		: [
				{
					label: 'Reject',
					size: 'sm',
					variant: 'outline-danger',
					disabled: !isReadOnly,
					onClick: () => onClickAction(ActionButtonType.REJECT),
				},
		  ]
}, isDeepEqual)

export const getRightSideActions = memoizeOne(
	(state, auctionMode, lendableQuantity, isReadOnly, isTimerEnd, selectedAuctionBidIds, onClickAction): any[] => {
		return auctionMode === AuctionMode.LIVE
			? [
					{
						label: 'Reject Bids',
						size: 'sm',
						variant: 'danger',
						className: 'mr-3 px-3',
						disabled: !isTimerEnd || ArrayUtil.isEmpty(selectedAuctionBidIds),
						icon: (
							<span className='pr-2'>
								<IconRejectAuctions />
							</span>
						),
						onClick: () => onClickAction('REJECT_BIDS'),
					},
					{
						label: 'Release Bids',
						size: 'sm',
						variant: 'success',
						className: 'px-3 py-2 auction-icon-btn',
						disabled: !isTimerEnd,
						icon: (
							<span className='pr-2'>
								<SentArrow />
							</span>
						),
						onClick: () => onClickAction('RELEASE_BIDS'),
					},
			  ]
			: [
					{
						label: auctionMode === AuctionMode.STAGED ? 'Save' : 'Stage',
						size: 'sm',
						variant: 'secondary',
						className: 'mr-3 px-4 py-2 auction-icon-btn',
						disabled: isReadOnly || disableStageAndBrodcast(state, lendableQuantity),
						icon: (
							<span className='pr-2'>
								<IconSave />
							</span>
						),
						onClick: () => onClickAction(ActionButtonType.STAGE, lendableQuantity),
					},
					{
						label: 'Broadcast',
						size: 'sm',
						variant: 'primary',
						className: 'px-3 py-1 auction-icon-btn',
						disabled: disableStageAndBrodcast(state, lendableQuantity),
						icon: (
							<span className='pr-2'>
								<IconBrodcast />
							</span>
						),
						onClick: () => onClickAction(ActionButtonType.BROADCAST, lendableQuantity),
					},
			  ]
	},
	isDeepEqual
)

export const toggleFieldsBetweenBpsAndPrcntg = memoizeOne(prevState => {
	const bpsAlteredFields = {}
	BpsToggleEligibleFields.forEach(field => {
		bpsAlteredFields[field] = !prevState.isBpsRadioSelected
			? Number(prevState[field] * 100)
			: Number(prevState[field] / 100)
	})
	return bpsAlteredFields
}, isDeepEqual)

export const addTimerDelay = memoizeOne((creationDateTimestamp, auctionDuration) => {
	const splittedDuration = auctionDuration && auctionDuration.split(':')
	const hrs = splittedDuration ? splittedDuration[0] : 0
	const mins = splittedDuration ? splittedDuration[1] : 0
	return creationDateTimestamp ? Number(creationDateTimestamp) + hrs * 3600 + mins * 60 : 0
}, isDeepEqual)

export const sortBoorwers = memoizeOne(allBorrowersList => {
	return sortDropdownOptions(
		(allBorrowersList || []).map(borrower => {
			return {
				id: borrower.legalEntityExternalId,
				label: borrower.shortName,
				value: borrower.legalEntityExternalId,
			}
		}),
		'label'
	)
}, isDeepEqual)

export const filterGrid = (state, props, discretionaryGridState, preApprovedGridState, colDef, isReadOnly = false) => {
	const {
		selectedInventorySource: {name},
		selectedSetLoc,
	} = state
	const {selectedClient, selectedReclaimRate, selectedCollType, selectedCollCurr, selectedFunds} = state
	if (name === InventorySourceType.Discretionary && !discretionaryGridState) {
		return false
	}
	if (name === InventorySourceType.PreApprovedClients && !preApprovedGridState) {
		return false
	}
	if (name === InventorySourceType.Discretionary) {
		const setLocFilterInstance = discretionaryGridState.getFilterInstance('setlLoc')
		const reclaimRateFilterInstance = discretionaryGridState.getFilterInstance('reclaimRate')
		const collTypeFilterInstance = discretionaryGridState.getFilterInstance('collType')
		const collCurrFilterInstance = discretionaryGridState.getFilterInstance('collCurr')
		if (isReadOnly) {
			setLocFilterInstance && setLocFilterInstance.setModel({values: [selectedSetLoc]})
			reclaimRateFilterInstance && reclaimRateFilterInstance.setModel({values: [selectedReclaimRate]})
			collTypeFilterInstance && collTypeFilterInstance.setModel({values: [selectedCollType]})
			collCurrFilterInstance && collCurrFilterInstance.setModel({values: [selectedCollCurr]})
		} else {
			switch (colDef) {
				case 'setlLoc':
					setLocFilterInstance && setLocFilterInstance.setModel({values: [selectedSetLoc]})
					reclaimRateFilterInstance && reclaimRateFilterInstance.setModel(null)
					collTypeFilterInstance && collTypeFilterInstance.setModel(null)
					collCurrFilterInstance && collCurrFilterInstance.setModel(null)
					break
				case 'reclaimRate':
					reclaimRateFilterInstance && reclaimRateFilterInstance.setModel({values: [selectedReclaimRate]})
					collTypeFilterInstance && collTypeFilterInstance.setModel(null)
					collCurrFilterInstance && collCurrFilterInstance.setModel(null)
					break
				case 'collType':
					collTypeFilterInstance && collTypeFilterInstance.setModel({values: [selectedCollType]})
					collCurrFilterInstance && collCurrFilterInstance.setModel(null)
					break
				case 'collCurr':
					collCurrFilterInstance && collCurrFilterInstance.setModel({values: [selectedCollCurr]})
					break
			}
		}
		discretionaryGridState.onFilterChanged()
	} else {
		const {availabilityResponse} = props
		const funds = extractFundsBasedOnClients(availabilityResponse, selectedClient.id)
		const selectedFundLabels = (funds || []).filter(fund => selectedFunds.includes(fund.id)).map(fund => fund.label)
		const clientFilterInstance = preApprovedGridState.getFilterInstance('client')
		const fundFilterInstance = preApprovedGridState.getFilterInstance('fund')
		const settleLocFilterInstance = preApprovedGridState.getFilterInstance('setlLoc')
		const dividentReclaimFilterInstance = preApprovedGridState.getFilterInstance('reclaimRate')
		const colTypeFilterInstance = preApprovedGridState.getFilterInstance('collType')
		const currenciesFilterInstance = preApprovedGridState.getFilterInstance('collCurr')
		if (isReadOnly) {
			clientFilterInstance && clientFilterInstance.setModel({values: [selectedClient.label]})
			fundFilterInstance && fundFilterInstance.setModel({values: [...selectedFundLabels]})
			settleLocFilterInstance && settleLocFilterInstance.setModel({values: [selectedSetLoc]})
			dividentReclaimFilterInstance && dividentReclaimFilterInstance.setModel({values: [selectedReclaimRate]})
			colTypeFilterInstance && colTypeFilterInstance.setModel({values: [selectedCollType]})
			currenciesFilterInstance && currenciesFilterInstance.setModel({values: [selectedCollCurr]})
		} else {
			switch (colDef) {
				case 'client':
					clientFilterInstance && clientFilterInstance.setModel({values: [selectedClient.label]})
					fundFilterInstance && fundFilterInstance.setModel(null)
					settleLocFilterInstance && settleLocFilterInstance.setModel(null)
					dividentReclaimFilterInstance && dividentReclaimFilterInstance.setModel(null)
					colTypeFilterInstance && colTypeFilterInstance.setModel(null)
					currenciesFilterInstance && currenciesFilterInstance.setModel(null)
					break
				case 'fund':
					fundFilterInstance && fundFilterInstance.setModel({values: [...selectedFundLabels]})
					settleLocFilterInstance && settleLocFilterInstance.setModel(null)
					dividentReclaimFilterInstance && dividentReclaimFilterInstance.setModel(null)
					colTypeFilterInstance && colTypeFilterInstance.setModel(null)
					currenciesFilterInstance && currenciesFilterInstance.setModel(null)
					break
				case 'setlLoc':
					settleLocFilterInstance && settleLocFilterInstance.setModel({values: [selectedSetLoc]})
					dividentReclaimFilterInstance && dividentReclaimFilterInstance.setModel(null)
					colTypeFilterInstance && colTypeFilterInstance.setModel(null)
					currenciesFilterInstance && currenciesFilterInstance.setModel(null)
					break
				case 'reclaimRate':
					dividentReclaimFilterInstance && dividentReclaimFilterInstance.setModel({values: [selectedReclaimRate]})
					colTypeFilterInstance && colTypeFilterInstance.setModel(null)
					currenciesFilterInstance && currenciesFilterInstance.setModel(null)
					break
				case 'collType':
					colTypeFilterInstance && colTypeFilterInstance.setModel({values: [selectedCollType]})
					currenciesFilterInstance && currenciesFilterInstance.setModel(null)
					break
				case 'collCurr':
					currenciesFilterInstance && currenciesFilterInstance.setModel({values: [selectedCollCurr]})
					break
			}
		}
		preApprovedGridState.onFilterChanged()
	}
}

export const getConfirmationMsgAndAction = memoizeOne(
	(confirmationMode, onCancel, onReject, onBrodcast, onRejectBids, onReleaseBids) => {
		const modeMapper = {
			CANCEL: {
				msg: CancelConfirmationMsg,
				action: onCancel,
			},
			REJECT: {
				msg: RejectConfirmationMsg,
				action: onReject,
			},
			BROADCAST: {
				msg: BroadcastConfirmationMsg,
				action: onBrodcast,
			},
			REJECT_BIDS: {
				msg: RejectBidsConfirmationMsg,
				action: onRejectBids,
			},
			RELEASE_BIDS: {
				msg: ReleaseBidsConfirmationMsg,
				action: onReleaseBids,
			},
		}
		return modeMapper[confirmationMode] || {}
	},
	isDeepEqual
)

const isValid = value => {
	return value !== null && value !== undefined
}

export const getBannerBoxLabel = memoizeOne((selectedSecurity, availabilityResponse) => {
	if (ObjectUtil.isEmpty(selectedSecurity)) {
		return ''
	}
	const {desc1, secId, tradeCountryCode, securityType, maturityDate, coupon} = selectedSecurity
	let label = `${desc1} | ${secId}`
	if (
		ObjectUtil.isEmpty(availabilityResponse) ||
		(availabilityResponse && ObjectUtil.isEmpty(availabilityResponse.aggData))
	) {
		return label
	}
	const {holders, marketPrice, sscUtilization, sscAvgFee} = availabilityResponse.aggData

	if (isValid(tradeCountryCode)) {
		label += ` | ${tradeCountryCode}`
	}
	if (isValid(securityType)) {
		label += ` ${!isValid(tradeCountryCode) ? '| ' : ''} ${securityType}`
	}

	if (isValid(maturityDate)) {
		label += ` | MATD: ${maturityDate}`
	}
	if (isValid(coupon)) {
		label += ` | CPN: ${coupon}`
	}

	if (isValid(marketPrice)) {
		label += ` | Px: $${formatWithSeparator(marketPrice)}`
	}
	if (isValid(sscUtilization)) {
		label += ` | Utilization: ${sscUtilization}%`
	}
	if (isValid(sscAvgFee)) {
		label += ` | VWAF: ${sscAvgFee}%`
	}
	if (isValid(holders)) {
		if (isValid(holders.clients)) {
			label += ` | Clients: ${holders.clients}`
		}
		if (isValid(holders.funds)) {
			label += ` | Funds: ${holders.funds}`
		}
	}
	return label
}, isDeepEqual)

export const validateDurationField = memoizeOne((value: number) => {
	return value < 6 ? value : AllowedDurationValue.includes(value) ? value : 0
}, isDeepEqual)

export const validateDurationFieldOnBlur = memoizeOne((value: number) => {
	return value < 5 ? 0 : value
}, isDeepEqual)

export const errorInventoryDataColDef = () => {
	return [
		Object.assign({}, {headerName: 'Auction ID', field: 'auctionReqId'}),
		Object.assign({}, {headerName: 'Security ID', field: 'securityId'}),
		Object.assign({}, {headerName: 'Status', field: 'status'}),
		Object.assign({}, {headerName: 'Reason Description', field: 'reasonDesc'}),
	]
}
export const preApprovedDataColumnDef = isErrorInInventoryData => {
	if (isErrorInInventoryData) {
		return errorInventoryDataColDef()
	}
	return [
		Object.assign(
			{},
			{
				headerName: 'Fund Id',
				field: 'fundId',
				cellRendererParams: {
					fields: [{key: '', value: 'fund'}],
					hideLabel: true,
				},
				cellRenderer: 'CustomTooltip',
			}
		),
		Object.assign({}, {headerName: 'Clearing Agent', field: 'setlLoc'}),
		Object.assign({}, {headerName: 'Dividend Rate', field: 'reclaimRate', ...noCommaThreeDecimal, ...filterRightAlign}),
		Object.assign({}, {headerName: 'Collateral Type', field: 'collType'}),
		Object.assign({}, {headerName: 'Collateral Currency', field: 'collCurr'}),
		Object.assign({}, {headerName: 'Lendable Qty', field: 'lendableQty', ...commaNoDecimal, ...filterRightAlign}),
		Object.assign({}, {headerName: 'Client Name', field: 'client', hide: true}),
		Object.assign({}, {headerName: 'Holders', field: 'holders', hide: true}),
		Object.assign({}, {headerName: 'Market', field: 'marketCode', hide: true}),
		Object.assign(
			{},
			{headerName: 'Market Price', field: 'marketPrice', ...commaTwoDecimal, ...filterRightAlign, hide: true}
		),
		Object.assign({}, {headerName: 'Program Utilization', field: 'sscUtilization', hide: true}),
		Object.assign({}, {headerName: 'Security Name', field: 'securityName', hide: true}),
		Object.assign({}, {headerName: 'Security Type', field: 'securityType', hide: true}),
		Object.assign({}, globalColumnDefinition.ticker, {hide: true}),
		Object.assign({}, {headerName: 'VWAF', field: 'sscAvgFee', hide: true}),
	]
}

export const bidsDataColumnDef = (isTimerEnd, allBorrowersList) => {
	const commonColDefs = [
		Object.assign({}, {headerName: 'Rank', field: 'rank'}),
		Object.assign({}, {headerName: 'Bid ID', field: 'auctionBidId'}),
		Object.assign(
			{},
			{
				headerName: 'Borrower',
				field: 'legalEntityId',
				cellRenderer: params => {
					const selectedBorrower = (allBorrowersList || []).find(
						b => b.legalEntityExternalId === params.data.legalEntityId
					)
					return selectedBorrower ? selectedBorrower.shortName : ''
				},
			}
		),
		Object.assign({}, {headerName: 'Bid Qty', field: 'requestQty', ...commaNoDecimal, ...filterRightAlign}),
		Object.assign({}, {headerName: 'Min. Qty', field: 'minQty', ...commaNoDecimal, ...filterRightAlign}),
		Object.assign({}, {headerName: 'Collateral Type', field: 'collType'}),
		Object.assign({}, {headerName: 'Collateral Currency', field: 'collCurr'}),
		Object.assign({}, {headerName: 'Bid Rate', field: 'bidRate', ...noCommaThreeDecimal, ...filterRightAlign}),
		Object.assign({}, {headerName: 'All In Rate', field: 'allInRate', ...noCommaThreeDecimal, ...filterRightAlign}),
		Object.assign(
			{},
			{
				headerName: 'Term',
				field: 'termDate',
				cellRenderer: params => {
					const {termSelection} = params && params.data
					return termSelection === 'OVERNIGHT' ? 'Overnight' : params.value
				},
			}
		),
		Object.assign({}, {headerName: 'Gross O/N Rev.', field: 'revenue', ...noCommaThreeDecimal, ...filterRightAlign}),
		Object.assign({}, {headerName: 'Bid Time', field: 'bidTime'}),
		Object.assign(
			{},
			{
				headerName: 'Eligibility',
				field: 'eligible',
				cellRenderer: params => {
					return params.value === 'Y' ? 'Eligible' : 'Ineligible'
				},
			}
		),
		Object.assign({}, {headerName: 'Ineligible Reason', field: 'reasonDesc'}),
		Object.assign({}, globalColumnDefinition.cusip, {hide: true}),
		Object.assign({}, globalColumnDefinition.isin, {hide: true}),
		Object.assign({}, globalColumnDefinition.sedol, {hide: true}),
		Object.assign({}, globalColumnDefinition.ticker, {hide: true}),
	]
	if (!isTimerEnd) {
		return commonColDefs
	}
	return [globalColumnDefinition.checkBoxField, ...commonColDefs]
}

export const discretionaryColumnDef = isErrorInInventoryData => {
	if (isErrorInInventoryData) {
		return errorInventoryDataColDef()
	}
	return [
		Object.assign({}, {headerName: 'Clearing Agent ', field: 'setlLoc'}),
		Object.assign({}, {headerName: 'Market Code', field: 'marketCode', hide: true}),
		Object.assign({}, {headerName: 'Dividend Rate', field: 'reclaimRate', ...noCommaThreeDecimal, ...filterRightAlign}),
		Object.assign({}, {headerName: 'Collateral Type', field: 'collType'}),
		Object.assign({}, {headerName: 'Collateral Currency', field: 'collCurr'}),
		Object.assign({}, {headerName: 'Lendable Qty', field: 'lendableQty', ...commaNoDecimal, ...filterRightAlign}),
		Object.assign({}, {headerName: 'Security Name', field: 'securityName', hide: true}),
		Object.assign({}, {headerName: 'Security Type', field: 'securityType', hide: true}),
		Object.assign({}, globalColumnDefinition.cusip, {hide: true}),
		Object.assign({}, globalColumnDefinition.isin, {hide: true}),
		Object.assign({}, globalColumnDefinition.sedol, {hide: true}),
		Object.assign({}, globalColumnDefinition.ticker, {hide: true}),
	]
}

export const getRowClassRules = memoizeOne(() => {
	return {
		winningOutline: params => {
			if (params.data) {
				const {winning, eligible} = params.data
				return winning === 'Y' && eligible === 'Y'
			}
			return false
		},
		lossingOutline: params => {
			if (params.data) {
				const {winning, eligible} = params.data
				return winning === 'N' && eligible === 'Y'
			}
			return false
		},
		ineligibleOutline: params => {
			if (params.data) {
				const {eligible} = params.data
				return eligible === 'N'
			}
			return false
		},
	}
}, isDeepEqual)

export const setSecurityDetails = memoizeOne(selectedAuctionsDetails => {
	const {ticker, cusip, isin, securityId, securityName, tradeCountryCode, securityType} = selectedAuctionsDetails
	const {maturityDate, coupon} = selectedAuctionsDetails
	return {
		cusip,
		maturityDate,
		coupon,
		sedol: '',
		isin,
		ticker,
		desc1: securityName,
		desc2: '',
		secId: securityId,
		securityType: securityType,
		tradeCountryCode: tradeCountryCode,
	}
}, isDeepEqual)
