import {GET_LCR_DATA} from './LCRActions'

export interface Lcr {
	lcrDataAndColumns: any
	lcrDataLoading: boolean
}

const initialState: Lcr = {
	lcrDataAndColumns: [],
	lcrDataLoading: false,
}

export function LCRReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_LCR_DATA.triggered:
			return {...state, lcrDataAndColumns: [], lcrDataLoading: true}
		case GET_LCR_DATA.failed:
			return {...state, lcrDataAndColumns: [], lcrDataLoading: false}
		case GET_LCR_DATA.succeeded:
			const lcrDataAndColumns = action.response.data
			return {
				...state,
				lcrDataAndColumns,
				lcrDataLoading: false,
			}
	}
	return state
}
