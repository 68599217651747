import {GET_TENANT_SUMMARY} from './accountSettingsHeaderActions'
import {TenantSummary} from './accountSettingsHeaderModel'

export interface TenantSummaryData {
	data: TenantSummary
	loading: boolean
}

const initialState: TenantSummaryData = {
	data: {} as TenantSummary,
	loading: false,
}

export function tenantSummaryReducer(state = initialState, action: any) {
	let data: TenantSummary
	switch (action.type) {
		case GET_TENANT_SUMMARY.triggered:
			return {...state, loading: true}
		case GET_TENANT_SUMMARY.succeeded:
			data = (action.response.data && action.response.data.summaryStats) || {}
			return {...state, loading: false, data}
		case GET_TENANT_SUMMARY.failed:
			return {...state, loading: false}
	}

	return state
}
