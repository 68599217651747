import gql from 'graphql-tag'

export const collateralBasketQuery = (basketId: string) => gql`
{
  collateralBasket(collateralBasketId: "${basketId}") {
    collateralBasketId
    collateralBasketType
    createdBy
    createdDate
    templateType
    schedule
    state
    name
    orderId
    funds
    index
    sector
    weighting{
      weight
      code
    }
    distinctSecurities
    isConstraintPool
    constraintMap{
      assetType
      value
    }
    minimumRating
    includeETF
    includeSpecialAndHTB
    includeDividendsPayingInNext10Days
    includeOverBorrows
    includeRestrictedSecurities
    collateralBasketPurpose
    strategy{
      strategy
      code
    }
    currency
    assetTypes
    notionalAmount
    targetAmount
    targetAmountField
    createdDate
    collateralBasketItems {
      assetMaturityDate
      collateralBasketItemId
      securityId
      classification
      ticker
      cusip
      isin
      inefficiencies {
        inefficiency
        abbr
        displayName
      }
      price
      priceMultiplier
      quantity
      availableQuantity
      feeRate
      rebateRate
      assetMaturityDate
      assetType
      cusipCode
      schedule
      scheduleDescription
      margin
      securityDescription
      fitchRating
      moodyRating
      dirtyPrice {
        amount
      }
      startCash {
        amount
      }
      repoInterest
      endCash {
        amount
      }
    }
  }
}
`
