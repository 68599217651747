import memoizeOne from 'memoize-one'
import isDeepEqual from 'lodash.isequal'
import {ArrayUtil} from 'helper-util'

import {MyLegalEntities} from './myLegalEntitiesModel'
import {globalColumnDefinition} from '../../../common/AgGrid/AgGridColumn'

export const myLegalEntityInitialise = (): MyLegalEntities => {
	return {
		businessSector: '',
		country: '',
		entityType: '',
		entitySubType: '',
		externalProviderRefId: '',
		fullName: '',
		id: null,
		legalEntityExternalId: '',
		legalEntityId: null,
		orgId: '',
		shortName: '',
		bbgGlobalIdentifier: '',
		internalCode1: '',
		internalCode2: '',
		internalCode3: '',
		internalCode4: '',
		internalCode5: '',
	}
}

export const isFormValidFn = myLegalEntities => {
	const {fullName, shortName, legalEntityExternalId, externalProviderRefId} = myLegalEntities
	return (
		fullName &&
		fullName.trim() &&
		shortName &&
		shortName.trim() &&
		legalEntityExternalId &&
		legalEntityExternalId.trim() &&
		externalProviderRefId &&
		externalProviderRefId.trim()
	)
}

export const getColumns = memoizeOne((isWritePermission, setFormData) => {
	return [
		Object.assign({}, globalColumnDefinition.fullName, {
			headerName: 'Legal Entity Name',
			onCellClicked: params => {
				isWritePermission && setFormData(params.data)
			},
			cellClass: [isWritePermission ? 'cursor-pointer text-link' : ''],
		}),
		globalColumnDefinition.shortName,
		globalColumnDefinition.legalEntityExternalId,
		globalColumnDefinition.externalProviderRefId,
		globalColumnDefinition.entityType,
		globalColumnDefinition.country,
		globalColumnDefinition.internalCode1,
		globalColumnDefinition.internalCode2,
		globalColumnDefinition.internalCode3,
		globalColumnDefinition.internalCode4,
		globalColumnDefinition.internalCode5,
		globalColumnDefinition.bbgGlobalIdentifier,
	]
}, isDeepEqual)

export const setLESelectedValue = (legalEntityDetail, myLegalEntitiesDetail) => {
	const myLegalEntities = JSON.parse(JSON.stringify(myLegalEntitiesDetail))
	const data = !ArrayUtil.isEmpty(legalEntityDetail.data) && legalEntityDetail.data[0]
	myLegalEntities.fullName = data && data.officialName ? data.officialName : ''
	myLegalEntities.legalEntityExternalId = data && data.entityLEI ? data.entityLEI : ''

	myLegalEntities.entityType = data && data.legalEntityTypeDescription ? data.legalEntityTypeDescription : ''
	myLegalEntities.orgId = data && data.legalEntityOrgID ? data.legalEntityOrgID : ''
	myLegalEntities.entitySubType = data && data.legalEntitySubTypeDescription ? data.legalEntitySubTypeDescription : ''
	myLegalEntities.businessSector =
		data && data.trbcBusinessSectorCodeDescription ? data.trbcBusinessSectorCodeDescription : ''

	myLegalEntities.country = data && data.domicileDescription ? data.domicileDescription : ''
	myLegalEntities.externalProviderRefId = data && data.identifier ? data.identifier : ''
	return myLegalEntities
}

export const modifiedLegalEntity = (legalEntity: any, selectedDetails?: any, skipSelect = true) => {
	!ArrayUtil.isEmpty(legalEntity.data) &&
		legalEntity.data.forEach(le => {
			if (!skipSelect && le.identifier === selectedDetails.identifier) {
				le.select = true
			} else {
				le.select = false
			}
		})
	return legalEntity
}
