import React from 'react'
import {ArrowCircleLeft, ArrowCircleRight} from '../../../styles/svg/svgIcons'

export const customPopoverIds = {
	abcmAnalytics: 'abcmAnalytics',
	abcPortfolio: 'abcPortfolio',
	avnCreateNewBasket: 'avnCreateNewBasket',
	box: 'box',
	collateralBasketPurpose: 'collateralBasketPurpose',
	collateralBasketStatus: 'collateralBasketStatus',
	collateralValue: 'collateralValue',
	dirtyPrice: 'dirtyPrice',
	firmCOF: 'firmCOF',
	heatmapTable: 'heatmapTable',
	inefficienciesConfig: 'inefficienciesConfig',
	inefficienciesDropdownViewall: 'inefficienciesDropdownViewall',
	inventoryManager: 'inventoryManager',
	locateRequestQueueStatus: 'locateRequestQueueStatus',
	matchingAlgorithm: 'matchingAlgorithm',
	nonCashHeatmap: 'nonCashHeatmap',
	ois: 'ois',
	orderEntryDuration: 'orderEntryDuration',
	orderEntryAllInRate: 'orderEntryAllInRate',
	portfolioManagerArrowRight: 'portfolioManagerArrowRight',
	portfolioManagerArrowLeft: 'portfolioManagerArrowLeft',
	repoHeatmap: 'repoHeatmap',
	restrictedStocklist: 'restrictedStocklist',
	sblHeatmap: 'sblHeatmap',
	simmCrif: 'simmCrif',
	sodCashflow: 'sodCashflow',
	tradingTicketDuration: 'tradingTicketDuration',
	tradingTicketNotional: 'tradingTicketNotional',
	tradingTicketPrice: 'tradingTicketPrice',
	indicationOfInterest: 'indicationOfInterest',
	requestForQuote: 'requestForQuote',
}

export const suggestionsData: any = {
	[customPopoverIds.abcmAnalytics]: [
		{
			header: 'ABCM Analytics',
			defination: 'Activity Based Collateral Modeling (ABCM) - Analytics',
		},
	],
	[customPopoverIds.abcPortfolio]: [
		{
			header: 'ABC Portfolio',
			defination: 'Activity Based Collateral (ABC) Modeling Portfolio',
		},
	],
	[customPopoverIds.collateralValue]: [
		{
			header: 'Collateral Value',
			defination: 'Market Value of the Collateral factoring for haircut',
		},
	],
	[customPopoverIds.dirtyPrice]: [
		{
			header: 'Dirty Price',
			defination: 'Price inclusive of haircut',
		},
	],
	[customPopoverIds.orderEntryAllInRate]: [
		{
			header: 'All in Rate',
			defination: 'All in rate will appear after filling benchmark and spread',
		},
	],
	[customPopoverIds.orderEntryDuration]: [
		{
			header: 'Duration',
			defination: 'Duration will appear after filling all the other dates',
		},
	],
	[customPopoverIds.avnCreateNewBasket]: [
		{
			header: 'AVN - Basket Criteria',
			defination: 'Target percentage chosen allocates ratio of entire needs or availabilities volume to created basket',
		},
	],
	[customPopoverIds.box]: [
		{
			header: 'Box',
			defination: 'Notional exposure of unencumbered assets in scope for availability and needs purposes',
		},
	],
	[customPopoverIds.collateralBasketPurpose]: [
		{
			header: 'Analysis',
			defination: 'Basket constituents/underlying are still available for other users.',
		},
		{
			header: 'Trading',
			defination: 'Basket constituents/underlying securities are locked on firm level for trading purpose',
		},
	],
	[customPopoverIds.collateralBasketStatus]: [
		{
			header: 'Basket Status - No Activity',
			defination:
				'Basket has been created without reference to a specific order, basket constituent have not been locked',
		},
		{
			header: 'Basket Status - Attached',
			defination: 'Basket has been attached to an order, basket constituents are locked',
		},
	],
	[customPopoverIds.firmCOF]: [
		{
			header: 'Firm COF',
			defination: 'manual input of Cost of Funds (CoF) rate',
		},
	],
	[customPopoverIds.heatmapTable]: [
		{
			header: 'Heatmap',
			defination: (
				<div>
					<p className='mb-0'>Heatmaps are derived from grouping criteria</p>
					<div>group 1 criteria determines box size (the greater the exposure, the larger the box)</div>
					<div>group 2 criteria determines color gradient (the greater the exposure, the darker the box)</div>
				</div>
			),
		},
	],
	[customPopoverIds.inefficienciesConfig]: [
		{
			header: 'RR',
			defination:
				'Re-Rate inefficiency derived from difference between Trade Rate and Market Rate being higher/lower than loan/borrow threshold (in bps)',
		},
		{
			header: 'PR',
			defination: 'Price inefficiency with Cost of Funds being lower than Source rate or higher than Use rate',
		},
		{header: 'CA', defination: 'Inefficiency based on upcoming corporate action'},
		{header: 'BS', defination: 'Balance Sheet inefficiency from Cash related transactions'},
		{
			header: 'SI',
			defination:
				'Stock loan inefficiency derived from pending returns/recalls to be considered for inefficient underlying borrows/loans',
		},
		{
			header: 'LC',
			defination: 'Liquidity Coverage Ratio inefficiency triggered by insufficient High Quality Liquid Assets (HQLA)',
		},
		{
			header: 'MT',
			defination: 'Trades maturing today',
		},
	],
	[customPopoverIds.inefficienciesDropdownViewall]: [
		{
			header: 'Viewall',
			defination: 'Displays all positions including securities without inefficiencies.',
		},
	],
	[customPopoverIds.inventoryManager]: [
		{
			header: 'Inventory Manager',
			defination: 'displays ABCM Analytics specifics for corresponding security in separate window',
		},
	],
	[customPopoverIds.locateRequestQueueStatus]: [
		{
			header: 'Open',
			defination: 'Located securities are not allocated to a specific transaction yet',
		},
		{
			header: 'In progress',
			defination: 'Located securities are locked and attached to a transaction',
		},
	],
	[customPopoverIds.matchingAlgorithm]: [
		{
			header: 'ClientRanking',
			defination: 'Matching is based on client ranking',
		},
		{
			header: 'FIFO',
			defination: 'Matching is based on time of arrival of the  availability file',
		},
		{
			header: 'MaxCpty',
			defination: 'Maximize all borrows from same counterparty',
		},
		{
			header: 'MinTCO',
			defination: 'Minimize ticket cost by doing full borrows (no order splits)',
		},
		{
			header: 'MaxYield',
			defination: 'Maximize yield',
		},
		{
			header: 'MinBalancesheet',
			defination: 'Minimize balance sheet by borrowing vs noncash',
		},
	],
	[customPopoverIds.nonCashHeatmap]: [
		{
			header: 'NonCash Heatmap',
			defination: 'Heatmap color gradient refers to Borrow exposure (red) vs Loan exposure (yellow)',
		},
	],
	[customPopoverIds.ois]: [
		{
			header: 'Repo Pricer - OIS',
			defination: 'Overnight indexed swap rate',
		},
	],
	[customPopoverIds.portfolioManagerArrowRight]: [
		{
			header: (
				<span>
					<ArrowCircleRight /> Forward Arrow
				</span>
			),
			defination: `moves files from 'available' into 'selected' box enabling consolidation`,
		},
	],
	[customPopoverIds.portfolioManagerArrowLeft]: [
		{
			header: (
				<span>
					<ArrowCircleLeft /> Backward Arrow
				</span>
			),
			defination: `removes 'selected' files back into 'available' box carving them out from consolidation `,
		},
	],
	[customPopoverIds.repoHeatmap]: [
		{
			header: 'Repo Heatmap',
			defination: 'Heatmap color gradient refers to Repo exposure (red) vs Reverse Repo exposure (yellow)',
		},
	],
	[customPopoverIds.restrictedStocklist]: [
		{
			header: 'Restricted Stock list',
			defination: 'Upon every update of restricted list portfolio files need to be consolidated again',
		},
	],
	[customPopoverIds.sblHeatmap]: [
		{
			header: 'SBL Heatmap',
			defination: 'Heatmap color gradient refers to Borrow exposure (red) vs Loan exposure (yellow)',
		},
	],
	[customPopoverIds.simmCrif]: [
		{
			header: 'CRIF File',
			defination:
				'Upload Common Risk Interchange Format file as ISDA SIMM standard format for risk sensitivities to standardize inputs to margin calculations',
		},
	],
	[customPopoverIds.sodCashflow]: [
		{
			header: 'SOD Cash',
			defination: 'manual input of Start of Day (SoD) Cash Position',
		},
	],
	////
	[customPopoverIds.tradingTicketDuration]: [
		{
			header: 'Duration',
			defination: 'Trading Duration',
		},
	],
	[customPopoverIds.tradingTicketNotional]: [
		{
			header: 'Notional',
			defination: 'Trading Ticket Notional',
		},
	],
	[customPopoverIds.tradingTicketPrice]: [
		{
			header: 'Price',
			defination: 'Trading Ticket Price',
		},
	],
	[customPopoverIds.indicationOfInterest]: [
		{
			header: 'IOI',
			defination: 'Indication of Interests',
		},
	],
	[customPopoverIds.requestForQuote]: [
		{
			header: 'RFQ',
			defination: 'Request For Quote',
		},
	],
}
