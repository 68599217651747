import {SAVE_PORTFOLIO, EDIT_PORTFOLIO, CLEAR_PORTFOLIO} from './portfoliosActions'
import {Portfolio} from './portfoliosModel'

export interface PortfolioData {
	loading?: boolean
	data: Portfolio[]
}

const initialState: PortfolioData = {
	loading: false,
	data: [],
}

export function portfolioReducer(state = initialState, action: any) {
	switch (action.type) {
		case SAVE_PORTFOLIO.triggered:
			return {...state, loading: true}
		case SAVE_PORTFOLIO.succeeded:
		case SAVE_PORTFOLIO.failed:
			return {...state, loading: false}

		case EDIT_PORTFOLIO.triggered:
			return {...state, loading: true}
		case EDIT_PORTFOLIO.succeeded:
		case EDIT_PORTFOLIO.failed:
			return {...state, loading: false}

		case CLEAR_PORTFOLIO:
			return {...state, data: null}
	}

	return state
}
