import {createFetchActions} from '../../../services/createActions'
import {post} from '../../../services/createAPICall'
import {getPMSBaseUrl} from '../../../services/apiService'

export const GET_P2P_WIDGETS = createFetchActions('GET_P2P_WIDGETS')
export const SAVE_P2P_WIDGETS = createFetchActions('SAVE_P2P_WIDGETS')
export const DELETE_P2P_WIDGETS = createFetchActions('DELETE_P2P_WIDGETS')
export const EMPTY_P2P_WIDGETS = createFetchActions('EMPTY_P2P_WIDGETS')

export const getWidgets = user => (dispatch: any) => {
	return dispatch(post(GET_P2P_WIDGETS, `${getPMSBaseUrl()}/getfmcfg`, user, null))
}

export const saveWidgets = widget => (dispatch: any) => {
	return dispatch(post(SAVE_P2P_WIDGETS, `${getPMSBaseUrl()}/savefmcfg`, widget, null))
}

export const deleteWidgets = user => (dispatch: any) => {
	return dispatch(post(DELETE_P2P_WIDGETS, `${getPMSBaseUrl()}/deletefmcfg`, user, null))
}

export const emptyWidgetsData = () => {
	return {type: EMPTY_P2P_WIDGETS}
}
