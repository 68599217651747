import {getPMSClient} from '../../..'
import {createFetchActions} from '../../../services/createActions'
import {collateralBasketQuery} from './collateralBasketItemQueries'

export const GET_COLLATERAL_BASKET = createFetchActions('GET_COLLATERAL_BASKET')
export const CLEAR_COLLATERAL_BASKET = createFetchActions('CLEAR_COLLATERAL_BASKET')

export const getCollateralBasket = (basketId: string) => {
	return function (dispatch) {
		dispatch({type: GET_COLLATERAL_BASKET.triggered})
		getPMSClient()
			.query({
				query: collateralBasketQuery(basketId),
				fetchPolicy: 'network-only',
				errorPolicy: 'all',
			})
			.then(response => {
				dispatch({
					type: GET_COLLATERAL_BASKET.succeeded,
					response,
				})
			})
	}
}

export const clearCollateralBasket = () => {
	return {
		type: CLEAR_COLLATERAL_BASKET,
	}
}
