export const featureList = {
	restrictedEntries: true,
	abcmRerun: true,
	fileTypeConfig: false,
	showFileTypes: false,
}

export const portfolioStatus = {
	success: 'success',
	downloading: 'downloading',
	error: 'error',
	completed: 'completed',
	enrichment_pending: 'enrichment_pending',
}

export const fileTypes = {
	tradeDate: {
		id: 'TRADE_DATE',
		label: 'Trade Date',
	},
	settlementDate: {
		id: 'SETTLE_DATE',
		label: 'Settlement Date',
	},
	taxLot: {
		id: 'TAX_LOT',
		label: 'Tax lot',
	},
	axe: {
		id: 'AXE',
		label: 'Axe',
	},
	marginDetails: {
		id: 'MARGIN',
		label: 'Margin Details',
	},
}

export const fileTypeIdList = [
	fileTypes.tradeDate.id,
	fileTypes.settlementDate.id,
	fileTypes.taxLot.id,
	fileTypes.axe.id,
	fileTypes.marginDetails.id,
]

export const fileTypeLabelList = {
	[fileTypes.tradeDate.id]: fileTypes.tradeDate.label,
	[fileTypes.settlementDate.id]: fileTypes.settlementDate.label,
	[fileTypes.taxLot.id]: fileTypes.taxLot.label,
	[fileTypes.axe.id]: fileTypes.axe.label,
	[fileTypes.marginDetails.id]: fileTypes.marginDetails.label,
}
