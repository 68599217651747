import React, {useState} from 'react'

interface Props {
	autoComplete?: string
	className?: string
	id?: string
	placeholder?: string
	readOnly?: boolean
	type?: string
	value?: string
	onBlur?: (event: any) => any
	onChange?: (event: any) => any
	onFocus?: (event: any) => any
}

const PasswordInput = (props: Props) => {
	const [showPassowrd, setShowPassword] = useState(true)

	return (
		<div className='form-password-input'>
			<input className='form-control' autoComplete='off' {...props} type={showPassowrd ? 'password' : 'text'} />
			<span onClick={() => setShowPassword(!showPassowrd)} className='form-icon'>
				<i
					className={`${showPassowrd ? 'fas fa-eye text-custom-control-checked' : 'fas fa-eye-slash text-danger'}`}
				></i>
			</span>
		</div>
	)
}

export default PasswordInput
