import memoizeOne from 'memoize-one'
import Lodash from 'lodash'
import {ObjectUtil} from 'helper-util'
import {defaultState, field, steps} from './OrderEntryModalConstant'
import {bidOfferType, cashCollateralOption, cashSingleStockOption} from './OrderDetails/OrderDetailsConstant'
import {currencyOption, maturityOption, strategyOption} from './OrderDetails/OrderDetailsConstant'
import {getDateFromArray} from '../../../utils/dateUtil'
import {orderType} from '../PeerToPeerConstant'
import {Config, CLIENT_CONSTANTS} from '../../../constants/clientConfig'
import {getQuantity} from '../../../utils/calculationUtil'
import moment from 'moment'

export const optimizePayloadData = (data: any) => {
	let payload = {}

	Object.keys(data).forEach(key => {
		const keyValue = data[key]
		if (keyValue !== null) {
			if (ObjectUtil.isObject(keyValue) && ObjectUtil.isObject(keyValue.value)) {
				payload = Object.assign({}, payload, keyValue.value)
			} else if (ObjectUtil.isObject(keyValue) && keyValue.value) {
				payload[key] = keyValue.value
			} else {
				payload[key] = keyValue
			}
		}
	})

	return payload
}

export const preloadPayloadData = (stagingOrderEditData: any) => {
	const orderDetails = Lodash.cloneDeep(defaultState.orderDetails)
	const indemnification = Lodash.cloneDeep(defaultState.indemnification)
	const additionalDetails = Lodash.cloneDeep(defaultState.additionalDetails)

	if (Config.activeConfig.id === CLIENT_CONSTANTS.STATESTREET)
		orderDetails.strategy = {id: 'REPO', label: 'Repo', value: 'REPO'}

	if (stagingOrderEditData) {
		orderDetails.orderIndicatorType = stagingOrderEditData.orderIndicatorType
		orderDetails.clientRiskType = stagingOrderEditData.clientRiskType
		orderDetails.clientIds = stagingOrderEditData.counterParties.map(item => item.clientId)
		orderDetails.strategy = strategyOption.find(item => item.value === stagingOrderEditData.strategy)
		orderDetails.rateType = stagingOrderEditData.allInRate.rateType
		orderDetails.allInRate = stagingOrderEditData.allInRate.allInRate
		orderDetails.benchmark = stagingOrderEditData.allInRate.benchmark
		orderDetails.referenceId = stagingOrderEditData.allInRate.referenceId
		orderDetails.spread = stagingOrderEditData.allInRate.spread
		const singleStock = stagingOrderEditData.offerSingleStock || stagingOrderEditData.bidSingleStock
		const collateralBasket = stagingOrderEditData.offerCollateral || stagingOrderEditData.bidCollateral
		orderDetails.collateralType = singleStock
			? bidOfferType.singleStock
			: collateralBasket.basketId
			? bidOfferType.collateral
			: bidOfferType.decideLater
		orderDetails.singleStock =
			orderDetails.collateralType === bidOfferType.singleStock
				? {
						cusip: singleStock.cusip,
						index: singleStock.index,
						isin: singleStock.isin,
						longName: singleStock.longName,
						price: singleStock.price,
						priceMultiplier: singleStock.priceMultiplier,
						quantity: singleStock.quantity,
						securityId: singleStock.securityId,
				  }
				: null
		orderDetails.collateralBasket =
			orderDetails.collateralType === bidOfferType.collateral
				? {
						id: collateralBasket.basketId,
						label: 'Need From Backend',
						value: collateralBasket.basketId,
				  }
				: null
		orderDetails.schedule = stagingOrderEditData.schedule
			? {
					id: stagingOrderEditData.schedule,
					label: stagingOrderEditData.schedule,
					value: stagingOrderEditData.schedule,
			  }
			: null
		orderDetails.bidOffer = [...cashSingleStockOption, ...cashCollateralOption].find(
			item =>
				item.value.bidType === stagingOrderEditData.bidType && item.value.offerType === stagingOrderEditData.offerType
		)
		orderDetails.substitutable = stagingOrderEditData.substitutable ? 'YES' : 'NO'
		orderDetails.quantity = stagingOrderEditData.quantity
		orderDetails.unit = null
		orderDetails.price = stagingOrderEditData.price ? stagingOrderEditData.price.amount : null
		orderDetails.haircut = stagingOrderEditData.haircut
		orderDetails.dirtyPrice = stagingOrderEditData.dirtyPrice ? stagingOrderEditData.dirtyPrice.amount : null
		orderDetails.startCash = stagingOrderEditData.startCash ? stagingOrderEditData.startCash.amount : null
		orderDetails.repoInterest = stagingOrderEditData.repoInterest ? stagingOrderEditData.repoInterest : null
		orderDetails.endCash = stagingOrderEditData.endCash ? stagingOrderEditData.endCash.amount : null
		orderDetails.notional = stagingOrderEditData.notional.amount
		orderDetails.currency = currencyOption.find(item => item.value === stagingOrderEditData.notional.currency)
		orderDetails.tradeDate = getDateFromArray(stagingOrderEditData.tradeDate)
		orderDetails.forwardStart = stagingOrderEditData.forwardStart
			? getDateFromArray(stagingOrderEditData.forwardStart)
			: null
		orderDetails.settlementDate = getDateFromArray(stagingOrderEditData.settlementDate)
		orderDetails.maturityDate = getDateFromArray(stagingOrderEditData.maturityDate)
		orderDetails.terminationDate = getDateFromArray(stagingOrderEditData.terminationDate)
		orderDetails.maturityType = maturityOption.find(item => item.value === stagingOrderEditData.maturityType)
		orderDetails.resetType = stagingOrderEditData.resetType ? 'YES' : 'NO'

		// Indemnification
		if (stagingOrderEditData.indemnification) {
			indemnification.isIndemnificationRequested = stagingOrderEditData.indemnification.indemnificationRequested
			indemnification.indemnificationPrice = stagingOrderEditData.indemnification.indemnificationPrice
			indemnification.indemnifier = stagingOrderEditData.indemnification.indemnifierClientId
				? {
						id: stagingOrderEditData.indemnification.indemnifierClientId,
						label: stagingOrderEditData.indemnification.indemnifierLegalId,
						value: stagingOrderEditData.indemnification.indemnifierClientId,
				  }
				: null
		}

		// Additional Details
		const extractedAdditionalDetails = singleStock || collateralBasket
		additionalDetails.assetType = extractedAdditionalDetails.assetType
			? {
					id: extractedAdditionalDetails.assetType,
					label: extractedAdditionalDetails.assetType,
					value: extractedAdditionalDetails.assetType,
			  }
			: null
		additionalDetails.assetSubType = extractedAdditionalDetails.assetSubType
			? {
					id: extractedAdditionalDetails.assetSubType,
					label: extractedAdditionalDetails.assetSubType,
					value: extractedAdditionalDetails.assetSubType,
			  }
			: null
		additionalDetails.index = extractedAdditionalDetails.index
			? {
					id: extractedAdditionalDetails.index,
					label: extractedAdditionalDetails.index,
					value: extractedAdditionalDetails.index,
			  }
			: null
	}

	return {orderDetails, indemnification, additionalDetails}
}

export const getAllowedSteps = memoizeOne((selectedOrderType: string, disabledSteps) => {
	return steps.filter(
		item => !disabledSteps.includes(item.id) && !(selectedOrderType !== orderType.p2p && item.id === 'indemnification')
	)
}, Lodash.isEqual)

export const getMandatoryClass = (mandatory: any[], name: string) => {
	return mandatory.includes(name) ? 'mandatory' : ''
}
export const IsDisabledDatePickerClass = (isSTT: boolean, data: any) => {
	return isSTT && data ? 'disabledDatePicker' : ''
}

export const disableNextButton = (mandatory, data) => {
	const startCashExclusion =
		data[field.STRATEGY] && ['REPO', 'SBL'].includes(data[field.STRATEGY].value)
			? mandatory.filter(item => ![field.START_CASH].includes(item))
			: mandatory

	const disableNextButton = startCashExclusion.some(item => data[item] === null)
	const startCashOverride =
		data[field.STRATEGY] && data[field.STRATEGY].value === 'REPO' && data[field.START_CASH] === 0
	const priceOverride = data[field.COLLATERAL_TYPE] === 'SINGLE_SECURITY' && data.price === 0

	const isBenchmarkRequired = data[field.RATE_TYPE] === 'FLOATING' ? true : false
	const isBenchmarkAvailable = data.benchmark ? true : false
	const isBenchmarkMandate = isBenchmarkRequired ? !isBenchmarkAvailable : false

	const result = disableNextButton || startCashOverride || priceOverride || isBenchmarkMandate

	return result
}

export const validateQuantity = (minLotSize, minPiece, minIncrement, singleStock, quantity, price) => {
	if (singleStock) {
		const minLotSizePiece = getQuantity(minLotSize, price, singleStock['priceMultiplier'])
		if (
			quantity >= Math.max(minPiece, minLotSizePiece) &&
			quantity % minIncrement === 0 &&
			quantity <= singleStock['availableQuantity']
		)
			return false
		else return true
	} else {
		return false
	}
}

export const isValidDate = (date, holidays) => {
	return (
		![0, 6].includes(date.day()) &&
		(holidays
			? !holidays.some(holiday => moment(holiday).format('YYYYMMDD') === moment(date).format('YYYYMMDD'))
			: true)
	)
}

export const addBusinessDays = (originalDate, numDaysToAdd, holidays) => {
	let daysRemaining = numDaysToAdd
	const newDate = originalDate.clone()
	while (daysRemaining > 0) {
		newDate.add(1, 'd')
		if (isValidDate(newDate, holidays)) daysRemaining--
	}
	return newDate
}

export const validDateHoliday = holidays => {
	const tempDate = moment().clone().startOf('day')
	return !(![0, 6].includes(tempDate.day()) && !holidays.find(holiday => moment(holiday).isSame(tempDate)))
}
