export const assertLongdataModifier = assertData => {
	if (assertData.length) {
		assertData.forEach(element => {
			element['subTableData'] = element.sectordata
			let grossLong = 0
			let netLong = 0
			Array.isArray(element.sectordata) &&
				element.sectordata.forEach(element => {
					grossLong += element.gross_long
					netLong += element.net_long
				})
			element['grossLong'] = grossLong
			element['netLong'] = netLong
		})
		return assertData
	} else {
		return assertData
	}
}

export const addNewProp = data => {
	if (data.length) {
		data.forEach(item => {
			item['newValue'] = item['value']
		})
		return data
	} else {
		return data
	}
}
export const numberFixedToTwo = data => {
	if (data.length) {
		data.forEach(item => {
			if (item.bins) {
				item.bins.forEach(bin => {
					bin['high'] = Number(bin['high'].toFixed(2))
					bin['low'] = Number(bin['low'].toFixed(2))
				})
			}
		})
		return data
	} else {
		return data
	}
}
