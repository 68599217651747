import * as axios from 'axios'
import {apiConfig, apiConfigInterface} from '../config/apiConfig'
import {getAuthToken} from './authService'

export function getIAMBaseUrl(config: apiConfigInterface = apiConfig) {
	return config.protocol + '://' + config.hostIAM
}

export function getMDSBaseUrl(config: apiConfigInterface = apiConfig) {
	return config.protocol + '://' + config.hostMDS
}

export function getMPSBaseUrl(config: apiConfigInterface = apiConfig) {
	return config.protocol + '://' + config.hostMPS
}

export function getPMSBaseUrl(config: apiConfigInterface = apiConfig) {
	return config.protocol + '://' + config.hostPMS
}

export function getPBMSBaseUrl(config: apiConfigInterface = apiConfig) {
	return config.protocol + '://' + config.hostPBMS
}

export function getConfigBaseUrl(config: apiConfigInterface = apiConfig) {
	return config.protocol + '://' + config.hostConfig
}

export function getLocateBaseUrl(config: apiConfigInterface = apiConfig) {
	return config.protocol + '://' + config.hostLocate
}

export function getALPBaseUrl(config: apiConfigInterface = apiConfig) {
	return config.protocol + '://' + config.hostALP
}

export function getNotificationBaseUrl(config: apiConfigInterface = apiConfig) {
	return config.protocol + '://' + config.hostNotification
}

export function getPMSGraphBaseUrl(config: apiConfigInterface = apiConfig) {
	return config.protocol + '://' + config.hostGraphPMS
}

export function getLocateGraphBaseUrl(config: apiConfigInterface = apiConfig) {
	return config.protocol + '://' + config.hostGraphLocate
}

export function getPBMSGraphBaseUrl(config: apiConfigInterface = apiConfig) {
	return config.protocol + '://' + config.hostGraphPBMS
}

export function getMPSGraphBaseUrl(config: apiConfigInterface = apiConfig) {
	return config.protocol + '://' + config.hostGraphMPS
}

export function getMDSGraphBaseUrl(config: apiConfigInterface = apiConfig) {
	return config.protocol + '://' + config.hostGraphMDS
}

export function getIAMGraphBaseUrl(config: apiConfigInterface = apiConfig) {
	return config.protocol + '://' + config.hostGraphIAM
}

export function getConfigGraphBaseUrl(config: apiConfigInterface = apiConfig) {
	return config.protocol + '://' + config.hostGraphConfig
}

export function getMPSWSBaseUrl(config: apiConfigInterface = apiConfig) {
	return config.protocol + '://' + config.hostWSMPS
}

export function getPMSWSBaseUrl(config: apiConfigInterface = apiConfig) {
	return config.protocol + '://' + config.hostWSPMS
}

export function getLocateWSBaseUrl(config: apiConfigInterface = apiConfig) {
	return config.protocol + '://' + config.hostWSLocate
}

export function getAlpWSBaseUrl(config: apiConfigInterface = apiConfig) {
	return config.protocol + '://' + config.hostWSAlp
}
export function getMarginAnalysisWSBaseUrl(config: apiConfigInterface = apiConfig) {
	return config.protocol + '://' + config.hostWSMna
}

export function getQuantBaseUrl(config: apiConfigInterface = apiConfig) {
	return config.protocol + '://' + config.hostQuant
}

export function getGatewayBaseUrl(config: apiConfigInterface = apiConfig) {
	return config.protocol + '://' + config.hostGateway
}
export function callAPI(requestConfig: any) {
	return axios.default(requestConfig)
}

export function setHeaders(requestConfig: any) {
	requestConfig['headers'] = requestConfig['headers'] ? requestConfig['headers'] : {}
	if (!requestConfig['headers']['Content-Type']) {
		requestConfig['headers']['Content-Type'] = 'application/json'
	}
	const token = getAuthToken()
	if (token) {
		requestConfig['headers']['Authorization'] = `Bearer ${token}`
	}
	return requestConfig
}
