import {
	ABCM_INITIAL_RANKING,
	ABCM_UPDATED_RANKING,
	ABCM_UPDATED_RANKING_DETAILS,
	CLEAR_ABCM_RANK_DATA,
} from './abcmRankActions'
import {RankingData} from './abcmRankModel'

export interface AbcmRanking {
	data: RankingData
	loading: boolean
	rankingId: string
	defaultRanking: RankingData
}

const initialState: AbcmRanking = {
	data: {} as RankingData,
	loading: false,
	rankingId: '',
	defaultRanking: {} as RankingData,
}

export function abcmRankingReducer(state = initialState, action: any) {
	switch (action.type) {
		case ABCM_INITIAL_RANKING.triggered:
			return {...state, loading: true}
		case ABCM_INITIAL_RANKING.succeeded:
			const res = action.response.data
			return {...state, loading: false, data: res, defaultRanking: res}
		case ABCM_INITIAL_RANKING.failed:
			return {...state, loading: false}

		case ABCM_UPDATED_RANKING_DETAILS.triggered:
			return {...state, loading: true}
		case ABCM_UPDATED_RANKING_DETAILS.succeeded:
			const response = action.response.data
			return {...state, loading: false, data: response}
		case ABCM_UPDATED_RANKING_DETAILS.failed:
			return {...state, loading: false}

		case ABCM_UPDATED_RANKING.triggered:
			return {...state, loading: true}
		case ABCM_UPDATED_RANKING.succeeded:
			const id = action.response.data
			return {...state, loading: false, rankingId: id}
		case ABCM_UPDATED_RANKING.failed:
			return {...state, loading: false}
		case CLEAR_ABCM_RANK_DATA:
			return {...state, loading: false, data: []}
	}
	return state
}
