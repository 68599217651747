import {GET_INTRA_DAY_ACTIVITIES, CLEAR_INTRA_DAY_ACTIVITIES} from './intradayActivitiesActions'

export interface IntradayActivities {
	intradayActivitiesLoading: boolean
	intradayActivities: any
}

const initialState: IntradayActivities = {
	intradayActivitiesLoading: true,
	intradayActivities: null,
}

export function IntradayActivitiesReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_INTRA_DAY_ACTIVITIES.triggered:
			return {...state, intradayActivitiesLoading: true, intradayActivities: null}
		case GET_INTRA_DAY_ACTIVITIES.failed:
			return {...state, intradayActivitiesLoading: false}
		case GET_INTRA_DAY_ACTIVITIES.succeeded:
			const intradayActivities =
				action &&
				action.response &&
				action.response.data &&
				action.response.data.portfolio &&
				action.response.data.portfolio.intraDayActivities
			return {...state, intradayActivitiesLoading: false, intradayActivities}
		case CLEAR_INTRA_DAY_ACTIVITIES:
			return {
				...state,
				intradayActivities: null,
			}
	}
	return state
}
