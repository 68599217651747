import {FETCH_SCORING_DATA, FETCH_FACTORY_WEIGHTS_DATA, UPDATE_WEIGHTS, HIE_CLUSTER} from './ClientScoringAction'
import {addNewProp, numberFixedToTwo} from './ClientScoringHelper'

export interface ClientScoring {
	clientFactorsLoading: boolean
	clientFactorsWeightsLoading: boolean
	clientFilterData: any
	clientInputData: any
	clientScoringData: any
	clientFactors: any
	hieCluster: any
}

const initialState: ClientScoring = {
	clientFactorsLoading: true,
	clientFactorsWeightsLoading: true,
	clientFilterData: null,
	clientInputData: null,
	clientScoringData: {},
	clientFactors: {},
	hieCluster: null,
}

export function clientScoringReducer(state = initialState, action: any) {
	switch (action.type) {
		case FETCH_FACTORY_WEIGHTS_DATA.triggered:
			return {...state, clientFactorsWeightsLoading: true}

		case FETCH_FACTORY_WEIGHTS_DATA.succeeded:
			state.clientFactors = action.response.data
			state.clientFactors['weights'] = state.clientFactors['weights'] ? addNewProp(state.clientFactors['weights']) : []
			state.clientFactors['bins'] = state.clientFactors['bins'] ? numberFixedToTwo(state.clientFactors['bins']) : []
			return {...state, clientFactorsWeightsLoading: false}

		case FETCH_SCORING_DATA.triggered:
			return {...state, clientFactorsLoading: true}

		case FETCH_SCORING_DATA.succeeded:
			state.clientScoringData = action.response.data
			return {...state, clientFactorsLoading: false}

		case UPDATE_WEIGHTS.triggered:
			return {...state, clientFactorsWeightsLoading: true, clientFactorsLoading: true}

		case UPDATE_WEIGHTS.succeeded:
			state.clientScoringData = action.response.data
			state.clientFactors['weights'] = action.response.data['weights'] ? addNewProp(state.clientFactors['weights']) : []
			state.clientFactors['bins'] = action.response.data['bins'] ? numberFixedToTwo(state.clientFactors['bins']) : []
			return {...state, clientFactorsWeightsLoading: false, clientFactorsLoading: false}

		case HIE_CLUSTER.succeeded:
			state.hieCluster = action.response.data
			return {...state}
	}
	return state
}
