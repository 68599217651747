import {GET_MKT_LAYOUTS, SAVE_MKT_LAYOUTS, GET_TABLE_DATA, DELETE_LAYOUTS} from './marketDataActions'

export interface MarketData2 {
	layouts: any
	marketDataTables: any
}

const initialState: MarketData2 = {
	layouts: [],
	marketDataTables: [],
}

export function marketDataReducer2(state = initialState, action: any) {
	switch (action.type) {
		case GET_MKT_LAYOUTS.triggered:
			return {...state}

		case GET_MKT_LAYOUTS.failed:
			return {...state}

		case GET_MKT_LAYOUTS.succeeded:
			const initialLayouts = action.response.data
			return {
				...state,
				layouts: initialLayouts,
			}

		case SAVE_MKT_LAYOUTS.triggered:
			return {...state}

		case SAVE_MKT_LAYOUTS.failed:
			return {...state}

		case SAVE_MKT_LAYOUTS.succeeded:
			const updatedLayouts = action.response.data.items
			return {
				...state,
				layouts: updatedLayouts,
			}
		case GET_TABLE_DATA.triggered:
			return {...state}

		case GET_TABLE_DATA.failed:
			return {...state}

		case GET_TABLE_DATA.succeeded:
			const marketDataTables = action.response.data
			return {
				...state,
				marketDataTables,
			}
		case DELETE_LAYOUTS.triggered:
			return {...state, loading: true}
		case DELETE_LAYOUTS.failed:
			return {...state, loading: false}
		case DELETE_LAYOUTS.succeeded:
			const remainedlayouts = action.response.data.items
			return {
				...state,
				layouts: remainedlayouts,
			}
	}

	return state
}
