import React from 'react'
import _ from 'lodash'
import {formatWithSeparator} from '../../utils/formatUtils'

export const getUniqueCurrencies = (data: any) => {
	const uniqueCurrencies = {}
	data.calendarDay.forEach(item => {
		item &&
			item.trades &&
			item.trades.forEach(trade => {
				uniqueCurrencies[trade && trade.notional && trade.notional.currency] = {
					id: trade && trade.notional && trade.notional.currency,
					label: trade && trade.notional && trade.notional.currency,
				}
			})
	})
	return uniqueCurrencies
}

export const getUniqProducts = (data, key) => {
	let prodcusList = []
	let uniqObj = {}

	if (data && data.calendarDay) {
		data.calendarDay.forEach(item => {
			prodcusList.push(_.uniqBy(item.trades, key))
		})
		const uniqueitems = Array.from(new Set(_.flattenDeep(prodcusList).map(obj => obj[key])))
		uniqueitems.forEach(item => {
			let obj = {}
			obj['id'] = item
			obj['label'] = item
			uniqObj[item] = obj
		})
	}
	return uniqObj
}

export const getCashCollateral = (tradeDetails: any) => {
	const cash = tradeDetails && tradeDetails.cashAmount
	const collateral = tradeDetails && tradeDetails.collateralAmount
	return (
		<div className='d-inline'>
			<span className='border-right pr-2'>(Cash: {formatWithSeparator(cash)}</span>
			<span className='pl-2'>Collateral: {formatWithSeparator(collateral)})</span>
		</div>
	)
}
