import React from 'react'
import VerticallyCenteredModal from '../../pages/styleGuide/fsModal'
import {Props, State} from './customiseMenuModel'
import Lodash from 'lodash'
import {isResponseSuccess} from '../../utils/apiResponseUtil'
import MenuList from './menuList'
import MenuItems from './menuItems'
import {CheckSuccessIcon} from '../../styles/svg/svgIcons'
import {v4 as createNewId} from 'uuid'
import FsLoader from '../../common/fsLoader/fsLoaderContainer'

export default class CustomiseMenu extends React.Component<Props, State> {
	constructor(props) {
		super(props)
		this.state = {
			allMenuData: [],
			activeNavigationGroup: {},
			selectedMenuOptions: [],
			menuCreationInProgress: false,
			isChangesApplied: false,
		}
	}

	componentDidMount = () => {
		const {getAllMenus} = this.props
		getAllMenus()
	}

	componentWillUnmount = () => {
		const {resetMenuData} = this.props
		resetMenuData()
	}

	componentDidUpdate = prevProps => {
		const {allMenuData} = this.props
		if (!Lodash.isEqual(prevProps.allMenuData, allMenuData)) {
			const activeMenuItem = allMenuData && allMenuData.find(item => item.isActive)
			const activeNavigationGroup = activeMenuItem || allMenuData[0]
			this.setState({
				allMenuData,
				activeNavigationGroup,
				selectedMenuOptions: !Lodash.isEmpty(activeNavigationGroup) ? activeNavigationGroup.items : [],
			})
		}
	}

	handleNavigationGroupChange = (navigationGroup: any) => {
		const activeNavigationGroup = Lodash.cloneDeep(navigationGroup)
		activeNavigationGroup.isActive = true
		this.setState({
			activeNavigationGroup,
			selectedMenuOptions: activeNavigationGroup.items,
		})
		this.handleActivate(null, navigationGroup)
	}

	handleEdit = (value: any, id: any) => {
		const activeNavigationGroup = Lodash.cloneDeep(this.state.activeNavigationGroup)
		const allMenuData = []
		this.state.allMenuData.forEach(item => {
			const newItem = JSON.parse(JSON.stringify(item))
			if (item.menuId === id) newItem.name = value
			allMenuData.push(newItem)
		})

		activeNavigationGroup.name = value
		this.setState({allMenuData, activeNavigationGroup})
		this.handleUpdate(activeNavigationGroup)
	}

	createNewMenu = (menuName: any) => {
		const activeNavigationGroup = {
			name: menuName,
			isActive: true,
			menuId: createNewId(),
			items: [],
			isMenuNew: true,
		}
		const selectedMenuOptions = []
		const allMenuData = Lodash.cloneDeep(this.state.allMenuData) || []
		allMenuData &&
			allMenuData.push({
				...activeNavigationGroup,
			})
		this.setState({
			allMenuData,
			menuCreationInProgress: false,
			activeNavigationGroup,
			selectedMenuOptions,
		})
	}

	handleSelectedMenuShift = menuValue => {
		const selectedMenuOptions = Lodash.cloneDeep(this.state.selectedMenuOptions)
		const itemIndex = selectedMenuOptions.findIndex(item => item === menuValue)
		selectedMenuOptions.splice(itemIndex, 1)
		this.setState({selectedMenuOptions})
	}

	handleAvailableMenuShift = menuValue => {
		const selectedMenuOptions = Lodash.cloneDeep(this.state.selectedMenuOptions)
		selectedMenuOptions.push(menuValue)
		this.setState({selectedMenuOptions})
	}

	handleSubmit = () => {
		const {updateMenu, handleCustomiseMenuClose, createMenu, allMenuData} = this.props
		const {activeNavigationGroup, selectedMenuOptions} = this.state
		const menuId = activeNavigationGroup && activeNavigationGroup.menuId
		const isMenuUpdated = allMenuData && allMenuData.map(item => item.menuId).includes(menuId)
		if (isMenuUpdated) {
			const req = {
				...activeNavigationGroup,
				isActive: true,
				items: selectedMenuOptions,
			}
			delete req.menuId
			updateMenu(activeNavigationGroup.menuId, req).then(response => {
				if (isResponseSuccess(response)) {
					handleCustomiseMenuClose()
				}
			})
		} else {
			const req = {
				...activeNavigationGroup,
				items: selectedMenuOptions,
			}
			delete req.menuId
			createMenu(req).then(response => {
				if (isResponseSuccess(response)) {
					handleCustomiseMenuClose()
				}
			})
		}
	}

	handleDelete = (id: any) => {
		const {deleteMenu, getAllMenus} = this.props
		deleteMenu(id).then(response => {
			if (isResponseSuccess(response)) {
				getAllMenus()
			}
		})
	}

	handleReset = () => {
		const {activeNavigationGroup} = this.state
		const {allMenuData} = this.props
		const originalMenuItem = allMenuData && allMenuData.find(item => item.menuId === activeNavigationGroup.menuId)
		const selectedMenuOptions = originalMenuItem ? originalMenuItem.items : []
		this.setState({selectedMenuOptions})
	}

	handleMenuCreationToggle = (menuCreationInProgress: boolean) => {
		this.setState({menuCreationInProgress})
	}

	handleUpdate = (activeNavigationGroup: any) => {
		const {updateMenu, getAllMenus} = this.props
		const req = {
			...activeNavigationGroup,
			isActive: true,
		}
		delete req.menuId
		updateMenu(activeNavigationGroup.menuId, req).then(response => {
			if (isResponseSuccess(response)) {
				getAllMenus()
			}
		})
	}

	handleActivate = (event, menuItem: any) => {
		event && event.stopPropagation()
		if (!menuItem.isActive) {
			const allMenuData = Lodash.cloneDeep(this.state.allMenuData)
			const updatedMenuData = allMenuData.map(item => {
				if (item.menuId === menuItem.menuId) {
					return {
						...item,
						isActive: true,
					}
				} else {
					return {
						...item,
						isActive: false,
					}
				}
			})
			const activeNavigationGroup = {
				...menuItem,
				isActive: true,
			}
			this.setState({
				allMenuData: updatedMenuData,
				activeNavigationGroup,
				selectedMenuOptions: menuItem.items,
			})
			this.handleUpdate(activeNavigationGroup)
		}
	}

	openConfirmationModal = () => {
		this.setState({isChangesApplied: true})
	}

	closeConfirmationModal = () => {
		this.setState({isChangesApplied: false})
	}

	render() {
		const {showCustomiseMenu, handleCustomiseMenuClose, loading} = this.props
		const {allMenuData, menuCreationInProgress, selectedMenuOptions, activeNavigationGroup, isChangesApplied} =
			this.state
		return (
			<>
				{loading && <FsLoader />}
				<VerticallyCenteredModal
					className='modal-customize-menu'
					size='xl'
					headerEl={''}
					bodyEl={
						<div className='customize-menu-container d-flex overflow-hidden border-none h-100'>
							<MenuList
								loading={loading}
								allMenuData={allMenuData}
								menuCreationInProgress={menuCreationInProgress}
								handleNavigationGroupChange={this.handleNavigationGroupChange}
								handleEdit={this.handleEdit}
								handleDelete={this.handleDelete}
								createNewMenu={this.createNewMenu}
								handleMenuCreationToggle={this.handleMenuCreationToggle}
								handleActivate={this.handleActivate}
								activeNavigationGroup={activeNavigationGroup}
							/>
							<MenuItems
								allMenuData={allMenuData}
								activeNavigationGroup={activeNavigationGroup}
								selectedMenuOptions={selectedMenuOptions}
								menuCreationInProgress={menuCreationInProgress}
								handleCustomiseMenuClose={handleCustomiseMenuClose}
								handleSelectedMenuShift={this.handleSelectedMenuShift}
								handleAvailableMenuShift={this.handleAvailableMenuShift}
								handleReset={this.handleReset}
								handleSubmit={this.openConfirmationModal}
							/>
						</div>
					}
					footerEl={''}
					show={showCustomiseMenu}
					onHide={handleCustomiseMenuClose}
				/>
				{isChangesApplied && (
					<VerticallyCenteredModal
						className='modal-customize-menu-applied'
						size='md'
						headerEl={<div></div>}
						bodyEl={
							<div className='text-center mt-4'>
								<span className='d-block m-auto text-danger'>
									<CheckSuccessIcon />
								</span>
								<span className='d-block f-20 my-3'>Your changes have been applied</span>
							</div>
						}
						footerEl={
							<div className='text-center pb-4 pt-3'>
								<button
									className='btn btn-secondary'
									onClick={() => {
										this.closeConfirmationModal()
										this.handleSubmit()
									}}
								>
									Close
								</button>
							</div>
						}
						show={isChangesApplied ? true : false}
						onHide={this.closeConfirmationModal}
					/>
				)}
			</>
		)
	}
}
