import {buildQuery} from 'axios-graphql-builder'
import gql from 'graphql-tag'
import {notificationConstant} from './appConstants'
import {getCounterpartyId} from '../services/authService'

const entries = {
	abcmCategoryName: null,
	acknowledgedBy: null,
	actions: null,
	activityType: null,
	allInRate: null,
	assetMaturityDate: null,
	assetSubType: null,
	assetType: null,
	availabilitiesContribution: {
		amount: null,
	},
	baseNotional: {
		amount: null,
		currency: null,
	},
	basePrice: {
		amount: null,
		currency: null,
	},
	bbId: null,
	book: null,
	borrowFee: null,
	buySellInd: null,
	cash: {
		amount: null,
		currency: null,
	},
	collateral: {
		amount: null,
		currency: null,
	},
	collateralType: null,
	counterParty: null,
	createdBy: null,
	createdDate: null,
	cusip: null,
	endDate: null,
	exchangeRate: null,
	fitchRating: null,
	fund: null,
	// fxRate: null,
	haircut: null,
	hqla: null,
	id: null,
	inefficiencies: {
		inefficiency: null,
		abbr: null,
		displayName: null,
	},
	index: null,
	isin: null,
	isRestricted: null,
	legalEntity: null,
	loanFee: null,
	maturityDate: null,
	maturityType: null,
	moodyRating: null,
	needsContribution: {
		amount: null,
	},
	notional: {
		amount: null,
		currency: null,
	},
	orderId: null,
	originalCashValue: {
		amount: null,
		currency: null,
	},
	originalCollateralValue: {
		amount: null,
		currency: null,
	},
	originalNotional: null,
	originalQuantity: null,
	originalTradeType: null,
	partial: null,
	poolFactor: null,
	portfolioEntryType: null,
	portfolioEntryTypeOption: null,
	price: {
		amount: null,
		currency: null,
	},
	priceMultiplier: null,
	quantity: null,
	rebate: null,
	recallInefficiencyContribution: {
		amount: null,
	},
	recallOrReturnQty: null,
	referenceRate: null,
	returnInefficiencyContribution: {
		amount: null,
	},
	ric: null,
	status: null,
	sector: null,
	securityId: null,
	securityIdType: null,
	settlementDate: null,
	snpRating: null,
	sourceSystem: null,
	sourceSystemTradeId: null,
	spread: null,
	startDate: null,
	tenor: null,
	termDate: null,
	ticker: null,
	tradeDate: null,
	tradeType: null,
}

export const getPortfolioAllEntriesQuery = () => {
	const portfolioAllEntries = {
		query: {
			portfolio: {
				all: {entries},
				pendingOrders: {entries},
			},
		},
	}

	return buildQuery(portfolioAllEntries)
}

export const getPortfolioSelectedEntriesQuery = selectedEntriesId => {
	const portfolioSelectedEntriesQuery = {
		query: {
			portfolioEntries: {
				entries,
			},
		},
	}

	const paramList = [
		{
			key: 'portfolioEntries',
			param: `ids: ${JSON.stringify(selectedEntriesId)}`,
		},
	]

	return buildQuery(portfolioSelectedEntriesQuery, paramList)
}

const restrictedEntriesQuery = {
	query: {
		restrictedSecurityDocumentEntries: {
			securityId: null,
		},
	},
}

export const getRestrictedEntriesQuery = () => {
	return buildQuery(restrictedEntriesQuery)
}

export const getExecutionNotificationsQuery = () => {
	const executionSummary = [
		notificationConstant.tradingQueue,
		notificationConstant.p2p,
		notificationConstant.avn,
	].reduce((acc, cur) => {
		acc[cur] = null
		return acc
	}, {})
	const notificationsQuery = {
		query: {
			executionSummary,
		},
	}
	return buildQuery(notificationsQuery)
}

export const getLocateAuthoriserNotificationsQuery = () => {
	return gql`
		query {
			locateCounterAuthorizer
		}
	`
}

export const getLocateClientNotificationsQuery = () => {
	return gql`
		query {
			locateCounterClient
		}
	`
}

export const getAllFundQuery = () => {
	const allFundQuery = {
		query: {
			allFunds: {
				fundId: null,
				id: null,
				fullName: null,
				shortName: null,
				fundType: null,
				duration: null,
				legalEntityId: null,
				tenantId: null,
				legalEntityIdentifier: null,
				bbgGlobalIdentifier: null,
				internalCode1: null,
				internalCode2: null,
				internalCode3: null,
				internalCode4: null,
				internalCode5: null,
			},
		},
	}
	const paramList = [
		{
			key: 'allFunds',
			param: `tenantId: ${JSON.stringify(getCounterpartyId())}`,
		},
	]
	return buildQuery(allFundQuery, paramList)
}

export const getAllPortfolioQuery = () => {
	const allPortfolioQuery = {
		query: {
			allPortfolios: {
				id: null,
				portfolioId: null,
				subBusinessUnitId: null,
				tenantId: null,
				fullName: null,
				shortName: null,
				legalEntityIdentifier: null,
				bbgGlobalIdentifier: null,
				internalCode1: null,
				internalCode2: null,
				internalCode3: null,
				internalCode4: null,
				internalCode5: null,
			},
		},
	}
	const paramList = [
		{
			key: 'allPortfolios',
			param: `tenantId: ${JSON.stringify(getCounterpartyId())}`,
		},
	]
	return buildQuery(allPortfolioQuery, paramList)
}
