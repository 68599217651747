import gql from 'graphql-tag'
export {sectorsIndicesQuery as distinctSectorsAndIndicesQuery} from '../../collateralBasketList/collateralBasket/collateralBasketQueries'

const typeAheadResultQuery = (keyword = '') => gql`
query{
  securitiesSuggestions(keyword: "${keyword}"){
    securityId
    ticker
    longName
    isin
    cusip
    index
    price
    priceMultiplier
    assetType
    assetSubType
  }
}
`
const tradingTicketMyBooksQuery = (tradeType: any) => gql`
	{
		myBooks(strategy: "${tradeType}")
	}
`

// const legalEntityQuery = gql`
// 	{
// 		legalEntities {
// 			legalEntityId
// 			fullName
// 			legalEntityExternalId
// 			shortName
// 			__typename
// 		}
// 	}
// `
const legalEntitiesQuery = (tenentId: string) => gql`
	query {
		allLegalEntities(tenantId:"${tenentId}") {
      legalEntityId
      fullName
      shortName
      legalEntityExternalId
		}
	}
`

const strategiesQuery = gql`
	{
		strategies {
			displayName
			code
			direction {
				displayName
				code
			}
		}
	}
`

const sblSecurityDetailsQuery = (securityId: any) => gql`
	query {
		tradeTicketSecurityDetails(securityId: "${securityId}") {
      closingMarketPrice{
        amount
        currency
        }
			securityAvailability {
				internalAvailableQuantity
				externalAvailability {
					lender
					quantity
					rebateRate
				}
				totalAvailableQuantity
			}
			securityNeed {
				totalNeedQuantity
			}
			securityLoan {
				externalLoan {
					borrower
					quantity
					rebateRate
				}
				totalLoanQuantity
			}
			securityBorrow {
				externalBorrow {
					lender
					quantity
					rebateRate
				}
				totalBorrowQuantity
			}
      clientsSecurityAvailabilities {
        clientId
        fullName
        shortName
        securityId
        availableQuantity
      }    
		}
	}
`
const repoSecurityDetailsQuery = (securityId: any) => gql`	
  query {
		tradeTicketSecurityDetails(securityId: "${securityId}") {
      closingMarketPrice{
        amount
        currency
        }
        securityAvailability {
          internalAvailableQuantity
          externalAvailability {
            lender
            quantity
            rebateRate
          }
          totalAvailableQuantity
        }
      securityBorrowReverseRepo{
        externalBorrow{
          securityId
          lender 
          quantity
          rebateRate
        }
        totalBorrowQuantity
      }      
      securityLoanRepo{
        externalLoan{
          securityId
          borrower
          quantity
          rebateRate
        }
        totalLoanQuantity
      }
      clientsSecurityAvailabilities {
        clientId
        fullName
        shortName
        securityId
        availableQuantity
      }
		}
	}
`
export {
	typeAheadResultQuery,
	strategiesQuery,
	sblSecurityDetailsQuery,
	repoSecurityDetailsQuery,
	tradingTicketMyBooksQuery,
	legalEntitiesQuery,
}
