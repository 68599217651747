import numeral from 'numeral'

numeral.register('locale', 'de_DE', {
	delimiters: {
		thousands: ',',
		decimal: '.',
	},
	abbreviations: {
		thousand: 'K',
		million: 'MM',
		billion: 'BN',
		trillion: 'TN',
	},
	currency: {
		symbol: '€',
	},
})

numeral.locale('de_DE')
