import React from 'react'
import {field} from '../../../pages/PeerToPeer/OrderEntryModal/OrderEntryModalConstant'
import {orderType} from '../../../pages/PeerToPeer/PeerToPeerConstant'
import {FinoptsysLogo} from '../../../styles/svg/svgIcons'
import {paths} from '../../../routes/routesConfig'

export const FinOptsys = {
	id: 'finOptSys',
	logo: <div className='header-logo-color text-center f-24'>FinOptSys</div>,
	adminToolAllow: false,
	renameAllInRateToRepoRate: false,
	login: {
		logo: <FinoptsysLogo />,
		fosLink: true,
		fosLogoAtBottom: true,
		carouselDescription: true,
		disableMfa: true,
	},
	landingPath: paths.productMenu,
	navbar: {
		disableThemeSwitch: false,
		disableDefaultHomepage: false,
		disableHelpIcon: false,
		disableDocumentation: true,
		showCookieConsent: false,
	},
	peerToPeer: {
		orderTypeTitle: {
			[orderType.repo]: {label: 'REPO', title: ''},
			[orderType.p2p]: {label: 'P2P', title: ''},
			[orderType.ficc]: {label: 'H2S', title: 'Hub to Spoke'},
			[orderType.gcf]: {label: 'FICC GCF', title: ''},
		},
		orderType: [orderType.gcf],
		orderEntry: {
			allowFloatingRate: true,
			allowAllInRate: true,
			displayStepsCol: true,
			uploadBasket: false,
			getScheduleForCB: true,
			[field.ORDER_TYPE]: {
				[orderType.repo]: [],
				[orderType.p2p]: [],
				[orderType.ficc]: [],
				[orderType.gcf]: [],
			},
			[field.STRATEGY]: [],
			[field.MATURITY_TYPE]: [],
			[field.RESET_TYPE]: false,
			[field.TERM_STRUCTURE]: true,
			[field.STEPS]: [],
			[field.FLOATINGRATECOMPRESSUI]: false,
			[field.CURRENCIES]: [
				{id: 'usd', label: 'USD', value: 'USD'},
				{id: 'gpb', label: 'GBP', value: 'GBP'},
				{id: 'eur', label: 'EUR', value: 'EUR'},
				{id: 'cad', label: 'CAD', value: 'CAD'},
				{id: 'jpy', label: 'JPY', value: 'JPY'},
				{id: 'aud', label: 'AUD', value: 'AUD'},
				{id: 'chf', label: 'CHF', value: 'CHF'},
			],
		},
		orderDetail: {
			allowCusipCandDValidation: false,
			hideIndeminifyFeeColumn: false,
			appendContractTypeToStrategy: false,
			settlementTab: true,
			collateralColumnDefsField: [
				'schedule',
				'cusipCode',
				'scheduleDescription',
				'margin',
				'securityDescription',
				'fitchRating',
				'moodyRating',
			],
			termAndCondition: false,
			editableDuration: true,
			disableTradeAndSettleDate: false,
		},
		orderNegotiation: {
			startCashEditable: false,
		},
	},
	abcPortfolio: {
		defaultColumn: true,
		isFetchAllEntriesConfig: true,
	},
	collateralBasket: {
		fetchBasketItems: {
			optimizedCusipTemplate: false,
		},
		defaultStrategy: {},
		defaultTemplateType: null,
		basketCreationInputField: {
			template: true,
			schedule: false,
			currencies: ['USD', 'GBP', 'EUR', 'CAD', 'JPY', 'AUD', 'CHF'],
			clientRiskTypeTitle: 'Client Risk Type',
		},
		collateralBasketConstruction: {
			strategy: [],
		},
		collateralBasketNegotiation: {
			customSecurityIdColumn: false,
		},
		useEnrichedSecurityQuery: false,
		restrictedColDefs: [],
	},
	collateralOptimizationDashboard: {
		title: 'Collateral Optimization Dashboard',
		enableObjectiveWeights: true,
	},
	accountSettings: {
		groups: {
			showTradeType: true,
		},
		clients: {
			isLeDeposAllowed: false,
		},
	},
	repo: {
		tradeTicketButton: false,
	},
	tradeBlotter: {
		strategy: [
			{id: 'REPO', label: 'REPO', value: 'REPO'},
			{id: 'SBL', label: 'SBL', value: 'SBL'},
			{id: 'OUTRIGHT_CP', label: 'OUTRIGHT CP', value: 'OUTRIGHT_CP'},
			{id: 'OUTRIGHT_TSY', label: 'OUTRIGHT TSY', value: 'OUTRIGHT_TSY'},
		],
		fieldDecimal: 2,
		allInPriceFieldDecimal: 2,
	},
	dashboardSettings: {
		enableRollTrade: true,
		enableNewCollateralOptDashBoard: true,
		displayCOFticker: true,
		displaySODticker: true,
		startCashColumnExist: true,
	},
	portfolioUploadConfirm: {
		displayMessage: true,
		displayRestrictedMessage: true,
	},
}
