import {
	CLEAR_CREATED_BASKET,
	CREATE_BASKET_FROM_SECURITY,
	EDIT_STAGING_ORDER,
	GET_ACTIVITY_LOG,
	GET_ORDER_DETAIL,
} from './PeerToPeerAction'
import {GET_STAGING_ORDER, ORDER_ACTION, PUBLISH_ORDER, RESET_ALL_ORDERS} from './PeerToPeerAction'
import {GET_ALL_ORDER, GET_SETTLEMENT_DETAIL} from './PeerToPeerAction'
import {GET_BENCHMARK, GET_BENCHMARKSTT} from './OrderEntryModal/OrderEntryModalAction'
import {
	SET_BASKETTABLEDATA,
	SET_REJECTEDCOLLATERALBASKETARRAYIDS,
} from '../Locate/AgGridCell/StatusButtonCellRenderer/StatusButtonCellRendererActions'

import {SET_SUGGESTION} from '../Locate/AgGridCell/SecuritySuggestionCellRenderer/SecuritySuggestionCellEditorActions'

export interface PeerToPeer {
	activityLog: any
	actionInProgress: boolean
	benchmarkOption: any
	benchmarkOptionSTT: any
	createdBasket: any
	firmOrder: any[]
	loading: boolean
	orderDetail: any
	openOrder: any[]
	openTrades: any[]
	orderPublished: boolean
	settlementDetail: any[]
	stagingOrder: any
	stagingOrderEditData: any
	rejectedCollateralBasketArrayIds: any[]
	basketTableData: any[]
	collateralBasketNegotiationData: any
}

const initialState: PeerToPeer = {
	activityLog: {},
	actionInProgress: false,
	benchmarkOption: [],
	benchmarkOptionSTT: [],
	createdBasket: null,
	firmOrder: null,
	loading: false,
	orderDetail: {},
	openOrder: null,
	openTrades: null,
	orderPublished: null,
	settlementDetail: null,
	stagingOrder: {
		P2P: null,
		FICC: null,
		GCF: null,
	},
	stagingOrderEditData: null,
	rejectedCollateralBasketArrayIds: [],
	basketTableData: [],
	collateralBasketNegotiationData: {},
}

export function PeerToPeerReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_BENCHMARK.triggered:
			return {...state, benchmarkOption: null}
		case GET_BENCHMARK.succeeded:
			return {...state, benchmarkOption: action.response.data}
		case GET_BENCHMARK.failed:
			return {...state, benchmarkOption: []}
		case GET_BENCHMARKSTT.triggered:
			return {...state, benchmarkOption: null}
		case GET_BENCHMARKSTT.succeeded:
			return {...state, benchmarkOption: action.response.data}
		case GET_BENCHMARKSTT.failed:
			return {...state, benchmarkOption: []}

		case EDIT_STAGING_ORDER.triggered || EDIT_STAGING_ORDER.failed:
			return {...state, stagingOrderEditData: null}
		case EDIT_STAGING_ORDER.succeeded:
			const stagingOrderEditData = action.response ? action.response.data : {}
			return {...state, stagingOrderEditData}

		case GET_ACTIVITY_LOG.triggered || GET_ACTIVITY_LOG.failed:
			return {...state}
		case GET_ACTIVITY_LOG.succeeded:
			const activityLog = JSON.parse(JSON.stringify(state.activityLog))
			if (action.response) activityLog[action.responseId] = action.response
			return {...state, activityLog}

		case GET_ALL_ORDER.triggered:
			return {...state, orderPublished: null, openOrder: null, firmOrder: null}
		case GET_ALL_ORDER.succeeded:
			if (action.response) {
				action.response.openOrders.forEach(item => (item.spread *= 100))
				action.response.myFirmActivity.forEach(item => (item.spread *= 100))
			}
			return {
				...state,
				openOrder: action.response ? action.response.openOrders : [],
				firmOrder: action.response ? action.response.myFirmActivity : [],
				openTrades: action.response ? action.response.openTrades : [],
			}
		case GET_ALL_ORDER.failed:
			return {...state, openOrder: [], firmOrder: []}

		case RESET_ALL_ORDERS:
			return {...state, openOrder: null, firmOrder: null, openTrades: null, orderDetail: {}, stagingOrderEditData: null}

		case GET_ORDER_DETAIL.triggered || GET_ORDER_DETAIL.failed:
			return {...state}
		case GET_ORDER_DETAIL.succeeded:
			const orderDetail = JSON.parse(JSON.stringify(state.orderDetail))
			if (action.response) orderDetail[action.orderId] = action.response.order
			return {...state, orderDetail}

		case GET_SETTLEMENT_DETAIL.triggered:
			return {...state, settlementDetail: null}
		case GET_SETTLEMENT_DETAIL.succeeded:
			return {...state, settlementDetail: action.response.data}
		case GET_SETTLEMENT_DETAIL.failed:
			return {...state, settlementDetail: []}

		case GET_STAGING_ORDER.triggered || GET_STAGING_ORDER.failed:
			return {...state}
		case GET_STAGING_ORDER.succeeded:
			const url = action.response && action.response.config ? action.response.config.url : ''
			const splitedUrl = url.split('?orderType=')
			const stagingOrder = JSON.parse(JSON.stringify(state.stagingOrder))
			stagingOrder[splitedUrl[1]] = action.response && action.response.data ? action.response.data.orderProgramList : {}
			return {...state, stagingOrder}

		case ORDER_ACTION.triggered:
			return {...state, actionInProgress: true}
		case ORDER_ACTION.succeeded || ORDER_ACTION.failed:
			return {...state, actionInProgress: false}

		case PUBLISH_ORDER.triggered:
			return {...state, orderPublished: null}
		case PUBLISH_ORDER.succeeded:
			return {...state, orderPublished: true}
		case PUBLISH_ORDER.failed:
			return {...state, orderPublished: false}

		case CREATE_BASKET_FROM_SECURITY.triggered:
			return {...state, createdBasket: null}
		case CREATE_BASKET_FROM_SECURITY.succeeded:
			return {...state, createdBasket: action.response.data}
		case CREATE_BASKET_FROM_SECURITY.failed:
			return {...state, createdBasket: {}}
		case CLEAR_CREATED_BASKET:
			return {...state, createdBasket: null}

		case SET_REJECTEDCOLLATERALBASKETARRAYIDS.triggered:
			return {...state, rejectedCollateralBasketArrayIds: null}
		case SET_REJECTEDCOLLATERALBASKETARRAYIDS.succeeded:
			return {...state, rejectedCollateralBasketArrayIds: action.data}
		case SET_REJECTEDCOLLATERALBASKETARRAYIDS.failed:
			return {...state, rejectedCollateralBasketArrayIds: null}
		case SET_BASKETTABLEDATA.triggered:
			return {...state, basketTableData: null}
		case SET_BASKETTABLEDATA.succeeded:
			return {...state, basketTableData: action.data}
		case SET_BASKETTABLEDATA.failed:
			return {...state, basketTableData: action.data}
		case SET_SUGGESTION.triggered:
			return {...state, collateralBasketNegotiationData: null}
		case SET_SUGGESTION.succeeded:
			return {...state, collateralBasketNegotiationData: action.data}
		case SET_SUGGESTION.failed:
			return {...state, collateralBasketNegotiationData: null}
	}
	return state
}
