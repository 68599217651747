import {getMPSBaseUrl} from '../../services/apiService'
import {get} from '../../services/createAPICall'
import {createFetchActions} from '../../services/createActions'

export const INVOKE_MARGIN_ATTRIBUTION = createFetchActions('INVOKE_MARGIN_ATTRIBUTION')
export const ATTRIBUTION_GRAPH_FIELDS = createFetchActions('ATTRIBUTION_GRAPH_FIELDS')
export const CRIF_RESULTS = createFetchActions('CRIF_RESULTS')
export const MARGIN_RESULTS = createFetchActions('MARGIN_RESULTS')

export const invokeMarginAttribution = (fund_code: any, group_code: string) => (dispatch: any) => {
	return dispatch(
		get(
			INVOKE_MARGIN_ATTRIBUTION,
			`${getMPSBaseUrl()}/v1/margin-attributions?fundCode=${fund_code}&groupingModel=${group_code}`
		)
	)
}

export const getCRIFResults = () => (dispatch: any) => {
	return dispatch(get(CRIF_RESULTS, `${getMPSBaseUrl()}/v1/margin-attributions/crif-and-margin?type=CRIF`))
}

export const getMarginResults = () => (dispatch: any) => {
	return dispatch(get(MARGIN_RESULTS, `${getMPSBaseUrl()}/v1/margin-attributions/crif-and-margin?type=MARGIN`))
}

export const updateGraphFields = fieldsList => {
	return {
		type: ATTRIBUTION_GRAPH_FIELDS,
		fieldsList,
	}
}

