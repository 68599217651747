import {createFetchActions} from '../../../services/createActions'
import {post, put} from '../../../services/createAPICall'
import {getConfigBaseUrl} from '../../../services/apiService'
import {getConfigClient} from '../../..'
import {getMyBUsQuery} from './myBUQueries'
import {getCounterpartyId} from '../../../services/authService'

export const GET_MY_BU = createFetchActions('GET_MY_BU')
export const SAVE_MY_BU = createFetchActions('SAVE_MY_BU')
export const EDIT_MY_BU = createFetchActions('EDIT_MY_BU')
export const CLEAR_BU_DATA = createFetchActions('CLEAR_BU_DATA')

export const getMyBUs = () => {
	return function (dispatch) {
		dispatch({type: GET_MY_BU.triggered})
		getConfigClient()
			.query({
				query: getMyBUsQuery(getCounterpartyId()),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_MY_BU.succeeded,
					response,
				})
			})
	}
}

export const saveMyBUs = (data: any) => (dispatch: any) => {
	return dispatch(
		post(SAVE_MY_BU, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/businessunits`, data)
	)
}

export const editMyBUs = (data: any) => (dispatch: any) => {
	return dispatch(
		put(
			EDIT_MY_BU,
			`${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/businessunits/${data.businessUnitId}`,
			data
		)
	)
}

export const clearBuData = () => {
	return {
		type: CLEAR_BU_DATA,
	}
}
