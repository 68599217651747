import {CLEAR_COLLATERAL_BASKET_LIST, GET_COLLATERAL_BASKET_LIST} from './collateralBasketListActions'
import {Basket} from './collateralBasketModel'

export interface CollateralBasketList {
	data: Basket[]
	loading: boolean
}

const initialState: CollateralBasketList = {
	data: [],
	loading: true,
}

export function collateralBasketListReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_COLLATERAL_BASKET_LIST.triggered:
			return {...state, loading: true}
		case GET_COLLATERAL_BASKET_LIST.succeeded:
			let res = action.response.data
			res = (res && res.allCollateralBaskets) || []
			return {...state, loading: false, data: res}
		case GET_COLLATERAL_BASKET_LIST.failed:
			return {...state, loading: false, data: []}

		case CLEAR_COLLATERAL_BASKET_LIST:
			return {...initialState}
	}
	return state
}
