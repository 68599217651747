export const strategyType = {
	REPO: 'Repo',
	SBL: 'SBL',
	SWAP: 'Swap',
	COLLATERAL_UPGRADE: 'Collateral Upgrade',
	COLLATERAL_DOWNGRADE: 'Collateral Downgrade',
	ASSET_SWITCH: 'Asset Switch',
	PURPOSE_BORROW: 'Purpose Borrow',
	INDEX: 'Index',
	ETF_CREATION_REDEMPTION: 'ETF Creation/Redemption',
}

export const productStrategies = [
	{displayName: 'SBL', code: 'SBL'},
	{displayName: 'Asset Switch', code: 'ASSET_SWITCH'},
	{displayName: 'Purpose Borrow', code: 'PURPOSE_BORROW'},
	{displayName: 'Repo', code: 'REPO'},
	{displayName: 'Swap', code: 'SWAP'},
	{displayName: 'Index', code: 'INDEX'},
	{displayName: 'ETF Creation/Redemption', code: 'ETF_CREATION_REDEMPTION'},
	{displayName: 'Collateral Upgrade', code: 'COLLATERAL_UPGRADE'},
	{displayName: 'Collateral Downgrade', code: 'COLLATERAL_DOWNGRADE'},
]

export const bidOfferTypes = {
	CASH: 'CASH',
	COLLATERAL: 'COLLATERAL',
	SINGLESTOCK: 'SINGLE_SECURITY',
}

export const bidOfferTypesFieldValue = {
	[bidOfferTypes.CASH]: 'cash',
	[bidOfferTypes.COLLATERAL]: 'collateral',
	[bidOfferTypes.SINGLESTOCK]: 'singleStock',
}

export const rateTypes = {
	FIXED: 'FIXED',
	FLOATING: 'FLOATING',
}

export const tradeOfferType = {
	SINGLEASSET: 'singleStockAsset',
	ATTACHEDBASKET: 'attachedBasket',
	DECIDELATER: 'decideLater',
}

export const frequencyOfReset = {
	MONTHLY: 'MONTHLY',
	QUARTERLY: 'QUARTERLY',
}

export const bidOfferData = [
	{
		label: 'Bid : Cash / Offer: Collateral',
		value: {
			bidType: bidOfferTypes.CASH,
			offerType: bidOfferTypes.COLLATERAL,
		},
	},
	{
		label: 'Bid : Collateral / Offer: Cash',
		value: {
			bidType: bidOfferTypes.COLLATERAL,
			offerType: bidOfferTypes.CASH,
		},
	},
]

export const singleStockBidOfferData = [
	{
		label: 'Bid : Cash / Offer: Single-Stock',
		value: {
			bidType: bidOfferTypes.CASH,
			offerType: bidOfferTypes.SINGLESTOCK,
		},
	},
	{
		label: 'Bid : Single-Stock / Offer: Cash',
		value: {
			bidType: bidOfferTypes.SINGLESTOCK,
			offerType: bidOfferTypes.CASH,
		},
	},
]

export const collateralUpgradeDowngradeBidOfferDetails = {
	label: 'Bid : Collateral / Offer: Collateral',
	value: {
		bidType: bidOfferTypes.COLLATERAL,
		offerType: bidOfferTypes.COLLATERAL,
	},
}
