import React from 'react'
import {CheckSuccessIcon} from '../../styles/svg/svgIcons'

export const loginToForgetPassword = 'login-to-forget-password'

export const forgotPasswordTitle = {
	passwordResetRequest: 'Reset Password',
	passwordReset: 'Change Password',
}

export const modalMessage = {
	requestSend: {
		headerIcon: <CheckSuccessIcon />,
		bodyTitle: 'Request Send',
		bodyMessage:
			'If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.',
	},
	requestFail: {
		headerIcon: null,
		bodyTitle: null,
		bodyMessage: 'Fail to request for password change.',
	},
	tokenExpire: {
		headerIcon: null,
		bodyTitle: null,
		bodyMessage: 'Your password reset link is invalid or expired. Request again.',
	},
	passwordReset: {
		headerIcon: <CheckSuccessIcon />,
		bodyTitle: 'Password Reset Successful',
		bodyMessage: 'Password has been reset successfully. Kindly login with new password.',
	},
	passwordResetFail: {
		headerIcon: null,
		bodyTitle: null,
		bodyMessage: 'Fail to update your password. Try Again.',
	},
}
