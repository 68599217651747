import {createFetchActions} from '../../services/createActions'
import {dummmyWidgetQuery} from './DummyWidgetQuery'
import {getPMSClient} from '../..'
import {get} from '../../services/createAPICall'
import {getALPBaseUrl} from '../../services/apiService'

export const FETCH_DUMMYWIDGET_DETAILS = createFetchActions('FETCH_DUMMYWIDGET_DETAILS')
export const FETCH_KPI_SUMMARY = createFetchActions('FETCH_KPI_SUMMARY')

export const getWidgetData = () => {
	return function (dispatch) {
		dispatch({type: FETCH_DUMMYWIDGET_DETAILS.triggered})
		getPMSClient()
			.query({
				query: dummmyWidgetQuery(),
				variables: {skipErrorModal: true},
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: FETCH_DUMMYWIDGET_DETAILS.succeeded,
					response,
				})
			})
			.catch(error => {
				dispatch({
					type: FETCH_DUMMYWIDGET_DETAILS.failed,
					error,
				})
			})
	}
}

export const getKPISummary = () => (dispatch: any) => {
	return dispatch(
		get(FETCH_KPI_SUMMARY, `${getALPBaseUrl()}/v1/alp/trades/kpi-summary`, null, null, null, null, 'skipErrorModal')
	)
}
