import {ArrayUtil} from 'helper-util'
import {globalColumnDefinition} from '../../../common/AgGrid/AgGridColumn'
import {noPinnedFooter} from '../../../common/AgGrid/AgGridHelper'
import {formatWithSeparator} from '../../../utils/formatUtils'
import {RejectedReasons} from '../common/Model'
import {
	computeShortFall,
	getSecurityLabel,
	isEligibleToComputeShortfall,
} from '../LocateAuthorizer/LocateAuthorizerHelper'

export const searchLocateClientBucketColumnDefs = (onActionClick, onStatusClick) => [
	Object.assign({}, globalColumnDefinition.checkBoxField, {}),
	Object.assign({}, globalColumnDefinition.actions, {
		cellRendererParams: {mode: 'REQUESTER', onActionClick},
		cellRenderer: 'ActionsCellRenderer',
	}),
	Object.assign({}, globalColumnDefinition.status, {
		cellRendererParams: {},
		cellRenderer: 'StatusCellRenderer',
		minWidth: 130,
		onCellClicked: onStatusClick,
		enableRowGroup: true,
	}),
	Object.assign({}, globalColumnDefinition.security, {
		enableRowGroup: true,
		cellRenderer: params => {
			if (!params.data) {
				return null
			}
			const securityLabel = params.data.ticker || getSecurityLabel(params.data)
			if (!params.data.securityId && securityLabel !== '')
				return `${securityLabel} <i title="Security is not in the security master" class="fa fa-exclamation-triangle text-info text-warning cursor-pointer"></i>`
			return securityLabel
		},
	}),
	Object.assign({}, globalColumnDefinition.authorizer, {
		enableRowGroup: true,
	}),
	Object.assign({}, globalColumnDefinition.requestedQuantity, {
		headerName: 'Requested Qnty',
		aggFunc: 'sum',
	}),
	Object.assign({}, globalColumnDefinition.totalApprovedQuantity, {
		aggFunc: 'sum',
		...noPinnedFooter,
	}),
	Object.assign({}, globalColumnDefinition.shortFall, {
		headerName: 'ShortFall',
		cellRenderer: params => {
			const {data} = params
			return data
				? isEligibleToComputeShortfall(data.status)
					? formatWithSeparator(computeShortFall(data, data.totalApprovedQuantity))
					: ''
				: 0
		},
	}),
	globalColumnDefinition.indicativeRateBps,
	Object.assign({}, globalColumnDefinition.totalMarketFee, {
		aggFunc: 'sum',
		rounding: true,
	}),
	Object.assign({}, globalColumnDefinition.description, {
		headerName: 'Reject Reason',
		minWidth: 100,
		cellRenderer: params => {
			const {data} = params
			return data && data.description ? RejectedReasons[data.description] : ''
		},
	}),
	Object.assign({}, globalColumnDefinition.modifiedDate, {
		headerName: 'Last Updated Date',
		minWidth: 100,
		sort: 'desc',
	}),
	Object.assign({}, globalColumnDefinition.locateRequestEntryId, {
		headerName: 'Locate Request Id',
	}),
	Object.assign({}, globalColumnDefinition.userEmail, {
		minWidth: 100,
	}),
	Object.assign({}, globalColumnDefinition.locateRequestType, {
		enableRowGroup: true,
	}),
	globalColumnDefinition.modifiedDate,
	Object.assign({}, globalColumnDefinition.endDate, {
		headerName: 'End Date (PTH)',
	}),
]

export const searchLocateClientcolumnDefsWithDelete = (
	securityChangeHandler,
	quantityChangeHandler,
	deleteSelectedRow,
	addNewRow,
	endDateObj,
	handleSecurityKeyPress,
	handleQuantityKeyPress
) => {
	if (endDateObj.enableDateColumn) {
		return [
			Object.assign({}, globalColumnDefinition.ticker, {
				headerName: 'Security',
				cellRendererParams: {onChange: securityChangeHandler, onKeyPress: handleSecurityKeyPress},
				cellRenderer: 'SecurityCellRenderer',
				minWidth: 80,
				maxWidth: 120,
				cellClass: ['d-flex align-items-center gridcellPadding ag-show-dropdown'],
			}),
			Object.assign({}, globalColumnDefinition.requestedQuantity, {
				headerName: 'Quantity',
				cellRendererParams: {onChange: quantityChangeHandler, onKeyPress: handleQuantityKeyPress},
				cellRenderer: 'QuantityCellRenderer',
				minWidth: 80,
				maxWidth: 120,
				cellClass: ['d-flex align-items-center justify-content-end gridcellPadding'],
			}),
			Object.assign({}, globalColumnDefinition.endDate, {
				cellRendererParams: {endDateHandler: endDateObj.endDateHandler},
				cellRenderer: 'EndDateCellRenderer',
				minWidth: 110,
				maxWidth: 150,
				suppressNavigable: true,
				cellClass: ['d-flex align-items-center gridcellPadding'],
			}),
			Object.assign({}, globalColumnDefinition.deleteField, {
				pinned: 'right',
				suppressNavigable: true,
				onCellClicked: params => {
					params.value ? deleteSelectedRow(params) : addNewRow()
				},
				cellRenderer: params => {
					if (params.value) {
						return '<i class="fa fa-times cursor-pointer"></i>'
					} else {
						return '<i class="fa fa-plus cursor-pointer"></i>'
					}
				},
				minWidth: 35,
				width: 35,
				maxWidth: 35,
				cellClass: ['d-flex align-items-center justify-content-center'],
			}),
		]
	}
	return [
		Object.assign({}, globalColumnDefinition.ticker, {
			headerName: 'Security',
			cellRendererParams: {onChange: securityChangeHandler, onKeyPress: handleSecurityKeyPress},
			cellRenderer: 'SecurityCellRenderer',
			minWidth: 180,
			maxWidth: 180,
			cellClass: ['d-flex align-items-center gridcellPadding ag-show-dropdown'],
		}),
		Object.assign({}, globalColumnDefinition.requestedQuantity, {
			headerName: 'Quantity',
			cellRendererParams: {onChange: quantityChangeHandler, onKeyPress: handleQuantityKeyPress},
			cellRenderer: 'QuantityCellRenderer',
			minWidth: 200,
			maxWidth: 200,
			cellClass: ['d-flex align-items-center justify-content-end gridcellPadding'],
		}),
		Object.assign({}, globalColumnDefinition.deleteField, {
			pinned: 'right',
			suppressNavigable: true,
			onCellClicked: params => {
				params.value ? deleteSelectedRow(params) : addNewRow()
			},
			cellRenderer: params => {
				if (params.value) {
					return '<i class="fa fa-times cursor-pointer"></i>'
				} else {
					return '<i class="fa fa-plus cursor-pointer"></i>'
				}
			},
			minWidth: 35,
			width: 35,
			maxWidth: 35,
			cellClass: ['d-flex align-items-center justify-content-center'],
		}),
	]
}

export const modifiedData = locateRequestQueueData => {
	return !ArrayUtil.isEmpty(locateRequestQueueData)
		? locateRequestQueueData.map(data => {
				return {
					...data,
					shortFall: data
						? isEligibleToComputeShortfall(data.status)
							? formatWithSeparator(computeShortFall(data, data.totalApprovedQuantity))
							: ''
						: 0,
					disableCheckbox: data.actions ? !(data.actions.includes('ACCEPT') || data.actions.includes('REJECT')) : true,
					id: data.locateRequestEntryId,
				}
		  })
		: []
}

export const getAccepAllRejectAllPayload = (selectedIds, actionType) => {
	return (
		!ArrayUtil.isEmpty(selectedIds) &&
		selectedIds.map(id => {
			return {
				locateRequestEntryId: id,
				action: actionType,
				description: '',
			}
		})
	)
}
