import React from 'react'
import * as Sentry from '@sentry/browser'
import {WarningIcon} from '../../styles/svg/svgIcons'

interface Props {}

interface State {
	hasError: boolean
	eventId: string
}

export default class ErrorWrapper extends React.Component<Props, State> {
	constructor(props) {
		super(props)
		this.state = {hasError: false, eventId: ''}
	}

	static getDerivedStateFromError = error => {
		return {
			hasError: true,
		}
	}

	componentDidCatch = (error, info) => {
		// You can also log the error to an error reporting service
		Sentry.withScope(scope => {
			scope.setExtras(info)
			const eventId = Sentry.captureException(error)
			this.setState({eventId})
		})
	}

	sendFeedback = () => {
		const {eventId} = this.state
		Sentry.showReportDialog({eventId})
	}

	render() {
		if (this.state.hasError) {
			console.error(this.state.hasError)
			// return <ErrorDisplayPage sendFeedback={this.sendFeedback} />
		}
		return this.props.children
	}
}

export const ErrorDisplayPage = props => {
	return (
		<div className='card flex-fill m-5'>
			<div className='m-auto text-center text-danger'>
				<WarningIcon />
				<h3 className='pt-2'>
					<span className='text-primary'>Looks like there might have been an error.</span>
				</h3>
				<div className='f-18 font-weight-600 mb-3'>Sorry for the inconvenience</div>
				<div className='f-16'>
					<div>It would be very helpful if you could provide some feedback regarding the situation</div>
					<div className='btn btn-outline-secondary btn-sm mt-2 mb-3' onClick={props.sendFeedback}>
						Provide Feedback
					</div>
					<div>For more info, please contact your adminstrator</div>
				</div>
			</div>
		</div>
	)
}
