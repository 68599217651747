import React from 'react'
import {field, steps} from '../../../pages/PeerToPeer/OrderEntryModal/OrderEntryModalConstant'
import {strategyType} from '../../../pages/orderEntry/orderEntryConstants'
import {fieldOption} from '../../../pages/PeerToPeer/OrderEntryModal/OrderDetails/OrderDetailsConstant'
import {orderType} from '../../../pages/PeerToPeer/PeerToPeerConstant'
import {VenturiLogoLong, VenturiLogoLargeLong} from '../../../styles/svg/svgIcons'
import {globalColumnDefinition} from '../../../common/AgGrid/AgGridColumn'

export const Statestreet = {
	id: 'stateStreet',
	logo: (
		<div className='header-logo-color text-center f-24'>
			<VenturiLogoLong />
		</div>
	),
	adminToolAllow: true,
	renameAllInRateToRepoRate: true,
	login: {
		logo: (
			<div className='header-logo-color text-center mb-4'>
				<VenturiLogoLargeLong />
			</div>
		),
		fosLink: false,
		fosLogoAtBottom: true,
		carouselDescription: false,
		disableMfa: false,
	},
	navbar: {
		disableThemeSwitch: true,
		disableDefaultHomepage: false,
		disableHelpIcon: false,
		disableDocumentation: true,
		showCookieConsent: true,
	},
	peerToPeer: {
		orderTypeTitle: {
			[orderType.repo]: {label: 'REPO', title: ''},
			[orderType.p2p]: {label: 'P2P', title: 'Peer To Peer'},
			[orderType.ficc]: {label: 'FICC DVP', title: ''},
			[orderType.gcf]: {label: 'FICC GCF', title: ''},
		},
		orderType: [],
		orderEntry: {
			allowFloatingRate: true,
			allowAllInRate: false,
			displayStepsCol: false,
			uploadBasket: true,
			getScheduleForCB: true,
			[field.ORDER_TYPE]: {
				[orderType.repo]: [],
				[orderType.p2p]: [fieldOption.ORDER_TYPE.RFQ],
				[orderType.ficc]: [fieldOption.ORDER_TYPE.IOI],
				[orderType.gcf]: [fieldOption.ORDER_TYPE.IOI],
			},
			[field.STRATEGY]: [
				fieldOption.STRATEGY.ASSET_SWITCH,
				fieldOption.STRATEGY.COLLATERAL_DOWNGRADE,
				fieldOption.STRATEGY.COLLATERAL_UPGRADE,
				fieldOption.STRATEGY.ETF_CREATION_REDEMPTION,
				fieldOption.STRATEGY.INDEX,
				fieldOption.STRATEGY.PURPOSE_BORROW,
				fieldOption.STRATEGY.SBL,
				fieldOption.STRATEGY.SWAP,
			],
			[field.MATURITY_TYPE]: [
				fieldOption.MATURITY_TYPE.CALLABLE,
				fieldOption.MATURITY_TYPE.EVERGREEN,
				fieldOption.MATURITY_TYPE.OPEN,
				fieldOption.MATURITY_TYPE.PUTTABLE,
			],
			[field.RESET_TYPE]: true,
			[field.TERM_STRUCTURE]: false,
			[field.STEPS]: [steps[1].id],
			[field.FLOATINGRATECOMPRESSUI]: true,
			[field.CURRENCIES]: [{id: 'usd', label: 'USD', value: 'USD'}],
		},
		orderDetail: {
			allowCusipCandDValidation: true,
			hideIndeminifyFeeColumn: true,
			appendContractTypeToStrategy: true,
			settlementTab: false,
			collateralColumnDefsField: ['isin', 'cusip', 'rating', 'fitchRating', 'moodyRating', 'isRestricted'],
			termAndCondition: true,
			editableDuration: false,
			disableTradeAndSettleDate: true,
		},
		orderNegotiation: {
			startCashEditable: true,
		},
	},
	abcPortfolio: {
		defaultColumn: false,
		isFetchAllEntriesConfig: true,
	},
	collateralBasket: {
		fetchBasketItems: {
			optimizedCusipTemplate: true,
		},
		defaultStrategy: {
			displayName: 'Repo',
			code: 'REPO',
			direction: [
				{
					displayName: 'Repo',
					code: 'SELL',
				},
			],
		},
		defaultTemplateType: 'CUSIP_SCHEDULE',
		basketCreationInputField: {
			template: true,
			schedule: false,
			currencies: ['USD'],
			clientRiskTypeTitle: 'Counterparty Profile',
		},
		collateralBasketConstruction: {
			strategy: [
				strategyType.SBL,
				strategyType.ASSET_SWITCH,
				strategyType.PURPOSE_BORROW,
				strategyType.SWAP,
				strategyType.INDEX,
				strategyType.ETF_CREATION_REDEMPTION,
				strategyType.COLLATERAL_DOWNGRADE,
				strategyType.COLLATERAL_UPGRADE,
			],
		},
		collateralBasketNegotiation: {
			customSecurityIdColumn: true,
		},
		useEnrichedSecurityQuery: true,
		restrictedColDefs: [globalColumnDefinition.inefficienciesArray, globalColumnDefinition.isRestricted],
	},
	collateralOptimizationDashboard: {
		title: 'Liquidity Optimization Dashboard',
		enableObjectiveWeights: false,
	},
	accountSettings: {
		groups: {
			showTradeType: false,
		},
		clients: {
			isLeDeposAllowed: true,
		},
	},
	repo: {
		tradeTicketButton: true,
	},
	tradeBlotter: {
		strategy: [
			{id: 'REPO', label: 'REPO', value: 'REPO'},
			{id: 'SBL', label: 'SBL', value: 'SBL'},
		],
		fieldDecimal: 4,
		allInPriceFieldDecimal: 6,
	},
	dashboardSettings: {
		enableRollTrade: false,
		enableNewCollateralOptDashBoard: false,
		displayCOFticker: false,
		displaySODticker: false,
		startCashColumnExist: false,
	},
	portfolioUploadConfirm: {
		displayMessage: false,
		displayRestrictedMessage: false,
	},
}
