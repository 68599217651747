import {getActivityTypeQuery} from './ActivityTypeQuery'
import {createFetchActions} from '../../../services/createActions'
import {getPMSGraphBaseUrl} from '../../../services/apiService'
import {graphQlWithAxios} from '../../../services/createAPICall'

export const ACTIVITY_TYPE = createFetchActions('ACTIVITY_TYPE')

export const getActivityTypeDetail = (id: string) => {
	const query = getActivityTypeQuery(id)
	const baseURL = `${getPMSGraphBaseUrl()}`
	return function (dispatch) {
		graphQlWithAxios(dispatch, ACTIVITY_TYPE, baseURL, query, data => {
			const response = data && data.data && data.data.data ? data.data.data.history : {}
			dispatch({
				type: ACTIVITY_TYPE.succeeded,
				response,
			})
		})
	}
}
