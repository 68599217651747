export const viewList = [
	{
		label: 'View All',
		value: 'View All',
	},
	{
		label: 'ABC Source',
		value: 'SOURCE',
	},
	{
		label: 'ABC Use',
		value: 'USE',
	},
]

export const urlEncryptionKey = 'abc-portfolio-url-encrytion-key'

export const defaultSelectedColDefs = ['actions', 'partial', 'activityType', 'allInRate', 'securityId']

export const pivotParams = {
	enableRowGroup: true,
	enablePivot: true,
}

export const aggFuncSum = {
	aggFunc: 'sum',
}
