import gql from 'graphql-tag'

// TODO use fragment
export const getSettlementDataQuery = gql`
	query {
		settlementLadder {
			day
			cashFlow {
				netCashIn {
					amount
					currency
				}
				netCashOut {
					amount
					currency
				}
				netCashFlow {
					amount
					currency
				}
			}
			buys {
				count
				netCashOut {
					amount
					currency
				}
				entries {
					id
					cusip
					securityId
					isin
					ticker
					originalTradeType
					legalEntity
					ric
					bbId
					quantity
					maturityDate
					price {
						amount
						currency
					}
					assetType
					assetSubType
					tradeType
					book
					fund
					buySellInd
					counterParty
					sourceSystem
					sourceSystemTradeId
					allInRate
					spread
					referenceRate
					haircut
					notional {
						amount
						currency
					}
					collateral {
						amount
						currency
					}
					cash {
						amount
						currency
					}
					startDate
					endDate
					termDate
					collateralType
					maturityType
					abcmCategoryName
					abcmCategoryDisplayName
					recallInefficiencyContribution {
						amount
						currency
					}
					returnInefficiencyContribution {
						amount
						currency
					}
					tenor
					hqla
					moodyRating
					snpRating
					fitchRating
					sector
					index
					baseNotional {
						amount
						currency
					}
					basePrice {
						amount
						currency
					}
					partial
					activityType
					portfolioEntryType
					actions
					inefficiencies {
						inefficiency
						abbr
						displayName
					}
				}
				returnImpact {
					impact {
						amount
						currency
					}
					counterParty
					entries {
						id
						cusip
						securityId
						isin
						ticker
						originalTradeType
						legalEntity
						ric
						bbId
						quantity
						maturityDate
						price {
							amount
							currency
						}
						assetType
						assetSubType
						tradeType
						book
						fund
						buySellInd
						counterParty
						sourceSystem
						sourceSystemTradeId
						allInRate
						spread
						referenceRate
						haircut
						notional {
							amount
							currency
						}
						collateral {
							amount
							currency
						}
						cash {
							amount
							currency
						}
						startDate
						endDate
						termDate
						collateralType
						maturityType
						abcmCategoryName
						abcmCategoryDisplayName
						recallInefficiencyContribution {
							amount
							currency
						}
						returnInefficiencyContribution {
							amount
							currency
						}
						tenor
						hqla
						moodyRating
						snpRating
						fitchRating
						sector
						index
						baseNotional {
							amount
							currency
						}
						basePrice {
							amount
							currency
						}
						partial
						activityType
						portfolioEntryType
						actions
						inefficiencies {
							inefficiency
							abbr
							displayName
						}
					}
				}
			}
			sells {
				count
				netCashIn {
					amount
					currency
				}
				entries {
					id
					cusip
					securityId
					isin
					ticker
					originalTradeType
					legalEntity
					ric
					bbId
					quantity
					maturityDate
					price {
						amount
						currency
					}
					assetType
					assetSubType
					tradeType
					book
					fund
					buySellInd
					counterParty
					sourceSystem
					sourceSystemTradeId
					allInRate
					spread
					referenceRate
					haircut
					notional {
						amount
						currency
					}
					collateral {
						amount
						currency
					}
					cash {
						amount
						currency
					}
					startDate
					endDate
					termDate
					collateralType
					maturityType
					abcmCategoryName
					abcmCategoryDisplayName
					recallInefficiencyContribution {
						amount
						currency
					}
					returnInefficiencyContribution {
						amount
						currency
					}
					tenor
					hqla
					moodyRating
					snpRating
					fitchRating
					sector
					index
					baseNotional {
						amount
						currency
					}
					basePrice {
						amount
						currency
					}
					partial
					activityType
					portfolioEntryType
					actions
					inefficiencies {
						inefficiency
						abbr
						displayName
					}
				}
				recallImpact {
					impact {
						amount
						currency
					}
					counterParty
					entries {
						id
						cusip
						securityId
						isin
						ticker
						originalTradeType
						legalEntity
						ric
						bbId
						quantity
						maturityDate
						price {
							amount
							currency
						}
						assetType
						assetSubType
						tradeType
						book
						fund
						buySellInd
						counterParty
						sourceSystem
						sourceSystemTradeId
						allInRate
						spread
						referenceRate
						haircut
						notional {
							amount
							currency
						}
						collateral {
							amount
							currency
						}
						cash {
							amount
							currency
						}
						startDate
						endDate
						termDate
						collateralType
						maturityType
						abcmCategoryName
						abcmCategoryDisplayName
						recallInefficiencyContribution {
							amount
							currency
						}
						returnInefficiencyContribution {
							amount
							currency
						}
						tenor
						hqla
						moodyRating
						snpRating
						fitchRating
						sector
						index
						baseNotional {
							amount
							currency
						}
						basePrice {
							amount
							currency
						}
						partial
						activityType
						portfolioEntryType
						actions
						inefficiencies {
							inefficiency
							abbr
							displayName
						}
					}
				}
			}
			maturing {
				count
				netCashIn {
					amount
					currency
				}
				netCashOut {
					amount
					currency
				}
				sources {
					id
					cusip
					securityId
					isin
					ticker
					originalTradeType
					legalEntity
					ric
					bbId
					quantity
					maturityDate
					price {
						amount
						currency
					}
					assetType
					assetSubType
					tradeType
					book
					fund
					buySellInd
					counterParty
					sourceSystem
					sourceSystemTradeId
					allInRate
					spread
					referenceRate
					haircut
					notional {
						amount
						currency
					}
					collateral {
						amount
						currency
					}
					cash {
						amount
						currency
					}
					startDate
					endDate
					termDate
					collateralType
					maturityType
					abcmCategoryName
					abcmCategoryDisplayName
					recallInefficiencyContribution {
						amount
						currency
					}
					returnInefficiencyContribution {
						amount
						currency
					}
					tenor
					hqla
					moodyRating
					snpRating
					fitchRating
					sector
					index
					baseNotional {
						amount
						currency
					}
					basePrice {
						amount
						currency
					}
					partial
					activityType
					portfolioEntryType
					actions
					inefficiencies {
						inefficiency
						abbr
						displayName
					}
				}
				uses {
					id
					cusip
					securityId
					isin
					ticker
					originalTradeType
					legalEntity
					ric
					bbId
					quantity
					maturityDate
					price {
						amount
						currency
					}
					assetType
					assetSubType
					tradeType
					book
					fund
					buySellInd
					counterParty
					sourceSystem
					sourceSystemTradeId
					allInRate
					spread
					referenceRate
					haircut
					notional {
						amount
						currency
					}
					collateral {
						amount
						currency
					}
					cash {
						amount
						currency
					}
					startDate
					endDate
					termDate
					collateralType
					maturityType
					abcmCategoryName
					abcmCategoryDisplayName
					recallInefficiencyContribution {
						amount
						currency
					}
					returnInefficiencyContribution {
						amount
						currency
					}
					tenor
					hqla
					moodyRating
					snpRating
					fitchRating
					sector
					index
					baseNotional {
						amount
						currency
					}
					basePrice {
						amount
						currency
					}
					partial
					activityType
					portfolioEntryType
					actions
					inefficiencies {
						inefficiency
						abbr
						displayName
					}
				}

				trades {
					endCash {
						amount
						currency
					}
					tradeId
					counterParty
					collateral {
						amount
						currency
					}
					cash {
						amount
						currency
					}
					collateralType
					notional {
						amount
						currency
					}
					baseNotional {
						amount
						currency
					}
					tradeType
					entries {
						id
						cusip
						securityId
						isin
						ticker
						originalTradeType
						legalEntity
						ric
						bbId
						quantity
						maturityDate
						price {
							amount
							currency
						}
						assetType
						assetSubType
						tradeType
						book
						fund
						buySellInd
						counterParty
						sourceSystem
						sourceSystemTradeId
						allInRate
						spread
						referenceRate
						haircut
						notional {
							amount
							currency
						}
						collateral {
							amount
							currency
						}
						cash {
							amount
							currency
						}
						startDate
						endDate
						termDate
						collateralType
						maturityType
						abcmCategoryName
						abcmCategoryDisplayName
						recallInefficiencyContribution {
							amount
							currency
						}
						returnInefficiencyContribution {
							amount
							currency
						}
						tenor
						hqla
						moodyRating
						snpRating
						fitchRating
						sector
						index
						baseNotional {
							amount
							currency
						}
						basePrice {
							amount
							currency
						}
						partial
						activityType
						portfolioEntryType
						actions
						inefficiencies {
							inefficiency
							abbr
							displayName
						}
					}
				}
			}
		}
	}
`
