import Lodash from 'lodash'
import * as React from 'react'
import {formatWithSeparator} from '../../utils/formatUtils'

interface Props {
	value: number
	allowDecimal?: boolean
	allowNegative?: boolean
	autoFocus?: boolean
	className?: string
	disabled?: boolean
	id?: string
	isRate?: number
	skipRateValidation?: boolean
	maxLength?: number
	name?: string
	restrictionValue?: object
	twoDecimal?: boolean
	sixDecimal?: boolean
	stopOnChange?: boolean
	vacant?: boolean
	title?: string
	max?: any
	onBlur?: (value: any) => any
	onKeyPress?: (event: any, value: number) => any
	onKeyUp?: (event: any, value: number) => any
	onKeyDown?: (event: any) => any
	onFocus?: () => any
	onClick?: () => any
}

interface State {
	fieldValue: string
}

export default class FsNumberField extends React.Component<Props, State> {
	static defaultProps = {
		className: 'px-2 form-control max-h-20px max-w-120px',
		autoFocus: false,
		maxLength: 19,
		allowDecimal: false,
		restrictionValue: null,
		max: null,
	}

	constructor(props) {
		super(props)

		const rounded = props.sixDecimal
			? Number.parseFloat(props.value).toFixed(6)
			: props.twoDecimal
			? Number.parseFloat(props.value).toFixed(2)
			: props.value
		const fieldValueAsNumber = props.vacant ? '' : props.allowDecimal ? rounded : Math.round(props.value)
		const fieldValue = props.vacant ? '' : this.formatValuewithCommas(Number(fieldValueAsNumber).toString())

		this.state = {
			fieldValue,
		}
	}

	componentDidUpdate = prevProps => {
		const {value, allowNegative, vacant} = this.props

		if (value !== undefined && !Lodash.isEqual(prevProps.value, value)) {
			this.setState({
				fieldValue: vacant
					? ''
					: allowNegative && value < 1
					? String(value)
					: this.formatValuewithCommas(Number(value).toString()) || '0',
			})
		}
		if (!Lodash.isEqual(prevProps.vacant, vacant) && vacant) {
			this.setState({
				fieldValue: '',
			})
		} else if (!Lodash.isEqual(prevProps.vacant, vacant) && !vacant) {
			this.setState({
				fieldValue: vacant
					? ''
					: allowNegative && value < 1
					? String(value)
					: this.formatValuewithCommas(Number(value).toString()) || '0',
			})
		}
	}

	handleChange = (event: any) => {
		const {allowDecimal, isRate, skipRateValidation, stopOnChange, allowNegative, max} = this.props

		const decimalPattern = allowDecimal ? /^\d*\.?\d*$/ : /^[0-9]*$/g

		let inputValue = event.target.value.replace(/,/g, '')

		let negative = false
		if (inputValue.includes('-')) {
			negative = true
			inputValue = inputValue.substr(1)
		}

		if ((inputValue === '' || decimalPattern.test(inputValue)) && !stopOnChange) {
			let fieldValue = this.formatValuewithCommas(inputValue)
			if (!skipRateValidation && Boolean(isRate) && isRate > 0) {
				if (Number(inputValue) >= 1000) {
					fieldValue = '999'
				}
				if (max && Number(inputValue) >= max) {
					fieldValue = this.formatValuewithCommas(String(max))
				}
			}
			if (allowNegative && negative) {
				fieldValue = inputValue === '' ? '-' : `-${fieldValue}`
			}
			this.setState({fieldValue})
		} else if (event.target.value === '0-' || event.target.value === '-0') {
			this.setState({fieldValue: '-'})
		}
	}

	formatValuewithCommas(value: string): string {
		const {isRate, twoDecimal, sixDecimal} = this.props

		const splittedValues = value.split('.')
		const formattedValue = Number(splittedValues[0]) <= 0 ? splittedValues[0] : formatWithSeparator(splittedValues[0])
		return splittedValues[1] !== undefined
			? sixDecimal && splittedValues[1].length > 6
				? Number.parseFloat(value).toFixed(6).toString()
				: twoDecimal && splittedValues[1].length > 2
				? Number.parseFloat(value).toFixed(2).toString()
				: Boolean(isRate) && isRate > 0
				? `${formattedValue}.${splittedValues[1].substring(0, isRate)}`
				: `${formattedValue}.${splittedValues[1]}`
			: formattedValue
	}

	handleOnBlur = () => {
		const {fieldValue} = this.state
		const fieldValueAsNumber = Number(fieldValue.replace(/,/g, ''))
		this.props.onBlur(fieldValueAsNumber)
	}

	handleOnKeyDown = event => {
		const {onKeyDown} = this.props
		if (onKeyDown) {
			onKeyDown(event)
		}
	}

	handleOnKeyPress = (event: any) => {
		const {fieldValue} = this.state

		const fieldValueAsNumber = Number(fieldValue.replace(/,/g, ''))
		if (this.props.onKeyPress) {
			this.props.onKeyPress(event, fieldValueAsNumber)
		}
	}

	handleOnKeyUp = (event: any) => {
		const {fieldValue} = this.state

		const fieldValueAsNumber = Number(fieldValue.replace(/,/g, ''))
		if (this.props.onKeyPress) {
			this.props.onKeyPress(event, fieldValueAsNumber)
		}
		if (this.props.onKeyUp) {
			this.props.onKeyUp(event, Number(this.state.fieldValue))
		}
	}

	handleOnFocus = _event => {
		const {onFocus} = this.props
		if (onFocus) onFocus()
	}
	handleOnClick = _event => {
		const {onClick} = this.props
		if (onClick) onClick()
	}

	render() {
		const {autoFocus, className, disabled, id, maxLength, name, title} = this.props
		const {restrictionValue} = this.props
		const {fieldValue} = this.state
		const showValue = restrictionValue
			? restrictionValue['max'] < fieldValue
				? restrictionValue['max']
				: restrictionValue['min'] > fieldValue
				? restrictionValue['min']
				: fieldValue
			: fieldValue
		const validShowValue = ['null', 'undefined'].includes(showValue) ? '' : showValue
		return (
			<input
				id={id}
				type='text'
				value={validShowValue}
				title={title}
				onBlur={this.handleOnBlur}
				className={`${className} ${disabled ? 'cursor-not-allowed' : ''}`}
				autoFocus={autoFocus}
				onChange={this.handleChange}
				maxLength={maxLength}
				name={name}
				disabled={disabled}
				onKeyPress={this.handleOnKeyPress}
				onKeyUp={this.handleOnKeyUp}
				onKeyDown={this.handleOnKeyDown}
				onFocus={this.handleOnFocus}
				onClick={this.handleOnClick}
			/>
		)
	}
}
