import React, {lazy, Suspense} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {history} from '../store/mainStore'
import FsLoader from '../common/fsLoader/fsLoaderContainer'
import {isAuthenticated} from '../services/authService'
import {routes, paths, protectedRouteList} from './routesConfig'
import AccessDenied from '../pages/accessDenied/accessDeniedComponent'
import ForgotPassword from '../pages/forgotPassword/forgotPasswordContainer'
import ErrorWrapper from '../pages/errorHandler/errorWrapper'
import {setStringInStorage, getStringFromStorage} from '../services/storageService'
import {checkRoutePermission} from '../utils/featureUtils'

const Login = lazy(() => import('../pages/login/loginContainer'))

export default class Routes extends React.Component<any, any> {
	renderRoutes(routes: Array<any>) {
		let routeList: Array<any> = []
		routes.forEach(({component: Component, path, childRoutes, ...rest}) => {
			routeList.push(
				<Route
					exact
					key={path}
					path={path}
					render={props => {
						let combinedProps = {...rest, ...props}
						return (
							<ErrorWrapper>
								<Component {...combinedProps} />
							</ErrorWrapper>
						)
					}}
					{...rest}
				/>
			)
			if (childRoutes && childRoutes.length > 0) {
				routeList = routeList.concat(this.renderRoutes(childRoutes))
			}
		})
		return routeList
	}

	render() {
		const {activePath} = this.props
		const {pathname, search} = history.location

		const isLoggedIn = isAuthenticated()
		const tenant = getStringFromStorage('tenant')

		const key = Object.keys(protectedRouteList).filter(key => protectedRouteList[key].path === activePath)[0]
		const hasRouteAccess = checkRoutePermission(protectedRouteList[key])

		if (!isLoggedIn && ![paths.default, paths.login, paths.forgotPassword, paths.resetPassword].includes(pathname)) {
			setStringInStorage('redirect_to', `${pathname}${search}`)
		}

		return (
			<Suspense fallback={<FsLoader />}>
				<Switch>
					<Route path={paths.login} component={Login} />
					<Route path={paths.forgotPassword} component={ForgotPassword} />
					<Route path={paths.resetPassword} component={ForgotPassword} />
					<Route path={paths.accessDenied} component={AccessDenied} />
					{!isLoggedIn && <Redirect to={`${paths.login}${tenant && '?tenant_code=' + tenant}`} />}
					{!hasRouteAccess && <Redirect to={paths.accessDenied} />}
					{isLoggedIn && hasRouteAccess && this.renderRoutes(routes)}
				</Switch>
			</Suspense>
		)
	}
}
