import {getPMSClient} from '../..'
import {createFetchActions} from '../../services/createActions'
import {portfolioStatus} from './portfolioStatusQueries'

export const GET_PORTFOLIO_STATUS = createFetchActions('GET_PORTFOLIO_STATUS')

export const getPortfolioStatus = () => {
	const query = portfolioStatus
	return function (dispatch) {
		dispatch({
			type: GET_PORTFOLIO_STATUS.triggered,
		})
		getPMSClient()
			.query({
				query,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_PORTFOLIO_STATUS.succeeded,
					response,
				})
			})
			.catch(error =>
				dispatch({
					type: GET_PORTFOLIO_STATUS.failed,
					error,
				})
			)
	}
}
