import React, {useState} from 'react'
import {CloseIcon, WarningIcon} from '../../styles/svg/svgIcons'
import {getDefaultAvailableMenuOptions} from './customiseMenuHelper'
import {getAvailableMenuOptions, getLayerBasedOptions, getUniqueLayers} from './customiseMenuHelper'
import _ from 'lodash'
import VerticallyCenteredModal from '../../pages/styleGuide/fsModal'

const MenuItems = props => {
	const [showWarningModal, setShowWarningModal] = useState(false)
	const {
		allMenuData,
		handleCustomiseMenuClose,
		activeNavigationGroup = {},
		selectedMenuOptions,
		menuCreationInProgress,
		handleSelectedMenuShift,
		handleAvailableMenuShift,
		handleReset,
		handleSubmit,
	} = props
	const availableMenuOptions = getAvailableMenuOptions(getDefaultAvailableMenuOptions(), selectedMenuOptions)
	const uniqueSelectedLayers = getUniqueLayers(selectedMenuOptions)
	const uniqueAvailableLayers = getUniqueLayers(availableMenuOptions)
	const isSelectedItemsEmpty = selectedMenuOptions.length === 0
	return (
		<>
			{menuCreationInProgress ? (
				<div className='h-100 d-flex flex-column align-items-center justify-content-center flex-two'>
					<div className='f-16 font-weight-bold'>Creating New Menu</div>
					<div className='f-13'>
						Customization option will be available <br /> once you have created the new menu
					</div>
					<span className='customize-close-icon' onClick={handleCustomiseMenuClose}>
						<CloseIcon />
					</span>
				</div>
			) : _.isEmpty(activeNavigationGroup) ? (
				<div className='h-100 d-flex flex-column align-items-center justify-content-center flex-two'>
					<div className='f-16 font-weight-bold'>Please create a new menu.</div>
					<div className='f-13'>
						Customization option will be available <br /> once you have created the new menu
					</div>
					<span className='customize-close-icon' onClick={handleCustomiseMenuClose}>
						<CloseIcon />
					</span>
				</div>
			) : (
				<div className='d-flex flex-column flex-two customize-menu-box'>
					<div className='d-flex justify-content-between'>
						<div className='font-weight-bold f-18 customize-menu-header px-22 py-30'>
							{`Customize`} <span className=''>{activeNavigationGroup.name || ''}</span>
						</div>
						<span className='customize-close-icon' onClick={handleCustomiseMenuClose}>
							<CloseIcon />
						</span>
					</div>
					<div className='d-flex flex-fill overflow-hidden'>
						<div className='selected-menu d-flex flex-column  border-right flex-one'>
							<div className='border-top border-bottom px-22 py-30 py-2 font-weight-bold'>Selected Menu</div>
							{getLayerBasedOptions(uniqueSelectedLayers, 'right', handleSelectedMenuShift)}
						</div>
						<div className='available-menu d-flex flex-column flex-one'>
							<div className='border-top border-bottom px-22 py-30 py-2 font-weight-bold'>Available Menu</div>
							{getLayerBasedOptions(uniqueAvailableLayers, 'left', handleAvailableMenuShift)}
						</div>
					</div>
					<div className='d-flex justify-content-between p-3 border-top'>
						<div>
							<button
								className='btn btn-sm btn-outline-dark'
								onClick={() => {
									const isNewMenuCreationInProgress = allMenuData && allMenuData.some(item => item.isMenuNew)
									if (isNewMenuCreationInProgress) {
										setShowWarningModal(true)
									} else {
										handleCustomiseMenuClose()
									}
								}}
							>
								Cancel
							</button>
							<button className='btn btn-sm btn-secondary ml-20' disabled={isSelectedItemsEmpty} onClick={handleReset}>
								Reset
							</button>
						</div>
						<div>
							<button className='btn btn-sm btn-secondary ml-20' disabled={isSelectedItemsEmpty} onClick={handleSubmit}>
								Apply
							</button>
						</div>
					</div>
				</div>
			)}
			{showWarningModal && (
				<VerticallyCenteredModal
					className='modal-customize-menu-applied'
					size='md'
					headerEl={<div></div>}
					bodyEl={
						<div className='text-center mt-4'>
							<span className='d-block m-auto text-danger'>
								<WarningIcon />
							</span>
							<span className='d-block f-20 my-3'>
								Your changes have not been saved. Are you sure you want to exit?
							</span>
						</div>
					}
					footerEl={
						<div className='text-center pb-4 pt-3'>
							<button
								className='btn btn-secondary'
								onClick={() => {
									setShowWarningModal(false)
								}}
							>
								No
							</button>
							<button
								className='ml-4 btn btn-secondary'
								onClick={() => {
									handleCustomiseMenuClose()
								}}
							>
								Yes
							</button>
						</div>
					}
					show={showWarningModal}
					onHide={() => {
						setShowWarningModal(false)
					}}
				/>
			)}
		</>
	)
}

export default MenuItems
