import {get, put} from '../../../services/createAPICall'
import {getConfigBaseUrl} from '../../../services/apiService'
import {createFetchActions} from '../../../services/createActions'

export const GET_USER_TENANT_DATA = createFetchActions('GET_USER_TENANT_DATA')
export const UPDATE_TENANT_ENTRY = createFetchActions('UPDATE_TENANT_ENTRY')

export const getUserTenantConfig = () => (dispatch: any) => {
	return dispatch(get(GET_USER_TENANT_DATA, `${getConfigBaseUrl()}/v1/tenants/`))
}

export const updateTenantEntry = data => (dispatch: any) => {
	return dispatch(put(UPDATE_TENANT_ENTRY, `${getConfigBaseUrl()}/v1/tenants/preferred`, data))
}
