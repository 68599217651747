import Lodash from 'lodash'
import {modifiedLegalEntity} from '../myLegalEntities/myLegalEntityHelper'
import {
	GET_MY_CLIENTS,
	EDIT_MY_CLIENTS,
	SAVE_MY_CLIENTS,
	GET_RELATIONSHIP_TYPES,
	GET_ASSETS,
	GET_CONTRACT_TYPES,
} from './myClientsActions'
import {CLEAR_SELECTED_CLIENT, GET_SELECTED_CLIENT, GET_ALL_CLIENTS} from './myClientsActions'
import {GET_DEPOS_TYPES, GET_AGREEMENT_PROPERTIES, GET_CLIENT_RISK_TYPES, GET_CLIENT_TYPES} from './myClientsActions'
import {GET_COLLATERAL_TYPES, GET_LIMIT_TYPES, GET_LIMIT_CATEGORIES} from './myClientsActions'
import {GET_ASSET_TYPES, GET_AGREEMENT_TYPES, SEARCH_LEGAL_ENTITY, SEARCH_LEGAL_ENTITY_DETAIL} from './myClientsActions'
import {SET_SELECTED_LEGAL_SEARCH, GET_SUB_ACCOUNT_TYPES} from './myClientsActions'
import {MyClient, Options} from './myClientsModel'
import {Modules} from '../userGroups/userGroupModel'
import {defaultMyClients} from './myClientsHelper'

export interface MyClientsData {
	assets: any[]
	assetTypeList: Options[]
	agreementTypeList: Options[]
	agreementPropertiesList: any[]
	clientsRiskTypeList: Options[]
	clientsTypeList: Options[]
	collateralTypeList: Options[]
	contractTypeList: Options[]
	data: MyClient[]
	deposList: any[]
	isModalLoading: boolean
	loading: boolean
	limitTypeList: Options[]
	limitCategoriesList: string[]
	liquidityTypeList: Options[]
	legalEntity: any
	legalEntityDetail: any
	modules: Modules[]
	relationshipTypeList: string[]
	selectedLESearch: any
	selectedClient: MyClient
	allClients: any[]
	subAccountTypeList: any[]
}

const initialState: MyClientsData = {
	assets: [],
	assetTypeList: [],
	agreementTypeList: [],
	agreementPropertiesList: [],
	clientsRiskTypeList: [],
	clientsTypeList: [],
	collateralTypeList: [],
	contractTypeList: [],
	data: [],
	deposList: [],
	isModalLoading: false,
	loading: false,
	limitTypeList: [],
	limitCategoriesList: [],
	liquidityTypeList: [],
	legalEntity: {loading: false, data: []},
	legalEntityDetail: {loading: false, data: null},
	modules: [],
	relationshipTypeList: [],
	selectedLESearch: null,
	selectedClient: defaultMyClients(),
	allClients: [],
	subAccountTypeList: [],
}

export function myClientsReducer(state = initialState, action: any) {
	let data: MyClient[] = []
	switch (action.type) {
		case GET_MY_CLIENTS.triggered:
			return {...state, loading: true}
		case GET_MY_CLIENTS.succeeded:
			data = (action.response.data && action.response.data.allClients) || []
			return {...state, loading: false, data}
		case GET_MY_CLIENTS.failed:
			return {...state, loading: false}

		case CLEAR_SELECTED_CLIENT:
			return {...state, selectedClient: defaultMyClients()}

		case GET_SELECTED_CLIENT.triggered:
			return {...state, isModalLoading: true}
		case GET_SELECTED_CLIENT.succeeded:
			const selectedClient = action.response.data
			return {...state, isModalLoading: false, selectedClient}
		case GET_SELECTED_CLIENT.failed:
			return {...state, isModalLoading: false}

		case SAVE_MY_CLIENTS.triggered:
			return {...state, loading: true}
		case SAVE_MY_CLIENTS.succeeded:
		case SAVE_MY_CLIENTS.failed:
			return {...state, loading: false}

		case EDIT_MY_CLIENTS.triggered:
			return {...state, loading: true}
		case EDIT_MY_CLIENTS.succeeded:
		case EDIT_MY_CLIENTS.failed:
			return {...state, loading: false}

		case SEARCH_LEGAL_ENTITY.triggered:
			return {...state, legalEntity: {loading: true, data: null}}
		case SEARCH_LEGAL_ENTITY.succeeded:
			return {...state, legalEntity: modifiedLegalEntity({loading: false, data: action.response.data})}
		case SEARCH_LEGAL_ENTITY.failed:
			return {...state, legalEntity: {loading: false, data: []}}

		case SEARCH_LEGAL_ENTITY_DETAIL.triggered:
			return {...state, legalEntityDetail: {loading: true, data: null}}
		case SEARCH_LEGAL_ENTITY_DETAIL.succeeded:
			return {...state, legalEntityDetail: {loading: false, data: action.response.data}}
		case SEARCH_LEGAL_ENTITY_DETAIL.failed:
			return {...state, legalEntityDetail: {loading: false, data: []}}

		case SET_SELECTED_LEGAL_SEARCH:
			const selectedDetails = action.payload
			const updatedLegalEntity = modifiedLegalEntity(Lodash.cloneDeep(state.legalEntity), selectedDetails, false)
			return {...state, selectedLESearch: selectedDetails, legalEntity: Lodash.cloneDeep(updatedLegalEntity)}

		case GET_DEPOS_TYPES.triggered:
			return {...state, isModalLoading: true, deposList: []}
		case GET_DEPOS_TYPES.succeeded:
			return {...state, isModalLoading: false, deposList: action.response.data}
		case GET_DEPOS_TYPES.failed:
			return {...state, isModalLoading: false}

		case GET_AGREEMENT_TYPES.triggered:
			return {...state, isModalLoading: true, agreementTypeList: []}
		case GET_AGREEMENT_TYPES.succeeded:
			return {
				...state,
				isModalLoading: false,
				agreementTypeList: action.response.data,
			}
		case GET_AGREEMENT_TYPES.failed:
			return {...state, isModalLoading: false}

		case GET_AGREEMENT_PROPERTIES.triggered:
			return {...state, isModalLoading: true, agreementPropertiesList: []}
		case GET_AGREEMENT_PROPERTIES.succeeded:
			return {...state, isModalLoading: false, agreementPropertiesList: action.response.data}
		case GET_AGREEMENT_PROPERTIES.failed:
			return {...state, isModalLoading: false}

		case GET_ASSET_TYPES.triggered:
			return {...state, isModalLoading: true, assetTypeList: []}
		case GET_ASSET_TYPES.succeeded:
			return {...state, isModalLoading: false, assetTypeList: action.response.data}
		case GET_ASSET_TYPES.failed:
			return {...state, isModalLoading: false}

		case GET_ASSETS.triggered:
			return {...state, loading: true, assets: []}
		case GET_ASSETS.succeeded:
			return {...state, loading: false, assets: action.response.data}
		case GET_ASSETS.failed:
			return {...state, loading: false}

		case GET_CLIENT_RISK_TYPES.triggered:
			return {...state, isModalLoading: true, clientsRiskTypeList: []}
		case GET_CLIENT_RISK_TYPES.succeeded:
			return {...state, isModalLoading: false, clientsRiskTypeList: action.response.data}
		case GET_CLIENT_RISK_TYPES.failed:
			return {...state, isModalLoading: false}

		case GET_CLIENT_TYPES.triggered:
			return {...state, isModalLoading: true, clientsTypeList: []}
		case GET_CLIENT_TYPES.succeeded:
			return {...state, isModalLoading: false, clientsTypeList: action.response.data}
		case GET_CLIENT_TYPES.failed:
			return {...state, isModalLoading: false}

		case GET_COLLATERAL_TYPES.triggered:
			return {...state, isModalLoading: true, collateralTypeList: []}
		case GET_COLLATERAL_TYPES.succeeded:
			return {...state, isModalLoading: false, collateralTypeList: action.response.data}
		case GET_COLLATERAL_TYPES.failed:
			return {...state, isModalLoading: false}

		case GET_CONTRACT_TYPES.triggered:
			return {...state, isModalLoading: true, contractTypeList: []}
		case GET_CONTRACT_TYPES.succeeded:
			return {
				...state,
				isModalLoading: false,
				contractTypeList: action.response.data,
			}
		case GET_CONTRACT_TYPES.failed:
			return {...state, isModalLoading: false}

		case GET_LIMIT_TYPES.triggered:
			return {...state, isModalLoading: true, limitTypeList: []}
		case GET_LIMIT_TYPES.succeeded:
			return {...state, isModalLoading: false, limitTypeList: action.response.data}
		case GET_LIMIT_TYPES.failed:
			return {...state, isModalLoading: false}

		case GET_LIMIT_CATEGORIES.triggered:
			return {...state, isModalLoading: true, limitCategoriesList: []}
		case GET_LIMIT_CATEGORIES.succeeded:
			return {...state, isModalLoading: false, limitCategoriesList: action.response.data}
		case GET_LIMIT_CATEGORIES.failed:
			return {...state, isModalLoading: false}

		case GET_RELATIONSHIP_TYPES.triggered:
			return {...state, isModalLoading: true, relationshipTypeList: []}
		case GET_RELATIONSHIP_TYPES.succeeded:
			return {...state, isModalLoading: false, relationshipTypeList: action.response.data}
		case GET_RELATIONSHIP_TYPES.failed:
			return {...state, isModalLoading: false}

		case GET_SUB_ACCOUNT_TYPES.triggered:
			return {...state, subAccountTypeList: []}
		case GET_SUB_ACCOUNT_TYPES.succeeded:
			return {...state, subAccountTypeList: action.response.data}
		case GET_SUB_ACCOUNT_TYPES.failed:
			return {...state, subAccountTypeList: []}

		case GET_ALL_CLIENTS.triggered:
			return {...state}
		case GET_ALL_CLIENTS.failed:
			return {...state}
		case GET_ALL_CLIENTS.succeeded:
			const allClients = action.response.data && action.response.data
			return {...state, allClients}
	}
	return state
}
