import {getPMSClient} from '../..'
import {createFetchActions} from '../../services/createActions'

import {fetchTemplateBodyQuery, fetchCollateralScheduleDropDowns, fetchtemplateList} from './CollateralScheduleQuery'

import {post, put} from '../../services/createAPICall'
import {getPMSBaseUrl} from '../../services/apiService'

export const FETCH_TEMPLATE_LIST_RESULT = createFetchActions('FETCH_TEMPLATE_LIST_RESULT')
export const FETCH_RULESET_ENTRIES_RESULT = createFetchActions('FETCH_RULESET_ENTRIES_RESULT')
export const FETCH_COLLATERAL_SCHEDULE_DROPDOWN_RESULT = createFetchActions('FETCH_COLLATERAL_SCHEDULE_DROPDOWN_RESULT')

export const SAVE_RULESET_DATA = createFetchActions('SAVE_RULESET_DATA')
export const SAVE_TEMPLATE_DATA = createFetchActions('SAVE_TEMPLATE_DATA')
export const UPDATE_DMN_TEMPLATE = createFetchActions('UPDATE_DMN_TEMPLATE')

export const ADD_RULE_TO_RULESET_GRID = 'ADD_RULE_TO_RULESET_GRID'
export const RESET_RULESET_ENTRIES = 'RESET_RULESET_ENTRIES'

export const restRulesetEntries = savedCollateralScheduleDataTemp => {
	return {type: RESET_RULESET_ENTRIES}
}

export const addNewRulesToRulesetGrid = (currentSetOfRules, newSetOfRule) => {
	return {type: ADD_RULE_TO_RULESET_GRID}
}

export const saveRulesetData = (data: any) => (dispatch: any) => {
	return dispatch(post(SAVE_RULESET_DATA, `${getPMSBaseUrl()}/v1/collateral-schedule/rules`, data))
}

export const saveTemplateData = (data: any) => (dispatch: any) => {
	try {
		if (data.templateBody.indexOf('<dmn:annotationEntry><dmn:text>') > 0) {
			data.templateBody = data.templateBody.replaceAll('<dmn:annotationEntry><dmn:text>', '<description>')
			data.templateBody = data.templateBody.replaceAll('</dmn:text></dmn:annotationEntry>', '</description>')
		}
	} catch {}
	return dispatch(
		post(
			SAVE_TEMPLATE_DATA,
			`${getPMSBaseUrl()}/v1/dmn/templates`,
			data,
			null,
			'Error occured while saving DMN template'
		)
	)
}

export const updateDMNTemplate = (data: any, id: any) => (dispatch: any) => {
	// let updatedXML = xml
	try {
		if (data.templateBody.indexOf('<dmn:annotationEntry><dmn:text>') > 0) {
			data.templateBody = data.templateBody.replaceAll('<dmn:annotationEntry><dmn:text>', '<description>')
			data.templateBody = data.templateBody.replaceAll('</dmn:text></dmn:annotationEntry>', '</description>')
		}
	} catch {}
	return dispatch(
		put(
			UPDATE_DMN_TEMPLATE,
			`${getPMSBaseUrl()}/v1/dmn/templates/${id}/`,
			data,
			null,
			'Error occured while updating DMN template'
		)
	)
}

export const getRulesetListRequestResult = data => {
	return function (dispatch) {
		dispatch({
			type: FETCH_TEMPLATE_LIST_RESULT.triggered,
		})
		getPMSClient()
			.query({
				query: fetchtemplateList(),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: FETCH_TEMPLATE_LIST_RESULT.succeeded,
					response,
				})
			})
	}
}

export const getTemplateBody = selectedRulesetId => {
	return function (dispatch) {
		dispatch({
			type: FETCH_RULESET_ENTRIES_RESULT.triggered,
		})
		getPMSClient()
			.query({
				query: fetchTemplateBodyQuery(selectedRulesetId),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: FETCH_RULESET_ENTRIES_RESULT.succeeded,
					response,
				})
			})
	}
}

export const getCollateralScheduleDropDowns = () => {
	return function (dispatch) {
		dispatch({
			type: FETCH_COLLATERAL_SCHEDULE_DROPDOWN_RESULT.triggered,
		})
		getPMSClient()
			.query({
				query: fetchCollateralScheduleDropDowns(),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: FETCH_COLLATERAL_SCHEDULE_DROPDOWN_RESULT.succeeded,
					response,
				})
			})
	}
}
