import {getConfigClient} from '../..'
import {getMPSBaseUrl} from '../../services/apiService'
import {createFetchActions} from '../../services/createActions'
import {get, post, del} from '../../services/createAPICall'
import {getMyClientsQuery} from '../accountSettingsRevamp/myClients/myClientsQueries'

export const CREATE_BASKET = createFetchActions('CREATE_BASKET')
export const CLEAR_DYNAMIC_FORM_DETAIL = createFetchActions('CLEAR_DYNAMIC_FORM_DETAIL')
export const CLEAR_PRODUCT_TYPE_SUBTYPE = createFetchActions('CLEAR_PRODUCT_TYPE_SUBTYPE')
export const GET_DYNAMIC_FORM_DETAIL = createFetchActions('GET_DYNAMIC_FORM_DETAIL')
export const GET_BASE_FORM_DETAIL = createFetchActions('GET_BASE_FORM_DETAIL')
export const GET_ALL_BASKETS = createFetchActions('GET_ALL_BASKETS')
export const GET_ALL_MARGIN_COMPARISON = createFetchActions('GET_ALL_MARGIN_COMPARISON')
export const GET_ALL_TRADES = createFetchActions('GET_ALL_TRADES')
export const UPDATE_TRADE_BASKET = createFetchActions('UPDATE_TRADE_BASKET')
export const GET_TRADE_BASKET = createFetchActions('GET_TRADE_BASKET')
export const ADD_FORECAST_TRADES = createFetchActions('ADD_FORECAST_TRADES')
export const UPDATE_FORECAST_TRADES_LIST = createFetchActions('UPDATE_FORECAST_TRADES_LIST')
export const CLEAR_TRADE_BASKET = createFetchActions('CLEAR_TRADE_BASKET')
export const EDIT_DYNAMIC_FORM = createFetchActions('EDIT_DYNAMIC_FORM')
export const GET_SAVED_TRADES = createFetchActions('GET_SAVED_TRADES')
export const GET_ALL_SOD_TRADES = createFetchActions('GET_ALL_SOD_TRADES')
export const DOWNLOAD_TRADE_DOCUMENT = createFetchActions('DOWNLOAD_TRADE_DOCUMENT')
export const GET_MARGIN_COMPARISON = createFetchActions('GET_MARGIN_COMPARISON')
export const GET_PRODUCT_TYPE_AND_SUB_TYPE = createFetchActions('GET_PRODUCT_TYPE_AND_SUB_TYPE')
export const GET_PRODUCT_RISK_CLASSES = createFetchActions('GET_PRODUCT_RISK_CLASSES')
export const INVOKE_MARGIN_COMPARISON = createFetchActions('INVOKE_MARGIN_COMPARISON')
export const SAVE_DYNAMIC_FORM_DETAIL = createFetchActions('SAVE_DYNAMIC_FORM_DETAIL')
export const SAVE_BASE_TRADE_FORM_DETAIL = createFetchActions('SAVE_BASE_TRADE_FORM_DETAIL')
export const SET_MARGIN_COMPARISON_CONTEXT = createFetchActions('SET_MARGIN_COMPARISON_CONTEXT')
export const DELETE_TRADE_BY_ID = createFetchActions('DELETE_TRADE_BY_ID')
export const GET_ALL_BASE_TRADES = createFetchActions('GET_ALL_BASE_TRADES')
export const DELETE_BASE_TRADE_BY_ID = createFetchActions('DELETE_BASE_TRADE_BY_ID')
export const GET_MY_COUNTERPARTIES = createFetchActions('GET_MY_COUNTERPARTIES')
export const ADD_TRADE_BASKET = createFetchActions('ADD_TRADE_BASKET')
export const DELETE_TRADE_BASKET_BY_ID = createFetchActions('DELETE_TRADE_BASKET_BY_ID')
export const SAVE_PRE_TRADE_FORM_DETAIL = createFetchActions('SAVE_PRE_TRADE_FORM_DETAIL')
export const CONVERT_BASE_TO_WHATIF_TRADE = createFetchActions('CONVERT_BASE_TO_WHATIF_TRADE')

export const getProductRiskClasses = () => (dispatch: any) => {
	return dispatch(get(GET_PRODUCT_RISK_CLASSES, `${getMPSBaseUrl()}/v1/margin-analytics/products/risk-classes`))
}
export const SAVE_FORECAST_TRADE_FORM_DETAIL = createFetchActions('SAVE_FORECAST_TRADE_FORM_DETAIL')

export const getAllCounterParty = () => {
	return function (dispatch) {
		dispatch({type: GET_MY_COUNTERPARTIES.triggered})
		getConfigClient()
			.query({
				query: getMyClientsQuery,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_MY_COUNTERPARTIES.succeeded,
					response,
				})
			})
	}
}

export const getProductTypeAndSubTypeBasedOnRiskClasses = (riskClasses: string) => (dispatch: any) => {
	return dispatch(
		get(GET_PRODUCT_TYPE_AND_SUB_TYPE, `${getMPSBaseUrl()}/v1/margin-analytics/products?risk_class=${riskClasses}`)
	)
}

export const getProductTypeAndSubType = () => (dispatch: any) => {
	return dispatch(get(GET_PRODUCT_TYPE_AND_SUB_TYPE, `${getMPSBaseUrl()}/v1/margin-analytics/products`))
}

export const clearDynamicFormDetail = () => {
	return {type: CLEAR_DYNAMIC_FORM_DETAIL}
}

export const clearProductTypeAndSubType = () => {
	return {type: CLEAR_PRODUCT_TYPE_SUBTYPE}
}

export const getDynamicFormDetail =
	(
		productType: string,
		productSubType: string,
		selectedFundCode: string = '',
		selectedPortfolioCode: string = '',
		tradetype1Val: string = '',
		tradeType2Val: string = '',
		counterparty_code: any = false
	) =>
	(dispatch: any) => {
		var url = `${getMPSBaseUrl()}/v1/margin-analytics/products/definitions/${productType}/${productSubType}?fund_code=${selectedFundCode}&portfolio_code=${selectedPortfolioCode}&trade_type_1=${tradetype1Val}&trade_type_2=${tradeType2Val}`
		url = counterparty_code ? url + `&counterparty_code=${counterparty_code}` : url + ''
		return dispatch(get(GET_DYNAMIC_FORM_DETAIL, url))
	}

export const saveDynamicFormDetail = (payload: any) => (dispatch: any) => {
	return dispatch(
		post(SAVE_DYNAMIC_FORM_DETAIL, `${getMPSBaseUrl()}/v1/margin-analytics/trades/whatIf/create`, payload)
	)
}

export const saveBaseTradeDetail = (payload: any, fromSOD: boolean) => (dispatch: any) => {
	return dispatch(
		post(
			SAVE_BASE_TRADE_FORM_DETAIL,
			`${getMPSBaseUrl()}/v1/margin-analytics/trades/base/create?fromSOD=${fromSOD}`,
			payload
		)
	)
}

export const savePreTradeDetail = (tradeId: string, payload: any) => (dispatch: any) => {
	return dispatch(
		post(SAVE_PRE_TRADE_FORM_DETAIL, `${getMPSBaseUrl()}/v1/margin-analytics/basket/update/${tradeId}`, payload)
	)
}

export const updateDynamicFormDetail = (tradeId: string, payload: any) => (dispatch: any) => {
	return dispatch(
		post(SAVE_DYNAMIC_FORM_DETAIL, `${getMPSBaseUrl()}/v1/margin-analytics/trades/update/${tradeId}`, payload)
	)
}

export const convertBaseToWhatIfTrade = (tradeId: string) => (dispatch: any) => {
	return dispatch(
		post(CONVERT_BASE_TO_WHATIF_TRADE, `${getMPSBaseUrl()}/v1/margin-analytics/trades/base/${tradeId}/whatIf/create`)
	)
}

export const getAllTrades = () => (dispatch: any) => {
	return dispatch(get(GET_ALL_TRADES, `${getMPSBaseUrl()}/v1/margin-analytics/trades/whatIf`))
}

export const getAllBaseTrades = () => (dispatch: any) => {
	return dispatch(get(GET_ALL_BASE_TRADES, `${getMPSBaseUrl()}/v1/margin-analytics/trades/base`))
}

export const getAllTradeBasket = () => (dispatch: any) => {
	return dispatch(get(GET_TRADE_BASKET, `${getMPSBaseUrl()}/v1/margin-analytics/basket`))
}

export const getSavedTradeDetails = (tradeId: any) => (dispatch: any) => {
	return dispatch(get(GET_DYNAMIC_FORM_DETAIL, `${getMPSBaseUrl()}/v1/margin-analytics/trades/displayTrade/${tradeId}`))
}

export const getTradeBasketDetails = (tradeId: any) => (dispatch: any) => {
	return dispatch(get(GET_DYNAMIC_FORM_DETAIL, `${getMPSBaseUrl()}/v1/margin-analytics/basket/displayTrade/${tradeId}`))
}

export const getAllSODTrades = () => (dispatch: any) => {
	return dispatch(get(GET_ALL_SOD_TRADES, `${getMPSBaseUrl()}/v1/margin-analytics/trades/sodTrades`))
}

export const downlaodTradeDocument = (tradeId: number) => (dispatch: any) => {
	return dispatch(get(DOWNLOAD_TRADE_DOCUMENT, `${getMPSBaseUrl()}/v1/margin-analytics/trades/sodTrade/${tradeId}`))
}

export const getAllBaskets = () => (dispatch: any) => {
	return dispatch(get(GET_ALL_BASKETS, `${getMPSBaseUrl()}/v1/margin-analytics/trades-basket/all`))
}

export const createBasket = payload => (dispatch: any) => {
	return dispatch(post(CREATE_BASKET, `${getMPSBaseUrl()}/v1/margin-analytics/trades-basket/create`, payload))
}

export const getMarginComparison = (basketId: number) => (dispatch: any) => {
	return dispatch(get(GET_MARGIN_COMPARISON, `${getMPSBaseUrl()}/v1/margin-analytics/margin-comparison/${basketId}`))
}

export const getAllMarginComparison = (basketId: number) => (dispatch: any) => {
	return dispatch(get(GET_ALL_MARGIN_COMPARISON, `${getMPSBaseUrl()}/v1/margin-analytics/margin-comparison/all`))
}

export const invokeMarginComparison = (payload: any, fund_code: string, portfolio_code: string) => (dispatch: any) => {
	return dispatch(
		post(
			INVOKE_MARGIN_COMPARISON,
			`${getMPSBaseUrl()}/v1/margin-analytics/margin-comparison/invoke?fund_code=${fund_code}&portfolio_code=${portfolio_code} `,
			payload
		)
	)
}

export const setComparisonDetailsInContext = payload => {
	return {
		type: SET_MARGIN_COMPARISON_CONTEXT,
		payload,
	}
}

export const deleteTradeByID = (tradeId: number) => (dispatch: any) => {
	return dispatch(del(DELETE_TRADE_BY_ID, `${getMPSBaseUrl()}/v1/margin-analytics/trades/${tradeId}`))
}

export const deleteTradeBasketByID = (tradeId: number) => (dispatch: any) => {
	return dispatch(del(DELETE_TRADE_BASKET_BY_ID, `${getMPSBaseUrl()}/v1/margin-analytics/basket/remove/${tradeId}`))
}

export const updateForecastData = forecastTrades => {
	return {
		type: ADD_FORECAST_TRADES,
		response: forecastTrades,
	}
}

export const updateForecastTradesList = forecastTradesList => {
	return {
		type: UPDATE_FORECAST_TRADES_LIST,
		response: forecastTradesList,
	}
}

export const getBaseTradeTemplate =
	(productTypeId: string, productSubTypeId: string, entityTradeId: string) => (dispatch: any) => {
		var url = `${getMPSBaseUrl()}/v1/margin-analytics/trades/sodTrade/convert/${productTypeId}/${productSubTypeId}/${entityTradeId}`
		return dispatch(get(GET_BASE_FORM_DETAIL, url))
	}

export const createForecastTrade = (payload: any) => (dispatch: any) => {
	return dispatch(
		post(SAVE_FORECAST_TRADE_FORM_DETAIL, `${getMPSBaseUrl()}/v1/margin-analytics/trades/forecast/create`, payload)
	)
}

