import {createFetchActions} from '../../../services/createActions'
import {post} from '../../../services/createAPICall'
import {getPMSBaseUrl} from '../../../services/apiService'

export const GET_FM_WIDGETS = createFetchActions('GET_FM_WIDGETS')
export const SAVE_FM_WIDGETS = createFetchActions('SAVE_FM_WIDGETS')
export const DELETE_FM_WIDGETS = createFetchActions('DELETE_FM_WIDGETS')
export const EMPTY_FM_WIDGETS = createFetchActions('EMPTY_FM_WIDGETS')

export const getWidgets = user => (dispatch: any) => {
	return dispatch(
		post(GET_FM_WIDGETS, `${getPMSBaseUrl()}/getfmcfg`, user, null, 'Error occured while getting ODR data')
	)
}

export const saveWidgets = widget => (dispatch: any) => {
	return dispatch(
		post(SAVE_FM_WIDGETS, `${getPMSBaseUrl()}/savefmcfg`, widget, null, 'Error occured while saving ODR data')
	)
}

export const deleteWidgets = user => (dispatch: any) => {
	return dispatch(
		post(DELETE_FM_WIDGETS, `${getPMSBaseUrl()}/deletefmcfg`, user, null, 'Error occured while getting ODR data')
	)
}

export const emptyWidgetsData = () => {
	return {type: EMPTY_FM_WIDGETS}
}
