import {scheduleA, scheduleB, scheduleC, scheduleD} from './CusipScheduleConstant'

export const transformData = (data: any) => {
	const transformedData = []
	for (const [securityId, schedules] of Object.entries<string[]>(data)) {
		transformedData.push({
			'Security Id': securityId,
			'Schedule A': schedules ? (schedules.includes(scheduleA) ? 'Y' : 'N') : 'N/A',
			'Schedule B': schedules ? (schedules.includes(scheduleB) ? 'Y' : 'N') : 'N/A',
			'Schedule C': schedules ? (schedules.includes(scheduleC) ? 'Y' : 'N') : 'N/A',
			'Schedule D': schedules ? (schedules.includes(scheduleD) ? 'Y' : 'N') : 'N/A',
		})
	}
	return transformedData
}
