import {GET_WIDGETS, SAVE_WIDGETS, DELETE_WIDGETS, GET_ALL_TRADES_FOR_WIDGETS} from './graphWidgetsActions'
import {addAmountsFromObjects} from './graphWidgetsHelpers'
export interface GraphWidgets {
	loading: boolean
	allWidgets?: any
	tradeData: any
}

const initialState: GraphWidgets = {
	loading: false,
	allWidgets: [],
	tradeData: {},
}

export function graphWidgetsReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_WIDGETS.triggered:
			return {...state, loading: true}
		case GET_WIDGETS.failed:
			return {...state, loading: false}
		case GET_WIDGETS.succeeded:
			state.allWidgets = action.response.data
			return {
				...state,
				loading: false,
			}
		case SAVE_WIDGETS.triggered:
			return {...state, loading: true}
		case SAVE_WIDGETS.failed:
			return {...state, loading: false}
		case SAVE_WIDGETS.succeeded:
			return {
				...state,
				loading: false,
			}
		case DELETE_WIDGETS.triggered:
			return {...state, loading: true}
		case DELETE_WIDGETS.failed:
			return {...state, loading: false}
		case DELETE_WIDGETS.succeeded:
			return {
				...state,
				loading: false,
			}
		case GET_ALL_TRADES_FOR_WIDGETS.triggered:
			return {...state, loading: true}
		case GET_ALL_TRADES_FOR_WIDGETS.failed:
			return {...state, loading: false}
		case GET_ALL_TRADES_FOR_WIDGETS.succeeded:
			state.tradeData = action.response.data ? addAmountsFromObjects(action.response.data.portfolio) : {}
			return {
				...state,
				loading: false,
			}
	}
	return state
}
