import {FETCH_INDEX_PRICE_DATA} from './IndexPricingAction'

export interface IndexPricer {
	indexPriceData: any
	isLoading: boolean
}

const initialState: IndexPricer = {
	indexPriceData: {},
	isLoading: false,
}

export function IndexPricerReducer(state = initialState, action: any) {
	switch (action.type) {
		case FETCH_INDEX_PRICE_DATA.triggered:
			return {...state, isLoading: true}

		case FETCH_INDEX_PRICE_DATA.failed:
			return {...state, isLoading: false}

		case FETCH_INDEX_PRICE_DATA.succeeded:
			state.indexPriceData = action.response.data && action.response.data
			return {...state, isLoading: false}
	}
	return state
}
