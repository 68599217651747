import gql from 'graphql-tag'
import {getCounterpartyId} from '../../../services/authService'

export const getAllAllocationsQuery = gql`
	query {
    allAllocations(tenantId:"${getCounterpartyId()}") {
      tenantId
      allocationId
      id
      name
      orgId
      strategies 
      modifiedBy
      modifiedDate
    }
	}
`
