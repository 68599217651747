import gql from 'graphql-tag'

export const collateralBasketListQuery = (includeAttachedToOrder: boolean) => gql`
	{
		allCollateralBaskets {
			collateralBasketId
			collateralBasketType
			counterParty
			counterPartyName
			basketType
			createdBy
			createdDate
			state
			schedule
			name
			orderId
			sector
			weighting {
				weight
				code
			}
			distinctSecurities
			isConstraintPool
			constraintMap {
				assetType
				value
			}
			minimumRating
			includeETF
			includeSpecialAndHTB
			includeDividendsPayingInNext10Days
			includeOverBorrows
			includeRestrictedSecurities
			collateralBasketPurpose
			strategy {
				strategy
				code
			}
			currency
			assetTypes
			index
			notionalAmount
			targetAmount
			targetAmountField
			createdDate
		}
	}
`
