export const cusipScheduleSampleData = [
	{securityId: 'MSFT US', cusipScheduleType: 'cusipSchedule TYPE1', cusipSchedule: 0.05},
	{securityId: 'GE US', cusipScheduleType: 'cusipSchedule TYPE2', cusipSchedule: 0.06},
	{securityId: 'MSFT US', cusipScheduleType: 'cusipSchedule TYPE3', cusipSchedule: 0.09},
]

export const scheduleA = 'US Treasuries'
export const scheduleB = 'US Treasuries, Agencies and Agency MBS'
export const scheduleC = 'Investment Grade Corporates 1'
export const scheduleD = 'Investment Grade Corporates 2'

export const columnDefs = [
	{
		dataFormat: '',
		field: 'Security Id',
		headerName: 'Security Id',
		type: '',
	},
	{
		dataFormat: '',
		field: 'Schedule A',
		headerName: scheduleA,
		type: '',
	},
	{
		dataFormat: '',
		field: 'Schedule B',
		headerName: scheduleB,
		type: '',
	},
	{
		dataFormat: '',
		field: 'Schedule C',
		headerName: scheduleC,
		type: '',
	},
	{
		dataFormat: '',
		field: 'Schedule D',
		headerName: scheduleD,
		type: '',
	},
]
