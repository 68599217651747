import {connect} from 'react-redux'
import {MainState} from '../../store/mainReducer'
import {bindActionCreators} from 'redux'
import {resetError} from './errorHandlerActions'
import ErrorHandler from './errorComponent'
import {toggleForceLogout} from '../login/loginActions'
import {authLogout} from '../../app/appActions'

const mapStateToProps = (state: MainState) => {
	return {
		displayMessage: state.errorHandler.errorMessage,
		errorCode: state.errorHandler.errorCode,
		isRedirect: state.errorHandler.isRedirect,
		forcedLogout: state.login.forcedLogout,
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return bindActionCreators(
		{
			authLogout,
			resetError,
			toggleForceLogout,
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorHandler)
