import {GET_COF, GET_CASH_POSITION_AND_FLOW, RELOAD} from './fundCashFlowTitleBarActions'
import {UPDATE_COF, UPDATE_CASH_POSITION} from './fundCashFlowTitleBarActions'

export interface FundCashFlowTitleBar {
	costOfFunds: any
	cashPositionAndFlow: any
	loading: boolean
	reload: boolean
}

const initialState: FundCashFlowTitleBar = {
	costOfFunds: null,
	cashPositionAndFlow: null,
	loading: false,
	reload: false,
}

export function fundCashFlowTitleBarReducer(state = initialState, action: any) {
	switch (action.type) {
		case RELOAD:
			return {...state, reload: action.response.reload}

		case GET_COF.triggered:
			return {...state, costOfFunds: null, loading: true}
		case GET_COF.succeeded:
			return {...state, costOfFunds: action.response.data, loading: false}

		case UPDATE_COF.triggered:
			return {...state, loading: true}
		case UPDATE_COF.succeeded:
			return {...state, loading: false}

		case GET_CASH_POSITION_AND_FLOW.triggered:
			return {...state, cashPositionAndFlow: null, loading: true}
		case GET_CASH_POSITION_AND_FLOW.succeeded:
			return {...state, cashPositionAndFlow: action.response.data, loading: false}
		case GET_CASH_POSITION_AND_FLOW.failed:
			return {...state, cashPositionAndFlow: null, loading: false}

		case UPDATE_CASH_POSITION.triggered:
			return {...state, loading: true}
		case UPDATE_CASH_POSITION.succeeded:
			return {...state, loading: false}
	}
	return state
}
