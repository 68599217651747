import numeral from 'numeral'

numeral.register('locale', 'en_US', {
	delimiters: {
		thousands: ',',
		decimal: '.',
	},
	abbreviations: {
		thousand: 'K',
		million: 'MM',
		billion: 'BN',
		trillion: 'TN',
	},
	currency: {
		symbol: '$',
	},
})

numeral.locale('en_US')
