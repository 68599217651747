import {createFetchActions} from '../../services/createActions'
import {get, post, put, del} from '../../services/createAPICall'
import {getConfigBaseUrl} from '../../services/apiService'

export const CREATE_MENU_CONFIG = createFetchActions('CREATE_MENU_CONFIG')
export const GET_ALL_MENU_CONFIG = createFetchActions('GET_ALL_MENU_CONFIG')
export const UPDATE_MENU_CONFIG = createFetchActions('UPDATE_MENU_CONFIG')
export const DELETE_MENU_CONFIG = createFetchActions('DELETE_MENU_CONFIG')
export const GET_ACTIVE_MENU_CONFIG = createFetchActions('GET_ACTIVE_MENU_CONFIG')
export const RESET_MENU_DATA = 'RESET_MENU_DATA'

export const createMenu = data => (dispatch: any) => {
	return dispatch(post(CREATE_MENU_CONFIG, `${getConfigBaseUrl()}/v1/menu-favourites`, data))
}

export const getAllMenus = () => (dispatch: any) => {
	return dispatch(get(GET_ALL_MENU_CONFIG, `${getConfigBaseUrl()}/v1/menu-favourites/`))
}

export const updateMenu = (id, req) => (dispatch: any) => {
	return dispatch(put(UPDATE_MENU_CONFIG, `${getConfigBaseUrl()}/v1/menu-favourites/${id}`, req))
}

export const deleteMenu = id => (dispatch: any) => {
	return dispatch(del(DELETE_MENU_CONFIG, `${getConfigBaseUrl()}/v1/menu-favourites/${id}`))
}

export const getActiveMenu = () => (dispatch: any) => {
	return dispatch(get(GET_ACTIVE_MENU_CONFIG, `${getConfigBaseUrl()}/v1/menu-favourites/active`))
}

export const resetMenuData = () => {
	return {
		type: RESET_MENU_DATA,
	}
}
