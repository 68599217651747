import {getPMSClient} from '../../../..'
import {createFetchActions} from '../../../../services/createActions'
import {getMaturingTradesQuery} from './maturingTradesQueries'

export const GET_ALL_MATURING_TRADES = createFetchActions('GET_ALL_MATURING_TRADES')

export const RESET_ALL_TRADES = 'RESET_ALL_TRADES'
export const SET_MATURING_TRADES_GROUP_FILTERS = 'SET_MATURING_TRADES_GROUP_FILTERS'
export const SET_MATURING_TRADES_SELECTED_DAY = 'SET_MATURING_TRADES_SELECTED_DAY'

export const setMaturingTradesGroupFilters = data => {
	return {
		type: SET_MATURING_TRADES_GROUP_FILTERS,
		response: {
			data,
		},
	}
}

export const setMaturingTradesSelectedDay = data => {
	return {
		type: SET_MATURING_TRADES_SELECTED_DAY,
		response: {
			data,
		},
	}
}

export const resetAllTrades = () => {
	return {type: RESET_ALL_TRADES}
}

export const getMaturingTradeEntries = (day: any, screenType: any) => {
	return function (dispatch) {
		dispatch({
			type: GET_ALL_MATURING_TRADES.triggered,
		})
		getPMSClient()
			.query({
				query: getMaturingTradesQuery(),
				fetchPolicy: 'network-only',
				variables: {
					day,
					screen: screenType,
				},
			})
			.then(response => {
				dispatch({
					type: GET_ALL_MATURING_TRADES.succeeded,
					response,
				})
			})
	}
}
