import {IBorrower} from '../../AlpNewRequestModal/AlpNewRequestModel'
import {addTimerDelay} from '../Internal/CreateEditAuction/CreateEditHelper'
import {updateGridDataOnTimerEnds} from '../Internal/InternalMainHelper'
import {
	CLEAR_BID_DETAILS,
	FETCH_AUCTION_BORROWERS_SUB_ACCOUNT,
	GET_ALL_MY_BIDS,
	GET_LIVE_AUCTIONS,
	SET_AUCTION_DETAILS_AND_MODE,
	SET_BORROWER_AUCTION_STATUS_ON_TIMER_ENDS,
} from './BorrowerMainActions'

export interface AlpAuctionsBorrowerMain {
	liveAuctionsGridData: any
	myBidsGridData: any
	borrowersSubAccount: IBorrower
	myBidDetails: {
		bidMode: string
	}
}

const initialState: AlpAuctionsBorrowerMain = {
	liveAuctionsGridData: [],
	myBidsGridData: [],
	borrowersSubAccount: null,
	myBidDetails: {
		bidMode: null,
	},
}

export function AlpAuctionsBorrowerMainReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_LIVE_AUCTIONS.triggered:
			return {
				...state,
				liveAuctionsGridData: [],
			}
		case GET_LIVE_AUCTIONS.failed:
			return {
				...state,
				liveAuctionsGridData: [],
			}
		case GET_LIVE_AUCTIONS.succeeded:
			const liveAuctionsGridData =
				action.response &&
				action.response.data &&
				action.response.data.flatMap(d => {
					const parsedCreationResponse = JSON.parse(d.creationResponse)
					let parsedBidResponse = {}
					if (d.bidResponse) {
						parsedBidResponse = JSON.parse(d.bidResponse)
					}
					return {
						...JSON.parse(d.creationResponse),
						...d,
						...parsedBidResponse,
						status: d.responseStatus,
						timeRemaining: addTimerDelay(d.epochModifiedDate, parsedCreationResponse.auctionDuration),
					}
				})
			return {
				...state,
				liveAuctionsGridData,
			}

		case SET_BORROWER_AUCTION_STATUS_ON_TIMER_ENDS: {
			return {
				...state,
				liveAuctionsGridData: updateGridDataOnTimerEnds(state.liveAuctionsGridData, action.payload),
			}
		}

		case GET_ALL_MY_BIDS.triggered:
			return {
				...state,
				myBidsGridData: [],
			}
		case GET_ALL_MY_BIDS.failed:
			return {
				...state,
				myBidsGridData: [],
			}
		case GET_ALL_MY_BIDS.succeeded:
			const myBidsGridData =
				action.response &&
				action.response.data.map(d => {
					const parsedBidCreationResponse = JSON.parse(d.creationResponse)
					const parsedAuctionUpdateResponse = !!d.auctionUpdateResponse && JSON.parse(d.auctionUpdateResponse)
					return {
						...d,
						...parsedBidCreationResponse,
						...JSON.parse(d.bidResponse),
						termDate: d.termDate,
						fillQty: parsedAuctionUpdateResponse ? parsedAuctionUpdateResponse.fillQty : 0,
						status: d.auctionStatus,
						timeRemaining: addTimerDelay(d.epochModifiedDate, parsedBidCreationResponse.auctionDuration),
					}
				})
			return {
				...state,
				myBidsGridData,
			}

		case SET_AUCTION_DETAILS_AND_MODE: {
			return {
				...state,
				myBidDetails: {
					...state.myBidDetails,
					auctionDetails: action.payload.auctionDetails,
					bidMode: action.payload.mode,
				},
			}
		}

		case FETCH_AUCTION_BORROWERS_SUB_ACCOUNT.triggered:
			return {...state, collateralTypes: null, isLoading: true, borrowersSubAccount: null}
		case FETCH_AUCTION_BORROWERS_SUB_ACCOUNT.failed:
			return {...state, isLoading: false, borrowersSubAccount: null}
		case FETCH_AUCTION_BORROWERS_SUB_ACCOUNT.succeeded:
			return {
				...state,
				borrowersSubAccount: action.response && action.response.data,
				isLoading: false,
			}

		case CLEAR_BID_DETAILS:
			return {
				...state,
				myBidDetails: {
					bidMode: null,
				},
			}
	}
	return state
}
