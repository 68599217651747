import {getPBMSClient} from '../../..'
import {createFetchActions} from '../../../services/createActions'
import {
	getPbBasketDetailsQuery,
	requestPbLoanDetailsQuery,
	pbActivityLogQuery,
	fetchPbSearchSecurityResultQuery,
} from './pbNeedsAvailabilitiesQueries'

import {get, post, put, del} from '../../../services/createAPICall'
import {getPBMSBaseUrl} from '../../../services/apiService'
export const GET_PB_BASKET_DETAILS = createFetchActions('GET_PB_BASKET_DETAILS')
export const UPLOAD_PB_BASKET_DETAILS = createFetchActions('UPLOAD_PB_BASKET_DETAILS')
export const REQUEST_PB_LOAN_DETAILS = createFetchActions('REQUEST_PB_LOAN_DETAILS')
export const SUBMIT_PB_LOAN = createFetchActions('SUBMIT_PB_LOAN')
export const FILTER_PB_BASKET = createFetchActions('FILTER_PB_BASKET')
export const SUBMIT_PB_BASKET = createFetchActions('SUBMIT_PB_BASKET')
export const SHARE_PB_BASKET = createFetchActions('SHARE_PB_BASKET')
export const GET_PB_DROPDOWN_DATA = createFetchActions('GET_PB_DROPDOWN_DATA')
export const GET_PB_BASKET_FILE_DATA = createFetchActions('GET_PB_BASKET_FILE_DATA')
export const SUBMIT_PB_MATCHING_ALGORITHM = createFetchActions('SUBMIT_PB_MATCHING_ALGORITHM')
export const GET_PB_BASKET_OVERVIEW = createFetchActions('GET_PB_BASKET_OVERVIEW')
export const SUBMIT_PB_LOAN_ENTRIES = createFetchActions('SUBMIT_PB_LOAN_ENTRIES')
export const DELETE_PB_BASKET_FILE = createFetchActions('DELETE_PB_BASKET_FILE')
export const DOWNLOAD_PB_BASKET = createFetchActions('DOWNLOAD_PB_BASKET')
export const SET_PB_COLLATERAL_TYPE = createFetchActions('SET_PB_COLLATERAL_TYPE')
export const FETCH_PB_ACTIVITY_LOG = createFetchActions('FETCH_PB_ACTIVITY_LOG')
export const GET_PB_PENDING_TARGET = createFetchActions('GET_PB_PENDING_TARGET')
export const UPDATE_PB_PENDING_TARGET = createFetchActions('UPDATE_PB_PENDING_TARGET')
export const FETCH_PB_LOCATE_SEARCH_RESULT = createFetchActions('FETCH_PB_LOCATE_SEARCH_RESULT')
export const FETCH_PB_LOCATE_SEARCH_OPENED = 'FETCH_PB_LOCATE_SEARCH_OPENED'
export const SET_PB_DATA_FROM_INDEX_ANALYSIS = 'SET_PB_DATA_FROM_INDEX_ANALYSIS'
export const SAVE_PB_LOCATE_SEARCH_RESULT = createFetchActions('SAVE_PB_LOCATE_SEARCH_RESULT')
export const RESET_PB_NEEDS_AVAILABILITY_DATA = 'RESET_PB_NEEDS_AVAILABILITY_DATA'

export const setPbDataFromIndexAnalysis = data => {
	return function (dispatch) {
		dispatch({
			type: SET_PB_DATA_FROM_INDEX_ANALYSIS,
			payload: data,
		})
	}
}

export const openPbLocateSearch = () => {
	return function (dispatch) {
		dispatch({
			type: FETCH_PB_LOCATE_SEARCH_OPENED,
		})
	}
}
export const getPbBasketDetails = () => {
	return function (dispatch) {
		dispatch({
			type: GET_PB_BASKET_DETAILS.triggered,
		})
		getPBMSClient()
			.query({
				query: getPbBasketDetailsQuery,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_PB_BASKET_DETAILS.succeeded,
					response,
				})
			})
	}
}

export const getPbBasketFileData = () => {
	return function (dispatch) {
		dispatch({
			type: GET_PB_BASKET_FILE_DATA.triggered,
		})
		dispatch({
			type: GET_PB_BASKET_FILE_DATA.succeeded,
			response: {},
		})
		// getPBMSClient()
		// 	.query({
		// 		query: getPbBasketFileDataQuery(),
		// 		fetchPolicy: 'network-only',
		// 	})
		// 	.then(response => {
		// 		dispatch({
		// 			type: GET_PB_BASKET_FILE_DATA.succeeded,
		// 			response,
		// 		})
		// 	})
	}
}

export const uploadPbBasketDetails = req => (dispatch: any) => {
	return dispatch(post(UPLOAD_PB_BASKET_DETAILS, `${getPBMSBaseUrl()}/v1/availabilities-needs/upload`, req))
}

export const requestPbLoanData = (id: string) => {
	return function (dispatch) {
		dispatch({
			type: REQUEST_PB_LOAN_DETAILS.triggered,
		})
		getPBMSClient()
			.query({
				query: requestPbLoanDetailsQuery(id),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: REQUEST_PB_LOAN_DETAILS.succeeded,
					response,
				})
			})
	}
}

export const getPbDropdownData = (type: string) => {
	return function (dispatch) {
		dispatch({
			type: GET_PB_DROPDOWN_DATA.triggered,
		})
		dispatch({
			type: GET_PB_DROPDOWN_DATA.succeeded,
			response: {},
		})
		// getPBMSClient()
		// 	.query({
		// 		query: getPbDropdownDataQuery(type),
		// 		fetchPolicy: 'network-only',
		// 	})
		// 	.then(response => {
		// 		dispatch({
		// 			type: GET_PB_DROPDOWN_DATA.succeeded,
		// 			response,
		// 		})
		// 	})
	}
}

export const submitPbLoan = (data: any, id: string) => (dispatch: any) => {
	const payload = {
		borrowItems: data.map((item: any) => {
			const obj = {
				id: item.id,
				allocatedQuantity: item.allocatedQuantity,
				collateralType: item.collateralType,
			}
			return obj
		}),
	}
	return dispatch(post(SUBMIT_PB_LOAN, `${getPBMSBaseUrl()}/v1/availabilities-needs/${id}/orders`, payload))
}

export const submitPbLoanEntries = (data: any, id: string) => (dispatch: any) => {
	const payload = data.map((item: any) => {
		const obj = {
			orderEntryId: item.orderId,
			quantity: item.allocated,
			action: item.actionTaken,
		}
		return obj
	})
	return dispatch(post(SUBMIT_PB_LOAN_ENTRIES, `${getPBMSBaseUrl()}/v1/availabilities-needs/orders`, payload))
}

export const filterPbBasketData = (payload: any) => (dispatch: any) => {
	return dispatch(post(FILTER_PB_BASKET, `${getPBMSBaseUrl()}/v1/availabilities-needs/filter`, payload))
}

export const submitPbBasketData = (payload: any) => (dispatch: any) => {
	return dispatch(post(SUBMIT_PB_BASKET, `${getPBMSBaseUrl()}/v1/availabilities-needs/basket`, payload))
}

export const submitPbMatchingAlgorithm = (payload: any) => (dispatch: any) => {
	return dispatch(
		put(SUBMIT_PB_MATCHING_ALGORITHM, `${getPBMSBaseUrl()}/v1/availabilities-needs/match?algorithm=${payload}`)
	)
}

export const getPbBasketOverview = () => {
	return function (dispatch) {
		dispatch({
			type: GET_PB_BASKET_OVERVIEW.triggered,
		})
		// getPBMSClient()
		// 	.query({
		// 		query: getPbBasketOverviewQuery(),
		// 		fetchPolicy: 'network-only',
		// 	})
		// 	.then(response => {
		// 		dispatch({
		// 			type: GET_PB_BASKET_OVERVIEW.succeeded,
		// 			response,
		// 		})
		// 	})
	}
}

export const deletePbBasketFile = (id: any) => (dispatch: any) => {
	return dispatch(del(DELETE_PB_BASKET_FILE, `${getPBMSBaseUrl()}/v1/availabilities-needs/delete/${id}`))
}

export const downloadPbBasketFile = (id: any) => (dispatch: any) => {
	return dispatch(get(DOWNLOAD_PB_BASKET, `${getPBMSBaseUrl()}/v1/availabilities-needs/basket/download/${id}`))
}

export const setPbCollateralType = (cash: number, id: string) => (dispatch: any) => {
	const req = {
		cash,
	}
	return dispatch(put(SET_PB_COLLATERAL_TYPE, `${getPBMSBaseUrl()}/v1/availabilities-needs/${id}`, req))
}

export const fetchPbActivityLog = (counterParty: string, way: string, view: string) => {
	return function (dispatch) {
		dispatch({type: FETCH_PB_ACTIVITY_LOG.triggered})
		getPBMSClient()
			.query({
				query: pbActivityLogQuery(counterParty, way, view),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: FETCH_PB_ACTIVITY_LOG.succeeded,
					response,
				})
			})
	}
}

export const getPbLendingTarget = () => (dispatch: any) => {
	return dispatch(get(GET_PB_PENDING_TARGET, `${getPBMSBaseUrl()}/config/lending-target`))
}

export const updatePbLendingTarget = (data: any) => (dispatch: any) => {
	return dispatch(put(UPDATE_PB_PENDING_TARGET, `${getPBMSBaseUrl()}/config/lending-target`, data))
}

export const getPbLocateSearchResult = data => {
	return function (dispatch) {
		dispatch({
			type: FETCH_PB_LOCATE_SEARCH_RESULT.triggered,
		})
		getPBMSClient()
			.query({
				query: fetchPbSearchSecurityResultQuery(data),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: FETCH_PB_LOCATE_SEARCH_RESULT.succeeded,
					response,
				})
			})
	}
}

export const savePbLocateData = (data: any) => (dispatch: any) => {
	return dispatch(post(SAVE_PB_LOCATE_SEARCH_RESULT, `${getPBMSBaseUrl()}/v1/availabilities-needs/locatesearch`, data))
}

export const sharePbBasket = (id, clients) => (dispatch: any) => {
	const payload = {
		availNeedBasketId: id,
		clients,
	}
	return dispatch(put(SHARE_PB_BASKET, `${getPBMSBaseUrl()}/v1/availabilities-needs/basket/share`, payload))
}

export const resetPbNeedsAvailability = () => {
	return {type: RESET_PB_NEEDS_AVAILABILITY_DATA}
}
