import {ACTIVITY_TYPE} from './ActivityTypeAction'

export interface ActivityType {
	loading: boolean
	activityTypeDetail: any
}

const initialState: ActivityType = {
	loading: false,
	activityTypeDetail: {},
}

export function ActivityTypeReducer(state = initialState, action: any) {
	switch (action.type) {
		case ACTIVITY_TYPE.triggered:
			return {...state, loading: true}
		case ACTIVITY_TYPE.succeeded:
			return {...state, loading: false, activityTypeDetail: action.response}
		case ACTIVITY_TYPE.failed:
			return {...state, loading: false}
	}
	return state
}
