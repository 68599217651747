import memoizeOne from 'memoize-one'
import isDeepEqual from 'lodash.isequal'
import {ArrayUtil} from 'helper-util'
import {globalColumnDefinition} from '../../../common/AgGrid/AgGridColumn'
import {securityTypeConstants} from './LocateAuthorizerConstant'
import {formatWithSeparator} from '../../../utils/formatUtils'
import {formatWithDateAndTime} from '../../../utils/dateUtil'
import {RejectedReasons} from '../common/Model'
import {noPinnedFooter} from '../../../common/AgGrid/AgGridHelper'

export const searchLocateBucketColumnDefs = onActionClick => [
	Object.assign({}, globalColumnDefinition.actions, {
		cellRendererParams: {mode: 'AUTHORIZER', onActionClick},
		cellRenderer: 'ActionsCellRenderer',
		minWidth: 80,
		maxWidth: 100,
	}),
	Object.assign({}, globalColumnDefinition.status, {
		cellRendererParams: {},
		cellRenderer: 'StatusCellRenderer',
		minWidth: 100,
		maxWidth: 165,
		enableRowGroup: true,
	}),
	Object.assign({}, globalColumnDefinition.security, {
		enableRowGroup: true,
		cellRenderer: params => {
			if (!params.data) {
				return null
			}
			const securityLabel = params.data.ticker || getSecurityLabel(params.data)
			if (!params.data.securityId && securityLabel !== '')
				return `${securityLabel} <i title="Security is not in the security master" class="fa fa-exclamation-triangle text-info text-warning cursor-pointer mr-1"></i>`
			return securityLabel
		},
	}),
	Object.assign({
		headerName: 'Security List Types',
		field: 'securityListTypes',
		cellRenderer: 'SecurityListTypeCellRenderer',
		minWidth: 100,
	}),
	Object.assign({}, globalColumnDefinition.counterParty, {
		minWidth: 100,
		enableRowGroup: true,
	}),
	Object.assign(globalColumnDefinition.requestedQuantity, {
		headerName: 'Requested Qnty',
		minWidth: 100,
		maxWidth: 150,
		aggFunc: 'sum',
	}),
	Object.assign(globalColumnDefinition.totalApprovedQuantity, {
		minWidth: 100,
		aggFunc: 'sum',
		...noPinnedFooter,
	}),

	Object.assign({}, globalColumnDefinition.shortFall, {
		headerName: 'ShortFall',
		minWidth: 100,
		maxWidth: 150,
		cellRenderer: params => {
			const {data} = params
			return data
				? isEligibleToComputeShortfall(data.status)
					? formatWithSeparator(computeShortFall(data, data.totalApprovedQuantity))
					: ''
				: 0
		},
	}),
	Object.assign({}, globalColumnDefinition.indicativeRateBps, {
		minWidth: 100,
	}),
	Object.assign({}, globalColumnDefinition.totalMarketFee, {
		aggFunc: 'sum',
		rounding: true,
	}),
	Object.assign({}, globalColumnDefinition.description, {
		headerName: 'Reject Reason',
		minWidth: 100,
		cellRenderer: params => {
			const {data} = params
			return data && data.description ? RejectedReasons[data.description] || data.description : ''
		},
	}),
	Object.assign({}, globalColumnDefinition.modifiedDate, {
		headerName: 'Last Updated Date',
		minWidth: 100,
		sort: 'desc',
	}),
	Object.assign({}, globalColumnDefinition.locateRequestEntryId, {
		headerName: 'Locate Request Id',
		minWidth: 100,
	}),
	Object.assign({}, globalColumnDefinition.userEmail, {
		minWidth: 100,
	}),
	Object.assign({}, globalColumnDefinition.locateRequestType, {
		minWidth: 100,
		maxWidth: 120,
		enableRowGroup: true,
	}),
	Object.assign({}, globalColumnDefinition.endDate, {
		headerName: 'End Date (PTH)',
		minWidth: 100,
		maxWidth: 150,
	}),
]

export const searchLocateClientcolumnDefsWithDelete = (
	securityChangeHandler,
	quantityChangeHandler,
	deleteSelectedRow,
	addNewRow,
	endDateObj,
	handleSecurityKeyPress,
	handleQuantityKeyPress
) => {
	if (endDateObj.enableDateColumn) {
		return [
			Object.assign({}, globalColumnDefinition.ticker, {
				headerName: 'Security',
				cellRendererParams: {
					onChange: securityChangeHandler,
					onKeyPress: handleSecurityKeyPress,
				},
				cellRenderer: 'SecurityCellRenderer',
				minWidth: 80,
				maxWidth: 125,
				cellClass: ['d-flex align-items-center gridcellPadding ag-show-dropdown'],
			}),
			Object.assign({}, globalColumnDefinition.requestedQuantity, {
				headerName: 'Quantity',
				cellRendererParams: {
					onChange: quantityChangeHandler,
					onKeyPress: handleQuantityKeyPress,
				},
				cellRenderer: 'QuantityCellRenderer',
				minWidth: 80,
				maxWidth: 125,
				cellClass: ['d-flex align-items-center gridcellPadding'],
			}),
			Object.assign({}, globalColumnDefinition.endDate, {
				cellRendererParams: {endDateHandler: endDateObj.endDateHandler},
				cellRenderer: 'EndDateCellRenderer',
				minWidth: 110,
				maxWidth: 160,
				suppressNavigable: true,
				cellClass: ['d-flex align-items-center gridcellPadding ag-show-dropdown'],
			}),
			Object.assign({}, globalColumnDefinition.deleteField, {
				pinned: 'right',
				suppressNavigable: true,
				onCellClicked: params => {
					params.value ? deleteSelectedRow(params) : addNewRow()
				},
				cellRenderer: params => {
					if (params.value) {
						return '<i class="fa fa-times cursor-pointer"></i>'
					} else {
						return '<i class="fa fa-plus cursor-pointer"></i>'
					}
				},
				minWidth: 35,
				width: 35,
				maxWidth: 35,
				cellClass: ['d-flex align-items-center justify-content-center'],
			}),
		]
	}
	return [
		Object.assign({}, globalColumnDefinition.ticker, {
			headerName: 'Security',
			cellRendererParams: {
				onChange: securityChangeHandler,
				onKeyPress: handleSecurityKeyPress,
			},
			cellRenderer: 'SecurityCellRenderer',
			minWidth: 180,
			maxWidth: 180,
			cellClass: ['d-flex align-items-center gridcellPadding ag-show-dropdown'],
		}),
		Object.assign({}, globalColumnDefinition.requestedQuantity, {
			headerName: 'Quantity',
			cellRendererParams: {
				onChange: quantityChangeHandler,
				onKeyPress: handleQuantityKeyPress,
			},
			cellRenderer: 'QuantityCellRenderer',
			minWidth: 200,
			maxWidth: 200,
			cellClass: ['d-flex align-items-center justify-content-end gridcellPadding'],
		}),
		Object.assign({}, globalColumnDefinition.deleteField, {
			pinned: 'right',
			suppressNavigable: true,
			onCellClicked: params => {
				params.value ? deleteSelectedRow(params) : addNewRow()
			},
			cellRenderer: params => {
				if (params.value) {
					return '<i class="fa fa-times cursor-pointer"></i>'
				} else {
					return '<i class="fa fa-plus cursor-pointer"></i>'
				}
			},
			minWidth: 35,
			width: 35,
			maxWidth: 35,
			cellClass: ['d-flex align-items-center justify-content-center'],
		}),
	]
}

export const wishlistColumnDefs = (selectedSecurityType, deleteSecurity) => {
	if (selectedSecurityType === securityTypeConstants.WARM || selectedSecurityType === securityTypeConstants.HTB) {
		return [
			Object.assign({}, globalColumnDefinition.deleteField, {
				onCellClicked: params => {
					params.value && deleteSecurity(params)
				},
				pinned: 'right',
				editable: false,
				cellRenderer: params => {
					if (params.value === true) return '<i class="fa fa-times text-danger cursor-pointer"></i>'
					return null
				},
			}),

			Object.assign({}, globalColumnDefinition.securityId, {
				headerName: 'Security',
				cellRenderer: params => {
					if (!params.data) {
						return null
					}
					if (params.data.isYield)
						return `${
							params.data.ticker || getSecurityLabel(params.data)
						} <i title="Security is not in the security master" class="fa fa-exclamation-triangle text-info text-warning cursor-pointer mr-1"></i>`
					return params.data.ticker || getSecurityLabel(params.data)
				},
			}),
			Object.assign({}, globalColumnDefinition.indicativeRate, {
				headerName: 'Indicative Rate (bps)',
			}),
		]
	}
	return [
		Object.assign({}, globalColumnDefinition.deleteField, {
			onCellClicked: params => {
				params.value && deleteSecurity(params)
			},
			pinned: 'right',
			editable: false,
			cellRenderer: params => {
				if (params.value === true) return '<i class="fa fa-times text-danger cursor-pointer"></i>'
				return null
			},
		}),
		Object.assign({}, globalColumnDefinition.securityId, {
			headerName: 'Security',
			cellRenderer: params => {
				if (!params.data) {
					return null
				}
				if (params.data.isYield)
					return `${
						params.data.ticker || getSecurityLabel(params.data)
					} <i title="Security is not in the security master" class="fa fa-exclamation-triangle text-info text-warning cursor-pointer mr-1"></i> `
				return params.data.ticker || getSecurityLabel(params.data)
			},
		}),
	]
}

export const computeShortFall = memoizeOne((data, approvedQuantity, whenApprovedQtyChange = false) => {
	const {requestedQuantity, status} = data
	if (isEligibleToComputeShortfall(status) || whenApprovedQtyChange) {
		return requestedQuantity > approvedQuantity ? Number(requestedQuantity - approvedQuantity) : 0
	}
	return 0
}, isDeepEqual)

export const selectFullOverPartial = memoizeOne((data, approvedQuantity) => {
	const {requestedQuantity} = data
	return approvedQuantity >= requestedQuantity
}, isDeepEqual)

export const computeApprovedQty = memoizeOne(data => {
	const {requestedQuantity, availableQuantity, status, approvedQuantity} = data
	if (status === 'PENDING') {
		return Math.min(requestedQuantity, availableQuantity)
	}
	return approvedQuantity
}, isDeepEqual)

export const locateNotificationContent = type => {
	const notification = {title: 'Locate Notification', message: ''}
	if (!type) return false
	notification['message'] =
		type === 'LOCATE_REQUEST_CREATED'
			? 'A new locate request was created'
			: type === 'LOCATE_REQUEST_AUTHORIZED'
			? 'Request was authorized'
			: 'Request was updated'
	return notification
}

export const detailsViewColumnDefs = (updateAvailableQuantity, updateClientName, locateClientList) => [
	Object.assign({}, globalColumnDefinition.clientName, {
		field: 'counterPartyName',
		cellRendererParams: {updateClientName, locateClientList},
		cellRenderer: 'ClientName',
		minWidth: 200,
		maxWidth: 250,
		cellClass: ['ag-show-dropdown lh-35'],
	}),

	Object.assign({}, globalColumnDefinition.availableQuantity, {
		cellRendererParams: {updateAvailableQuantity},
		cellRenderer: 'AvailableQuantity',
		minWidth: 200,
		maxWidth: 250,
		cellClass: ['d-flex align-items-center justify-content-end'],
	}),
]

export const clearFocusOfAllFields = data => {
	data.forEach(row => {
		row.securityFocus = false
		row.quantityFocus = false
	})
	return data
}

export const isEligibleToComputeShortfall = status => {
	return (
		status === 'APPROVED' ||
		status === 'AUTOAPPROVED' ||
		status === 'PARTIALLY_APPROVED' ||
		status === 'ACCEPTED' ||
		status === 'REJECTED' ||
		status === 'DECLINED'
	)
}

export const getSecurityListAuditDetails = memoizeOne(securityListAuditData => {
	const wtcAuditDetails = securityListAuditData.find(
		auditDetail => auditDetail.securityListType === securityTypeConstants.WTC
	)

	const wtcUserName = wtcAuditDetails && wtcAuditDetails.username.split('@')[0].split('.')
	const wtcAuditDetailsDisplay = wtcAuditDetails
		? `${wtcAuditDetails.createdDate ? 'Updated - ' + formatWithDateAndTime(wtcAuditDetails.createdDate) : ''} | ${
				wtcUserName ? wtcUserName[0] + ' ' + wtcUserName[1] : ''
		  }`
		: ''

	const htbAuditDetails = securityListAuditData.find(
		auditDetail => auditDetail.securityListType === securityTypeConstants.HTB
	)

	const htbUserName = htbAuditDetails && htbAuditDetails.username.split('@')[0].split('.')
	const htbAuditDetailsDisplay = htbAuditDetails
		? `${htbAuditDetails.createdDate ? 'Updated - ' + formatWithDateAndTime(htbAuditDetails.createdDate) : ''} | ${
				htbUserName ? htbUserName[0] + ' ' + htbUserName[1] : ''
		  }`
		: ''

	const warmAuditDetails = securityListAuditData.find(
		auditDetail => auditDetail.securityListType === securityTypeConstants.WARM
	)

	const warmUserName = warmAuditDetails && warmAuditDetails.username.split('@')[0].split('.')
	const warmAuditDetailsDisplay = warmAuditDetails
		? `${warmAuditDetails.createdDate ? 'Updated - ' + formatWithDateAndTime(warmAuditDetails.createdDate) : ''} | ${
				warmUserName ? warmUserName[0] + ' ' + warmUserName[1] : ''
		  }`
		: ''
	const resAuditDetails = securityListAuditData.find(
		auditDetail => auditDetail.securityListType === securityTypeConstants.RES
	)

	const resUserName = resAuditDetails && resAuditDetails.username.split('@')[0].split('.')
	const resAuditDetailsDisplay = resAuditDetails
		? `${resAuditDetails.createdDate ? 'Updated - ' + formatWithDateAndTime(resAuditDetails.createdDate) : ''} | ${
				resUserName ? resUserName[0] + ' ' + resUserName[1] : ''
		  }`
		: ''
	return {
		wtcAuditDetailsDisplay,
		htbAuditDetailsDisplay,
		warmAuditDetailsDisplay,
		resAuditDetailsDisplay,
	}
}, isDeepEqual)

export const getSecurityLabel = memoizeOne(data => {
	const {securityId, security} = data
	return securityId || security
}, isDeepEqual)

export const csvJSONLocate = csv => {
	const lines = csv.split('\n')
	const filteredLines = lines.filter(line => line !== '\r')

	const lastIndex = filteredLines.length - 1
	if (filteredLines[lastIndex] !== '') {
		filteredLines.push('')
	}

	let result = []
	const headers = filteredLines[0].split(',')
	for (let i = 1; i < filteredLines.length - 1; i++) {
		const obj = {}
		const currentline = filteredLines[i].split(',')
		for (var j = 0; j < headers.length; j++) {
			const trimmedHeader = headers[j].trim().replace(/"([^"]+(?="))"/g, '$1')
			const eliminatedCurrentLine = currentline[j].replace(/"([^"]+(?="))"/g, '$1')
			obj[trimmedHeader] = eliminatedCurrentLine.replace(/[\r\t]+/g, '')
		}
		result.push(obj)
	}
	return result
}

export const sampleLocateFileDowloadProps = () => {
	return {
		data: [{ticker: 'security_test', requestedQuantity: '0', endDate: '2021-01-01'}],
		headers: [
			{label: 'ticker', key: 'ticker'},
			{label: 'requestedQuantity', key: 'requestedQuantity'},
			{label: 'endDate', key: 'endDate'},
		],
		filename: 'Sample_Locate',
		className: 'f-12',
		buttonTextContent: 'Sample CSV file',
	}
}

export const embedShortfall = locateRequestQueueData => {
	return !ArrayUtil.isEmpty(locateRequestQueueData)
		? locateRequestQueueData.map(data => {
				return {
					...data,
					shortFall: data
						? isEligibleToComputeShortfall(data.status)
							? formatWithSeparator(computeShortFall(data, data.totalApprovedQuantity))
							: ''
						: 0,
				}
		  })
		: []
}

export const isTruthy = field => {
	return field !== null && field !== undefined
}
