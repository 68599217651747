import gql from 'graphql-tag'

export const responseActivityLog = responseId => gql`
query {
    orderCollaborationLogs(orderResponseId: "${responseId}") {
        time
        title
        activity
        level
    }
}
`
