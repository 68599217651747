import React from 'react'
import {RouteComponentProps} from 'react-router-dom'
import {FormatUtil} from 'helper-util'
import PasswordResetRequestForm from './passwordResetRequestForm/passwordResetRequestForm'
import PasswordResetForm from './passwordResetForm/passwordResetForm'
import {forgotPasswordTitle, loginToForgetPassword, modalMessage} from './forgotPasswordConstant'
import FsModalBox from '../../common/fsModalBox'
import {history} from '../../store/mainStore'
import {clearAuthToken, isAuthenticated} from '../../services/authService'
import {isResponseSuccess} from '../../utils/apiResponseUtil'
import LoginCarousel from '../common/LoginCarousel/LoginCarouselComponent'
import {amplifyForgotPasswordSubmit, amplifySentResetOtp, redirectLoginWithTenantCode} from '../../utils/amplify'
import {WarningIcon} from '../../styles/svg/svgIcons'
import {genericLogout, maskLoginErrorMessages} from '../../app/appHelper'
import {getStringFromStorage, removeItemFromStorage} from '../../services/storageService'
import '../login/login.scss'

interface Props extends RouteComponentProps {
	loading: string
	authLogout: () => any
	passwordChangeRequest: (email: string, errorMessage: string) => any
	validateResetToken: (resetToken: string, errorMessage: string) => any
	passwordReset: (resetToken: string, newPassword: string, errorMessage: string) => any
}

interface State {
	modalDetail: any
	allowPasswordReset: boolean
	email: string
}

export default class ForgotPassword extends React.Component<Props, State> {
	constructor(props) {
		super(props)
		this.state = {
			modalDetail: null,
			email: '',
			allowPasswordReset: props && props.match && props.match.params && props.match.params.resetToken,
		}

		this.validateAndOpenPasswordReset()

		if (Boolean(getStringFromStorage(loginToForgetPassword))) {
			removeItemFromStorage(loginToForgetPassword)
		} else {
			history && redirectLoginWithTenantCode(history)
		}
	}

	validateAndOpenPasswordReset = () => {
		const {match, authLogout, validateResetToken} = this.props
		const parmas: any = match && match.params

		if (parmas.resetToken) {
			validateResetToken(parmas.resetToken, modalMessage.tokenExpire.bodyMessage).then(data => {
				isAuthenticated() && authLogout()
				clearAuthToken()
				this.setState({
					allowPasswordReset: isResponseSuccess(data),
				})
			})
		}
	}

	handlePasswordReset = (values: any) => {
		const {email} = this.state
		const {otp, newPassword} = values
		amplifyForgotPasswordSubmit(email, otp, newPassword && newPassword.trim())
			.then(data => {
				this.redirectToLogin()
			})
			.catch(error => {
				this.setState({
					modalDetail: {
						code: error.code,
						message: error.message,
						mode: 'Reset',
					},
				})
			})
	}

	handlePasswordChangeRequest = (values: any) => {
		const {email} = values
		amplifySentResetOtp(FormatUtil.text.toLowerCase(email && email.trim()))
			.then(data => {
				this.setState({
					...this.state,
					allowPasswordReset: true,
					email: email && email.trim(),
				})
			})
			.catch(error => {
				this.setState({
					modalDetail: {
						code: error.code,
						message: error.message,
						mode: 'Reset',
					},
				})
			})
	}

	closeModal = () => {
		this.setState({modalDetail: null})
	}

	redirectToLogin = () => {
		const {history} = this.props
		genericLogout(null, history)
	}

	render() {
		const {modalDetail, allowPasswordReset} = this.state

		return (
			<div id='outer'>
				<div id='login-container' className='card card-login'>
					<div className='left'>
						<LoginCarousel />
					</div>
					<div className='right'>
						<span className='f-28 text-grey-9 font-weight-600 d-block border-bottom pb-3 mb-3 text-left'>
							{allowPasswordReset ? forgotPasswordTitle.passwordReset : forgotPasswordTitle.passwordResetRequest}
						</span>
						{allowPasswordReset ? (
							<PasswordResetForm onSubmit={this.handlePasswordReset} />
						) : (
							<PasswordResetRequestForm onSubmit={this.handlePasswordChangeRequest} />
						)}
					</div>

					{modalDetail && (
						<FsModalBox
							show={Boolean(modalDetail)}
							headerIcon={
								<span className='d-block m-auto text-danger'>
									<WarningIcon />
								</span>
							}
							bodyTitle={''}
							bodyMessage={maskLoginErrorMessages(modalDetail)}
							handleModalCancel={this.closeModal}
						/>
					)}
				</div>
			</div>
		)
	}
}
