import {
	GET_DASHBOARD_ENTITIES_AMOUNT,
	GET_DASHBOARD_PORTFOLIO_ANALYTICS,
	RESET_DASHBOARD_DATA,
	SET_CLICKED_PORTFOLIO_ENTITY,
	SET_CLICKED_GROUPBY_ENTITY,
	SET_TOGGLE_INDEX,
	SET_CLICKED_FIRST_LEVEL_TREEMAP_VALUE,
	SET_CLICKED_SECOND_LEVEL_TREEMAP_VALUE,
	SET_DRILLDOWNN,
	SET_TRADE_BY,
	GET_NOTIFICATIONS,
	GET_ALL_TRADES_FOR_DASHBOARD,
} from './dashboardActions'
import {addAmountsFromObjects} from './dashboardHelper'

export interface Dashboard {
	loading: boolean
	clickedGroupByEntity: any
	clickedToggleIndex: any
	firstLevelTreemapValue: string
	secondLevelTreemapValue: string
	clickedPortfolioEntity: string
	dashboardEntityAmountDataLoading: boolean
	dashboardEntityAmountData: any
	dashboardPortfolioAnalyticsLoading: boolean
	dashboardPortfolioAnalytics: any
	drilldown: any
	tradeBy: any
	notifications: any
	tradeData: any
	tradesDataLoading: boolean
}

const initialState: Dashboard = {
	loading: false,
	clickedGroupByEntity: {},
	clickedToggleIndex: {},
	firstLevelTreemapValue: '',
	secondLevelTreemapValue: '',
	clickedPortfolioEntity: '',
	dashboardEntityAmountDataLoading: false,
	dashboardEntityAmountData: {},
	dashboardPortfolioAnalyticsLoading: false,
	dashboardPortfolioAnalytics: [{}, {}],
	drilldown: {},
	tradeBy: null,
	notifications: [],
	tradeData: [],
	tradesDataLoading: false,
}

export function dashboardReducer(state = initialState, action: any) {
	switch (action.type) {
		case SET_CLICKED_PORTFOLIO_ENTITY:
			const clickedPortfolioEntity = action.response.data
			return {...state, clickedPortfolioEntity: clickedPortfolioEntity}

		case SET_CLICKED_GROUPBY_ENTITY:
			const {groupEntityname, groupOne, groupTwo} = action.response
			const clickedGroupByEntity = state.clickedGroupByEntity
			clickedGroupByEntity[groupEntityname] = [groupOne, groupTwo]
			return {...state, clickedGroupByEntity}

		case SET_TOGGLE_INDEX:
			const {toggleName, index} = action.response
			const clickedToggleIndex = state.clickedToggleIndex
			clickedToggleIndex[toggleName] = index
			return {...state, clickedToggleIndex}

		case SET_CLICKED_FIRST_LEVEL_TREEMAP_VALUE:
			const firstLevelTreemapValue = action.response.data
			return {...state, firstLevelTreemapValue}

		case SET_CLICKED_SECOND_LEVEL_TREEMAP_VALUE:
			const secondLevelTreemapValue = action.response.data
			return {...state, secondLevelTreemapValue}

		case SET_DRILLDOWNN:
			const {drilldownName, drilldownData} = action.response
			const drilldown = {}
			if (drilldownName && drilldownData) drilldown[drilldownName] = drilldownData
			return {...state, drilldown}
		case GET_DASHBOARD_ENTITIES_AMOUNT.triggered:
			return {
				...state,
				dashboardEntityAmountData: state.dashboardEntityAmountData,
				dashboardEntityAmountDataLoading: true,
				loading: true,
			}
		case GET_DASHBOARD_ENTITIES_AMOUNT.succeeded:
			const dashboardEntityAmountData = action.response.data
			return {
				...state,
				dashboardEntityAmountData: dashboardEntityAmountData,
				dashboardEntityAmountDataLoading: false,
				loading: state.dashboardPortfolioAnalyticsLoading,
			}

		case GET_DASHBOARD_PORTFOLIO_ANALYTICS.triggered:
			return {
				...state,
				dashboardPortfolioAnalytics: state.dashboardPortfolioAnalytics,
				dashboardPortfolioAnalyticsLoading: true,
				loading: true,
			}
		case GET_DASHBOARD_PORTFOLIO_ANALYTICS.succeeded:
			const {response} = action
			const dashboardPortfolioAnalytics = [
				{...state.dashboardPortfolioAnalytics[0], ...response[0]},
				{...state.dashboardPortfolioAnalytics[1], ...response[1]},
			]
			return {
				...state,
				dashboardPortfolioAnalytics,
				dashboardPortfolioAnalyticsLoading: false,
				loading: state.dashboardEntityAmountDataLoading,
			}

		case RESET_DASHBOARD_DATA:
			return {
				...state,
				dashboardEntityAmountData: {},
				dashboardPortfolioAnalytics: [{}, {}],
				loading: false,
			}

		case SET_TRADE_BY.triggered:
			return {...state, tradeBy: null, loading: true}
		case SET_TRADE_BY.succeeded:
			let tradeBy = JSON.parse(JSON.stringify(state.tradeBy))
			const tradeByList = action.response
			Object.keys(tradeByList).forEach(key => {
				if (key && tradeByList[key]) {
					const tradingQueueSummary = tradeByList[key].tradingQueueSummary
					if (tradeBy === null) tradeBy = {}
					tradingQueueSummary.forEach(summaryDetail => {
						const counterParty = summaryDetail.counterParty
						const userSummaries = summaryDetail.userSummaries
						if (!tradeBy[key]) tradeBy[key] = {}
						let tradeByText = `\n\nMoved by: `
						userSummaries.forEach(summary => {
							tradeByText += `\n${summary.userEmail} (${summary.entriesCount})`
						})
						tradeBy[key][counterParty] = tradeByText
					})
				}
			})
			return {
				...state,
				tradeBy,
				loading: false,
			}
		case SET_TRADE_BY.failed:
			return {...state, loading: false}

		case GET_NOTIFICATIONS.triggered:
			return {...state, loading: true}
		case GET_NOTIFICATIONS.succeeded:
			return {...state, loading: false, notifications: action.response.data}
		case GET_NOTIFICATIONS.failed:
			return {...state, loading: false}
		case GET_ALL_TRADES_FOR_DASHBOARD.triggered:
			return {...state, tradesDataLoading: true}
		case GET_ALL_TRADES_FOR_DASHBOARD.failed:
			return {...state, tradesDataLoading: false}
		case GET_ALL_TRADES_FOR_DASHBOARD.succeeded:
			state.tradeData = action.response.data ? addAmountsFromObjects(action.response.data.portfolio) : []
			return {
				...state,
				tradesDataLoading: false,
			}
	}
	return state
}
