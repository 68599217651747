import React from 'react'
import {Dropdown} from 'react-bootstrap'
import {IconInfo} from '../../styles/svg/svgIcons'
import CustomPopover from '../../common/popovers/customPopover/customPopoverContainer'
import {ArrayUtil} from 'helper-util'

interface Props {
	title?: any
	hoverTitleOnDisable?: string
	id?: string
	titleClass?: string
	ddClass?: string
	ddMenuClass?: string
	noSelectionText?: string
	selectedItem?: string
	dropdownItems?: any
	keyField?: string
	valueField: string
	onSelect?: (item: any) => void
	required?: boolean
	isInvalid?: boolean
	noTitle?: boolean
	disabled?: boolean
	iconInfo?: boolean
	optionalValueFieldOne?: string
	optionalValueFieldTwo?: string
	popoverClass?: string
	popoverIconClass?: string
	popoverAlignment?: string
	disabledFields?: Array<any>
	inputClass?: string
	autoSelect?: boolean
	inSOD?: boolean
}

export default class FsSingleSelectDropdown extends React.Component<Props, any> {
	componentDidUpdate() {
		const {dropdownItems, selectedItem, onSelect, autoSelect} = this.props
		if (ArrayUtil.getLength(dropdownItems) === 1 && !selectedItem && autoSelect) {
			onSelect(dropdownItems[0])
		}
	}
	render() {
		const {
			title = '',
			hoverTitleOnDisable = '',
			id = '',
			titleClass = '',
			ddClass = '',
			ddMenuClass = '',
			inputClass = '',
			noSelectionText = ' Select ',
			selectedItem,
			onSelect = (item: any) => {},
			dropdownItems = [],
			isInvalid = false,
			keyField,
			valueField,
			disabled = false,
			iconInfo = false,
			optionalValueFieldOne,
			optionalValueFieldTwo,
			popoverIconClass,
			popoverClass,
			disabledFields = [],
			popoverAlignment = 'bottom',
			noTitle = false,
		} = this.props

		const infoText = 'Includes data in SOD File'

		return (
			<>
				<Dropdown
					className={`${isInvalid ? 'dropdown-error' : ''} ${ddClass || ''}`}
					title={disabled ? hoverTitleOnDisable : ''}
				>
					{!noTitle && <label className={titleClass || ''}>{title}</label>}
					{iconInfo && <IconInfo />}{' '}
					{
						<CustomPopover
							id={id}
							alignment={popoverAlignment}
							popoverIconClass={popoverIconClass}
							popoverClass={popoverClass}
						/>
					}
					<Dropdown.Toggle variant='' id={id} disabled={disabled} className={inputClass}>
						{selectedItem || noSelectionText}
					</Dropdown.Toggle>
					<Dropdown.Menu className={ddMenuClass}>
						{Array.isArray(dropdownItems) &&
							dropdownItems.map(item => (
								<Dropdown.Item
									title={item.label}
									disabled={disabled || (keyField && disabledFields.includes(item[keyField]))}
									className={keyField && disabledFields.includes(item[keyField]) ? 'greyed-out' : ''}
									key={(keyField && item[keyField]) || item}
									onClick={() => onSelect(item)}
								>
									{optionalValueFieldTwo && `${item[optionalValueFieldTwo]}, `}
									{(valueField && item[valueField]) || item}{' '}
									{optionalValueFieldOne && `(${item[optionalValueFieldOne]})`}
									{optionalValueFieldOne && `(${item[optionalValueFieldOne]})`}
									{item.inSOD && (
										<i className='fa fa-floppy-o mt-1 mx-2 link f-14 text-muted' aria-hidden='true' title={infoText} />
									)}
								</Dropdown.Item>
							))}
					</Dropdown.Menu>
					{isInvalid && <div className='text-danger'>Please select appropriate value</div>}
				</Dropdown>
			</>
		)
	}
}
