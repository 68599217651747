import {
	GET_TRADING_QUEUE_DATA,
	LOCK_ENTRIES,
	RELEASE_ENTRIES,
	DELETE_ENTRIES,
	EXECUTE_RECALL_RETURN_ACTION,
	ACCEPT_REJECT_ENTRIES,
	FETCH_ACTIVITY_LOG,
	RESET_ACTIVITY_LOG,
	RESET_TRADING_QUEUE_DATA,
	EXECUTE_RE_RATE_ACTION,
} from './tradingQueueActions'

export interface TradingQueue {
	loading: boolean
	entryLoading: boolean
	activityLoading: boolean
	tradingQueueLoading: boolean
	recallReturnLoading: boolean
	data: any
	activityLog: any[]
}

const initialState: TradingQueue = {
	loading: true,
	entryLoading: false,
	activityLoading: true,
	tradingQueueLoading: false,
	recallReturnLoading: false,
	data: null,
	activityLog: [],
}

export function tradingQueueReducer(state = initialState, action: any) {
	const {tradingQueueLoading, recallReturnLoading} = state
	switch (action.type) {
		case GET_TRADING_QUEUE_DATA.triggered:
			return {...state, data: null, tradingQueueLoading: true, loading: true}
		case GET_TRADING_QUEUE_DATA.succeeded:
			const data = action.response.data && action.response.data.tradingQueueNew

			return {...state, tradingQueueLoading: false, loading: recallReturnLoading, data}
		case GET_TRADING_QUEUE_DATA.failed:
			return {
				...state,
				tradingQueueLoading: false,
				loading: recallReturnLoading,
				data: {
					firmActivitiesQueue: {incoming: [], outgpoing: []},
					userActivitiesQueue: {open: [], outgoing: []},
				},
			}

		case LOCK_ENTRIES.triggered:
			return {...state, entryLoading: true}
		case LOCK_ENTRIES.succeeded:
			return {...state, entryLoading: false}
		case LOCK_ENTRIES.failed:
			return {...state, entryLoading: false}

		case RELEASE_ENTRIES.triggered:
			return {...state, entryLoading: true}
		case RELEASE_ENTRIES.succeeded:
			return {...state, entryLoading: false}
		case RELEASE_ENTRIES.failed:
			return {...state, entryLoading: false}

		case DELETE_ENTRIES.triggered:
			return {...state, entryLoading: true}
		case DELETE_ENTRIES.succeeded:
			return {...state, entryLoading: false}
		case DELETE_ENTRIES.failed:
			return {...state, entryLoading: false}

		case EXECUTE_RECALL_RETURN_ACTION.triggered || EXECUTE_RE_RATE_ACTION.triggered:
			return {...state, recallReturnLoading: true, loading: true}
		case EXECUTE_RECALL_RETURN_ACTION.failed || EXECUTE_RE_RATE_ACTION.failed:
			return {...state, recallReturnLoading: false, loading: tradingQueueLoading}
		case EXECUTE_RECALL_RETURN_ACTION.succeeded || EXECUTE_RE_RATE_ACTION.succeeded:
			return {...state, recallReturnLoading: false, loading: tradingQueueLoading}

		case ACCEPT_REJECT_ENTRIES.triggered:
			return {...state, loading: true}
		case ACCEPT_REJECT_ENTRIES.succeeded:
			return {...state, loading: false}
		case ACCEPT_REJECT_ENTRIES.failed:
			return {...state, loading: false}

		case FETCH_ACTIVITY_LOG.triggered:
			return {...state, activityLog: [], activityLoading: true}
		case FETCH_ACTIVITY_LOG.succeeded:
			const activityLog = action.response.data && action.response.data.activityOrderLogs
			return {...state, activityLog, activityLoading: false}
		case FETCH_ACTIVITY_LOG.failed:
			return {...state, activityLoading: false}

		case RESET_TRADING_QUEUE_DATA:
			return {...state, data: null, loading: true}

		case RESET_ACTIVITY_LOG:
			return {...state, activityLog: [], activityLoading: true}
	}
	return state
}
