import {
	SET_COLLATERAL_OPTIMIZATION_CLICKED_PORTFOLIO_ENTITY,
	SET_COLLATERAL_OPTIMIZATION_CLICKED_GROUPBY_ENTITY,
	SET_COLLATERAL_OPTIMIZATION_TOGGLE_INDEX,
	SET_COLLATERAL_OPTIMIZATION_DRILLDOWN,
} from './collateralOptimizationActions'

export interface CollateralOptimization {
	loading: boolean
	clickedCollateralOptimizationGroupByEntity: any
	clickedCollateralOptimizationPortfolioEntity: string
	clickedCollateralOptimizationToggleIndex: any
	drilldown: any
}

const initialState: CollateralOptimization = {
	loading: false,
	clickedCollateralOptimizationGroupByEntity: {},
	clickedCollateralOptimizationToggleIndex: {},
	clickedCollateralOptimizationPortfolioEntity: '',
	drilldown: {},
}

export function collateralOptimizationReducer(state = initialState, action: any) {
	switch (action.type) {
		case SET_COLLATERAL_OPTIMIZATION_CLICKED_GROUPBY_ENTITY:
			const {groupEntityname, groupOne, groupTwo} = action.response
			const clickedCollateralOptimizationGroupByEntity = state.clickedCollateralOptimizationGroupByEntity
			clickedCollateralOptimizationGroupByEntity[groupEntityname] = [groupOne, groupTwo]
			return {...state, clickedCollateralOptimizationGroupByEntity}

		case SET_COLLATERAL_OPTIMIZATION_TOGGLE_INDEX:
			const {toggleName, index} = action.response
			const clickedCollateralOptimizationToggleIndex = state.clickedCollateralOptimizationToggleIndex
			clickedCollateralOptimizationToggleIndex[toggleName] = index
			return {...state, clickedCollateralOptimizationToggleIndex}

		case SET_COLLATERAL_OPTIMIZATION_CLICKED_PORTFOLIO_ENTITY:
			const clickedCollateralOptimizationPortfolioEntity = action.response.data
			return {...state, clickedCollateralOptimizationPortfolioEntity}
		case SET_COLLATERAL_OPTIMIZATION_DRILLDOWN:
			const {drilldownName, drilldownData} = action.response
			const drilldown = {}
			if (drilldownName && drilldownData) drilldown[drilldownName] = drilldownData
			return {...state, drilldown}
	}
	return state
}
