import {POST_SIMM_DATA, SET_UPLOADED_DATA, CALC_TRADES} from './SimmAction'

export interface Simm {
	loading: boolean
	computedSimmData: any
	uploadedData: any
	showTable: boolean
	uploadedTradesData: any
}

const initialState: Simm = {
	loading: false,
	computedSimmData: [],
	uploadedData: [],
	showTable: false,
	uploadedTradesData: [],
}

export function SimmReducer(state = initialState, action: any) {
	switch (action.type) {
		case POST_SIMM_DATA.triggered:
			return {...state, loading: true}
		case POST_SIMM_DATA.failed:
			return {...state, loading: false}
		case POST_SIMM_DATA.succeeded:
			state.computedSimmData = action.response.data
			state.showTable = true
			return {
				...state,
				loading: false,
			}
		case SET_UPLOADED_DATA:
			state.uploadedData = action.data
			state.showTable = false
			return {
				...state,
			}
		case CALC_TRADES.succeeded:
			state.uploadedTradesData = action.response.data
			return {
				...state,
				loading: false,
			}
	}
	return state
}
