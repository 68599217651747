import {CLEAR_COLLATERAL_BASKET, GET_COLLATERAL_BASKET} from './collateralBasketItemActions'
import {Basket} from '../collateralBasketModel'

export interface CollateralBasketData {
	data: Basket
	loading: boolean
}

const initialState: CollateralBasketData = {
	data: {} as Basket,
	loading: true,
}

export function collateralBasketItemReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_COLLATERAL_BASKET.triggered:
			return {...state, data: {}, loading: true}
		case GET_COLLATERAL_BASKET.succeeded:
			let res = action.response.data
			res = (res && res.collateralBasket) || {}
			return {...state, loading: false, data: res}
		case GET_COLLATERAL_BASKET.failed:
			return {...state, loading: false, data: {}}

		case CLEAR_COLLATERAL_BASKET:
			return {...initialState}
	}
	return state
}
