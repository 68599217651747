import {getPivotTableConfig} from '../../utils/featureUtils'
import {
	GET_ABCMRESULT_PIVOT_TABLE,
	RESET_ABCMRESULT_STATE,
	GET_ABCMRESULT_PIVOT_TABLE_FOR_CPTY_SOURCE,
	GET_ABCMRESULT_PIVOT_TABLE_FOR_CPTY_USE,
	SET_PIVOT_TABLE_CONFIG,
	SET_ABCM_DISPLAY_VIEW,
} from './abcmResultActions'
import {formatPivotTableData, csvJSON} from './abcmResultHelper'

export interface AbcmResult {
	data: any
	abcmDetailLoading: boolean
	pivotTableData: any
	loading: boolean
	pivotTableSourceData: any
	pivotTableUseData: any
	pivotTableConfig: any
	pivotTableMetric: any
	abcmFMData: any
}

const initialState: AbcmResult = {
	data: [],
	abcmDetailLoading: true,
	pivotTableData: [],
	loading: false,
	pivotTableSourceData: [],
	pivotTableUseData: [],
	pivotTableConfig: {},
	pivotTableMetric: '',
	abcmFMData: [],
}

export function abcmresultReducer(state = initialState, action: any) {
	switch (action.type) {
		case SET_PIVOT_TABLE_CONFIG:
			const pivotTableConfig = getPivotTableConfig(state.pivotTableConfig, action.response.data, action.response.metric)
			return {...state, pivotTableConfig}

		case SET_ABCM_DISPLAY_VIEW:
			const pivotTableMetric = action.response.data
			return {...state, pivotTableMetric}

		case GET_ABCMRESULT_PIVOT_TABLE.triggered:
			return {...state, loading: true, abcmDetailLoading: true}
		case GET_ABCMRESULT_PIVOT_TABLE.succeeded:
			const data = (action.response.data || {}).abcmResult || []
			const pivotTableData = formatPivotTableData(action.response.data, 'abcmResult')
			const abcmFMData = csvJSON(pivotTableData)
			return {...state, loading: false, abcmDetailLoading: false, pivotTableData, data, abcmFMData}
		case GET_ABCMRESULT_PIVOT_TABLE.failed:
			return {...state, loading: false, abcmDetailLoading: false, pivotTableData: [], data: []}

		case GET_ABCMRESULT_PIVOT_TABLE_FOR_CPTY_SOURCE.succeeded:
			const SourceData = (action.response.data || {}).abcmResultCounterParty || []
			const pivotTableSourceData = formatPivotTableData(action.response.data, 'source')
			return {...state, loading: false, pivotTableSourceData, SourceData}

		case GET_ABCMRESULT_PIVOT_TABLE_FOR_CPTY_USE.succeeded:
			const UseData = (action.response.data || {}).abcmResultCounterParty || []
			const pivotTableUseData = formatPivotTableData(action.response.data, 'use')
			return {...state, loading: false, pivotTableUseData, UseData}

		case RESET_ABCMRESULT_STATE:
			return {...initialState}
	}
	return state
}
