import React from 'react'

interface Props {
	id: string
	title?: string
	onChange: (value) => void
	checked?: boolean
	disabled?: boolean
}

export default function FsOnOffSwitch(props: Props) {
	const {title = '', onChange = () => {}, id = '', checked = false, disabled = false} = props
	return (
		<div className='d-flex'>
			<div className='custom-control custom-switch'>
				<input
					disabled={disabled}
					type='checkbox'
					checked={checked}
					className='custom-control-input'
					id={id}
					onChange={e => onChange(e.target.checked)}
				/>
				<label className='custom-control-label f-14 text-grey-5' htmlFor={id}>
					{title}
				</label>
			</div>
		</div>
	)
}
