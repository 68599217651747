import {GET_ABPM_DATA} from './ABPMActions'

export interface Abpm {
	abpmDataAndColumns: any
	abpmDataLoading: boolean
}

const initialState: Abpm = {
	abpmDataAndColumns: {},
	abpmDataLoading: false,
}

export function ABPMReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_ABPM_DATA.triggered:
			return {...state, abpmDataAndColumns: [], abpmDataLoading: true}
		case GET_ABPM_DATA.failed:
			return {...state, abpmDataAndColumns: [], abpmDataLoading: false}
		case GET_ABPM_DATA.succeeded:
			const abpmDataAndColumns = action.response.data
			return {
				...state,
				abpmDataAndColumns,
				abpmDataLoading: false,
			}
	}
	return state
}
