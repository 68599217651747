import {getPMSClient} from '../..'
import {createFetchActions} from '../../services/createActions'
import {get, put} from '../../services/createAPICall'
import {getPMSBaseUrl} from '../../services/apiService'
import {cashPositionAndFlowQuery} from './fundCashFlowTitleBarQueries'

export const RELOAD = 'RELOAD'
export const GET_COF = createFetchActions('GET_COF')
export const UPDATE_COF = createFetchActions('GET_COF')
export const GET_CASH_POSITION_AND_FLOW = createFetchActions('GET_CASH_POSITION_AND_FLOW')
export const UPDATE_CASH_POSITION = createFetchActions('UPDATE_CASH_POSITION')

export const reloadFundCashFlowData = (reload: boolean) => {
	return {
		type: RELOAD,
		response: {
			reload,
		},
	}
}

export const getCostOfFunds = () => (dispatch: any) => {
	return dispatch(get(GET_COF, `${getPMSBaseUrl()}/config/cof`))
}

export const updateCostOfFunds = (data: any) => (dispatch: any) => {
	return dispatch(put(UPDATE_COF, `${getPMSBaseUrl()}/config/cof`, data))
}

export const getCashPositionAndFlow = () => {
	return function (dispatch) {
		dispatch({
			type: GET_CASH_POSITION_AND_FLOW.triggered,
		})

		getPMSClient()
			.query({
				query: cashPositionAndFlowQuery,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_CASH_POSITION_AND_FLOW.succeeded,
					response,
				})
			})
	}
}

export const updateCashPosition = (data: number) => (dispatch: any) => {
	return dispatch(put(UPDATE_CASH_POSITION, `${getPMSBaseUrl()}/config/sod`, data))
}
