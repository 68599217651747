import {GET_DOCUMENT_OVERVIEW, GET_ERROR_REPORT, RESET_ERROR_REPORT_STATE} from './errorReportAction'

export interface ErrorReport {
	data: any[]
	overviewData: any
	loading: boolean
	overviewLoading: boolean
}

const initialState: ErrorReport = {
	data: [],
	overviewData: null,
	loading: false,
	overviewLoading: false,
}

export function errorReportReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_DOCUMENT_OVERVIEW.triggered:
			return {...state, overviewData: null, overviewLoading: true}
		case GET_DOCUMENT_OVERVIEW.succeeded:
			return {...state, overviewData: action.response.data, overviewLoading: false}
		case GET_DOCUMENT_OVERVIEW.failed:
			return {...state, overviewData: {}, overviewLoading: false}

		case GET_ERROR_REPORT.triggered:
			return {...state, loading: true}
		case GET_ERROR_REPORT.succeeded:
			return {...state, data: action.response.data, loading: false}
		case GET_ERROR_REPORT.failed:
			return {...state, loading: false}

		case RESET_ERROR_REPORT_STATE:
			return {...initialState}
	}
	return state
}
