import {connect} from 'react-redux'
import {MainState} from '../../store/mainReducer'
import {bindActionCreators} from 'redux'

import ToastNotification from './toastNotificationComponent'
import {setNotificationData} from './toastNotificationActions'

const mapStateToProps = (state: MainState) => {
	return {
		toastNotificationData: state.toastNotification.data,
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return bindActionCreators(
		{
			setNotificationData,
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(ToastNotification)
