import {getPMSClient} from '../..'
import {createFetchActions} from '../../services/createActions'
import {collateralBasketListQuery} from './collateralBasketListQueries'

export const GET_COLLATERAL_BASKET_LIST = createFetchActions('GET_COLLATERAL_BASKET_LIST')
export const CLEAR_COLLATERAL_BASKET_LIST = createFetchActions('CLEAR_COLLATERAL_BASKET_LIST')

export const getCollateralBasketList = (includeAttachedToOrder: boolean) => {
	return function (dispatch) {
		dispatch({type: GET_COLLATERAL_BASKET_LIST.triggered})
		getPMSClient()
			.query({
				query: collateralBasketListQuery(includeAttachedToOrder),
				fetchPolicy: 'network-only',
				errorPolicy: 'all',
			})
			.then(response => {
				dispatch({
					type: GET_COLLATERAL_BASKET_LIST.succeeded,
					response,
				})
			})
	}
}

export const clearCollateralBasketList = () => {
	return {
		type: CLEAR_COLLATERAL_BASKET_LIST,
	}
}
