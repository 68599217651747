import {GET_FM_WIDGETS, SAVE_FM_WIDGETS, DELETE_FM_WIDGETS, EMPTY_FM_WIDGETS} from './FmWidgetActions'

export interface FmWidgets {
	loading: boolean
	widgetsList: any
}

const initialState: FmWidgets = {
	loading: false,
	widgetsList: [],
}

export function fmWidgetsReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_FM_WIDGETS.triggered:
			return {...state, loading: true}
		case GET_FM_WIDGETS.failed:
			return {...state, loading: false}
		case GET_FM_WIDGETS.succeeded:
			const widgetsList = action.response.data
			return {
				...state,
				widgetsList,
				loading: false,
			}

		case SAVE_FM_WIDGETS.triggered:
			return {...state, loading: true}
		case SAVE_FM_WIDGETS.failed:
			return {...state, loading: false}
		case SAVE_FM_WIDGETS.succeeded:
			return {
				...state,
				loading: false,
			}

		case DELETE_FM_WIDGETS.triggered:
			return {...state, loading: true}
		case DELETE_FM_WIDGETS.failed:
			return {...state, loading: false}
		case DELETE_FM_WIDGETS.succeeded:
			return {
				...state,
				loading: false,
			}

		case EMPTY_FM_WIDGETS:
			return {
				...state,
				widgetsList: [],
			}
	}
	return state
}
