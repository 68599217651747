import {START_LOADING, STOP_LOADING} from './changePaswordActions'

export interface ChangePassword {
	loading: boolean
}

const initialState: ChangePassword = {
	loading: false,
}

export function changePasswordReducer(state = initialState, action: any) {
	switch (action.type) {
		case START_LOADING:
			return {...state, loading: true}
		case STOP_LOADING:
			return {...state, loading: false}
	}

	return state
}
