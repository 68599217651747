import {GET_MKT_REPORTS, SAVE_MKT_REPORTS, DELETE_MKT_REPORTS, SET_MKT_TABLE_TYPE} from './marketDataActions'

export interface MarketData {
	reports: any
	selectedTableName: any
}

const initialState: MarketData = {
	reports: [],
	selectedTableName: {type: 'Reference Rates'},
}

export function marketDataReducer(state = initialState, action: any) {
	switch (action.type) {
		case SET_MKT_TABLE_TYPE:
			state.selectedTableName.type = action.data
			return {...state}
		case GET_MKT_REPORTS.triggered:
			return {...state}
		case GET_MKT_REPORTS.failed:
			return {...state}
		case GET_MKT_REPORTS.succeeded:
			state.reports = action.response.data
			return {
				...state,
			}
		case SAVE_MKT_REPORTS.triggered:
			return {...state}
		case SAVE_MKT_REPORTS.failed:
			return {...state}
		case SAVE_MKT_REPORTS.succeeded:
			state.reports = action.response.data.items
			return {
				...state,
			}
		case DELETE_MKT_REPORTS.triggered:
			return {...state, loading: true}
		case DELETE_MKT_REPORTS.failed:
			return {...state, loading: false}
		case DELETE_MKT_REPORTS.succeeded:
			state.reports = action.response.data.items
			return {
				...state,
				loading: false,
			}
	}

	return state
}
