export const fieldConstants = {
	INDICATIVERATEBPS: 'indicativeRateBps',
	APPROVEDQTY: 'approvedQuantity',
}

export const configConstants = {
	SETTINGS: 'Settings',
	AUTO_APPROVE: 'Auto Approve',
	OFF: 'OFF',
	ON: 'ON',
	TOTAL_AVAILABLE_INVENTORY: '% of Total Available Inventory',
	MAXIMUM_QUANTITY_LIMIT: 'Maximum Quantity Limit',
	GC_RATE: 'GC Rate (bps)',
	CLIENT_CONFIRMATION_REQUIRED: 'Client Confirmation Required',
	IS_AUTO_LOCATE_FIELD: 'isAutoLocate',
	INVENTORY_PERCENTAGE_FIELD: 'inventoryPercentage',
	MAXIMUM_QUANTITY_FIELD: 'maximumQuantity',
	GC_RATE_FIELD: 'gcRate',
	IS_CLIENT_CONFIRMATION_REQUIRED_FIELD: 'isClientConfirmationRequired',
}

export const warningMsg = {
	SELECT_COUNTERPARTY: 'Please Select Counterparty',
	FIELD_MISSING_VALIDATION:
		'Security Id, Quantity, Locate Type or End Date is missing. Please check before you submit.',
	SELECT_VALID_FILE: 'Please select valid file',
	SELECT_VALID_SECURITY: 'Invalid security / Not from locate inventory',
}

export const securityTypeConstants = {
	WARM: 'WARM',
	HTB: 'HTB',
	WTC: 'WTC',
	RES: 'RES',
}

export const initialNewRequestRowData = [
	{
		securityId: '',
		ticker: '',
		requestedQuantity: '',
		delete: true,
		locateRequestType: 'LOCATE',
		endDate: '',
		index: 0,
		securityFocus: false,
		quantityFocus: false,
		showYield: false,
	},
	{
		securityId: '',
		ticker: '',
		requestedQuantity: '',
		delete: true,
		locateRequestType: 'LOCATE',
		endDate: '',
		index: 1,
		securityFocus: false,
		quantityFocus: false,
		showYield: false,
	},
	{
		securityId: '',
		ticker: '',
		requestedQuantity: '',
		delete: true,
		locateRequestType: 'LOCATE',
		endDate: '',
		index: 2,
		securityFocus: false,
		quantityFocus: false,
		showYield: false,
	},
	{
		securityId: '',
		ticker: '',
		requestedQuantity: '',
		delete: true,
		locateRequestType: 'LOCATE',
		endDate: '',
		index: 3,
		securityFocus: false,
		quantityFocus: false,
		showYield: false,
	},
	{
		securityId: '',
		ticker: '',
		requestedQuantity: '',
		delete: true,
		locateRequestType: 'LOCATE',
		endDate: '',
		index: 4,
		securityFocus: false,
		quantityFocus: false,
		showYield: false,
	},
	{
		securityId: '',
		ticker: '',
		requestedQuantity: '',
		delete: false,
		locateRequestType: 'LOCATE',
		endDate: '',
		index: 5,
		securityFocus: false,
		quantityFocus: false,
		showYield: false,
	},
]

export const locateTypeRadioList = [
	{
		id: 'LOCATE',
		value: 'LOCATE',
		label: 'Locate',
	},
	{
		id: 'BORROW',
		value: 'BORROW',
		label: 'Borrow',
	},
	{
		id: 'PTH',
		value: 'PTH',
		label: 'Pay To Hold',
	},
]
