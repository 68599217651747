import gql from 'graphql-tag'

export const getDeposQuery = tenantId => gql`
	query {
		allDepos (tenantId:"${tenantId}") {
      tenantId
      deposId
      id
      deposType
      deposValue
      orgId
      legalEntities {
          legalEntityId
          id
          fullName
          shortName
          legalEntityExternalId
          entityType
          entitySubType
          businessSector
          country
          tenantId
      }
		}
	}
`
