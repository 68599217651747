import {buildQuery} from 'axios-graphql-builder'
import {tradeDetailsEntries} from '../../../features/tradeDetails/tradeDetailsQueries'

export const getActivityTypeQuery = (id: string) => {
	const activityType = {
		query: {
			history: {
				original: {...tradeDetailsEntries},
				history: {...tradeDetailsEntries},
			},
		},
	}

	const paramList = [
		{
			key: 'history',
			param: `id: ${JSON.stringify(id)}`,
		},
	]

	return buildQuery(activityType, paramList)
}
