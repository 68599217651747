import {ROLL_MATURING_TRADES} from './MaturityTradeTableActions'

export interface MaturityTradeTable {
	loading: boolean
}

const initialState: MaturityTradeTable = {
	loading: true,
}

export function maturityTradeTableReducer(state = initialState, action: any) {
	switch (action.type) {
		case ROLL_MATURING_TRADES.triggered:
			return {...state, loading: true}
		case ROLL_MATURING_TRADES.failed:
			return {...state, loading: false}
		case ROLL_MATURING_TRADES.succeeded:
			return {...state, loading: false}
	}
	return state
}
