import * as Sentry from '@sentry/browser'

export const HTTP_ERROR = 'HTTP_OTHER_ERROR'
export const GRAPHQL_ERROR = 'GRAPHQL_ERROR'
export const ERROR_RESET = 'ERROR_RESET'

const executeCustomError = (type, props) => {
	return {
		type,
		props: props,
	}
}

const executeHTTPHandler = (msg, errorCode) => {
	return {
		type: HTTP_ERROR,
		props: {msg, errorCode},
	}
}

export const executeGraphQLErrorHandler = (msg = '', errorCode = '') => {
	return {
		type: GRAPHQL_ERROR,
		props: {msg, errorCode},
	}
}

export const resetError = () => {
	return {
		type: ERROR_RESET,
	}
}

export const handleHTTPError = error => {
	const {
		response: {response = {}},
		type,
		correlationId,
	} = error

	// unused as of now, enables us create handler on the basis of status code
	const {data = {}} = response
	const errorMessage = error && error.action && error.action.actionData && error.action.actionData.errorMessage
	let message = data.message ? data.message : errorMessage ? errorMessage : ''
	const errCode = data.code
	Sentry.captureMessage({...message, correlationId})
	if (error && error.action && error.action.params === 'skipErrorModal') {
		message = ''
	}
	executeCustomError(type, message)
	return executeHTTPHandler(message, errCode)
}
