import {UPLOAD_ASSET_VALUE_FILE, GET_ALL_UPLOADED_ASSET_VALUE_FILES} from './AssetValuesActions'

export interface AssetValues {
	loading: boolean
	allFilesData: any
}

const initialState: AssetValues = {
	loading: false,
	allFilesData: {},
}

export function AssetValuesReducer(state = initialState, action: any) {
	switch (action.type) {
		case UPLOAD_ASSET_VALUE_FILE.triggered:
			return {...state, loading: true}
		case UPLOAD_ASSET_VALUE_FILE.failed:
			return {...state, loading: false}
		case UPLOAD_ASSET_VALUE_FILE.succeeded:
			return {
				...state,
				loading: false,
			}

		case GET_ALL_UPLOADED_ASSET_VALUE_FILES.triggered:
			return {...state, loading: true}
		case GET_ALL_UPLOADED_ASSET_VALUE_FILES.failed:
			return {...state, loading: false}
		case GET_ALL_UPLOADED_ASSET_VALUE_FILES.succeeded:
			return {
				...state,
				allFilesData: action.response.data,
				loading: false,
			}
	}
	return state
}
