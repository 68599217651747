import React, {useState} from 'react'
import {Form} from 'react-bootstrap'

const CreateMenu = props => {
	const {menuCreationInProgress, handleMenuSubmit, handleCancel, handleCreate} = props
	const [menuName, setMenuName] = useState('')

	const handleChange = (event: any) => {
		setMenuName(event.target.value)
	}

	const handleSubmit = () => {
		handleMenuSubmit(menuName)
	}

	return (
		<div>
			{menuCreationInProgress ? (
				<div className='px-22 py-30 create-new-menu-item'>
					<Form.Group className='custom-input'>
						<div className='d-flex justify-content-between'>
							<label className='dd-label mandatory'>Menu name</label>
							<span className='f-12 max-char-length'>Max 15 characters</span>
						</div>
						<Form.Control
							type='text'
							name='menuName'
							value={menuName}
							className='form-control'
							onChange={handleChange}
							maxLength={15}
						/>
					</Form.Group>
					<div>
						<button
							className='btn btn-sm btn-outline-dark'
							onClick={() => {
								setMenuName('')
								handleCancel()
							}}
						>
							Cancel
						</button>
						<button className='btn btn-sm btn-secondary ml-20' onClick={handleSubmit}>
							Done
						</button>
					</div>
				</div>
			) : (
				<span className='px-22 py-30 create-new-menu-item f-15 cursor-pointer d-block' onClick={handleCreate}>
					+ Create New
				</span>
			)}
		</div>
	)
}

export default CreateMenu
