import {createFetchActions} from '../../services/createActions'
import {post, put, del} from '../../services/createAPICall'
import {getPMSBaseUrl} from '../../services/apiService'
import {getPMSClient} from '../..'
import {getCalendarEventsQuery} from './settlementCalendarQueries'

export const GET_CALENDAR_EVENTS = createFetchActions('GET_CALENDAR_EVENTS')
export const NEW_CALENDAR_EVENT = createFetchActions('NEW_CALENDAR_EVENT')
export const EDIT_CALENDAR_EVENT = createFetchActions('EDIT_CALENDAR_EVENT')
export const DELETE_CALENDAR_EVENT = createFetchActions('DELETE_CALENDAR_EVENT')
export const RESET_SETTLEMENT_CALENDAR = 'RESET_SETTLEMENT_CALENDAR'

export const getCalendarEventsData = (year, month, counterparty, currency, tradeType) => {
	return function (dispatch) {
		dispatch({type: GET_CALENDAR_EVENTS.triggered})
		getPMSClient()
			.query({
				query: getCalendarEventsQuery(year, month, counterparty, currency, tradeType),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_CALENDAR_EVENTS.succeeded,
					response,
				})
			})
	}
}

export const postNewCalendarEvent = (req: any) => (dispatch: any) => {
	return dispatch(post(NEW_CALENDAR_EVENT, `${getPMSBaseUrl()}/v1/calendar-events/`, req))
}

export const editCalendarEvent = (req: any) => (dispatch: any) => {
	return dispatch(put(EDIT_CALENDAR_EVENT, `${getPMSBaseUrl()}/v1/calendar-events/`, req))
}

export const deleteCalendarEvent = (id: string) => (dispatch: any) => {
	return dispatch(del(DELETE_CALENDAR_EVENT, `${getPMSBaseUrl()}/v1/calendar-events/${id}`))
}

export const resetSettlementCalendar = () => {
	return {
		type: RESET_SETTLEMENT_CALENDAR,
	}
}
