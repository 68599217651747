import {UPLOADED_FILES_LIST, DOWNLOAD_OLD_FILE} from './TradeUploadAction'

export interface TradeUpload {
	uploadFilesList: any[]
	loading: boolean
}

const initialState: TradeUpload = {
	uploadFilesList: [],
	loading: false,
}

export function TradeUploadReducer(state = initialState, action: any) {
	switch (action.type) {
		case UPLOADED_FILES_LIST.triggered:
		case UPLOADED_FILES_LIST.failed:
			return {...state, uploadFilesList: []}
		case UPLOADED_FILES_LIST.succeeded:
			return {...state, uploadFilesList: action.response.data}

		case DOWNLOAD_OLD_FILE.triggered:
			return {...state, loading: true}
		case DOWNLOAD_OLD_FILE.succeeded:
		case DOWNLOAD_OLD_FILE.failed:
			return {...state, loading: false}
	}
	return state
}

