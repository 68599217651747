import {createFetchActions} from '../../../services/createActions'
import {post, put} from '../../../services/createAPICall'
import {getConfigBaseUrl} from '../../../services/apiService'
import {getConfigClient} from '../../..'
import {getCounterpartyId} from '../../../services/authService'
import {getSubBusinessUnitsQuery} from './subBusinessUnitQueries'

export const GET_SUB_BUSINESS_UNITS = createFetchActions('GET_SUB_BUSINESS_UNITS')
export const SAVE_SUB_BUSINESS_UNITS = createFetchActions('SAVE_SUB_BUSINESS_UNITS')
export const EDIT_SUB_BUSINESS_UNITS = createFetchActions('EDIT_SUB_BUSINESS_UNITS')
export const CLEAR_SUB_BUSINESS_UNITS = createFetchActions('CLEAR_SUB_BUSINESS_UNITS')

export const getSubBusinessUnits = () => {
	return function (dispatch) {
		dispatch({type: GET_SUB_BUSINESS_UNITS.triggered})
		getConfigClient()
			.query({
				query: getSubBusinessUnitsQuery,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_SUB_BUSINESS_UNITS.succeeded,
					response,
				})
			})
	}
}

export const saveSubBusinessUnit = (data: any) => (dispatch: any) => {
	return dispatch(
		post(
			SAVE_SUB_BUSINESS_UNITS,
			`${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/subbusinessunits`,
			data
		)
	)
}

export const editSubBusinessUnit = (data: any) => (dispatch: any) => {
	return dispatch(
		put(
			EDIT_SUB_BUSINESS_UNITS,
			`${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/subbusinessunits/${data.subBusinessUnitId}`,
			data
		)
	)
}

export const clearSubBusinessUnit = () => {
	return {
		type: CLEAR_SUB_BUSINESS_UNITS,
	}
}
