import {createFetchActions} from '../../services/createActions'
import {post} from '../../services/createAPICall'
import {getPMSBaseUrl} from '../../services/apiService'
export const POST_SIMM_DATA = createFetchActions('POST_SIMM_DATA')
export const SET_UPLOADED_DATA = 'SET_UPLOADED_DATA'
export const CALC_TRADES = createFetchActions('CALC_TRADES')

export const postSimmData = req => (dispatch: any) => {
	return dispatch(
		post(
			POST_SIMM_DATA,
			//`http://localhost:8080/postdata`,
			//`https://eb303907.ngrok.io/postdata`,
			// 'https://testfsroute.serveo.net/postdata',
			`${getPMSBaseUrl()}/calcsimm`,
			req,
			null,
			'Invalid simm file uploaded'
		)
	)
}
export const setUploadedData = data => (dispatch: any) => {
	return dispatch({type: 'SET_UPLOADED_DATA', data: data})
}
export const calcTrades = req => (dispatch: any) => {
	return dispatch(
		post(
			CALC_TRADES,
			`http://localhost:8080/calctrades`,
			//`https://eb303907.ngrok.io/postdata`,
			// 'https://testfsroute.serveo.net/postdata',
			//`${getPMSBaseUrl()}/calctrades`,
			req,
			null,
			'Invalid simm file uploaded'
		)
	)
}
