import {GET_USER_TENANT_DATA} from './tenantSelectionAction'

export interface Tenant {
	tenantData: any
}

const initialState: Tenant = {
	tenantData: [],
}

export function tenantReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_USER_TENANT_DATA.triggered:
			return {...state}

		case GET_USER_TENANT_DATA.succeeded:
			return {...state, tenantData: action.response.data}

		case GET_USER_TENANT_DATA.failed:
			return {...state, tenantData: []}
	}
	return state
}
