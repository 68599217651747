import {createFetchActions} from '../../../services/createActions'
import {get, put} from '../../../services/createAPICall'
import {getConfigBaseUrl} from '../../../services/apiService'
import {getConfigClient} from '../../..'
import {getCurrenciesQuery, getCountriesQuery, getTimezoneQuery} from './myOrgInfoQueries'
import {getCounterpartyId} from '../../../services/authService'

export const GET_ORG_INFO = createFetchActions('GET_ORG_INFO')
export const EDIT_ORG_INFO = createFetchActions('EDIT_ORG_INFO')
export const GET_CURRENCIES = createFetchActions('GET_CURRENCIES')
export const GET_COUNTRIES = createFetchActions('GET_COUNTRIES')
export const GET_TIMEZONES = createFetchActions('GET_TIMEZONES')
export const CLEAR_ORG_INFO_DATA = 'CLEAR_ORG_INFO_DATA'

export const getOrgInfo = () => (dispatch: any) => {
	return dispatch(get(GET_ORG_INFO, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/`))
}

export const editOrgInfo = (data: any) => (dispatch: any) => {
	return dispatch(put(EDIT_ORG_INFO, `${getConfigBaseUrl()}/v2/config/tenants/${data.tenantId}`, data))
}

export const clearOrgInfoData = () => {
	return {
		type: CLEAR_ORG_INFO_DATA,
	}
}

export const getCurrencies = () => {
	return function (dispatch) {
		dispatch({type: GET_CURRENCIES.triggered})
		getConfigClient()
			.query({
				query: getCurrenciesQuery,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_CURRENCIES.succeeded,
					response,
				})
			})
	}
}

export const getCountries = () => {
	return function (dispatch) {
		dispatch({type: GET_COUNTRIES.triggered})
		getConfigClient()
			.query({
				query: getCountriesQuery,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_COUNTRIES.succeeded,
					response,
				})
			})
	}
}

export const getTimezones = () => {
	return function (dispatch) {
		dispatch({type: GET_TIMEZONES.triggered})
		getConfigClient()
			.query({
				query: getTimezoneQuery,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_TIMEZONES.succeeded,
					response,
				})
			})
	}
}
