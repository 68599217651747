import {
	FETCH_INDEX_ANALYSIS_DATA,
	FETCH_TRACKING_ANALYSIS_DATA,
	DELETE_TRACKING_BASKET_ENTITY_DATA,
	FETCH_IOM_DATA,
	DELETE_INDEX_ANALYSIS_IOM_DATA,
	FETCH_INDEX_ANALYSIS_LONG_DATA,
	FETCH_INDEX_ANALYSIS_SHORT_DATA,
} from './indexAnalysisAction'
import {addTrackingBasketData, deleteTrackingBasketData} from './indexAnalysisHelper'

export interface IndexAnalysis {
	indexAnalysisdata: any
	isLoading: boolean
	trackingBasketData: any
	trackingBasketLoading: boolean
	indexAnalysisIOMData: any
	isAssetLongDataLoading: boolean
	isAssetShortDataLoading: boolean
}
const initialState: IndexAnalysis = {
	indexAnalysisdata: {},
	isLoading: false,
	trackingBasketData: [],
	trackingBasketLoading: false,
	indexAnalysisIOMData: null,
	isAssetLongDataLoading: false,
	isAssetShortDataLoading: false,
}
export function IndexAnalysisReducer(state = initialState, action: any) {
	switch (action.type) {
		case FETCH_INDEX_ANALYSIS_DATA.triggered:
			return {...state, isLoading: true}

		case FETCH_INDEX_ANALYSIS_DATA.succeeded || FETCH_INDEX_ANALYSIS_DATA.failed:
			state.indexAnalysisdata = action.response.data && action.response.data
			return {...state, isLoading: false}

		case FETCH_TRACKING_ANALYSIS_DATA.triggered:
			return {...state, trackingBasketLoading: true}

		case FETCH_TRACKING_ANALYSIS_DATA.failed:
			return {...state, trackingBasketLoading: false}

		case FETCH_TRACKING_ANALYSIS_DATA.succeeded:
			const data = action.response.data && action.response.data
			const resultData = addTrackingBasketData(data, state.trackingBasketData)
			return {...state, trackingBasketLoading: false, trackingBasketData: resultData}

		case DELETE_TRACKING_BASKET_ENTITY_DATA:
			const filteredData = deleteTrackingBasketData(state.trackingBasketData, action.data)
			return {...state, trackingBasketData: filteredData}

		case FETCH_IOM_DATA.triggered:
			return {...state, isLoading: true}

		case FETCH_IOM_DATA.succeeded || FETCH_IOM_DATA.failed:
			state.indexAnalysisIOMData = action.response.data && action.response.data['strdata'] ? action.response.data : null
			return {...state, isLoading: false}

		case DELETE_INDEX_ANALYSIS_IOM_DATA:
			return {...state, indexAnalysisIOMData: null}

		case FETCH_INDEX_ANALYSIS_LONG_DATA.triggered:
			return {...state, isAssetLongDataLoading: true}

		case FETCH_INDEX_ANALYSIS_LONG_DATA.succeeded || FETCH_INDEX_ANALYSIS_LONG_DATA.failed:
			state.indexAnalysisdata = action.response.data && action.response.data
			return {...state, isAssetLongDataLoading: false}

		case FETCH_INDEX_ANALYSIS_SHORT_DATA.triggered:
			return {...state, isAssetShortDataLoading: true}

		case FETCH_INDEX_ANALYSIS_SHORT_DATA.succeeded || FETCH_INDEX_ANALYSIS_SHORT_DATA.failed:
			state.indexAnalysisdata = action.response.data && action.response.data
			return {...state, isAssetShortDataLoading: false}
	}

	return state
}
