import gql from 'graphql-tag'

export const getAllFilesQuery = gql`
	query {
		gcDocuments {
			uid
			originalFileName
			isError
			errorList
			entries {
				transformationRateDaily
				transformationIndexAnnualized1
				transformationIndexDaily1
				transformationIndexAnnualized2
				transformationIndexDaily2
				transformationIndexSpreadAnnual
				transformationIndexSpreadDaily
				rebateRateDaily
				rebateIndexYieldAnnualized1
				rebateIndexYieldDaily1
				rebateIndexYieldAnnualized2
				rebateIndexYieldDaily2
				rebateSpreadAnnual
				rebateSpreadDaily
				feeRateDaily
				feeRate1Daily
				feeAnchor1
				fee2Daily
				fee2Anchor
				uid
				businessCode
				securityId
				referenceRate
				modelRate
				feeSplit
				isError
				__typename
			}
			__typename
		}
	}
`
