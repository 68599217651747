import memoizeOne from 'memoize-one'
import isDeepEqual from 'lodash.isequal'
import {ArrayUtil} from 'helper-util'
import {isValidFile} from '../../../../utils/featureUtils'
import {fieldOption, maturityOption, strategyOption} from './OrderDetailsConstant'
import {getNotional, getEndCash, getRepoInterest, getAllInPrice, getStartCash} from '../../../../utils/calculationUtil'
import {getQuantity, getAllInPriceRepoBlotter} from '../../../../utils/calculationUtil'
import {getStartCashRepoBlotter, getQuantityRepoBlotter} from '../../../../utils/calculationUtil'
import {roundUpToQuater} from '../../../TradeBlotter/NewTradeModal/NewTradeModalHelper'
import {FormatUtil} from 'helper-util'

export const getBenchmarkOption = (benchmark: any[]) => {
	const benchmarkOption = []
	!ArrayUtil.isEmpty(benchmark) &&
		benchmark.forEach(item => {
			const benchmarkItem = {
				id: `benchmark-${item.value}`,
				label: item.name,
				value: item.value,
			}
			benchmarkOption.push(benchmarkItem)
		})
	return benchmarkOption
}

export const getCounterpartyOptionSTT = (
	counterparty: any[],
	legalEntityList?: any,
	legalEntityExternalId?: string,
	clientRiskType?: any,
	clientContractType?: any
) => {
	const counterpartyOption = []
	!ArrayUtil.isEmpty(counterparty) &&
		counterparty.forEach(item => {
			const clientLeIdList = !ArrayUtil.isEmpty(item.legalEntities)
				? item.legalEntities.map(le => le.legalEntityId)
				: []
			const clientRiskTypeList = !ArrayUtil.isEmpty(item.legalEntities)
				? item.legalEntities.map(le => le.clientRiskType)
				: []
			const selectedLe = legalEntityList.find(le => le.legalEntityExternalId === legalEntityExternalId)
			if (
				(clientRiskType &&
					clientContractType &&
					item.contractTypes &&
					item.contractTypes.includes(clientContractType) &&
					clientLeIdList.includes(selectedLe && selectedLe.id) &&
					clientRiskTypeList.includes(clientRiskType)) ||
				!clientRiskType ||
				!clientContractType
			) {
				const counterpartyItem = {
					id: item.clientId,
					label: item.fullName,
					value: item.clientId,
				}
				counterpartyOption.push(counterpartyItem)
			}
		})
	return counterpartyOption
}

export const getCounterpartyOption = (counterparty: any[], clientRiskType?: any) => {
	const counterpartyOption = []
	!ArrayUtil.isEmpty(counterparty) &&
		counterparty.forEach(item => {
			if ((clientRiskType && clientRiskType === item.clientRiskType) || !clientRiskType) {
				const counterpartyItem = {
					id: item.clientId,
					label: item.fullName,
					value: item.clientId,
				}
				counterpartyOption.push(counterpartyItem)
			}
		})
	return counterpartyOption
}

const scheduleOptionSortingSequence = {
	'US Treasuries': 1,
	'US Treasuries, Agencies and Agency MBS': 2,
	'Investment Grade Corporates 1': 3,
	'Investment Grade Corporates 2': 4,
}

export const getScheduleOption = (schedule: any[]) => {
	const scheduleOption = []
	!ArrayUtil.isEmpty(schedule) &&
		schedule.forEach((item, itemIndex) => {
			const scheduleItem = {
				id: `schedule-${itemIndex}`,
				label: item,
				value: item,
				sequenceId: scheduleOptionSortingSequence[item],
			}
			scheduleOption.push(scheduleItem)
		})
	return scheduleOption.sort((a, b) => a.sequenceId - b.sequenceId)
}

export const getCollateralBasketOption = (collateralBasket: any[]) => {
	const collateralBasketOption = []
	!ArrayUtil.isEmpty(collateralBasket) &&
		collateralBasket.forEach(item => {
			const collateralBasketItem = {
				id: item.collateralBasketId,
				label: item.name,
				value: item.collateralBasketId,
				notional: Math.round(item.notionalAmount || 0),
				startCash: Math.round(item.startCashAmount || 0),
				book: item.book,
				counterParty: item.counterParty,
				schedule: item.schedule,
			}
			collateralBasketOption.push(collateralBasketItem)
		})
	return collateralBasketOption
}

export const getErrorMessage = (securities: any[]) => {
	const threshold = 5

	if (!ArrayUtil.isEmpty(securities)) {
		if (securities.length < threshold) return `Error(s): ${securities.join(', ')}`

		const additionalError = securities.length - threshold
		return `Error(s): ${securities.slice(0, threshold).join(', ')}${
			additionalError > 0 ? ` + ${additionalError} more ...` : ''
		}`
	}

	return 'Contain some error(s)'
}

export const getNotionalData = (securityDetails, price, quantity, collateralBasket, collateralType) => {
	let priceMultiplier = securityDetails && securityDetails.priceMultiplier
	if (collateralType === 'DECIDE_LATER') priceMultiplier = 1
	return collateralBasket ? Math.round(collateralBasket.notional) : getNotional(price, priceMultiplier, quantity)
}

export const getDirtyPriceData = (_statergy, marketPrice, margin) => {
	return getAllInPrice(marketPrice, margin)
}
export const getDirtyPriceDataRepoBlotter = (_statergy, marketPrice, margin) => {
	return getAllInPriceRepoBlotter(marketPrice, margin)
}

export const getDirtyPriceDataForNonSttSbl = (marketPrice, margin) => {
	return roundUpToQuater((marketPrice * margin) / 100)
}

export const getContractValueForNonSttSbl = (allInPrice, quantity, securityDetails) => {
	const priceMultiplier = (securityDetails && securityDetails.priceMultiplier) || 1
	return allInPrice * quantity * priceMultiplier
}

export const getStartCashData = (quantity, dirtyPrice, securityDetails, statergy?, collateralBasket?) => {
	let priceMultiplier = securityDetails && securityDetails.priceMultiplier
	return collateralBasket
		? Math.round(collateralBasket.startCash)
		: getStartCash(quantity, dirtyPrice ? Number(Number(dirtyPrice).toFixed(6)) : 0, priceMultiplier, false)
}

export const getStartCashDataRepoBlotter = (quantity, allInPrice) => {
	return getStartCashRepoBlotter(quantity, allInPrice)
}

export const getStartCashForOutrightTsy = (currentValue, quantity, allInPrice) => {
	return quantity && allInPrice ? (allInPrice * quantity) / 100 : currentValue
}

export const getProceedsAmount = (par = 0, discountAmount = 0) => {
	return Math.round(Number(par) - Number(discountAmount))
}

export const getImpliedAllInRate = (discountAmount = 0, proceeds = 0, tenor = '0') => {
	return Number(((discountAmount / proceeds / Number(tenor)) * 360 * 100).toFixed(2))
}

export const getDiscountAmount = (par = 0, discountRate = 0, tenor = '0') => {
	return Number((((Number(par) * Number(discountRate)) / 360 / 100) * Number(tenor)).toFixed(2))
}

export const getRepoInterestData = (startCash, allInRate, duration, statergy) => {
	if (!(statergy && statergy.value === fieldOption.STRATEGY.REPO)) return null
	return getRepoInterest(startCash, allInRate, duration)
}

export const getEndCashData = (statergy, startCash, repoInterest) => {
	if (!(statergy && statergy.value === fieldOption.STRATEGY.REPO)) return null
	return getEndCash(startCash ? Number(startCash.toFixed(6)) : 0, repoInterest, false)
}

export const onFileUpload = (files: any, onLoad) => {
	if (files.length && isValidFile(files[0].name)) {
		onLoad(files[0])
	}
}

export const getMaturityType = memoizeOne(disableList => {
	return maturityOption.filter(maturityOption => !disableList.includes(maturityOption.id))
}, isDeepEqual)

export const getStrategyType = memoizeOne(disableList => {
	return strategyOption.filter(stratigyOption => !disableList.includes(stratigyOption.id))
}, isDeepEqual)

export const sampleCBDownloadProps = () => {
	return {
		data: [{securityId: 'US001055AM48', quantity: '34000'}],
		headers: [
			{label: 'SECURITY ID', key: 'securityId'},
			{label: 'QUANTITY', key: 'quantity'},
		],
		filename: 'Sample_Collateral_Basket',
		className: 'f-12',
		buttonTextContent: 'Sample collateral basket CSV file',
	}
}

export const getQuantityFromStartCash = (startCash = 0, dirtyPrice = 0, securityDetails) => {
	let priceMultiplier = securityDetails && securityDetails.priceMultiplier
	return getQuantity(startCash, dirtyPrice, priceMultiplier)
}

export const getQuantityFromStartCashRepoBlotter = (startCash = 0, allInPrice = 0) => {
	return getQuantityRepoBlotter(startCash, allInPrice)
}

export const getExternalProviderRefId = (legalEntityList, legalEntityExternalId) => {
	const refId =
		!ArrayUtil.isEmpty(legalEntityList) && legalEntityExternalId
			? legalEntityList.find(le => le.legalEntityExternalId === legalEntityExternalId).externalProviderRefId
			: null
	return refId && refId.length > 0 ? refId : null
}

export const getLabelKey = (allowAllInRate, option) => {
	return !allowAllInRate
		? `${FormatUtil.text.toUpperCase(String(option.type))}`
		: `${FormatUtil.text.toUpperCase(String(option.type))}-${option.tenor}`
}
