import {FETCH_RESPONSE_ACTIVITY_LOG} from './responseActivityLogActions'
import {ActivityLog} from './responseActivityLogModel'

export interface ResponseActivityLog {
	data: ActivityLog[]
	loading: boolean
	responseId?: string
}

const initialState: ResponseActivityLog = {
	data: [],
	loading: false,
	responseId: '',
}

export function responseActivityLogReducer(state = initialState, action: any) {
	switch (action.type) {
		case FETCH_RESPONSE_ACTIVITY_LOG.triggered:
			return {...state, loading: true}

		case FETCH_RESPONSE_ACTIVITY_LOG.succeeded:
			const {data, responseId} = action.response
			const res = data.orderCollaborationLogs || []
			return {...state, loading: false, data: res, responseId}
	}
	return state
}
