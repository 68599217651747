import {createFetchActions} from '../../../services/createActions'
import {post, put, get} from '../../../services/createAPICall'
import {getConfigBaseUrl, getMDSBaseUrl} from '../../../services/apiService'
import {getConfigClient} from '../../..'
import {getMyClientsQuery} from './myClientsQueries'
import {getCounterpartyId} from '../../../services/authService'

export const GET_MY_CLIENTS = createFetchActions('GET_MY_CLIENTS')
export const SAVE_MY_CLIENTS = createFetchActions('SAVE_MY_CLIENTS')
export const EDIT_MY_CLIENTS = createFetchActions('EDIT_MY_CLIENTS')
export const GET_ALL_CLIENTS = createFetchActions('GET_ALL_CLIENTS')

export const SEARCH_LEGAL_ENTITY = createFetchActions('SEARCH_LEGAL_ENTITY')
export const SEARCH_LEGAL_ENTITY_DETAIL = createFetchActions('SEARCH_LEGAL_ENTITY_DETAIL')
export const SET_SELECTED_LEGAL_SEARCH = createFetchActions('SET_SELECTED_LEGAL_SEARCH')
export const SET_SELECTED_CLIENT = createFetchActions('SET_SELECTED_CLIENT')
export const GET_SELECTED_CLIENT = createFetchActions('GET_SELECTED_CLIENT')
export const CLEAR_SELECTED_CLIENT = createFetchActions('CLEAR_SELECTED_CLIENT')

export const GET_DEPOS_TYPES = createFetchActions('GET_DEPOS_TYPES')
export const GET_AGREEMENT_TYPES = createFetchActions('GET_AGREEMENT_TYPES')
export const GET_AGREEMENT_PROPERTIES = createFetchActions('GET_AGREEMENT_PROPERTIES')
export const GET_ASSET_TYPES = createFetchActions('GET_ASSET_TYPES')
export const GET_ASSETS = createFetchActions('GET_ASSETS')
export const GET_CLIENT_TYPES = createFetchActions('GET_CLIENT_TYPES')
export const GET_CLIENT_RISK_TYPES = createFetchActions('GET_CLIENT_RISK_TYPES')
export const GET_COLLATERAL_TYPES = createFetchActions('GET_COLLATERAL_TYPES')
export const GET_CONTRACT_TYPES = createFetchActions('GET_CONTRACT_TYPES')
export const GET_LIMIT_TYPES = createFetchActions('GET_LIMIT_TYPES')
export const GET_LIMIT_CATEGORIES = createFetchActions('GET_LIMIT_CATEGORIES')
export const GET_RELATIONSHIP_TYPES = createFetchActions('GET_RELATIONSHIP_TYPES')
export const GET_SUB_ACCOUNT_TYPES = createFetchActions('GET_SUB_ACCOUNT_TYPES')

export const getMyClients = () => {
	return function (dispatch) {
		dispatch({type: GET_MY_CLIENTS.triggered})
		getConfigClient()
			.query({
				query: getMyClientsQuery,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_MY_CLIENTS.succeeded,
					response,
				})
			})
	}
}

export const getAllClientsList = () => (dispatch: any) => {
	return dispatch(get(GET_ALL_CLIENTS, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/clients/`))
}

export const getSelectedClient = (clientId: string) => (dispatch: any) => {
	return dispatch(
		get(GET_SELECTED_CLIENT, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/clients/${clientId}`)
	)
}

export const searchLegalEntity = (searchText: string) => (dispatch: any) => {
	if (searchText === '') return null
	return dispatch(get(SEARCH_LEGAL_ENTITY, `${getConfigBaseUrl()}/v2/config/legal-entity/search?name=${searchText}`))
}

export const searchLegalEntityDetail = (id: string) => (dispatch: any) => {
	return dispatch(get(SEARCH_LEGAL_ENTITY_DETAIL, `${getConfigBaseUrl()}/v2/config/legal-entity/details/${id}`))
}

export const setSelectedLegalSearch = details => {
	return {type: SET_SELECTED_LEGAL_SEARCH, payload: details}
}

export const clearSelectedClient = () => {
	return {type: CLEAR_SELECTED_CLIENT}
}

export const getDeposTypes = () => (dispatch: any) => {
	return dispatch(get(GET_DEPOS_TYPES, `${getConfigBaseUrl()}/v2/config/depos-types`))
}

export const getAgreementTypes = () => (dispatch: any) => {
	return dispatch(get(GET_AGREEMENT_TYPES, `${getConfigBaseUrl()}/v2/config/agreement-types`))
}

export const getContractTypes = () => (dispatch: any) => {
	return dispatch(get(GET_CONTRACT_TYPES, `${getConfigBaseUrl()}/v2/config/client-contract-type`))
}

export const getSubAccountTypes = () => (dispatch: any) => {
	return dispatch(
		get(GET_SUB_ACCOUNT_TYPES, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/sub-account-types`)
	)
}

export const getAgreementProperties = (agreementType: string) => (dispatch: any) => {
	return dispatch(
		get(GET_AGREEMENT_PROPERTIES, `${getConfigBaseUrl()}/v2/config/${agreementType}/agreement-properties`)
	)
}

export const getAssets = () => (dispatch: any) => {
	return dispatch(get(GET_ASSETS, `${getMDSBaseUrl()}/v1/assets`))
}

export const getAssetTypes = () => (dispatch: any) => {
	return dispatch(get(GET_ASSET_TYPES, `${getConfigBaseUrl()}/v2/config/asset-types`))
}

export const getClientRiskTypes = () => (dispatch: any) => {
	return dispatch(get(GET_CLIENT_RISK_TYPES, `${getConfigBaseUrl()}/v2/config/client-risk-types`))
}

export const getClientTypes = () => (dispatch: any) => {
	return dispatch(get(GET_CLIENT_TYPES, `${getConfigBaseUrl()}/v2/config/client-types`))
}

export const getCollateralTypes = () => (dispatch: any) => {
	return dispatch(get(GET_COLLATERAL_TYPES, `${getConfigBaseUrl()}/v2/config/collateral-types`))
}

export const getLimitTypes = () => (dispatch: any) => {
	return dispatch(get(GET_LIMIT_TYPES, `${getConfigBaseUrl()}/v2/config/limits-types`))
}

export const getLimitCategories = () => (dispatch: any) => {
	return dispatch(get(GET_LIMIT_CATEGORIES, `${getConfigBaseUrl()}/v2/config/limits-categories`))
}

export const getRelationshipTypes = () => (dispatch: any) => {
	return dispatch(
		get(GET_RELATIONSHIP_TYPES, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/relationship-types`)
	)
}

export const saveMyClients = (data: any) => (dispatch: any) => {
	return dispatch(post(SAVE_MY_CLIENTS, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/clients`, data))
}

export const editMyClients = (data: any) => (dispatch: any) => {
	return dispatch(put(EDIT_MY_CLIENTS, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/clients`, data))
}
