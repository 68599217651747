import jwt_decode from 'jwt-decode'
import {setAuthExpiryTimeFn} from '../app/appHelper'
import {isAuthenticated, setAuthToken} from '../services/authService'
import {getStringFromStorage} from '../services/storageService'
import {amplifyCurrentSession, amplifyCurrentUser} from './amplify'

let clearTimer
export const setAuthExpiryTime = (token: string, history: any, callBack = (resolved: boolean, token: string) => {}) => {
	const authToken = token || getStringFromStorage('authTokenKey')
	if (authToken) {
		const expiryTime = jwt_decode(authToken).exp
		const currentTime = new Date().getTime() / 1000
		const time = expiryTime > currentTime ? expiryTime - currentTime : 0
		if (clearTimer || !isAuthenticated()) {
			clearTimeout(clearTimer)
		}
		clearTimer = setTimeout(async () => {
			const cognitoUser = await amplifyCurrentUser()
			const currentSession: any = await amplifyCurrentSession()
			console.log(
				`${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}:${new Date().getMilliseconds()}`,
				'expiry time : ',
				expiryTime,
				'current time : ',
				currentTime,
				'difference time : ',
				time
			)
			try {
				cognitoUser.refreshSession(currentSession.refreshToken, (err, session: any) => {
					if ((err && err.__type === 'NotAuthorizedException') || err || !session) {
						// TODO : Need to enhance below logic, Retry logic
						cognitoUser.refreshSession(currentSession.refreshToken, (err, session: any) => {
							if ((err && err.__type === 'NotAuthorizedException') || err || !session) {
								callBack(false, '')
							} else {
								setAuthToken(session.idToken.jwtToken)
								setAuthExpiryTimeFn(history, session.idToken.jwtToken)
								callBack(true, session.idToken.jwtToken)
							}
						})
					} else {
						setAuthToken(session.idToken.jwtToken)
						setAuthExpiryTimeFn(history, session.idToken.jwtToken)
						callBack(true, session.idToken.jwtToken)
					}
				})
			} catch (e) {
				callBack(false, '')
			}
		}, time * 1000 - 60000)
	}
}

// const callRefreshTokenAPI = (cognitoUser, currentSession, history, callBack) => {
// 	cognitoUser.refreshSession(currentSession.refreshToken, (err, session: any) => {
// 		if ((err && err.__type === 'NotAuthorizedException') || err || !session) {
// 			// callBack(false, '')
// 			callRefreshTokenAPI(cognitoUser, currentSession, history, callBack)
// 		} else {
// 			setAuthToken(session.idToken.jwtToken)
// 			setAuthExpiryTimeFn(history, session.idToken.jwtToken)
// 			callBack(true, session.idToken.jwtToken)
// 		}
// 	})
// }
