import memoizeOne from 'memoize-one'
import Lodash, {isEmpty} from 'lodash'
import {ArrayUtil} from 'helper-util'

import {MyClient} from './myClientsModel'
import {Modules} from '../userGroups/userGroupModel'
import {getCounterpartyId} from '../../../services/authService'
import {globalColumnDefinition} from '../../../common/AgGrid/AgGridColumn'

export const getAscendingOrder = (data, field) => {
	if (data.length) {
		data.sort(function (a, b) {
			return parseFloat(a[field]) - parseFloat(b[field])
		})
		return data
	} else {
		return data
	}
}

export const modifyPayload = (clientData: MyClient, modules: Modules[], isLeDeposAllowed) => {
	const myClientsPayload = Lodash.cloneDeep(clientData)
	isEmpty(myClientsPayload.clientId) && delete myClientsPayload.clientId
	myClientsPayload.tenantId = getCounterpartyId()
	myClientsPayload.isActive = true
	myClientsPayload.clientRanking = Number(myClientsPayload.clientRanking)
	if (isLeDeposAllowed) {
		let eligibleLeList = []
		if (
			!ArrayUtil.isEmpty(clientData.agreementTypes) &&
			clientData.agreementTypes.length === 1 &&
			clientData.agreementTypes.includes('INDEMNIFY')
		) {
			eligibleLeList = (clientData.legalEntities || []).map(le => {
				return {
					legalEntityId: le.id,
					externalProviderRefId: le.externalProviderRefId,
					depos: [],
					clientRiskType: 'MARGIN_BASE',
				}
			})
		} else {
			eligibleLeList = !ArrayUtil.isEmpty(myClientsPayload.leDepos) ? Lodash.cloneDeep(myClientsPayload.leDepos) : []
		}
		myClientsPayload.legalEntities = eligibleLeList || []
		myClientsPayload.clientRiskType = 'MARGIN_BASE'
		myClientsPayload.depos = []
		delete myClientsPayload.leDepos
		delete myClientsPayload.clientRiskTypeDesc
	} else {
		myClientsPayload.depos = !ArrayUtil.isEmpty(myClientsPayload.depos) ? myClientsPayload.depos : []
		myClientsPayload.leDepos = []
	}

	myClientsPayload.properties = !ArrayUtil.isEmpty(myClientsPayload.properties) ? myClientsPayload.properties : []
	myClientsPayload.legalEntityExternalId =
		myClientsPayload.legalEntityExternalId && myClientsPayload.legalEntityExternalId.trim()

	myClientsPayload.limits = !ArrayUtil.isEmpty(myClientsPayload.limits)
		? myClientsPayload.limits.map(lmt => {
				return {
					...lmt,
					assetType: lmt.assetType.code,
				}
		  })
		: []

	myClientsPayload.durationLimits = !ArrayUtil.isEmpty(myClientsPayload.durationLimits)
		? myClientsPayload.durationLimits.map(durLmt => {
				return {
					...durLmt,
					assetType: durLmt.assetType.code,
				}
		  })
		: []

	myClientsPayload.moduleIds = (modules || [])
		.filter(mod => ((myClientsPayload.moduleIds as any) || []).includes(mod.moduleId))
		.map(mod => mod['defaultId'])

	return myClientsPayload
}

export const defaultSorted = [
	{
		id: 'clientRanking',
		desc: false,
	},
]

export const getColumns = memoizeOne((isWritePermission, isLeDeposAllowed, setFormData) => {
	return [
		Object.assign({}, globalColumnDefinition.fullName, {
			onCellClicked: params => {
				isWritePermission && setFormData(params.data)
			},
			cellClass: [isWritePermission ? 'cursor-pointer text-link' : ''],
		}),
		globalColumnDefinition.shortName,
		globalColumnDefinition.clientRanking,
		Object.assign({}, globalColumnDefinition.legalEntityExternalId, {
			headerName: isLeDeposAllowed ? 'Client Legal Entity Id' : 'Legal Entity Id',
		}),
		Object.assign({}, globalColumnDefinition.agreementTypes, {
			cellRenderer: params => (ArrayUtil.isEmpty(params.value) ? '' : params.value.join(', ')),
		}),
		Object.assign({}, globalColumnDefinition.contractTypes, {
			cellRenderer: params => {
				let paramsCamelCase = null
				if (!ArrayUtil.isEmpty(params.value)) {
					paramsCamelCase = params.value.map(val => {
						let firstLetter = val.charAt(0)
						let restLetter = val.slice(1).toLowerCase()
						return firstLetter + restLetter
					})
				}

				return ArrayUtil.isEmpty(params.value) ? '' : paramsCamelCase.join(', ')
			},
		}),
		Object.assign({}, globalColumnDefinition.clientRiskType, {
			field: 'clientRiskTypeDesc',
			cellRenderer: params => (params.value ? params.value.replaceAll(/_/g, ' ') : ''),
		}),
		Object.assign({}, globalColumnDefinition.clientType, {
			field: 'clientTypeDesc',
			cellRenderer: params => (params.value ? params.value.replaceAll(/_/g, ' ') : ''),
		}),
		Object.assign({}, globalColumnDefinition.relationships, {
			enableRowGroup: true,
			cellRenderer: params => (ArrayUtil.isEmpty(params.value) ? '' : params.value.join(', ')),
		}),
		globalColumnDefinition.internalCode1,
		globalColumnDefinition.internalCode2,
		globalColumnDefinition.internalCode3,
		globalColumnDefinition.internalCode4,
		globalColumnDefinition.internalCode5,
		globalColumnDefinition.bbgGlobalIdentifier,
	]
}, isEmpty)

export const defaultMyClients = (): MyClient => {
	return {
		agreementTypes: [],
		clientId: '',
		clientRiskType: '',
		clientRiskTypeDesc: '',
		clientType: '',
		clientRanking: null,
		country: '',
		depos: null,
		leDepos: null,
		durationLimits: null,
		entityType: '',
		entitySubType: '',
		externalProviderRefId: null,
		fullName: '',
		internalCreditRating: '',
		isActive: false,
		legalEntityExternalId: '',
		legalEntities: [],
		limits: null,
		relationships: [],
		moduleIds: [],
		orgId: 0,
		properties: null,
		shortName: '',
		tenantId: '',
		subAccountIds: [],
		contractTypes: [],
		bbgGlobalIdentifier: '',
		internalCode1: '',
		internalCode2: '',
		internalCode3: '',
		internalCode4: '',
		internalCode5: '',
	}
}

