import {
	UPLOAD_PB_PORTFOLIO_DOCS,
	GET_PB_PORTFOLIO_DOCS_INCLUDED,
	GET_PB_PORTFOLIO_DOCS_ALL,
	SAVE_PB_INCLUDED_DOCS,
	CLEAR_PB_PORTFOLIO_MANAGER_STATE,
	GET_PB_PORTFOLIO_UPDATE_DATETIME,
	SET_PB_PROCESSED_PORTFOLIO_OBJECT,
	RESET_PB_PROCESSED_PORTFOLIO_OBJECT,
	SET_PB_PORTFOLIO_DOCUMENT_STATUS,
	UPLOAD_PB_RESTRICTED_PORTFOLIO,
	GET_PB_RESTRICTED_PORTFOLIO,
	PF_PB_UPLOAD_ONGOING,
} from './pbPortfolioManagerActions'

export interface PbPortfolioManager {
	apiIncludedDocs: DocItem[]
	includedDocs: DocItem[]
	allDocs: DocItem[]
	includedIds: string[]
	portfolioUpdatedDateTime: any[]
	loading: boolean
	processedPortfolioObject: any
	currentDoc: {id: string; status: string}
	restrictedStockDetails: any
	pfUploadOngoing: boolean
}

const initialState: PbPortfolioManager = {
	apiIncludedDocs: [],
	includedDocs: [],
	allDocs: [],
	includedIds: [],
	portfolioUpdatedDateTime: [],
	loading: false,
	processedPortfolioObject: {},
	currentDoc: null,
	restrictedStockDetails: {},
	pfUploadOngoing: false,
}

export interface DocItem {
	id: string
	tenantId: string
	fileName: string
	errorCount: number
	status: 'SUCCESS' | 'FAILURE' | string
	createdAt: string[]
}

export function pbPortfolioManagerReducer(state = initialState, action: any) {
	let includedIds = []
	switch (action.type) {
		case GET_PB_PORTFOLIO_DOCS_INCLUDED.triggered:
			return {...state, loading: true}
		case GET_PB_PORTFOLIO_DOCS_INCLUDED.succeeded:
			includedIds = (action.response.data || []).map(item => String(item.id))
			const data = action.response.data
			return {
				...state,
				loading: false,
				apiIncludedDocs: data || [],
				includedDocs: data || [],
				includedIds,
			}
		case GET_PB_PORTFOLIO_DOCS_INCLUDED.failed:
			return {...state, loading: false}

		case GET_PB_PORTFOLIO_DOCS_ALL.triggered:
			return {...state, loading: true}
		case GET_PB_PORTFOLIO_DOCS_ALL.succeeded:
			return {...state, allDocs: action.response.data || [], loading: false}
		case GET_PB_PORTFOLIO_DOCS_ALL.failed:
			return {...state, loading: false}

		case GET_PB_PORTFOLIO_UPDATE_DATETIME.triggered:
			return {...state, loading: true}
		case GET_PB_PORTFOLIO_UPDATE_DATETIME.succeeded:
			return {
				...state,
				portfolioUpdatedDateTime: action.response.data || [],
				loading: false,
			}
		case GET_PB_PORTFOLIO_UPDATE_DATETIME.failed:
			return {...state, loading: false}

		case UPLOAD_PB_PORTFOLIO_DOCS.triggered:
			return {...state, loading: true}
		case UPLOAD_PB_PORTFOLIO_DOCS.succeeded:
		case UPLOAD_PB_PORTFOLIO_DOCS.failed:
			return {...state, loading: false}
		case UPLOAD_PB_RESTRICTED_PORTFOLIO.triggered:
			return {...state, loading: true}
		case UPLOAD_PB_RESTRICTED_PORTFOLIO.succeeded:
			return {...state, loading: false}
		case UPLOAD_PB_RESTRICTED_PORTFOLIO.failed:
			return {...state, loading: false}
		case GET_PB_RESTRICTED_PORTFOLIO.triggered:
			return {...state, loading: true}
		case GET_PB_RESTRICTED_PORTFOLIO.succeeded:
		case GET_PB_RESTRICTED_PORTFOLIO.failed:
			return {...state, loading: false, restrictedStockDetails: action.response.data || {}}
		case SAVE_PB_INCLUDED_DOCS.triggered:
			return {...state, loading: true}
		case SAVE_PB_INCLUDED_DOCS.succeeded:
		case SAVE_PB_INCLUDED_DOCS.failed:
			return {...state, loading: false}

		case CLEAR_PB_PORTFOLIO_MANAGER_STATE:
			return {...initialState}

		case SET_PB_PROCESSED_PORTFOLIO_OBJECT:
			return {...state, processedPortfolioObject: action.payload.data}

		case RESET_PB_PROCESSED_PORTFOLIO_OBJECT:
			return {
				...state,
				processedPortfolioObject: initialState.processedPortfolioObject,
			}

		case SET_PB_PORTFOLIO_DOCUMENT_STATUS:
			const docId = action.payload.id
			const docStatus = action.payload.status
			return {
				...state,
				loading: false,
				currentDoc: {
					id: docId,
					status: docStatus,
				},
			}

		case PF_PB_UPLOAD_ONGOING:
			return {
				...state,
				pfUploadOngoing: action.payload.uploading,
			}
	}
	return state
}
