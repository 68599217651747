import {ArrayUtil} from 'helper-util'
import {getPBMSClient} from '../../..'
import {createFetchActions} from '../../../services/createActions'
import {getPbAbcPortfolioQuery, getPbAbcFiltersQuery, buildPbAbcPortfolioQuery} from './pbAbcPortfolioQueries'

export const GET_PB_ABC_PORTFOLIO = createFetchActions('GET_PB_ABC_PORTFOLIO')
export const GET_PB_ABC_PORTFOLIO_FILTER = createFetchActions('GET_PB_ABC_PORTFOLIO_FILTER')

export const SET_PB_ABC_PORTFOLIO_GROUP_FILTERS = 'SET_PB_ABC_PORTFOLIO_GROUP_FILTERS'
export const SET_PB_ABC_PORTFOLIO_SELECTED_VIEW = 'SET_PB_ABC_PORTFOLIO_SELECTED_VIEW'
export const RESET_PB_ABC_PORTFOLIO_DATA = 'RESET_PB_ABC_PORTFOLIO_DATA'
export const RESET_PB_ABC_PORTFOLIO_FILTERS = 'RESET_PB_ABC_PORTFOLIO_FILTERS'
export const RESET_PB_ABC_PORTFOLIO_LOADER = 'RESET_PB_ABC_PORTFOLIO_LOADER'
export const STOP_PB_ABC_PORTFOLIO_LOADER = 'STOP_PB_ABC_PORTFOLIO_LOADER'

export const setPbAbcPortfolioGroupFilters = data => {
	return {
		type: SET_PB_ABC_PORTFOLIO_GROUP_FILTERS,
		response: {
			data,
		},
	}
}

export const setPbAbcPortfolioSelectedView = data => {
	return {
		type: SET_PB_ABC_PORTFOLIO_SELECTED_VIEW,
		response: {
			data,
		},
	}
}

export const getPbAbcPortfolioData = (page: any, size: any, formattedFilters: any) => {
	return function (dispatch) {
		dispatch({
			type: GET_PB_ABC_PORTFOLIO.triggered,
		})
		getPBMSClient()
			.query({
				query: getPbAbcPortfolioQuery(),
				fetchPolicy: 'network-only',
				variables: {
					page,
					size,
					filters: formattedFilters,
				},
			})
			.then(response => {
				dispatch({
					type: GET_PB_ABC_PORTFOLIO.succeeded,
					response,
				})
			})
	}
}

export const getPbAbcPortfolioFilterValues = (field: any, filter: any, groupKey: any) => {
	let variables = !ArrayUtil.isEmpty(filter)
		? {
				field,
				filter,
		  }
		: {
				field,
		  }
	return function (dispatch) {
		dispatch({
			type: GET_PB_ABC_PORTFOLIO_FILTER.triggered,
		})
		getPBMSClient()
			.query({
				query: getPbAbcFiltersQuery(groupKey),
				fetchPolicy: 'network-only',
				variables,
			})
			.then(response => {
				dispatch({
					type: GET_PB_ABC_PORTFOLIO_FILTER.succeeded,
					response,
					groupKey,
				})
			})
	}
}

export const resetPbAbcPortfolioData = () => {
	return {
		type: RESET_PB_ABC_PORTFOLIO_DATA,
	}
}

export const resetPbAbcPortfolioFilters = () => {
	return {
		type: RESET_PB_ABC_PORTFOLIO_FILTERS,
	}
}

export const resetPbAbcPortfolioLoader = () => {
	return {
		type: RESET_PB_ABC_PORTFOLIO_LOADER,
	}
}

export const getPbAbcPortfolioDataBasedOnSelectedColumns = (
	page: any,
	size: any,
	formattedFilters: any,
	selectedColumndefs: any
) => {
	return function (dispatch) {
		dispatch({
			type: GET_PB_ABC_PORTFOLIO.triggered,
		})
		getPBMSClient()
			.query({
				query: buildPbAbcPortfolioQuery(selectedColumndefs),
				fetchPolicy: 'network-only',
				variables: {
					page,
					size,
					filters: formattedFilters,
				},
			})
			.then(response => {
				dispatch({
					type: GET_PB_ABC_PORTFOLIO.succeeded,
					response,
				})
			})
	}
}

export const stopPbTableLoader = () => {
	return {
		type: STOP_PB_ABC_PORTFOLIO_LOADER,
	}
}
