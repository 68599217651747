// import React from 'react'
import _ from 'lodash'
import memoizeOne from 'memoize-one'
import isDeepEqual from 'lodash.isequal'
import {ArrayUtil, FormatUtil} from 'helper-util'
import {
	// tableHeaderWithTitle, tableHeaderClassName,
	getHeaderValue,
} from '../../../../utils/reactTableUtil'
import {formatWithSeparator} from '../../../../utils/formatUtils'
import {p2pActions} from './recallReturnRequestConstant'
// import {InlineEditIcon} from '../../../../styles/svg/svgIcons'
import {globalColumnDefinition} from '../../../../common/AgGrid/AgGridColumn'

export const getColumns = (
	columnDef,
	items,
	handleAcceptRejectAction,
	handleAcknowledgeAction,
	handleQuantityClick,
	handleAllInRateChange
) => {
	const columnDefinition = _.cloneDeep(columnDef)
	const actionsIndex = columnDefinition.findIndex(e => e.field === 'actions')
	const allInRateIndex = columnDefinition.findIndex(e => e.field === 'allInRate')
	const quantityIndex = columnDefinition.findIndex(e => e.field === 'recallReturnQuantity')

	if (actionsIndex >= 0) {
		columnDefinition[actionsIndex] = Object.assign({}, globalColumnDefinition.actions, {
			cellRendererParams: {items, handleAcceptRejectAction, handleAcknowledgeAction},
			cellRenderer: 'Actions',
			minWidth: 250,
		})
	}

	if (allInRateIndex >= 0) {
		columnDefinition[allInRateIndex] = Object.assign({}, globalColumnDefinition.allInRate, {
			cellRendererParams: {items, handleEditableCell: handleAllInRateChange},
			cellRenderer: 'AllInRate',
		})
	}

	if (quantityIndex >= 0) {
		columnDefinition[quantityIndex] = Object.assign({}, globalColumnDefinition.recallReturnQuantity, {
			cellRendererParams: {items, handleQuantityClick},
			cellRenderer: 'RecallReturnQuantity',
		})
	}

	return columnDefinition
}

export const getRowData = memoizeOne(entries => {
	const rowData = []
	!ArrayUtil.isEmpty(entries) &&
		entries.forEach(entry => {
			const rowObj = JSON.parse(JSON.stringify(entry))

			rowObj.quantity = entry.originalQuantity
			rowObj.inefficienciesArray = ArrayUtil.isEmpty(entry.inefficiencies) ? [] : entry.inefficiencies.map(e => e.abbr)
			rowObj.priceAmount = entry.price ? entry.price.amount : 0
			rowObj.basePriceAmount = entry.basePrice ? entry.basePrice.amount : 0
			rowObj.notionalAmount = entry.notional ? entry.notional.amount : 0
			rowObj.collateralAmount = entry.collateral ? entry.collateral.amount : 0
			rowObj.notionalCurrency = entry.notional ? entry.notional.currency : ''
			rowObj.recallInefficiencyContributionAmount = entry.recallInefficiencyContribution
				? entry.recallInefficiencyContribution.amount
				: 0
			rowObj.returnInefficiencyContributionAmount = entry.returnInefficiencyContribution
				? entry.returnInefficiencyContribution.amount
				: 0
			rowObj.collateralAmount = entry.originalCollateralValue ? entry.originalCollateralValue.amount : 0
			rowObj.priceCurrency = entry.price ? entry.price.currency : ''
			rowObj.baseNotional = entry.baseNotional ? entry.baseNotional.amount : 0
			rowObj.recallReturnQuantity = entry.quantity

			rowData.push(rowObj)
		})
	return rowData
}, isDeepEqual)

export const getAcceptedEntriesNotional = (acceptedEntries, entries) => {
	let acceptedEntriesNotional = 0
	entries.forEach(entry => {
		if (acceptedEntries.includes(entry.orderId)) {
			acceptedEntriesNotional += entry['baseNotionalAmount']
		}
	})
	return formatWithSeparator(acceptedEntriesNotional)
}

export const getCsvData = (tradeTableData: any[]) => {
	const csvData = []
	Array.isArray(tradeTableData) &&
		tradeTableData.forEach(tableItem => {
			csvData.push(tableItem)
		})
	return csvData
}

export const getCsvHeader = (columnDef: any[]) => {
	const csvHeader = []
	Array.isArray(columnDef) &&
		columnDef.forEach(column => {
			if (column) {
				const header = {
					label: getHeaderValue(column),
					key: column.accessor,
				}
				csvHeader.push(header)
			}
		})
	return csvHeader
}

export const getEntriesOnFilter = (entries: any[], id: string) => {
	return id === 'all'
		? entries
		: entries.filter(item => FormatUtil.text.toLowerCase(item.status) === FormatUtil.text.toLowerCase(id))
}

export const getP2pActionLabel = (actions: any[]) => {
	if (ArrayUtil.getLength(actions) === 1) {
		return p2pActions[actions[0]]
	} else {
		return actions.map(item => p2pActions[item]).join(', ')
	}
}

export const isAcceptAllCheck = (entries: any[]) => {
	const isAcceptAllEntries = entries.filter(item => item.actions.includes('ACCEPT'))
	return Boolean(ArrayUtil.getLength(isAcceptAllEntries))
}

export const isAcknowledgeAllCheck = (entries: any[]) => {
	const isAcknowledgeAllEntries = entries.filter(item => item.actions.includes('ACKNOWLEDGE'))
	return Boolean(ArrayUtil.getLength(isAcknowledgeAllEntries))
}

// export const getActionLabel = (filteredEntries: any[]) => {
//   return !ArrayUtil.isEmpty(filteredEntries) ? filteredEntries[0].actions[0] : ''
// }
