import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {MainState} from '../../store/mainReducer'
import ForgotPassword from './forgotPasswordComponent'
import {validateResetToken} from './forgotPasswordAction'
import {authLogout} from '../../app/appActions'

const mapStateToProps = (state: MainState) => {
	return {}
}

const mapDispatchToProps = (dispatch: any) => {
	return bindActionCreators(
		{
			authLogout,
			validateResetToken,
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
