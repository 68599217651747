import gql from 'graphql-tag'

const portfolio_entries_fragment = gql`
	fragment DasboardPortfolioEntries on PortfolioEntry {
		actions
		abcmCategoryName
		activityType
		allInRate
		assetClassification
		assetMaturityDate
		assetSubType
		assetType
		availableQuantity
		balanceSheetContribution {
			amount
			currency
		}
		baseNotional {
			amount
			currency
		}
		basePrice {
			amount
			currency
		}
		bbId
		book
		borrowFee
		loanFee
		buySellInd
		collateralType
		collateral {
			amount
			currency
		}
		counterParty
		createdBy
		createdDate
		cusip
		effectiveBaseNotional {
			amount
			currency
		}
		endDate
		exchangeRate
		fitchRating
		fund
		haircut
		hqla
		id
		inefficiencies {
			inefficiency
			abbr
			displayName
		}
		index
		isin
		legalEntity
		maturityDate
		maturityType
		moodyRating
		needQuantity
		notional {
			amount
			currency
		}
		originalTradeType
		poolFactor
		portfolioEntryType
		partial
		price {
			amount
			currency
		}
		priceMultiplier
		quantity
		recallInefficiencyContribution {
			amount
			currency
		}
		recallQuantity
		referenceRate
		returnInefficiencyContribution {
			amount
			currency
		}
		returnQuantity
		availabilitiesContribution {
			amount
			currency
		}
		needsContribution {
			amount
			currency
		}
		platformTradeId
		rebate
		ric
		sector
		securityId
		securityIdType
		settlementDate
		snpRating
		sourceSystem
		sourceSystemTradeId
		spread
		startDate
		tenor
		termDate
		tradeDate
		ticker
		tradeType
	}
`

const portfolio_entries_re_rate_fragment = gql`
	fragment DasboardPortfolioEntries on ReRateEntry {
		actions
		abcmCategoryName
		activityType
		allInRate
		assetClassification
		assetMaturityDate
		assetSubType
		assetType
		availableQuantity
		balanceSheetContribution {
			amount
			currency
		}
		baseNotional {
			amount
			currency
		}
		basePrice {
			amount
			currency
		}
		bbId
		book
		borrowFee
		loanFee
		buySellInd
		collateralType
		collateral {
			amount
			currency
		}
		counterParty
		createdBy
		createdDate
		cusip
		effectiveBaseNotional {
			amount
			currency
		}
		endDate
		exchangeRate
		fitchRating
		fund
		haircut
		hqla
		id
		inefficiencies {
			inefficiency
			abbr
			displayName
		}
		index
		isin
		maturityDate
		maturityType
		moodyRating
		needQuantity
		notional {
			amount
			currency
		}
		poolFactor
		portfolioEntryType
		partial
		price {
			amount
			currency
		}
		priceMultiplier
		quantity
		recallInefficiencyContribution {
			amount
			currency
		}
		recallQuantity
		referenceRate
		returnInefficiencyContribution {
			amount
			currency
		}
		returnQuantity
		availabilitiesContribution {
			amount
			currency
		}
		needsContribution {
			amount
			currency
		}
		platformTradeId
		rebate
		ric
		sector
		securityId
		securityIdType
		settlementDate
		snpRating
		sourceSystem
		sourceSystemTradeId
		spread
		startDate
		tenor
		termDate
		tradeDate
		ticker
		tradeType
	}
`

const dashboardEntityAmountQuery = gql`
	query {
		sblDashboard {
			overview {
				loans {
					amount
					currency
				}
				borrows {
					amount
					currency
				}
				matureTrades {
					amount
					currency
				}
				openTrades {
					amount
					currency
				}
				recallInefficiencies {
					amount
					currency
				}
				returnInefficiencies {
					amount
					currency
				}
				nonCashLoan {
					amount
					currency
				}
				nonCashBorrow {
					amount
					currency
				}
				reRate {
					amount
					currency
				}
				needs {
					amount
					currency
				}
				availabilities {
					amount
					currency
				}
			}
		}
	}
`

const recallInefficienciesDataQuery = gql`
	query {
		sblDashboard {
			recallInefficiencies {
				entries {
					...DasboardPortfolioEntries
				}
			}
		}
	}
	${portfolio_entries_fragment}
`

const returnInefficienciesDataQuery = gql`
	query {
		sblDashboard {
			returnInefficiencies {
				entries {
					...DasboardPortfolioEntries
				}
			}
		}
	}
	${portfolio_entries_fragment}
`

const nonCashBorrowDataQuery = gql`
	query {
		sblDashboard {
			nonCashTrades {
				counterParty
				borrow {
					amount
					currency
				}
				loan {
					amount
					currency
				}
				entries {
					...DasboardPortfolioEntries
				}
			}
		}
	}
	${portfolio_entries_fragment}
`
const nonCashLoanDataQuery = nonCashBorrowDataQuery

const sblTradesSblDataQuery = gql`
	query {
		sblDashboard {
			sblTrades {
				cashDetails {
					grossExposure {
						amount
						currency
					}
					netExposure {
						amount
						currency
					}
					allBorrows {
						amount
						currency
					}
					allLoans {
						amount
						currency
					}
				}
				nonCashDetails {
					grossExposure {
						amount
						currency
					}
					netExposure {
						amount
						currency
					}
					allBorrows {
						amount
						currency
					}
					allLoans {
						amount
						currency
					}
				}
				grossDetails {
					grossExposure {
						amount
						currency
					}
					netExposure {
						amount
						currency
					}
					allBorrows {
						amount
						currency
					}
					allLoans {
						amount
						currency
					}
				}
				cash {
					counterparty
					loan {
						amount
						currency
					}
					borrow {
						amount
						currency
					}
					trades {
						...DasboardPortfolioEntries
					}
				}
				nonCash {
					counterparty
					loan {
						amount
						currency
					}
					borrow {
						amount
						currency
					}
					trades {
						...DasboardPortfolioEntries
					}
				}
				gross {
					counterparty
					loan {
						amount
						currency
					}
					borrow {
						amount
						currency
					}
					trades {
						...DasboardPortfolioEntries
					}
				}
			}
		}
	}
	${portfolio_entries_fragment}
`
const sblTradesBorrowsDataQuery = sblTradesSblDataQuery

const matureTradesDataQuery = gql`
	query {
		sblDashboard {
			matureTrades {
				entries {
					...DasboardPortfolioEntries
				}
			}
		}
	}
	${portfolio_entries_fragment}
`

const openTradesDataQuery = gql`
	query {
		sblDashboard {
			openTrades {
				entries {
					...DasboardPortfolioEntries
				}
			}
		}
	}
	${portfolio_entries_fragment}
`

const reRateDataQuery = gql`
	query {
		sblDashboard {
			reRate {
				entries {
					...DasboardPortfolioEntries
				}
			}
		}
	}
	${portfolio_entries_re_rate_fragment}
`

const longTradesDataQuery = gql`
	query {
		sblDashboard {
			longTrades {
				entries {
					...DasboardPortfolioEntries
				}
			}
		}
	}
	${portfolio_entries_fragment}
`

const shortTradesDataQuery = gql`
	query {
		sblDashboard {
			shortTrades {
				entries {
					...DasboardPortfolioEntries
				}
			}
		}
	}
	${portfolio_entries_fragment}
`

const availabilitiesDataQuery = gql`
	query {
		sblDashboard {
			availabilities {
				entries {
					...DasboardPortfolioEntries
				}
			}
		}
	}
	${portfolio_entries_fragment}
`

const externalAvailabilityQuery = gql`
	query {
		externalAvailabilityNeeds(type: AVAILABILITY) {
			security {
				securityId
				price
				currency
				assetType
			}
			quantity
			counterPartyAvailabilityNeeds {
				clientId
				shortName
				quantity
				rebateRate
			}
		}
	}
`

const needsDataQuery = gql`
	query {
		sblDashboard {
			needs {
				entries {
					...DasboardPortfolioEntries
				}
			}
		}
	}
	${portfolio_entries_fragment}
`

const dashboardPortfolioAnalyticsQuery = {
	recallInefficienciesDataQuery,
	returnInefficienciesDataQuery,
	nonCashBorrowDataQuery,
	nonCashLoanDataQuery,
	sblTradesSblDataQuery,
	sblTradesBorrowsDataQuery,
	matureTradesDataQuery,
	openTradesDataQuery,
	reRateDataQuery,
	longTradesDataQuery,
	shortTradesDataQuery,
	availabilitiesDataQuery,
	needsDataQuery,
	externalAvailabilityQuery,
}

export {dashboardEntityAmountQuery, dashboardPortfolioAnalyticsQuery}
