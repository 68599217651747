import {getALPBaseUrl, getConfigBaseUrl} from '../../../../services/apiService'
import {getCounterpartyId} from '../../../../services/authService'
import {createFetchActions} from '../../../../services/createActions'
import {get, post} from '../../../../services/createAPICall'
export const GET_BID_DETAILS = createFetchActions('GET_BID_DETAILS')
export const GET_LIVE_AUCTIONS = createFetchActions('GET_LIVE_AUCTIONS')
export const GET_ALL_MY_BIDS = createFetchActions('GET_ALL_MY_BIDS')
export const SET_AUCTION_DETAILS_AND_MODE = createFetchActions('SET_AUCTION_DETAILS_AND_MODE')
export const PLACE_BID = createFetchActions('PLACE_BID')
export const CLEAR_BID_DETAILS = createFetchActions('CLEAR_BID_DETAILS')
export const FETCH_AUCTION_BORROWERS_SUB_ACCOUNT = createFetchActions('FETCH_AUCTION_BORROWERS_SUB_ACCOUNT')
export const SET_BORROWER_AUCTION_STATUS_ON_TIMER_ENDS = createFetchActions('SET_BORROWER_AUCTION_STATUS_ON_TIMER_ENDS')

export const getMyBidsById = (bidId: string) => (dispatch: any) => {
	return dispatch(get(GET_BID_DETAILS, `${getALPBaseUrl()}/v1/auction/bid/${bidId}`))
}

export const getLiveAuctions = () => (dispatch: any) => {
	return dispatch(get(GET_LIVE_AUCTIONS, `${getALPBaseUrl()}/v1/auction/borrower/LiveAuctions`))
}

export const getAllMyBids = () => (dispatch: any) => {
	return dispatch(get(GET_ALL_MY_BIDS, `${getALPBaseUrl()}/v1/auction/myBids`))
}

export const placeBid = payload => (dispatch: any) => {
	return dispatch(post(PLACE_BID, `${getALPBaseUrl()}/v1/auction/bid-request`, payload))
}

export const fetchAuctionBorrowersSubAccount = () => (dispatch: any) => {
	return dispatch(
		get(
			FETCH_AUCTION_BORROWERS_SUB_ACCOUNT,
			`${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/client-group`
		)
	)
}

export const setAuctionDetailsAndMode = (auctionDetails, value) => {
	return {
		type: SET_AUCTION_DETAILS_AND_MODE,
		payload: {auctionDetails, mode: value},
	}
}

export const clearBidDetails = () => {
	return {
		type: CLEAR_BID_DETAILS,
	}
}

export const setBorrowerAuctionStatusOnTimerEnd = auctionReqId => {
	return {
		type: SET_BORROWER_AUCTION_STATUS_ON_TIMER_ENDS,
		payload: auctionReqId,
	}
}
