import gql from 'graphql-tag'

export const activityLogQuery = (counterParty, way) => gql`
  query{
    activityOrderLogs(counterParty:"${counterParty}",way:${way}){
      description
      level
      direction
      createdDate
      action
    }
    }
`
