import {
	GET_REPO_ENTITIES_AMOUNT,
	GET_REPO_PORTFOLIO_ANALYTICS,
	RESET_DASHBOARD_DATA,
	SET_REPO_CLICKED_PORTFOLIO_ENTITY,
	SET_REPO_CLICKED_GROUPBY_ENTITY,
	SET_CLICKED_FIRST_LEVEL_TREEMAP_VALUE,
	SET_CLICKED_SECOND_LEVEL_TREEMAP_VALUE,
	SET_REPO_TOGGLE_INDEX,
	SET_REPO_DRILLDOWNN,
} from './repoActions'

export interface Repo {
	loading: boolean
	clickedRepoGroupByEntity: any
	firstLevelTreemapValue: string
	secondLevelTreemapValue: string
	clickedRepoPortfolioEntity: string
	dashboardEntityAmountDataLoading: boolean
	dashboardEntityAmountData: any
	dashboardPortfolioAnalyticsLoading: boolean
	dashboardPortfolioAnalytics: Object
	clickedRepoToggleIndex: any
	drilldown: any
}

const initialState: Repo = {
	loading: false,
	clickedRepoGroupByEntity: {},
	clickedRepoToggleIndex: {},
	firstLevelTreemapValue: '',
	secondLevelTreemapValue: '',
	clickedRepoPortfolioEntity: '',
	dashboardEntityAmountDataLoading: false,
	dashboardEntityAmountData: {},
	dashboardPortfolioAnalyticsLoading: false,
	dashboardPortfolioAnalytics: [{}, {}],
	drilldown: {},
}

export function repoReducer(state = initialState, action: any) {
	switch (action.type) {
		case SET_REPO_CLICKED_PORTFOLIO_ENTITY:
			const clickedRepoPortfolioEntity = action.response.data
			return {...state, clickedRepoPortfolioEntity: clickedRepoPortfolioEntity}

		case SET_REPO_CLICKED_GROUPBY_ENTITY:
			const {groupEntityname, groupOne, groupTwo} = action.response
			const clickedRepoGroupByEntity = state.clickedRepoGroupByEntity
			clickedRepoGroupByEntity[groupEntityname] = [groupOne, groupTwo]
			return {...state, clickedRepoGroupByEntity}

		case SET_REPO_TOGGLE_INDEX:
			const {toggleName, index} = action.response
			const clickedRepoToggleIndex = state.clickedRepoToggleIndex
			clickedRepoToggleIndex[toggleName] = index
			return {...state, clickedRepoToggleIndex}

		case SET_REPO_DRILLDOWNN:
			const {drilldownName, drilldownData} = action.response
			const drilldown = {}
			if (drilldownName && drilldownData) drilldown[drilldownName] = drilldownData
			return {...state, drilldown}

		case SET_CLICKED_FIRST_LEVEL_TREEMAP_VALUE:
			const firstLevelTreemapValue = action.response.data
			return {...state, firstLevelTreemapValue: firstLevelTreemapValue}

		case SET_CLICKED_SECOND_LEVEL_TREEMAP_VALUE:
			const secondLevelTreemapValue = action.response.data
			return {...state, secondLevelTreemapValue: secondLevelTreemapValue}

		case GET_REPO_ENTITIES_AMOUNT.triggered:
			return {
				...state,
				dashboardEntityAmountData: state.dashboardEntityAmountData,
				dashboardEntityAmountDataLoading: true,
				loading: true,
			}
		case GET_REPO_ENTITIES_AMOUNT.succeeded:
			const dashboardEntityAmountData = action.response.data
			let entityData = dashboardEntityAmountData.repoDashboard.repoOverview
			dashboardEntityAmountData.repoDashboard.repoOverview['repoTradesRepo'] = entityData.repoTrades
			dashboardEntityAmountData.repoDashboard.repoOverview['repoTradesReverse'] = entityData.reverseRepoTrades
			dashboardEntityAmountData.repoDashboard.repoOverview['matureTrades'] = entityData.matureTrades
			dashboardEntityAmountData.repoDashboard.repoOverview['openTrades'] = entityData.openTrades
			return {
				...state,
				dashboardEntityAmountData: dashboardEntityAmountData,
				dashboardEntityAmountDataLoading: false,
				loading: state.dashboardPortfolioAnalyticsLoading,
			}

		case GET_REPO_PORTFOLIO_ANALYTICS.triggered:
			return {
				...state,
				dashboardPortfolioAnalytics: state.dashboardPortfolioAnalytics,
				dashboardPortfolioAnalyticsLoading: true,
				loading: true,
			}
		case GET_REPO_PORTFOLIO_ANALYTICS.succeeded:
			const {response} = action
			const dashboardPortfolioAnalytics = [
				{...state.dashboardPortfolioAnalytics[0], ...response[0]},
				{...state.dashboardPortfolioAnalytics[1], ...response[1]},
			]
			return {
				...state,
				dashboardPortfolioAnalytics,
				dashboardPortfolioAnalyticsLoading: false,
				loading: state.dashboardEntityAmountDataLoading,
			}

		case RESET_DASHBOARD_DATA:
			return {
				...state,
				dashboardEntityAmountData: {},
				dashboardPortfolioAnalytics: [{}, {}],
				loading: false,
			}
	}
	return state
}
