'GET_COLLATERAL_BASKETS'
import {
	GET_TYPEAHEAD_RESULT,
	GET_STRATEGIES,
	CREATE_ORDER,
	GET_COLLATERAL_BASKETS,
	GET_ACTIVE_BENCHMARKS,
} from './orderEntryActions'

export interface OrderEntry {
	orderId: string
	collateralBaskets: any
	typeAheadResult: any[]
	productStrategies: any[]
	activeBenchmarks: any[]
	isLoading: boolean
}

const initialState: OrderEntry = {
	orderId: '',
	collateralBaskets: {},
	typeAheadResult: [],
	productStrategies: [],
	activeBenchmarks: [],
	isLoading: false,
}

export function orderEntryReducer(state = initialState, action: any) {
	switch (action.type) {
		case CREATE_ORDER.triggered:
			return {...state, isLoading: true}
		case CREATE_ORDER.succeeded:
			return {...state, orderId: action.response.data, isLoading: false}
		case CREATE_ORDER.failed:
			return {...state, isLoading: false}

		case GET_COLLATERAL_BASKETS.triggered:
			return {...state, isLoading: true}
		case GET_COLLATERAL_BASKETS.succeeded:
			return {
				...state,
				collateralBaskets: (action.response.data || {}).openCollateralBaskets || [],
				isLoading: false,
			}
		case GET_COLLATERAL_BASKETS.failed:
			return {...state, isLoading: false}

		case GET_TYPEAHEAD_RESULT.triggered:
			return {...state, isLoading: true}
		case GET_TYPEAHEAD_RESULT.succeeded:
			return {
				...state,
				typeAheadResult: (action.response.data || {}).securitiesSuggestions,
				isLoading: false,
			}
		case GET_TYPEAHEAD_RESULT.failed:
			return {...state, isLoading: false}

		case GET_STRATEGIES.triggered:
			return {...state, isLoading: true}
		case GET_STRATEGIES.succeeded:
			return {
				...state,
				productStrategies: action.response.data.strategies,
				isLoading: false,
			}
		case GET_STRATEGIES.failed:
			return {...state, isLoading: false}

		case GET_ACTIVE_BENCHMARKS.triggered:
			return {...state, isLoading: true}
		case GET_ACTIVE_BENCHMARKS.succeeded:
			return {
				...state,
				activeBenchmarks: action.response.data,
				isLoading: false,
			}
		case GET_ACTIVE_BENCHMARKS.failed:
			return {...state, isLoading: false}
	}
	return state
}
