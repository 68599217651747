import {setObjectInStorage, getObjectFromStorage} from './storageService'

export const configKey = 'configKey'
export const permissionKey = 'permissions'

export function setClientConfiguration(config: any = {}) {
	setObjectInStorage(configKey, config)
}

export function getClientConfiguration() {
	const defaultConfig = {baseCurrency: 'USD', timeZone: 'America/New_York'}
	return getObjectFromStorage(configKey) || defaultConfig
}

export function getTimeZone() {
	const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
	return timezone
}

export function getTimeZoneFromToken() {
	return getClientConfiguration() ? getClientConfiguration().timeZone : null
}

export function getBaseCurrency() {
	return getClientConfiguration().baseCurrency
}

export function isTimeZonePresent() {
	return getTimeZone() ? true : false
}

export const setPermissionsInStorage = (permissionList: string[]) => {
	setObjectInStorage(permissionKey, permissionList)
}

export const getPermissionsFromStorage = () => {
	return getObjectFromStorage(permissionKey)
}
