import {createFetchActions} from '../../services/createActions'
import {get, post} from '../../services/createAPICall'
import {getPMSBaseUrl, getMDSBaseUrl} from '../../services/apiService'
import {typeAheadResultQuery, strategiesQuery, getCollateralBasketsQuery} from './orderEntryQueries'
import {getMDSClient, getPMSClient} from '../..'

export {getAssets} from '../accountSettingsRevamp/myClients/myClientsActions'

export {getProducts} from '../collateralBasketList/collateralBasket/collateralBasketActions'

export const CREATE_ORDER = createFetchActions('CREATE_ORDER')
export const GET_COLLATERAL_BASKETS = createFetchActions('GET_COLLATERAL_BASKETS')
export const GET_TYPEAHEAD_RESULT = createFetchActions('GET_TYPEAHEAD_RESULT')
export const GET_STRATEGIES = createFetchActions('GET_STRATEGIES')
export const GET_ACTIVE_BENCHMARKS = createFetchActions('GET_ACTIVE_BENCHMARKS')

export const createOrder = data => (dispatch: any) => {
	return dispatch(post(CREATE_ORDER, `${getPMSBaseUrl()}/v1/orders/`, data))
}

export const getCollateralBaskets = () => {
	return function (dispatch) {
		dispatch({type: GET_COLLATERAL_BASKETS.triggered})
		getPMSClient()
			.query({
				query: getCollateralBasketsQuery(false),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_COLLATERAL_BASKETS.succeeded,
					response,
				})
			})
	}
}

export const getActiveBenchmarks = () => dispatch => {
	return dispatch(get(GET_ACTIVE_BENCHMARKS, `${getMDSBaseUrl()}/v1/benchmarks`))
}

export function getTypeAheadResult(keyword) {
	return function (dispatch) {
		getPMSClient()
			.query({
				query: typeAheadResultQuery(keyword),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_TYPEAHEAD_RESULT.succeeded,
					response,
				})
			})
	}
}

export const getProductStrategies = () => {
	return function (dispatch) {
		dispatch({type: GET_STRATEGIES.triggered})
		getMDSClient()
			.query({
				query: strategiesQuery,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_STRATEGIES.succeeded,
					response,
				})
			})
	}
}
