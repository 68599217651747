import {createFetchActions} from '../../../services/createActions'
import {post, put} from '../../../services/createAPICall'
import {getConfigBaseUrl} from '../../../services/apiService'
import {getCounterpartyId} from '../../../services/authService'

export const GET_MY_FUNDS = createFetchActions('GET_MY_FUNDS')
export const SAVE_MY_FUNDS = createFetchActions('SAVE_MY_FUNDS')
export const EDIT_MY_FUNDS = createFetchActions('EDIT_MY_FUNDS')
export const CLEAR_FUNDS_DATA = createFetchActions('CLEAR_FUNDS_DATA')

export const saveMyFunds = (data: any) => (dispatch: any) => {
	return dispatch(post(SAVE_MY_FUNDS, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/funds/`, data))
}

export const editMyFunds = (data: any) => (dispatch: any) => {
	return dispatch(
		put(EDIT_MY_FUNDS, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/funds/${data.fundId}`, data)
	)
}

// export const clearFundsData = () => {
// 	return {
// 		type: CLEAR_FUNDS_DATA,
// 	}
// }
