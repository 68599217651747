import gql from 'graphql-tag'
export {sectorsIndicesQuery as distinctSectorsAndIndicesQuery} from '../collateralBasketList/collateralBasket/collateralBasketQueries'

//TODO Add Index to gql
const typeAheadResultQuery = (keyword = '') => gql`
query{
  securitiesSuggestions(keyword: "${keyword}"){
    securityId
    ticker
    longName
    isin
    cusip
    index
    price
    priceMultiplier
    assetType
    assetSubType
  }
}
`

const strategiesQuery = gql`
	{
		strategies {
			displayName
			code
			direction {
				displayName
				code
			}
		}
	}
`

export const getCollateralBasketsQuery = (flag: boolean) => gql`
	query {
		openCollateralBaskets {
			collateralBasketId
			name
			orderId
			sector
			weighting {
				weight
				code
			}
			distinctSecurities
			isConstraintPool
			minimumRating
			includeSpecialAndHTB
			includeDividendsPayingInNext10Days
			strategy {
				strategy
				code
			}
			currency
			assetTypes
			index
			notionalAmount
			createdDate
			collateralBasketItems {
				collateralBasketItemId
				securityId
				classification
				ticker
				cusip
				isin
				price
				priceMultiplier
				quantity
				availableQuantity
			}
		}
	}
`

export {typeAheadResultQuery, strategiesQuery}
