import {Subject} from 'rxjs'

export const mpsTenantTopicOrderNotificationEmitter = new Subject()

export const pmsTopicGroupEmitter = new Subject()
export const pmsActivityCreatedTopicGroupEmitter = new Subject()
export const pmsRestrictedSecurityDocumentUploadedEmitter = new Subject()
export const pmsAbcmRerunEmitter = new Subject()
export const pmsUserQueueNotificationEmitter = new Subject()
export const pmsConfigurationUpdateEmitter = new Subject()

export const locateActivityCreatedTopicGroupEmitter = new Subject()
export const alpActivityCreatedTopicGroupEmitter = new Subject()
export const alpActivityCreatedTopicTenantEmitter = new Subject()
export const alpActivityCreatedOnlyLocateTopicGroupEmitter = new Subject()
export const alpBatchTopicGroupTriggerEmitter = new Subject()
// export const alpBatchTopicGroupUnsubscribeEmitter = new Subject()
// export const alpBatchTopicGroupEmitter = new Subject()
export const alpTopicUserEmitter = new Subject()

export const auctionTopicGroupTriggerEmitter = new Subject()
export const auctionTopicGroupUnsubscribeEmitter = new Subject()
export const auctionTopicGroupEmitter = new Subject()
export const auctionTopicBorrowerGroupEmitter = new Subject()
export const auctionTopicTenantGroupEmitter = new Subject()
export const auctionUpdateTopicTriggerEmitter = new Subject()
export const auctionUpdateTopicUnsubscribeEmitter = new Subject()
export const auctionUpdateTopicGroupEmitter = new Subject()

export const marginAnalysisTopicUserEmitter = new Subject()
