import {
	GET_ABC_PORTFOLIO,
	FETCH_ABC_PORTFOLIO_COUNT,
	GET_ABC_PORTFOLIO_FILTER,
	SET_ABC_PORTFOLIO_GROUP_FILTERS,
	SET_ABC_PORTFOLIO_SELECTED_VIEW,
	RESET_ABC_PORTFOLIO_DATA,
	RESET_ABC_PORTFOLIO_FILTERS,
	RESET_ABC_PORTFOLIO_LOADER,
	STOP_ABC_PORTFOLIO_LOADER,
	FETCH_LOCATE_SEARCH_RESULT_FOR_ABCM,
	FETCH_ABCM_LOCATE_SEARCH_RESULT_AND_UPDATE_QUEUE,
	EMPTY_LOCATE_COMPLETE_QUEUE_RESULT_FROM_ABCM,
} from './abcPortfolioActions'

export interface AbcPortfolio {
	loading: boolean
	abcPortfolio: any
	groupOneFilterValues: any
	groupTwoFilterValues: any
	selectedView: any
	groupFilters: any
	tableLoading: boolean
	locateSearchData: any
	abcmLocateSearchLoading: boolean
	locateSearchBucketData: any[]
	locateSearchLoading: boolean
	totalRows: number
}

const initialState: AbcPortfolio = {
	loading: false,
	abcPortfolio: [],
	groupOneFilterValues: [],
	groupTwoFilterValues: [],
	selectedView: '',
	groupFilters: {},
	tableLoading: true,
	locateSearchData: [],
	abcmLocateSearchLoading: false,
	locateSearchBucketData: [],
	locateSearchLoading: false,
	totalRows: 0,
}

export function abcPortfolioReducer(state = initialState, action: any) {
	switch (action.type) {
		case SET_ABC_PORTFOLIO_GROUP_FILTERS:
			return {...state, groupFilters: action.response.data}
		case SET_ABC_PORTFOLIO_SELECTED_VIEW:
			return {...state, selectedView: action.response.data}
		case FETCH_ABC_PORTFOLIO_COUNT.succeeded:
			return {...state, totalRows: action.response && action.response.data}
		case GET_ABC_PORTFOLIO.triggered:
			return {...state, loading: true, tableLoading: true}
		case GET_ABC_PORTFOLIO.failed:
			return {...state, loading: false, tableLoading: false}
		case GET_ABC_PORTFOLIO.succeeded:
			const abcPortfolio = action && action.response && action.response.data && action.response.data.abcPortfolio
			return {
				...state,
				abcPortfolio,
				loading: false,
				tableLoading: false,
			}

		case GET_ABC_PORTFOLIO_FILTER.triggered:
			return {...state, loading: true}
		case GET_ABC_PORTFOLIO_FILTER.failed:
			return {...state, loading: false}
		case GET_ABC_PORTFOLIO_FILTER.succeeded:
			let filterParams: any = {}
			if (action.groupKey === 'groupOne') {
				filterParams.groupOneFilterValues = action.response.data && action.response.data.abcPortfolioFiltersValue
			} else {
				filterParams.groupTwoFilterValues = action.response.data && action.response.data.abcPortfolioFiltersValue
			}
			return {
				...state,
				...filterParams,
				loading: false,
			}

		case RESET_ABC_PORTFOLIO_DATA:
			return {
				...state,
				abcPortfolio: [],
			}

		case RESET_ABC_PORTFOLIO_FILTERS:
			return {
				...state,
				groupOneFilterValues: [],
				groupTwoFilterValues: [],
			}

		case RESET_ABC_PORTFOLIO_LOADER:
			return {
				...state,
				tableLoading: false,
			}

		case STOP_ABC_PORTFOLIO_LOADER:
			return {
				...state,
				tableLoading: false,
			}

		case FETCH_LOCATE_SEARCH_RESULT_FOR_ABCM.triggered:
			return {...state, abcmLocateSearchLoading: true, locateSearchData: []}
		case FETCH_LOCATE_SEARCH_RESULT_FOR_ABCM.failed:
			return {...state, locateSearchData: [], abcmLocateSearchLoading: false}
		case FETCH_LOCATE_SEARCH_RESULT_FOR_ABCM.succeeded:
			const locateSearchData = action.response.data && action.response.data.searchAvailabilitySecurityResults
			return {...state, locateSearchData, abcmLocateSearchLoading: false}

		case FETCH_ABCM_LOCATE_SEARCH_RESULT_AND_UPDATE_QUEUE.triggered:
			return {...state, locateSearchLoading: true}
		case FETCH_ABCM_LOCATE_SEARCH_RESULT_AND_UPDATE_QUEUE.failed:
			return {...state, locateSearchLoading: false}
		case FETCH_ABCM_LOCATE_SEARCH_RESULT_AND_UPDATE_QUEUE.succeeded:
			const locateSearchBucketData =
				action.response.data && action.response.data.searchAvailabilitiesNeedsDocumentSearchResultAndUpdateQueue
			return {...state, locateSearchBucketData, locateSearchLoading: false}

		case EMPTY_LOCATE_COMPLETE_QUEUE_RESULT_FROM_ABCM:
			return {...state, locateSearchBucketData: []}
	}
	return state
}
