import {
	CLEAR_ALLOCATIONS,
	CLEAR_SELECTED_ALLOCATION,
	EDIT_MY_ALLOCATIONS,
	FETCH_ALLOCATION_DETAILS,
	GET_LEGAL_ENTITY_DATA,
} from './AllocationActions'
import {GET_ALL_ALLOCATIONS, SAVE_MY_ALLOCATIONS} from './AllocationActions'

export interface AllocationData {
	loading: boolean
	allAllocations: any[]
	selectedAllocationDetails: any
	legalEntities: any
}

const initialState: AllocationData = {
	loading: false,
	allAllocations: [],
	selectedAllocationDetails: null,
	legalEntities: [],
}

export function AllocationReducer(state = initialState, action: any) {
	let allAllocations = []
	switch (action.type) {
		case GET_ALL_ALLOCATIONS.triggered:
			return {...state, loading: true}
		case GET_ALL_ALLOCATIONS.succeeded:
			allAllocations = (action.response.data && action.response.data.allAllocations) || []
			return {...state, loading: false, allAllocations}
		case GET_ALL_ALLOCATIONS.failed:
			return {...state, loading: false}

		case FETCH_ALLOCATION_DETAILS.triggered:
			return {...state, loading: true}
		case FETCH_ALLOCATION_DETAILS.succeeded:
			const selectedAllocationDetails = (action.response.data && action.response.data) || []
			return {...state, loading: false, selectedAllocationDetails}
		case FETCH_ALLOCATION_DETAILS.failed:
			return {...state, loading: false}

		case GET_LEGAL_ENTITY_DATA.triggered:
			return {...state, loading: true}
		case GET_LEGAL_ENTITY_DATA.succeeded:
			return {...state, loading: false, legalEntities: action.response.data}
		case GET_LEGAL_ENTITY_DATA.failed:
			return {...state, loading: false}

		case SAVE_MY_ALLOCATIONS.triggered:
			return {...state, loading: true}
		case SAVE_MY_ALLOCATIONS.succeeded:
		case SAVE_MY_ALLOCATIONS.failed:
			return {...state, loading: false}

		case EDIT_MY_ALLOCATIONS.triggered:
			return {...state, loading: true}
		case EDIT_MY_ALLOCATIONS.succeeded:
		case EDIT_MY_ALLOCATIONS.failed:
			return {...state, loading: false}

		case CLEAR_SELECTED_ALLOCATION:
			return {...state, selectedAllocationDetails: []}

		case CLEAR_ALLOCATIONS:
			return {...state, allAllocations: [], legalEntities: []}
	}
	return state
}
