import React from 'react'
import {Link} from 'react-router-dom'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {ArrayUtil, FormatUtil} from 'helper-util'
import _ from 'lodash'

import {IconChangeView, InProgress, NoActivity, EditIcon} from '../styles/svg/svgIcons'
import {
	formatWithSeparator,
	formatWithSeparatorAndDecimals,
	joinArrayElements,
	formatWithTwoDecimals,
	formatWithFourDecimals,
} from '../utils/formatUtils'
import {tableHeaderClassName, tableHeaderWithTitle} from '../utils/reactTableUtil'
import {getInefficiencyClassName, showBidStatus} from '../utils/featureUtils'
import {getShortenedId} from '../utils/featureUtils'
import {formatWithDateAndTime, formatResponseTime, formatDate} from '../utils/dateUtil'
import {getCounterPartyStatus} from '../utils/featureUtils'
import {paths} from '../routes/routesConfig'
import {encryptURL, noCommaFourDecimal} from '../common/AgGrid/AgGridHelper'
import {extendStorageToPopup} from '../services/authService'

export const getTotal = (accessor, data) => {
	const summation = accessor && data ? data.reduce((acc, next) => (acc += next[accessor]), 0) : null

	if (summation === null) return <div></div>
	return (
		<div className='totalTxt d-flex align-items-end flex-column pr-2 pl-3 pb-2'>
			<p>Total</p>
			<p>{formatWithSeparator(Math.round(summation))}</p>
		</div>
	)
}

const getNotionalTotal = (accessor, data) => {
	const summation = accessor && data ? data.reduce((acc, next) => (acc += next[accessor] * next['price']), 0) : null

	if (summation === null) return <div></div>
	return (
		<div className='totalTxt d-flex align-items-end flex-column pr-2 pl-3 pb-2'>
			<p>Total</p>
			<p>{formatWithSeparator(Math.round(summation))}</p>
		</div>
	)
}

// Common Cell Design
const commaNoDecimal = {
	headerClassName: tableHeaderClassName(),
	Cell: props => {
		const modifiedValue = formatWithSeparator(props.value)
		return <div className='overflow-ellipsis w-100 text-right mr-2'>{modifiedValue}</div>
	},
}

const commaTwoDecimal = {
	headerClassName: tableHeaderClassName(),
	Cell: props => {
		const modifiedValue = formatWithSeparatorAndDecimals(Number(props.value))
		return <div className='overflow-ellipsis w-100 text-right mr-2'>{modifiedValue}</div>
	},
}

const noCommaNoDecimal = {
	headerClassName: tableHeaderClassName(),
	Cell: props => {
		const modifiedValue = Math.round(props.value)
		return <div className='overflow-ellipsis w-100 text-right mr-2'>{modifiedValue}</div>
	},
}

const noCommaTwoDecimal = {
	headerClassName: tableHeaderClassName(),
	Cell: props => {
		const modifiedValue = formatWithTwoDecimals(Number(props.value))
		return <div className='overflow-ellipsis w-100 text-right mr-2'>{modifiedValue}</div>
	},
}

const noCommaTwoDecimalPercent = {
	headerClassName: tableHeaderClassName(),
	Cell: props => {
		const modifiedValue = formatWithTwoDecimals(Number(props.value) * 100)
		return <div className='overflow-ellipsis w-100 text-right mr-2'>{modifiedValue}</div>
	},
}

const yyyyMmDdDate = {
	Cell: props => {
		return <div className='overflow-ellipsis'>{formatDate(props.value)}</div>
	},
}

/*A-Z Convention*/
export const customColumnField = {
	inefficienciesArray: 'inefficienciesArray',
	tableTitle: 'tableTitle',
}

/*A-Z Convention*/
export const columnDefinition = {
	abcmCategoryName: {
		Header: tableHeaderWithTitle('ABCM Category'),
		accessor: 'abcmCategoryName',
	},
	abcmCategoryType: {
		Header: tableHeaderWithTitle('ABCM Category Type'),
		accessor: 'abcmCategoryType',
	},
	acknowledgedBy: {
		Header: tableHeaderWithTitle('Acknowledged By'),
		accessor: 'acknowledgedBy',
		minWidth: 130,
	},
	activityType: {
		Header: tableHeaderWithTitle('Activity'),
		accessor: 'activityType',
		Cell: props => {
			return <div className='text-success'>{props.value}</div>
		},
		minWidth: 80,
	},
	activityTypev2: {
		Header: tableHeaderWithTitle('Activity'),
		accessor: 'activityType',
		Cell: props => {
			const notClickable =
				FormatUtil.text.toLowerCase(props.value) === 'n/a' ||
				FormatUtil.text.toLowerCase(props.original.portfolioEntryType) !== 'start of day'

			if (notClickable) return props.value

			return (
				<Link to={`/activity-type/${props.original.id}`} className='text-success'>
					{props.value}
				</Link>
			)
		},
		minWidth: 80,
	},
	agreement: {
		Header: tableHeaderWithTitle('Agreement'),
		accessor: 'clientAgreements',
		Cell: props => {
			return (
				<span className='overflow-ellipsis'>
					{(Array.isArray(props.value) &&
						props.value
							.filter(item => item.isActive)
							.map(item => item.agreementType)
							.join(', ')) ||
						''}
				</span>
			)
		},
	},
	agreementType: {
		Header: tableHeaderWithTitle('Agreement Type'),
		accessor: 'agreementType',
		minWidth: 150,
	},
	allowedNtl: {
		Header: tableHeaderWithTitle('Avail Notl'),
		accessor: 'allowedNtl',
		minWidth: 120,
		...commaNoDecimal,
	},
	allInRate: {
		Header: tableHeaderWithTitle('All In Rate'),
		headerClassName: tableHeaderClassName(),
		accessor: 'allInRate',
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>
					{`${formatWithSeparatorAndDecimals(props.value)} %`}
				</div>
			)
		},
		minWidth: 120,
	},
	allocatedQuantity: {
		Header: tableHeaderWithTitle('Matched Quantity'),
		accessor: 'allocatedQuantity',
		minWidth: 150,
		headerClassName: tableHeaderClassName(),
		Cell: props => (
			<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
		),
	},
	allocatedQuantityv1: {
		Header: tableHeaderWithTitle('Matched Quantity'),
		accessor: 'allocated',
		minWidth: 150,
		headerClassName: tableHeaderClassName(),
		Cell: props => {
			const isNegotiated = props.original.original !== props.original.allocated
			return (
				<div className='d-flex'>
					<div className={`overflow-ellipsis ${isNegotiated ? '' : 'w-100'}text-right mr-2`}>
						{formatWithSeparator(Math.round(props.value))}
					</div>
					{isNegotiated && (
						<div title={`Original: ${props.original.original}`}>
							<EditIcon />
						</div>
					)}
				</div>
			)
		},
	},
	allocatedNotional: {
		Header: tableHeaderWithTitle('Matched Notional'),
		accessor: 'allocatedNotional',
		minWidth: 150,
		headerClassName: tableHeaderClassName(),
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
		Footer: props => {
			return getTotal('allocatedNotional', props.data)
		},
	},
	allocatedNotionalv1: {
		Header: tableHeaderWithTitle('Matched Notional'),
		accessor: 'allocatedNotional',
		minWidth: 150,
		headerClassName: tableHeaderClassName(),
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>
					{formatWithSeparator(Math.round(props.original.allocated * props.original.securityPrice.amount))}
				</div>
			)
		},
		Footer: props => {
			const data = _.cloneDeep(props.data).map(item => {
				if (item && item['securityPrice.amount']) {
					item.price = item['securityPrice.amount']
				}
				return item
			})
			return getNotionalTotal('allocated', data)
		},
	},
	amount: {
		Header: tableHeaderWithTitle('Amount'),
		accessor: 'amount',
		minWidth: 80,
		Cell: props => {
			return formatWithSeparator(props.value)
		},
	},
	asset: {
		Header: tableHeaderWithTitle('Asset'),
		accessor: 'asset',
		minWidth: 90,
	},
	assetClassification: {
		Header: tableHeaderWithTitle('Asset Classification'),
		accessor: 'assetClassification',
		minWidth: 140,
	},
	assignedTo: {
		Header: tableHeaderWithTitle('Assigned to'),
		accessor: 'orderId',
		Cell: props => <div className='overflow-ellipsis'>{getShortenedId(props.value)}</div>,
		minWidth: 120,
	},
	assetMaturityDate: {
		Header: tableHeaderWithTitle('Asset Maturity Date'),
		accessor: 'assetMaturityDate',
		...yyyyMmDdDate,
	},
	assetSubType: {
		Header: tableHeaderWithTitle('Asset SubType'),
		accessor: 'assetSubType',
		minWidth: 150,
	},
	assetType: {
		Header: tableHeaderWithTitle('Asset Type'),
		accessor: 'assetType',
		minWidth: 120,
	},
	assetUtilizationPercent: {
		Header: tableHeaderWithTitle('Asset Utilization'),
		headerClassName: tableHeaderClassName(),
		accessor: 'assetUtilizationPercent',
		minWidth: 120,
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{`${formatWithSeparatorAndDecimals(
					props.value
				)} %`}</div>
			)
		},
	},
	availableNotional: {
		Header: tableHeaderWithTitle('Net Notional'),
		headerClassName: tableHeaderClassName(),
		accessor: 'availableNotional',
		minWidth: 150,
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-3'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
		Footer: props => {
			return getTotal('availableNotional', props.data)
		},
	},
	availabilitiesContributionAmount: {
		Header: tableHeaderWithTitle('Availabilty Notional'),
		accessor: 'availabilitiesContributionAmount',
		minWidth: 120,
		...commaNoDecimal,
	},
	availableQuantity: {
		Header: tableHeaderWithTitle('Net Quantity'),
		headerClassName: tableHeaderClassName(),
		accessor: 'availableQuantity',
		minWidth: 150,
		Cell: props => <div className='overflow-ellipsis w-100 text-right mr-3'>{formatWithSeparator(props.value)}</div>,
	},
	availableQuantityV1: {
		Header: tableHeaderWithTitle('Availability Quantity'),
		headerClassName: tableHeaderClassName(),
		accessor: 'availableQuantity',
		minWidth: 150,
		Cell: props => <div className='overflow-ellipsis w-100 text-right mr-3'>{formatWithSeparator(props.value)}</div>,
	},
	balanceSheet: {
		Header: tableHeaderWithTitle('Balance Sheet'),
		headerClassName: tableHeaderClassName(),
		accessor: 'balanceSheetContributionAmount',
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
		minWidth: 120,
	},
	balanceSheetv1: {
		Header: tableHeaderWithTitle('Balance Sheet'),
		headerClassName: tableHeaderClassName(),
		accessor: 'balanceSheetContributionAmount',
		Cell: props => {
			const amount = (props.original.balanceSheetContribution && props.original.balanceSheetContribution.amount) || 0
			return <div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(amount))}</div>
		},
		minWidth: 120,
	},
	balancesheetContributionFromBasket: (totalBalancesheetContributionFromBasket: number) => {
		return {
			Header: tableHeaderWithTitle('Balancesheet Impact'),
			accessor: 'balancesheetContributionFromBasket',
			minWidth: 150,
			headerClassName: 'bg-cream border-left inventory-text pl-3',
			className: 'bg-cream border-left inventory-text pl-3',
			Cell: props => <div className='text-right w-80'>{formatWithSeparator(props.value)}</div>,
			Footer: (
				<div className='totalTxt d-flex align-items-end flex-column pr-4 pl-3'>
					<p>Total</p>
					<p>{formatWithSeparator(totalBalancesheetContributionFromBasket)}</p>
				</div>
			),
		}
	},
	basePriceAmount: {
		Header: tableHeaderWithTitle('Base Price Amount'),
		headerClassName: tableHeaderClassName(),
		accessor: 'basePriceAmount',
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparatorAndDecimals(props.value)}</div>
			)
		},
		minWidth: 120,
	},
	basePriceAmountV2: {
		Header: tableHeaderWithTitle('Base Price Amount'),
		headerClassName: tableHeaderClassName(),
		accessor: 'basePrice.amount',
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparatorAndDecimals(props.value)}</div>
			)
		},
		minWidth: 120,
	},
	baseNotional: {
		Header: tableHeaderWithTitle('Base Notional'),
		headerClassName: tableHeaderClassName(),
		accessor: 'baseNotional',
		minWidth: 120,
		...commaNoDecimal,
	},
	baseNotionalv2: {
		Header: tableHeaderWithTitle('Base Notional'),
		headerClassName: tableHeaderClassName(),
		accessor: 'baseNotional.amount',
		minWidth: 120,
		...commaNoDecimal,
	},
	baseNotionalv3: {
		Header: tableHeaderWithTitle('Base Notional'),
		headerClassName: tableHeaderClassName(),
		accessor: 'baseNotionalAmount',
		minWidth: 120,
		...commaNoDecimal,
	},
	baseNotionalCurrency: {
		Header: tableHeaderWithTitle('Base Notional Currency'),
		accessor: 'baseNotionalCurrency',
		Cell: props => {
			return <div className='overflow-ellipsis w-100 text-center mr-2'>{props.value}</div>
		},
		minWidth: 150,
	},
	basketActions: {Header: tableHeaderWithTitle('Actions'), accessor: 'actions', minWidth: 180},
	basketAssetTypes: {
		Header: tableHeaderWithTitle('Asset Type'),
		id: 'assetType',
		Cell: props => {
			return <div className='overflow-ellipsis'>{joinArrayElements(props.original.assetTypes)}</div>
		},
		minWidth: 120,
	},
	basketIndex: {
		Header: tableHeaderWithTitle('Index'),
		id: 'index',
		Cell: props => {
			return <div className='overflow-ellipsis'>{joinArrayElements(props.original.index)}</div>
		},
		minWidth: 80,
	},
	basketNotional: {
		Header: tableHeaderWithTitle('Basket Notional'),
		headerClassName: tableHeaderClassName(),
		accessor: 'basketNotional',
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
		minWidth: 120,
		Footer: props => {
			return getTotal('basketNotional', props.data)
		},
	},
	basketNotionalv1: {
		Header: tableHeaderWithTitle('Basket Notional'),
		headerClassName: tableHeaderClassName(),
		accessor: 'notional',
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
		minWidth: 120,
		Footer: props => {
			return getTotal('basketNotional', props.data)
		},
	},
	basketSector: {
		Header: tableHeaderWithTitle('Sector'),
		accessor: 'sector',
		Cell: props => <div className='overflow-ellipsis'>{joinArrayElements(props.value)}</div>,
		minWidth: 80,
	},
	basketStrategy: {
		Header: tableHeaderWithTitle('Strategy'),
		id: 'strategy',
		Cell: props => {
			return props.original.strategy && <div className='overflow-ellipsis'>{props.original.strategy.strategy}</div>
		},
		minWidth: 80,
	},
	benchmark: {
		Header: tableHeaderWithTitle('Benchmark'),
		accessor: 'benchmark',
		minWidth: 120,
	},
	bid: {
		Header: tableHeaderWithTitle('Bid'),
		accessor: 'bid',
		minWidth: 80,
		Cell: props => {
			const value = ((props.value as string) || '').toLowerCase()
			return FormatUtil.text.toUpperCase(value.charAt(0)).concat(value.slice(1, value.length))
		},
	},
	bbId: {Header: tableHeaderWithTitle('BBID'), accessor: 'bbId', minWidth: 80},
	book: {Header: tableHeaderWithTitle('Book'), accessor: 'book', minWidth: 80},
	borrow: {
		Header: tableHeaderWithTitle('Borrow'),
		accessor: 'borrow',
		minWidth: 120,
		...commaNoDecimal,
	},
	borrowAndLoan: {
		Header: tableHeaderWithTitle('Trade Type'),
		accessor: 'borrowAndLoan',
		minWidth: 100,
		Cell: props => {
			const isLoan =
				FormatUtil.text.toLowerCase(props.value) === 'loan' || FormatUtil.text.toLowerCase(props.value) === 'repo'
			const className = `badge badge-pill text-white ${isLoan ? 'bg-rajah' : 'bg-flamingo'}`
			return <span className={className}>{props.value}</span>
		},
	},
	borrowContributionAmount: {
		Header: tableHeaderWithTitle('Borrow Contribution'),
		accessor: 'borrowContributionAmount',
		minWidth: 120,
		...commaNoDecimal,
	},
	borrowFee: {
		Header: tableHeaderWithTitle('Borrow Fee'),
		accessor: 'borrowFee',
		...commaTwoDecimal,
	},
	borrowVsCash: (totalBorrowVsCash: number) => {
		return {
			Header: tableHeaderWithTitle('Borrow vs Cash'),
			headerClassName: 'bg-blue border-left inventory-text pl-3',
			accessor: 'borrowVsCash',
			className: 'bg-blue border-left inventory-text pl-3',
			minWidth: 150,
			Footer: (
				<div className='totalTxt d-flex align-items-end flex-column pr-4'>
					<p>Total</p>
					<p>{formatWithSeparator(totalBorrowVsCash)}</p>
				</div>
			),
		}
	},
	borrowVsCashNotional: (totalBorrowVsCashNotional: number) => {
		return {
			Header: tableHeaderWithTitle('BvC Notional'),
			headerClassName: 'bg-blue pl-3 inventory-text',
			accessor: 'borrowVsCashNotional',
			className: 'bg-blue pl-3 inventory-text',
			minWidth: 150,
			Cell: props => <div className='text-right w-80'>{formatWithSeparator(props.value)}</div>,
			Footer: (
				<div className='totalTxt d-flex align-items-end flex-column pr-4 pl-3'>
					<p>Total</p>
					<p>{formatWithSeparator(totalBorrowVsCashNotional)}</p>
				</div>
			),
		}
	},
	borrowVsNonCash: (totalBorrowVsNonCash: number) => {
		return {
			Header: tableHeaderWithTitle('Borrow vs Non Cash'),
			headerClassName: 'bg-blue pl-3 inventory-text',
			accessor: 'borrowVsNonCash',
			className: 'bg-blue pl-3 inventory-text',
			minWidth: 150,
			Footer: (
				<div className='totalTxt d-flex align-items-end flex-column pr-4'>
					<p>Total</p>
					<p>{formatWithSeparator(totalBorrowVsNonCash)}</p>
				</div>
			),
		}
	},
	borrowVsNonCashNotional: (totalBorrowVsNonCashNotional: number) => {
		return {
			Header: tableHeaderWithTitle('BvNC Notional'),
			headerClassName: 'bg-blue pl-3 inventory-text',
			accessor: 'borrowVsNonCashNotional',
			className: 'bg-blue pl-3 inventory-text',
			minWidth: 150,
			Cell: props => <div className='text-right w-80'>{formatWithSeparator(props.value)}</div>,
			Footer: (
				<div className='totalTxt d-flex align-items-end flex-column pr-4 pl-3'>
					<p>Total</p>
					<p>{formatWithSeparator(totalBorrowVsNonCashNotional)}</p>
				</div>
			),
		}
	},
	bvcSaving: (totalBvcSaving: number) => {
		return {
			Header: tableHeaderWithTitle('Borrow vs Cash'),
			accessor: 'bvcSaving',
			minWidth: 150,
			headerClassName: 'bg-cream inventory-text pl-3',
			className: 'bg-cream inventory-text pl-3',
			Cell: props => <div className='text-right w-80'>{formatWithSeparator(props.value)}</div>,
			Footer: (
				<div className='totalTxt d-flex align-items-end flex-column pr-4'>
					<p>Total</p>
					<p>{formatWithSeparator(totalBvcSaving)}</p>
				</div>
			),
		}
	},
	bvncSaving: (totalBvncSaving: number) => {
		return {
			Header: tableHeaderWithTitle('Borrow vs Non Cash'),
			accessor: 'bvncSaving',
			headerClassName: 'bg-cream pl-3 inventory-text',
			minWidth: 150,
			className: 'bg-cream pl-3 inventory-text',
			Cell: props => <div className='text-right w-80'>{formatWithSeparator(props.value)}</div>,
			Footer: (
				<div className='totalTxt d-flex align-items-end flex-column pr-4'>
					<p>Total</p>
					<p>{formatWithSeparator(totalBvncSaving)}</p>
				</div>
			),
		}
	},
	buySellInd: {
		Header: tableHeaderWithTitle('Buy Sell Indicator'),
		accessor: 'buySellInd',
		minWidth: 80,
	},
	cancellationReason: {
		Header: tableHeaderWithTitle('Cancellation Reason'),
		accessor: 'cancellationReason',
		Cell: props => {
			return (
				<div title={props.value} className='overflow-ellipsis w-100'>
					{props.value}
				</div>
			)
		},
		minWidth: 180,
	},
	cashAmount: {
		Header: tableHeaderWithTitle('Recall/Return Cash Amount'),
		headerClassName: tableHeaderClassName(),
		accessor: 'cashAmount',
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
		minWidth: 140,
		Footer: props => {
			return getTotal('cashAmount', props.data)
		},
	},
	cashAmountv1: {
		Header: tableHeaderWithTitle('Cash Value'),
		headerClassName: tableHeaderClassName(),
		accessor: 'cashAmount',
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
		minWidth: 140,
	},
	cashAmountv2: {
		Header: tableHeaderWithTitle('Cash'),
		headerClassName: tableHeaderClassName(),
		accessor: 'cashAmount',
		Cell: props => {
			return (
				<div
					className={`overflow-ellipsis w-100 text-right mr-2 font-weight-600 ${
						props.value > 0 ? 'text-success' : props.value === 0 ? 'text-warning' : 'text-danger'
					}`}
				>
					{formatWithSeparator(Math.round(props.value))}
				</div>
			)
		},
		minWidth: 140,
	},
	cashCurrency: {Header: tableHeaderWithTitle('Cash Currency'), accessor: 'cashCurrency', minWidth: 120},
	cashRate: {
		Header: tableHeaderWithTitle('Cash Rate'),
		headerClassName: tableHeaderClassName(),
		accessor: 'cash',
		Cell: props => {
			return <div className='overflow-ellipsis w-100 text-right mr-2'>{props.value}</div>
		},
		minWidth: 120,
	},
	classification: {
		Header: tableHeaderWithTitle('Classification'),
		accessor: 'classification',
		minWidth: 120,
	},
	client: {
		Header: tableHeaderWithTitle('Client'),
		accessor: 'client',
		minWidth: 80,
	},
	clientRanking: {
		Header: tableHeaderWithTitle('Client Ranking'),
		headerClassName: tableHeaderClassName(),
		accessor: 'clientRanking',
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>
					{Number(props.value) ? formatWithSeparator(Math.round(props.value)) : props.value}
				</div>
			)
		},
	},
	contractId: {
		Header: tableHeaderWithTitle('Contract ID'),
		accessor: 'contractId',
		minWidth: 60,
		Cell: props => {
			return props.value ? props.value : 'N/A'
		},
	},
	counterParty: {
		Header: tableHeaderWithTitle('Counterparty'),
		accessor: 'counterParty',
		minWidth: 120,
	},
	counterPartyv1: {
		Header: tableHeaderWithTitle('Counterparty'),
		accessor: 'counterPartyName',
		minWidth: 120,
	},
	counterPartyQuantity: {
		Header: tableHeaderWithTitle('Cpty Available Qty'),
		accessor: 'counterPartyQuantity',
		minWidth: 180,
		headerClassName: tableHeaderClassName(),
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
	},
	counterPartyQuantityv1: {
		Header: tableHeaderWithTitle('Cpty Available Qty'),
		accessor: 'availableQuantity',
		minWidth: 180,
		headerClassName: tableHeaderClassName(),
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
	},
	counterPartyNotional: {
		Header: tableHeaderWithTitle('Cpty Available Notional'),
		accessor: 'counterPartyNotional',
		minWidth: 180,
		headerClassName: tableHeaderClassName(),
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>
					{formatWithSeparator(Math.round(props.original.counterPartyQuantity * props.original.price))}
				</div>
			)
		},
		Footer: props => {
			return getNotionalTotal('counterPartyQuantity', props.data)
		},
	},
	counterPartyNotionalv1: {
		Header: tableHeaderWithTitle('Cpty Available Notional'),
		accessor: 'counterPartyNotional',
		minWidth: 180,
		headerClassName: tableHeaderClassName(),
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>
					{formatWithSeparator(Math.round(props.original.availableQuantity * props.original.securityPrice.amount))}
				</div>
			)
		},
		Footer: props => {
			const data = _.cloneDeep(props.data).map(item => {
				if (item && item['securityPrice.amount']) {
					item.price = item['securityPrice.amount']
				}
				return item
			})
			return getNotionalTotal('availableQuantity', data)
		},
	},
	counterPartyStatus: {
		Header: tableHeaderWithTitle('Status'),
		accessor: 'counterPartyStatus',
		minWidth: 150,
		Cell: props => {
			const value = getCounterPartyStatus(props.original.orderEntries || props.original.orderDetails)
			const badgeType = value === 'ACTION REQUIRED' ? 'bg-blue' : value === 'COMPLETED' ? 'bg-success' : 'bg-warning'
			return (
				<span className={`badge ${badgeType} f-12 badge-pill px-3 py-2 font-weight-400 text-white lh-10`}>{value}</span>
			)
		},
	},
	collateralAmount: {
		Header: tableHeaderWithTitle('Recall/Return Collateral Amount'),
		headerClassName: tableHeaderClassName(),
		accessor: 'collateralAmount',
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
		minWidth: 150,
		Footer: props => {
			return getTotal('collateralAmount', props.data)
		},
	},
	collateralAmountv1: {
		Header: tableHeaderWithTitle('Collateral Value'),
		headerClassName: tableHeaderClassName(),
		accessor: 'collateralAmount',
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
		minWidth: 150,
	},
	collateralAmountv2: {
		Header: tableHeaderWithTitle('Collateral Amount'),
		headerClassName: tableHeaderClassName(),
		accessor: 'collateralAmount',
		Cell: props => {
			return (
				<div
					className={`overflow-ellipsis w-100 text-right mr-2 font-weight-600 ${
						props.value > 0 ? 'text-success' : props.value === 0 ? 'text-warning' : 'text-danger'
					}`}
				>
					{formatWithSeparator(Math.round(props.value))}
				</div>
			)
		},
		minWidth: 150,
	},
	collateralAmountV3: {
		Header: tableHeaderWithTitle('Recall/Return Collateral Amount'),
		headerClassName: tableHeaderClassName(),
		accessor: 'collateral.amount',
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
		minWidth: 150,
		Footer: props => {
			return getTotal('collateral.amount', props.data)
		},
	},
	collateralBasketType: {
		Header: tableHeaderWithTitle('Direction'),
		accessor: 'collateralBasketType',
	},
	collateralBasketPurpose: {
		Header: tableHeaderWithTitle('Collateral Basket Purpose'),
		accessor: 'collateralBasketPurpose',
		minWidth: 150,
		Cell: props => {
			return <div>{props.value}</div>
		},
	},
	collateralCurrency: {
		Header: tableHeaderWithTitle('Collateral Currency'),
		accessor: 'collateralCurrency',
		minWidth: 120,
	},
	collateralType: {
		Header: tableHeaderWithTitle('Collateral Type'),
		accessor: 'collateralType',
		minWidth: 220,
	},
	country: {
		Header: tableHeaderWithTitle('Country'),
		accessor: 'country',
	},
	cpty: {Header: tableHeaderWithTitle('Counterparty'), accessor: 'cpty', minWidth: 120},
	createdBy: {
		Header: tableHeaderWithTitle('Created By'),
		accessor: 'createdBy',
		minWidth: 120,
	},
	createdDate: {
		Header: tableHeaderWithTitle('Created Date'),
		accessor: 'createdDate',
		Cell: props => {
			return <div className='overflow-ellipsis'>{formatDate(props.value)}</div>
		},
		minWidth: 120,
	},
	createdTime: {
		Header: tableHeaderWithTitle('Created Time'),
		accessor: 'createdDate',
		Cell: props => {
			if (props.value !== 'N/A') return <div>{formatWithDateAndTime(props.value)}</div>
			return props.value
		},
		className: 'align-self-start',
		minWidth: 150,
	},
	credit: {
		Header: tableHeaderWithTitle('Credit'),
		headerClassName: tableHeaderClassName(),
		accessor: 'credit',
		Cell: props => {
			return <div className='overflow-ellipsis w-100 text-right mr-2'>{props.value}</div>
		},
		minWidth: 80,
	},
	currency: {Header: tableHeaderWithTitle('Currency'), accessor: 'currency', minWidth: 80},
	currencyv1: {Header: tableHeaderWithTitle('Currency'), accessor: 'securityPrice.currency', minWidth: 80},
	currentAvailableQuantity: {
		Header: tableHeaderWithTitle('Current Available Quantity'),
		accessor: 'currentAvailableQuantity',
		minWidth: 180,
		headerClassName: tableHeaderClassName(),
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
	},
	currentDuration: {
		Header: tableHeaderWithTitle('Cur Dur'),
		accessor: 'currentDuration',
		minWidth: 90,
		...noCommaNoDecimal,
	},
	cusip: {Header: tableHeaderWithTitle('CUSIP'), accessor: 'cusip', minWidth: 80},
	cusipCode: {Header: tableHeaderWithTitle('Code'), accessor: 'cusipCode', minWidth: 80},
	date: {
		Header: tableHeaderWithTitle('Date'),
		accessor: 'date',
		width: 80,
	},
	dirtyPrice: {
		Header: tableHeaderWithTitle('All In Price'),
		accessor: 'dirtyPrice',
		Cell: props => {
			return props && props.value && formatWithSeparatorAndDecimals(props.value.amount)
		},
	},
	distinctSecurities: {
		Header: tableHeaderWithTitle('Distinct Securities'),
		headerClassName: tableHeaderClassName(),
		accessor: 'distinctSecurities',
		Cell: props => {
			return <div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(props.value)}</div>
		},
		minWidth: 150,
	},
	duration: {
		Header: tableHeaderWithTitle('Duration'),
		headerClassName: tableHeaderClassName(),
		accessor: 'duration',
		Cell: props => {
			return <div className='overflow-ellipsis w-100 text-right mr-2'>{props.value}</div>
		},
		minWidth: 120,
	},
	durationInSec: {
		Header: tableHeaderWithTitle('Duration'),
		accessor: 'durationInSec',
		headerClassName: tableHeaderClassName(),
		minWidth: 120,
		Cell: props => {
			return <div className='overflow-ellipsis w-100 text-right mr-2'>{props.value}</div>
		},
	},
	durationTitle: {
		Header: tableHeaderWithTitle('Duration'),
		accessor: 'durationTitle',
		minWidth: 120,
	},
	email: {Header: tableHeaderWithTitle('Email Id'), accessor: 'email'},
	endCash: {
		Header: tableHeaderWithTitle('End Cash'),
		accessor: 'endCash',
		Cell: props => {
			return props && props.value && formatWithSeparator(props.value.amount)
		},
	},
	endDate: {
		Header: tableHeaderWithTitle('End Date'),
		accessor: 'endDate',
		Cell: props => {
			return <div className='overflow-ellipsis'>{formatDate(props.value)}</div>
		},
		minWidth: 120,
	},
	equity: {
		Header: tableHeaderWithTitle('Equity'),
		headerClassName: tableHeaderClassName(),
		accessor: 'equity',
		Cell: props => {
			return <div className='overflow-ellipsis w-100 text-right mr-2'>{props.value}</div>
		},
		minWidth: 120,
	},
	exposure: {
		Header: tableHeaderWithTitle('Exposure'),
		accessor: 'exposure',
		minWidth: 120,
		...commaNoDecimal,
	},
	feeRate: {
		Header: tableHeaderWithTitle('Fee Rate'),
		headerClassName: tableHeaderClassName(),
		accessor: 'feeRate',
		minWidth: 80,
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparatorAndDecimals(props.value)}</div>
			)
		},
	},
	file: {
		Header: tableHeaderWithTitle('File'),
		accessor: 'originalFilename',
		minWidth: 250,
		Cell: props => {
			return <div className='overflow-ellipsis'>{props.value}</div>
		},
	},
	financingGroup: {
		Header: tableHeaderWithTitle('Financing Group Name'),
		accessor: 'group.longName',
	},
	firmInternalizationPercent: {
		Header: tableHeaderWithTitle('Firm Internalization'),
		headerClassName: 'bg-blue inventory-text pl-3',
		className: 'bg-blue inventory-text pl-3',
		accessor: 'firmInternalizationPercent',
		Cell: props => {
			return <div className='overflow-ellipsis w-100 text-right mr-2'>{props.value} %</div>
		},
	},
	firmLong: (totalFirmLong: number) => {
		return {
			Header: tableHeaderWithTitle('Firm Long'),
			headerClassName: 'bg-blue pl-3 inventory-text',
			accessor: 'firmLong',
			className: 'bg-blue pl-3 inventory-text',
			minWidth: 150,
			Footer: (
				<div className='totalTxt d-flex align-items-end flex-column pr-4 pl-3'>
					<p>Total</p>
					<p>{formatWithSeparator(totalFirmLong)}</p>
				</div>
			),
		}
	},
	firmLongNotional: (totalFirmLongNotional: number) => {
		return {
			Header: tableHeaderWithTitle('Firm Long Notional'),
			headerClassName: 'bg-blue pl-3 inventory-text',
			accessor: 'firmLongNotional',
			className: 'bg-blue pl-3 inventory-text',
			minWidth: 150,
			Footer: (
				<div className='totalTxt d-flex align-items-end flex-column pr-4 pl-3'>
					<p>Total</p>
					<p>{formatWithSeparator(totalFirmLongNotional)}</p>
				</div>
			),
		}
	},
	firmLongSaving: (totalFirmLongSaving: number) => {
		return {
			Header: tableHeaderWithTitle('Firm Long'),
			accessor: 'firmLongSaving',
			headerClassName: 'bg-cream pl-3 inventory-text',
			minWidth: 150,
			className: 'bg-cream pl-3 inventory-text',
			Cell: props => <div className='text-right w-80'>{formatWithSeparator(props.value)}</div>,
			Footer: (
				<div className='totalTxt d-flex align-items-end flex-column pr-4 pl-3'>
					<p>Total</p>
					<p>{formatWithSeparator(totalFirmLongSaving)}</p>
				</div>
			),
		}
	},
	fitchRating: {
		Header: tableHeaderWithTitle('Fitch Rating'),
		accessor: 'fitchRating',
		minWidth: 120,
	},
	fixedIncome: {
		Header: tableHeaderWithTitle('Fixed Income'),
		headerClassName: tableHeaderClassName(),
		accessor: 'fixedIncome',
		Cell: props => {
			return <div className='overflow-ellipsis w-100 text-right mr-2'>{props.value}</div>
		},
		minWidth: 120,
	},
	frequencyOfReset: {
		Header: tableHeaderWithTitle('Frequency Of Reset'),
		accessor: 'frequencyOfReset',
		minWidth: 150,
	},
	fund: {Header: tableHeaderWithTitle('Fund'), accessor: 'fund', minWidth: 80},
	fundType: {
		Header: tableHeaderWithTitle('Fund Type'),
		accessor: 'fundType',
		minWidth: 120,
	},
	fundDuration: {
		Header: tableHeaderWithTitle('Fund Duration'),
		accessor: 'duration',
		headerClassName: tableHeaderClassName(),
		Cell: props => {
			return <div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(props.value)}</div>
		},
		minWidth: 150,
	},
	fxRate: {
		Header: tableHeaderWithTitle('Fx Rate'),
		accessor: 'exchangeRate',
		// accessor: 'fxRate',
		minWidth: 120,
		...noCommaFourDecimal,
	},
	grossNotional: {
		Header: tableHeaderWithTitle('Gross Notional'),
		accessor: 'grossNotional',
		minWidth: 120,
		...commaNoDecimal,
	},
	grossLongQuantity: {
		Header: tableHeaderWithTitle('Gross Long Quantity'),
		accessor: 'effectiveGrossLongQty',
		minWidth: 150,
		headerClassName: 'bg-blue inventory-text pl-3',
		className: 'bg-blue inventory-text pl-3',
		Cell: props => (
			<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
		),
	},
	grossLongNotional: (totalGrossLongNotional: number) => {
		return {
			Header: tableHeaderWithTitle('Gross Long Notional'),
			accessor: 'effectiveGrossLongNotional',
			minWidth: 150,
			headerClassName: 'bg-blue inventory-text pl-3',
			className: 'bg-blue inventory-text pl-3',
			Cell: props => (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			),
			Footer: (
				<div className='totalTxt d-flex align-items-end flex-column pr-4 pl-3'>
					<p>Total</p>
					<p>{formatWithSeparator(totalGrossLongNotional)}</p>
				</div>
			),
		}
	},
	grossShortQuantity: {
		Header: tableHeaderWithTitle('Gross Short Quantity'),
		accessor: 'effectiveGrossShortQty',
		minWidth: 150,
		headerClassName: 'bg-blue inventory-text pl-3',
		className: 'bg-blue inventory-text pl-3',
		Cell: props => (
			<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
		),
	},
	grossShortNotional: (totalGrossShortNotional: number) => {
		return {
			Header: tableHeaderWithTitle('Gross Short Notional'),
			accessor: 'effectiveGrossShortNotional',
			minWidth: 150,
			headerClassName: 'bg-blue inventory-text pl-3',
			className: 'bg-blue inventory-text pl-3',
			Cell: props => (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			),
			Footer: (
				<div className='pr-2 totalTxt d-flex align-items-end flex-column pr-2 pl-3'>
					<p>Total</p>
					<p>{formatWithSeparator(totalGrossShortNotional)}</p>
				</div>
			),
		}
	},
	groupedAssetType: {
		Header: tableHeaderWithTitle('Asset Type'),
		accessor: 'groupedAssetType',
		Cell: props => {
			const assetTypes = Array.isArray(props.value) ? props.value.join(', ') : ''
			return <span title={assetTypes}>{assetTypes}</span>
		},
	},
	haircut: {
		Header: tableHeaderWithTitle('Haircut'),
		headerClassName: tableHeaderClassName(),
		accessor: 'haircut',
		Cell: props => {
			return <div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithTwoDecimals(props.value)}</div>
		},
		minWidth: 80,
	},
	index: {Header: tableHeaderWithTitle('Index'), accessor: 'index', minWidth: 80},
	[customColumnField.inefficienciesArray]: {
		Header: tableHeaderWithTitle('Inefficiencies Type'),
		accessor: customColumnField.inefficienciesArray,
		Cell: props => {
			return (
				<div className='w-100'>
					{props.value &&
						props.value.split(',').map(val => {
							return (
								<span key={val} className={`${getInefficiencyClassName(val)} mr-2`}>
									{val}
								</span>
							)
						})}
				</div>
			)
		},
		minWidth: 220,
	},
	isin: {Header: tableHeaderWithTitle('ISIN'), accessor: 'isin', minWidth: 80},
	isRestricted: {
		Header: tableHeaderWithTitle('Restricted'),
		accessor: 'isRestricted',
		minWidth: 120,
		Cell: props => {
			return props.value
		},
	},
	isRestrictedv1: {
		Header: tableHeaderWithTitle('Restricted'),
		accessor: 'isRestricted',
		minWidth: 120,
		Cell: props => {
			return props.value ? 'Yes' : 'No'
		},
	},
	impactAssetUtilizationPercent: {
		Header: tableHeaderWithTitle('Impact Asset Utilization'),
		headerClassName: tableHeaderClassName(),
		accessor: 'assetUtilizationImpactPercent',
		minWidth: 120,
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{`${formatWithSeparatorAndDecimals(
					props.value
				)} %`}</div>
			)
		},
	},
	incomingEntryStatus: {
		Header: tableHeaderWithTitle('Status'),
		accessor: 'status',
		minWidth: 150,
		Cell: props => {
			const textColor =
				props.value === 'ACCEPTED' ? 'text-success' : props.value === 'REJECTED' ? 'text-danger' : 'text-warning'
			return <div className={`w-100 mr-2 ${textColor}`}>{props.value}</div>
		},
	},
	internalizationTotal: (totalInternalization: number) => {
		return {
			Header: tableHeaderWithTitle('Internalization Total'),
			headerClassName: 'bg-yellow border-left inventory-text pl-3',
			minWidth: 150,
			accessor: 'internalizationTotal',
			className: 'bg-yellow border-left inventory-text pl-3',
			Cell: props => <div className='text-right w-80'>{formatWithSeparator(props.value)}</div>,
			Footer: (
				<div className='totalTxt d-flex align-items-end flex-column pr-4'>
					<p>Total</p>
					<p>{formatWithSeparator(totalInternalization)}</p>
				</div>
			),
		}
	},
	internalizationNotional: (totalInternalizationNotional: number) => {
		return {
			Header: tableHeaderWithTitle('Internalization Notional'),
			headerClassName: 'bg-yellow inventory-text pl-3',
			minWidth: 150,
			accessor: 'internalizationNotional',
			className: 'bg-yellow inventory-text pl-3',
			Cell: props => <div className='text-right w-80'>{formatWithSeparator(props.value)}</div>,
			Footer: (
				<div className='totalTxt d-flex align-items-end flex-column pr-4'>
					<p>Total</p>
					<p>{formatWithSeparator(totalInternalizationNotional)}</p>
				</div>
			),
		}
	},
	internalizationPercent: {
		Header: tableHeaderWithTitle('Internalization Percent'),
		headerClassName: 'bg-yellow pl-3 inventory-text',
		accessor: 'internalizationPercent',
		className: 'bg-yellow pl-3 inventory-text',
		Cell: props => `${formatWithSeparatorAndDecimals(props.value * 100)} %`,
		minWidth: 150,
	},
	legalEntity: {Header: tableHeaderWithTitle('Legal Entity'), accessor: 'legalEntity', minWidth: 120},
	legalEntityId: {
		Header: tableHeaderWithTitle('Legal Entity Id'),
		accessor: 'legalEntity.legalEntityExternalId',
		minWidth: 150,
	},
	legalEntityIdv1: {
		Header: tableHeaderWithTitle('Legal Entity Id'),
		accessor: 'legalEntityExternalId',
	},
	loan: {
		Header: tableHeaderWithTitle('Loan'),
		accessor: 'loan',
		minWidth: 120,
		...commaNoDecimal,
	},
	loanContributionAmount: {
		Header: tableHeaderWithTitle('Loan Contribution'),
		accessor: 'loanContributionAmount',
		minWidth: 120,
		...commaNoDecimal,
	},
	loanFee: {
		Header: tableHeaderWithTitle('Loan Fee'),
		accessor: 'loanFee',
		...commaTwoDecimal,
	},
	loanVsCash: (totalLoanVsCash: number) => {
		return {
			Header: tableHeaderWithTitle('Loan vs Cash'),
			headerClassName: 'bg-blue border-left inventory-text pl-3',
			accessor: 'loanVsCash',
			className: 'bg-blue border-left inventory-text pl-3',
			minWidth: 150,
			Footer: (
				<div className='totalTxt d-flex align-items-end flex-column pr-4 pl-3'>
					<p>Total</p>
					<p>{formatWithSeparator(totalLoanVsCash)}</p>
				</div>
			),
		}
	},
	loanVsCashNotional: totalLoanVsCashNotional => {
		return {
			Header: tableHeaderWithTitle('LvC Notional'),
			headerClassName: 'bg-blue pl-3 inventory-text',
			accessor: 'loanVsCashNotional',
			className: 'bg-blue pl-3 inventory-text',
			minWidth: 150,
			Footer: (
				<div className='totalTxt d-flex align-items-end flex-column pr-4 pl-3'>
					<p>Total</p>
					<p>{formatWithSeparator(totalLoanVsCashNotional)}</p>
				</div>
			),
		}
	},
	loanVsNonCash: totalLoanVsNonCash => {
		return {
			Header: tableHeaderWithTitle('Loan vs Non Cash'),
			headerClassName: 'bg-blue pl-3 inventory-text',
			accessor: 'loanVsNonCash',
			className: 'bg-blue pl-3 inventory-text',
			minWidth: 150,
			Footer: (
				<div className='totalTxt d-flex align-items-end flex-column pr-4 pl-3'>
					<p>Total</p>
					<p>{formatWithSeparator(totalLoanVsNonCash)}</p>
				</div>
			),
		}
	},
	loanVsNonCashNotional: (totalLoanVsNonCashNotional: number) => {
		return {
			Header: tableHeaderWithTitle('LvNC Notional'),
			headerClassName: 'bg-blue pl-3 inventory-text',
			accessor: 'loanVsNonCashNotional',
			className: 'bg-blue pl-3 inventory-text',
			minWidth: 150,
			Footer: (
				<div className='totalTxt d-flex align-items-end flex-column pr-4 pl-3'>
					<p>Total</p>
					<p>{formatWithSeparator(totalLoanVsNonCashNotional)}</p>
				</div>
			),
		}
	},
	lvcSaving: (totalLvcSaving: number) => {
		return {
			Header: tableHeaderWithTitle('Loan vs Cash'),
			accessor: 'lvcSaving',
			minWidth: 150,
			headerClassName: 'bg-cream inventory-text pl-3',
			className: 'bg-cream inventory-text pl-3',
			Cell: props => <div className='text-right w-80'>{formatWithSeparator(props.value)}</div>,
			Footer: (
				<div className='totalTxt d-flex align-items-end flex-column pr-4 pl-3'>
					<p>Total</p>
					<p>{formatWithSeparator(totalLvcSaving)}</p>
				</div>
			),
		}
	},
	lvncSaving: (totalLvncSaving: number) => {
		return {
			Header: tableHeaderWithTitle('Loan vs Non Cash'),
			accessor: 'lvncSaving',
			headerClassName: 'bg-cream pl-3 inventory-text',
			minWidth: 150,
			className: 'bg-cream pl-3 inventory-text',
			Cell: props => <div className='text-right w-80'>{formatWithSeparator(props.value)}</div>,
			Footer: (
				<div className='totalTxt d-flex align-items-end flex-column pr-4 pl-3'>
					<p>Total</p>
					<p>{formatWithSeparator(totalLvncSaving)}</p>
				</div>
			),
		}
	},
	match: {
		Header: tableHeaderWithTitle('Optimal Match %', '% of Matched Notional of Total Needs'),
		accessor: 'match',
		headerClassName: tableHeaderClassName(),
		minWidth: 150,
		Cell: props => (
			<div className='overflow-ellipsis w-100 text-right mr-2'>{`${formatWithSeparatorAndDecimals(
				props.value
			)} %`}</div>
		),
	},
	matchedMaturityAmount: {
		Header: tableHeaderWithTitle('Matched Maturity'),
		headerClassName: tableHeaderClassName(),
		accessor: 'matchedMaturityAmount',
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
		minWidth: 120,
	},
	matchPercentage: {
		Header: tableHeaderWithTitle('Match %'),
		accessor: 'matchPercentage',
		headerClassName: tableHeaderClassName(),
		minWidth: 120,
		Cell: props => (
			<div className='overflow-ellipsis w-100 text-right mr-2'>{`${
				Boolean(props.original.myQuantity)
					? formatWithTwoDecimals((props.original.allocatedQuantity * 100) / props.original.myQuantity)
					: 0
			} %`}</div>
		),
	},
	matchedAgainstNeeds: {
		Header: tableHeaderWithTitle('% Matched Vs Total Needs'),
		accessor: 'matchedAgainstNeeds',
		headerClassName: tableHeaderClassName(),
		Cell: props => {
			return <div className='overflow-ellipsis w-100 text-right'>{`${formatWithTwoDecimals(props.value)} %`}</div>
		},
		minWidth: 150,
	},
	matchedAgainstNeedsv1: {
		Header: tableHeaderWithTitle('% Matched Vs Total Needs'),
		accessor: 'matchedAgainstTotalNeeds',
		headerClassName: tableHeaderClassName(),
		Cell: props => {
			return <div className='overflow-ellipsis w-100 text-right'>{`${formatWithTwoDecimals(props.value)} %`}</div>
		},
		minWidth: 150,
	},
	matchedNotional: {
		Header: tableHeaderWithTitle('Matched Notional'),
		accessor: 'matchedNotional',
		headerClassName: tableHeaderClassName(),
		Cell: props => {
			return <div className='overflow-ellipsis w-100 text-right'>{formatWithSeparator(Math.round(props.value))}</div>
		},
		minWidth: 150,
	},
	matchedVsTotalAvailabilityPercentage: {
		Header: tableHeaderWithTitle('% of Matched Notional vs Total Availability Notional'),
		accessor: 'matchedAgainstCptyAvailability',
		headerClassName: tableHeaderClassName(),
		minWidth: 150,
		Cell: props => (
			<div className='overflow-ellipsis w-100 text-right mr-2'>{`${formatWithTwoDecimals(props.value)} %`}</div>
		),
	},
	maturityDate: {
		Header: tableHeaderWithTitle('Maturity Date'),
		accessor: 'maturityDate',
		minWidth: 120,
		Cell: props => {
			return <div className='overflow-ellipsis'>{formatDate(props.value)}</div>
		},
	},
	maturityType: {
		Header: tableHeaderWithTitle('Maturity Type'),
		accessor: 'maturityType',
		minWidth: 120,
	},
	margin: {Header: tableHeaderWithTitle('Margin'), accessor: 'margin', minWidth: 80, ...commaTwoDecimal},
	maxDuration: {
		Header: tableHeaderWithTitle('Max Dur'),
		accessor: 'maxDuration',
		minWidth: 90,
		...noCommaNoDecimal,
	},
	maxNotl: {
		Header: tableHeaderWithTitle('Max Notl'),
		accessor: 'maxNotl',
		minWidth: 120,
		...commaNoDecimal,
	},
	minDuration: {
		Header: tableHeaderWithTitle('Min Dur'),
		accessor: 'minDuration',
		minWidth: 90,
		...noCommaNoDecimal,
	},
	moodyRating: {
		Header: tableHeaderWithTitle('Moody Rating'),
		accessor: 'moodyRating',
		minWidth: 120,
	},
	name: {Header: tableHeaderWithTitle('Name'), accessor: 'longName', minWidth: 80},
	netExposure: {
		Header: tableHeaderWithTitle('Net Exposure'),
		headerClassName: tableHeaderClassName(),
		accessor: 'netExposure',
		minWidth: 120,
		Cell: props => {
			const modifiedValue = formatWithSeparator(props.value)
			let customClass = Number(props.value) > 0 ? 'text-success' : 'text-danger'
			return <div className={`overflow-ellipsis w-100 text-right mr-2 ${customClass}`}>{modifiedValue}</div>
		},
	},
	netLongShortQuantity: {
		Header: tableHeaderWithTitle('Net Long/Short'),
		accessor: 'effectiveNetLongShortQty',
		minWidth: 150,
		headerClassName: 'bg-blue inventory-text pl-3',
		className: 'bg-blue inventory-text pl-3',
		Cell: props => (
			<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
		),
	},
	netLongShortNotional: totalNetLongShortNotional => {
		return {
			Header: tableHeaderWithTitle('Net Long/Short Notional'),
			accessor: 'effectiveNetLongShortNotional',
			minWidth: 150,
			headerClassName: 'bg-blue inventory-text pl-3',
			className: 'bg-blue inventory-text pl-3',
			Cell: props => (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			),
			Footer: (
				<div className='totalTxt d-flex align-items-end flex-column pr-2 pl-3'>
					<p>Total</p>
					<p>{formatWithSeparator(totalNetLongShortNotional)}</p>
				</div>
			),
		}
	},
	netNotional: {
		Header: tableHeaderWithTitle('Net Notional'),
		headerClassName: tableHeaderClassName(),
		accessor: 'netNotional',
		minWidth: 120,
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
		Footer: props => {
			return getTotal('netNotional', props.data)
		},
	},
	notional: {
		Header: tableHeaderWithTitle('Notional'),
		accessor: 'notional',
		minWidth: 120,
		...commaNoDecimal,
	},
	notionalAmount: {
		Header: tableHeaderWithTitle('Notional Amount'),
		accessor: 'notionalAmount',
		minWidth: 120,
		...commaNoDecimal,
	},
	notionalAmountV1: {
		Header: tableHeaderWithTitle('Notional Amount'),
		accessor: 'notionalAmount',
		Cell: props => {
			return <div className='overflow-ellipsis w-100 text-right'>{formatWithSeparator(Math.round(props.value))}</div>
		},
		minWidth: 120,
		Footer: props => {
			return getTotal('notionalAmount', props.data)
		},
	},
	notionalAmountV2: {
		Header: tableHeaderWithTitle('Notional Amount'),
		accessor: 'notional.amount',
		Cell: props => {
			return <div className='overflow-ellipsis w-100 text-right'>{formatWithSeparator(Math.round(props.value))}</div>
		},
		minWidth: 120,
		Footer: props => {
			return getTotal('notional.amount', props.data)
		},
	},
	notionalCurrency: {
		Header: tableHeaderWithTitle('Notional Currency'),
		accessor: 'notionalCurrency',
		Cell: props => {
			return <div className='overflow-ellipsis w-100 text-center mr-2'>{props.value}</div>
		},
		minWidth: 150,
	},
	notionalCurrencyV2: {
		Header: tableHeaderWithTitle('Notional Currency'),
		accessor: 'notional.currency',
		Cell: props => {
			return <div className='overflow-ellipsis w-100 text-center mr-2'>{props.value}</div>
		},
		minWidth: 150,
	},
	notl: {
		Header: tableHeaderWithTitle('Cur Notl'),
		accessor: 'notl',
		minWidth: 120,
		...commaNoDecimal,
	},
	needsStatus: {
		Header: tableHeaderWithTitle('Status'),
		accessor: 'status',
		minWidth: 80,
		Cell: props => {
			const textColor =
				props.value === 'ACCEPTED' ? 'text-success' : props.value === 'REJECTED' ? 'text-danger' : 'text-warning'
			return <div className={`w-100 mr-2 ${textColor}`}>{props.value}</div>
		},
	},
	needsQuantity: {
		Header: tableHeaderWithTitle('My Needs Qty'),
		accessor: 'needsQuantity',
		minWidth: 150,
		headerClassName: tableHeaderClassName(),
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
	},
	needsQuantityv1: {
		Header: tableHeaderWithTitle('Quantity'),
		accessor: 'needQuantity',
		minWidth: 150,
		headerClassName: tableHeaderClassName(),
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
	},
	needsQuantityV2: {
		Header: tableHeaderWithTitle('Needs Quantity'),
		accessor: 'needQuantity',
		minWidth: 150,
		headerClassName: tableHeaderClassName(),
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
	},
	needsNotional: {
		Header: tableHeaderWithTitle('My Needs Notional'),
		accessor: 'notional',
		minWidth: 150,
		headerClassName: tableHeaderClassName(),
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>
					{formatWithSeparator(Math.round(props.original.myQuantity * props.original.price))}
				</div>
			)
		},
		Footer: props => {
			return getNotionalTotal('myQuantity', props.data)
		},
	},
	needsNotionalv1: {
		Header: tableHeaderWithTitle('My Needs Notional'),
		accessor: 'notional',
		minWidth: 150,
		headerClassName: tableHeaderClassName(),
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>
					{formatWithSeparator(Math.round(props.original.needQuantity * props.original.securityPrice.amount))}
				</div>
			)
		},
		Footer: props => {
			const data = _.cloneDeep(props.data).map(item => {
				if (item && item['securityPrice.amount']) {
					item.price = item['securityPrice.amount']
				}
				return item
			})
			return getNotionalTotal('needQuantity', data)
		},
	},
	needsContributionAmount: {
		Header: tableHeaderWithTitle('Needs Notional'),
		accessor: 'needsContributionAmount',
		minWidth: 120,
		...commaNoDecimal,
	},
	offer: {
		Header: tableHeaderWithTitle('Offer'),
		accessor: 'offer',
		minWidth: 80,
		Cell: props => {
			const value = FormatUtil.text.toLowerCase(props.value)
			return FormatUtil.text.toUpperCase(value.charAt(0)).concat(value.slice(1, value.length))
		},
	},
	optimalMatchNotional: {
		Header: tableHeaderWithTitle('Optimal Match Notional'),
		accessor: 'optimalMatchNotional',
		minWidth: 180,
		headerClassName: tableHeaderClassName(),
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
	},
	optTrdDur: {
		Header: tableHeaderWithTitle('Trd Dur'),
		accessor: 'optTrdDur',
		width: 90,
		...noCommaNoDecimal,
	},
	optTrdNotl: {
		Header: tableHeaderWithTitle('Trd Notl'),
		accessor: 'optTrdNotl',
		width: 120,
		...commaNoDecimal,
	},
	optTrdYield: {
		Header: tableHeaderWithTitle('Trd Yield'),
		accessor: 'optTrdYield',
		width: 90,
		...noCommaTwoDecimalPercent,
	},
	orderCreatedBy: {
		Header: tableHeaderWithTitle('Created By'),
		accessor: 'createdByEmail',
		minWidth: 180,
	},
	orderStatus: {
		Header: tableHeaderWithTitle('Order Status'),
		accessor: 'context.displayStatus',
		Cell: props => {
			return showBidStatus(props.value)
		},
		minWidth: 180,
	},
	originalCashValueAmount: {
		Header: tableHeaderWithTitle('Cash Amount'),
		headerClassName: tableHeaderClassName(),
		accessor: 'originalCashValueAmount',
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
		minWidth: 150,
		Footer: props => {
			return getTotal('originalCashValueAmount', props.data)
		},
	},
	originalCollateralValueAmount: {
		Header: tableHeaderWithTitle('Collateral Amount'),
		headerClassName: tableHeaderClassName(),
		accessor: 'originalCollateralValueAmount',
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
		minWidth: 150,
		Footer: props => {
			return getTotal('originalCollateralValueAmount', props.data)
		},
	},
	originalCollateralValueAmountV2: {
		Header: tableHeaderWithTitle('Collateral Amount'),
		headerClassName: tableHeaderClassName(),
		accessor: 'originalCollateralValue.amount',
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
		minWidth: 150,
		Footer: props => {
			return getTotal('originalCollateralValueAmount', props.data)
		},
	},
	originalNotional: {
		Header: tableHeaderWithTitle('Notional Amount'),
		accessor: 'originalNotional',
		Cell: props => {
			return <div className='overflow-ellipsis w-100 text-right'>{formatWithSeparator(Math.round(props.value))}</div>
		},
		minWidth: 120,
		Footer: props => {
			return getTotal('originalNotional', props.data)
		},
	},
	originalQuantity: {
		Header: tableHeaderWithTitle('Quantity'),
		accessor: 'originalQuantity',
		minWidth: 100,
		...commaNoDecimal,
	},
	originalQuantityv1: {
		Header: tableHeaderWithTitle('Original Quantity'),
		accessor: 'original',
		minWidth: 150,
		headerClassName: tableHeaderClassName(),
		...commaNoDecimal,
	},
	originalTradeType: {
		Header: tableHeaderWithTitle('Original Trade Type'),
		accessor: 'originalTradeType',
		minWidth: 120,
	},
	partial: {
		Header: tableHeaderWithTitle('Activity Type'),
		accessor: 'partial',
		Cell: props => {
			if (props.value === true) return <div className='text-text-rajah'>Partial</div>
			if (props.value === false) return <div className='text-primary'>Full</div>
			return props.value
		},
	},
	permission: {
		Header: tableHeaderWithTitle('Permission'),
		accessor: 'privileges',
		Cell: props => {
			return (
				<OverlayTrigger
					key={props.original.userId}
					placement='auto'
					overlay={
						<Tooltip id={props.original.userId}>
							<div>
								{Array.isArray(props.value) &&
									props.value.map(item => {
										return <div key={item.code}>{item && item.displayName}</div>
									})}
							</div>
						</Tooltip>
					}
				>
					<span className='overflow-ellipsis'>
						{(Array.isArray(props.value) && props.value.map(item => item.displayName).join(', ')) || ''}
					</span>
				</OverlayTrigger>
			)
		},
	},
	pendingIn: {
		Header: tableHeaderWithTitle('Pending In'),
		headerClassName: tableHeaderClassName(),
		accessor: 'pendingIn',
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
		minWidth: 120,
	},
	pendingInNotional: {
		Header: tableHeaderWithTitle('Pending In Notional'),
		headerClassName: tableHeaderClassName(),
		accessor: 'pendingInNotional',
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
		minWidth: 150,
	},
	pendingOut: {
		Header: tableHeaderWithTitle('Pending Out'),
		headerClassName: tableHeaderClassName(),
		accessor: 'pendingOut',
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
		minWidth: 120,
	},
	pendingOutNotional: {
		Header: tableHeaderWithTitle('Pending Out Notional'),
		headerClassName: tableHeaderClassName(),
		accessor: 'pendingOutNotional',
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
		minWidth: 150,
	},
	pnl: {
		Header: tableHeaderWithTitle('Pnl'),
		accessor: 'pnl',
		minWidth: 100,
		...commaNoDecimal,
	},
	platformTradeId: {
		Header: tableHeaderWithTitle('Trade ID'),
		accessor: 'platformTradeId',
		minWidth: 60,
		Cell: props => {
			return props.value ? props.value : 'N/A'
		},
	},
	portfolioEntryType: {
		Header: tableHeaderWithTitle('Type'),
		accessor: 'portfolioEntryType',
		Cell: props => {
			if (props.value === 'Intra Day') {
				const tooltipText = `Created Time: ${formatWithDateAndTime(props.original.createdDate)}
Created By: ${props.original.createdBy}`
				return (
					<div title={tooltipText} className='text-orange'>
						{props.value}
					</div>
				)
			}
			return <div className='text-text-rajah'>{props.value}</div>
		},
		minWidth: 80,
	},
	potentialExposure: {
		Header: tableHeaderWithTitle('Potential Exposure'),
		headerClassName: tableHeaderClassName(),
		accessor: 'potentialExposure',
		minWidth: 120,
		Cell: props => {
			const modifiedValue = formatWithSeparator(props.value)
			let customClass = Number(props.value) > 0 ? 'text-success' : 'text-danger'
			return <div className={`overflow-ellipsis w-100 text-right mr-2 ${customClass}`}>{modifiedValue}</div>
		},
	},
	potentialMatch: {
		Header: tableHeaderWithTitle('Potential match'),
		accessor: 'potentialMatch',
		headerClassName: tableHeaderClassName(),
		minWidth: 120,
		Cell: props => <div className='overflow-ellipsis w-100 text-right mr-2'>{`${props.value} %`}</div>,
	},
	price: {
		Header: tableHeaderWithTitle('Price'),
		headerClassName: tableHeaderClassName(),
		accessor: 'price',
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparatorAndDecimals(props.value)}</div>
			)
		},
		minWidth: 80,
	},
	pricev1: {
		Header: tableHeaderWithTitle('Price'),
		headerClassName: tableHeaderClassName(),
		accessor: 'securityPrice.amount',
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparatorAndDecimals(props.value)}</div>
			)
		},
		minWidth: 80,
	},
	priceAmount: {
		Header: tableHeaderWithTitle('Price Amount'),
		headerClassName: tableHeaderClassName(),
		accessor: 'priceAmount',
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparatorAndDecimals(props.value)}</div>
			)
		},
		minWidth: 120,
	},
	priceAmountV2: {
		Header: tableHeaderWithTitle('Price Amount'),
		headerClassName: tableHeaderClassName(),
		accessor: 'price.amount',
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparatorAndDecimals(props.value)}</div>
			)
		},
		minWidth: 120,
	},
	priceCurrency: {
		Header: tableHeaderWithTitle('Price Currency'),
		accessor: 'priceCurrency',
		Cell: props => {
			return <div className='overflow-ellipsis w-100 text-center mr-2'>{props.value}</div>
		},
		minWidth: 120,
	},
	priceCurrencyV2: {
		Header: tableHeaderWithTitle('Price Currency'),
		accessor: 'price.currency',
		Cell: props => {
			return <div className='overflow-ellipsis w-100 text-center mr-2'>{props.value}</div>
		},
		minWidth: 120,
	},
	priceMultiplier: {
		Header: tableHeaderWithTitle('Price Multiplier'),
		accessor: 'priceMultiplier',
		headerClassName: tableHeaderClassName(),
		Cell: props => {
			const modifiedValue = formatWithTwoDecimals(props.value === null ? 1 : props.value)
			return <div className='overflow-ellipsis w-100 text-right mr-2'>{modifiedValue}</div>
		},
	},
	poolFactor: {
		Header: tableHeaderWithTitle('Pool Factor'),
		accessor: 'poolFactor',
		...commaTwoDecimal,
	},
	product: {
		Header: tableHeaderWithTitle('Product'),
		accessor: 'product',
		minWidth: 80,
	},
	quantity: {
		Header: tableHeaderWithTitle('Quantity'),
		accessor: 'quantity',
		minWidth: 100,
		...commaNoDecimal,
	},
	rank: {
		Header: tableHeaderWithTitle('Client Ranking'),
		accessor: 'rank',
		minWidth: 120,
		Cell: props => (props.value ? props.value : 'N/A'),
	},
	rate: {
		Header: tableHeaderWithTitle('Rate'),
		accessor: 'rate',
		minWidth: 80,
		...noCommaTwoDecimal,
	},
	rating: {
		Header: tableHeaderWithTitle('Rating'),
		accessor: 'minimumRating',
		minWidth: 80,
	},
	rebate: {
		Header: tableHeaderWithTitle('Rebate'),
		accessor: 'rebate',
		...noCommaTwoDecimal,
	},
	rebateRate: {
		Header: tableHeaderWithTitle('Rebate Rate'),
		headerClassName: tableHeaderClassName(),
		accessor: 'rebateRate',
		minWidth: 120,
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparatorAndDecimals(props.value)}</div>
			)
		},
	},
	recallReturnBy: {
		Header: tableHeaderWithTitle('Recall/Return By'),
		accessor: 'createdBy',
		minWidth: 140,
	},
	recallReturnTimeStamp: {
		Header: tableHeaderWithTitle('Recall/Return Time'),
		accessor: 'createdDate',
		Cell: props => {
			return <div className='overflow-ellipsis'>{formatWithDateAndTime(props.value)}</div>
		},
		minWidth: 140,
	},
	recallReturnNotional: {
		Header: tableHeaderWithTitle('Recall/Return Notional'),
		accessor: 'recallReturnNotional',
		headerClassName: tableHeaderClassName(),
		Cell: props => {
			return <div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(props.value)}</div>
		},
		minWidth: 160,
		Footer: props => {
			return getTotal('recallReturnNotional', props.data)
		},
	},
	recallInefficiencyContributionAmount: {
		Header: tableHeaderWithTitle('Recall Contribution'),
		headerClassName: tableHeaderClassName(),
		accessor: 'recallInefficiencyContributionAmount',
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right pr-15'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
		minWidth: 150,
	},
	recallInefficiencyContributionAmountV1: {
		Header: tableHeaderWithTitle('Recall Contribution'),
		headerClassName: tableHeaderClassName(),
		accessor: 'recallInefficiencyContributionAmount',
		Cell: props => {
			return <div className='overflow-ellipsis w-100 text-right'>{formatWithSeparator(Math.round(props.value))}</div>
		},
		minWidth: 150,
		Footer: props => {
			return getTotal('recallInefficiencyContributionAmount', props.data)
		},
	},
	recallInefficiencyContributionAmountV2: {
		Header: tableHeaderWithTitle('Recall Contribution'),
		headerClassName: tableHeaderClassName(),
		accessor: 'recallInefficiencyContribution.amount',
		Cell: props => {
			return <div className='overflow-ellipsis w-100 text-right'>{formatWithSeparator(Math.round(props.value))}</div>
		},
		minWidth: 150,
		Footer: props => {
			return getTotal('recallInefficiencyContribution.amount', props.data)
		},
	},
	recallReturnAction: {
		Header: tableHeaderWithTitle('Action'),
		accessor: 'actions',
		Cell: props => {
			return ArrayUtil.getLength(props.value) > 0 ? props.value[0] : ''
		},
	},
	recallReturnType: {
		Header: tableHeaderWithTitle('Type'),
		accessor: 'recallReturnType',
	},
	referenceRate: {
		Header: tableHeaderWithTitle('Reference Rate'),
		accessor: 'referenceRate',
		minWidth: 120,
	},
	isRegNetting: {
		Header: tableHeaderWithTitle('RegNetting'),
		accessor: 'isRegNetting',
		Cell: props => {
			return <div>{Boolean(props.value) ? 'YES' : 'NO'}</div>
		},
		minWidth: 60,
	},
	repo: {
		Header: tableHeaderWithTitle('Repo'),
		accessor: 'repo',
		minWidth: 120,
		...commaNoDecimal,
	},
	repoAndReverse: {
		Header: tableHeaderWithTitle('Repo/Reverse'),
		accessor: 'repoAndReverse',
		minWidth: 100,
		Cell: props => {
			const isReverse = FormatUtil.text.toLowerCase(props.value) === 'reverse'
			const className = `badge badge-pill text-white ${isReverse ? 'bg-rajah' : 'bg-flamingo'}`
			return <span className={className}>{props.value}</span>
		},
	},
	repoInterest: {
		Header: tableHeaderWithTitle('Repo Interest'),
		accessor: 'repoInterest',
		Cell: props => {
			return props && props.value && formatWithSeparatorAndDecimals(props.value)
		},
	},
	repoSblType: {
		Header: tableHeaderWithTitle('Type'),
		accessor: 'repoSblType',
	},
	returnInefficiencyContributionAmount: {
		Header: tableHeaderWithTitle('Return Contribution'),
		headerClassName: tableHeaderClassName(),
		accessor: 'returnInefficiencyContributionAmount',
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right pr-15'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
		minWidth: 150,
	},
	returnInefficiencyContributionAmountV1: {
		Header: tableHeaderWithTitle('Return Contribution'),
		headerClassName: tableHeaderClassName(),
		accessor: 'returnInefficiencyContributionAmount',
		Cell: props => {
			return <div className='overflow-ellipsis w-100 text-right'>{formatWithSeparator(Math.round(props.value))}</div>
		},
		minWidth: 150,
		Footer: props => {
			return getTotal('returnInefficiencyContributionAmount', props.data)
		},
	},
	returnInefficiencyContributionAmountV2: {
		Header: tableHeaderWithTitle('Return Contribution'),
		headerClassName: tableHeaderClassName(),
		accessor: 'returnInefficiencyContribution.amount',
		Cell: props => {
			return <div className='overflow-ellipsis w-100 text-right'>{formatWithSeparator(Math.round(props.value))}</div>
		},
		minWidth: 150,
		Footer: props => {
			return getTotal('returnInefficiencyContribution.amount', props.data)
		},
	},
	ric: {Header: tableHeaderWithTitle('RIC'), accessor: 'ric', minWidth: 80},
	reverse: {
		Header: tableHeaderWithTitle('Reverse'),
		accessor: 'reverse',
		minWidth: 120,
		...commaNoDecimal,
	},
	schedule: {Header: tableHeaderWithTitle('Schedule'), accessor: 'schedule', minWidth: 120},
	scheduleDescription: {
		Header: tableHeaderWithTitle('Schedule Description'),
		accessor: 'scheduleDescription',
		width: 120,
	},
	securityDescription: {
		Header: tableHeaderWithTitle('Security Description'),
		accessor: 'securityDescription',
		minWidth: 120,
	},
	sector: {Header: tableHeaderWithTitle('Sector'), accessor: 'sector', minWidth: 80},
	securityId: {
		Header: tableHeaderWithTitle('Security Id'),
		accessor: 'securityId',
		Cell: props => {
			const data = {
				isEncrpted: true,
				selectedGroupOne: 'securityId',
				selectedGroupOneItem: props.value,
			}
			return (
				<div className='d-flex w-100 text-link'>
					<div title={props.value} className='w-80 float-left overflow-ellipsis'>
						<span onClick={() => extendStorageToPopup(`/abc-portfolio?${encryptURL(data)}`)}>{props.value}</span>
					</div>
					{props.row && props.row.securityId && (
						<div className='float-right'>
							<span
								className='float-right'
								onClick={() => extendStorageToPopup(`${paths.abcmResult}?securityId=${props.row.securityId}`)}
							>
								<IconChangeView />
							</span>
						</div>
					)}
				</div>
			)
		},
		minWidth: 150,
	},
	securityIdV2: {
		Header: tableHeaderWithTitle('Security Id'),
		accessor: 'Security Id',
		Cell: props => {
			return (
				<div className='d-flex w-100 text-link'>
					<div title={props.value} className='w-80 float-left overflow-ellipsis'>
						<span onClick={() => extendStorageToPopup(`/abc-portfolio?filter=securityId&filterValue=${props.value}`)}>
							{props.value}
						</span>
					</div>
					{props.row && props.row['Security Id'] && (
						<div className='float-right'>
							<span
								className='float-right'
								onClick={() => extendStorageToPopup(`${paths.abcmResult}?securityId=${props.row['Security Id']}`)}
							>
								<IconChangeView />
							</span>
						</div>
					)}
				</div>
			)
		},
		minWidth: 150,
	},
	securityIdType: {
		Header: tableHeaderWithTitle('Security Id Type'),
		accessor: 'securityIdType',
	},
	settlementDate: {
		Header: tableHeaderWithTitle('Settlement Date'),
		accessor: 'settlementDate',
		Cell: props => {
			return <div className='overflow-ellipsis'>{formatDate(props.value)}</div>
		},
		minWidth: 150,
	},
	shortFallQuantity: {
		Header: tableHeaderWithTitle('Shortfall Quantity'),
		accessor: 'shortFallQuantity',
		Cell: props => <div className='overflow-ellipsis text-right w-100'>{formatWithSeparator(props.value)}</div>,
		minWidth: 150,
	},
	shortName: {Header: tableHeaderWithTitle('Short Name'), accessor: 'shortName'},
	snpRating: {
		Header: tableHeaderWithTitle('S&P Rating'),
		accessor: 'snpRating',
		minWidth: 120,
	},
	sodAvailableQuantity: {
		Header: tableHeaderWithTitle('SOD Available Quantity'),
		accessor: 'sodAvailableQuantity',
		minWidth: 180,
		headerClassName: tableHeaderClassName(),
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
	},
	sourceSystem: {
		Header: tableHeaderWithTitle('Source System'),
		accessor: 'sourceSystem',
		minWidth: 150,
	},
	sourceSystemTradeId: {
		Header: tableHeaderWithTitle('Source System Trade Id'),
		accessor: 'sourceSystemTradeId',
		minWidth: 150,
	},
	spread: {
		Header: tableHeaderWithTitle('Spread'),
		headerClassName: tableHeaderClassName(),
		accessor: 'spread',
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparatorAndDecimals(props.value)}</div>
			)
		},
		minWidth: 80,
	},
	startDate: {
		Header: tableHeaderWithTitle('Start Date'),
		accessor: 'startDate',
		Cell: props => {
			return <div className='overflow-ellipsis'>{formatDate(props.value)}</div>
		},
		minWidth: 120,
	},
	status: {
		Header: tableHeaderWithTitle('Status'),
		Cell: props => {
			const status = props.original && props.original.state ? props.original.state.replace('_', ' ') : ''
			let column = null
			if (props.original.state === 'NO_ACTIVITY') {
				column = (
					<div className='overflow-ellipsis font-weight-600 text-primary'>
						<NoActivity /> {FormatUtil.text.toTitleCase(status)}
					</div>
				)
			} else {
				column = (
					<div className='overflow-ellipsis font-weight-600 text-danger'>
						<InProgress /> {FormatUtil.text.toTitleCase(status)}
					</div>
				)
			}
			return column
		},
		minWidth: 120,
	},
	statusv1: {
		Header: tableHeaderWithTitle('Status'),
		accessor: 'enabled',
		Cell: props => {
			return props.value ? 'Active' : 'Inactive'
		},
	},
	statusv2: {
		Header: tableHeaderWithTitle('Status'),
		accessor: 'isActive',
		Cell: props => {
			return props.value ? 'Active' : 'Inactive'
		},
	},
	startCash: {
		Header: tableHeaderWithTitle('Start Cash'),
		accessor: 'startCash',
		Cell: props => {
			return props && props.value && formatWithSeparator(props.value.amount)
		},
	},
	strategy: {
		Header: tableHeaderWithTitle('Strategy'),
		accessor: 'strategy',
		minWidth: 80,
		Cell: props => {
			return <div>{props.value}</div>
		},
	},
	swapBasket: {
		Header: tableHeaderWithTitle('Swap Basket'),
		headerClassName: 'pl-3',
		className: 'pl-3',
		accessor: 'swapBasket',
		Cell: props => <div className='text-right w-80'>{formatWithSeparator(props.value)}</div>,
		minWidth: 150,
	},
	swapNotional: (totalSwapNotional: number) => {
		return {
			Header: tableHeaderWithTitle('Swap Notional'),
			accessor: 'swapNotional',
			headerClassName: 'pl-3',
			minWidth: 150,
			className: 'pl-3',
			Cell: props => <div className='text-right w-80'>{formatWithSeparator(props.value)}</div>,
			Footer: (
				<div className='totalTxt d-flex align-items-end flex-column pr-4'>
					<p>Total</p>
					<p>{formatWithSeparator(totalSwapNotional)}</p>
				</div>
			),
		}
	},
	targetNotional: {
		Header: tableHeaderWithTitle('Target Notional'),
		headerClassName: tableHeaderClassName(),
		accessor: 'targetNotionalAmount',
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
		minWidth: 150,
	},
	termDate: {
		Header: tableHeaderWithTitle('Term Date'),
		accessor: 'termDate',
		Cell: props => {
			return <div className='overflow-ellipsis'>{formatDate(props.value)}</div>
		},
		minWidth: 120,
	},
	terminationDate: {
		Header: tableHeaderWithTitle('Termination Date'),
		accessor: 'terminationDate',
		Cell: props => {
			return <div className='overflow-ellipsis'>{formatDate(props.value)}</div>
		},
		minWidth: 150,
	},
	[customColumnField.tableTitle]: {
		Header: tableHeaderWithTitle(''),
		accessor: customColumnField.tableTitle,
		Cell: props => {
			return <div className='font-weight-600'>{props.value}</div>
		},
		minWidth: 120,
	},
	ticker: {Header: tableHeaderWithTitle('Ticker'), accessor: 'ticker'},
	timeQueued: {
		Header: tableHeaderWithTitle('Time Queued'),
		accessor: 'createdDate',
		Cell: props => <div>{formatResponseTime(props.value)}</div>,
		minWidth: 120,
	},
	totalAvailableNotional: {
		Header: tableHeaderWithTitle('Total Available Notional'),
		accessor: 'notional',
		minWidth: 180,
		...commaNoDecimal,
	},
	totalAvailableNotionalv1: {
		Header: tableHeaderWithTitle('Total Available Notional'),
		accessor: 'totalAvailableNotional',
		minWidth: 180,
		...commaNoDecimal,
	},
	totalSblNotional: {
		Header: tableHeaderWithTitle('Total Notional'),
		headerClassName: tableHeaderClassName(),
		accessor: 'totalSblNotional',
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
		minWidth: 140,
	},
	totalNotionalv1: {
		Header: tableHeaderWithTitle('Total Notional'),
		headerClassName: tableHeaderClassName(),
		accessor: 'notionalAmount',
		Cell: props => {
			return (
				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(props.value))}</div>
			)
		},
		minWidth: 120,
		Footer: props => {
			return getTotal('notionalAmount', props.data)
		},
	},
	totalSaving: (totalColumnSaving: number) => {
		return {
			Header: tableHeaderWithTitle('Total'),
			accessor: 'totalSaving',
			headerClassName: 'bg-cream pl-3 inventory-text',
			minWidth: 150,
			className: 'bg-cream pl-3 inventory-text',
			Cell: props => <div className='text-right w-80'>{formatWithSeparator(props.value)}</div>,
			Footer: (
				<div className='totalTxt d-flex align-items-end flex-column pr-4 mr-3'>
					<p>Total</p>
					<p>{formatWithSeparator(totalColumnSaving)}</p>
				</div>
			),
		}
	},
	tradeDate: {
		Header: tableHeaderWithTitle('Trade Date'),
		accessor: 'tradeDate',
		minWidth: 150,
		Cell: props => {
			return <div className='overflow-ellipsis'>{formatDate(props.value)}</div>
		},
	},
	tradeId: {
		Header: tableHeaderWithTitle('Trade ID'),
		accessor: 'tradeId',
		minWidth: 80,
	},
	tradeType: {
		Header: tableHeaderWithTitle('Trade Type'),
		accessor: 'tradeType',
		minWidth: 120,
	},
	type: {Header: tableHeaderWithTitle('Type'), accessor: 'type', minWidth: 120},
	unInternalizedTotal: (totalUninternalization: number) => {
		return {
			Header: tableHeaderWithTitle('Uninternalized Total'),
			headerClassName: 'bg-yellow pl-3 inventory-text',
			minWidth: 150,
			accessor: 'uninternalizedTotal',
			className: 'bg-yellow pl-3 inventory-text',
			Cell: props => <div className='text-right w-80'>{formatWithSeparator(props.value)}</div>,
			Footer: (
				<div className='totalTxt d-flex align-items-end flex-column pr-4'>
					<p>Total</p>
					<p>{formatWithSeparator(totalUninternalization)}</p>
				</div>
			),
		}
	},
	unInternalizedNotional: (totalUninternalizedNotional: number) => {
		return {
			Header: tableHeaderWithTitle('Uninternalized Notional'),
			headerClassName: 'bg-yellow pl-3 inventory-text',
			minWidth: 150,
			accessor: 'uninternalizedNotional',
			className: 'bg-yellow pl-3 inventory-text',
			Cell: props => <div className='text-right w-80'>{formatWithSeparator(props.value)}</div>,
			Footer: (
				<div className='totalTxt d-flex align-items-end flex-column pr-4'>
					<p>Total</p>
					<p>{formatWithSeparator(totalUninternalizedNotional)}</p>
				</div>
			),
		}
	},
	uninternalizedPercent: {
		Header: tableHeaderWithTitle('Uninternalized Percent'),
		headerClassName: 'bg-yellow pl-3 inventory-text',
		accessor: 'uninternalizedPercent',
		className: 'bg-yellow pl-3 inventory-text',
		Cell: props => `${formatWithSeparatorAndDecimals(props.value * 100)} %`,
		minWidth: 150,
	},
	uninternalizedSaving: (totalUninternalizedSaving: number) => {
		return {
			Header: tableHeaderWithTitle('Uninternalized'),
			accessor: 'uninternalizedSaving',
			headerClassName: 'bg-cream pl-3 inventory-text',
			minWidth: 150,
			className: 'bg-cream pl-3 inventory-text',
			Cell: props => <div className='text-right w-80'>{formatWithSeparator(props.value)}</div>,
			Footer: (
				<div className='totalTxt d-flex align-items-end flex-column pr-4'>
					<p>Total</p>
					<p>{formatWithSeparator(totalUninternalizedSaving)}</p>
				</div>
			),
		}
	},
	updated: {
		Header: tableHeaderWithTitle('Updated'),
		accessor: 'createdDate',
		Cell: props => {
			if (typeof props.value === 'string') return <div>{formatWithDateAndTime(props.value)}</div>
			return props.value
		},
		className: 'align-self-start',
		minWidth: 150,
	},
	validation: {Header: tableHeaderWithTitle('validation'), accessor: 'way', minWidth: 80},
	way: {
		Header: tableHeaderWithTitle('Way'),
		accessor: 'way',
		minWidth: 80,
	},
	weightPercentage: {
		Header: tableHeaderWithTitle('Weight %'),
		headerClassName: tableHeaderClassName(),
		accessor: 'weightPercentage',
		Cell: props => {
			return <div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithFourDecimals(props.value)}</div>
		},
	},
	weighting: {
		Header: tableHeaderWithTitle('Weighting'),
		id: 'weighting',
		Cell: props => {
			return (
				props.original &&
				props.original.weighting && <div className='overflow-ellipsis'>{props.original.weighting.weight}</div>
			)
		},
		minWidth: 80,
	},
	yield: {
		Header: tableHeaderWithTitle('Cur Yield'),
		accessor: 'yield',
		minWidth: 90,
		...noCommaTwoDecimalPercent,
	},
}
