import {createFetchActions} from '../../../services/createActions'
import {get, post, put} from '../../../services/createAPICall'
import {getConfigBaseUrl} from '../../../services/apiService'
import {getConfigClient} from '../../..'
import {getDeposQuery} from './deposQueries'
import {getCounterpartyId} from '../../../services/authService'

export const GET_DEPOS = createFetchActions('GET_DEPOS')
export const GET_DEPOS_TYPES = createFetchActions('GET_DEPOS_TYPES')
export const SAVE_DEPOS = createFetchActions('SAVE_DEPOS')
export const EDIT_DEPOS = createFetchActions('EDIT_DEPOS')

export const getDepos = () => {
	return function (dispatch) {
		dispatch({type: GET_DEPOS.triggered})
		getConfigClient()
			.query({
				query: getDeposQuery(getCounterpartyId()),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_DEPOS.succeeded,
					response,
				})
			})
	}
}

export const getDepoTypes = () => (dispatch: any) => {
	return dispatch(get(GET_DEPOS_TYPES, `${getConfigBaseUrl()}/v2/config/depos-types`))
}

export const saveDepos = (deposData: any) => (dispatch: any) => {
	return dispatch(post(SAVE_DEPOS, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/depos/`, deposData))
}

export const editDepos = (deposData: any) => (dispatch: any) => {
	return dispatch(
		put(
			EDIT_DEPOS,
			`${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/depos/${deposData.deposId}`,
			deposData
		)
	)
}
