import {createFetchActions} from '../../../services/createActions'
import {post, put} from '../../../services/createAPICall'
import {getConfigBaseUrl} from '../../../services/apiService'
import {getCounterpartyId} from '../../../services/authService'

export const GET_PORTFOLIO = createFetchActions('GET_PORTFOLIO')
export const SAVE_PORTFOLIO = createFetchActions('SAVE_PORTFOLIO')
export const EDIT_PORTFOLIO = createFetchActions('EDIT_PORTFOLIO')
export const CLEAR_PORTFOLIO = createFetchActions('CLEAR_PORTFOLIO')

export const savePortfolios = (data: any) => (dispatch: any) => {
	return dispatch(
		post(SAVE_PORTFOLIO, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/portfolios`, data)
	)
}

export const editPortfolios = (data: any) => (dispatch: any) => {
	return dispatch(
		put(
			EDIT_PORTFOLIO,
			`${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/portfolios/${data.portfolioId}`,
			data
		)
	)
}
