export const SUMMARY_FIELDS = [
	{
		field: 'securityDesc',
		label: 'Security Name',
	},
	{
		field: 'cusip',
		label: 'CUSIP',
	},
	{
		field: 'collateralCcy',
		label: 'Collateral CCY',
	},
	{
		field: 'collateralDate',
		label: 'Collateral Date',
	},

	{
		field: 'ticker',
		label: 'Ticker',
	},
	{
		field: 'sedol',
		label: 'SEDOL',
	},
	{
		field: 'divRate',
		label: 'Dividend Req (%)',
		format: 'rate',
	},
	{
		field: 'borrowerLei',
		label: 'Legal Entity ID',
	},

	{
		field: 'extSettleLoc',
		label: 'Place of Settlement',
	},
	{
		field: 'isin',
		label: 'ISIN',
	},
	{
		field: 'tradeDate',
		label: 'Trade Date',
	},
	{
		field: 'borrowerSubAccount',
		label: 'Account Type',
		format: 'accountType',
	},

	{
		field: 'settleType',
		label: 'Settlement Type',
	},
	{
		field: 'collateralType',
		label: 'Collateral Type',
	},

	{
		field: 'settleDate',
		label: 'Settlement Date',
	},
]

export const TRIGGER_FIELDS_TO_CLEARED = {
	minReqQtyFocus: false,
	maxReqQtyFocus: false,
	qtyIncrementFocus: false,
	requestedQtyFocus: false,
}
