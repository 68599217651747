import {createFetchActions} from '../../../services/createActions'
import {post, put} from '../../../services/createAPICall'
import {getConfigBaseUrl} from '../../../services/apiService'
import {getConfigClient} from '../../..'
import {getMyAccountsQuery} from './myAccountsQueries'
import {getCounterpartyId} from '../../../services/authService'

export const GET_MY_ACCOUNTS = createFetchActions('GET_MY_ACCOUNTS')
export const SAVE_MY_ACCOUNTS = createFetchActions('SAVE_MY_ACCOUNTS')
export const EDIT_MY_ACCOUNTS = createFetchActions('EDIT_MY_ACCOUNTS')
export const CLEAR_ACCOUNTS_DATA = createFetchActions('CLEAR_ACCOUNTS_DATA')

export const getMyAccounts = () => {
	return function (dispatch) {
		dispatch({type: GET_MY_ACCOUNTS.triggered})
		getConfigClient()
			.query({
				query: getMyAccountsQuery,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_MY_ACCOUNTS.succeeded,
					response,
				})
			})
	}
}

export const saveMyAccounts = (data: any) => (dispatch: any) => {
	return dispatch(
		post(SAVE_MY_ACCOUNTS, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/accounts/`, data)
	)
}

export const editMyAccounts = (data: any) => (dispatch: any) => {
	return dispatch(
		put(
			EDIT_MY_ACCOUNTS,
			`${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/accounts/${data.accountId}`,
			data
		)
	)
}

export const clearAccountsData = () => {
	return {
		type: CLEAR_ACCOUNTS_DATA,
	}
}
