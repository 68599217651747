import {GET_RE_RATE_DETAIL} from './ReRateAction'

export interface ReRate {
	loading: boolean
	reRateDetail: any
}

const initialState: ReRate = {
	loading: false,
	reRateDetail: null,
}

export function ReRateReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_RE_RATE_DETAIL.triggered:
			return {...state, loading: true, reRateDetail: null}
		case GET_RE_RATE_DETAIL.succeeded:
			return {...state, loading: false, reRateDetail: action.response}
		case GET_RE_RATE_DETAIL.failed:
			return {...state, loading: false, reRateDetail: {}}
	}
	return state
}
