import {getPMSClient} from '../..'
import {getPMSBaseUrl} from '../../services/apiService'
import {createFetchActions} from '../../services/createActions'
import {post} from '../../services/createAPICall'
import {getAllFilesQuery} from './marginAnalyticsQueries'

export const UPLOAD_MARGIN_FILE = createFetchActions('UPLOAD_MARGIN_FILE')
export const GET_ALL_UPLOADED_FILES = createFetchActions('GET_ALL_UPLOADED_FILES')

export const uploadMarginFile = data => (dispatch: any) => {
	return dispatch(post(UPLOAD_MARGIN_FILE, `${getPMSBaseUrl()}/v1/market-rate/upload`, data))
}

export function getAllFiles() {
	return function (dispatch) {
		dispatch({
			type: GET_ALL_UPLOADED_FILES.triggered,
		})

		getPMSClient()
			.query({
				query: getAllFilesQuery,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_ALL_UPLOADED_FILES.succeeded,
					response,
				})
			})
	}
}
