import {
	GET_BENCHMARK,
	GET_COUNTERPARTY,
	GET_COLLATERAL_BASKET,
	GET_HOLIDAYS,
	GET_BENCHMARKSTT,
	RESET_HOLIDAYS,
} from './OrderEntryModalAction'
import {GET_SCHEDULE, GET_TYPE_AHEAD_RESULT, VALIDATE_SCHEDULE, UPLOAD_COLLATERAL_BASKET} from './OrderEntryModalAction'
import {CUSIP_SCHEDULE} from './OrderEntryModalAction'

export interface OrderEntryModal {
	loading: boolean
	basketUploadedData: any
	benchmarkOption: any[]
	benchmarkOptionSTT: any[]
	counterparty: any[]
	collateralBasket: any[]
	holidays: any[]
	schedule: any[]
	scheduleOfSecurity: any
	scheduleValidated: any
	typeAheadResult: any
}

const initialState: OrderEntryModal = {
	loading: false,
	basketUploadedData: null,
	benchmarkOption: null,
	benchmarkOptionSTT: null,
	counterparty: null,
	collateralBasket: null,
	holidays: null,
	schedule: null,
	scheduleOfSecurity: '',
	scheduleValidated: null,
	typeAheadResult: [],
}

export function OrderEntryModalReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_BENCHMARK.triggered:
			return {...state, benchmarkOption: null}
		case GET_BENCHMARK.succeeded:
			return {...state, benchmarkOption: action.response.data}
		case GET_BENCHMARK.failed:
			return {...state, benchmarkOption: []}

		case GET_BENCHMARKSTT.triggered:
			return {...state, benchmarkOptionSTT: null}
		case GET_BENCHMARKSTT.succeeded:
			return {...state, benchmarkOptionSTT: action.response.data}
		case GET_BENCHMARKSTT.failed:
			return {...state, benchmarkOptionSTT: []}

		case GET_COUNTERPARTY.triggered:
			return {...state, counterparty: null}
		case GET_COUNTERPARTY.succeeded:
			return {...state, counterparty: action.response}
		case GET_COUNTERPARTY.failed:
			return {...state, counterparty: []}

		case GET_HOLIDAYS.triggered:
			return {...state, holidays: null}
		case GET_HOLIDAYS.succeeded:
			return {...state, holidays: action.response && action.response.data && action.response.data.holidays}
		case GET_HOLIDAYS.failed:
			return {...state, holidays: []}

		case GET_COLLATERAL_BASKET.triggered:
			return {...state, collateralBasket: null}
		case GET_COLLATERAL_BASKET.succeeded:
			return {...state, collateralBasket: action.response}
		case GET_COLLATERAL_BASKET.failed:
			return {...state, collateralBasket: []}

		case GET_SCHEDULE.triggered:
			return {...state, schedule: null}
		case GET_SCHEDULE.succeeded:
			return {...state, schedule: action.response.data}
		case GET_SCHEDULE.failed:
			return {...state, schedule: []}

		case VALIDATE_SCHEDULE.triggered:
			return {...state, scheduleValidated: null}
		case VALIDATE_SCHEDULE.succeeded:
			return {...state, scheduleValidated: action.response.data}
		case VALIDATE_SCHEDULE.failed:
			return {...state, scheduleValidated: {}}

		case UPLOAD_COLLATERAL_BASKET.triggered || UPLOAD_COLLATERAL_BASKET.succeeded || UPLOAD_COLLATERAL_BASKET.failed:
			return {...state}

		case GET_TYPE_AHEAD_RESULT.triggered:
			return {...state, typeAheadResult: null}
		case GET_TYPE_AHEAD_RESULT.succeeded:
			return {...state, typeAheadResult: action.response}
		case GET_TYPE_AHEAD_RESULT.failed:
			return {...state, typeAheadResult: []}

		case CUSIP_SCHEDULE.triggered:
			return {...state, scheduleOfSecurity: null}
		case CUSIP_SCHEDULE.succeeded:
			return {...state, scheduleOfSecurity: action.response.data}
		case CUSIP_SCHEDULE.failed:
			return {...state, scheduleOfSecurity: null}

		case RESET_HOLIDAYS:
			return {...state, holidays: null}
	}
	return state
}
