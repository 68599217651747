import gql from 'graphql-tag'
import {getCounterpartyId} from '../../../services/authService'

export const getSubBusinessUnitsQuery = gql`
	query {
		allSubBusinessUnits(tenantId: "${getCounterpartyId()}") {
      id
      subBusinessUnitId
      tenantId
      businessUnitId
      fullName
      shortName
      legalEntityIdentifier
      bbgGlobalIdentifier
      internalCode1
      internalCode2
      internalCode3
      internalCode4
      internalCode5
		}
	}
`
