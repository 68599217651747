import {IBorrower} from '../../AlpNewRequestModal/AlpNewRequestModel'
import {CLEAR_INVENTORY_DATA, FETCH_AVAILABILITY_DETAILS} from './CreateEditAuction/CreateEditAuctionActions'
import {GET_AVAILABILITY_DETAILS, GET_LIVE_AUCTIONS} from './CreateEditAuction/CreateEditAuctionActions'
import {GET_AUCTIONS, GET_STAGE_AUCTIONS} from './CreateEditAuction/CreateEditAuctionActions'
import {InventorySourceLabel} from './CreateEditAuction/CreateEditAuctionConstant'
import {addTimerDelay, massageDiscretionaryInventoryData} from './CreateEditAuction/CreateEditHelper'
import {massagePreApprovedInventoryData} from './CreateEditAuction/CreateEditHelper'
import {FETCH_ALL_AUCTION_BORROWERS, FETCH_ALL_BIDS_FOR_AUCTION} from './InternalMainActions'
import {GET_CLOSED_AUCTIONS, SET_AUCTION_STATUS_ON_TIMER_ENDS} from './InternalMainActions'
import {GET_REJECTED_AUCTIONS, SET_AUCTION_MODE} from './InternalMainActions'
import {updateGridDataOnTimerEnds} from './InternalMainHelper'

export interface AlpAuctionsInternalMain {
	createEditAuction: {
		availabilityReqId: string
		inventoryData: any
		noInventoryData: any
		availabilityResponse: any
		selectedAuctionsResponse: any
		showLoadingTillWsResponse: boolean
		auctionMode: string
	}
	liveAuctionsGridData: any
	stageAuctionsGridData: any
	rejectedAuctionsGridData: any
	closedAuctionsGridData: any
	allBorrowersList: IBorrower[]
	allBidsInAuction: any[]
}

const initialState: AlpAuctionsInternalMain = {
	createEditAuction: {
		availabilityReqId: '',
		inventoryData: [],
		noInventoryData: [],
		availabilityResponse: [],
		selectedAuctionsResponse: {},
		showLoadingTillWsResponse: false,
		auctionMode: null,
	},
	liveAuctionsGridData: null,
	stageAuctionsGridData: null,
	rejectedAuctionsGridData: null,
	closedAuctionsGridData: null,
	allBorrowersList: [],
	allBidsInAuction: [],
}
export function AlpAuctionsInternalMainReducer(state = initialState, action: any) {
	switch (action.type) {
		case FETCH_AVAILABILITY_DETAILS: {
			return {
				...state,
				createEditAuction: {
					availabilityReqId: '',
					inventoryData: [],
					noInventoryData: [],
					availabilityResponse: [],
					selectedAuctionsResponse: {},
					showLoadingTillWsResponse: true,
				},
			}
		}

		case GET_AVAILABILITY_DETAILS.triggered:
			return {
				...state,
				createEditAuction: {
					...state.createEditAuction,
					availabilityReqId: '',
					inventoryData: [],
					noInventoryData: [],
					availabilityResponse: [],
					showLoadingTillWsResponse: true,
				},
			}
		case GET_AVAILABILITY_DETAILS.failed:
			return {
				...state,
				createEditAuction: {
					...state.createEditAuction,
					availabilityReqId: '',
					inventoryData: [],
					noInventoryData: [],
					availabilityResponse: [],
					showLoadingTillWsResponse: false,
				},
			}
		case GET_AVAILABILITY_DETAILS.succeeded:
			const availabilityDetails = action.response && action.response.data && action.response.data
			const availabilityResponse = availabilityDetails && availabilityDetails.availabilityResponse
			const availabilityReqId = action.response && action.response.data && action.response.data.auctionReqId
			const parsedAvailabilityResponse = JSON.parse(availabilityResponse)
			const {inventorySource, status} = parsedAvailabilityResponse

			return {
				...state,
				createEditAuction: {
					...state.createEditAuction,
					availabilityReqId,
					inventoryData:
						status === 'Rejected'
							? []
							: inventorySource === InventorySourceLabel.PreApprovedClients
							? massagePreApprovedInventoryData(parsedAvailabilityResponse, availabilityDetails)
							: massageDiscretionaryInventoryData(parsedAvailabilityResponse, availabilityDetails),
					noInventoryData: status === 'Rejected' ? [{...parsedAvailabilityResponse}] : [],
					availabilityResponse: parsedAvailabilityResponse,
					showLoadingTillWsResponse: false,
				},
			}

		case GET_AUCTIONS.succeeded:
			const selectedAuctionsResponse = JSON.parse(
				action.response && action.response.data && action.response.data.creationResponse
			)
			return {
				...state,
				createEditAuction: {
					...state.createEditAuction,
					selectedAuctionsResponse: {
						...selectedAuctionsResponse,
						responseStatus: action.response.data.responseStatus,
						requestId: action.response.data.requestId,
						ticker: action.response.data.ticker,
						cusip: action.response.data.cusip,
						isin: action.response.data.isin,
						securityId: action.response.data.securityId,
						securityName: action.response.data.securityName,
						securityType: action.response.data.securityType,
						maturityDate: action.response.data.maturityDate,
						coupon: action.response.data.coupon,
						tradeCountryCode: action.response.data.tradeCountryCode,
						createdBy: action.response.data.createdBy,
						modifiedBy: action.response.data.modifiedBy,
						borrowerSelection: action.response.data.borrowerSelection,
						clientName: action.response.data.clientName,
						fundName: action.response.data.fundName,
						termDateSelection: action.response.data.termDateSelection,
						auctionReferenceId: action.response.data.auctionReferenceId,
						timeRemaining: addTimerDelay(
							action.response.data.epochModifiedDate,
							selectedAuctionsResponse.auctionDuration
						),
					},
				},
			}

		case GET_LIVE_AUCTIONS.triggered:
			return {
				...state,
				liveAuctionsGridData: null,
			}
		case GET_LIVE_AUCTIONS.failed:
			return {
				...state,
				liveAuctionsGridData: [],
			}
		case GET_LIVE_AUCTIONS.succeeded:
			let liveAuctionsGridData = action.response && action.response.data
			if (!liveAuctionsGridData || liveAuctionsGridData === 'No Records Found') {
				return {
					...state,
					liveAuctionsGridData: [],
				}
			}
			liveAuctionsGridData = liveAuctionsGridData.flatMap(d => {
				const parsedCreationResponse = JSON.parse(d.creationResponse)
				return {
					...parsedCreationResponse,
					...d,
					status: d.responseStatus,
					timeRemaining: addTimerDelay(d.epochModifiedDate, parsedCreationResponse.auctionDuration),
				}
			})
			return {
				...state,
				liveAuctionsGridData: liveAuctionsGridData,
			}

		case GET_STAGE_AUCTIONS.triggered:
			return {
				...state,
				stageAuctionsGridData: null,
			}
		case GET_STAGE_AUCTIONS.failed:
			return {
				...state,
				stageAuctionsGridData: [],
			}

		case GET_STAGE_AUCTIONS.succeeded:
			let stageAuctionsGridData = action.response && action.response.data
			if (!stageAuctionsGridData || stageAuctionsGridData === 'No Records Found') {
				return {
					...state,
					stageAuctionsGridData: [],
				}
			}
			stageAuctionsGridData = stageAuctionsGridData.flatMap(d => {
				return {
					...JSON.parse(d.creationResponse),
					...d,
					status: d.responseStatus,
				}
			})
			return {
				...state,
				stageAuctionsGridData: stageAuctionsGridData,
			}

		case GET_REJECTED_AUCTIONS.triggered:
			return {
				...state,
				rejectedAuctionsGridData: null,
			}

		case GET_REJECTED_AUCTIONS.failed:
			return {
				...state,
				rejectedAuctionsGridData: [],
			}

		case GET_REJECTED_AUCTIONS.succeeded:
			let rejectedAuctionsGridData = action.response && action.response.data
			if (!rejectedAuctionsGridData || rejectedAuctionsGridData === 'No Records Found') {
				return {
					...state,
					rejectedAuctionsGridData: [],
				}
			}
			rejectedAuctionsGridData = rejectedAuctionsGridData.flatMap(d => {
				return {
					...JSON.parse(d.creationResponse),
					...d,
					status: d.responseStatus,
				}
			})
			return {
				...state,
				rejectedAuctionsGridData: rejectedAuctionsGridData,
			}
		case GET_CLOSED_AUCTIONS.triggered:
			return {
				...state,
				closedAuctionsGridData: null,
			}

		case GET_CLOSED_AUCTIONS.failed:
			return {
				...state,
				closedAuctionsGridData: [],
			}

		case GET_CLOSED_AUCTIONS.succeeded:
			let closedAuctionsGridData = action.response && action.response.data
			if (!closedAuctionsGridData || closedAuctionsGridData === 'No Records Found') {
				return {
					...state,
					closedAuctionsGridData: [],
				}
			}
			closedAuctionsGridData = closedAuctionsGridData.flatMap(d => {
				return {
					...JSON.parse(d.creationResponse),
					...d,
					status: d.responseStatus,
				}
			})
			return {
				...state,
				closedAuctionsGridData: closedAuctionsGridData,
			}

		case CLEAR_INVENTORY_DATA: {
			return {
				...state,
				createEditAuction: {
					...state.createEditAuction,
					availabilityReqId: '',
					inventoryData: [],
					noInventoryData: [],
					availabilityResponse: [],
					selectedAuctionsResponse: {},
					showLoadingTillWsResponse: false,
				},
			}
		}
		case SET_AUCTION_MODE: {
			return {
				...state,
				createEditAuction: {
					...state.createEditAuction,
					auctionMode: action.payload,
				},
			}
		}

		case SET_AUCTION_STATUS_ON_TIMER_ENDS: {
			return {
				...state,
				liveAuctionsGridData: updateGridDataOnTimerEnds(state.liveAuctionsGridData, action.payload),
			}
		}

		case FETCH_ALL_AUCTION_BORROWERS.triggered:
			return {...state, allBorrowersList: []}
		case FETCH_ALL_AUCTION_BORROWERS.failed:
			return {...state, allBorrowersList: []}
		case FETCH_ALL_AUCTION_BORROWERS.succeeded:
			return {
				...state,
				allBorrowersList: action.response && action.response.data,
			}

		case FETCH_ALL_BIDS_FOR_AUCTION.triggered:
			return {...state, allBidsInAuction: []}
		case FETCH_ALL_BIDS_FOR_AUCTION.failed:
			return {...state, allBidsInAuction: []}
		case FETCH_ALL_BIDS_FOR_AUCTION.succeeded:
			const allBidsInAuction = action.response && action.response.data
			if (!allBidsInAuction || allBidsInAuction === 'No Records Found') {
				return {
					...state,
					allBidsInAuction: [],
				}
			}
			return {
				...state,
				allBidsInAuction: allBidsInAuction.map(d => {
					return {
						...d,
						...JSON.parse(d.bidResponse),
						...JSON.parse(d.auctionUpdateResponse),
						termDate: d.termDate,
					}
				}),
			}
	}
	return state
}
