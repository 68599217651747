import gql from 'graphql-tag'

export const portfolioStatus = gql`
	query {
		currentPortfolio {
			isStale
			createdDate
			portfolioDocuments {
				tenantId
				id
				fileName
				status
			}
		}
	}
`
