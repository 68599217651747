import {put} from '../../../../src/services/createAPICall'
import {createFetchActions} from '../../../../src/services/createActions'
import {getPMSBaseUrl} from '../../../../src/services/apiService'

export const GET_OPTIMIZATION_WIDGETS = createFetchActions('GET_OPTIMIZATION_WIDGETS')

export const getCollateralOptimizationWidgetsData = () => (dispatch: any) => {
	const data = {
		params: {
			objective: [],
			strategy: 'Collateral Optimization',
		},
		requestType: 'dataWidgets',
	}

	return dispatch(put(GET_OPTIMIZATION_WIDGETS, `${getPMSBaseUrl()}/v1/decisionMaking`, data))
}
