import React from 'react'
import Lodash from 'lodash'
import memoizeOne from 'memoize-one'
import isDeepEqual from 'lodash.isequal'
import {ArrayUtil, FormatUtil} from 'helper-util'
import {formatWithDateAndTime} from '../../utils/dateUtil'
import {portfolioStatus} from './portfolioManagerConstants'

import {CloseCircle, SuccessIcon, WarningIconSmall} from '../../styles/svg/svgIcons'
import {getUserName} from '../../features/navigationBar/navigationBarHelper'

import {commaTwoDecimal, commaNoDecimal} from '../../common/AgGrid/AgGridHelper'
export const getIncludedIds = (data: any[]) => {
	return [...(data.map(item => item.id) || [])]
}

export const getExcludedDocs = (allDocs: any[] = [], includedIds: string[] = []) => {
	const excludedDocs = allDocs.filter(item => !includedIds.includes(String(item.id)))
	return excludedDocs || []
}

export const formatAllDocs = (allDocs: any[], processedObject: any, currentDoc: {id: string; status: string}) => {
	if (Lodash.isEmpty(processedObject)) {
		return allDocs
	} else {
		const processedObjectId = processedObject.id
		allDocs.forEach(item => {
			if (item.id === processedObjectId) {
				item.status = processedObject.status
				item.errorCount = processedObject.errorCount
				item.completedEnrichmentCount = processedObject.completedEnrichmentCount
				item.warningCount = processedObject.warningCount
			}
			if (currentDoc && item.id === currentDoc.id) {
				item.status = currentDoc.status
			}
		})
		return allDocs
	}
}

export const getUser = item => {
	return (item.audit && item.audit.createdBy) || ''
}

export const getUserInitials = item => {
	const email = item.audit && item.audit.createdBy
	return getUserName({email})
}

export const getUploadedDateTime = item => {
	const dateArray = item.audit && item.audit.createdDate
	const date = new Date(
		Date.UTC(dateArray[0], dateArray[1] - 1, dateArray[2], dateArray[3], dateArray[4], dateArray[5])
	)
	return formatWithDateAndTime(date)
}

export const getTotalErrorCount = data => {
	if (data && data.length) {
		data = data.filter(item => item.status !== 'SUCCESS')
		return data
			.map(item => {
				return item.errorCount
			})
			.reduce((a, b) => a + b, 0)
	}
}

export const checkPortfolio = portfolioUpdatedDateTime => {
	if (portfolioUpdatedDateTime && portfolioUpdatedDateTime.length) {
		const modifiedDate = portfolioUpdatedDateTime[0].audit.modifiedDate

		const pfUpdatedDate = new Date(modifiedDate[0], modifiedDate[1] - 1, modifiedDate[2])

		const todayDate = new Date()
		if (
			todayDate.getDate() === pfUpdatedDate.getDate() &&
			todayDate.getMonth() === pfUpdatedDate.getMonth() &&
			todayDate.getFullYear() === pfUpdatedDate.getFullYear()
		) {
			return true
		}
		return false
	}
}

export const showDragOption = (status: string) => {
	const docStatus = status && FormatUtil.text.toLowerCase(status)
	if (
		docStatus === portfolioStatus.success ||
		docStatus === portfolioStatus.error ||
		docStatus === portfolioStatus.enrichment_pending
	) {
		return false
	} else {
		return true
	}
}

export const getRestrictedStockListDetails = (type: string) => {
	if (type === 'default') {
		return {
			icon: <CloseCircle />,
			label: 'Not Available',
			fileUploaded: false,
			allowFileDownload: false,
		}
	} else if (type === 'stale') {
		return {
			icon: <WarningIconSmall />,
			label: 'Stale/Outdated',
			fileUploaded: true,
			allowFileDownload: false,
		}
	} else if (type === 'latest') {
		return {
			icon: <SuccessIcon />,
			label: 'Available',
			fileUploaded: true,
			allowFileDownload: false,
		}
	}
}

export const getColumnDefsOfPortfoloEtl = memoizeOne(etlData => {
	const colDefs = Lodash.cloneDeep(etlData)
	return (
		!ArrayUtil.isEmpty(colDefs) &&
		colDefs.map(element => {
			if (element.type === 'numericColumn') {
				return element.dataFormat === 'commaTwoDecimal'
					? {...element, ...commaTwoDecimal}
					: {...element, ...commaNoDecimal}
			}
			return element
		})
	)
}, isDeepEqual)
