import React from 'react'
import {Carousel} from 'react-bootstrap'
import {CLIENT_CONSTANTS, Config} from '../../../constants/clientConfig'
import {loginCarousel} from './LoginCarouselConstant'

interface Props {}

interface State {}

export default class LoginCarousel extends React.Component<Props, State> {
	render() {
		return (
			<Carousel className='login-carousel'>
				{loginCarousel.map(carousel => {
					return (
						<Carousel.Item key={carousel.title}>
							<Carousel.Caption>
								<div>
									<div className='m-auto w-270px login-logo-wrapper'>{Config.activeConfig.login.logo}</div>
									{![CLIENT_CONSTANTS.FINOPTSYS, CLIENT_CONSTANTS.DH].includes(Config.activeConfig.id) &&
										!Config.activeConfig.login.fosLogoAtBottom && (
											<>
												<div className='f-14 text-black mt-3'>Powered By</div>
												<h3 className='f-28 text-black'>FinOptSys</h3>
											</>
										)}
									{Config.activeConfig.login.fosLink && (
										<span
											className='site-link f-14 pb-3 mb-2 d-inline-block text-link'
											onClick={() => window.open('https://www.finoptsys.com/')}
										>
											Solutions for the Future &#8226; www.finoptsys.com
										</span>
									)}
								</div>
								<div className='img-wrap'>
									<img src={carousel.icon} alt='data science' />
								</div>
								<div>
									<span className='image-title mb-2 d-inline-block mt-3 font-weight-600'>{carousel.title}</span>
									{Config.activeConfig.login.carouselDescription && (
										<p className='image-description f-18'>{carousel.description}</p>
									)}
									{![CLIENT_CONSTANTS.FINOPTSYS, CLIENT_CONSTANTS.DH].includes(Config.activeConfig.id) &&
										Config.activeConfig.login.fosLogoAtBottom && (
											<>
												<div className='f-14 text-black mt-3'>Powered By</div>
												<h3 className='f-28 text-black'>FinOptSys</h3>
											</>
										)}
								</div>
							</Carousel.Caption>
						</Carousel.Item>
					)
				})}
			</Carousel>
		)
	}
}
