import React from 'react'
import {Dropdown} from 'react-bootstrap'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {ArrayUtil} from 'helper-util'
import {IconDelete, LoaderIcon, NavigationCheckMark, VerticalDots} from '../../styles/svg/svgIcons'
import CreateMenu from './createMenu'
import EditableNameField from './editableNameField'

const MenuList = props => {
	const {
		activeNavigationGroup,
		allMenuData,
		menuCreationInProgress,
		handleNavigationGroupChange,
		handleEdit,
		handleDelete,
		createNewMenu,
		handleMenuCreationToggle,
		handleActivate,
		loading,
	} = props
	const isCreateMenuDisabled = allMenuData && allMenuData.some(item => item.isMenuNew)
	return (
		<div className='d-flex flex-column flex-one menu-list-box'>
			<div className='menu-list-title'>My Navigation Group</div>
			<PerfectScrollbar>
				<div
					className={`d-flex flex-column flex-fill overflow-auto loader-wrapper ${
						loading ? '-loading cursor-not-allowed' : ''
					}`}
				>
					<div className='comp-loader'>
						<LoaderIcon />
					</div>
					{!ArrayUtil.isEmpty(allMenuData) &&
						allMenuData.map(item => {
							return (
								<div
									className={`menu-list-item px-22 py-30 py-3 d-flex justify-content-between ${
										item.menuId === activeNavigationGroup.menuId ? 'active-menu-item' : ''
									}`}
									onClick={() => handleNavigationGroupChange(item)}
								>
									<div className='f-14 menu-item-title d-flex align-items-center'>
										<EditableNameField dataItem={item} handleEdit={handleEdit} />
									</div>
									<div className='d-flex align-items-center ml-3'>
										<div className='mr-2 f-15 d-flex align-items-center' onClick={event => handleActivate(event, item)}>
											{item.menuId === activeNavigationGroup.menuId ? (
												<>
													<span className='navigation-active-chk d-flex'>
														<NavigationCheckMark />
													</span>
													<span className='ml-2 text-green-menu-config'>Active</span>
												</>
											) : (
												<>
													{/* <ErrorIcon /> */}
													<span className='ml-2 cursor-pointer text-purple-menu-config'>Activate</span>
												</>
											)}
										</div>
										{!item.isMenuNew && (
											<Dropdown className='v-dots'>
												<Dropdown.Toggle variant='' id='tradeActionColumnSelection' className='pr-0 pt-0 pb-1'>
													<VerticalDots />
												</Dropdown.Toggle>
												<Dropdown.Menu
													className='max-h-200px w-60 overflow-auto p-0 shadow-sm'
													onClick={() => handleDelete(item.menuId)}
												>
													<Dropdown.Item className='px-2'>
														<IconDelete />
														<span className='pl-2'>{`Delete ${item.name}`}</span>
													</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
										)}
									</div>
								</div>
							)
						})}
				</div>
			</PerfectScrollbar>
			{!isCreateMenuDisabled && (
				<CreateMenu
					menuCreationInProgress={menuCreationInProgress}
					handleMenuSubmit={createNewMenu}
					handleCancel={() => handleMenuCreationToggle(false)}
					handleCreate={() => handleMenuCreationToggle(true)}
				/>
			)}
		</div>
	)
}

export default MenuList
