import {createFetchActions} from '../../services/createActions'
import {getPMSBaseUrl} from '../../services/apiService'
import {put} from '../../services/createAPICall'

export const GET_COUNTERPARTY_HAIRCUT_DATA = createFetchActions('GET_COUNTERPARTY_HAIRCUT_DATA')

export function getHaircutDataAndColumns() {
	return function (dispatch) {
		dispatch(
			put(
				GET_COUNTERPARTY_HAIRCUT_DATA,
				`${getPMSBaseUrl()}/v1/decisionMaking`,
				{
					params: {
						objective: [],
						strategy: 'Collateral Optimization',
					},
					requestType: 'haircut',
				},

				null,
				'Internal Server Error occured while getting counterparty haircut data'
			)
		)
	}
}
