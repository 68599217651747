import {
	GET_REPORTS_DATA_SYNCFUSION,
	SAVE_SF_REPORTS,
	GET_SF_REPORTS,
	DELETE_SF_REPORTS,
} from './reportsSyncfusionActions'

export interface ReportsSyncfusion {
	reportsLoading: boolean
	totalData?: []
	reports: any
}

const initialState: ReportsSyncfusion = {
	reportsLoading: false,
	totalData: [],
	reports: [],
}

export function reportsSyncfusionReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_REPORTS_DATA_SYNCFUSION.triggered:
			return {...state, reportsLoading: true}
		case GET_REPORTS_DATA_SYNCFUSION.failed:
			return {...state, reportsLoading: false}
		case GET_REPORTS_DATA_SYNCFUSION.succeeded:
			state.totalData = action.response.data
			return {
				...state,
				reportsLoading: false,
			}
		case SAVE_SF_REPORTS.triggered:
			return {...state}
		case SAVE_SF_REPORTS.failed:
			return {...state}
		case SAVE_SF_REPORTS.succeeded:
			state.reports = action.response.data.items
			return {
				...state,
			}
		case GET_SF_REPORTS.triggered:
			return {...state}
		case GET_SF_REPORTS.failed:
			return {...state}
		case GET_SF_REPORTS.succeeded:
			state.reports = action.response.data
			return {
				...state,
			}
		case DELETE_SF_REPORTS.triggered:
			return {...state}
		case DELETE_SF_REPORTS.failed:
			return {...state}
		case DELETE_SF_REPORTS.succeeded:
			state.reports = action.response.data.items
			return {
				...state,
			}
	}
	return state
}
