import gql from 'graphql-tag'

export const pbAvnDocumentFragment = gql`
	fragment avnDocument on AvailabilitiesNeedsDocument {
		id
		counterPartyName
		originalFilename
		type
		match
		potentialMatch
		notional
		totalAvailableNotional
		rank
		matchedNotional
		matchedAgainstCptyAvailability
		optimalMatchNotional
		matchedAgainstNeeds
		cashPercent
		nonCashPercent
		createdDate
		collateralType
	}
`
export const pbAvnActivitiesFragment = gql`
	fragment avnActivities on AvailabilitiesNeedsOrderEntry {
		orderId
		counterParty
		counterPartyTenantId
		isOwner
		securityId
		securityPrice {
			amount
			currency
		}
		exchangeRate
		availableQuantity
		original
		allocated
		allocatedNotional
		rebateRate
		feeRate
		name
		isin
		needQuantity
		needsNotional
		status
		collateralType
		actions
	}
`

export const pbAvnIncomingOrdeDetailsFragment = gql`
	fragment avnIncomingOrderDetails on AvailNeedsIncomingOrderDetails {
		orderId
		counterParty
		counterPartyTenantId
		isOwner
		securityId
		securityPrice {
			amount
			currency
		}
		exchangeRate
		availableQuantity
		allocated
		allocatedNotional
		original
		rebateRate
		feeRate
		name
		isin
		needQuantity
		needsNotional
		status
		collateralType
		actions
		sodAvailableQuantity
		assetUtilizationPercent
		assetUtilizationImpactPercent
		lendingTargetQuantity
		currentAvailableQuantity
	}
`

export const getPbBasketDetailsQuery = gql`
	{
		availabilitiesNeeds {
			userAvnActivities {
				open {
					id
					counterPartyName
					originalFilename
				}
			}
		}
	}
`

export const requestPbLoanDetailsQuery = (id = '') => gql`
{
	availNeedsOpenDetails(id: "${id}") {
    id
    name
    securityId
	price
	exchangeRate
    collateralType
	counterPartyQuantity
	counterPartyNotional
	needsQuantity
	needsNotional
    allocatedQuantity
    allocatedNotional
    isin
    rebateRate
    currency
    feeRate
  }
}
`

export const getPbDropdownDataQuery = (type = '') => gql`
query {
  availAndNeedDropDowns(type:${type}){
    assetTypes
    assetSubTypes
    indices
    funds
    ratings
    sectors
  }
}
`

export const getPbBasketFileDataQuery = () => gql`
	query {
		availabilitiesNeedsBaskets {
			availNeedBasketId
			name
			currency
			assetTypes
			type
			notional
			totalQuantity
			availNeedBasketItems {
				availNeedBasketItemId
				securityId
				price
				needQuantity
				notional
				isin
				feeRate
				rebateRate
			}
		}
	}
`

export const getPbBasketOverviewQuery = () => gql`
	query {
		portfolio {
			overView {
				availabilities {
					amount
					currency
				}
				needs {
					amount
					currency
				}
			}
		}
	}
`

export const pbActivityLogQuery = (counterParty: string, way: string, view: String) => gql`
	query{
		availNeedOrderLogs(counterParty:"${counterParty}",way:${way}, view:${view}){
		description
		level
		direction
		createdDate
    	}
    }
`

export const fetchPbSearchSecurityResultQuery = security => {
	return gql`
		query {
			searchAvailabilitySecurityResults(
				searchSecurity: {
					searchSecurityEntry: [ 
            ${security}
					]
				}
			) {
				securityId
				  requestedQuantity
        	totalAvailableQuantity
          approvedQuantity
          locateRequestId
          locateRequestedBy
				availabilitiesNeedsDocumentSearchEntries {
          securityId
					availableQuantity
					rebateRate
					feeRate
					counterPartyName
					documentId
					documentEntryId
          clientRank
          approvedQuantity
          filledFlag
          shortFall
          locateRequestId
          id
          name
          price
          exchangeRate
          counterPartyQuantity
          counterPartyNotional
          needsQuantity
          needsNotional
          allocatedQuantity
          allocatedNotional
          isin
          currency
          collateralType
          documentIdForCreateBorrow
				}
			}
		}
	`
}
