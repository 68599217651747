import {ArrayUtil} from 'helper-util'
import {formatWithThreeDecimals, formatWithSeparator} from '../../../utils/formatUtils'

export const rateColumnDef = onRateToggle => {
	return {
		headerName: 'Rebate/Fee',
		field: 'rate',
		headerComponent: 'FeeHeader',
		headerComponentParams: {
			onToggle: onRateToggle,
		},
		headerClass: ['d-flex', 'justify-content-end'],
		width: 160,
		maxWidth: 160,
		minWidth: 150,
		cellRenderer: params => {
			if (params.value === null || params.value === undefined || (params.data && params.data.status !== 'ACCEPTED')) {
				return null
			}
			return params.value
		},
	}
}

const fallbackFormatter = (data, skipFormatting = false) => {
	if (data.originalRate === 0 || data.rate === 0 || data.fee === 0) {
		return skipFormatting ? 0 : formatWithThreeDecimals(0)
	}
	return ''
}

export const dataMassagingFn = gridData => {
	return !ArrayUtil.isEmpty(gridData)
		? gridData.map(d => {
				return {
					...d,
					shortfall: d.isPartial ? (d.maxReqQty || 0) - (d.bookedQty || 0) : (d.requestedQty || 0) - (d.bookedQty || 0),
					originalRate: d.rate ? formatWithThreeDecimals(Number(d.rate)) : 0,
					rate: d.rate ? formatWithThreeDecimals(Number(d.rate)) : fallbackFormatter(d),
					feeToggleState: 0,
					requestedQty: d.isPartial ? d.maxReqQty : d.requestedQty,

					// status: 'COUNTER',
				}
		  })
		: []
}

export const alterRateField = (gridData, toggleMode) => {
	return !ArrayUtil.isEmpty(gridData)
		? gridData.map(d => {
				return {
					...d,
					rate:
						toggleMode === 0
							? d.originalRate || fallbackFormatter(d)
							: d.originalRate
							? formatWithSeparator(Number(d.originalRate * 100))
							: fallbackFormatter(d, true),
					feeToggleState: toggleMode,
				}
		  })
		: []
}
