import React from 'react'
import {Dropdown, Form} from 'react-bootstrap'
import {ArrayUtil, ObjectUtil} from 'helper-util'

interface Props {
	data: any[]
	label: string
	options: any[]
	inputClass?: string
	defaultPlaceholder?: string
	noTitle?: boolean
	isDisabled?: boolean
	isMandatory?: boolean
	onChange: (value: any[]) => any
}

export default function FsMultiSelectDropdown(props: Props) {
	const {data, label, options, isDisabled, isMandatory, inputClass, noTitle, defaultPlaceholder, onChange} = props
	const preSelectedOption = !ArrayUtil.isEmpty(data) && options.filter(option => data.includes(option.id))

	const preSelectedOptionLabel = {}
	!ArrayUtil.isEmpty(preSelectedOption) &&
		preSelectedOption.forEach(item => {
			preSelectedOptionLabel[item.id] = item.label
		})

	return (
		<div className='custom-input'>
			{!noTitle && <label className={`dd-label trade-label mt-3 ${isMandatory ? 'mandatory' : ''}`}>{label}</label>}
			<Dropdown>
				<Dropdown.Toggle disabled={isDisabled} variant='' id='' className={inputClass}>
					{ObjectUtil.isEmpty(preSelectedOptionLabel)
						? `${defaultPlaceholder || 'Select'} ${label}`
						: Object.values(preSelectedOptionLabel).join(', ')}
				</Dropdown.Toggle>
				<Dropdown.Menu className='max-h-180px overflow-auto'>
					<div className='py-2 px-3'>
						{options.map(item => {
							return (
								<div className='mb-2 form-sm' key={item.id}>
									<Form.Check
										custom
										checked={Object.keys(preSelectedOptionLabel).includes(item.value)}
										type='checkbox'
										id={item.id}
										label={item.label}
										value={item.value}
										onChange={e => {
											const newSelectedOptionLabel = JSON.parse(JSON.stringify(preSelectedOptionLabel))
											if (newSelectedOptionLabel[item.id]) {
												delete newSelectedOptionLabel[item.id]
											} else {
												newSelectedOptionLabel[item.id] = item.label
											}

											onChange(
												data === null
													? [e.target.value]
													: data.includes(item.value)
													? data.filter(item => item !== e.target.value)
													: [...data, e.target.value]
											)
										}}
										className='f-14 text-primary font-weight-600'
									/>
								</div>
							)
						})}
					</div>
				</Dropdown.Menu>
			</Dropdown>
		</div>
	)
}
