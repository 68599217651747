import {ArrayUtil} from 'helper-util'
import {v4 as uuidv4} from 'uuid'
import {FETCH_ALL_SECURITIES, FETCH_ALL_COLLATERAL_TYPES, CLEAR_BASKET_GRID_DATA} from './AlpNewRequestModalActions'
import {TOGGLE_ENTER_RATE, TOGGLE_PARTIAL} from './AlpNewRequestModalActions'
import {FETCH_LOCATE_INVENTORY, TOGGLE_LOCATE_MODE, ON_LOCATE_INVENTORY} from './AlpNewRequestModalActions'
import {SET_SECURITY_DETAILS, SET_BASKET_BULK_SECURITY_DETAILS} from './AlpNewRequestModalActions'
import {CLEAR_MASTER_SECURITIES} from './AlpNewRequestModalActions'
import {SET_SOURCE, FETCH_ADDITIONAL_SECURITY_DETAILS} from './AlpNewRequestModalActions'
import {ON_BASKET_ROW_UPDATE, ON_SINGLE_STOCK_ROW_UPDATE, ON_BASKET_RADIO_SELECT} from './AlpNewRequestModalActions'
import {FETCH_ALL_BORROWERS, FETCH_BORROWERS_SUB_ACCOUNT} from './AlpNewRequestModalActions'
import {SET_INPUT_PANEL_REQUEST_DATA, ON_CLEAR_ALL, ON_CREATE_BORROW} from './AlpNewRequestModalActions'
import {getBasketGridOnBulkUpload, getBasketGridOnRowUpdate, masterSecurityMapper} from './AlpNewRequestModalHelper'
import {mergeInventoryDataAfterLocate} from './AlpNewRequestModalHelper'
import {getBasketGrodOnRadioSelect, getSingleStockOnRowUpdate} from './AlpNewRequestModalHelper'
import {getFirstInitialRow, modifySingleStockGridForAdditionDetails} from './AlpNewRequestModalHelper'
import {getSelectedSecuritySearch} from './AlpNewRequestModalHelper'
import {CollateralType, IBorrower, IMasterSecurity, SelectedSearchSecurity} from './AlpNewRequestModel'
import {InputPanel} from './InputPanel/InputPanelModel'
import {BASKET} from './AlpNewRequestModalConstant'

export interface AlpNewRequest {
	isLoading: boolean
	selectedSearchSecurity: SelectedSearchSecurity
	singleStockGridData: any
	basketGridData: any
	masterSecurities: IMasterSecurity[]
	collateralTypes: CollateralType
	allBorrowersList: IBorrower[]
	borrowersSubAccountList: IBorrower
	requestType: string
	source: string
	inputPanelRequestData: InputPanel
	isEnterRateSelected: boolean
	isLocateMode: boolean
	isLocateClicked: boolean
	showLoadingTillWsResponse: boolean
}

const initialState: AlpNewRequest = {
	isLoading: false,
	requestType: BASKET,
	source: '',
	selectedSearchSecurity: null,
	singleStockGridData: null,
	basketGridData: [getFirstInitialRow(1, uuidv4(), '')],
	masterSecurities: null,
	collateralTypes: null,
	allBorrowersList: null,
	borrowersSubAccountList: null,
	inputPanelRequestData: null,
	isEnterRateSelected: false,
	isLocateMode: true,
	isLocateClicked: false,
	showLoadingTillWsResponse: false,
}

export function AlpNewRequestReducer(state = initialState, action: any) {
	switch (action.type) {
		case FETCH_ALL_SECURITIES.triggered:
			return {...state, masterSecurities: null, isLoading: true}
		case FETCH_ALL_SECURITIES.failed:
			return {...state, isLoading: false}
		case FETCH_ALL_SECURITIES.succeeded:
			const masterSecurities = masterSecurityMapper((action.response && action.response.data) || [])
			return {
				...state,
				masterSecurities: masterSecurities,
				isLoading: false,
			}

		case FETCH_ALL_COLLATERAL_TYPES.triggered:
			return {...state, collateralTypes: null, isLoading: true}
		case FETCH_ALL_COLLATERAL_TYPES.failed:
			return {...state, isLoading: false}
		case FETCH_ALL_COLLATERAL_TYPES.succeeded:
			const colTypes = action.response && action.response.data
			return {
				...state,
				collateralTypes: colTypes,
				isLoading: false,
			}

		case FETCH_ALL_BORROWERS.triggered:
			return {...state, collateralTypes: null, isLoading: true}
		case FETCH_ALL_BORROWERS.failed:
			return {...state, isLoading: false}
		case FETCH_ALL_BORROWERS.succeeded:
			return {
				...state,
				allBorrowersList: action.response && action.response.data,
				isLoading: false,
			}

		case FETCH_BORROWERS_SUB_ACCOUNT.triggered:
			return {...state, collateralTypes: null, isLoading: true}
		case FETCH_BORROWERS_SUB_ACCOUNT.failed:
			return {...state, isLoading: false}
		case FETCH_BORROWERS_SUB_ACCOUNT.succeeded:
			return {
				...state,
				borrowersSubAccountList: action.response && action.response.data,
				isLoading: false,
			}

		case ON_CREATE_BORROW.triggered:
			return {...state, isLoading: true}
		case ON_CREATE_BORROW.failed:
			return {...state, isLoading: false}
		case ON_CREATE_BORROW.succeeded:
			return {
				...state,
				isLoading: false,
			}

		case SET_SOURCE:
			return {
				...state,
				source: action.payload,
			}

		case TOGGLE_ENTER_RATE:
			return {
				...state,
				isEnterRateSelected: !state.isEnterRateSelected,
			}

		case TOGGLE_PARTIAL:
			const isTransitionFromNonPartialToPartial = state.inputPanelRequestData
				? !state.inputPanelRequestData.isPartial
				: true
			return {
				...state,
				inputPanelRequestData: {
					...state.inputPanelRequestData,
					isPartial: state.inputPanelRequestData ? !state.inputPanelRequestData.isPartial : true,
				},
				basketGridData: state.basketGridData.map(b => {
					return {
						...b,
						minReqQty: 100,
						maxReqQty: isTransitionFromNonPartialToPartial ? b.requestedQuantity : b.maxReqQty,
						requestedQuantity: isTransitionFromNonPartialToPartial ? b.requestedQuantity : b.maxReqQty,
					}
				}),
			}

		case TOGGLE_LOCATE_MODE:
			return {
				...state,
				isLocateMode: !state.isLocateMode,
			}

		case SET_INPUT_PANEL_REQUEST_DATA:
			return {
				...state,
				inputPanelRequestData: {
					...action.payload,
					isPartial:
						state.inputPanelRequestData && state.inputPanelRequestData.isPartial
							? state.inputPanelRequestData.isPartial
							: false,
				},
			}

		case FETCH_LOCATE_INVENTORY.succeeded:
			return {
				...state,
				isLocateMode: false,
				showLoadingTillWsResponse: false,
				basketGridData: mergeInventoryDataAfterLocate(
					(action.response && action.response.data) || [],
					state.basketGridData
				),
			}

		case ON_LOCATE_INVENTORY:
			return {...state, isLocateClicked: true, showLoadingTillWsResponse: true}

		case SET_BASKET_BULK_SECURITY_DETAILS:
			return {
				...state,
				basketGridData: getBasketGridOnBulkUpload(action.payload, state.basketGridData, state.masterSecurities),
				isLocateMode: true,
			}

		case ON_SINGLE_STOCK_ROW_UPDATE:
			return {
				...state,
				singleStockGridData: getSingleStockOnRowUpdate(action.payload, state.singleStockGridData),
			}

		case ON_BASKET_ROW_UPDATE:
			const {basketGridData, isLocateMode} = getBasketGridOnRowUpdate(
				action.payload,
				state.basketGridData,
				state.isLocateMode,
				state.isLocateClicked,
				state.inputPanelRequestData
			)
			return {
				...state,
				basketGridData: basketGridData,
				isLocateMode: isLocateMode !== undefined ? isLocateMode : state.isLocateMode,
			}

		case SET_SECURITY_DETAILS:
			const {basketGridData: basketDataGridCopy, isLocateMode: isLocateModeCopy} = getBasketGridOnRowUpdate(
				action.payload,
				state.basketGridData,
				state.isLocateMode,
				state.isLocateClicked,
				state.inputPanelRequestData
			)
			return {
				...state,
				basketGridData: basketDataGridCopy,
				isLocateMode: isLocateModeCopy,
			}

		case ON_BASKET_RADIO_SELECT:
			return {
				...state,
				basketGridData: getBasketGrodOnRadioSelect(action.payload, state.basketGridData),
			}

		case CLEAR_MASTER_SECURITIES:
			return {
				...state,
				masterSecurities: null,
			}

		case FETCH_ADDITIONAL_SECURITY_DETAILS.triggered:
			return {...state, singleStockGridData: null, selectedSearchSecurity: null, isLoading: true}
		case FETCH_ADDITIONAL_SECURITY_DETAILS.failed:
			return {...state, isLoading: false}
		case FETCH_ADDITIONAL_SECURITY_DETAILS.succeeded:
			const targetedInventoryResponse = action.response && action.response.data
			return {
				...state,
				selectedSearchSecurity: getSelectedSecuritySearch(targetedInventoryResponse),
				singleStockGridData: modifySingleStockGridForAdditionDetails(targetedInventoryResponse),
				isLoading: false,
			}

		case CLEAR_BASKET_GRID_DATA:
			const isDataAvlbl = !ArrayUtil.isEmpty(state.basketGridData) && state.basketGridData[0].security !== ''
			return {
				...state,
				basketGridData: isDataAvlbl ? [getFirstInitialRow(1, uuidv4(), '')] : state.basketGridData,
			}

		case ON_CLEAR_ALL:
			if (action.requestType === BASKET) {
				let inputPanelRequestData = {
					...state.inputPanelRequestData,
				}
				if (action.isInputSelectionClearRequired) {
					inputPanelRequestData = null
				}
				return {
					...state,
					basketGridData: [getFirstInitialRow(1, uuidv4(), '')],
					isLocateMode: true,
					isLocateClicked: false,
					showLoadingTillWsResponse: false,
					inputPanelRequestData: inputPanelRequestData,
					isEnterRateSelected: false,
				}
			} else {
				return {
					...state,
				}
			}
	}
	return state
}
