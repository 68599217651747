import {createFetchActions} from '../../../services/createActions'
import {getMPSClient} from '../../..'
import {responseActivityLog} from './responseActivityLogQueries'

export const FETCH_RESPONSE_ACTIVITY_LOG = createFetchActions('FETCH_RESPONSE_ACTIVITY_LOG')

export const fetchResponseActivityLog = (responseId: string) => {
	return function (dispatch) {
		dispatch({type: FETCH_RESPONSE_ACTIVITY_LOG.triggered})
		getMPSClient()
			.query({
				query: responseActivityLog(responseId),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: FETCH_RESPONSE_ACTIVITY_LOG.succeeded,
					response: {
						...response,
						responseId,
					},
				})
			})
	}
}
