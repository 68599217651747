import {
	GET_CALENDAR_EVENTS,
	NEW_CALENDAR_EVENT,
	EDIT_CALENDAR_EVENT,
	DELETE_CALENDAR_EVENT,
	RESET_SETTLEMENT_CALENDAR,
} from './settlementCalendarActions'
import {getUniqProducts, getUniqueCurrencies} from './settlementCalendarHelper'

export interface SettlementCalendar {
	loading: boolean
	calendarData: any
	products: any
	clients: any
	currencies: any
}

const initialState: SettlementCalendar = {
	loading: true,
	calendarData: null,
	products: [],
	clients: [],
	currencies: [],
}

export function settlementCalendarReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_CALENDAR_EVENTS.triggered:
			return {...state, calendarData: null, loading: true}
		case GET_CALENDAR_EVENTS.failed:
			return {...state, calendarData: {}, products: [], clients: [], currencies: [], loading: false}
		case GET_CALENDAR_EVENTS.succeeded:
			state.calendarData = action.response.data
			state.products = getUniqProducts(action.response.data, 'tradeType')
			state.clients = getUniqProducts(action.response.data, 'counterParty')
			state.currencies = getUniqueCurrencies(action.response.data)
			return {
				...state,
				loading: false,
			}

		case NEW_CALENDAR_EVENT.triggered:
			return {...state, loading: true}
		case NEW_CALENDAR_EVENT.failed:
			return {...state, loading: false}
		case NEW_CALENDAR_EVENT.succeeded:
			return {...state, loading: false}

		case EDIT_CALENDAR_EVENT.triggered:
			return {...state, loading: true}
		case EDIT_CALENDAR_EVENT.failed:
			return {...state, loading: false}
		case EDIT_CALENDAR_EVENT.succeeded:
			return {...state, loading: false}

		case DELETE_CALENDAR_EVENT.triggered:
			return {...state, loading: true}
		case DELETE_CALENDAR_EVENT.failed:
			return {...state, loading: false}
		case DELETE_CALENDAR_EVENT.succeeded:
			return {...state, loading: false}

		case RESET_SETTLEMENT_CALENDAR:
			return {...initialState}
	}
	return state
}
