import gql from 'graphql-tag'

export const getMyBUsQuery = tenantId => gql`
	query {
		allBusinessUnits (tenantId:"${tenantId}") {
      businessUnitId
      id
      fullName
      shortName
      country
      fundId
      tenantId
		}
	}
`
