export const dropdownOption = {
	assetSubType: {id: 'assetSubType', label: 'Asset Subtype'},
	assetType: {id: 'assetType', label: 'Asset Type'},
	counterParty: {id: 'counterParty', label: 'Counterparty'},
	tradeId: {id: 'tradeId', label: 'Trade ID'},
	notionalCurrency: {id: 'notionalCurrency', label: 'Currency'},
	fitchRating: {id: 'fitchRating', label: 'Fitch Rating'},
	fund: {id: 'fund', label: 'Fund Name'},
	hqla: {id: 'hqla', label: 'HQLA'},
	index: {id: 'index', label: 'Index'},
	moodyRating: {id: 'moodyRating', label: 'Moody Rating'},
	sector: {id: 'sector', label: 'Sector'},
	securityId: {id: 'securityId', label: 'Security'},
	snpRating: {id: 'snpRating', label: 'S&P Rating'},
	tenor: {id: 'tenor', label: 'Tenor'},
	tradeType: {id: 'tradeType', label: 'Trade Type'},
}

export const portfolioEntities = {
	inefficiencies: 'inefficiencies',
	recallInefficiencies: 'recallInefficiencies',
	returnInefficiencies: 'returnInefficiencies',
	repoTrades: 'repoTrades',
	repoTradesRepo: 'repoTradesRepo',
	repoTradesReverse: 'repoTradesReverse',
	repoTradesNotional: 'repoTradesNotional',
	repoTradesExposure: 'repoTradesExposure',
	repoTradesRepoEnhanced: 'repoTrades',
	repoTradesReverseEnhanced: 'reverseRepoTrades',
	nonCash: 'nonCash',
	nonCashBorrow: 'nonCashBorrow',
	nonCashLoan: 'nonCashLoan',
	nonCashNotional: 'nonCashNotional',
	nonCashExposure: 'nonCashExposure',
	sblTrades: 'sblTrades',
	sblTradesSbl: 'sblTradesSbl',
	sblTradesBorrows: 'sblTradesBorrows',
	sblTradesLoans: 'sblTradesLoans',
	sblTradesNotional: 'sblTradesNotional',
	sblTradesExposure: 'sblTradesExposure',
	boxes: 'boxes',
	availabilities: 'availabilities',
	needs: 'needs',
	matureTrades: 'matureTrades',
	term: 'term',
	overNight: 'overNight',
	open: 'open',
	openTrades: 'openTrades',
	reRate: 'reRate',
	all: 'all',
	'all-test': 'all-test',
	longTrades: 'longTrades',
	shortTrades: 'shortTrades',
	grossExposure: 'grossExposure',
	cashTrades: 'cashTrades',
	nonCashTrades: 'nonCashTrades',
	dayZero: 'dayZero',
	dayOne: 'dayOne',
	dayTwo: 'dayTwo',
	dayThree: 'dayThree',
	dayFour: 'dayFour',
	dayFive: 'dayFive',
	collateralOptimization: 'collateralOptimization',
	previousCollateral: 'previousCollateral',
	currentCollateral: 'currentCollateral',
	requiredCollateral: 'requiredCollateral',
	pbPositions: 'pbPositions',
	pbLong: 'Long',
	pbShort: 'Short',
	pbIntraday: 'pbIntraday',
	pbBuys: 'pbBuys',
	pbSells: 'pbSells',
	pbMargin: 'pbMargin',
}

export const portfolioTitle = {
	[portfolioEntities.inefficiencies]: 'Recalls/Returns',
	[portfolioEntities.recallInefficiencies]: 'Recall',
	[portfolioEntities.returnInefficiencies]: 'Return',
	[portfolioEntities.nonCash]: 'Non-Cash',
	[portfolioEntities.nonCashBorrow]: 'Borrow',
	[portfolioEntities.nonCashLoan]: 'Loan',
	[portfolioEntities.nonCashNotional]: 'Gross Notional',
	[portfolioEntities.nonCashExposure]: 'Net Exposure',
	[portfolioEntities.repoTrades]: 'Repo',
	[portfolioEntities.repoTradesRepo]: 'Repo',
	[portfolioEntities.repoTradesRepoEnhanced]: 'Repo',
	[portfolioEntities.repoTradesReverse]: 'Reverse',
	[portfolioEntities.repoTradesReverseEnhanced]: 'Reverse',
	[portfolioEntities.repoTradesNotional]: 'Gross Notional',
	[portfolioEntities.repoTradesExposure]: 'Net Exposure',
	[portfolioEntities.sblTrades]: 'SBL',
	[portfolioEntities.sblTradesSbl]: 'Loan',
	[portfolioEntities.sblTradesBorrows]: 'Borrows',
	[portfolioEntities.sblTradesNotional]: 'Gross Notional',
	[portfolioEntities.sblTradesExposure]: 'Net Exposure',
	[portfolioEntities.boxes]: 'Box',
	[portfolioEntities.availabilities]: 'Availability',
	[portfolioEntities.needs]: 'Needs',
	[portfolioEntities.matureTrades]: 'Maturing Trades',
	[portfolioEntities.term]: 'Term',
	[portfolioEntities.overNight]: 'Overnight',
	[portfolioEntities.open]: 'Open',
	[portfolioEntities.openTrades]: 'Outstanding Trades',
	[portfolioEntities.reRate]: 'ReRates',
	[portfolioEntities.all]: 'ABC Portfolio',
	[portfolioEntities['all-test']]: 'ABC Portfolio - Test',
	[portfolioEntities.longTrades]: 'ABC Source',
	[portfolioEntities.shortTrades]: 'ABC Use',
	[portfolioEntities.boxes]: 'Box',
	[portfolioEntities.cashTrades]: 'Cash',
	[portfolioEntities.nonCashTrades]: 'NonCash',
	[portfolioEntities.grossExposure]: 'Gross Exposure',
	[portfolioEntities.dayZero]: 'T+0',
	[portfolioEntities.dayOne]: 'T+1',
	[portfolioEntities.dayTwo]: 'T+2',
	[portfolioEntities.dayThree]: 'T+3',
	[portfolioEntities.dayFour]: 'T+4',
	[portfolioEntities.dayFive]: 'T+5',
	[portfolioEntities.collateralOptimization]: 'Liquidity Optimization',
	[portfolioEntities.previousCollateral]: 'Previous Collateral',
	[portfolioEntities.currentCollateral]: 'Today Margin',
	[portfolioEntities.requiredCollateral]: 'Required Collateral',
	[portfolioEntities.pbPositions]: 'Positions',
	[portfolioEntities.pbLong]: 'Longs',
	[portfolioEntities.pbShort]: 'Shorts',
	[portfolioEntities.pbIntraday]: 'Intraday Trades',
	[portfolioEntities.pbBuys]: 'Buys',
	[portfolioEntities.pbSells]: 'Sells',
	[portfolioEntities.pbMargin]: 'Margin',
}

export const dataTransformFunctionName = {
	[portfolioEntities.inefficiencies]: 'generalTransform',
	[portfolioEntities.recallInefficiencies]: 'inefficienciesRecallTransform',
	[portfolioEntities.returnInefficiencies]: 'inefficienciesReturnTransform',
	[portfolioEntities.nonCash]: 'generalTransform',
	[portfolioEntities.nonCashBorrow]: 'generalTransform',
	[portfolioEntities.nonCashLoan]: 'generalTransform',
	[portfolioEntities.nonCashNotional]: 'notionalTransform',
	[portfolioEntities.nonCashExposure]: 'exposureTransform',
	[portfolioEntities.repoTrades]: 'generalTransform',
	[portfolioEntities.repoTradesRepo]: 'generalTransform',
	[portfolioEntities.repoTradesRepo]: 'generalTransform',
	[portfolioEntities.repoTradesNotional]: 'notionalTransform',
	[portfolioEntities.repoTradesExposure]: 'exposureTransform',
	[portfolioEntities.sblTrades]: 'generalTransform',
	[portfolioEntities.sblTradesSbl]: 'generalTransform',
	[portfolioEntities.sblTradesNotional]: 'notionalTransform',
	[portfolioEntities.sblTradesExposure]: 'exposureTransform',
	[portfolioEntities.availabilities]: 'availabilitiesTransform',
	[portfolioEntities.needs]: 'needsTransform',
	[portfolioEntities.matureTrades]: 'generalTransform',
	[portfolioEntities.term]: 'termTransform',
	[portfolioEntities.overNight]: 'overNightTransform',
	[portfolioEntities.open]: 'openTransform',
	[portfolioEntities.openTrades]: 'openTradesTransform',
	[portfolioEntities.all]: 'generalTransform',
	[portfolioEntities['all-test']]: 'generalTransform',
	[portfolioEntities.longTrades]: 'generalTransform',
	[portfolioEntities.shortTrades]: 'generalTransform',
	[portfolioEntities.boxes]: 'generalTransform',
	[portfolioEntities.cashTrades]: 'sblGroupingTransfrom',
	[portfolioEntities.nonCashTrades]: 'sblGroupingTransfrom',
	[portfolioEntities.grossExposure]: 'sblGroupingTransfrom',
	[portfolioEntities.collateralOptimization]: 'generalTransform',
	[portfolioEntities.pbPositions]: 'generalTransform',
	[portfolioEntities.pbLong]: 'generalTransform',
	[portfolioEntities.pbShort]: 'generalTransform',
	[portfolioEntities.pbIntraday]: 'generalTransform',
	[portfolioEntities.pbBuys]: 'generalTransform',
	[portfolioEntities.pbSells]: 'generalTransform',
	[portfolioEntities.pbMargin]: 'generalTransform',
}

export const portfolioGrouping = {
	[portfolioEntities.inefficiencies]: [portfolioEntities.recallInefficiencies, portfolioEntities.returnInefficiencies],
	[portfolioEntities.nonCash]: [portfolioEntities.nonCashBorrow, portfolioEntities.nonCashLoan],
	[portfolioEntities.sblTrades]: [portfolioEntities.sblTradesBorrows, portfolioEntities.sblTradesSbl],
	[portfolioEntities.matureTrades]: [portfolioEntities.matureTrades],
	[portfolioEntities.openTrades]: [portfolioEntities.openTrades],
	[portfolioEntities.reRate]: [portfolioEntities.reRate],
	[portfolioEntities.all]: [portfolioEntities.longTrades, portfolioEntities.shortTrades],
	[portfolioEntities['all-test']]: [portfolioEntities.longTrades, portfolioEntities.shortTrades],
	[portfolioEntities.boxes]: [portfolioEntities.availabilities, portfolioEntities.needs],
	[portfolioEntities.repoTrades]: [
		portfolioEntities.repoTradesRepoEnhanced,
		portfolioEntities.repoTradesReverseEnhanced,
	],
	[portfolioEntities.collateralOptimization]: [
		portfolioEntities.previousCollateral,
		portfolioEntities.currentCollateral,
		// portfolioEntities.requiredCollateral,
	],
	[portfolioEntities.pbPositions]: [portfolioEntities.pbLong, portfolioEntities.pbShort],
	[portfolioEntities.pbIntraday]: [portfolioEntities.pbBuys, portfolioEntities.pbSells],
	[portfolioEntities.pbMargin]: [portfolioEntities.pbMargin],
}

export const defaultPortfolioComputedValue = {
	filterType: {},
	showToggle: false,
	toggleList: [],
	heatmapConfig: {},
	portfolioClass: {},
	portfolioGroup: {},
	secondPortfolioExist: true,
	firstPortfolio: {
		firstPortfolioLoading: true,
		firstPortfolioSubtitle: '',
		firstPortfolioData: [],
	},
	secondPortfolio: {
		secondPortfolioLoading: true,
		secondPortfolioSubtitle: '',
		secondPortfolioData: [],
	},
}
