import React from 'react'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import {ArrayUtil} from 'helper-util'
import {IconInfoSmall} from '../../../styles/svg/svgIcons'
import {suggestionsData} from './customPopoverConstants'

interface Props {
	id: string
	isHelpOn: boolean
	alignment?: any
	popoverClass?: any
	popoverIconClass?: any
}

export default class CustomPopoverWithID extends React.Component<Props, any> {
	render() {
		const {id, isHelpOn, alignment = 'bottom', popoverClass, popoverIconClass} = this.props

		const helpData = suggestionsData[id]

		return (
			<>
				{isHelpOn && !ArrayUtil.isEmpty(helpData) && (
					<span className='ml-2'>
						<OverlayTrigger
							trigger='click'
							placement={alignment}
							rootClose={true}
							overlay={
								<Popover id='popover-basic' className='custom-popover-basic'>
									<Popover.Content className={`shadow-sm p-20 custom-popover-content ${popoverClass}`}>
										{helpData.map((value, index) => {
											return (
												<div className='pb-2 f-14 text-grey-5' key={`popover-${index}`}>
													<span className='font-weight-bold'>{value.header}:</span>
													<div>{value.defination}</div>
												</div>
											)
										})}
									</Popover.Content>
								</Popover>
							}
						>
							<span className={`popover-info-icon cursor-pointer ${popoverIconClass}`}>
								<IconInfoSmall />
							</span>
						</OverlayTrigger>
					</span>
				)}
			</>
		)
	}
}
