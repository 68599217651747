import memoizeOne from 'memoize-one'
import isDeepEqual from 'lodash.isequal'
import Lodash from 'lodash'
import {ArrayUtil} from 'helper-util'

export const getGridWidth = (wideNavbar: boolean) => {
	return window.innerWidth - (wideNavbar ? 265 : 90)
}

export const updateGridDataOnTimerEnds = memoizeOne((liveAuctionsGridData, auctionReqId) => {
	const rowIndexToBeUpdated = (liveAuctionsGridData || []).findIndex(d => d.auctionReqId === auctionReqId)
	if (ArrayUtil.isEmpty(liveAuctionsGridData)) {
		return []
	}
	const updatedLiveGridData = Lodash.cloneDeep(liveAuctionsGridData)
	if (updatedLiveGridData[rowIndexToBeUpdated]) {
		updatedLiveGridData[rowIndexToBeUpdated].status = 'PENDING_RELEASE'
	}
	return updatedLiveGridData
}, isDeepEqual)
