import {createFetchActions} from '../../../services/createActions'
import {get, post, put} from '../../../services/createAPICall'
import {getConfigBaseUrl} from '../../../services/apiService'

import {getAllAllocationsQuery} from './AllocationQueries'
import {getCounterpartyId} from '../../../services/authService'
import {getConfigClient} from '../../..'

export const GET_LEGAL_ENTITY_DATA = createFetchActions('GET_LEGAL_ENTITY_DATA')
export const GET_ALL_ALLOCATIONS = createFetchActions('GET_ALL_ALLOCATIONS')
export const FETCH_ALLOCATION_DETAILS = createFetchActions('FETCH_ALLOCATION_DETAILS')
export const CLEAR_SELECTED_ALLOCATION = createFetchActions('CLEAR_SELECTED_ALLOCATION')

export const SAVE_MY_ALLOCATIONS = createFetchActions('SAVE_MY_ALLOCATIONS')
export const EDIT_MY_ALLOCATIONS = createFetchActions('EDIT_MY_ALLOCATIONS')
export const CLEAR_ALLOCATIONS = createFetchActions('CLEAR_ALLOCATIONS')

export const getAllAllocations = () => {
	return function (dispatch) {
		dispatch({type: GET_ALL_ALLOCATIONS.triggered})
		getConfigClient()
			.query({
				query: getAllAllocationsQuery,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_ALL_ALLOCATIONS.succeeded,
					response,
				})
			})
	}
}

export const getLegalEntities = () => (dispatch: any) => {
	return dispatch(
		get(GET_LEGAL_ENTITY_DATA, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/legalentities`)
	)
}
export const fetchAllocationDetails = (allocationId: string) => (dispatch: any) => {
	return dispatch(
		get(
			FETCH_ALLOCATION_DETAILS,
			`${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/allocations/${allocationId}`
		)
	)
}

export const saveMyAllocations = (data: any) => (dispatch: any) => {
	return dispatch(
		post(SAVE_MY_ALLOCATIONS, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/allocations`, data)
	)
}

export const editMyAllocations = (data: any) => (dispatch: any) => {
	return dispatch(
		put(
			EDIT_MY_ALLOCATIONS,
			`${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/allocations/${data.allocationId}`,
			data
		)
	)
}

export const clearSelectedAllocation = () => {
	return {type: CLEAR_SELECTED_ALLOCATION}
}

export const clearAllocationsData = () => {
	return {
		type: CLEAR_ALLOCATIONS,
	}
}
