import {GET_ALL_TRADES_FOR_OPERATIONS} from './operationsActions'
import {addAmountsFromObjects} from '../dashboard/dashboardHelper'

export interface Operations {
	tradeData: any
	tradesDataLoading: boolean
}

const initialState: Operations = {
	tradeData: null,
	tradesDataLoading: false,
}

export function operationsReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_ALL_TRADES_FOR_OPERATIONS.triggered:
			return {...state, tradeData: null, tradesDataLoading: true}
		case GET_ALL_TRADES_FOR_OPERATIONS.failed:
			return {...state, tradeData: [], tradesDataLoading: false}
		case GET_ALL_TRADES_FOR_OPERATIONS.succeeded:
			state.tradeData = action.response.data ? addAmountsFromObjects(action.response.data.portfolio) : []
			return {
				...state,
				tradesDataLoading: false,
			}
	}
	return state
}
