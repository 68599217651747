/**
 * Type: Reusable
 *
 * Hex based color values
 * Naming rule to follow: 100 very light, 900 very dark shade
 * Taken from - https://material-ui.com/customization/color/
 */
import * as am4core from '@amcharts/amcharts4/core'

export const fsPrimaryColor = '#8a2be2'

export const fsRed = {
	100: '#ffcdd2',
	200: '#ef9a9a',
	300: '#e57373',
	400: '#ef5350',
	500: '#f44336',
	600: '#e53935',
	700: '#d32f2f',
	800: '#c62828',
	900: '#b71c1c',
	A100: '#ff8a80',
	A200: '#ff5252',
	A400: '#ff1744',
	A700: '#d50000',
}

export const fsPink300 = '#f06292'

export const fsPurple300 = '#ba68c8'

export const fsIndigo300 = '#7986cb'

export const fsOrange300 = '#ffb74d'

export const fsBrown300 = '#8d6e63'

export const fsLime300 = '#d4e157'

export const chartColors = {
	blue: am4core.color('#0671BC'),
	purple: am4core.color('#93278F'),
	orange: am4core.color('#F7931E'),
}

export const settlementLadderColors = [
	{color: '#DFC488', className: 'sl-color-one'},
	{color: '#88DFD5', className: 'sl-color-two'},
	{color: '#8896DF', className: 'sl-color-three'},
	{color: '#CE88DF', className: 'sl-color-four'},
	{color: '#0762EF', className: 'sl-color-five'},
	{color: '#F7931E', className: 'sl-color-six'},
]
