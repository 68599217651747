import gql from 'graphql-tag'
import lodash from 'lodash'

export const buildPbAbcPortfolioQuery = selectedColumndefs => {
	const updatedColumns = [...selectedColumndefs, 'id', 'quantity', 'securityId']
	let uniqueColumns = lodash.uniq(updatedColumns)

	let buildQuery = ''
	let arr = []
	if (updatedColumns.includes('baseNotionalCurrency') || updatedColumns.includes('baseNotionalAmount')) {
		buildQuery += `baseNotional {
			amount
			currency
		}`
		arr.push.apply(arr, ['baseNotionalCurrency', 'baseNotionalAmount'])
	}
	if (updatedColumns.includes('notionalCurrency') || updatedColumns.includes('notionalAmount')) {
		buildQuery += `notional {
			amount
			currency
		}`
		arr.push.apply(arr, ['notionalCurrency', 'notionalAmount'])
	}
	if (
		updatedColumns.includes('balanceSheetContributionCurrency') ||
		updatedColumns.includes('balanceSheetContributionAmount')
	) {
		buildQuery += `balanceSheetContribution {
			amount
			currency
		}`
		arr.push.apply(arr, ['balanceSheetContributionCurrency', 'balanceSheetContributionAmount'])
	}
	if (updatedColumns.includes('collateralCurrency') || updatedColumns.includes('collateralAmount')) {
		buildQuery += `collateral {
			amount
			currency
		}`
		arr.push.apply(arr, ['collateralCurrency', 'collateralAmount'])
	}
	if (updatedColumns.includes('priceCurrency') || updatedColumns.includes('priceAmount')) {
		buildQuery += `price {
			amount
			currency
		}`
		arr.push.apply(arr, ['priceCurrency', 'priceAmount'])
	}
	if (updatedColumns.includes('basePriceCurrency') || updatedColumns.includes('basePriceAmount')) {
		buildQuery += `basePrice {
			amount
			currency
		}`
		arr.push.apply(arr, ['basePriceCurrency', 'basePriceAmount'])
	}
	if (
		updatedColumns.includes('availabilitiesContributionCurrency') ||
		updatedColumns.includes('availabilitiesContributionAmount')
	) {
		buildQuery += `availabilitiesContribution {
			amount
			currency
		}`
		arr.push.apply(arr, ['availabilitiesContributionCurrency', 'availabilitiesContributionAmount'])
	}
	if (updatedColumns.includes('needsContributionCurrency') || updatedColumns.includes('needsContributionAmount')) {
		buildQuery += `needsContribution {
			amount
			currency
		}`
		arr.push.apply(arr, ['needsContributionCurrency', 'needsContributionAmount'])
	}
	if (
		updatedColumns.includes('effectiveBaseNotionalCurrency') ||
		updatedColumns.includes('effectiveBaseNotionalAmount')
	) {
		buildQuery += `effectiveBaseNotional {
			amount
			currency
		}`
		arr.push.apply(arr, ['effectiveBaseNotionalCurrency', 'effectiveBaseNotionalAmount'])
	}
	if (updatedColumns.includes('inefficienciesArray')) {
		buildQuery += `inefficiencies {
			inefficiency
			abbr
			displayName
		}
	`
		arr.push.apply(arr, ['inefficienciesArray'])
	}
	uniqueColumns = uniqueColumns.filter(e => !arr.includes(e))

	return gql`
		query($page: Int!, $size: Int!, $filters: [AbcFilter!]) {
			abcPortfolio(abcPortfolioSearchParams: {page: $page, size: $size, filters: $filters}) {
				entries {
					${uniqueColumns.join(`\n`)}\n${buildQuery}
				}
			}
		}
	`
}

export const getPbAbcPortfolioQuery = () => {
	return gql`
		query ($page: Int!, $size: Int!, $filters: [AbcFilter!]) {
			abcPortfolio(abcPortfolioSearchParams: {page: $page, size: $size, filters: $filters}) {
				entries {
					allInRate
					assetClassification
					assetMaturityDate
					assetSubType
					assetType
					availableQuantity
					baseNotional {
						amount
						currency
					}
					basePrice {
						amount
						currency
					}
					bbId
					book
					borrowFee
					loanFee
					buySellInd
					collateralType
					collateral {
						amount
						currency
					}
					counterParty
					createdBy
					createdDate
					cusip
					effectiveBaseNotional {
						amount
						currency
					}
					endDate
					exchangeRate
					fitchRating
					fund
					haircut
					hqla
					id
					index
					isin
					maturityDate
					maturityType
					moodyRating
					notional {
						amount
						currency
					}
					poolFactor
					portfolioEntryType
					price {
						amount
						currency
					}
					priceMultiplier
					quantity
					referenceRate
					availabilitiesContribution {
						amount
						currency
					}
					needsContribution {
						amount
						currency
					}
					platformTradeId
					rebate
					originalTradeType
					legalEntity
					ric
					sector
					securityId
					securityIdType
					settlementDate
					snpRating
					sourceSystem
					sourceSystemTradeId
					spread
					startDate
					tenor
					termDate
					tradeDate
					ticker
					tradeType
					legalEntity
					originalTradeType
					lcrNotional
					lcrCost
					lcrHaircut
					lcrHqla
				}
				totalElements
				totalPages
			}
		}
	`
}

export const getPbAbcFiltersQuery = (groupKey: any) => {
	if (groupKey === 'groupOne') {
		return gql`
			query ($field: String!) {
				abcPortfolioFiltersValue(field: $field)
			}
		`
	} else {
		return gql`
			query ($field: String!, $filter: [AbcFilter!]) {
				abcPortfolioFiltersValue(field: $field, filter: $filter)
			}
		`
	}
}
