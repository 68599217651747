import React from 'react'
import {Formik} from 'formik'
import * as yup from 'yup'
import {history} from '../../../store/mainStore'
import FsTextField from '../../../common/formItems/fsTextField'
import '../../login/login.scss'
import {forgotPasswordTitle} from '../forgotPasswordConstant'
import {redirectLoginWithTenantCode} from '../../../utils/amplify'
import PasswordInput from '../../../common/PasswordInput'

interface Props {
	onSubmit: Function
}

interface State {}

export default class PasswordResetForm extends React.Component<Props, State> {
	render() {
		const {onSubmit} = this.props

		const validationSchema = yup.object().shape({
			otp: yup
				.string()
				.required('OTP is required')
				.matches(/^([0-9]{6,})$/, 'Must Contain atleast 6 numeric digits'),
			newPassword: yup.string().required('password is required'),
			confirmPassword: yup
				.string()
				.required('password is required')
				.test('match', 'password do not match', function (confirmPassword) {
					return confirmPassword === this.parent.newPassword
				})
				.matches(
					/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
					'Must Contain atleast 8 Characters, and atleast One Uppercase, One Lowercase, One Number and One Special Case Character'
				),
		})

		const removeAtribute = id => {
			const fieldEl = document.getElementById(id)
			if (fieldEl) fieldEl.removeAttribute('readOnly')
		}

		return (
			<Formik
				validationSchema={validationSchema}
				initialValues={{
					otp: '',
					newPassword: '',
					confirmPassword: '',
				}}
				onSubmit={values => {
					values.otp = String(values.otp)
					onSubmit(values)
				}}
			>
				{({values, errors, handleSubmit, handleChange, handleBlur, touched}) => (
					<form onSubmit={handleSubmit}>
						<FsTextField
							id='otp'
							autoComplete='off'
							label='OTP'
							placeholder='Enter OTP'
							type='text'
							className='px-3 override-number-input'
							onChange={handleChange}
							value={values.otp}
							onBlur={handleBlur}
						/>
						<div className={`${!errors.newPassword ? 'form-group' : ''} pb-2`}>
							<label className='grey-10 mb-1'>New Password</label>
							<PasswordInput
								id='newPassword'
								type='password'
								className='form-control'
								placeholder='Enter new password'
								autoComplete='off'
								value={values.newPassword}
								readOnly={true}
								onChange={handleChange}
								onBlur={handleBlur}
								onFocus={() => removeAtribute('newPassword')}
							/>
							{errors.newPassword && touched.newPassword ? (
								<div className='text-danger mt-2 ml-2'>{errors.newPassword}</div>
							) : null}
						</div>
						<div className={`${!errors.confirmPassword ? 'form-group' : ''} pb-2`}>
							<label className='grey-10 mb-1'>Confirm Password</label>
							<PasswordInput
								id='confirmPassword'
								type='password'
								className='form-control'
								placeholder='Confirm new password'
								autoComplete='off'
								value={values.confirmPassword}
								readOnly={true}
								onChange={handleChange}
								onBlur={handleBlur}
								onFocus={() => removeAtribute('confirmPassword')}
							/>
							{errors.confirmPassword && touched.confirmPassword ? (
								<div className='text-danger mt-2 ml-2'>{errors.confirmPassword}</div>
							) : null}
						</div>
						<button
							id='default_FsContainedButton'
							type='submit'
							className='btn btn-secondary btn-xl d-block w-100 mt-4 mb-4'
						>
							{forgotPasswordTitle.passwordReset}
						</button>
						<div className='d-flex'>
							<span
								onClick={() => {
									history && redirectLoginWithTenantCode(history)
								}}
								className='link f-14 d-block text-center w-100 f-16'
							>
								Cancel
							</span>
						</div>
					</form>
				)}
			</Formik>
		)
	}
}

