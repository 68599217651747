export function setObjectInStorage(key: string, obj: any): boolean {
	sessionStorage.setItem(key, JSON.stringify(obj))
	return true
}

//To-do (Memoize wrapper for other get helper functions below)
// AS-7368: Removed memoize as it causing issue in graph plotting
export const getObjectFromStorage = (key: string) => {
	const item = sessionStorage.getItem(key)
	return item ? JSON.parse(item) : null
}

export function setStringInStorage(key: string, str: string): boolean {
	sessionStorage.setItem(key, str)
	return true
}
export function setStringInLocalStorage(key: string, str: string): boolean {
	localStorage.setItem(key, str)
	return true
}
export function getStringFromStorage(key: string): string {
	const item = sessionStorage.getItem(key)
	return item ? item : ''
}

export const removeItemFromStorage = (key: string) => {
	sessionStorage.removeItem(key)
}
