import {createFetchActions} from '../../../services/createActions'
import {get, post} from '../../../services/createAPICall'
import {getPBMSBaseUrl} from '../../../services/apiService'

export const GET_PB_PORTFOLIO_DOCS_INCLUDED = createFetchActions('GET_PB_PORTFOLIO_DOCS_INCLUDED')
export const GET_PB_PORTFOLIO_DOCS_ALL = createFetchActions('GET_PB_PORTFOLIO_DOCS_ALL')
export const UPLOAD_PB_PORTFOLIO_DOCS = createFetchActions('UPLOAD_PB_PORTFOLIO_DOCS')
export const SAVE_PB_INCLUDED_DOCS = createFetchActions('SAVE_PB_INCLUDED_DOCS')
export const GET_PB_PORTFOLIO_UPDATE_DATETIME = createFetchActions('GET_PB_PORTFOLIO_UPDATE_DATETIME')
export const UPLOAD_PB_RESTRICTED_PORTFOLIO = createFetchActions('UPLOAD_PB_RESTRICTED_PORTFOLIO')
export const GET_PB_RESTRICTED_PORTFOLIO = createFetchActions('GET_PB_RESTRICTED_PORTFOLIO')

export const SET_PB_INCLUDED_IDS = 'SET_PB_INCLUDED_IDS'
export const CLEAR_PB_PORTFOLIO_MANAGER_STATE = 'CLEAR_PB_PORTFOLIO_MANAGER_STATE'
export const RESET_PB_INCLUDED_DOCS = 'RESET_PB_INCLUDED_DOCS'
export const SET_PB_PROCESSED_PORTFOLIO_OBJECT = 'SET_PB_PROCESSED_PORTFOLIO_OBJECT'
export const RESET_PB_PROCESSED_PORTFOLIO_OBJECT = 'RESET_PB_PROCESSED_PORTFOLIO_OBJECT'
export const SET_PB_PORTFOLIO_DOCUMENT_DETAILS = 'SET_PB_PORTFOLIO_DOCUMENT_DETAILS'
export const SET_PB_PORTFOLIO_DOCUMENT_STATUS = 'SET_PB_PORTFOLIO_DOCUMENT_STATUS'
export const PF_PB_UPLOAD_ONGOING = 'PF_PB_UPLOAD_ONGOING'

export const getPortfolioDocsIncluded =
	(page: number, size = 10) =>
	(dispatch: any) => {
		return dispatch(get(GET_PB_PORTFOLIO_DOCS_INCLUDED, `${getPBMSBaseUrl()}/v1/portfolios/documents/consolidate`))
	}

export const getPortfolioDocsAll =
	(page: number, size = 10, days: number) =>
	(dispatch: any) => {
		return dispatch(get(GET_PB_PORTFOLIO_DOCS_ALL, `${getPBMSBaseUrl()}/v1/portfolios/documents/today?days=${days}`))
	}

export const saveIncludedDocs = (ids: string[]) => (dispatch: any) => {
	return dispatch(post(SAVE_PB_INCLUDED_DOCS, `${getPBMSBaseUrl()}/v1/portfolios/documents/consolidate`, ids))
}

export const uploadPortfolioDocs = (data: any) => (dispatch: any) => {
	const headers = {
		'Content-Type': 'multipart/form-data',
	}
	return dispatch(post(UPLOAD_PB_PORTFOLIO_DOCS, `${getPBMSBaseUrl()}/v1/portfolios/documents/upload`, data, headers))
}

export const uploadPortfolioDocsCheck = (data: any) => (dispatch: any) => {
	const headers = {
		'Content-Type': data.headers !== 'json' ? 'multipart/form-data' : 'application/json',
	}
	return dispatch(post(UPLOAD_PB_PORTFOLIO_DOCS, `${getPBMSBaseUrl()}/etl`, data.data, headers))
}

export const uploadRestrictedPortfolio = (data: any) => (dispatch: any) => {
	const headers = {
		'Content-Type': 'multipart/form-data',
	}
	return dispatch(
		post(UPLOAD_PB_RESTRICTED_PORTFOLIO, `${getPBMSBaseUrl()}/v1/security/restricted-documents/upload`, data, headers)
	)
}

export const getRestrictedPortfolioDetails = () => (dispatch: any) => {
	return dispatch(get(GET_PB_RESTRICTED_PORTFOLIO, `${getPBMSBaseUrl()}/v1/security/restricted-documents/today`))
}

export const getPortfolioUpdateDateTime = () => (dispatch: any) => {
	return dispatch(get(GET_PB_PORTFOLIO_UPDATE_DATETIME, `${getPBMSBaseUrl()}/v1/portfolios?page=0&size=1`))
}

export const clearPortfolioManagerState = () => {
	return {
		type: CLEAR_PB_PORTFOLIO_MANAGER_STATE,
	}
}

export const setProcessedPortfolioObject = (data: any) => {
	return {
		type: SET_PB_PROCESSED_PORTFOLIO_OBJECT,
		payload: {
			data: data,
		},
	}
}

export const resetProcessedPortfolioObject = () => {
	return {
		type: RESET_PB_PROCESSED_PORTFOLIO_OBJECT,
	}
}

export const setPortfolioDocumentStatus = (id: string, status: string) => {
	return {
		type: SET_PB_PORTFOLIO_DOCUMENT_STATUS,
		payload: {
			id: id,
			status: status,
		},
	}
}

export const pfUploadOngoing = (uploading: boolean) => {
	return {
		type: PF_PB_UPLOAD_ONGOING,
		payload: {
			uploading,
		},
	}
}
