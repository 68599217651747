import * as axios from 'axios'
import {ObjectUtil} from 'helper-util'

import {IAction} from './createActions'
import {CALL_API} from '../middleware/apiMiddleware'
import {executeAPI} from '../middleware/apiMiddleware'
import {getAuthToken} from '../services/authService'

function getRequestObject(
	method: string,
	actions: IAction,
	url: string,
	data: any,
	headers: any,
	errorMessage: any,
	successMessage: any,
	params: any,
	responseType = '',
	addAccessToken = true
) {
	return executeAPI(
		{
			[CALL_API]: {
				types: actions,
				url: url,
				method: method,
				data: data,
				headers: headers,
				responseType: responseType,
			},
			params,
			actionData: {errorMessage, successMessage},
		},
		addAccessToken
	)
}

function get(
	actions: IAction,
	url: string,
	data?: any,
	headers?: any,
	errorMessage?: string,
	successMessage?: string,
	params?: string,
	responseType?: string
) {
	return getRequestObject('GET', actions, url, data, headers, errorMessage, successMessage, params, responseType)
}

function post(
	actions: IAction,
	url: string,
	data?: any,
	headers?: any,
	errorMessage?: string,
	successMessage?: string,
	params?: string,
	responseType?: string
) {
	return getRequestObject('POST', actions, url, data, headers, errorMessage, successMessage, params, responseType)
}

function postFile(
	actions: IAction,
	url: string,
	data?: any,
	headers?: any,
	errorMessage?: string,
	successMessage?: string,
	params?: string,
	responseType?: string
) {
	return getRequestObject(
		'POST',
		actions,
		url,
		data,
		headers,
		errorMessage,
		successMessage,
		params,
		responseType,
		false
	)
}

function put(
	actions: IAction,
	url: string,
	data?: any,
	headers?: any,
	errorMessage?: string,
	successMessage?: string,
	params?: string,
	responseType?: string
) {
	return getRequestObject('PUT', actions, url, data, headers, errorMessage, successMessage, params, responseType)
}

function patch(
	actions: IAction,
	url: string,
	data?: any,
	headers?: any,
	errorMessage?: string,
	successMessage?: string,
	params?: string,
	responseType?: string
) {
	return getRequestObject('PATCH', actions, url, data, headers, errorMessage, successMessage, params, responseType)
}

function del(
	actions: IAction,
	url: string,
	data?: any,
	headers?: any,
	errorMessage?: string,
	successMessage?: string,
	params?: string,
	responseType?: string
) {
	return getRequestObject('DELETE', actions, url, data, headers, errorMessage, successMessage, params, responseType)
}

export const graphQlWithAxios = (
	dispatch: any,
	type: any,
	baseURL: string,
	query: any,
	resultCallBack: any,
	returnResponse?: boolean
) => {
	dispatch && dispatch({type: type.triggered})

	const instance = axios.default.create({
		baseURL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			Authorization: `Bearer ${getAuthToken()}`,
		},
	})

	const instanceQuery = ObjectUtil.isObject(query)
		? query
		: JSON.stringify({
				query: query,
		  })

	if (returnResponse) return instance.post('', instanceQuery).then(resultCallBack => resultCallBack)
	return instance.post('', instanceQuery).then(resultCallBack)
}

export {get, post, postFile, put, patch, del}
