import Lodash from 'lodash'
import {GET_OPTIMIZATION_WIDGETS} from './collateralOptLayoutAction'

export interface CollateralOptimizationNewDashboard {
	loading: boolean
	collateralOptimizationWidgetsData: any
}

const initialState: CollateralOptimizationNewDashboard = {
	loading: false,
	collateralOptimizationWidgetsData: {},
}

export function CollateralOptimizationNewDashboardReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_OPTIMIZATION_WIDGETS.triggered:
			return {...state, loading: true}
		case GET_OPTIMIZATION_WIDGETS.succeeded:
			const collateralOptimizationWidgetsData = Lodash.cloneDeep(action.response.data)
			return {...state, collateralOptimizationWidgetsData, loading: false}
		case GET_OPTIMIZATION_WIDGETS.failed:
			return {...state, loading: false}
	}

	return state
}
