import {createFetchActions} from '../../../services/createActions'
import {get, post, put, del} from '../../../services/createAPICall'
import {getConfigBaseUrl} from '../../../services/apiService'
import {PrivilegesObj} from './usersListModel'
import {getCounterpartyId} from '../../../services/authService'

export const GET_ACTIVE_USERS = createFetchActions('GET_ACTIVE_USERS')
export const GET_DRAFT_USERS = createFetchActions('GET_DRAFT_USERS')
export const GET_USER_DETAILS = createFetchActions('GET_USER_DETAILS')
export const GET_GROUPS = createFetchActions('GET_GROUPS')
export const GET_SELECTED_GROUP = createFetchActions('GET_SELECTED_GROUP')
export const GET_CLIENTS = createFetchActions('GET_CLIENTS')
export const GET_SELECTED_CLIENT = createFetchActions('GET_SELECTED_CLIENT')
export const SAVE_USER = createFetchActions('SAVE_USER')
export const EDIT_USER = createFetchActions('EDIT_USER')
export const ACTIVATE_USER = createFetchActions('ACTIVATE_USER')
export const DEACTIVATE_USER = createFetchActions('DEACTIVATE_USER')
export const DELETE_USER = createFetchActions('DELETE_USER')
export const CLEAR_PRIVILEGES_LIST = createFetchActions('CLEAR_PRIVILEGES_LIST')
export const CLEAR_USER_DETAILS = createFetchActions('CLEAR_USER_DETAILS')
export const SET_MODIFIED_PRIVILEGES = createFetchActions('SET_MODIFIED_PRIVILEGES')

export const getActiveUsers = () => (dispatch: any) => {
	return dispatch(get(GET_ACTIVE_USERS, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/users`))
}
export const getDraftUsers = () => (dispatch: any) => {
	return dispatch(get(GET_DRAFT_USERS, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/draft/users`))
}

export const getUserDetails = userId => (dispatch: any) => {
	return dispatch(
		get(GET_USER_DETAILS, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/users/${userId}`)
	)
}

export const getAllGroups = () => (dispatch: any) => {
	return dispatch(get(GET_GROUPS, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/groups/`))
}

export const getGroupDetails = (groupId: string) => (dispatch: any) => {
	return dispatch(
		get(GET_SELECTED_GROUP, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/groups/${groupId}`)
	)
}

export const getAllClients = () => (dispatch: any) => {
	return dispatch(get(GET_CLIENTS, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/clients/`))
}

export const getClientDetails = (clientId: string) => (dispatch: any) => {
	return dispatch(
		get(GET_SELECTED_CLIENT, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/clients/${clientId}`)
	)
}

export const saveUser = (data: any) => (dispatch: any) => {
	return dispatch(post(SAVE_USER, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/users/`, data))
}

export const editUser = (data: any) => (dispatch: any) => {
	return dispatch(
		put(EDIT_USER, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/users/${data.userId}`, data)
	)
}

export const activateUser = (data: any) => (dispatch: any) => {
	return dispatch(
		put(
			ACTIVATE_USER,
			`${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/users/enable/${data.userId}`,
			data
		)
	)
}

export const deactivateUser = (data: any) => (dispatch: any) => {
	return dispatch(
		put(
			DEACTIVATE_USER,
			`${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/users/disable/${data.userId}`,
			data
		)
	)
}
export const deleteUser = (data: any) => (dispatch: any) => {
	return dispatch(
		del(DELETE_USER, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/users/${data.userId}`, data)
	)
}

export const clearPrivilegesList = () => {
	return {
		type: CLEAR_PRIVILEGES_LIST,
	}
}

export const clearUserDetails = () => {
	return {
		type: CLEAR_USER_DETAILS,
	}
}

export const setModifiedPrivileges = (modifiedPrivileges: PrivilegesObj) => {
	return {
		type: SET_MODIFIED_PRIVILEGES,
		payload: modifiedPrivileges,
	}
}
