import {getLocateClient, getPMSClient} from '../../..'
import {createFetchActions} from '../../../services/createActions'
import {fetchAvailNeedCounterpartyDetail, fetchSecurityInventory} from './LocateInventoryMgmtQueries'
import {post} from '../../../services/createAPICall'
import {getPMSBaseUrl} from '../../../services/apiService'

export const FETCH_SECURITY_INVENTORY = createFetchActions('FETCH_SECURITY_INVENTORY')
export const FETCH_AVAILABLE_NEED_COUNTERPARTY_DETAILS = createFetchActions('FETCH_AVAILABLE_NEED_COUNTERPARTY_DETAILS')
export const FETCH_EXTERNAL_AVAILABILITIES = createFetchActions('FETCH_EXTERNAL_AVAILABILITIES')
export const ADD_AVAILABILITY_FROM_LOCATE_INVENTORY = createFetchActions('ADD_AVAILABILITY_FROM_LOCATE_INVENTORY')
export const CLEAR_MAIN_GRID_DATA = createFetchActions('CLEAR_MAIN_GRID_DATA')

export const getSecurityInventory = () => {
	const query = fetchSecurityInventory()
	return function (dispatch) {
		dispatch({
			type: FETCH_SECURITY_INVENTORY.triggered,
		})
		getLocateClient()
			.query({
				query,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: FETCH_SECURITY_INVENTORY.succeeded,
					response,
				})
			})
	}
}

export const getAvailNeedCounterpartyDetail = securityId => {
	const query = fetchAvailNeedCounterpartyDetail(securityId)
	return function (dispatch) {
		dispatch({
			type: FETCH_AVAILABLE_NEED_COUNTERPARTY_DETAILS.triggered,
		})
		getPMSClient()
			.query({
				query,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: FETCH_AVAILABLE_NEED_COUNTERPARTY_DETAILS.succeeded,
					response,
				})
			})
	}
}

export const addAvailabilityFromLocate = req => (dispatch: any) => {
	return dispatch(
		post(ADD_AVAILABILITY_FROM_LOCATE_INVENTORY, `${getPMSBaseUrl()}/v1/availabilities-needs/add-availability`, req)
	)
}

export const clearGridData = () => {
	return {
		type: CLEAR_MAIN_GRID_DATA,
	}
}
