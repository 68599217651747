import {createFetchActions} from '../../services/createActions'
import {post} from '../../services/createAPICall'
import {getPMSBaseUrl} from '../../services/apiService'
export const GET_MKT_LAYOUTS = createFetchActions('GET_MKT_LAYOUTS')
export const SAVE_MKT_LAYOUTS = createFetchActions('SAVE_MKT_LAYOUTS')
export const GET_TABLE_DATA = createFetchActions('GET_TABLE_DATA')
export const DELETE_LAYOUTS = createFetchActions('DELETE_LAYOUTS')

export const getLayouts = user => (dispatch: any) => {
	return dispatch(
		post(GET_MKT_LAYOUTS, `${getPMSBaseUrl()}/getfmcfg`, user, null, 'Error occured while getting market data layouts')
	)
}

export const saveLayouts = data => (dispatch: any) => {
	return dispatch(
		post(SAVE_MKT_LAYOUTS, `${getPMSBaseUrl()}/savefmcfg`, data, null, 'Error occured while saving market data layouts')
	)
}

export const getTableData = () => (dispatch: any) => {
	return dispatch(
		post(GET_TABLE_DATA, `${getPMSBaseUrl()}/gettabledata`, {}, null, 'Error occured while saving market data tables')
	)
}

export const deleteLayouts = user => (dispatch: any) => {
	return dispatch(
		post(
			DELETE_LAYOUTS,
			`${getPMSBaseUrl()}/deletefmcfg`,
			user,
			null,
			'Error occured while deleting market data tables'
		)
	)
}
