import {getALPBaseUrl, getConfigBaseUrl} from '../../../services/apiService'
import {getCounterpartyId} from '../../../services/authService'
import {createFetchActions} from '../../../services/createActions'
import {get, post} from '../../../services/createAPICall'

export const FETCH_ALP_AUTHORIZER_DETAILS = createFetchActions('FETCH_ALP_AUTHORIZER_DETAILS')
export const FETCH_ALL_SECURITIES = createFetchActions('FETCH_ALL_SECURITIES')
export const FETCH_ALL_COLLATERAL_TYPES = createFetchActions('FETCH_ALL_COLLATERAL_TYPES')
export const FETCH_ALL_BORROWERS = createFetchActions('FETCH_ALL_BORROWERS')
export const FETCH_BORROWERS_SUB_ACCOUNT = createFetchActions('FETCH_BORROWERS_SUB_ACCOUNT')
export const FETCH_LOCATE_INVENTORY = createFetchActions('FETCH_LOCATE_INVENTORY')
export const FETCH_ADDITIONAL_SECURITY_DETAILS = createFetchActions('FETCH_ADDITIONAL_SECURITY_DETAILS')
export const SET_INPUT_PANEL_REQUEST_DATA = createFetchActions('SET_INPUT_PANEL_REQUEST_DATA')
export const SET_SOURCE = createFetchActions('SET_SOURCE')
export const ON_LOCATE_INVENTORY = createFetchActions('ON_LOCATE_INVENTORY')
export const ON_CREATE_BORROW = createFetchActions('ON_CREATE_BORROW')
export const ON_BASKET_ROW_UPDATE = createFetchActions('ON_BASKET_ROW_UPDATE')
export const ON_BASKET_RADIO_SELECT = createFetchActions('ON_BASKET_RADIO_SELECT')
export const ON_SINGLE_STOCK_ROW_UPDATE = createFetchActions('ON_SINGLE_STOCK_ROW_UPDATE')
export const CLEAR_MAIN_GRID_DATA = createFetchActions('CLEAR_MAIN_GRID_DATA')
export const CLEAR_BASKET_GRID_DATA = createFetchActions('CLEAR_BASKET_GRID_DATA')
export const ON_CLEAR_ALL = createFetchActions('ON_CLEAR_ALL')
export const DUMMY_ACTIONS = createFetchActions('DUMMY_ACTIONS')
export const CLEAR_MASTER_SECURITIES = createFetchActions('CLEAR_MASTER_SECURITIES')
export const TOGGLE_ENTER_RATE = createFetchActions('TOGGLE_ENTER_RATE')
export const TOGGLE_PARTIAL = createFetchActions('TOGGLE_PARTIAL')
export const TOGGLE_LOCATE_MODE = createFetchActions('TOGGLE_LOCATE_MODE')
export const SET_SECURITY_DETAILS = createFetchActions('SET_SECURITY_DETAILS')
export const SET_BASKET_BULK_SECURITY_DETAILS = createFetchActions('SET_BASKET_BULK_SECURITY_DETAILS')

export const getAlpSttAuthorizerDetails = () => (dispatch: any) => {
	return dispatch(get(FETCH_ALP_AUTHORIZER_DETAILS, `${getALPBaseUrl()}/v1/alp/targeted-inventory`))
}

export const fetchAllSecurities = () => (dispatch: any) => {
	return dispatch(get(FETCH_ALL_SECURITIES, `${getALPBaseUrl()}/v1/alp/security-master`))
}

export const fetchAllCollateralTypes = () => (dispatch: any) => {
	return dispatch(get(FETCH_ALL_COLLATERAL_TYPES, `${getALPBaseUrl()}/v1/alp/collateral-type-map`))
}

export const fetchAllBorrowers = () => (dispatch: any) => {
	return dispatch(
		get(
			FETCH_ALL_BORROWERS,
			`${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/clients?agreementTypes=AGENCYLENDING`
		)
	)
}

export const fetchBorrowersSubAccount = () => (dispatch: any) => {
	return dispatch(
		get(FETCH_BORROWERS_SUB_ACCOUNT, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/client-group`)
	)
}

export const fetchAdditionalSecurityDetails = payload => (dispatch: any) => {
	return dispatch(
		post(FETCH_ADDITIONAL_SECURITY_DETAILS, `${getALPBaseUrl()}/v1/alp/search-targeted-inventory`, payload)
	)
}

export const locateInventory = payload => (dispatch: any) => {
	return dispatch(post(DUMMY_ACTIONS, `${getALPBaseUrl()}/v1/alp/locate-targeted-inventory`, payload)).then(res => {
		if (!!res && res.response && res.response.status === 200) {
			dispatch({
				type: ON_LOCATE_INVENTORY,
				payload: res.response.data,
				inputPayload: payload,
			})
		}
	})
}

export const fetchLocateInventoryByBatchId = (batchId: string) => (dispatch: any) => {
	return dispatch(get(FETCH_LOCATE_INVENTORY, `${getALPBaseUrl()}/v1/alp/locate-targeted-inventory/${batchId}`))
}

export const onCreateBorrow = payload => (dispatch: any) => {
	return dispatch(post(ON_CREATE_BORROW, `${getALPBaseUrl()}/v1/alp/trades`, payload))
}

export const setInputPanelRequestData = requestData => {
	return {
		type: SET_INPUT_PANEL_REQUEST_DATA,
		payload: requestData,
	}
}

export const setSource = selectedSource => {
	return {
		type: SET_SOURCE,
		payload: selectedSource,
	}
}

export const onBasketRowUpdate = (groupIndex, index, action, data?) => {
	return {
		type: ON_BASKET_ROW_UPDATE,
		payload: {
			groupIndex,
			index,
			action,
			data,
		},
	}
}

export const onBasketRadioSelect = (groupIndex, index) => {
	return {
		type: ON_BASKET_RADIO_SELECT,
		payload: {
			groupIndex,
			index,
		},
	}
}

export const onSingleStockRowUpdate = (index, action, data?) => {
	return {
		type: ON_SINGLE_STOCK_ROW_UPDATE,
		payload: {
			index,
			action,
			data,
		},
	}
}

export const setSecurityDetails = (groupIndex, index, action, securityDetails: any) => {
	return {
		type: SET_SECURITY_DETAILS,
		payload: {
			groupIndex,
			index,
			action,
			data: {
				field: 'security',
				...securityDetails,
			},
		},
	}
}
export const setBasketBulSecurityDetails = (uploadedSecurityDetails: any, isPartial) => {
	return {
		type: SET_BASKET_BULK_SECURITY_DETAILS,
		payload: {
			securities: uploadedSecurityDetails,
			isPartial,
		},
	}
}

export const toggleEnterRate = () => {
	return {
		type: TOGGLE_ENTER_RATE,
	}
}

export const togglePartial = () => {
	return {
		type: TOGGLE_PARTIAL,
	}
}

export const toggleLocateMode = () => {
	return {
		type: TOGGLE_LOCATE_MODE,
	}
}

export const clearGridData = () => {
	return {
		type: CLEAR_MAIN_GRID_DATA,
	}
}
export const clearBasketGridData = () => {
	return {
		type: CLEAR_BASKET_GRID_DATA,
	}
}

export const onClearAll = (requestType: string, isInputSelectionClearRequired = false) => {
	return {
		type: ON_CLEAR_ALL,
		requestType,
		isInputSelectionClearRequired,
	}
}

export const clearMasterSecurities = () => {
	return {
		type: CLEAR_MASTER_SECURITIES,
	}
}
