import {GET_P2P_WIDGETS, SAVE_P2P_WIDGETS, DELETE_P2P_WIDGETS, EMPTY_P2P_WIDGETS} from './WidgetDropDownActions'

export interface WidgetDropDown {
	loading: boolean
	widgetsList: any
}

const initialState: WidgetDropDown = {
	loading: false,
	widgetsList: [],
}

export function WidgetDropDownReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_P2P_WIDGETS.triggered:
			return {...state, loading: true}
		case GET_P2P_WIDGETS.failed:
			return {...state, loading: false}
		case GET_P2P_WIDGETS.succeeded:
			const widgetsList = action.response.data
			return {...state, widgetsList, loading: false}

		case SAVE_P2P_WIDGETS.triggered:
			return {...state, loading: true}
		case SAVE_P2P_WIDGETS.failed:
			return {...state, loading: false}
		case SAVE_P2P_WIDGETS.succeeded:
			return {...state, loading: false}

		case DELETE_P2P_WIDGETS.triggered:
			return {...state, loading: true}
		case DELETE_P2P_WIDGETS.failed:
			return {...state, loading: false}
		case DELETE_P2P_WIDGETS.succeeded:
			return {...state, loading: false}

		case EMPTY_P2P_WIDGETS:
			return {...state, widgetsList: []}
	}
	return state
}
