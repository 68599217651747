/**
 * Dropzone: Reusable
 *
 * Features:
 * Create a section / div where file/s can be dropped
 * Files can be selected by clicking on the section
 * Selected file objects will be returned to parent component
 */

import * as React from 'react'
import {useDropzone} from 'react-dropzone'
import {UploadIcon} from '../styles/svg/svgIcons'
import {CSVLink} from 'react-csv'
import {ObjectUtil} from 'helper-util'

interface Props {
	id?: string
	multiple?: boolean
	disabled?: boolean
	fileUploadText?: string
	fileDownload?: any
	onDropOrSelect: (files: any) => void
	isWritePermission?: boolean
	capitalizeEachWord?: boolean
}

export default function FsUploadButton(props: Props) {
	const {
		id = 'default_FsUploadButton',
		multiple = false,
		onDropOrSelect = () => {},
		disabled = false,
		fileUploadText = 'Upload your portfolio',
		fileDownload,
		isWritePermission = true,
		capitalizeEachWord,
	} = props

	const onDrop = React.useCallback(
		acceptedFiles => {
			onDropOrSelect(acceptedFiles)
		},
		[onDropOrSelect]
	)

	const {getRootProps, getInputProps, isDragActive} = useDropzone({
		onDrop,
		multiple,
		disabled,
	})

	return (
		<div className={isDragActive ? 'portfolio-upload-wrapper gradient-border' : 'portfolio-upload-wrapper'}>
			<div className='portfolio-upload'>
				<div
					{...getRootProps()}
					className={`outline-none d-flex w-100 h-100 align-items-center
						${isWritePermission && 'cursor-pointer'}`}
				>
					<input id={id} {...getInputProps()} />
					{isDragActive ? (
						<div className='media m-auto'>
							<div className='media-left'>
								<UploadIcon></UploadIcon>
							</div>
							<div className='media-right'>
								<span className='d-block f-20 font-weight-600 text-grey-5'>{fileUploadText}</span>
								<span className='d-block f-16 text-grey-5'>
									Drag &amp; drop your file here or {/* eslint-disable-next-line */}
									<a href={isWritePermission && '#'} className={`font-weight-600 ${isWritePermission && 'link'}`}>
										browse file
									</a>
								</span>
							</div>
						</div>
					) : (
						<div className='media m-auto'>
							<div className='media-left'>
								<UploadIcon></UploadIcon>
							</div>
							<div className='media-right'>
								<span className='d-block f-24 font-weight-600 text-grey-5'>{fileUploadText}</span>
								{capitalizeEachWord ? (
									<span className='d-block f-16 text-grey-5'>
										Drag &amp; Drop File Here or {/* eslint-disable-next-line */}
										<a href={isWritePermission && '#'} className={`font-weight-600 ${isWritePermission && 'link'}`}>
											Browse Files
										</a>
									</span>
								) : (
									<span className='d-block f-16 text-grey-5'>
										Drag &amp; drop your file here or {/* eslint-disable-next-line */}
										<a href={isWritePermission && '#'} className={`font-weight-600 ${isWritePermission && 'link'}`}>
											browse file
										</a>
									</span>
								)}
								{!ObjectUtil.isEmpty(fileDownload) && (
									<span className='font-weight-600 link d-flex justify-content-end'>
										<CSVLink
											headers={fileDownload.headers}
											data={fileDownload.data}
											filename={`${fileDownload.filename}.csv`}
											target='_blank'
											className={fileDownload.className}
										>
											<span
												onClick={e => {
													e.stopPropagation()
												}}
											>
												{fileDownload.buttonTextContent}
											</span>
										</CSVLink>
									</span>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
