import gql from 'graphql-tag'

const dummmyWidgetQuery = () => gql`
	{
		widget {
			repos {
				notional {
					amount
					currency
				}
				count
			}
			reverse {
				notional {
					amount
					currency
				}
				count
			}
			box {
				notional {
					amount
					currency
				}
				count
			}
			open {
				notional {
					amount
					currency
				}
				count
			}
			trades {
				notional {
					amount
					currency
				}
				count
			}
			gross {
				amount
				currency
			}
		}
	}
`
export {dummmyWidgetQuery}
