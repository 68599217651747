import {connect} from 'react-redux'
import {MainState} from '../../store/mainReducer'
import {bindActionCreators} from 'redux'
import CustomiseMenu from './customiseMenuComponent'
import {getAllMenus, resetMenuData, updateMenu, createMenu, deleteMenu} from './customiseMenuActions'

const mapStateToProps = (state: MainState) => {
	return {
		loading: state.customiseMenu.loading,
		allMenuData: state.customiseMenu.allMenuData,
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return bindActionCreators(
		{
			getAllMenus,
			updateMenu,
			createMenu,
			deleteMenu,
			resetMenuData,
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomiseMenu)
