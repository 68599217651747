import {HTTP_ERROR, ERROR_RESET, GRAPHQL_ERROR} from './errorHandlerActions'

export interface ErrorHandler {
	showErrorModal: boolean
	errorMessage: string
	errorCode: string
	isRedirect: boolean
}

const initialState: ErrorHandler = {
	showErrorModal: false,
	errorMessage: '',
	errorCode: '',
	isRedirect: false,
}

export function errorReducer(state = initialState, action) {
	switch (action.type) {
		case HTTP_ERROR:
			return {...state, errorMessage: action.props.msg, errorCode: action.props.errorCode}
		case ERROR_RESET:
			return {state: initialState}
		case GRAPHQL_ERROR:
			return {...state, errorMessage: action.props.msg, errorCode: action.props.errorCode, isRedirect: true}
		default:
			return state
	}
}
