import {GET_COUNTERPARTY_HAIRCUT_DATA} from './CounterpartyHaircutActions'

export interface CounterpartyHaircut {
	haircutDataAndColumns: any
	haircutDataLoading: boolean
}

const initialState: CounterpartyHaircut = {
	haircutDataAndColumns: [],
	haircutDataLoading: false,
}

export function CounterpartyHaircutReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_COUNTERPARTY_HAIRCUT_DATA.triggered:
			return {...state, haircutDataAndColumns: [], haircutDataLoading: true}
		case GET_COUNTERPARTY_HAIRCUT_DATA.failed:
			return {...state, haircutDataAndColumns: [], haircutDataLoading: false}

		case GET_COUNTERPARTY_HAIRCUT_DATA.succeeded:
			const haircutDataAndColumns = action.response.data
			return {
				...state,
				haircutDataAndColumns,
				haircutDataLoading: false,
			}
	}
	return state
}
