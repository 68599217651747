import {createFetchActions} from '../../services/createActions'
import {post} from '../../services/createAPICall'
import {getPMSBaseUrl} from '../../services/apiService'
import {getMPSClient, getPMSClient} from '../..'
import {securitySuggestionsQuery, getBasketProgressDataQuery} from './cbNegotiationQueries'
import {enrichedSecuritySuggestionsQuery} from './cbNegotiationQueries'
import {getCollateralBasketsQuery} from './cbNegotiationQueries'
import {QueryConfig} from '../collateralBasketList/collateralBasket/collateralBasketModel'

export const GET_COLLATERAL_BASKETS = createFetchActions('GET_COLLATERAL_BASKETS')
export const GET_BASKET_PROGRESS_DATA = createFetchActions('GET_BASKET_PROGRESS_DATA')
export const ATTACH_COLLATERAL_BASKET = createFetchActions('ATTACH_COLLATERAL_BASKET')
export const ACCEPT_COLLATERAL_BASKET = createFetchActions('ACCEPT_COLLATERAL_BASKET')
export const NEGOTIATE_COLLATERAL_BASKET = createFetchActions('NEGOTIATE_COLLATERAL_BASKET')
export const GET_SECURITY_SUGGESTIONS = createFetchActions('GET_SECURITY_SUGGESTIONS')
export const UPDATE_COLLATERAL_BASKET = createFetchActions('UPDATE_COLLATERAL_BASKET')

export const getBasketProgressData = (orderId: string, isScheduleAllowed) => {
	return function (dispatch) {
		dispatch({type: GET_BASKET_PROGRESS_DATA.triggered})
		getMPSClient()
			.query({
				query: getBasketProgressDataQuery(orderId, isScheduleAllowed),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_BASKET_PROGRESS_DATA.succeeded,
					response,
				})
			})
	}
}

export const getCollateralBaskets = () => {
	return function (dispatch) {
		dispatch({type: GET_COLLATERAL_BASKETS.triggered})
		getPMSClient()
			.query({
				query: getCollateralBasketsQuery(false),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_COLLATERAL_BASKETS.succeeded,
					response,
				})
			})
	}
}

export const getSecuritySuggestions = (keyword: string, config: QueryConfig) => {
	return function (dispatch) {
		getPMSClient()
			.query({
				query: securitySuggestionsQuery(keyword, config),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_SECURITY_SUGGESTIONS.succeeded,
					response: response.data && response.data.securitiesWithConstraints,
				})
			})
	}
}

export const getEnrichedSecuritySuggestions = (keyword: string, config: QueryConfig) => {
	return function (dispatch) {
		getPMSClient()
			.query({
				query: enrichedSecuritySuggestionsQuery(keyword, config),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_SECURITY_SUGGESTIONS.succeeded,
					response: response.data && response.data.enrichedSecuritiesWithConstraints,
				})
			})
	}
}

export const attachCollateralBasket = (collateralBasketId, orderId) => (dispatch: any) => {
	const headers = {
		'Content-Type': 'application/json;charset=UTF-8',
	}
	return dispatch(
		post(
			ATTACH_COLLATERAL_BASKET,
			`${getPMSBaseUrl()}/v1/orders/${orderId}/collateral-baskets/${collateralBasketId}/`,
			{
				action: 'ATTACH',
			},
			headers
		)
	)
}

export const acceptCollateralBasket = (collateralBasketId, orderId) => (dispatch: any) => {
	return dispatch(
		post(
			ACCEPT_COLLATERAL_BASKET,
			`${getPMSBaseUrl()}/v1/orders/${orderId}/collateral-baskets/${collateralBasketId}/`,
			{
				action: 'ACCEPT',
			}
		)
	)
}

export const negotiateCollateralBasket = (collateralBasketId, orderId, payload) => (dispatch: any) => {
	return dispatch(
		post(
			NEGOTIATE_COLLATERAL_BASKET,
			`${getPMSBaseUrl()}/v1/orders/${orderId}/collateral-baskets/${collateralBasketId}/`,
			{
				action: 'NEGOTIATE',
				rejectedSecurities: payload,
			}
		)
	)
}

export const updateCollateralBasket = (collateralBasketId, orderId, payload) => (dispatch: any) => {
	return dispatch(
		post(
			UPDATE_COLLATERAL_BASKET,
			`${getPMSBaseUrl()}/v1/orders/${orderId}/collateral-baskets/${collateralBasketId}/`,
			{
				action: 'NEGOTIATE',
				addedSecurities: payload,
			}
		)
	)
}
