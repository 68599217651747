import {SAVE_MY_FUNDS, EDIT_MY_FUNDS, CLEAR_FUNDS_DATA} from './myFundsActions'
import {MyFunds} from './myFundsModel'

export interface MyFundsData {
	loading: boolean
	data: MyFunds[]
}

const initialState: MyFundsData = {
	loading: false,
	data: [],
}

export function myFundsReducer(state = initialState, action: any) {
	switch (action.type) {
		case SAVE_MY_FUNDS.triggered:
			return {...state, loading: true}
		case SAVE_MY_FUNDS.succeeded:
		case SAVE_MY_FUNDS.failed:
			return {...state, loading: false}

		case EDIT_MY_FUNDS.triggered:
			return {...state, loading: true}
		case EDIT_MY_FUNDS.succeeded:
		case EDIT_MY_FUNDS.failed:
			return {...state, loading: false}

		case CLEAR_FUNDS_DATA:
			return {...state, data: null}
	}

	return state
}
