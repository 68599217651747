import {tradeDetailsQuery, sblTradeDetailsQuery, repoTradeDetailsQuery} from './tradeDetailsQueries'
import {createFetchActions} from '../../services/createActions'
import {getPMSBaseUrl, getPMSGraphBaseUrl} from '../../services/apiService'
import {post} from '../../services/createAPICall'
import * as axios from 'axios'
import {getAuthToken} from '../../services/authService'

export const RESET_ALL_TRADES = 'RESET_ALL_TRADES'
export const GET_ALL_TRADES = createFetchActions('GET_ALL_TRADES')
export const EXECUTE_TRADE_ACTION = createFetchActions('EXECUTE_TRADE_ACTION')
export const SET_TRADE_DETAILS_GROUP_FILTERS = 'SET_TRADE_DETAILS_GROUP_FILTERS'
export const SET_TRADE_DETAILS_SELECTED_VIEW = 'SET_TRADE_DETAILS_SELECTED_VIEW'

export const setTradeDetailsGroupFilters = data => {
	return {
		type: SET_TRADE_DETAILS_GROUP_FILTERS,
		response: {
			data,
		},
	}
}

export const setTradeDetailsSelectedView = data => {
	return {
		type: SET_TRADE_DETAILS_SELECTED_VIEW,
		response: {
			data,
		},
	}
}

export const resetAllTrades = () => {
	return {type: RESET_ALL_TRADES}
}

export const getAllTrades = (viewList: any, dashboardType: any) => {
	let query = null
	if (dashboardType === 'SBL') {
		query = sblTradeDetailsQuery[viewList.id]
	} else if (dashboardType === 'REPO') {
		query = repoTradeDetailsQuery[viewList.id]
	} else {
		query = tradeDetailsQuery[viewList.id]
	}
	return function (dispatch) {
		dispatch({type: GET_ALL_TRADES.triggered})
		const instance = axios.default.create({
			baseURL: `${getPMSGraphBaseUrl()}`,
			timeout: 1000000,
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${getAuthToken()}`,
			},
		})

		instance.post('', query).then(data => {
			dispatch({
				type: GET_ALL_TRADES.succeeded,
				response: data.data,
			})
		})
	}
}

export const executeTradeAction = (req, action) => (dispatch: any) => {
	return dispatch(post(EXECUTE_TRADE_ACTION, `${getPMSBaseUrl()}/v1/portfolios/${action}`, req))
}
