import {createFetchActions} from '../../../services/createActions'
import {getMPSBaseUrl} from '../../../services/apiService'
import {get} from '../../../services/createAPICall'

export const SOD_FILE_DETAILS = createFetchActions('SOD_FILE_DETAILS')

export const getSODFileDetails = (productType, productSubType) => (dispatch: any) => {
	return dispatch(
		get(SOD_FILE_DETAILS, `${getMPSBaseUrl()}/v1/margin-analytics/trades/sodTrade/${productType}/${productSubType}`)
	)
}

export const updateSODFileDetails = () => {
	return {
		type: SOD_FILE_DETAILS.triggered,
		response: {},
	}
}

