import {getPMSClient} from '../..'
import {createFetchActions} from '../../services/createActions'
import {getTradingQueueDataQuery} from './tradingQueueQueries'
import {activityLogQuery} from './activityLog/activityLogQueries'
import {post, put} from '../../services/createAPICall'
import {getPMSBaseUrl} from '../../services/apiService'

export const GET_TRADING_QUEUE_DATA = createFetchActions('GET_TRADING_QUEUE_DATA')
export const SAVE_ENTRIES_IN_QUEUE = createFetchActions('SAVE_ENTRIES_IN_QUEUE')
export const LOCK_ENTRIES = createFetchActions('LOCK_ENTRIES')
export const RELEASE_ENTRIES = createFetchActions('RELEASE_ENTRIES')
export const DELETE_ENTRIES = createFetchActions('DELETE_ENTRIES')
export const EXECUTE_RECALL_RETURN_ACTION = createFetchActions('EXECUTE_RECALL_RETURN_ACTION')
export const EXECUTE_RE_RATE_ACTION = createFetchActions('EXECUTE_RE_RATE_ACTION')
export const ACCEPT_REJECT_ENTRIES = createFetchActions('ACCEPT_REJECT_ENTRIES')
export const FETCH_ACTIVITY_LOG = createFetchActions('FETCH_ACTIVITY_LOG')
export const RESET_TRADING_QUEUE_DATA = 'TRADING_QUEUE_DATA'
export const RESET_ACTIVITY_LOG = 'RESET_ACTIVITY_LOG'

export const getTradingQueueData = () => {
	return function (dispatch) {
		dispatch({
			type: GET_TRADING_QUEUE_DATA.triggered,
		})
		getPMSClient()
			.query({
				query: getTradingQueueDataQuery,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_TRADING_QUEUE_DATA.succeeded,
					response,
				})
			})
	}
}

export const saveEntriesInQueue = (requestPayload: any) => (dispatch: any) => {
	return dispatch(post(SAVE_ENTRIES_IN_QUEUE, `${getPMSBaseUrl()}/v1/trading/queue`, requestPayload))
}

export const lockEntries = (entries: any) => (dispatch: any) => {
	return dispatch(post(LOCK_ENTRIES, `${getPMSBaseUrl()}/v1/trading/queue/lock`, entries))
}

export const releaseEntries = (entries: any) => (dispatch: any) => {
	return dispatch(put(RELEASE_ENTRIES, `${getPMSBaseUrl()}/v1/trading/queue/release`, entries))
}

export const deleteEntries = (entries: any) => (dispatch: any) => {
	return dispatch(put(DELETE_ENTRIES, `${getPMSBaseUrl()}/v1/trading/queue/delete`, entries))
}

export const executeRecallReturnAction = req => (dispatch: any) => {
	return dispatch(post(EXECUTE_RECALL_RETURN_ACTION, `${getPMSBaseUrl()}/v1/portfolios/activity`, req))
}

export const executeReRateAction = req => (dispatch: any) => {
	return dispatch(post(EXECUTE_RE_RATE_ACTION, `${getPMSBaseUrl()}/v1/portfolios/re-rate`, req))
}

export const handleAcceptRejectActions = (entries: any) => (dispatch: any) => {
	return dispatch(post(ACCEPT_REJECT_ENTRIES, `${getPMSBaseUrl()}/v1/portfolios/orders`, entries))
}

export const fetchActivityLog = (counterParty: string, way: string) => {
	return function (dispatch) {
		dispatch({type: FETCH_ACTIVITY_LOG.triggered})
		getPMSClient()
			.query({
				query: activityLogQuery(counterParty, way),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: FETCH_ACTIVITY_LOG.succeeded,
					response,
				})
			})
	}
}

export const resetTradingQueueData = () => {
	return {
		type: RESET_TRADING_QUEUE_DATA,
	}
}

export const resetActivityLog = () => {
	return {
		type: RESET_ACTIVITY_LOG,
	}
}
