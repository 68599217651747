import React, {useState, useMemo, useEffect, useCallback, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import NumericInput from 'react-numeric-input'
import {Button, Form, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import moment from 'moment-timezone'
import {AsyncTypeahead, Menu, MenuItem} from 'react-bootstrap-typeahead'
import {ArrayUtil, FormatUtil, ObjectUtil} from 'helper-util'
import {cashSingleStockOption, cashCollateralOption, fieldOption} from './OrderDetailsConstant'
import {
	getCounterpartyOptionSTT,
	getExternalProviderRefId,
	getLabelKey,
	getQuantityFromStartCash,
} from './OrderDetailsHelper'
import {getCollateralBasketOption} from './OrderDetailsHelper'
import {getMaturityType, getStrategyType, sampleCBDownloadProps} from './OrderDetailsHelper'
import {getErrorMessage, onFileUpload} from './OrderDetailsHelper'
import {getCounterpartyOption, getScheduleOption} from './OrderDetailsHelper'
import {field} from '../OrderEntryModalConstant'
import {getCusipSchedule, getTypeAheadResult} from '../OrderEntryModalAction'
import {uploadCollateralBasket, validateSchedule, getSchedule} from '../OrderEntryModalAction'
import {getMandatoryClass, IsDisabledDatePickerClass} from '../OrderEntryModalHelper'
import FsDropzone from '../../../../common/fsDropzone'
import FsSingleSelectDropdown from '../../../../common/dropdowns/fsSingleSelectDropdown'
import FsNumberField from '../../../../common/formItems/fsNumberField'
import {CalendarIcon, IconDelete} from '../../../../styles/svg/svgIcons'
import CustomPopover from '../../../../common/popovers/customPopover/customPopoverContainer'
import FsMultiSelectDropdown from '../../../../common/dropdowns/fsMultiSelectDropdown'
import {customPopoverIds} from '../../../../common/popovers/customPopover/customPopoverConstants'
import {MainState} from '../../../../store/mainReducer'
import {getDateFromArray, getDaysDifference} from '../../../../utils/dateUtil'
import {CLIENT_CONSTANTS, Config} from '../../../../constants/clientConfig'
import {bidOfferType} from './OrderDetailsConstant'
import {formatWithFourDecimals, formatWithSeparator} from '../../../../utils/formatUtils'
import {isResponseSuccess} from '../../../../utils/apiResponseUtil'
import {getDirtyPriceData, getEndCashData, getNotionalData} from './OrderDetailsHelper'
import {getRepoInterestData, getStartCashData} from './OrderDetailsHelper'
import {getQuantity, getQuantityFromNotional} from '../../../../utils/calculationUtil'
import {scheduleC, scheduleD} from '../../../CusipSchedule/CusipScheduleConstant'
import FsDatePicker from '../../../../common/formItems/fsDatePicker'
import {extendStorageToPopup} from '../../../../services/authService'

interface Props {
	allData: any
	allowedSelectedStep: any
	mandatory: any[]
	extra?: any
	onChange: (key: string, value: any) => any
}

export const OrderDetails = (props: Props) => {
	const isSTT = Config.activeConfig.id === CLIENT_CONSTANTS.STATESTREET
	const {allData, allowedSelectedStep, mandatory, extra, onChange} = props
	const data = allData[allowedSelectedStep.id]

	const dispatch = useDispatch()

	const [cusipSchedule, setCusipSchedule] = useState('')
	const [isSecurityInvalid, setSecurityInvalid] = useState(false)

	const [minPiece, setMinPiece] = useState(1000)
	const [minIncrement, setMinIncrement] = useState(1000)
	const [minLotSize, setMinLotSize] = useState(null)
	const [priceMultiplier, setPriceMultiplier] = useState(1)

	const [show, setShow] = useState(false)
	const [bidOfferDetails, setBidOfferDetails] = useState(data[field.BID_OFFER] || null)
	const [uploadedCollateralBasketData, setUploadedCollateralBasketData] = useState(null)

	const {benchmarkOption, benchmarkOptionSTT, counterparty, collateralBasket} = useSelector(
		(state: MainState) => state.orderEntryModal
	)
	const {schedule, scheduleValidated, typeAheadResult} = useSelector((state: MainState) => state.orderEntryModal)
	const {clientsRiskTypeList} = useSelector((state: MainState) => state.myClients)
	const {contractTypeList} = useSelector((state: MainState) => state.myClients)

	const {data: legalEntityList} = useSelector((state: MainState) => state.myLegalEntities)

	const [forwardStart, setForwardStart] = useState(data['forwardStart'])
	const toggleForwardStart = () => setForwardStart(!forwardStart)

	const {allowAllInRate, allowFloatingRate} = Config.activeConfig.peerToPeer.orderEntry

	const selectedClientRiskType =
		clientsRiskTypeList && clientsRiskTypeList.find(clientRisk => clientRisk.code === data['clientRiskType'])

	const selectedContractType =
		contractTypeList && contractTypeList.find(contractTypeList => contractTypeList.code === data['clientContractType'])

	const counterpartyOption = useMemo(
		() =>
			isSTT
				? getCounterpartyOptionSTT(
						counterparty,
						legalEntityList,
						data['legalEntityExternalId'],
						data['clientRiskType'],
						data['clientContractType']
				  )
				: getCounterpartyOption(counterparty, data['clientRiskType']),
		// eslint-disable-next-line
		[isSTT, counterparty, data['legalEntityExternalId'], data['clientRiskType'], data['clientContractType']]
	)

	const defaultScheduleOption = useMemo(() => getScheduleOption(schedule), [schedule])
	const collateralBasketOption = useMemo(() => getCollateralBasketOption(collateralBasket), [collateralBasket])
	const [scheduleOption, setScheduleOption] = useState(defaultScheduleOption)
	const handleShowToggle = () => setShow(!show)
	const disableTradeAndSettleDate = Config.activeConfig.peerToPeer.orderDetail.disableTradeAndSettleDate
	const cbOptions = !(counterpartyOption.length === 0 && collateralBasketOption.length !== 0)
		? collateralBasketOption
				.filter(
					item =>
						item.counterParty ===
						counterpartyOption.find(cp =>
							data['clientIds'] && data['clientIds'].length > 0 ? cp.id === data['clientIds'][0] : true
						).id
				)
				.filter(cb => {
					const current = getExternalProviderRefId(legalEntityList, data['legalEntityExternalId'])
					return !current || cb.book === current
				})
				.filter(cb => {
					if (![scheduleC, scheduleD].includes(cb.schedule) || !data['clientIds'] || data['clientIds'].length !== 1)
						return true
					return cb.counterParty === counterpartyOption.find(cp => cp.id === data['clientIds'][0]).id
				})
		: []

	const handleOpenCollateralBasket = () => {
		extendStorageToPopup(`${window.location.href.split('/')[0]}upsert-collateral-basket`)
		localStorage.setItem('basket', '')
	}

	const configOrderType = Config.activeConfig.peerToPeer.orderEntry[field.ORDER_TYPE][extra.selectedOrderType]
	const duration = useMemo(
		() =>
			String(
				getDaysDifference(
					data[field.MATURITY_DATE],
					forwardStart && data[field.FORWARD_START] ? data[field.FORWARD_START] : data[field.TRADE_DATE]
				)
			),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[forwardStart, data[field.FORWARD_START], data[field.MATURITY_DATE], data[field.TRADE_DATE]]
	)
	const fRateCompressUI = Config.activeConfig.peerToPeer.orderEntry.floatingRateCompressedUI

	const [blur, setBlur] = useState(false)
	const [quantityChange, setQuantityChange] = useState(false)

	const isAvlblQuantityError = (data['singleStock'] && data['singleStock'].availableQuantity) < data['quantity']

	const [isAvlblQuantityErrorStartCash, setisAvlblQuantityErrorStartCash] = useState(false)
	const [isAvlblQuantityErrorNotional, setisAvlblQuantityErrorNotional] = useState(false)

	const minLotSizePiece = getQuantity(minLotSize, data['price'], priceMultiplier)
	const isQuantityError =
		data['singleStock'] &&
		data['singleStock'].availableQuantity &&
		(data['quantity'] < minPiece || Number(data['quantity']) % minIncrement !== 0 || data['quantity'] < minLotSizePiece)

	let minPieceErrorMessage = ''
	const avlblQuantityErrorMessage = 'The quantity needs to be less than or equal to available quantity.'
	if (data['collateralType'] === bidOfferType.singleStock && isQuantityError) {
		minPieceErrorMessage = `The quantity needs to  be multiple of ${minIncrement} and greater than or equal to ${minPiece}`
		if (minLotSizePiece) minPieceErrorMessage += ` and should not be less than ${minLotSizePiece}.`
		else minPieceErrorMessage += `.`
	}

	const typeaheadRef = useRef(null)

	useEffect(() => {
		if (configOrderType.length === 1)
			!configOrderType.includes(fieldOption.ORDER_TYPE.IOI)
				? onChange(field.ORDER_TYPE, fieldOption.ORDER_TYPE.IOI)
				: onChange(field.ORDER_TYPE, fieldOption.ORDER_TYPE.RFQ)

		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		const updatedSecurity =
			typeAheadResult &&
			data['singleStock'] &&
			typeAheadResult.find(ss => ss.securityId === data['singleStock'].securityId)

		updatedSecurity && onChange('singleStock', updatedSecurity)

		// eslint-disable-next-line
	}, [typeAheadResult])

	const isStartCashEditable: boolean =
		data['collateralType'] === bidOfferType.decideLater ||
		(data['collateralType'] === bidOfferType.singleStock &&
			data['bidOffer'] &&
			data['bidOffer'].label === 'OFFER - Collateral')

	const handleStartCashChange = (startCash, quantity) => {
		// if (minIncrement - (quantity % minIncrement) > quantity % minIncrement) {
		// 	quantity -= quantity % minIncrement
		// } else {
		// quantity += minIncrement - (quantity % minIncrement)
		// }
		quantity += 1000 - (quantity % 1000)

		const isAvlblQuantityError = (data['singleStock'] && data['singleStock'].availableQuantity) < quantity

		setisAvlblQuantityErrorStartCash(isAvlblQuantityError)

		onChange('quantity', quantity)

		onChange(field.START_CASH, startCash)

		if (isStartCashEditable) setQuantityChange(false)
		onChange(
			field.NOTIONAL,
			getNotionalData(data['singleStock'], data['price'], quantity, data['collateralBasket'], data['collateralType'])
		)
		const repoInterest = getRepoInterestData(startCash, data['allInRate'], duration, data[field.STRATEGY])
		onChange('repoInterest', repoInterest)

		const endCash = getEndCashData(data[field.STRATEGY], startCash, repoInterest)
		onChange('endCash', endCash)
	}

	const handleNotionalChange = value => {
		if (value !== data[field.NOTIONAL]) {
			onChange(field.NOTIONAL, value)
			if (data['collateralType'] === bidOfferType.singleStock) {
				const {price, priceMultiplier} = data['singleStock']
				if (price) {
					let quantity = getQuantityFromNotional(value, price, priceMultiplier)

					if (minIncrement - (quantity % minIncrement) > quantity % minIncrement) {
						quantity -= quantity % minIncrement
					} else {
						quantity += minIncrement - (quantity % minIncrement)
					}

					const isAvlblQuantityError = (data['singleStock'] && data['singleStock'].availableQuantity) < quantity
					setisAvlblQuantityErrorNotional(isAvlblQuantityError)

					onChange('quantity', quantity)

					const startCash = getStartCashData(
						quantity,
						data['dirtyPrice'],
						data['singleStock'],
						data[field.STRATEGY],
						data['collateralBasket']
					)
					onChange(field.START_CASH, startCash)
				}
			}
		}
	}
	const ExternalProviderRefId = getExternalProviderRefId(legalEntityList, data['legalEntityExternalId'])
	const holidays = extra.holidays || []

	const isValidDate = useCallback(
		date => {
			const tempDate = date.clone().startOf('day')
			return ![0, 6].includes(tempDate.day()) && !holidays.find(holiday => moment(holiday).isSame(tempDate))
		},
		[holidays]
	)

	const isSettlementDateValid = useCallback(
		date => {
			const tempSettlementDate = date.clone().startOf('day')
			const tempTradeDate = forwardStart
				? data['forwardStart'].clone().startOf('day')
				: data[field.TRADE_DATE].clone().startOf('day')
			return isValidDate(date) && tempSettlementDate.isSame(tempTradeDate)
		},
		[forwardStart, data, isValidDate]
	)

	const isExtensionValidDate = useCallback(
		date => {
			const tempDate = date.clone().startOf('day')
			const tempMaturityDate = data[field.MATURITY_DATE].clone().startOf('day')
			return (
				moment(tempDate).isBefore(tempMaturityDate) &&
				!moment(tempDate).isSame(tempMaturityDate) &&
				![0, 6].includes(tempDate.day()) &&
				!holidays.find(holiday => moment(holiday).isSame(tempDate))
			)
		},
		// eslint-disable-next-line
		[data[field.MATURITY_DATE], holidays, data[field.EXTENSION_DATE]]
	)

	const addBusinessDays = useCallback(
		(originalDate, numDaysToAdd) => {
			let daysRemaining = numDaysToAdd
			const newDate = originalDate.clone()
			while (daysRemaining > 0) {
				newDate.add(1, 'days')
				if (isValidDate(newDate)) daysRemaining--
			}
			return newDate
		},
		[isValidDate]
	)

	const substractBusinessDays = useCallback(
		(originalDate, numDaysToAdd) => {
			let daysRemaining = Math.abs(numDaysToAdd)
			const newDate = originalDate.clone()
			while (daysRemaining > 0) {
				newDate.subtract(1, 'days')
				if (isExtensionValidDate(newDate)) daysRemaining--
			}
			return newDate
		},
		[isExtensionValidDate]
	)

	const uploadBasketPermission = Config.activeConfig.peerToPeer.orderEntry.uploadBasket
	const allowCusipScheduleCandDFilter = !Config.activeConfig.peerToPeer.orderDetail.allowCusipCandDValidation

	return (
		<div className={isSTT ? 'col' : 'col-19'}>
			<div className='row h-100'>
				<div className='col-12 px-4 border-right-dashed f-14'>
					<div className='d-flex align-items-center'>
						<span className='pr-3 pb-1 mandatory*'>Order Type:</span>
						{!configOrderType.includes(fieldOption.ORDER_TYPE.IOI) && (
							<div className='pr-4 d-flex'>
								<Form.Check
									type='radio'
									custom
									name={field.ORDER_TYPE}
									label={
										<div className='d-flex align-items-center'>
											<span className='pr-1'>{fieldOption.ORDER_TYPE.IOI}</span>
										</div>
									}
									id={fieldOption.ORDER_TYPE.IOI}
									onChange={() => onChange(field.ORDER_TYPE, fieldOption.ORDER_TYPE.IOI)}
									checked={data[field.ORDER_TYPE] === fieldOption.ORDER_TYPE.IOI}
								/>
								<CustomPopover id={customPopoverIds.indicationOfInterest} />
							</div>
						)}
						{!configOrderType.includes(fieldOption.ORDER_TYPE.RFQ) && (
							<div className='pr-4 d-flex'>
								<Form.Check
									type='radio'
									custom
									name={field.ORDER_TYPE}
									label={
										<div className='d-flex align-items-center'>
											<span className='pr-1'>{fieldOption.ORDER_TYPE.RFQ}</span>
										</div>
									}
									id={fieldOption.ORDER_TYPE.RFQ}
									onChange={() => onChange(field.ORDER_TYPE, fieldOption.ORDER_TYPE.RFQ)}
									checked={data[field.ORDER_TYPE] === fieldOption.ORDER_TYPE.RFQ}
								/>
								<CustomPopover id={customPopoverIds.requestForQuote} />
							</div>
						)}
					</div>
					<div className='mt-3 px-0'>
						<FsSingleSelectDropdown
							ddMenuClass='max-height-180 overflow-auto'
							title='Legal Entity'
							titleClass='pr-1 mandatory depos-label'
							noSelectionText='Select legal entity id'
							selectedItem={
								!ArrayUtil.isEmpty(legalEntityList) && data['legalEntityExternalId']
									? legalEntityList.find(le => le.legalEntityExternalId === data['legalEntityExternalId']).fullName
									: ''
							}
							dropdownItems={legalEntityList}
							keyField='id'
							valueField='fullName'
							iconInfo={false}
							onSelect={item => {
								onChange('legalEntityExternalId', item.legalEntityExternalId)
								if (data['singleStock'] && data['singleStock'].securityId) {
									dispatch(
										getTypeAheadResult(
											FormatUtil.text.toUpperCase(String(data['singleStock'].securityId)),
											getExternalProviderRefId(legalEntityList, item.legalEntityExternalId),
											data[field.CLIENT_RISK_TYPE]
										)
									)
								}
								if (data['collateralBasket'] && data['collateralBasket'].book !== item.externalProviderRefId) {
									onChange('collateralBasket', null)
									onChange('schedule', null)
								}
							}}
						/>
					</div>

					<div>
						<FsSingleSelectDropdown
							title='Counterparty Profile'
							id={field.CLIENT_RISK_TYPE}
							titleClass={`${getMandatoryClass(mandatory, field.CLIENT_RISK_TYPE)}`}
							ddClass='form-sm mt-3'
							noSelectionText='Select Counterparty Profile'
							selectedItem={(selectedClientRiskType && selectedClientRiskType.description) || ''}
							dropdownItems={clientsRiskTypeList}
							keyField='code'
							valueField='description'
							onSelect={item => {
								if (!data[field.CLIENT_RISK_TYPE] || item.code !== data[field.CLIENT_RISK_TYPE]) {
									onChange(field.CLIENT_RISK_TYPE, item.code)
									onChange(field.CLIENT_IDS, null)
									extra.setIndemnification('indemnifier', null)
									onChange(field.COLLATERAL_TYPE, null)
									onChange('collateralBasket', null)
									onChange(field.SCHEDULE, null)
									setCusipSchedule('')
									onChange(field.NOTIONAL, null)
									onChange(field.START_CASH, null)
									onChange('margin', null)
								}
							}}
						/>
						{isSTT && (
							<FsSingleSelectDropdown
								title='Contract Type'
								id={field.CONTRACT_TYPE}
								titleClass={`${getMandatoryClass(mandatory, field.CONTRACT_TYPE)}`}
								ddClass='form-sm mt-3'
								noSelectionText='Select Contract Type'
								selectedItem={(selectedContractType && selectedContractType.description) || ''}
								dropdownItems={contractTypeList}
								keyField='code'
								valueField='description'
								onSelect={item => {
									if (!data[field.CONTRACT_TYPE] || item.code !== data[field.CONTRACT_TYPE]) {
										onChange(field.CONTRACT_TYPE, item.code)
										onChange(field.CLIENT_IDS, null)
									}
								}}
							/>
						)}

						{data['clientRiskType'] && ((isSTT && data['clientContractType']) || !isSTT) && (
							<FsMultiSelectDropdown
								data={data['clientIds']}
								label='Counterparty'
								options={counterpartyOption}
								isDisabled={counterpartyOption === null}
								isMandatory={true}
								onChange={value => {
									onChange('clientIds', value)
									extra.setIndemnification('indemnifier', null)
									onChange(field.COLLATERAL_TYPE, null)
									onChange('collateralBasket', null)
									onChange(field.NOTIONAL, null)
									onChange(field.START_CASH, null)
									onChange('schedule', null)
								}}
							/>
						)}
						<div className='row'>
							<div className='col-12'>
								<FsSingleSelectDropdown
									title='Strategy'
									id={field.STRATEGY}
									titleClass='mandatory'
									ddClass='form-sm mt-3'
									noSelectionText='Select'
									selectedItem={data[field.STRATEGY] ? data[field.STRATEGY].label : ''}
									dropdownItems={getStrategyType(Config.activeConfig.peerToPeer.orderEntry[field.STRATEGY])}
									keyField='id'
									valueField='label'
									onSelect={item => {
										onChange(field.STRATEGY, item)

										const dirtyPrice = getDirtyPriceData(item, data['price'], data['haircut'])
										onChange('dirtyPrice', dirtyPrice)

										const startCash = getStartCashData(
											data['quantity'],
											dirtyPrice,
											data['singleStock'],
											item,
											data['collateralBasket']
										)
										onChange(field.START_CASH, startCash)

										const repoInterest = getRepoInterestData(startCash, data['allInRate'], duration, item)
										onChange('repoInterest', repoInterest)

										const endCash = getEndCashData(item, startCash, repoInterest)
										onChange('endCash', endCash)
									}}
								/>
							</div>
							<div className='col-12'>
								<FsSingleSelectDropdown
									title='Bid/Offer'
									id={field.BID_OFFER}
									titleClass={`${getMandatoryClass(mandatory, field.BID_OFFER)}`}
									ddClass='form-sm mt-3'
									noSelectionText='Select'
									selectedItem={data[field.BID_OFFER] ? data[field.BID_OFFER].label : ''}
									dropdownItems={
										data['collateralType'] === bidOfferType.singleStock ? cashSingleStockOption : cashCollateralOption
									}
									keyField='id'
									valueField='label'
									onSelect={item => {
										if (
											!data[field.BID_OFFER] ||
											item.label !== (data[field.BID_OFFER] && data[field.BID_OFFER].label)
										) {
											onChange(field.BID_OFFER, item)
											setBidOfferDetails(item)
											onChange(field.START_CASH, 0)
											onChange('quantity', 0)
											onChange(
												'bidType',
												data['collateralType'] === bidOfferType.singleStock
													? item['id'] === 'repo'
														? bidOfferType.singleStock
														: bidOfferType.cash
													: item['id'] === 'repo'
													? bidOfferType.collateral
													: bidOfferType.cash
											)
											onChange(
												'offerType',
												data['collateralType'] === bidOfferType.singleStock
													? item['id'] === 'reverseRepo'
														? bidOfferType.singleStock
														: bidOfferType.cash
													: item['id'] === 'reverseRepo'
													? bidOfferType.collateral
													: bidOfferType.cash
											)
										}
									}}
								/>
							</div>
						</div>
						<div className='d-flex align-items-center mt-3'>
							<span className='pr-3 pb-1 mandatory'>Rate:</span>
							<div className='pr-4'>
								<Form.Check
									custom
									type='radio'
									name='rate'
									id='fixed'
									label='Fixed'
									onChange={() => {
										onChange('rateType', 'FIXED')
										onChange('benchmark', null)
										onChange('referenceId', null)
										onChange('spread', null)
									}}
									className='pr-2'
									checked={data['rateType'] === 'FIXED'}
								/>
							</div>
							<div className='pr-4'>
								<Form.Check
									type='radio'
									custom
									disabled={!allowFloatingRate}
									name='rate'
									id='floating'
									label='Floating'
									onChange={() => {
										onChange('rateType', 'FLOATING')

										onChange('allInRate', null)

										const repoInterest = getRepoInterestData(
											data[field.START_CASH],
											null,
											duration,
											data[field.STRATEGY]
										)
										onChange('repoInterest', repoInterest)

										const endCash = getEndCashData(data[field.STRATEGY], data[field.START_CASH], repoInterest)
										onChange('endCash', endCash)
									}}
									checked={data['rateType'] === 'FLOATING'}
								/>
							</div>
							{Config.activeConfig.peerToPeer.orderEntry[field.TERM_STRUCTURE] && (
								<div className='ml-auto'>
									<Form.Check
										type='checkbox'
										custom
										name='rate'
										id='term-structure'
										label='Term Structure'
										onChange={e => onChange('termStructure', e.target.checked)}
										checked={data['termStructure'] === true}
									/>
								</div>
							)}
						</div>
						<div className='row'>
							{data['rateType'] === 'FIXED' && (
								<div className='col-12'>
									<div className='custom-input mt-3'>
										<label className='dd-label trade-label mandatory*'>
											Rate (%)
											<CustomPopover id={customPopoverIds.orderEntryAllInRate} />
										</label>
										<FsNumberField
											name='allInRate'
											value={data['allInRate']}
											onBlur={value => {
												onChange('allInRate', value)

												const repoInterest = getRepoInterestData(
													data[field.START_CASH],
													value,
													duration,
													data[field.STRATEGY]
												)
												onChange('repoInterest', repoInterest)

												const endCash = getEndCashData(data[field.STRATEGY], data[field.START_CASH], repoInterest)
												onChange('endCash', endCash)
											}}
											className='form-control'
											allowDecimal
											allowNegative
											isRate={4}
											max={100}
										/>
									</div>
								</div>
							)}
							{data['rateType'] === 'FLOATING' && (
								<>
									{!allowAllInRate ? (
										<div className={fRateCompressUI ? 'col-8' : 'col-12'}>
											<FsSingleSelectDropdown
												id='benchmark'
												title='Benchmark'
												titleClass='mandatory'
												ddClass='form-sm mt-3'
												noSelectionText='Select Benchmark'
												selectedItem={data['benchmark']}
												dropdownItems={benchmarkOptionSTT ? benchmarkOptionSTT : []}
												keyField='id'
												valueField='label'
												onSelect={(item: any) => {
													const benchmarkLabel = item
													const referenceId = ''
													const refRate = ''
													const bench = 0

													const repoInterest = getRepoInterestData(
														data[field.START_CASH],
														bench + data['spread'],
														duration,
														data[field.STRATEGY]
													)
													onChange('benchmark', benchmarkLabel)
													onChange('referenceId', referenceId)
													onChange('refRate', refRate)
													onChange('repoInterest', repoInterest)
												}}
											/>
										</div>
									) : (
										<div className={fRateCompressUI ? 'col-8' : 'col-12'}>
											<div className='custom-input mt-3'>
												<label className='dd-label trade-label mandatory'>Benchmark</label>

												<AsyncTypeahead
													filterBy={['type']}
													id='benchmark'
													isLoading={benchmarkOption === null}
													labelKey={(option: any) => getLabelKey(allowAllInRate, option)}
													minLength={2}
													onSearch={query => {}}
													options={benchmarkOption ? benchmarkOption : []}
													onChange={(item: any) => {
														const benchmarkLabel = item[0] ? getLabelKey(allowAllInRate, item[0]) : ''
														const referenceId = item[0] ? item[0].tenor : ''
														const refRate = item[0] ? item[0].yield : ''
														const bench = item[0] ? parseFloat(item[0].yield) : 0
														const repoInterest = getRepoInterestData(
															data[field.START_CASH],
															bench + data['spread'],
															duration,
															data[field.STRATEGY]
														)
														onChange('benchmark', benchmarkLabel)
														onChange('referenceId', referenceId)
														onChange('refRate', refRate)
														onChange('repoInterest', repoInterest)
													}}
													defaultInputValue={data['referenceId'] ? data['referenceId'] : ''}
													placeholder='Search Benchmark'
													renderMenu={(results, menuProps) => {
														return (
															<Menu {...menuProps} className='w-100 h-170px'>
																{results.map((result: any, index) => (
																	<MenuItem key={index} option={result} position={index} className='py-2'>
																		<span className='text-primary d-block f-14 font-weight-600'>{result.tenor}</span>
																		<span className='f-12 text-grey-4 lh-normal d-block'>
																			{result.yield && <div>Rate: {result.yield}</div>}
																			{result.type && (
																				<div>Rate Type: {FormatUtil.text.toUpperCase(String(result.type))}</div>
																			)}
																		</span>
																	</MenuItem>
																))}
															</Menu>
														)
													}}
												/>
											</div>
										</div>
									)}
									{allowAllInRate ? (
										<div className={fRateCompressUI ? 'col-5' : 'col-12'}>
											<div className='custom-input mt-3'>
												<label className={`d-label trade-label mandatory* ${isSTT && 'white-space-nowrap'}`}>
													Reference rate (%)
												</label>
												<FsNumberField
													name='refRate'
													value={data['refRate']}
													onBlur={value => onChange('refRate', value)}
													className='form-control'
													allowDecimal
													allowNegative
													isRate={4}
												/>
											</div>
										</div>
									) : (
										''
									)}
									<div className={fRateCompressUI ? 'col-5' : 'col-12'}>
										<div className='custom-input mt-3'>
											<label className='dd-label trade-label mandatory*'>Spread (bps)</label>
											<FsNumberField
												name='spread'
												value={data['spread']}
												onBlur={value => {
													const spread = parseFloat(value) || 0

													onChange('spread', spread)

													onChange('allInRate', allowAllInRate ? data['refRate'] + spread / 100 : NaN)

													const repoInterest = getRepoInterestData(
														data[field.START_CASH],
														data['benchmark'] + spread,
														duration,
														data[field.STRATEGY]
													)
													onChange('repoInterest', repoInterest)
												}}
												className='form-control'
												allowDecimal
												allowNegative
												isRate={4}
											/>
										</div>
									</div>
									{allowAllInRate && (
										<div className={fRateCompressUI ? 'col-5' : 'col-12'}>
											<div className='custom-input mt-3'>
												<label className='dd-label trade-label mandatory*'>
													{Config.activeConfig.renameAllInRateToRepoRate ? 'Repo Rate (%)' : 'All in Rate (%)'}
												</label>
												<FsNumberField
													name='allInRate'
													value={formatWithFourDecimals(data['allInRate'])}
													onBlur={value => onChange('allInRate', value)}
													className='form-control'
													allowDecimal
													allowNegative
													isRate={4}
												/>
											</div>
										</div>
									)}
								</>
							)}
						</div>
						<div className='mt-3'>
							<span className='pr-3 pb-1 mandatory'>Collateral Type:</span>
							<div className='d-flex align-items-center '>
								<div className='pr-4'>
									<Form.Check
										name='trade-offer'
										type='radio'
										custom
										id='single-stock'
										label='Single Security'
										onChange={() => {
											const bidofferSelected = data.bidOffer
											onChange('collateralType', bidOfferType.singleStock)
											onChange('singleStock', null)
											onChange('collateralBasket', null)
											onChange(
												'bidType',
												bidofferSelected
													? bidOfferDetails && bidOfferDetails.id === 'repo'
														? bidOfferType.singleStock
														: bidOfferType.cash
													: bidOfferType.singleStock
											)
											onChange(
												'offerType',
												bidofferSelected
													? bidOfferDetails && bidOfferDetails.id === 'reverseRepo'
														? bidOfferType.singleStock
														: bidOfferType.cash
													: bidOfferType.cash
											)
											onChange('schedule', null)
											onChange('price', null)
											onChange(field.NOTIONAL, null)

											const dirtyPrice = getDirtyPriceData(data[field.STRATEGY], null, data['haircut'])
											onChange('dirtyPrice', dirtyPrice)

											const startCash = getStartCashData(
												data['quantity'],
												dirtyPrice,
												data['singleStock'],
												data[field.STRATEGY],
												null
											)
											onChange(field.START_CASH, startCash)

											const repoInterest = getRepoInterestData(
												startCash,
												data['allInRate'],
												duration,
												data[field.STRATEGY]
											)
											onChange('repoInterest', repoInterest)

											const endCash = getEndCashData(data[field.STRATEGY], startCash, repoInterest)
											onChange('endCash', endCash)

											dispatch(validateSchedule(null))
										}}
										checked={data['collateralType'] === bidOfferType.singleStock}
									/>
								</div>
								<div className='pr-4'>
									<Form.Check
										name='trade-offer'
										type='radio'
										custom
										id='collateral-basket'
										label='Collateral Basket'
										disabled={data['clientIds'] && data['clientIds'].length > 1}
										onChange={() => {
											const bidofferSelected = data.bidOffer
											onChange('collateralType', 'COLLATERAL')
											onChange('singleStock', null)
											onChange('collateralBasket', null)
											setCusipSchedule('')
											onChange('price', null)
											onChange(
												'bidType',
												bidofferSelected
													? bidOfferDetails && bidOfferDetails.id === 'repo'
														? bidOfferType.collateral
														: bidOfferType.cash
													: bidOfferType.collateral
											)
											onChange(
												'offerType',
												bidofferSelected
													? bidOfferDetails && bidOfferDetails.id === 'reverseRepo'
														? bidOfferType.collateral
														: bidOfferType.cash
													: bidOfferType.cash
											)
											onChange('schedule', null)
											onChange('margin', null)
											onChange('quantity', null)
											onChange('price', null)
											onChange(field.NOTIONAL, null)

											const dirtyPrice = null

											const startCash = getStartCashData(
												data['quantity'],
												dirtyPrice,
												data['singleStock'],
												data[field.STRATEGY],
												data['collateralBasket']
											)
											onChange(field.START_CASH, startCash)

											const repoInterest = getRepoInterestData(
												startCash,
												data['allInRate'],
												duration,
												data[field.STRATEGY]
											)
											onChange('repoInterest', repoInterest)

											const endCash = getEndCashData(data[field.STRATEGY], startCash, repoInterest)
											onChange('endCash', endCash)

											dispatch(validateSchedule(null))
										}}
										checked={data['collateralType'] === 'COLLATERAL'}
									/>
								</div>
								<div className='pr-4'>
									<Form.Check
										name='trade-offer'
										type='radio'
										custom
										id='decide-later-radio'
										label='Decide Later'
										onChange={() => {
											const bidofferSelected = data.bidOffer
											onChange('collateralType', 'DECIDE_LATER')
											onChange('singleStock', null)
											setCusipSchedule('')
											onChange('collateralBasket', null)
											onChange('haircut', null)
											onChange('schedule', null)
											onChange('price', 100)
											onChange('margin', null)
											onChange('repoInterest', null)
											onChange('endCash', null)
											onChange(field.START_CASH, 0)
											onChange(field.NOTIONAL, 0)
											onChange('quantity', null)
											setScheduleOption(defaultScheduleOption)
											dispatch(validateSchedule(null))
											onChange(
												'bidType',
												bidofferSelected
													? bidOfferDetails && bidOfferDetails.id === 'repo'
														? bidOfferType.collateral
														: bidOfferType.cash
													: bidOfferType.collateral
											)
											onChange(
												'offerType',
												bidofferSelected
													? bidOfferDetails && bidOfferDetails.id === 'reverseRepo'
														? bidOfferType.collateral
														: bidOfferType.cash
													: bidOfferType.cash
											)
										}}
										checked={data['collateralType'] === 'DECIDE_LATER'}
									/>
								</div>
							</div>
						</div>
						{data['collateralType'] === bidOfferType.singleStock && (
							<div className='row'>
								<div className='custom-input col-12 mt-3 p2p-dropdown-label'>
									<label className='dd-label mandatory'>
										Select Single Security
										{ObjectUtil.isEmpty(scheduleValidated) ? (
											''
										) : ArrayUtil.isEmpty(scheduleValidated.securities) ? (
											<i className='ml-2 fas fa-check-circle text-success' />
										) : (
											<OverlayTrigger
												placement='top'
												overlay={<Tooltip id={`tooltip-top`}>{getErrorMessage(scheduleValidated.securities)}</Tooltip>}
											>
												<i className='ml-2 fas fa-times-circle text-danger' />
											</OverlayTrigger>
										)}
									</label>
									<AsyncTypeahead
										filterBy={['ticker', 'longName', 'cusip', 'isin']}
										id='cusip'
										isLoading={typeAheadResult === null}
										labelKey={(option: any) => option.securityId || ''}
										minLength={2}
										onSearch={query =>
											dispatch(
												getTypeAheadResult(
													FormatUtil.text.toUpperCase(String(query)),
													ExternalProviderRefId,
													data[field.CLIENT_RISK_TYPE]
												)
											)
										}
										options={typeAheadResult ? typeAheadResult : []}
										onInputChange={(text: string, event: Event) => {
											if (text.length === 0) {
												onChange('schedule', null)
												onChange('securityId', null)
												setCusipSchedule('')
											}
										}}
										ref={typeaheadRef}
										onChange={selectedSecurity => {
											if (selectedSecurity[0] && selectedSecurity[0].securityId) {
												dispatch(getCusipSchedule(selectedSecurity[0].securityId)).then(res => {
													const response = (res && res.response && res.response.data) || ''
													const cusipAvailability = response.length > 0 || false
													const isCusipCandD = [scheduleC, scheduleD].includes(response)

													if ((isResponseSuccess(res) && !isCusipCandD) || allowCusipScheduleCandDFilter) {
														const input = typeaheadRef.current.inputNode

														input.title = ''
														input.className = 'form-control'

														onChange('singleStock', selectedSecurity[0])

														const {quantity} = data
														const {price, currency, margin} = selectedSecurity[0]
														const haircut = margin ? margin : 0
														onChange(
															field.NOTIONAL,
															getNotionalData(
																selectedSecurity[0],
																price,
																quantity,
																data['collateralBasket'],
																data['collateralType']
															)
														)
														onChange('price', price || 0)
														onChange('margin', margin || 0)
														onChange('haircut', haircut)

														onChange('minPiece', selectedSecurity[0].minPiece || 1000)
														onChange('minIncrement', selectedSecurity[0].minIncrement || 1000)
														onChange('minLotSize', selectedSecurity[0].minLotSize || null)

														setMinPiece(selectedSecurity[0].minPiece || 1000)
														setMinIncrement(selectedSecurity[0].minIncrement || 1000)
														setMinLotSize(selectedSecurity[0].minLotSize || null)
														setPriceMultiplier(selectedSecurity[0].priceMultiplier || 1)

														onChange('quantity', 0)
														const dirtyPrice = getDirtyPriceData(data[field.STRATEGY], price, haircut)
														onChange('dirtyPrice', dirtyPrice)

														const startCash = getStartCashData(
															data['quantity'],
															dirtyPrice,
															selectedSecurity[0],
															data[field.STRATEGY],
															data['collateralBasket']
														)
														onChange(field.START_CASH, startCash)

														const repoInterest = getRepoInterestData(
															startCash,
															data['allInRate'],
															duration,
															data[field.STRATEGY]
														)
														onChange('repoInterest', repoInterest)

														const endCash = getEndCashData(data[field.STRATEGY], startCash, repoInterest)
														onChange('endCash', endCash)

														if (currency) {
															onChange(field.CURRENCY, {
																id: FormatUtil.text.toLowerCase(currency),
																label: currency,
																value: currency,
															})
														}
														setSecurityInvalid(false)
														setCusipSchedule(
															allowCusipScheduleCandDFilter && !cusipAvailability
																? 'Not Eligible'
																: res && res.response && res.response.data
																? res.response.data
																: null
														)
														onChange(
															'schedule',
															res && res.response && res.response.data !== '' ? res.response.data : null
														)
													} else {
														const input = typeaheadRef.current.inputNode
														input.title = 'Security with Investment Grade 1 and 2 schedules are not allowed'
														input.className = 'input_error'
														onChange('schedule', null)
														setCusipSchedule('')
														setSecurityInvalid(true)
													}
												})
											}
										}}
										defaultInputValue={(data['singleStock'] && data['singleStock'].securityId) || ''}
										placeholder='Search Cusip'
										useCache={false}
										renderMenu={(results, menuProps) => {
											return (
												<Menu {...menuProps} className='w-100 h-250px custom-async-menu'>
													{results.map((result: any, index) => (
														<MenuItem key={index} option={result} position={index} className='custom-tooltip'>
															<span className='text-primary d-block f-14 font-weight-600'>
																<div>{result.securityId}</div>
															</span>
															<span className='f-12 text-grey-4 lh-normal d-block'>
																{
																	<div className='overflow-ellipsis'>
																		Available Quantity: {formatWithSeparator(result.availableQuantity)}
																	</div>
																}
															</span>
															<span className='card f-12 text-grey-4 lh-normal mt-1 p-2 border w-custom-tooltip'>
																<div className='overflow-ellipsis'>CUSIP: {result.cusip || ''}</div>
																<div className='overflow-ellipsis'>ISIN: {result.isin || ''}</div>
																<div className='overflow-ellipsis'>Ticker: {result.ticker || ''}</div>
																<div className='overflow-ellipsis'>Name: {result.securityDescription || ''}</div>
																<div className='overflow-ellipsis'>Asset Type: {result.assetType || ''}</div>
																<div className='overflow-ellipsis'>Asset Subtype: {result.assetSubtype || ''}</div>
																<div className='overflow-ellipsis'>
																	Avail. Quantity: {formatWithSeparator(result.availableQuantity)}
																</div>
															</span>
														</MenuItem>
													))}
												</Menu>
											)
										}}
									/>
								</div>
								<div className='col-12'>
									<FsSingleSelectDropdown
										title='Schedule'
										id='schedule'
										titleClass='mandatory'
										ddClass='form-sm p2p-dropdown-item mt-3'
										noSelectionText='Select'
										selectedItem={cusipSchedule || (data['schedule'] && data['schedule'].label)}
										dropdownItems={cusipSchedule ? [cusipSchedule] : []}
										keyField='id'
										valueField='label'
										onSelect={item => {
											onChange('schedule', item)
										}}
									/>
								</div>
							</div>
						)}
						{data['collateralType'] === 'COLLATERAL' && (
							<div className='row'>
								<div className='col-12 mt-3 position-relative p2p-dropdown-label'>
									<FsSingleSelectDropdown
										title={
											<div className='f-14'>
												Select Basket
												{ObjectUtil.isEmpty(scheduleValidated) ? (
													''
												) : ArrayUtil.isEmpty(scheduleValidated.securities) ? (
													<i className='ml-2 fas fa-check-circle text-success' />
												) : (
													<OverlayTrigger
														placement='top'
														overlay={
															<Tooltip id={`tooltip-top`}>{getErrorMessage(scheduleValidated.securities)}</Tooltip>
														}
													>
														<i className='ml-2 fas fa-times-circle text-danger' />
													</OverlayTrigger>
												)}
											</div>
										}
										id='collateralBasket'
										titleClass='mandatory'
										ddClass='form-sm p2p-dropdown-item'
										noSelectionText='Select'
										selectedItem={data['collateralBasket'] ? data['collateralBasket'].label : ''}
										dropdownItems={cbOptions}
										keyField='id'
										valueField='label'
										onSelect={item => {
											onChange('collateralBasket', item)
											onChange(field.NOTIONAL, item.notional)
											onChange(field.START_CASH, item.startCash)

											dispatch(getSchedule(item.value)).then(
												res => {
													if (isResponseSuccess(res)) {
														onChange('schedule', res.response.data)

														const payload = {
															collateralBasketId: item.value,
															schedule: res.response.data,
															isSingleStock: data['collateralType'] === bidOfferType.singleStock,
														}
														setScheduleOption([])
														dispatch(validateSchedule(payload))
													}
												},
												err => {
													console.log(err)
												}
											)

											if (data['schedule']) {
												const payload = {
													collateralBasketId: item.value,
													schedule: data['schedule'],
													isSingleStock: data['collateralType'] === bidOfferType.singleStock,
												}
												dispatch(validateSchedule(payload))
											}
										}}
										disabled={collateralBasketOption === null}
									/>
									<div className='d-flex w-50 select-basket-label pl-3 text-align-right'>
										<div className='btn-link' onClick={handleOpenCollateralBasket}>
											Create
										</div>
										{!uploadBasketPermission && (
											<div className='btn-link' onClick={handleShowToggle}>
												Upload
											</div>
										)}
									</div>
									<Modal
										show={show}
										backdrop={'static'}
										onHide={handleShowToggle}
										aria-labelledby='contained-modal-title-vcenter'
										centered
									>
										<Modal.Header>Upload Collateral Basket</Modal.Header>
										<Modal.Body>
											<div>
												<FsDropzone
													id='collateral-basket-order-entry-upload'
													fileUploadText='Upload Collateral Basket'
													onDropOrSelect={data =>
														onFileUpload(data, fileData => setUploadedCollateralBasketData(fileData))
													}
													multiple={false}
													disabled={false}
													fileDownload={sampleCBDownloadProps()}
												/>
											</div>
											{uploadedCollateralBasketData && (
												<div className='mr-4 mt-3 d-flex justify-content-start'>
													<div className={`pr-2 f-14 font-weight-600 mr-2 badge badge-pill badge-primary`}>
														{uploadedCollateralBasketData.name || 'N/A'}
													</div>
													<div
														className={`cursor-pointer`}
														onClick={() => {
															setUploadedCollateralBasketData(null)
															onChange('collateralBasket', null)
														}}
													>
														<IconDelete />
													</div>
												</div>
											)}
										</Modal.Body>
										<Modal.Footer className='justify-content-between'>
											<Button variant='outline-primary' onClick={handleShowToggle}>
												Cancel
											</Button>
											<Button
												className='px-5'
												variant='secondary'
												disabled={!uploadedCollateralBasketData}
												onClick={() => {
													const data = new FormData()
													data.append('file', uploadedCollateralBasketData)
													dispatch(uploadCollateralBasket(data)).then(res => {
														if (isResponseSuccess(res)) {
															const {notionalAmount, startCashAmount} = res.response.data
															onChange(field.NOTIONAL, notionalAmount || 0)
															onChange(field.START_CASH, startCashAmount || 0)
															onChange('collateralBasket', getCollateralBasketOption([res.response.data])[0])
														}
													})
													handleShowToggle()
												}}
											>
												Confirm
											</Button>
										</Modal.Footer>
									</Modal>
								</div>
								<div className='col-12'>
									<FsSingleSelectDropdown
										title='Schedule'
										id='schedule'
										titleClass='mandatory'
										ddClass='form-sm mt-3 p2p-dropdown-item'
										noSelectionText='Select'
										selectedItem={data['schedule'] ? data['schedule'].label || data['schedule'] : ''}
										dropdownItems={scheduleOption}
										keyField='id'
										valueField='label'
										onSelect={item => {
											onChange('schedule', item)

											if (data['collateralBasket']) {
												const payload = {
													collateralBasketId: data['collateralBasket'].value,
													schedule: item.value,
													isSingleStock: data['collateralType'] === bidOfferType.singleStock,
												}
												dispatch(validateSchedule(payload))
											}
										}}
									/>
								</div>
							</div>
						)}
						{data['collateralType'] === 'DECIDE_LATER' && (
							<div className='row'>
								<div className='col-12'>
									<FsSingleSelectDropdown
										title='Schedule'
										id='schedule'
										titleClass='mandatory'
										ddClass='form-sm mt-3 p2p-dropdown-item'
										noSelectionText='Select'
										selectedItem={data['schedule'] ? data['schedule'].label || data['schedule'] : ''}
										dropdownItems={scheduleOption}
										keyField='id'
										valueField='label'
										onSelect={item => onChange('schedule', item)}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className='col-12 pl-4 f-14'>
					<div className='row'>
						<div className='mt-3 col-8'>
							{data['collateralType'] === bidOfferType.singleStock &&
								((!blur && !isStartCashEditable) || (isStartCashEditable && quantityChange)) && (
									<div
										className='custom-input'
										title={
											isQuantityError ? minPieceErrorMessage : isAvlblQuantityError ? avlblQuantityErrorMessage : ''
										}
									>
										<label className='dd-label trade-label mandatory*'>Quantity/Size</label>
										<NumericInput
											disabled={isSecurityInvalid}
											className={` ${isQuantityError || isAvlblQuantityError ? 'input_error' : 'form-control'}`}
											step={minIncrement}
											value={data['quantity']}
											min={0}
											strict={true}
											onChange={value => {
												onChange('quantity', value)
												const startCash = getStartCashData(
													value,
													data['dirtyPrice'],
													data['singleStock'],
													data[field.STRATEGY],
													data['collateralBasket']
												)
												onChange(field.START_CASH, startCash)

												const repoInterest = getRepoInterestData(
													startCash,
													data['allInRate'],
													duration,
													data[field.STRATEGY]
												)
												onChange('repoInterest', repoInterest)

												const endCash = getEndCashData(data[field.STRATEGY], startCash, repoInterest)
												onChange('endCash', endCash)

												onChange(
													field.NOTIONAL,
													getNotionalData(
														data['singleStock'],
														data['price'],
														value,
														data['collateralBasket'],
														data['collateralType']
													)
												)
											}}
											onBlur={() => {
												if (!isQuantityError) {
													setQuantityChange(false)
													setBlur(true)
												}
											}}
										/>
									</div>
								)}
							{(data['collateralType'] !== bidOfferType.singleStock ||
								(blur && data['collateralType'] === bidOfferType.singleStock && !isStartCashEditable) ||
								(isStartCashEditable && !quantityChange)) && (
								<div className='custom-input' title={isAvlblQuantityError ? avlblQuantityErrorMessage : ''}>
									<label className='dd-label trade-label mandatory*'>Quantity/Size</label>
									<FsNumberField
										name='quantity'
										disabled={
											data['collateralType'] === bidOfferType.decideLater ||
											data['collateralType'] === bidOfferType.collateral
										}
										value={data['quantity']}
										onBlur={value => {
											onChange('quantity', value)

											const startCash = getStartCashData(
												value,
												data['dirtyPrice'],
												data['singleStock'],
												data[field.STRATEGY],
												data['collateralBasket']
											)
											onChange(field.START_CASH, startCash)

											const repoInterest = getRepoInterestData(
												startCash,
												data['allInRate'],
												duration,
												data[field.STRATEGY]
											)
											onChange('repoInterest', repoInterest)

											const endCash = getEndCashData(data[field.STRATEGY], startCash, repoInterest)
											onChange('endCash', endCash)

											onChange(
												field.NOTIONAL,
												getNotionalData(
													data['singleStock'],
													data['price'],
													value,
													data['collateralBasket'],
													data['collateralType']
												)
											)
										}}
										onFocus={() => {
											setBlur(false)
											setQuantityChange(true)
										}}
										className={` ${
											(data['collateralType'] === bidOfferType.singleStock ? isAvlblQuantityError : false) ||
											isQuantityError ||
											isAvlblQuantityError
												? 'input_error'
												: 'form-control'
										}`}
									/>
								</div>
							)}
						</div>
						<div className='mt-3 col-8'>
							<div
								className='custom-input'
								title={isAvlblQuantityErrorNotional || isAvlblQuantityError ? avlblQuantityErrorMessage : ''}
							>
								<label
									className={`dd-label trade-label ${
										data['collateralType'] !== bidOfferType.decideLater
											? getMandatoryClass(mandatory, field.NOTIONAL)
											: ''
									}`}
								>
									Collateral Value
								</label>
								<FsNumberField
									value={data[field.NOTIONAL]}
									disabled={
										data['collateralType'] === bidOfferType.decideLater ||
										data['collateralType'] === bidOfferType.collateral ||
										isSecurityInvalid
									}
									className={` ${
										(
											data['collateralType'] === bidOfferType.singleStock
												? isAvlblQuantityErrorNotional || isAvlblQuantityError
												: false
										)
											? 'input_error'
											: 'form-control'
									}`}
									onBlur={value => {
										handleNotionalChange(value)
									}}
									onKeyUp={(event, value) => {
										const notionalString = event.currentTarget.value
										const notional = Number(notionalString.replace(/,/g, ''))
										handleNotionalChange(notional)
									}}
								/>
							</div>
						</div>
						<div className='custom-input col-8 mt-3'>
							<FsSingleSelectDropdown
								title='Currency'
								id={field.CURRENCY}
								titleClass={getMandatoryClass(mandatory, field.CURRENCY)}
								ddClass='form-sm'
								noSelectionText='Select'
								selectedItem={data[field.CURRENCY] ? data[field.CURRENCY].label : ''}
								dropdownItems={Config.activeConfig.peerToPeer.orderEntry.currencies}
								keyField='id'
								valueField='label'
								onSelect={item => onChange(field.CURRENCY, item)}
							/>
						</div>
					</div>
					<div className='row'>
						{data['collateralType'] !== bidOfferType.collateral && (
							<div className='mt-3 col-8'>
								<div className='custom-input'>
									<label className='dd-label trade-label mandatory*'>Market Price</label>
									<FsNumberField
										sixDecimal
										allowDecimal
										value={data['price']}
										onBlur={value => {
											onChange('price', value)

											const dirtyPrice = getDirtyPriceData(data[field.STRATEGY], value, data['haircut'])
											onChange('dirtyPrice', dirtyPrice)

											const startCash = getStartCashData(
												data['quantity'],
												dirtyPrice,
												data['singleStock'],
												data[field.STRATEGY],
												data['collateralBasket']
											)
											onChange(field.START_CASH, startCash)

											const repoInterest = getRepoInterestData(
												startCash,
												data['allInRate'],
												duration,
												data[field.STRATEGY]
											)
											onChange('repoInterest', repoInterest)

											const endCash = getEndCashData(data[field.STRATEGY], startCash, repoInterest)
											onChange('endCash', endCash)

											onChange(
												field.NOTIONAL,
												getNotionalData(
													data['singleStock'],
													value,
													data['quantity'],
													data['collateralBasket'],
													data['collateralType']
												)
											)
										}}
										className='form-control'
										disabled={data['collateralType'] === bidOfferType.decideLater || isSecurityInvalid}
									/>
								</div>
							</div>
						)}
						<div className='mt-3 col-8'>
							<div className='custom-input'>
								<label className='dd-label trade-label mandatory*'>Margin</label>
								<FsNumberField
									allowDecimal
									disabled
									value={data['margin']}
									vacant={
										data['collateralType'] === bidOfferType.decideLater ||
										data['collateralType'] === bidOfferType.collateral
									}
									onBlur={value => {
										onChange('haircut', value)

										const dirtyPrice = getDirtyPriceData(data[field.STRATEGY], data['price'], value)
										onChange('dirtyPrice', dirtyPrice)

										const startCash = getStartCashData(
											data['quantity'],
											dirtyPrice,
											data['singleStock'],
											data[field.STRATEGY],
											data['collateralBasket']
										)
										onChange(field.START_CASH, startCash)

										const repoInterest = getRepoInterestData(
											startCash,
											data['allInRate'],
											duration,
											data[field.STRATEGY]
										)
										onChange('repoInterest', repoInterest)

										const endCash = getEndCashData(data[field.STRATEGY], startCash, repoInterest)
										onChange('endCash', endCash)
									}}
									className='form-control'
								/>
							</div>
						</div>
						{data[field.STRATEGY] && data[field.STRATEGY].value === fieldOption.STRATEGY.REPO && (
							<div className='mt-3 col-8'>
								<div className='custom-input'>
									<label className='dd-label trade-label mandatory*'>All In Price</label>
									<FsNumberField
										sixDecimal
										disabled
										allowDecimal
										value={data['dirtyPrice']}
										vacant={
											data['collateralType'] === bidOfferType.decideLater ||
											data['collateralType'] === bidOfferType.collateral
										}
										onBlur={value => {
											onChange('dirtyPrice', value)

											const startCash = getStartCashData(
												data['quantity'],
												value,
												data['singleStock'],
												data[field.STRATEGY],
												data['collateralBasket']
											)
											onChange(field.START_CASH, startCash)

											const repoInterest = getRepoInterestData(
												startCash,
												data['allInRate'],
												duration,
												data[field.STRATEGY]
											)
											onChange('repoInterest', repoInterest)

											const endCash = getEndCashData(data[field.STRATEGY], startCash, repoInterest)
											onChange('endCash', endCash)
										}}
										className='form-control'
									/>
								</div>
							</div>
						)}
					</div>
					{data[field.STRATEGY] && data[field.STRATEGY].value === fieldOption.STRATEGY.REPO && (
						<div className='row'>
							<div className='custom-input col-8 mt-3'>
								<div
									className='custom-input'
									title={isAvlblQuantityErrorStartCash || isAvlblQuantityError ? avlblQuantityErrorMessage : ''}
								>
									<label className={`dd-label trade-label ${getMandatoryClass(mandatory, field.START_CASH)}`}>
										Start Cash
									</label>
									<FsNumberField
										disabled={!isStartCashEditable}
										className={` ${
											(
												data['collateralType']
													? isAvlblQuantityErrorStartCash || isAvlblQuantityError || data[field.START_CASH] === 0
													: false
											)
												? 'input_error'
												: 'form-control'
										}`}
										value={Math.round(data[field.START_CASH])}
										onBlur={value => {
											let quantity = getQuantityFromStartCash(value, data['dirtyPrice'], data['singleStock'])
											handleStartCashChange(value, quantity)
										}}
										onKeyUp={(event, value) => {
											let startCash = event.currentTarget.value
											let quantity = getQuantityFromStartCash(
												Number(startCash.replace(/,/g, '')),
												data['dirtyPrice'],
												data['singleStock']
											)
											handleStartCashChange(Number(startCash.replace(/,/g, '')), quantity)
										}}
									/>
								</div>
							</div>
							<div className='mt-3 col-8'>
								<div className='custom-input'>
									<label className='dd-label trade-label mandatory*'>Repo Interest</label>
									<FsNumberField
										disabled
										value={data['repoInterest']}
										onBlur={value => {
											onChange('repoInterest', value)
										}}
										className='form-control'
									/>
								</div>
							</div>
							<div className='mt-3 col-8'>
								<div className='custom-input'>
									<label className={`dd-label trade-label mandatory*`}>End Cash</label>
									<FsNumberField
										disabled
										value={Math.round(data['endCash'])}
										onBlur={value => {
											onChange('haircut', value)

											const dirtyPrice = getDirtyPriceData(data[field.STRATEGY], data['price'], value)
											onChange('dirtyPrice', dirtyPrice)

											const startCash = getStartCashData(
												data['quantity'],
												dirtyPrice,
												data['singleStock'],
												data[field.STRATEGY],
												data['collateralBasket']
											)
											onChange(field.START_CASH, startCash)

											const repoInterest = getRepoInterestData(
												startCash,
												data['allInRate'],
												duration,
												data[field.STRATEGY]
											)
											onChange('repoInterest', repoInterest)

											const endCash = getEndCashData(data[field.STRATEGY], startCash, repoInterest)
											onChange('endCash', endCash)
										}}
										className='form-control'
									/>
								</div>
							</div>
						</div>
					)}
					<div className='row mt-3'>
						<div className='datepicker-wrapper col-8'>
							<FsSingleSelectDropdown
								title='Maturity Type'
								id={field.MATURITY_TYPE}
								titleClass={getMandatoryClass(mandatory, field.MATURITY_TYPE)}
								ddClass='form-sm'
								noSelectionText='Select'
								selectedItem={data[field.MATURITY_TYPE] ? data[field.MATURITY_TYPE].label : ''}
								dropdownItems={getMaturityType(Config.activeConfig.peerToPeer.orderEntry[field.MATURITY_TYPE])}
								keyField='id'
								valueField='label'
								onSelect={item => {
									onChange(field.MATURITY_TYPE, item)

									const currentDate = moment()
									const maturityDate = addBusinessDays(currentDate, item.value === 'Extendable' ? 2 : 1)
									onChange(field.TRADE_DATE, currentDate)
									forwardStart && onChange(field.FORWARD_START, currentDate)
									onChange(field.SETTLEMENT_DATE, currentDate)
									if (item.value === 'Overnight') {
										onChange(field.MATURITY_DATE, maturityDate)
										onChange('terminationDate', maturityDate)
									} else if (item.value === 'Extendable') {
										const extendableDate = addBusinessDays(currentDate, 1)
										onChange(field.MATURITY_DATE, maturityDate)
										onChange(field.EXTENSION_DATE, extendableDate)
									}
									const duration = String(getDaysDifference(maturityDate, currentDate))
									const repoInterest = getRepoInterestData(
										data[field.START_CASH],
										data['allInRate'],
										duration,
										data[field.STRATEGY]
									)
									onChange('repoInterest', repoInterest)

									const endCash = getEndCashData(data[field.STRATEGY], data[field.START_CASH], repoInterest)
									onChange('endCash', endCash)
								}}
							/>
						</div>

						<div className='datepicker-wrapper col-8'>
							<label className={getMandatoryClass(mandatory, field.TRADE_DATE)}>Trade Date</label>
							<FsDatePicker
								disabled={disableTradeAndSettleDate}
								dateFormat='YYYY-MM-DD'
								selected={data[field.TRADE_DATE] ? data[field.TRADE_DATE] : getDateFromArray([])}
								onChange={date => {
									onChange(field.TRADE_DATE, date)
									if (data['forwardStart']) onChange('forwardStart', date)
									onChange(
										field.MATURITY_DATE,
										addBusinessDays(
											moment(date),
											data[field.MATURITY_TYPE] && data[field.MATURITY_TYPE].value === 'Extendable' ? 2 : 1
										)
									)
									onChange('terminationDate', addBusinessDays(moment(date), 1))
									onChange(field.SETTLEMENT_DATE, date)
									if (data[field.MATURITY_TYPE] && data[field.MATURITY_TYPE].value === 'Extendable') {
										const extendableDate = addBusinessDays(date, 1)
										onChange(field.EXTENSION_DATE, extendableDate)
									}

									const duration = String(getDaysDifference(addBusinessDays(moment(date), 1), date))
									//addBusinessDays(moment(date), 1) used above in duration is for maturity date
									const repoInterest = getRepoInterestData(
										data[field.START_CASH],
										data['allInRate'],
										duration,
										data[field.STRATEGY]
									)
									onChange('repoInterest', repoInterest)

									const endCash = getEndCashData(data[field.STRATEGY], data[field.START_CASH], repoInterest)
									onChange('endCash', endCash)
								}}
								minDate={new Date()}
								popperPlacement='bottom'
								filterDate={isValidDate}
								onFocus={e => e.target.blur()}
							/>
							<span className='calendar-icon'>
								<CalendarIcon />
							</span>
						</div>
						{data[field.MATURITY_TYPE] && data[field.MATURITY_TYPE].value === 'Extendable' && (
							<div className='datepicker-wrapper col-8'>
								<label className={getMandatoryClass(mandatory, field.EXTENSION_DATE)}>Extension Date</label>
								<FsDatePicker
									dateFormat='YYYY-MM-DD'
									selected={data[field.EXTENSION_DATE] ? data[field.EXTENSION_DATE] : getDateFromArray([])}
									onChange={date => {
										onChange(field.EXTENSION_DATE, date)
									}}
									minDate={addBusinessDays(data[field.TRADE_DATE], 1)}
									popperPlacement='top'
									filterDate={isExtensionValidDate}
									onFocus={e => e.target.blur()}
								/>
								<span className='calendar-icon'>
									<CalendarIcon />
								</span>
							</div>
						)}
						{!isSTT && (
							<div
								className={`datepicker-wrapper  datepicker-left 
            ${
							data[field.MATURITY_TYPE] && data[field.MATURITY_TYPE].value === 'Extendable'
								? ' row mt-3 w-100'
								: 'col-8'
						}`}
							>
								<div
									className={` mb-0 ${
										data[field.MATURITY_TYPE] && data[field.MATURITY_TYPE].value === 'Extendable' && 'ml-1 col-8'
									}`}
								>
									<Form.Check
										className='d-flex align-items-center f-14'
										custom
										type='checkbox'
										id={`custom-checkbox`}
										label={
											<div className='d-flex flex-column'>
												<span>Forward Start</span>
											</div>
										}
										checked={forwardStart}
										onChange={e => {
											onChange(field.FORWARD_START, e.target.checked ? data[field.TRADE_DATE] : null)
											onChange(field.MATURITY_DATE, addBusinessDays(data[field.TRADE_DATE], 1))
											onChange('terminationDate', addBusinessDays(data[field.TRADE_DATE], 1))
											onChange(field.SETTLEMENT_DATE, data[field.TRADE_DATE])
											toggleForwardStart()
										}}
										disabled={isSTT}
									/>
								</div>
								{forwardStart && (
									<div
										className={`${
											data[field.MATURITY_TYPE] && data[field.MATURITY_TYPE].value === 'Extendable' && ' ml-1 col-8'
										}`}
									>
										<DatePicker
											dateFormat='YYYY-MM-DD'
											selected={data['forwardStart'] ? data['forwardStart'] : getDateFromArray([])}
											onChange={date => {
												onChange('forwardStart', date)
												onChange(field.MATURITY_DATE, addBusinessDays(moment(date), 1))
												onChange('terminationDate', addBusinessDays(moment(date), 1))
												onChange(field.SETTLEMENT_DATE, date)
											}}
											minDate={data[field.TRADE_DATE] ? data[field.TRADE_DATE] : new Date()}
											filterDate={isValidDate}
											popperPlacement='bottom'
										/>
										<span className='calendar-icon'>
											<CalendarIcon />
										</span>
									</div>
								)}
							</div>
						)}
					</div>

					<div className='row mt-3'>
						<div className='datepicker-wrapper col-8'>
							<label className={getMandatoryClass(mandatory, field.SETTLEMENT_DATE)}>Settlement Date</label>
							<DatePicker
								className={disableTradeAndSettleDate ? 'disabled-field' : ''}
								disabled={disableTradeAndSettleDate}
								dateFormat='YYYY-MM-DD'
								selected={data[field.SETTLEMENT_DATE] ? data[field.SETTLEMENT_DATE] : getDateFromArray([])}
								onChange={date => onChange(field.SETTLEMENT_DATE, date)}
								minDate={
									isSTT ? data[field.MATURITY_DATE] : forwardStart ? data['forwardStart'] : data[field.TRADE_DATE]
								}
								popperPlacement='top'
								filterDate={isSTT ? isValidDate : isSettlementDateValid}
							/>
							<span className='calendar-icon'>
								<CalendarIcon />
							</span>
						</div>
						<div className='datepicker-wrapper col-8 datepicker-left'>
							<label className={getMandatoryClass(mandatory, field.MATURITY_DATE)}>End Date</label>
							<DatePicker
								className={IsDisabledDatePickerClass(
									!isSTT,
									data[field.MATURITY_TYPE] && data[field.MATURITY_TYPE].value === 'Open'
								)}
								disabled={!isSTT && data[field.MATURITY_TYPE] && data[field.MATURITY_TYPE].value === 'Open'}
								dateFormat='YYYY-MM-DD'
								selected={data[field.MATURITY_DATE] ? data[field.MATURITY_DATE] : getDateFromArray([])}
								onChange={date => {
									onChange(field.MATURITY_DATE, date)
									onChange('terminationDate', date)

									if (
										data[field.MATURITY_TYPE] &&
										data[field.MATURITY_TYPE].value === 'Extendable' &&
										data[field.EXTENSION_DATE] >= date
									) {
										const extendableDate = substractBusinessDays(date, -1)
										onChange(field.EXTENSION_DATE, extendableDate)
									}

									const duration = String(getDaysDifference(date, data[field.TRADE_DATE]))
									const repoInterest = getRepoInterestData(
										data[field.START_CASH],
										data['allInRate'],
										duration,
										data[field.STRATEGY]
									)
									onChange('repoInterest', repoInterest)

									const endCash = getEndCashData(data[field.STRATEGY], data[field.START_CASH], repoInterest)
									onChange('endCash', endCash)
								}}
								minDate={addBusinessDays(
									data[field.FORWARD_START] ? data[field.FORWARD_START] : data[field.TRADE_DATE],
									data[field.MATURITY_TYPE] && data[field.MATURITY_TYPE].value === 'Extendable' ? 2 : 1
								)}
								popperPlacement='top'
								filterDate={isValidDate}
								onFocus={e => e.target.blur()}
							/>
							<span className='calendar-icon'>
								<CalendarIcon />
							</span>
						</div>
						<div className='custom-input col-8'>
							<label className={getMandatoryClass(mandatory, field.DURATION)}>
								Tenor (days)
								<CustomPopover id={customPopoverIds.orderEntryDuration} />
							</label>
							<FsNumberField
								disabled
								name={field.DURATION}
								value={Number(duration)}
								onBlur={value => {}}
								className='form-control'
							/>
						</div>
					</div>
					<div className='row mt-3'>
						{/* <div className='datepicker-wrapper col'>
								<label className='mandatory*'>Termination Date</label>
								<DatePicker
									dateFormat='YYYY-MM-DD'
									selected={data['terminationDate'] ? data['terminationDate'] : getDateFromArray([])}
									onChange={date => onChange('terminationDate', date)}
									minDate={new Date()}
									popperPlacement='top'
								/>
								<span className='calendar-icon'>
									<CalendarIcon />
								</span>
							</div> */}
					</div>
					<div className='row mt-3'>
						{!Config.activeConfig.peerToPeer.orderEntry[field.RESET_TYPE] && (
							<div className='d-flex align-items-center ml-2'>
								<span className='pr-3 pb-1 mandatory*'>Reset Type:</span>
								<div className='pr-4'>
									<Form.Check
										name={field.RESET_TYPE}
										type='radio'
										custom
										id='reset-yes'
										label='Yes'
										onChange={() => onChange(field.RESET_TYPE, 'YES')}
										className='pr-2'
										checked={data[field.RESET_TYPE] === 'YES'}
									/>
								</div>
								<div>
									<Form.Check
										name={field.RESET_TYPE}
										type='radio'
										custom
										id='reset-no'
										label='No'
										onChange={() => onChange(field.RESET_TYPE, 'NO')}
										checked={data[field.RESET_TYPE] === 'NO'}
									/>
								</div>
							</div>
						)}
					</div>
					<div className='row mt-3'>
						<div className='col'>
							<label className='mandatory*'>Comments</label>
							<textarea
								className='form-control px-2'
								rows={3}
								maxLength={100}
								name='comments'
								value={data[field.COMMENTS]}
								onChange={e => onChange(field.COMMENTS, e.target.value)}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
