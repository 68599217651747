import {
	INVOKE_MARGIN_ATTRIBUTION,
	ATTRIBUTION_GRAPH_FIELDS,
	CRIF_RESULTS,
	MARGIN_RESULTS,
} from './MarginAttributionActions'

export interface MarginAttribution {
	loading: boolean
	crifLoading: boolean
	marginLoading: boolean
	attributionDetails: any
	fieldsList: any
	crifData: any[]
	marginData: any[]
}
const initialState: MarginAttribution = {
	loading: false,
	crifLoading: false,
	marginLoading: false,
	attributionDetails: {},
	fieldsList: {},
	crifData: [],
	marginData: [],
}

export function MarginAttributionReducer(state = initialState, action: any) {
	switch (action.type) {
		case INVOKE_MARGIN_ATTRIBUTION.triggered:
			return {...state, loading: true, data: []}
		case INVOKE_MARGIN_ATTRIBUTION.succeeded:
			return {
				...state,
				attributionDetails: action.response && action.response.data ? action.response.data : {},
				loading: false,
			}
		case INVOKE_MARGIN_ATTRIBUTION.failed:
			return {...state, attributionDetails: {}, loading: false, data: []}

		case ATTRIBUTION_GRAPH_FIELDS:
			return {...state, fieldsList: action.fieldsList}

		case CRIF_RESULTS.triggered:
			return {...state, crifLoading: true, crifData: []}
		case CRIF_RESULTS.succeeded:
			return {
				...state,
				crifData:
					(action.response &&
						action.response.data &&
						action.response.data.result &&
						action.response.data.result.crif) ||
					[],
				crifLoading: false,
			}
		case CRIF_RESULTS.failed:
			return {...state, crifLoading: false, crifData: []}

		case MARGIN_RESULTS.triggered:
			return {...state, marginLoading: true, marginData: []}
		case MARGIN_RESULTS.succeeded:
			return {
				...state,
				marginData:
					(action.response &&
						action.response.data &&
						action.response.data.result &&
						action.response.data.result.MARGIN_IMPACT) ||
					[],
				marginLoading: false,
			}
		case MARGIN_RESULTS.failed:
			return {...state, marginLoading: false, marginData: []}
	}

	return state
}

