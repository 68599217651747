import Lodash from 'lodash'
import {GET_MENU_ITEM, DELETE_MENU_ITEM, GET_OPTIMIZATION_LIST} from './DecisionMakingDashboardAction'

export interface DecisionMakingDashboard {
	loading: boolean
	widgetsData: any
	optimizationList: any
}

const initialState: DecisionMakingDashboard = {
	loading: false,
	widgetsData: {},
	optimizationList: null,
}

export function DecisionMakingDashboardReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_MENU_ITEM.triggered:
			return {...state, loading: true}
		case GET_MENU_ITEM.succeeded:
			return {
				...state,
				loading: false,
				widgetsData: {...state.widgetsData, [action.response.data.id]: action.response.data},
			}
		case GET_MENU_ITEM.failed:
			return {...state, loading: false}

		case DELETE_MENU_ITEM:
			const widgetsData = JSON.parse(JSON.stringify(state.widgetsData))
			delete widgetsData[action.payload]
			return {...state, loading: false, widgetsData}

		case GET_OPTIMIZATION_LIST.triggered:
			return {...state, loading: true}
		case GET_OPTIMIZATION_LIST.succeeded:
			const optimizationList = Lodash.cloneDeep(action.response.data)
			return {...state, optimizationList, loading: false}
		case GET_OPTIMIZATION_LIST.failed:
			return {...state, optimizationList: null, loading: false}
	}

	return state
}
