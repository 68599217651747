import gql from 'graphql-tag'

export const getAllFilesQuery = gql`
	query {
		benchmarkWeights {
			uid
			originalFileName
			isError
			errorList
			entries {
				index
				assetType
				name
				weight
				uid
				isError
			}
		}
	}
`
