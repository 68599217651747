import DataScience from '../../../styles/svg/data-science.svg'
import P2PPlatform from '../../../styles/svg/p2p-platform.svg'
import PowerOfAlgorithm from '../../../styles/svg/power-of-algorithm.svg'

export const loginCarousel = [
	{
		title: 'P2P Platform',
		description: 'P2P Repo',
		icon: P2PPlatform,
	},
	{
		title: 'Data Science',
		description: 'ABCM Report | Portfolio Analytics | Decision Making',
		icon: DataScience,
	},
	{
		title: 'Power of Algorithm',
		description: 'Intelligent Analytics | Unique Insights | Predictive Tools',
		icon: PowerOfAlgorithm,
	},
]
