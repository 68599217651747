import {GET_PORTFOLIO_STATUS} from './portfolioStatusAction'

export interface PortfolioStatus {
	portfolioStatusData: any
}

const initialState: PortfolioStatus = {
	portfolioStatusData: null,
}

export function portfolioStatusReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_PORTFOLIO_STATUS.triggered:
			return {...state, portfolioStatusData: null}
		case GET_PORTFOLIO_STATUS.succeeded:
			const currentPortfolio = action.response.data && action.response.data.currentPortfolio
			const isStale = currentPortfolio ? currentPortfolio.isStale : null
			const createdDate = currentPortfolio ? currentPortfolio.createdDate : null
			const portfolioDocuments = currentPortfolio ? currentPortfolio.portfolioDocuments : null
			const portfolioStatusData = {
				isStale,
				createdDate,
				portfolioDocuments,
			}
			return {...state, portfolioStatusData}
		case GET_PORTFOLIO_STATUS.failed:
			return {...state, portfolioStatusData: null}
	}
	return state
}
