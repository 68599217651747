import {GET_SECURITIES, RESET_INVENTORY} from './inventoryActions'
import {InventorySecurity} from './inventoryModel'

export interface Inventory {
	loading: boolean
	data: InventorySecurity[]
}

const initialState: Inventory = {
	loading: true,
	data: [],
}

export function inventoryReducer(state = initialState, action: any) {
	let data: InventorySecurity[] = []
	switch (action.type) {
		case GET_SECURITIES.triggered:
			return {...state, loading: true}
		case GET_SECURITIES.succeeded:
			data = (action.response.data && action.response.data.inventory) || []
			return {...state, loading: false, data}
		case GET_SECURITIES.failed:
			return {...state, loading: false}

		case RESET_INVENTORY:
			return {...state, ...initialState}
	}
	return state
}
