import {getPMSClient} from '../..'
import {createFetchActions} from '../../services/createActions'
import {abcmRerunTimingQuery} from './abcmStatusQueries'

export const GET_ABCM_RERUN_TIMING = createFetchActions('GET_ABCM_RERUN_TIMING')
export const SET_ABCM_RERUN_TIMING = 'SET_ABCM_RERUN_TIMING'

export const setAbcmRerunTiming = (pendingAbcmStatus: any) => {
	return {
		type: SET_ABCM_RERUN_TIMING,
		response: {
			data: pendingAbcmStatus,
		},
	}
}

export function getAbcmRerunTiming() {
	return function (dispatch) {
		dispatch({
			type: GET_ABCM_RERUN_TIMING.triggered,
		})

		getPMSClient()
			.query({
				query: abcmRerunTimingQuery,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_ABCM_RERUN_TIMING.succeeded,
					response,
				})
			})
	}
}
