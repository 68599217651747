import {batchActions} from 'redux-batched-actions'
import {setHeaders as apiService_setHeaders, callAPI as apiService_callAPI} from '../services/apiService'
import {IAction} from '../services/createActions'
import {handleHTTPError} from '../pages/errorHandler/errorHandlerActions'
export const CALL_API = 'Call API'

// declare var require: any;
// let batch = require('redux-batched-actions');

/**
 * A Redux middleware that interprets actions with CALL_API info specified.
 * Performs the call and promises when such actions are dispatched.
 */

export function executeAPI(action: any, addAccessToken = true) {
	return (dispatch: any, getState: any) => {
		let callAPI = action[CALL_API]

		callAPI = apiService_setHeaders(callAPI)

		let {url} = callAPI
		const {types} = callAPI

		if (typeof url === 'function') {
			url = url(getState())
		}

		if (typeof url !== 'string') {
			throw new Error('Specify a string endpoint URL.')
		}
		let type: IAction = types
		if (!(type.triggered && type.succeeded && type.failed)) {
			throw new Error('Expected all 3 action types.')
		}

		function actionWith(data: any) {
			const finalAction = Object.assign({}, action, data)
			delete finalAction[CALL_API]
			return finalAction
		}

		const {triggered, succeeded} = type
		dispatch(actionWith({type: triggered}))

		if (callAPI.isFile) {
			const formData = new FormData()
			callAPI.data.forEach((el: any) => {
				formData.append('file', el)
			})
			callAPI.data = formData
			callAPI.headers['Content-Type'] = 'multipart/form-data'
		}
		return apiService_callAPI(callAPI)
			.then((response: any) => {
				let returnObject = {
					response,
					type: succeeded,
					params: action.params,
				}
				if (action.actionData && action.actionData.successMessage && action.actionData.successMessage.length > 0) {
					dispatch(batchActions([actionWith(returnObject)]))
				} else {
					dispatch(actionWith(returnObject))
				}
				return returnObject
			})
			.catch(response => {
				let returnObject = {
					response,
					type: type.failed,
					action,
					correlationId: callAPI.headers['correlation-id'],
				}
				dispatch(batchActions([actionWith(returnObject), handleHTTPError(returnObject)]))
			})
	}
}
