import gql from 'graphql-tag'
import {getCounterpartyId} from '../../../services/authService'

export const getMyAccountsQuery = gql`
	query {
		allAccounts(tenantId: "${getCounterpartyId()}") {
			accountId
			id
			name
			accountType
			portfolioId
			tenantId
		}
	}
`
