import {createFetchActions} from '../../../../services/createActions'

export const SET_REJECTEDCOLLATERALBASKETARRAYIDS = createFetchActions('SET_REJECTEDCOLLATERALBASKETARRAYIDS')
export const SET_BASKETTABLEDATA = createFetchActions('SET_BASKETTABLEDATA')

export const setrejectedCollateralBasketArrayIds = rejectedCollateralBasketArrayIds => (dispatch: any) => {
	dispatch({
		type: SET_REJECTEDCOLLATERALBASKETARRAYIDS.succeeded,
		data: rejectedCollateralBasketArrayIds,
	})
}

export const setBasketTableData = rejectedCollateralBasketArrayIds => (dispatch: any) => {
	dispatch({
		type: SET_BASKETTABLEDATA.succeeded,
		data: rejectedCollateralBasketArrayIds,
	})
}
