import gql from 'graphql-tag'

export const getAllFilesQuery = gql`
	query {
		marginDocuments {
			uid
			originalFileName
			isError
			errorList
			entries {
				uid
				counterParty
				marginToday
				marginType
				marginPrev
				marginDiff
				isError
			}
		}
	}
`
