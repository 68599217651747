import {FETCH_COLLATERAL_OPTIMIZATION_DETAILS_DATA} from './CollateralOptimizationTradesActions'

export interface CollateralOptimizationTrades {
	loading: boolean
	collateralTradesData: any
	collOptDetailsColDefs: any
}

const initialState: CollateralOptimizationTrades = {
	loading: false,
	collateralTradesData: [],
	collOptDetailsColDefs: [],
}
export function CollateralOptimizationTradesReducer(state = initialState, action: any) {
	switch (action.type) {
		case FETCH_COLLATERAL_OPTIMIZATION_DETAILS_DATA.triggered:
			return {...state, loading: true, collateralTradesData: [], collOptDetailsColDefs: []}

		case FETCH_COLLATERAL_OPTIMIZATION_DETAILS_DATA.failed:
			return {
				...state,
				loading: false,
				collateralTradesData: [],
				collOptDetailsColDefs: [],
			}

		case FETCH_COLLATERAL_OPTIMIZATION_DETAILS_DATA.succeeded:
			const collateralOptimizationDetails = action.response.data ? action.response.data.details : []
			const colDefs = action.response.data ? action.response.data.colDefs : []
			return {
				...state,
				loading: false,
				collateralTradesData: collateralOptimizationDetails,
				collOptDetailsColDefs: colDefs,
			}
	}

	return state
}
