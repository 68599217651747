import {createFetchActions} from '../../services/createActions'
import {getPMSClient} from '../..'
import {getSecurities} from './inventoryQueries'

export const GET_SECURITIES = createFetchActions('GET_SECURITIES')
export const RESET_INVENTORY = 'RESET_INVENTORY'

export const getInventorySecurities = () => {
	return function (dispatch) {
		dispatch({type: GET_SECURITIES.triggered})
		getPMSClient()
			.query({
				query: getSecurities,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_SECURITIES.succeeded,
					response,
				})
			})
	}
}

export const resetInventory = () => {
	return {
		type: RESET_INVENTORY,
	}
}
