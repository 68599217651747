import {buildQuery} from 'axios-graphql-builder'
import gql from 'graphql-tag'

export const getBasketDetailsQuery = () => {
	const avnDocument = {
		id: null,
		counterPartyName: null,
		originalFilename: null,
		type: null,
		match: null,
		potentialMatch: null,
		notional: null,
		totalAvailableNotional: null,
		rank: null,
		matchedNotional: null,
		matchedAgainstCptyAvailability: null,
		optimalMatchNotional: null,
		matchedAgainstNeeds: null,
		cashPercent: null,
		nonCashPercent: null,
		createdDate: null,
		collateralType: null,
	}

	const avnActivities = {
		orderId: null,
		counterParty: null,
		counterPartyTenantId: null,
		isOwner: null,
		securityId: null,
		securityPrice: {
			amount: null,
			currency: null,
		},
		exchangeRate: null,
		availableQuantity: null,
		original: null,
		allocated: null,
		allocatedNotional: null,
		rebateRate: null,
		feeRate: null,
		name: null,
		isin: null,
		needQuantity: null,
		needsNotional: null,
		status: null,
		collateralType: null,
		actions: null,
	}

	const avnIncomingOrderDetails = {
		orderId: null,
		counterParty: null,
		counterPartyTenantId: null,
		isOwner: null,
		securityId: null,
		securityPrice: {
			amount: null,
			currency: null,
		},
		exchangeRate: null,
		availableQuantity: null,
		allocated: null,
		allocatedNotional: null,
		original: null,
		rebateRate: null,
		feeRate: null,
		name: null,
		isin: null,
		needQuantity: null,
		needsNotional: null,
		status: null,
		collateralType: null,
		actions: null,
		sodAvailableQuantity: null,
		assetUtilizationPercent: null,
		assetUtilizationImpactPercent: null,
		lendingTargetQuantity: null,
		currentAvailableQuantity: null,
	}

	const basketDetails = {
		query: {
			availabilitiesNeeds: {
				algorithm: null,
				userAvnActivities: {
					open: {
						...avnDocument,
					},
					outgoing: {
						counterPartyName: null,
						matchedNotional: null,
						totalAvailableNotional: null,
						matchedAgainstCptyAvailability: null,
						matchedAgainstTotalNeeds: null,
						orderEntries: {
							...avnActivities,
						},
					},
				},
				firmAvnActivities: {
					incoming: {
						counterPartyName: null,
						orderDetails: {
							...avnIncomingOrderDetails,
						},
					},
					outgoing: {
						totalAvailableNotional: null,
						counterPartyName: null,
						matchedNotional: null,
						matchedAgainstCptyAvailability: null,
						matchedAgainstTotalNeeds: null,
						orderEntries: {
							...avnActivities,
						},
					},
				},
			},
		},
	}

	return buildQuery(basketDetails)
}

export const getPbQuery = () => {
	const pb = {
		query: {
			pBAxeDocuments: {
				documentId: null,
				tenantId: null,
				groupId: null,
				originalFilename: null,
				remoteFilename: null,
				uploadedDate: null,
				counterParty: null,
				counterPartyName: null,
				type: null,
				rank: null,
				isError: null,
				tempLocation: null,
				createdDate: null,
				errorList: null,
				entries: {
					pbAxeDocumentEntryId: null,
					bbgTicker: null,
					qty: null,
					sedol: null,
					cusip: null,
					allocated: null,
					rate: null,
					error: null,
				},
			},
		},
	}

	return buildQuery(pb)
}

export const getSwapQuery = () => {
	const swap = {
		query: {
			swapDocuments: {
				documentId: null,
				tenantId: null,
				groupId: null,
				originalFilename: null,
				remoteFilename: null,
				uploadedDate: null,
				counterParty: null,
				counterPartyName: null,
				type: null,
				rank: null,
				isError: null,
				tempLocation: null,
				createdDate: null,
				errorList: null,
				entries: {
					swapDocumentEntryId: null,
					ticker: null,
					quantity: null,
					sedol: null,
					allocated: null,
					spread: null,
					direction: null,
					instrumentCcy: null,
					settlementCcy: null,
					benchmarkRate: null,
					cashNeutral: null,
					error: null,
				},
			},
		},
	}

	return buildQuery(swap)
}

export const requestLoanDetailsQuery = (id = '') => gql`
{
	availNeedsOpenDetails(id: "${id}") {
    id
    name
    securityId
	price
	exchangeRate
    collateralType
	counterPartyQuantity
	counterPartyNotional
	needsQuantity
	needsNotional
    allocatedQuantity
    allocatedNotional
    isin
    rebateRate
    currency
    feeRate
  }
}
`

export const getDropdownDataQuery = (type = '') => gql`
query {
  availAndNeedDropDowns(type:${type}){
    assetTypes
    assetSubTypes
    indices
    funds
    ratings
    sectors
  }
}
`

export const getBasketFileDataQuery = () => gql`
	query {
		availabilitiesNeedsBaskets {
			availNeedBasketId
			name
			currency
			assetTypes
			type
			notional
			totalQuantity
			availNeedBasketItems {
				availNeedBasketItemId
				securityId
				price
				needQuantity
				notional
				isin
				feeRate
				rebateRate
			}
		}
	}
`

export const getBasketOverviewQuery = () => gql`
	query {
		portfolio {
			overView {
				availabilities {
					amount
					currency
				}
				needs {
					amount
					currency
				}
			}
		}
	}
`

export const activityLogQuery = (counterParty: string, way: string, view: String) => gql`
	query{
		availNeedOrderLogs(counterParty:"${counterParty}",way:${way}, view:${view}){
		description
		level
		direction
		createdDate
    	}
    }
`

export const fetchSearchSecurityResultQuery = security => {
	return gql`
		query {
			searchAvailabilitySecurityResults(
				searchSecurity: {
					searchSecurityEntry: [ 
            ${security}
					]
				}
			) {
				securityId
				  requestedQuantity
        	totalAvailableQuantity
          approvedQuantity
          locateRequestId
          locateRequestedBy
				availabilitiesNeedsDocumentSearchEntries {
          securityId
					availableQuantity
					rebateRate
					feeRate
					counterPartyName
					documentId
					documentEntryId
          clientRank
          approvedQuantity
          filledFlag
          shortFall
          locateRequestId
          id
          name
          price
          exchangeRate
          counterPartyQuantity
          counterPartyNotional
          needsQuantity
          needsNotional
          allocatedQuantity
          allocatedNotional
          isin
          currency
          collateralType
          documentIdForCreateBorrow
				}
			}
		}
	`
}
