import {createFetchActions} from '../../services/createActions'
import {getPMSBaseUrl} from '../../services/apiService'
import {get, post, put} from '../../services/createAPICall'

export const RECENT_TRADE_BLOTTER_ERROR = createFetchActions('RECENT_TRADE_BLOTTER_ERROR')

export const UPLOAD_TRADE_BLOTTER = createFetchActions('UPLOAD_TRADE_BLOTTER')
export const FINALIZE_TRADE_BLOTTER_UPLOAD = createFetchActions('FINALIZE_TRADE_BLOTTER_UPLOAD')
export const DELETE_TRADE = createFetchActions('DELETE_TRADE')
export const GET_TRADES = createFetchActions('GET_TRADES')
export const GET_DEPO_OPTION = createFetchActions('GET_DEPO_OPTION')

export const uploadTradeBlotter = (tradeType: string, data: any) => (dispatch: any) => {
	const headers = {
		'Content-Type': 'multipart/form-data',
	}
	return dispatch(
		post(UPLOAD_TRADE_BLOTTER, `${getPMSBaseUrl()}/v1/tradeBlotter/upload?type=${tradeType}`, data, headers)
	)
}

export const finalizeTradeBlotterUpload = (documentId: string, allowToProceed: boolean) => (dispatch: any) => {
	return dispatch(
		put(
			FINALIZE_TRADE_BLOTTER_UPLOAD,
			`${getPMSBaseUrl()}/v1/tradeBlotter/upload/process?documentId=${documentId}&allowToProceed=${allowToProceed}`
		)
	)
}

export const getRecentTradeBlotterError = errorModalId => (dispatch: any) => {
	return dispatch(
		get(RECENT_TRADE_BLOTTER_ERROR, `${getPMSBaseUrl()}/v1/tradeBlotter/upload/error/?id=${errorModalId}`)
	)
}

export const deleteTrade = (id: any) => (dispatch: any) => {
	return dispatch(put(DELETE_TRADE, `${getPMSBaseUrl()}/v1/tradeBlotter/archive/${id}`))
}

export const getTrades = () => dispatch => {
	return dispatch(get(GET_TRADES, `${getPMSBaseUrl()}/v1/tradeBlotter`))
}

export const getDepoOption = () => dispatch => {
	return dispatch(get(GET_DEPO_OPTION, `${getPMSBaseUrl()}/v1/mds/depo`))
}
