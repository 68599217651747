export const replaceMultipleSpaces = val => {
	if (val && typeof val === 'string') {
		return val.replace(/\s\s+/g, ' ')
	}
	return null
}

export const restrictSpecialCharacterFn = event => {
	const enableSpecialCharacters = ['stt-qa', 'qa', 'dev'].includes(process.env.REACT_APP_ENV)

	const regex = new RegExp('^[a-zA-Z0-9 ]+$')
	if (enableSpecialCharacters && !regex.test(event.key)) {
		event.preventDefault()
		return false
	}
}

export const usernameCharacterValidationFn = event => {
	const enableSpecialCharacters = ['stt-qa', 'qa', 'dev'].includes(process.env.REACT_APP_ENV)
	const regex = new RegExp('^[a-zA-Z0-9.@_ ]+$')
	const atCount = (event.target.value.match(new RegExp('@', 'g')) || []).length
	const underscoreCount = (event.target.value.match(new RegExp('_', 'g')) || []).length
	const key = event.key

	const restrictIfTrue = !regex.test(key) || (key === '@' && atCount >= 1) || (key === '_' && underscoreCount >= 1)

	if (enableSpecialCharacters && key !== 'Backspace' && restrictIfTrue) {
		event.preventDefault()
		return false
	}
}
