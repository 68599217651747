import {FormatUtil} from 'helper-util'
import {gqlErrors} from './constants/graphQLErrors'
import {executeGraphQLErrorHandler} from './pages/errorHandler/errorHandlerActions'
import * as Sentry from '@sentry/browser'

export const gqlErrorHandler = (graphQLErrors, networkError, mainStore, headers, extraVariables?) => {
	const gqlError =
		(Array.isArray(graphQLErrors) && graphQLErrors.length > 0 && graphQLErrors[0].extensions) ||
		(Array.isArray(graphQLErrors) && graphQLErrors.length > 0 && graphQLErrors[0])

	const netError = networkError && networkError.result && networkError.result.message
	const netErrorCode = networkError && networkError.result && networkError.result.code
	const errorMessage = gqlError ? (gqlError.errorMessage ? gqlError.errorMessage : gqlError.message) : netError
	const correlationId = headers.get('correlation-id')
	Sentry.captureMessage({...errorMessage, correlationId})

	if (extraVariables && extraVariables.skipErrorModal) {
		return
	}
	switch (gqlError && gqlError.httpStatus && FormatUtil.text.toLowerCase(gqlError.httpStatus)) {
		case gqlErrors.forbidden:
			mainStore.dispatch(executeGraphQLErrorHandler(errorMessage, netErrorCode))
			break
		default:
			// TODO
			mainStore.dispatch(executeGraphQLErrorHandler(errorMessage, netErrorCode))
	}
}
