import {createFetchActions} from '../../services/createActions'
import {getPMSBaseUrl} from '../../services/apiService'
import {put} from '../../services/createAPICall'
import {getPMSClient} from '../..'
import {intraDayActivitiesQuery} from '../intradayActivities/intradayActivitiesQueries'

export const GET_ALL_TRADES_FOR_OPERATIONS = createFetchActions('GET_ALL_TRADES_FOR_OPERATIONS')
export const UPDATE_SETTLEMENT_STATUS = createFetchActions('UPDATE_SETTLEMENT_STATUS')

export function getAllTrades() {
	return function (dispatch) {
		dispatch({
			type: GET_ALL_TRADES_FOR_OPERATIONS.triggered,
		})

		getPMSClient()
			.query({
				query: intraDayActivitiesQuery,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_ALL_TRADES_FOR_OPERATIONS.succeeded,
					response,
				})
			})
			.catch(() => {})
	}
}

export const updateSettlementStatus = payload => (dispatch: any) => {
	return dispatch(put(UPDATE_SETTLEMENT_STATUS, `${getPMSBaseUrl()}/v1/portfolios/settlement-status`, payload))
}
