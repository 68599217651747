import {getALPBaseUrl} from '../../../services/apiService'
import {createFetchActions} from '../../../services/createActions'
import {get, post} from '../../../services/createAPICall'

export const TOGGLE_NEGOTIATION_MODAL = createFetchActions('TOGGLE_NEGOTIATION_MODAL')
export const TOGGLE_NEGOTIATION_TRADE = createFetchActions('TOGGLE_NEGOTIATION_TRADE')
export const ON_TRADE_QTY_UPDATE = createFetchActions('ON_TRADE_QTY_UPDATE')
export const ON_QTY_BLUR = createFetchActions('ON_QTY_BLUR')
export const ON_QTY_FOCUS = createFetchActions('ON_QTY_FOCUS')
export const GER_SELECTED_TRADE_DATA = createFetchActions('GER_SELECTED_TRADE_DATA')
export const NEGOTIATE_TRADE = createFetchActions('NEGOTIATE_TRADE')

export const toggleNegotiationModal = () => {
	return {
		type: TOGGLE_NEGOTIATION_MODAL,
	}
}

export const getSelectedTradeData = tradeUuid => (dispatch: any) => {
	return dispatch(get(GER_SELECTED_TRADE_DATA, `${getALPBaseUrl()}/v1/alp/trades/${tradeUuid}`))
}

export const negotiateTrade = tradeData => (dispatch: any) => {
	return dispatch(post(NEGOTIATE_TRADE, `${getALPBaseUrl()}/v1/alp/trades/negotiate`, tradeData))
}

export const toggleNegotiationTrade = () => {
	return {
		type: TOGGLE_NEGOTIATION_TRADE,
	}
}

export const onTradeQtyUpdate = (field, value) => {
	return {
		type: ON_TRADE_QTY_UPDATE,
		payload: {
			field,
			value,
		},
	}
}
export const onQtyBlur = triggerField => {
	return {
		type: ON_QTY_BLUR,
		payload: {
			triggerField,
		},
	}
}
export const onQtyFocus = focusField => {
	return {
		type: ON_QTY_FOCUS,
		payload: {
			focusField,
		},
	}
}
