import React from 'react'
import ErrorModal from './errorModal'
import {ERROR_CONSTANTS, ERROR_MSG, ERROR_MSG_DISPLAY} from './errorBoundaryConstant'

import {history} from '../../store/mainStore'
import {paths} from '../../routes/routesConfig'
import {clearAuthToken} from '../../services/authService'
import {genericLogout} from '../../app/appHelper'

interface State {
	showModal: boolean
}

interface Props {
	displayMessage: string
	errorCode: string
	isRedirect: boolean
	forcedLogout: boolean
	authLogout: () => any
	resetError: () => any
	toggleForceLogout: () => any
}

export default class ErrorHandler extends React.Component<Props, State> {
	resetError = () => {
		const {displayMessage} = this.props
		// if (this.props.isRedirect) {
		// TODO
		// history.goBack()
		// }
		ERROR_CONSTANTS.UNAUTHORIZED.forEach(msg => {
			if (displayMessage === msg) {
				genericLogout(null, history)
			}
		})

		this.props.resetError()
	}

	redirectToLogin = errorMessage => {
		const {forcedLogout, toggleForceLogout} = this.props
		clearAuthToken()
		this.resetError()
		!forcedLogout && errorMessage === ERROR_MSG.singleLogin && toggleForceLogout()
		history && history.push(paths.abcPortfolioTest)
	}
	displayMessage = () => {
		const {displayMessage} = this.props
		let flag = false
		ERROR_CONSTANTS.UNAUTHORIZED.forEach(msg => {
			if (displayMessage === msg) {
				flag = true
			}
		})
		return flag ? ERROR_MSG_DISPLAY.displayMessage : displayMessage
	}

	render() {
		const {displayMessage, errorCode} = this.props
		if (Object.values(ERROR_MSG).includes(displayMessage) || errorCode === '002007') {
			this.redirectToLogin(displayMessage)
			return null
		}
		const displayMessageOnModal = this.displayMessage()

		return <ErrorModal errorMessage={displayMessageOnModal} resetError={this.resetError} />
	}
}
