import gql from 'graphql-tag'

export const fetchLocateDetailsQuery = () => {
	return gql`
		query {
			allAuthorizerLocateRequestEntry {
				locateRequestEntryId
				securityId
				securityListTypes
				requestedQuantity
				businessDate
				locateRequestType
				security
				securityIdType
				status
				endDate
				counterParty
				authorizer
				actions
				createdDate
				modifiedDate
				totalApprovedQuantity
				description
				marketPrice {
					amount
					currency
				}
				indicativeRateBps
				centsPerShare
				totalMarketFee
				userEmail
				notional {
					amount
					currency
				}
				ticker
			}
		}
	`
}

export const fetchLocateConfigQuery = () => {
	return gql`
		query {
			locateConfig {
				key
				value
			}
		}
	`
}

export const fetchSecurityListQuery = securityType => {
	return gql`
		query {
      securityList(type:"${securityType}"){
        security
        securityId
        indicativeRate
        ticker
      }
		}
	`
}

export const calculateMarketFeeAndCentsPerShareQuery = (marketPrice, fee, approvedQuantity) => {
	return gql`
		query {
			rate(marketPrice: ${marketPrice}, fee: ${fee}, approvedQuantity: ${approvedQuantity}) {
				centsPerShare
				totalMarketFee
        notional
			}
		}
	`
}

export const securitySuggestionsQuery = (keyword, page, size) => {
	return gql`
    query {
      securitySuggestions(keyword:"${keyword}", page:${page}, size:${size}){
        securityId
        securityIdType
        cusip
        ticker
        isin
        sedol
      }
  }
`
}

export const getLocateRequestEntryDetail = locateRequestEntryId => {
	return gql`
		query {
			locateRequestEntryDetail(locateRequestEntryId: "${locateRequestEntryId}") {
				approvedQuantity
				availableQuantity
				marketPrice {
					amount
					currency
				}
				indicativeRateBps
				centsPerShare
				totalMarketFee
				notional {
					amount
					currency
				}
			}
		}
	`
}

export const getSecurityListAuditDetailsQuery = () => {
	return gql`
		query {
			securityListAuditDetails {
				securityListType
				createdDate
				username
			}
		}
	`
}
