import {createFetchActions} from '../../../services/createActions'
import {get, post} from '../../../services/createAPICall'
import {getPMSBaseUrl} from '../../../services/apiService'

export const ABCM_INITIAL_RANKING = createFetchActions('ABCM_INITIAL_RANKING')
export const ABCM_UPDATED_RANKING = createFetchActions('ABCM_UPDATED_RANKING')
export const ABCM_UPDATED_RANKING_DETAILS = createFetchActions('ABCM_UPDATED_RANKING_DETAILS')
export const CLEAR_ABCM_RANK_DATA = 'CLEAR_ABCM_RANK_DATA'

export const getInitialRankingData = () => (dispatch: any) => {
	return dispatch(get(ABCM_INITIAL_RANKING, `${getPMSBaseUrl()}/v1/abcm-rankings/default`))
}

export const getABCMRankingDetails = (rankingId: string) => (dispatch: any) => {
	return dispatch(get(ABCM_UPDATED_RANKING_DETAILS, `${getPMSBaseUrl()}/v1/abcm-rankings/${rankingId}`))
}

export const getUpdatedRanking = (id, data) => (dispatch: any) => {
	return dispatch(post(ABCM_UPDATED_RANKING, `${getPMSBaseUrl()}/v1/portfolios/${id}/abcm`, data))
}

export const clearAbcmRankData = () => {
	return {
		type: CLEAR_ABCM_RANK_DATA,
	}
}
