import {getALPBaseUrl} from '../../../../../services/apiService'
import {createFetchActions} from '../../../../../services/createActions'
import {get, post, put} from '../../../../../services/createAPICall'

export const FETCH_AVAILABILITY_DETAILS = createFetchActions('FETCH_AVAILABILITY_DETAILS')
export const GET_AVAILABILITY_DETAILS = createFetchActions('GET_AVAILABILITY_DETAILS')
export const CLEAR_INVENTORY_DATA = createFetchActions('CLEAR_INVENTORY_DATA')
export const CREATE_AUCTION_REQUEST = createFetchActions('CREATE_AUCTION_REQUEST')
export const EDIT_STAGE_AUCTION_REQUEST = createFetchActions('EDIT_STAGE_AUCTION_REQUEST')
export const STAGE_TO_BRODCAST_AUCTION_REQUEST = createFetchActions('STAGE_TO_BRODCAST_AUCTION_REQUEST')
export const BULK_REJECT_BIDS = createFetchActions('BULK_REJECT_BIDS')
export const BULK_RELEASE_BIDS = createFetchActions('BULK_RELEASE_BIDS')
export const GET_AUCTIONS = createFetchActions('GET_AUCTIONS')
export const GET_LIVE_AUCTIONS = createFetchActions('GET_LIVE_AUCTIONS')
export const GET_STAGE_AUCTIONS = createFetchActions('GET_STAGE_AUCTIONS')

export const getAvailabilityDetails = (requestId: string) => (dispatch: any) => {
	return dispatch(get(GET_AVAILABILITY_DETAILS, `${getALPBaseUrl()}/v1/auction/availability/${requestId}`))
}

export const fetchAvailabilityDetails = payload => (dispatch: any) => {
	return dispatch(post(FETCH_AVAILABILITY_DETAILS, `${getALPBaseUrl()}/v1/auction/availability-request`, payload))
}

export const createAuctionRequest = payload => (dispatch: any) => {
	return dispatch(post(CREATE_AUCTION_REQUEST, `${getALPBaseUrl()}/v1/auction/creation-request`, payload))
}

export const editStageAuctionRequest = payload => (dispatch: any) => {
	return dispatch(put(EDIT_STAGE_AUCTION_REQUEST, `${getALPBaseUrl()}/v1/auction/edit-stage-auction`, payload))
}

export const stageToBreadcastAuctionRequest = payload => (dispatch: any) => {
	return dispatch(put(STAGE_TO_BRODCAST_AUCTION_REQUEST, `${getALPBaseUrl()}/v1/auction/stage-to-live`, payload))
}

export const bulkRejectBids = payload => (dispatch: any) => {
	return dispatch(put(BULK_REJECT_BIDS, `${getALPBaseUrl()}/v1/auction/bulk-reject-bids`, payload))
}

export const releaseBids = payload => (dispatch: any) => {
	return dispatch(post(BULK_RELEASE_BIDS, `${getALPBaseUrl()}/v1/auction/release-request`, payload))
}

export const clearInventoryData = () => {
	return {
		type: CLEAR_INVENTORY_DATA,
	}
}
