import {createFetchActions} from '../../services/createActions'

export const SET_COLLATERAL_OPTIMIZATION_CLICKED_PORTFOLIO_ENTITY =
	'SET_COLLATERAL_OPTIMIZATION_CLICKED_PORTFOLIO_ENTITY'
export const SET_COLLATERAL_OPTIMIZATION_CLICKED_GROUPBY_ENTITY = 'SET_COLLATERAL_OPTIMIZATION_CLICKED_GROUPBY_ENTITY'
export const SET_COLLATERAL_OPTIMIZATION_TOGGLE_INDEX = createFetchActions('SET_COLLATERAL_OPTIMIZATION_TOGGLE_INDEX')
export const SET_COLLATERAL_OPTIMIZATION_DRILLDOWN = createFetchActions('SET_COLLATERAL_OPTIMIZATION_DRILLDOWN')

export const setClickedPortfolioEntity = data => {
	return {
		type: SET_COLLATERAL_OPTIMIZATION_CLICKED_PORTFOLIO_ENTITY,
		response: {
			data: data,
		},
	}
}

export const setClickedGroupByEntity = (name, groupOne, groupTwo) => {
	return {
		type: SET_COLLATERAL_OPTIMIZATION_CLICKED_GROUPBY_ENTITY,
		response: {
			groupEntityname: name,
			groupOne,
			groupTwo,
		},
	}
}

export const setToggleActiveIndex = (name: string, index: number) => {
	return {
		type: SET_COLLATERAL_OPTIMIZATION_TOGGLE_INDEX,
		response: {
			toggleName: name,
			index,
		},
	}
}

export const setDrilldown = (name?: string, drilldownData?: Object) => {
	return {
		type: SET_COLLATERAL_OPTIMIZATION_DRILLDOWN,
		response: {
			drilldownName: name,
			drilldownData,
		},
	}
}
