import {getPMSClient} from '../..'
import {createFetchActions} from '../../services/createActions'
import {intraDayActivitiesQuery} from './intradayActivitiesQueries'

export const GET_INTRA_DAY_ACTIVITIES = createFetchActions('GET_INTRA_DAY_ACTIVITIES')
export const CLEAR_INTRA_DAY_ACTIVITIES = 'CLEAR_INTRA_DAY_ACTIVITIES'

export function getIntraDayActivities() {
	return function (dispatch) {
		dispatch({
			type: GET_INTRA_DAY_ACTIVITIES.triggered,
		})

		getPMSClient()
			.query({
				query: intraDayActivitiesQuery,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_INTRA_DAY_ACTIVITIES.succeeded,
					response,
				})
			})
	}
}

export const clearIntraDayActivities = data => {
	return {
		type: CLEAR_INTRA_DAY_ACTIVITIES,
	}
}
