import React from 'react'
import VerticallyCenteredModal from '../pages/styleGuide/fsModal'

interface Props {
	show: boolean
	headerIcon: any
	bodyTitle: string
	bodyMessage: string
	handleModalCancel: () => any
}

interface State {}

export default class FsModalBox extends React.Component<Props, State> {
	render() {
		const {show, headerIcon, bodyTitle, bodyMessage, handleModalCancel} = this.props
		return (
			<VerticallyCenteredModal
				size='md'
				headerEl={<div className='text-center mt-4'>{headerIcon}</div>}
				bodyEl={
					<div className='text-center mt-3 px-5'>
						<span className='f-22 font-weight-600 text-grey-5 d-block'>{bodyTitle}</span>
						<span className='d-block f-18 mt-2 mb-3'>{bodyMessage}</span>
					</div>
				}
				footerEl={
					<div className='text-center pb-4 pt-3'>
						<button className='btn btn-secondary mr-3 px-5' onClick={handleModalCancel}>
							Ok
						</button>
					</div>
				}
				show={show}
				onHide={handleModalCancel}
			/>
		)
	}
}
