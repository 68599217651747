import {createFetchActions} from '../../../services/createActions'
import {getConfigClient} from '../../..'
import {getTenantSummaryQuery} from './accountSettingsHeaderQueries'

export const GET_TENANT_SUMMARY = createFetchActions('GET_TENANT_SUMMARY')

export const getTenantSummary = () => {
	return function (dispatch) {
		dispatch({type: GET_TENANT_SUMMARY.triggered})
		getConfigClient()
			.query({
				query: getTenantSummaryQuery(),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_TENANT_SUMMARY.succeeded,
					response,
				})
			})
	}
}
