import {createFetchActions} from '../../services/createActions'
import {get} from '../../services/createAPICall'
import {getPMSBaseUrl} from '../../services/apiService'

export const GET_SUCCESS_REPORT = createFetchActions('GET_SUCCESS_REPORT')

export const getSuccessReport =
	(docId: string, page = 0, size = 10) =>
	(dispatch: any) => {
		const url = `${getPMSBaseUrl()}/v1/portfolios/documents/${docId}?page=${page}&size=${size}`
		return dispatch(get(GET_SUCCESS_REPORT, url))
	}
