import {ArrayUtil, ObjectUtil} from 'helper-util'

export const getConstraints = (data: any) => {
	const constraints = {}
	!ObjectUtil.isEmpty(data) &&
		Object.keys(data).forEach(item => {
			const newValue =
				!ArrayUtil.isEmpty(data[item]) &&
				data[item].map(option => {
					return {id: option, label: option, value: option}
				})
			constraints[item] = newValue
		})
	return constraints
}
