import {createFetchActions} from '../../../../services/createActions'

export const SET_SUGGESTION = createFetchActions('SET_SUGGESTION')

export const setCollateralBasketObject = data => (dispatch: any) => {
	dispatch({
		type: SET_SUGGESTION.succeeded,
		data: data,
	})
}
