import {post} from '../../services/createAPICall'
import {createFetchActions} from '../../services/createActions'
import {getPMSBaseUrl} from '../../services/apiService'

export const FETCH_SCORING_DATA = createFetchActions('FETCH_SCORING_DATA')
export const FETCH_FACTORY_WEIGHTS_DATA = createFetchActions('FETCH_FACTORY_WEIGHTS_DATA')
export const UPDATE_WEIGHTS = createFetchActions('UPDATE_WEIGHTS')
export const HIE_CLUSTER = createFetchActions('HIE_CLUSTER')

export const fetchScoringData = () => (dispatch: any) => {
	return dispatch(
		post(
			FETCH_SCORING_DATA,
			`${getPMSBaseUrl()}/csgetsummaryanddetails`,
			null,
			null,
			'Error occured while fecthing client summary data'
		)
	)
}
export const fetchFactoryWeightsData = () => (dispatch: any) => {
	return dispatch(
		post(
			FETCH_FACTORY_WEIGHTS_DATA,
			`${getPMSBaseUrl()}/csgetfactorweights`,
			null,
			null,
			'Error occured while fecthing client weights data'
		)
	)
}
export const updateWeights = (data: any) => (dispatch: any) => {
	return dispatch(
		post(
			UPDATE_WEIGHTS,
			`${getPMSBaseUrl()}/cssetfactorweights`,
			data,
			null,
			'Error occured while fecthing client factory weights'
		)
	)
}
export const getHieCluster = () => (dispatch: any) => {
	return dispatch(
		post(HIE_CLUSTER, `${getPMSBaseUrl()}/hie_cluster`, null, null, 'Error occured while fecthing HieCluster data')
	)
}
