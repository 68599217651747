import {GROUPED_PORTFOLIO_ENTRY} from './SblEfficiencyAction'

export interface SblEfficiency {
	groupdedPortfolioEntry: any[]
}

const initialState: SblEfficiency = {
	groupdedPortfolioEntry: null,
}

export function SblEfficiencyReducer(state = initialState, action: any) {
	switch (action.type) {
		case GROUPED_PORTFOLIO_ENTRY.triggered:
			return {...state, groupdedPortfolioEntry: null}
		case GROUPED_PORTFOLIO_ENTRY.succeeded:
			return {...state, groupdedPortfolioEntry: action.response.data}
		case GROUPED_PORTFOLIO_ENTRY.failed:
			return {...state, groupdedPortfolioEntry: []}
	}
	return state
}
