import {createFetchActions} from '../../services/createActions'
import {getPMSBaseUrl} from '../../services/apiService'
import {put} from '../../services/createAPICall'

export const GET_LCR_DATA = createFetchActions('GET_LCR_DATA')

export function getLcrDataAndColumns() {
	return function (dispatch) {
		dispatch(
			put(
				GET_LCR_DATA,
				`${getPMSBaseUrl()}/v1/decisionMaking`,
				{
					params: {
						objective: [],
						strategy: 'Collateral Optimization',
					},
					requestType: 'lcr',
				},

				null,
				'Internal Server Error occured while getting lcr data'
			)
		)
	}
}
