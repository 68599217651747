import React, {useState} from 'react'
import {CloseIcon, StateStreetNewLogo} from '../styles/svg/svgIcons'
import {Table} from 'react-bootstrap'
import {FsModal} from './FsModal'

interface Props {
	onClick: () => any
}

export const CookieConsentMsg = onClick => {
	return (
		<>
			<span>
				This platform uses session storage technologies to ensure the proper functioning and security of the site.
				Further information on these technologies can be found
				<span className='hyperlink ml-1' onClick={onClick}>
					here
				</span>
			</span>
		</>
	)
}

export const CookieConsentBodyContent = onClose => (
	<>
		<div className='d-flex justify-content-between'>
			<div>
				<div className='header-logo-color f-24'>
					<StateStreetNewLogo />
				</div>
				<div className='f-14'>Effective Date: March 2024</div>
				<div className='f-14'>Last Reviewed: March 2024 </div>
			</div>

			<h4 className='header-logo-color'>Cookies and Online Tracking Disclosure</h4>
			<div className='text-grey-3 cursor-pointer' onClick={onClose}>
				<CloseIcon />
			</div>
		</div>
		<div className='mt-3 mb-3 border-bottom'></div>

		<h6 className='text-grey-5'>
			At State Street (“we”, “our”, “us”) we are committed to handling cookies and online tracking methods responsibly
			and transparently. This Cookies and Online Tracking Disclosure (“Cookies Disclosure”) contains the following
			sections:
		</h6>

		<ul>
			<li className='f-15'>What are cookies & similar technologies?</li>
			<li className='f-15'>
				What category of cookies & similar technologies are used?
				<ul>
					<li className='f-15'>Strictly Necessary</li>
				</ul>
			</li>
			<li className='f-15'>
				What are the most frequently used or a representative sample of the cookies on this platform?
			</li>
			<li className='f-15'>Cookie lifespan </li>
		</ul>

		<h6 className='text-grey-5 font-weight-600'>What are cookies & similar technologies? </h6>
		<p className='f-15'>
			Cookies & similar technologies are small text files downloaded to your computer or device by websites you visit.
			They are widely used to allow websites to function properly, as well as to provide business information to the
			operators of the site.
		</p>

		<br />

		<h6 className='text-grey-5 font-weight-600'>What category of cookies & similar technologies do we use? </h6>
		<p className='f-15'>We use the following category of cookies & similar technologies on this platform:</p>
		<h6 className='text-grey-5 mt-2' style={{fontStyle: 'italic'}}>
			Strictly Necessary{' '}
		</h6>
		<p className='f-15'>
			These storage sessions are essential to authenticate you and enable you to navigate this platform and use its
			features, such as accessing secure areas. Without this technology, we cannot provide the services you have
			requested.
		</p>
		<br />

		<h6 className='text-grey-5 font-weight-600'>
			What are the most frequently used or a representative sample of the cookies or technologies on this platform?
		</h6>
		<p className='f-15'>
			The following table details the most frequently used or a representative sample of the cookies and similar
			technologies this platform uses and explains their use and lifespan.
		</p>
		<br />

		<Table bordered className='border-4 mb-12'>
			<thead>
				<tr>
					<th>Session Storage Field</th>
					<th style={{width: '13%'}}>Type</th>
					<th>Duration</th>
					<th>Description</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className='py-2 text-grey-5'>userData </td>
					<td className='py-2 text-grey-5'>Strictly necessary</td>
					<td className='py-2 text-grey-5'>Session</td>
					<td className='py-2 text-grey-5'>User Attributes such as username, email and isEmailVerified</td>
				</tr>
				<tr>
					<td className='py-2 text-grey-5'>clockDrift </td>
					<td className='py-2 text-grey-5'>Strictly necessary</td>
					<td className='py-2 text-grey-5'>Session</td>
					<td className='py-2 text-grey-5'>
						Internally, it calculates a clockDrift which is used for token refresh activity.
					</td>
				</tr>
				<tr>
					<td className='py-2 text-grey-5'>idToken </td>
					<td className='py-2 text-grey-5'>Strictly necessary</td>
					<td className='py-2 text-grey-5'>Session</td>
					<td className='py-2 text-grey-5'>
						The ID token is a JSON Web Token (JWT) that contains the identity of the authenticated user, such as name,
						email
					</td>
				</tr>
				<tr>
					<td className='py-2 text-grey-5'>accessToken </td>
					<td className='py-2 text-grey-5'>Strictly necessary</td>
					<td className='py-2 text-grey-5'>Session</td>
					<td className='py-2 text-grey-5'>Auto generated access token</td>
				</tr>

				<tr>
					<td className='py-2 text-grey-5'>refreshToken </td>
					<td className='py-2 text-grey-5'>Strictly necessary</td>
					<td className='py-2 text-grey-5'>Session</td>
					<td className='py-2 text-grey-5'>Refresh token to get a new set of tokens once current token expires</td>
				</tr>
				<tr>
					<td className='py-2 text-grey-5'>LastAuthUser </td>
					<td className='py-2 text-grey-5'>Strictly necessary</td>
					<td className='py-2 text-grey-5'>Session</td>
					<td className='py-2 text-grey-5'>Username</td>
				</tr>
				<tr>
					<td className='py-2 text-grey-5'>amplify-signin-with-hostedUI </td>
					<td className='py-2 text-grey-5'>Strictly necessary</td>
					<td className='py-2 text-grey-5'>Session</td>
					<td className='py-2 text-grey-5'>
						Cognito provides a customizable user experience via a web "Hosted UI". The Hosted UI allows end-users to
						login and register directly to user pool
					</td>
				</tr>
				<tr>
					<td className='py-2 text-grey-5'>amplifyConfig </td>
					<td className='py-2 text-grey-5'>Strictly necessary</td>
					<td className='py-2 text-grey-5'>Session</td>
					<td className='py-2 text-grey-5'>
						Holds information about clientId, federation, mfa, uiConfigId & userPoolId
					</td>
				</tr>
				<tr>
					<td className='py-2 text-grey-5'>configKey </td>
					<td className='py-2 text-grey-5'>Strictly necessary</td>
					<td className='py-2 text-grey-5'>Session</td>
					<td className='py-2 text-grey-5'>Application configuration</td>
				</tr>
				<tr>
					<td className='py-2 text-grey-5'>userKey </td>
					<td className='py-2 text-grey-5'>Strictly necessary</td>
					<td className='py-2 text-grey-5'>Session</td>
					<td className='py-2 text-grey-5'>Username and email</td>
				</tr>
				<tr>
					<td className='py-2 text-grey-5'>tenant </td>
					<td className='py-2 text-grey-5'></td>
					<td className='py-2 text-grey-5'>Session</td>
					<td className='py-2 text-grey-5'>Current tenant value</td>
				</tr>
				<tr>
					<td className='py-2 text-grey-5'>authTokenKey </td>
					<td className='py-2 text-grey-5'>Strictly necessary</td>
					<td className='py-2 text-grey-5'>Session</td>
					<td className='py-2 text-grey-5'>Application token key</td>
				</tr>
				<tr>
					<td className='py-2 text-grey-5'>tenantPageAccessible </td>
					<td className='py-2 text-grey-5'>Strictly necessary</td>
					<td className='py-2 text-grey-5'>Session</td>
					<td className='py-2 text-grey-5'>Determines whether admin tool tenant selection page should be displayed</td>
				</tr>
				<tr>
					<td className='py-2 text-grey-5'>activeClientConfig </td>
					<td className='py-2 text-grey-5'>Strictly necessary</td>
					<td className='py-2 text-grey-5'>Session</td>
					<td className='py-2 text-grey-5'>User Interface configuration</td>
				</tr>
				<tr>
					<td className='py-2 text-grey-5'>permissions </td>
					<td className='py-2 text-grey-5'>Strictly necessary</td>
					<td className='py-2 text-grey-5'>Session</td>
					<td className='py-2 text-grey-5'>List of user permissions</td>
				</tr>
			</tbody>
		</Table>
	</>
)

export default function CookieConsent(props: Props) {
	const [showConsentModal, toggleConsentModal] = useState(false)

	const onConsentModalClose = () => {
		props.onClick()
		toggleConsentModal(false)
	}

	return (
		<>
			{!showConsentModal && (
				<div className='cookie-popup'>
					<p>{CookieConsentMsg(() => toggleConsentModal(true))}</p>
					<div className='text-grey-3' onClick={props.onClick}>
						<CloseIcon />
					</div>
				</div>
			)}

			{showConsentModal && (
				<FsModal scrollable size='xl' show={true} onHide={onConsentModalClose}>
					{CookieConsentBodyContent(onConsentModalClose)}
				</FsModal>
			)}
		</>
	)
}
