import memoizeOne from 'memoize-one'
import Lodash from 'lodash'
import {ArrayUtil, InputUtil} from 'helper-util'

import {globalColumnDefinition} from '../../../common/AgGrid/AgGridColumn'
import {MyClient} from '../myClients/myClientsModel'
import {Privileges, UserGroup} from '../userGroups/userGroupModel'
import {Groups, User} from './usersListModel'
import {replaceMultipleSpaces} from '../../../utils/textFieldUtil'
import {getCounterpartyId} from '../../../services/authService'

export const initialiseUser = () => {
	return {
		clientId: 0,
		email: '',
		firstName: '',
		groups: [] as Groups[],
		id: 0,
		isActive: false,
		isDraft: true,
		isMfaEnabled: false,
		isInternal: true,
		lastName: '',
		middleName: '',
		orgId: 0,
		phoneCode: '',
		phoneNumber: '',
		privileges: [] as Privileges[],
		timeZone: null,
		tenantId: '',
		userId: '',
		username: '',
	}
}

export const getPrivilegesListForGroups = (groupDetails: UserGroup) => {
	let privilegesList = []
	const {isComposite, componentGroups, modules} = groupDetails
	if (isComposite) {
		!ArrayUtil.isEmpty(componentGroups) &&
			componentGroups.forEach(compGrp => {
				!ArrayUtil.isEmpty(compGrp.modules) &&
					compGrp.modules.forEach(module => {
						if (!ArrayUtil.isEmpty(module.privileges)) {
							const sortedPriviledges = module.privileges.sort((a, b) => a.id - b.id)
							sortedPriviledges.forEach(privilege => {
								if (!(module.code === 'CONFIG' && privilege.permissionType === 'View')) {
									privilegesList.push({
										...privilege,
										moduleId: module.id,
										disableIfMutuallyExclusive: false,
									})
								}
							})
						}
					})
			})
	} else {
		!ArrayUtil.isEmpty(modules) &&
			modules.forEach(module => {
				if (!ArrayUtil.isEmpty(module.privileges)) {
					const sortedPriviledges = module.privileges.sort((a, b) => a.id - b.id)
					sortedPriviledges.forEach(privilege => {
						if (!(module.code === 'CONFIG' && privilege.permissionType === 'View')) {
							privilegesList.push({
								...privilege,
								moduleId: module.id,
								disableIfMutuallyExclusive: false,
							})
						}
					})
				}
			})
	}
	privilegesList = privilegesList.filter(pvlg => !pvlg.isDisableInternal)
	return Lodash.groupBy(
		privilegesList.sort((a, b) => a.moduleId - b.moduleId),
		'displayName'
	)
}

export const getPrivilegesListForClients = (clientDetails: MyClient) => {
	let privilegesList = []
	const {modules} = clientDetails
	!ArrayUtil.isEmpty(modules) &&
		modules.forEach(module => {
			if (!ArrayUtil.isEmpty(module.privileges) && !(module.code === 'CONFIG')) {
				const sortedPriviledges = module.privileges.sort((a, b) => a.id - b.id)
				sortedPriviledges.forEach(privilege => {
					privilegesList.push({
						...privilege,
						moduleId: module.id,
						disableIfMutuallyExclusive: false,
					})
				})
			}
		})
	privilegesList = privilegesList.filter(pvlg => !pvlg.isDisableExternal)
	return Lodash.groupBy(
		privilegesList.sort((a, b) => a.moduleId - b.moduleId),
		'displayName'
	)
}

export const getColumns = memoizeOne((isWritePermission, setFormData) => {
	return [
		globalColumnDefinition.firstName,
		globalColumnDefinition.lastName,
		Object.assign({}, globalColumnDefinition.username, {
			onCellClicked: params => {
				isWritePermission && setFormData(params.data)
			},
			cellClass: [isWritePermission ? 'cursor-pointer text-link' : ''],
		}),
		globalColumnDefinition.email,
		Object.assign({}, globalColumnDefinition.isInternal, {cellRenderer: params => (params.value === true ? 'Y' : 'N')}),
		Object.assign({}, globalColumnDefinition.groups, {
			cellRenderer: params => (ArrayUtil.isEmpty(params.value) ? '' : params.value.join(', ')),
		}),
		Object.assign({}, globalColumnDefinition.clients, {
			cellRenderer: params => (ArrayUtil.isEmpty(params.value) ? '' : params.value.join(', ')),
		}),
	]
}, Lodash.isEqual)

export const isFormValidFn = (user: User) => {
	const {firstName, lastName, email, isInternal, groups, clientId} = user
	const selectedGroupOrClientId = isInternal ? !ArrayUtil.isEmpty(groups) && groups[0].groupId : clientId
	return (
		firstName &&
		firstName.trim() &&
		lastName &&
		lastName.trim() &&
		InputUtil.validateEmail(email) &&
		selectedGroupOrClientId
	)
}

export const handleOnChangeFn = (
	user: User,
	key,
	val,
	action,
	privilegesList,
	onGetGroupDetails,
	onGetClientDetails,
	onClearPrivilegesList
) => {
	const userCopy = Lodash.cloneDeep(user)
	let modifiedPrivilegesList = privilegesList
	if (key !== 'privileges' && key !== 'clients') {
		userCopy[key] = val
	}

	if (key === 'groups') {
		userCopy['groups'] = [val]
		userCopy['privileges'] = []
		userCopy['clientId'] = null
		val && onGetGroupDetails(val.groupId)
	} else if (key === 'clients') {
		userCopy['clientId'] = val.id
		userCopy['privileges'] = []
		userCopy['groups'] = []
		val && onGetClientDetails(val.clientId)
	} else if (key === 'privileges') {
		const {privileges} = userCopy
		let privilegesCopy = Lodash.cloneDeep(privileges)
		const clonedPrivilegesList = Lodash.cloneDeep(privilegesList)
		const flatternPrivilegesList = Object.values(clonedPrivilegesList).flat()

		if (val.isMutuallyExclusive) {
			if (action === 'ADD') {
				flatternPrivilegesList.forEach(privileges => {
					if (privileges['moduleId'] === val.moduleId && privileges['id'] !== val.id) {
						privileges['disableIfMutuallyExclusive'] = true
					}
				})
			}
			if (action === 'REMOVE') {
				flatternPrivilegesList.forEach(privileges => {
					privileges['disableIfMutuallyExclusive'] = false
				})
			}
			modifiedPrivilegesList = Lodash.groupBy(flatternPrivilegesList, 'displayName')

			const privilegesByModuleId = Lodash.groupBy(flatternPrivilegesList, 'moduleId')
			const selectedPrivilegesByModules = privilegesByModuleId[val.moduleId]
			privilegesCopy = privilegesCopy.filter(
				pvlg => !selectedPrivilegesByModules.some(sltPvlg => sltPvlg['id'] === pvlg.id)
			)
		}

		if (action === 'ADD') {
			privilegesCopy = privilegesCopy.filter(
				pvlg =>
					!(
						pvlg.displayName === val.displayName &&
						pvlg.permissionType === (val.permissionType === 'Edit' ? 'View' : 'Edit')
					)
			)
			privilegesCopy.push(val)
		} else if (action === 'REMOVE') {
			privilegesCopy = privilegesCopy.filter(pvlg => pvlg.id !== val.id)
		}

		const clonedModifiedPrivilegesList = Lodash.cloneDeep(modifiedPrivilegesList)
		const selectedPrivilegsGroup = clonedModifiedPrivilegesList[val.displayName]
		selectedPrivilegsGroup &&
			selectedPrivilegsGroup.forEach(privilege => {
				if (action === 'ADD') {
					if (privilege.permissionType === (val.permissionType === 'Edit' ? 'View' : 'Edit')) {
						privilege['disableIfToggle'] = true
					}
				} else if (action === 'REMOVE') {
					privilege['disableIfToggle'] = false
				}
			})
		modifiedPrivilegesList = clonedModifiedPrivilegesList

		userCopy[key] = privilegesCopy
	} else if (key === 'isInternal') {
		userCopy['groups'] = []
		userCopy['privileges'] = []
		onClearPrivilegesList()
	}
	return {
		userCopy,
		modifiedPrivilegesList,
	}
}

export const getPayload = (user: User, isEdit) => {
	const {firstName, middleName, lastName, username, email} = user
	const {timeZone, isMfaEnabled, phoneCode, phoneNumber, isInternal, groups, userId, privileges, clientId} = user

	const payLoad = {
		firstName: replaceMultipleSpaces(firstName),
		middleName: replaceMultipleSpaces(middleName),
		lastName: replaceMultipleSpaces(lastName),
		username: username,
		isActive: true,
		isDraft: true,
		isInternal: isInternal,
		email,
		phoneCode: phoneCode,
		phoneNumber: phoneNumber,
		timeZone: timeZone,
		isMfaEnabled: isMfaEnabled,
		groupIds: isInternal ? groups.map(item => item.id) : null,
		clientId: !isInternal ? clientId : null,
		userType: 'REGULAR',
		privilegeIds: privileges.map(item => item.id),
	}
	if (isEdit) {
		payLoad['userId'] = userId
		payLoad['isDraft'] = false
	}
	payLoad['tenantId'] = getCounterpartyId()

	return payLoad
}
