import {createFetchActions} from '../../services/createActions'
import {getPMSBaseUrl} from '../../services/apiService'
import {get} from '../../services/createAPICall'

export const GET_CUSIP_SCHEDULE_DATA = createFetchActions('GET_CUSIP_SCHEDULE_DATA')

export const getCusipScheduleDataAndColumns = () => {
	return function (dispatch: any) {
		dispatch(
			get(
				GET_CUSIP_SCHEDULE_DATA,
				`${getPMSBaseUrl()}/v1/portfolios/cusip-schedule`,
				null,
				null,
				'Internal Server Error occured while getting generic cusip data'
			)
		)
	}
}
