import {dropdownOption, portfolioEntities, portfolioTitle} from '../../constants/dashboardConstants'

export const defaultDropdownItem = 'View All'

export const routingParamKey = {
	screenType: 'screenType',
	maturityDay: 'maturityDay',
	dashboardType: 'dashboardType',
	selectedView: 'selectedView',
	selectedGroupOne: 'selectedGroupOne',
	selectedGroupTwo: 'selectedGroupTwo',
	selectedGroupOneItem: 'selectedGroupOneItem',
	selectedGroupTwoItem: 'selectedGroupTwoItem',
}

export const inefficienciesViewList = {
	inefficiencies: {id: portfolioEntities.inefficiencies, label: defaultDropdownItem},
	recallInefficiencies: {
		id: portfolioEntities.recallInefficiencies,
		label: portfolioTitle.recallInefficiencies,
	},
	returnInefficiencies: {
		id: portfolioEntities.returnInefficiencies,
		label: portfolioTitle.returnInefficiencies,
	},
}

export const matureTradesViewlist = {
	matureTrades: {id: portfolioEntities.matureTrades, label: portfolioTitle.matureTrades},
}

export const openTradesViewlist = {
	openTrades: {id: portfolioEntities.openTrades, label: portfolioTitle.openTrades},
}

export const abcPortfolioViewList = {
	all: {id: portfolioEntities.all, label: defaultDropdownItem},
	longTrades: {id: portfolioEntities.longTrades, label: portfolioTitle.longTrades},
	shortTrades: {id: portfolioEntities.shortTrades, label: portfolioTitle.shortTrades},
}

export const boxesViewlist = {
	boxes: {id: portfolioEntities.boxes, label: defaultDropdownItem},
	availabilities: {id: portfolioEntities.availabilities, label: portfolioTitle.availabilities},
	needs: {id: portfolioEntities.needs, label: portfolioTitle.needs},
}

export const defaultViewList = {
	...inefficienciesViewList,
	...matureTradesViewlist,
	...openTradesViewlist,
	...abcPortfolioViewList,
	...boxesViewlist,
}

export const groupDropdownConfig = {
	groupOne: {
		id: 'groupOne',
		label: 'Group 1',
		counterId: 'groupTwo',
		onClick: 'setGroupOneDropdown',
		groupItem: {
			id: 'groupOneItem',
			onClick: 'setGroupOneItemDropdown',
		},
	},
	groupTwo: {
		id: 'groupTwo',
		label: 'Group 2',
		counterId: 'groupOne',
		onClick: 'setGroupTwoDropdown',
		groupItem: {
			id: 'groupTwoItem',
			onClick: 'setGroupTwoItemDropdown',
		},
	},
}

export const defaultGroupList = dropdownOption

export const pivotParams = {
	enableRowGroup: true,
	enablePivot: true,
}

export const aggFuncSum = {
	aggFunc: 'sum',
}
