import {get} from '../../services/createAPICall'
import {createFetchActions} from '../../services/createActions'
import {getPMSBaseUrl} from '../../services/apiService'

export const GET_DOCUMENT_OVERVIEW = createFetchActions('GET_DOCUMENT_OVERVIEW')
export const GET_ERROR_REPORT = createFetchActions('GET_ERROR_REPORT')
export const RESET_ERROR_REPORT_STATE = 'RESET_ERROR_REPORT_STATE'

export const getDocumentOverview = (docId: string) => (dispatch: any) => {
	const url = `${getPMSBaseUrl()}/v1/portfolios/documents/${docId}/overview`
	return dispatch(get(GET_DOCUMENT_OVERVIEW, url))
}

// Todo - Fix this when we implement server side pagination!
export const getErrorReport =
	(docId: string, page = 0, size = 10) =>
	(dispatch: any) => {
		const url = `${getPMSBaseUrl()}/v1/portfolios/documents/${docId}`
		return dispatch(get(GET_ERROR_REPORT, url))
	}

export const resetErorReportState = () => {
	return {
		type: RESET_ERROR_REPORT_STATE,
	}
}
