import {GET_COUNTERPARTY} from '../../PeerToPeer/OrderEntryModal/OrderEntryModalAction'
import {CREATE_TRADE, GET_TRADES} from './NewTradeModalAction'

export interface NewTradeModal {
	allTrades: any[]
	loading: boolean
	basketUploadedData: any
	benchmarkOption: any[]
	counterparties: any[]
	collateralBasket: any[]
	schedule: any[]
	scheduleOfSecurity: any
	scheduleValidated: any
	typeAheadResult: any
}

const initialState: NewTradeModal = {
	allTrades: null,
	loading: false,
	basketUploadedData: null,
	benchmarkOption: null,
	counterparties: [],
	collateralBasket: null,
	schedule: null,
	scheduleOfSecurity: '',
	scheduleValidated: null,
	typeAheadResult: [],
}

export function newTradeModalReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_COUNTERPARTY.triggered:
			return {...state, counterparties: null}
		case GET_COUNTERPARTY.succeeded:
			return {...state, counterparties: action.response}
		case GET_COUNTERPARTY.failed:
			return {...state, counterparties: []}

		case GET_TRADES.triggered:
			return {...state, allTrades: null}
		case GET_TRADES.succeeded:
			return {...state, allTrades: action.response}
		case GET_TRADES.failed:
			return {...state, allTrades: []}

		case CREATE_TRADE.triggered:
		case CREATE_TRADE.succeeded:
		case CREATE_TRADE.failed:
			return {...state}
	}

	return state
}
