import {getALPBaseUrl, getConfigBaseUrl} from '../../../../services/apiService'
import {getCounterpartyId} from '../../../../services/authService'
import {createFetchActions} from '../../../../services/createActions'
import {get, post, put} from '../../../../services/createAPICall'
export const GET_AUCTIONS = createFetchActions('GET_AUCTIONS')
export const GET_LIVE_AUCTIONS = createFetchActions('GET_LIVE_AUCTIONS')
export const GET_STAGE_AUCTIONS = createFetchActions('GET_STAGE_AUCTIONS')
export const GET_REJECTED_AUCTIONS = createFetchActions('GET_REJECTED_AUCTIONS')
export const GET_CLOSED_AUCTIONS = createFetchActions('GET_CLOSED_AUCTIONS')
export const REJECT_AUCTIONS = createFetchActions('REJECT_AUCTIONS')
export const FETCH_INVENTORY_DETAILS = createFetchActions('FETCH_INVENTORY_DETAILS')
export const SET_AUCTION_MODE = createFetchActions('SET_AUCTION_MODE')
export const BULK_BRODCAST_TO_LIVE = createFetchActions('BULK_BRODCAST_TO_LIVE')
export const FETCH_ALL_AUCTION_BORROWERS = createFetchActions('FETCH_ALL_AUCTION_BORROWERS')
export const FETCH_ALL_BIDS_FOR_AUCTION = createFetchActions('FETCH_ALL_BIDS_FOR_AUCTION')
export const GET_AUCTION_WIDGET_LAYOUT = createFetchActions('GET_AUCTION_WIDGET_LAYOUT')
export const SAVE_AUCTION_WIDGET_LAYOUT = createFetchActions('SAVE_AUCTION_WIDGET_LAYOUT')
export const SET_AUCTION_STATUS_ON_TIMER_ENDS = createFetchActions('SET_AUCTION_STATUS_ON_TIMER_ENDS')

export const getAuctionsById = auctionId => (dispatch: any) => {
	return dispatch(get(GET_AUCTIONS, `${getALPBaseUrl()}/v1/auction/creation/${auctionId}`))
}

export const getLiveAuctions = () => (dispatch: any) => {
	return dispatch(get(GET_LIVE_AUCTIONS, `${getALPBaseUrl()}/v1/auction/dashboard?status=LIVE`))
}

export const getStageAuctions = () => (dispatch: any) => {
	return dispatch(get(GET_STAGE_AUCTIONS, `${getALPBaseUrl()}/v1/auction/dashboard?status=STAGED`))
}
export const getAllBidsByActionId = (auctionId: string) => (dispatch: any) => {
	return dispatch(get(FETCH_ALL_BIDS_FOR_AUCTION, `${getALPBaseUrl()}/v1/auction/bids/${auctionId}`))
}

export const getRejectedAuctions = () => (dispatch: any) => {
	return dispatch(get(GET_REJECTED_AUCTIONS, `${getALPBaseUrl()}/v1/auction/dashboard?status=REJECTED`))
}
export const getClosedAuctions = () => (dispatch: any) => {
	return dispatch(get(GET_CLOSED_AUCTIONS, `${getALPBaseUrl()}/v1/auction/dashboard?status=CLOSED`))
}

export const rejectAuctions = (auctionsIds: string[]) => (dispatch: any) => {
	return dispatch(put(REJECT_AUCTIONS, `${getALPBaseUrl()}/v1/auction/bulk-reject-auctions`, auctionsIds))
}
export const cancelAuctions = (requestId: string) => (dispatch: any) => {
	return dispatch(put(REJECT_AUCTIONS, `${getALPBaseUrl()}/v1/auction/cancel-auction/${requestId}`))
}

export const bulkBroadcastToLive = payload => (dispatch: any) => {
	return dispatch(put(BULK_BRODCAST_TO_LIVE, `${getALPBaseUrl()}/v1/auction/bulk-broadcast-to-live`, payload))
}

export const fetchAllAuctionBorrowers = () => (dispatch: any) => {
	return dispatch(
		get(
			FETCH_ALL_AUCTION_BORROWERS,
			`${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/clients?agreementTypes=AGENCYLENDING`
		)
	)
}

export const getWidgetLayout = user => (dispatch: any) => {
	return dispatch(post(GET_AUCTION_WIDGET_LAYOUT, `${getALPBaseUrl()}/getfmcfg`, user, null))
}

export const saveWidgetLayout = widget => (dispatch: any) => {
	return dispatch(post(SAVE_AUCTION_WIDGET_LAYOUT, `${getALPBaseUrl()}/savefmcfg`, widget, null))
}

export const getInventoryData = () => {
	return {
		type: FETCH_INVENTORY_DETAILS,
	}
}

export const setAuctionMode = value => {
	return {
		type: SET_AUCTION_MODE,
		payload: value,
	}
}

export const setAuctionStatusOnTimerEnd = auctionReqId => {
	return {
		type: SET_AUCTION_STATUS_ON_TIMER_ENDS,
		payload: auctionReqId,
	}
}
