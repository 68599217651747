import gql from 'graphql-tag'
import {getCounterpartyId} from '../../../services/authService'

export const getStrategiesQuery = gql`
	query {
		allStrategies(tenantId: "${getCounterpartyId()}") {
			strategyId
			id
			name
			code
			description
			tenantId
		}
	}
`

export const getModulesQuery = gql`
	query {
		allModules(tenantId: "${getCounterpartyId()}") {
			moduleId
			id
			name
			code
			description
			tenantId
      isInternal
			privileges {
				id
				privilegeId
				code
				description
				displayName
				permissionType
				isMutuallyExclusive
				tenantId
			}
		}
	}
`
