import {CLEAR_LEGAL_ENTITY_DATA, GET_MY_LEGAL_ENTITIES, SAVE_MY_LEGAL_ENTITIES} from './myLegalEntitiesActions'
import {EDIT_MY_LEGAL_ENTITIES, GET_SECTORS_INDICES} from './myLegalEntitiesActions'
import {SEARCH_LEGAL_ENTITY_DETAIL, SEARCH_LEGAL_ENTITY} from './myLegalEntitiesActions'
import {CLEAR_ALL_LEGAL_ENTITY, SET_SELECTED_LEGAL_SEARCH} from './myLegalEntitiesActions'
import {MyLegalEntities} from './myLegalEntitiesModel'
import {SectorsIndices} from '../../collateralBasketList/collateralBasket/collateralBasketModel'
import Lodash from 'lodash'
import {modifiedLegalEntity} from './myLegalEntityHelper'

export interface MyLegalEntitiesData {
	sectorsIndices: SectorsIndices
	loading: boolean
	data: MyLegalEntities[]
	legalEntity: any
	legalEntityDetail: any
	selectedLESearch: any
}

const initialState: MyLegalEntitiesData = {
	sectorsIndices: {sectors: [], indices: []},
	loading: false,
	data: [],
	legalEntity: {loading: false, data: []},
	legalEntityDetail: {loading: false, data: null},
	selectedLESearch: {},
}

export function myLegalEntitiesReducer(state = initialState, action: any) {
	let data: MyLegalEntities[] = []
	switch (action.type) {
		case GET_MY_LEGAL_ENTITIES.triggered:
			return {...state, loading: true}
		case GET_MY_LEGAL_ENTITIES.succeeded:
			data = (action.response.data && action.response.data.allLegalEntities) || []
			return {...state, loading: false, data}
		case GET_MY_LEGAL_ENTITIES.failed:
			return {...state, loading: false}

		case SAVE_MY_LEGAL_ENTITIES.triggered:
			return {...state, loading: true}
		case SAVE_MY_LEGAL_ENTITIES.succeeded:
		case SAVE_MY_LEGAL_ENTITIES.failed:
			return {...state, loading: false}

		case EDIT_MY_LEGAL_ENTITIES.triggered:
			return {...state, loading: true}
		case EDIT_MY_LEGAL_ENTITIES.succeeded:
		case EDIT_MY_LEGAL_ENTITIES.failed:
			return {...state, loading: false}

		case GET_SECTORS_INDICES.triggered:
			return {...state, loading: true}
		case GET_SECTORS_INDICES.succeeded:
			const sectorsIndices = {
				sectors: (action.response.data || {}).distinctSectors,
				indices: (action.response.data || {}).indices,
			}
			return {...state, loading: false, sectorsIndices}
		case GET_SECTORS_INDICES.failed:
			return {...state, loading: false}

		case SEARCH_LEGAL_ENTITY.triggered:
			return {...state, legalEntity: {loading: true, data: null}}
		case SEARCH_LEGAL_ENTITY.succeeded:
			return {...state, legalEntity: modifiedLegalEntity({loading: false, data: action.response.data})}
		case SEARCH_LEGAL_ENTITY.failed:
			return {...state, legalEntity: {loading: false, data: []}}

		case SEARCH_LEGAL_ENTITY_DETAIL.triggered:
			return {...state, legalEntityDetail: {loading: true, data: null}}
		case SEARCH_LEGAL_ENTITY_DETAIL.succeeded:
			return {...state, legalEntityDetail: {loading: false, data: action.response.data}}
		case SEARCH_LEGAL_ENTITY_DETAIL.failed:
			return {...state, legalEntityDetail: {loading: false, data: []}}

		case CLEAR_ALL_LEGAL_ENTITY:
			return {...state, legalEntity: {loading: false, data: null}, legalEntityDetail: {loading: false, data: null}}

		case CLEAR_LEGAL_ENTITY_DATA:
			return {...state, data: []}

		case SET_SELECTED_LEGAL_SEARCH:
			const selectedDetails = action.payload
			const updatedLegalEntity = modifiedLegalEntity(Lodash.cloneDeep(state.legalEntity), selectedDetails, false)
			return {...state, selectedLESearch: selectedDetails, legalEntity: Lodash.cloneDeep(updatedLegalEntity)}
	}
	return state
}
