import {connect} from 'react-redux'
import {MainState} from '../../store/mainReducer'
import {bindActionCreators} from 'redux'
import FsLoader from './fsLoaderComponent'

const mapStateToProps = (state: MainState) => {
	return {
		hideLoader: state.errorHandler.isRedirect,
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FsLoader)
