import {GET_COLLATERAL_BASKETS, GET_BASKET_PROGRESS_DATA, GET_SECURITY_SUGGESTIONS} from './cbNegotiationActions'

export interface CollateralBasketItem {
	id: string
	ticker: string
	cusip: string
	isin: string
	strategy: string
	way: string
	price: number
	priceMultiplier: number
	quantity: number
	notional: number
	classification: Classification
	rating: string
	newlyAdded?: boolean
	securityId: string
	cusipCode?: string
	schedule?: string
	scheduleDescription?: string
	securityDescription?: string
	margin?: number
	fitchRating?: number
	moodyRating?: number
	restricted?: string
	assetType?: string
	quantityAdded?: boolean
	dirtyPrice?: number
	basketQuantity: number
}

export interface Classification {
	displayName: string
	code: string
}

export interface Suggestion {
	ticker: string
	cusip: string
	isin: string
	price: number
	priceMultiplier: number
	currency: string
	assetClassification: string
	id: string
	securityId: string
}

export interface CBNegotiation {
	loading: boolean
	basketProgressData: any
	collateralBaskets: any[]
	suggestions: Suggestion[]
}

const initialState: CBNegotiation = {
	loading: false,
	basketProgressData: {},
	collateralBaskets: [],
	suggestions: [],
}

export function cbNegotiationReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_COLLATERAL_BASKETS.triggered:
			return {...state, loading: true}
		case GET_COLLATERAL_BASKETS.failed:
			return {...state, loading: false}
		case GET_COLLATERAL_BASKETS.succeeded:
			return {
				...state,
				loading: false,
				collateralBaskets: action.response.data.openCollateralBaskets || [],
			}

		case GET_BASKET_PROGRESS_DATA.triggered:
			return {...state, loading: true}
		case GET_BASKET_PROGRESS_DATA.failed:
			return {...state, loading: false}
		case GET_BASKET_PROGRESS_DATA.succeeded:
			const basketProgressData = (action.response.data || {}).order || {}
			return {...state, loading: false, basketProgressData}

		case GET_SECURITY_SUGGESTIONS.succeeded:
			return {...state, suggestions: action.response}
	}
	return state
}
