import {GET_SETTLEMENT_DATA, CLEAR_SETTLEMENT_DATA} from './settlementLadderActions'

export interface SettlementData {
	loading: boolean
	data: any[]
}

const initialState: SettlementData = {
	loading: true,
	data: null,
}

export function settlementDataReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_SETTLEMENT_DATA.triggered:
			return {...state, data: null, loading: true}
		case GET_SETTLEMENT_DATA.succeeded:
			const data = (action.response.data && action.response.data.settlementLadder) || []
			return {...state, loading: false, data}
		case GET_SETTLEMENT_DATA.failed:
			return {...state, data: [], loading: false}

		case CLEAR_SETTLEMENT_DATA:
			return {...initialState}
	}

	return state
}
