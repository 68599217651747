import {v4 as uuidv4} from 'uuid'
import Lodash from 'lodash'
import {ArrayUtil} from 'helper-util'

export const getFirstInitialRow = (groupIndex, index, security) => {
	return {
		groupIndex,
		index,
		security,
		skipSecurity: false,
		select: true,
		delete: false,
		groupFirstRow: true,
		locateResponseReceived: false,
		requestedQuantity: 0,

		ticker: '',
		cusip: '',
		securityDesc: '',
		collateralType: '',
		collateralCcy: '',
		targetedAvailQty: 0,
		borrowerSubAccount: '',
		collateralPrice: '',

		maxReqQty: 0,
		minReqQty: 0,
		qtyIncrement: 100,

		rate: null,
	}
}
export const massageInventoryData = (groupIndex, inventories) => {
	return inventories.map((d, i) => {
		return {
			...d,
			select: false,
			skipSecurity: true,
			groupFirstRow: false,
			index: uuidv4(),
			groupIndex,
			originalRebateProgramRate: d.rebateProgramRate,
		}
	})
}

const convertToNumber = (securities, index, field) => {
	return (securities[index] && securities[index][field] && Number(securities[index][field])) || 0
}

export const mergeInventoryDataAfterLocate = (responsePayload, basketGridData) => {
	let basketGridDataCopy = Lodash.cloneDeep(basketGridData)
	let clearAllFocusObj = {}
	const focusArr = [
		'securityFocus',
		'requestedQuantityFocus',
		'minReqQtyFocus',
		'maxReqQtyFocus',
		'qtyIncrementFocus',
		'rateFocus',
	]
	focusArr.forEach(f => {
		clearAllFocusObj[f] = false
	})
	responsePayload.forEach(d => {
		const rowIndexToUpdate = basketGridDataCopy.findIndex(b => b.index === d.serialId)
		if (!ArrayUtil.isEmpty(d.inventories)) {
			basketGridDataCopy[rowIndexToUpdate] = {
				...basketGridDataCopy[rowIndexToUpdate],
				...d.inventories[0],
				isInventoryEmpty: false,
				security: d.security,
				cusip: d.cusip,
				ticker: d.ticker,
				select: true,
				skipSecurity: false,
				groupFirstRow: true,
				originalRebateProgramRate: d.inventories[0].rebateProgramRate,
				securityFocus: true,
			}
			if (d.inventories.length > 1) {
				d.inventories.shift()
				basketGridDataCopy = basketGridDataCopy.filter((b, i) => !(b.serialId === d.serialId && i !== rowIndexToUpdate))
				basketGridDataCopy.splice(
					rowIndexToUpdate + 1,
					0,
					...massageInventoryData(basketGridDataCopy[rowIndexToUpdate].groupIndex, d.inventories)
				)
			}
		} else {
			basketGridDataCopy[rowIndexToUpdate] = {
				...basketGridDataCopy[rowIndexToUpdate],
				isInventoryEmpty: true,
			}
		}
	})

	let lstIndex = basketGridDataCopy.length - 1
	while (!basketGridDataCopy[lstIndex].groupFirstRow) {
		lstIndex -= 1
	}

	return basketGridDataCopy.map((b, index) => {
		return {
			...b,
			...clearAllFocusObj,
			delete: index !== lstIndex,
			locateResponseReceived: true,
		}
	})
}

export const getBasketGridOnBulkUpload = (responsePayload, basketGridData, masterSecurities) => {
	const {securities, isPartial} = responsePayload
	let clearAllFocusObj = {}
	const focusArr = [
		'securityFocus',
		'requestedQuantityFocus',
		'minReqQtyFocus',
		'maxReqQtyFocus',
		'qtyIncrementFocus',
		'rateFocus',
	]
	focusArr.forEach(f => {
		clearAllFocusObj[f] = false
	})

	const uploadedFormattedRows = (securities || []).map((d, k) => {
		const matchedMasterSecurity = (masterSecurities || []).find(
			security => security.ticker === d.security || security.cusip === d.security
		)
		let noInventoryObj: any = {
			...getFirstInitialRow(uuidv4(), uuidv4(), d.security),
			ticker: (matchedMasterSecurity && matchedMasterSecurity.ticker) || d.security,
			cusip: matchedMasterSecurity && matchedMasterSecurity.cusip,
			sedol: matchedMasterSecurity && matchedMasterSecurity.sedol,
			isin: matchedMasterSecurity && matchedMasterSecurity.isin,
			securityDesc: (matchedMasterSecurity && matchedMasterSecurity.desc1) || '',
			requestedQuantity: convertToNumber(securities, k, 'requestedQty'),
			rate: convertToNumber(securities, k, 'rate'),
		}
		if (isPartial) {
			noInventoryObj = {
				...noInventoryObj,
				minReqQty: convertToNumber(securities, k, 'minReqQty'),
				maxReqQty: convertToNumber(securities, k, 'maxReqQty'),
				qtyIncrement: convertToNumber(securities, k, 'qtyIncrement'),
			}
			delete noInventoryObj['requestedQuantity']
		}
		return noInventoryObj
	})

	const filteredGridData = basketGridData.filter(d => d.security !== '')
	const basketGridUploadDataCopy = Lodash.cloneDeep(filteredGridData.concat(uploadedFormattedRows.flat()))

	let lstIndex = basketGridUploadDataCopy.length - 1
	while (!basketGridUploadDataCopy[lstIndex].groupFirstRow) {
		lstIndex -= 1
	}

	return basketGridUploadDataCopy.map((b, index) => {
		return {
			...b,
			...clearAllFocusObj,
			delete: index !== lstIndex,
			locateResponseReceived: false,
		}
	})
}

export const getSingleStockOnRowUpdate = (payload, singleStockGridData) => {
	const {index, action, data: dataObjSS} = payload
	let singleStockGridDataCopy = Lodash.cloneDeep(singleStockGridData)
	const {field, data, focusField} = dataObjSS
	const ssRowIndexToUpdate = singleStockGridDataCopy.findIndex(b => b.index === index)
	let clearAllFocusObj = {}
	const focusArr = ['borrowQuantityFocus', 'minReqQtyFocus', 'maxReqQtyFocus', 'qtyIncrementFocus', 'rateFocus']
	focusArr.forEach(f => {
		clearAllFocusObj[f] = false
	})
	if (action === 'UPDATE') {
		if (field === 'borrowQnty') {
			singleStockGridDataCopy[ssRowIndexToUpdate] = {
				...singleStockGridDataCopy[ssRowIndexToUpdate],
				borrowQuantity: data,
			}
		}
		if (field === 'minReqQty') {
			singleStockGridDataCopy[ssRowIndexToUpdate] = {
				...singleStockGridDataCopy[ssRowIndexToUpdate],
				minReqQty: data,
			}
		}
		if (field === 'maxReqQty') {
			singleStockGridDataCopy[ssRowIndexToUpdate] = {
				...singleStockGridDataCopy[ssRowIndexToUpdate],
				maxReqQty: data,
			}
		}
		if (field === 'qtyIncrement') {
			singleStockGridDataCopy[ssRowIndexToUpdate] = {
				...singleStockGridDataCopy[ssRowIndexToUpdate],
				qtyIncrement: data,
			}
		}
		if (field === 'rate') {
			singleStockGridDataCopy[ssRowIndexToUpdate] = {
				...singleStockGridDataCopy[ssRowIndexToUpdate],
				...clearAllFocusObj,
				rebateProgramRate: data,
				[focusField]: true,
			}
		}
	} else if (action === 'refreshAll') {
		singleStockGridDataCopy[ssRowIndexToUpdate] = {
			...singleStockGridDataCopy[ssRowIndexToUpdate],
			...clearAllFocusObj,
		}
		if (focusField !== '') {
			singleStockGridDataCopy[ssRowIndexToUpdate][focusField] = true
		}
	} else if (action === 'setFocus') {
		singleStockGridDataCopy[ssRowIndexToUpdate] = {
			...singleStockGridDataCopy[ssRowIndexToUpdate],
			...clearAllFocusObj,
			[field]: true,
		}
	}
	return singleStockGridDataCopy
}

export const getBasketGridOnRowUpdate = (
	payload,
	basketGridData,
	existingIsLocateMode,
	isLocateClicked,
	inputPanelRequestData
) => {
	const {groupIndex, index, action: actionType, data: dataObj} = payload
	let basketGridDataCopy = Lodash.cloneDeep(basketGridData)
	const rowIndexToUpdate = basketGridDataCopy.findIndex(b => b.index === index)
	let clearAllFocusObj = {}
	let isLocateMode
	const focusArr = [
		'securityFocus',
		'requestedQuantityFocus',
		'minReqQtyFocus',
		'maxReqQtyFocus',
		'qtyIncrementFocus',
		'rateFocus',
	]
	focusArr.forEach(f => {
		clearAllFocusObj[f] = false
	})
	if (actionType === 'UPDATE') {
		const {field, data} = dataObj
		if (field === 'security') {
			const {security, cusip, ticker, sedol, isin, desc1} = dataObj
			let focusObj: any = {
				requestedQuantityFocus: true,
			}
			if (inputPanelRequestData && inputPanelRequestData.isPartial) {
				focusObj = {
					minReqQtyFocus: true,
					requestedQuantityFocus: false,
				}
			}

			basketGridDataCopy[rowIndexToUpdate] = {
				...basketGridDataCopy[rowIndexToUpdate],
				...clearAllFocusObj,
				...getFirstInitialRow(
					basketGridDataCopy[rowIndexToUpdate].groupIndex,
					basketGridDataCopy[rowIndexToUpdate].index,
					security || ticker || cusip
				),
				...focusObj,
				isInventoryEmpty: false,
				securityDesc: desc1,
				ticker,
				cusip,
				sedol,
				isin,
				maxReqQty: basketGridDataCopy[rowIndexToUpdate].maxReqQty,
				minReqQty: basketGridDataCopy[rowIndexToUpdate].minReqQty,
				qtyIncrement: basketGridDataCopy[rowIndexToUpdate].qtyIncrement,
				requestedQuantity: basketGridDataCopy[rowIndexToUpdate].requestedQuantity,
				reclaimRate: basketGridDataCopy[rowIndexToUpdate].reclaimRate,
			}
			isLocateMode = true
		} else if (field === 'requestedQnty') {
			basketGridDataCopy[rowIndexToUpdate] = {
				...basketGridDataCopy[rowIndexToUpdate],
				requestedQuantity: data,
			}
		} else if (field === 'minReqQty') {
			basketGridDataCopy[rowIndexToUpdate] = {
				...basketGridDataCopy[rowIndexToUpdate],
				minReqQty: data,
			}
		} else if (field === 'maxReqQty') {
			basketGridDataCopy[rowIndexToUpdate] = {
				...basketGridDataCopy[rowIndexToUpdate],
				maxReqQty: data,
			}
		} else if (field === 'qtyIncrement') {
			basketGridDataCopy[rowIndexToUpdate] = {
				...basketGridDataCopy[rowIndexToUpdate],
				qtyIncrement: data,
			}
		} else if (field === 'rate') {
			basketGridDataCopy[rowIndexToUpdate] = {
				...basketGridDataCopy[rowIndexToUpdate],
				rate: data,
			}
		}
	} else if (actionType === 'DELETE') {
		basketGridDataCopy = (basketGridDataCopy || []).filter(b => b.groupIndex !== groupIndex)
		basketGridDataCopy = (basketGridDataCopy || []).map(b => {
			return {
				...b,
				...clearAllFocusObj,
			}
		})
		if (!basketGridDataCopy.length) {
			basketGridDataCopy.push({...getFirstInitialRow(uuidv4(), uuidv4(), ''), ...clearAllFocusObj})
		}
		const isAllLocateResponseReceived = ((basketGridDataCopy || []).filter(b => !!b.security) || []).every(
			b => !!b.locateResponseReceived
		)
		isLocateMode =
			existingIsLocateMode || existingIsLocateMode === undefined
				? isLocateClicked
					? !isAllLocateResponseReceived
					: true
				: !isAllLocateResponseReceived
	} else if (actionType === 'ADD') {
		basketGridDataCopy = (basketGridDataCopy || []).map(b => {
			return {
				...b,
				...clearAllFocusObj,
			}
		})
		basketGridDataCopy.push({...getFirstInitialRow(uuidv4(), uuidv4(), ''), securityFocus: true})
	} else if (actionType === 'refreshAll') {
		const {focusField} = dataObj
		basketGridDataCopy[rowIndexToUpdate] = {
			...basketGridDataCopy[rowIndexToUpdate],
			...clearAllFocusObj,
		}
		if (focusField !== '') {
			basketGridDataCopy[rowIndexToUpdate][focusField] = true
		}
	} else if (actionType === 'setFocus') {
		const {field} = dataObj
		basketGridDataCopy = resetEveryFocus(basketGridDataCopy)
		basketGridDataCopy[rowIndexToUpdate] = {
			...basketGridDataCopy[rowIndexToUpdate],
			...clearAllFocusObj,
			[field]: true,
		}
	}

	let lastIndex = basketGridDataCopy.length - 1
	while (!basketGridDataCopy[lastIndex].groupFirstRow) {
		lastIndex -= 1
	}

	let returnObj: any = {
		basketGridData: basketGridDataCopy.map((b, index) => {
			return {
				...b,
				delete: index !== lastIndex,
			}
		}),
	}
	if (isLocateMode !== undefined) {
		returnObj = {
			...returnObj,
			isLocateMode: isLocateMode,
		}
	}
	return returnObj
}

export const getBasketGrodOnRadioSelect = (payload, basketGridData) => {
	const {index: indexRowSelect, groupIndex: groupIndexRowSelect} = payload
	let basketGridDataCopyforSelect = Lodash.cloneDeep(basketGridData)
	basketGridDataCopyforSelect.forEach(row => {
		if (row.groupIndex === groupIndexRowSelect) {
			if (row.index === indexRowSelect) {
				row.select = true
			} else {
				row.select = false
			}
		}
	})

	return resetEveryFocus(basketGridDataCopyforSelect)
}

export const getSelectedSecuritySearch = targetedInventoryResponse => {
	return {
		securityId: targetedInventoryResponse.cusip,
		security: targetedInventoryResponse.security || targetedInventoryResponse.cusip,
		description:
			targetedInventoryResponse.inventories.length > 0 && targetedInventoryResponse.inventories[0].securityDesc,
	}
}

export const modifySingleStockGridForAdditionDetails = targetedInventoryResponse => {
	return (targetedInventoryResponse.inventories || []).map(d => {
		return {
			...d,
			index: uuidv4(),
			maxReqQty: 0,
			minReqQty: 0,
			qtyIncrement: 100,
			borrowQuantity: 0,
			isNegotiationEnabled: false,
			originalRebateProgramRate: d.rebateProgramRate,
		}
	})
}

export const resetEveryFocus = basketGridData => {
	let clearAllFocusObj = {}
	const focusArr = [
		'securityFocus',
		'requestedQuantityFocus',
		'minReqQtyFocus',
		'maxReqQtyFocus',
		'qtyIncrementFocus',
		'rateFocus',
	]
	focusArr.forEach(f => {
		clearAllFocusObj[f] = false
	})
	return basketGridData.map(bsktData => {
		return {
			...bsktData,
			...clearAllFocusObj,
		}
	})
}

export const masterSecurityMapper = securities => {
	return securities.map(security => {
		return {
			...security,
			cusip: security.secId,
			cusipCopy: security.cusip,
		}
	})
}
