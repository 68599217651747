import {
	FETCH_COLLATERAL_SCHEDULE_DROPDOWN_RESULT,
	FETCH_TEMPLATE_LIST_RESULT,
	FETCH_RULESET_ENTRIES_RESULT,
	UPDATE_DMN_TEMPLATE,
} from './CollateralScheduleAction'

export interface CollateralSchedule {
	loading: boolean
	locateSearchData: any
	locateRequestQueueData: any
	locateSearchBucketData: any
	rulesetList: any
	templateList: any
	rulesetEntries: any
	collateralScheduleDropDowns: any
}

const initialState: CollateralSchedule = {
	loading: false,
	locateSearchData: [],
	locateRequestQueueData: [],
	locateSearchBucketData: [],
	rulesetList: [],
	templateList: [],
	rulesetEntries: [],
	collateralScheduleDropDowns: [],
}

export function CollateralScheduleReducer(state = initialState, action: any) {
	switch (action.type) {
		case FETCH_TEMPLATE_LIST_RESULT.triggered:
			return {...state, loading: true}
		case FETCH_TEMPLATE_LIST_RESULT.succeeded:
			return {...state, templateList: action.response.data && action.response.data.templateList, loading: false}

		case FETCH_RULESET_ENTRIES_RESULT.triggered:
			return {...state, loading: true}
		case FETCH_RULESET_ENTRIES_RESULT.succeeded:
			return {
				...state,
				rulesetEntries: action.response.data && action.response.data.templateEntry.templateBody,
				loading: false,
			}

		// case ADD_RULE_TO_RULESET_GRID:
		// 	return {...state, savedCollateralScheduleData: {}, loading: false}

		// case RESET_RULESET_ENTRIES:
		// 	return {...state, rulesetEntries: {}, loading: false}

		case FETCH_COLLATERAL_SCHEDULE_DROPDOWN_RESULT.triggered:
			return {...state, loading: true}
		case FETCH_COLLATERAL_SCHEDULE_DROPDOWN_RESULT.succeeded:
			return {
				...state,
				collateralScheduleDropDowns: action.response.data && action.response.data.collateralScheduleDropDowns,
				loading: false,
			}

		case UPDATE_DMN_TEMPLATE.triggered || UPDATE_DMN_TEMPLATE.failed:
			return {...state}
		case UPDATE_DMN_TEMPLATE.succeeded:
			return {...state}
	}
	return state
}
