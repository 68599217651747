import {getPMSClient} from '../..'
import {createFetchActions} from '../../services/createActions'
import {
	dashboardEntityAmountQuery,
	dashboardPortfolioAnalyticsQuery,
	tradingQueueSummaryQuery,
	allTradesQuery,
} from './dashboardQueries'
import {get} from '../../services/createAPICall'
import {getNotificationBaseUrl, getPMSGraphBaseUrl} from '../../services/apiService'
import {getTimeZone} from '../../services/clientConfigurationService'
import {getAuthToken} from '../../services/authService'
import * as axios from 'axios'
export const GET_NOTIFICATIONS = createFetchActions('GET_NOTIFICATIONS')
export const SET_CLICKED_PORTFOLIO_ENTITY = 'SET_CLICKED_PORTFOLIO_ENTITY'
export const SET_CLICKED_GROUPBY_ENTITY = 'SET_CLICKED_GROUPBY_ENTITY'
export const SET_TOGGLE_INDEX = 'SET_TOGGLE_INDEX'
export const SET_DRILLDOWNN = 'SET_DRILLDOWNN'
export const SET_CLICKED_FIRST_LEVEL_TREEMAP_VALUE = 'SET_CLICKED_FIRST_LEVEL_TREEMAP_VALUE'
export const SET_CLICKED_SECOND_LEVEL_TREEMAP_VALUE = 'SET_CLICKED_SECOND_LEVEL_TREEMAP_VALUE'
export const GET_CLICKED_PORTFOLIO_ENTITY = createFetchActions('GET_CLICKED_PORTFOLIO_ENTITY')
export const GET_CLICKED_GROUPBY_ENTITY = createFetchActions('GET_CLICKED_GROUPBY_ENTITY')
export const RESET_DASHBOARD_DATA = 'RESET_DASHBOARD_DATA'
export const GET_DASHBOARD_ENTITIES_AMOUNT = createFetchActions('GET_DASHBOARD_ENTITIES_AMOUNT')
export const GET_DASHBOARD_PORTFOLIO_ANALYTICS = createFetchActions('GET_DASHBOARD_PORTFOLIO_ANALYTICS')
export const GET_PORTFOLIO_UPLOADED_DATE = createFetchActions('GET_PORTFOLIO_UPLOADED_DATE')
export const GET_PORTFOLIO_DOCS_INCLUDED = createFetchActions('GET_PORTFOLIO_DOCS_INCLUDED')
export const SET_TRADE_BY = createFetchActions('SET_TRADE_BY')
export const GET_ALL_TRADES_FOR_DASHBOARD = createFetchActions('GET_ALL_TRADES_FOR_DASHBOARD')
export const GET_INTRA_DAY_ACTIVITIES = createFetchActions('GET_INTRA_DAY_ACTIVITIES')

export const setClickedPortfolioEntity = data => {
	return {
		type: SET_CLICKED_PORTFOLIO_ENTITY,
		response: {
			data: data,
		},
	}
}

export const setClickedFirstLevelTreemapValue = data => {
	return {
		type: SET_CLICKED_FIRST_LEVEL_TREEMAP_VALUE,
		response: {
			data: data,
		},
	}
}

export const setClickedSecondLevelTreemapValue = data => {
	return {
		type: SET_CLICKED_SECOND_LEVEL_TREEMAP_VALUE,
		response: {
			data: data,
		},
	}
}

export const setClickedGroupByEntity = (name, groupOne, groupTwo) => {
	return {
		type: SET_CLICKED_GROUPBY_ENTITY,
		response: {
			groupEntityname: name,
			groupOne,
			groupTwo,
		},
	}
}

export const setToggleActiveIndex = (name: string, index: number) => {
	return {
		type: SET_TOGGLE_INDEX,
		response: {
			toggleName: name,
			index,
		},
	}
}

export const setDrilldown = (name?: string, drilldownData?: Object) => {
	return {
		type: SET_DRILLDOWNN,
		response: {
			drilldownName: name,
			drilldownData,
		},
	}
}

export function getDashboardEntitiesAmount() {
	return function (dispatch) {
		dispatch({
			type: GET_DASHBOARD_ENTITIES_AMOUNT.triggered,
		})

		getPMSClient()
			.query({
				query: dashboardEntityAmountQuery,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_DASHBOARD_ENTITIES_AMOUNT.succeeded,
					response,
				})
			})
	}
}

const executePortfolioQuery = portfolioQuery => {
	if (!portfolioQuery) return null
	return getPMSClient()
		.query({
			query: portfolioQuery,
			fetchPolicy: 'network-only',
		})
		.then(response => {
			return response.data
		})
		.catch(error => {
			return null
		})
}

export const getDashboardPortfolioAnalytics = portfolioQueryType => {
	return function (dispatch) {
		dispatch({
			type: GET_DASHBOARD_PORTFOLIO_ANALYTICS.triggered,
		})

		const firstPortfolioQuery =
			Array.isArray(portfolioQueryType) &&
			portfolioQueryType.length > 0 &&
			dashboardPortfolioAnalyticsQuery[portfolioQueryType[0]]
		const secondPortfolioQuery =
			Array.isArray(portfolioQueryType) &&
			portfolioQueryType.length === 2 &&
			dashboardPortfolioAnalyticsQuery[portfolioQueryType[1]]

		Promise.all([executePortfolioQuery(firstPortfolioQuery), executePortfolioQuery(secondPortfolioQuery)]).then(
			combinedResponse =>
				dispatch({
					type: GET_DASHBOARD_PORTFOLIO_ANALYTICS.succeeded,
					response: [{[portfolioQueryType[0]]: combinedResponse[0]}, {[portfolioQueryType[1]]: combinedResponse[1]}],
				})
		)
	}
}

export const resetDashboardData = () => {
	return {
		type: RESET_DASHBOARD_DATA,
	}
}

export const setTradeByData = (queryDetail: any[]) => {
	const firstQuery = queryDetail[0] && tradingQueueSummaryQuery(queryDetail[0].cptyName, queryDetail[0].cptyType)
	const secondQuery = queryDetail[1] && tradingQueueSummaryQuery(queryDetail[1].cptyName, queryDetail[1].cptyType)
	return function (dispatch) {
		dispatch({
			type: SET_TRADE_BY.triggered,
		})

		Promise.all([executePortfolioQuery(firstQuery), executePortfolioQuery(secondQuery)]).then(combinedResponse =>
			dispatch({
				type: SET_TRADE_BY.succeeded,
				response: {
					[queryDetail[0].tradeBy]: combinedResponse[0],
					[queryDetail[1].tradeBy]: combinedResponse[1],
				},
			})
		)
	}
}

export const getNotifications = () => (dispatch: any) => {
	return dispatch(get(GET_NOTIFICATIONS, `${getNotificationBaseUrl()}/v1/events`, {}, {timezone: getTimeZone()}))
}

export const getAllTrades = () => {
	const query = allTradesQuery
	return function (dispatch) {
		dispatch({type: GET_ALL_TRADES_FOR_DASHBOARD.triggered})

		const instance = axios.default.create({
			baseURL: `${getPMSGraphBaseUrl()}`,
			timeout: 1000000,
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${getAuthToken()}`,
			},
		})

		instance.post('', query).then(data => {
			dispatch({
				type: GET_ALL_TRADES_FOR_DASHBOARD.succeeded,
				response: data.data,
			})
		})
	}
}
