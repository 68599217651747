import gql from 'graphql-tag'

export const abcmRerunTimingQuery = gql`
	query {
		pendingAbcmStatus {
			tenantId
			groupId
			pendingAbcm
			time
		}
	}
`
