import {GET_SUCCESS_REPORT} from './successReportAction'

export interface SuccessReport {
	data: any[]
	isLoading: boolean
}

const initialState: SuccessReport = {
	data: [],
	isLoading: false,
}

export function successReportReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_SUCCESS_REPORT.triggered:
			return {...state, isLoading: true}
		case GET_SUCCESS_REPORT.succeeded:
			return {...state, data: action.response.data, isLoading: false}
		case GET_SUCCESS_REPORT.failed:
			return {...state, isLoading: false}
	}
	return state
}
