export const ERROR_MSG = {
	singleLogin: 'Multiple sessions for same user are in progress, kindly login again.',
	invalidToken: 'Invalid Jwt token.',
	logoutFailure: 'Instance logout failure',
}

export const ERROR_CONSTANTS = {
	UNAUTHORIZED: [
		'User session expired, please login again.',
		'No Valid JWT token.',
		'User logged out, please login again.',
		'Invalid credentials.',
	],
}

export const ERROR_MSG_DISPLAY = {
	displayMessage: 'User session expired, please login again.',
}
