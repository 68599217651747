import {getConfigBaseUrl} from '../../services/apiService'
import {createFetchActions} from '../../services/createActions'
import {post, get, put, del} from '../../services/createAPICall'

export const GET_ALL_DATAVISUALIZATION_DETAILS_NEW = createFetchActions('GET_ALL_DATAVISUALIZATION_DETAILS_NEW')
export const CREATE_DATAVISUALIZATION_DETAILS_NEW = createFetchActions('CREATE_DATAVISUALIZATION_DETAILS_NEW')
export const UPDATE_DATAVISUALIZATION_DETAILS_NEW = createFetchActions('UPDATE_DATAVISUALIZATION_DETAILS_NEW')
export const DELETE_DATAVISUALIZATION_DETAILS_NEW = createFetchActions('DELETE_DATAVISUALIZATION_DETAILS_NEW')

export const createNewDataVisualizationDetails = widget => (dispatch: any) => {
	return dispatch(
		post(CREATE_DATAVISUALIZATION_DETAILS_NEW, `${getConfigBaseUrl()}/v2/config/users/layouts/`, widget, null)
	)
}

export const getAllDataVisualizationDetails = screenId => (dispatch: any) => {
	return dispatch(
		get(GET_ALL_DATAVISUALIZATION_DETAILS_NEW, `${getConfigBaseUrl()}/v2/config/users/layouts/${screenId}`)
	)
}

export const updateDataVisualizationDetails = widget => (dispatch: any) => {
	return dispatch(
		put(UPDATE_DATAVISUALIZATION_DETAILS_NEW, `${getConfigBaseUrl()}/v2/config/users/layouts/`, widget, null)
	)
}

export const deleteDataVisualizationDetails = screenId => (dispatch: any) => {
	return dispatch(
		del(DELETE_DATAVISUALIZATION_DETAILS_NEW, `${getConfigBaseUrl()}/v2/config/users/layouts/${screenId}`)
	)
}
