import {
	FETCH_SECURITY_INVENTORY,
	FETCH_AVAILABLE_NEED_COUNTERPARTY_DETAILS,
	ADD_AVAILABILITY_FROM_LOCATE_INVENTORY,
	CLEAR_MAIN_GRID_DATA,
} from './LocateInventoryMgmtActions'
import {flatternAvailabilityNeedsDetails, flatternInventoryDetails} from './LocateInventoryMgmtHelper'

export interface AlpInventoryManager {
	loading: boolean
	gridLoading: boolean
	securityInventory: any
}

const initialState: AlpInventoryManager = {
	loading: false,
	gridLoading: false,
	securityInventory: null,
}

export function AlpInventoryMgmtReducer(state = initialState, action: any) {
	switch (action.type) {
		case FETCH_SECURITY_INVENTORY.triggered:
			return {...state, securityInventory: null, gridLoading: true}
		case FETCH_SECURITY_INVENTORY.failed:
			return {...state, securityInventory: [], gridLoading: false}
		case FETCH_SECURITY_INVENTORY.succeeded:
			return {
				...state,
				securityInventory: flatternInventoryDetails(action.response.data && action.response.data.securityInventory),
				gridLoading: false,
			}

		case FETCH_AVAILABLE_NEED_COUNTERPARTY_DETAILS.triggered:
			return {...state, loading: true}
		case FETCH_AVAILABLE_NEED_COUNTERPARTY_DETAILS.failed:
			return {...state, loading: false}
		case FETCH_AVAILABLE_NEED_COUNTERPARTY_DETAILS.succeeded:
			const availNeedCounterpartyDetail = action.response.data && action.response.data.availNeedCounterpartyDetail
			const securityInventoryCopy = [...state.securityInventory]
			return {
				...state,
				securityInventory: flatternAvailabilityNeedsDetails(securityInventoryCopy, availNeedCounterpartyDetail),
				loading: false,
			}
		case ADD_AVAILABILITY_FROM_LOCATE_INVENTORY.triggered ||
			ADD_AVAILABILITY_FROM_LOCATE_INVENTORY.failed ||
			ADD_AVAILABILITY_FROM_LOCATE_INVENTORY.succeeded:
			return {...state}
		case CLEAR_MAIN_GRID_DATA:
			return {
				...state,
				securityInventory: null,
			}
	}
	return state
}
