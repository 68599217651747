import {getMPSBaseUrl} from '../../../services/apiService'
import {createFetchActions} from '../../../services/createActions'
import {get, post} from '../../../services/createAPICall'

export const DOWNLOAD_PRODUCT_DOCUMENT = createFetchActions('DOWNLOAD_PRODUCT_DOCUMENT')
export const PRODUCT_DOCUMENT_DETAILS = createFetchActions('PRODUCT_DOCUMENT_DETAILS')
export const SAVE_PRE_TRADE_FILE = createFetchActions('SAVE_PRE_TRADE_FILE')
export const SAVE_TRADE_LISTS = createFetchActions('SAVE_TRADE_LISTS')
export const UPLOADED_FILES_LIST = createFetchActions('UPLOADED_FILES_LIST')
export const DOWNLOAD_OLD_FILE = createFetchActions('UPLOADED_FILES_LIST')

export const downloadProductFile = (fileId: string) => (dispatch: any) => {
	return dispatch(
		get(
			DOWNLOAD_PRODUCT_DOCUMENT,
			`${getMPSBaseUrl()}/v1/margin-analytics/trades/template/download?fileId=${fileId}`,
			null,
			null,
			null,
			null,
			null,
			'blob'
		)
	)
}

export const getDocumentDetails = (productTypeSelected: string, productSubTypeSelected: string) => (dispatch: any) => {
	return dispatch(
		get(
			PRODUCT_DOCUMENT_DETAILS,
			`${getMPSBaseUrl()}/v1/margin-analytics/trades/template/${productTypeSelected}/${productSubTypeSelected}`
		)
	)
}

export const uploadPretradeFile =
	(
		fundCode: string,
		portfolioCode: string,
		productTypeSelected: string,
		productSubTypeSelected: string,
		tradeTypes1Selected: string,
		tradeTypes2Selected: string,
		data
	) =>
	(dispatch: any) => {
		return dispatch(
			post(
				SAVE_PRE_TRADE_FILE,
				`${getMPSBaseUrl()}/v1/margin-analytics/trades/upload-excel/${productTypeSelected}/${productSubTypeSelected}?fund_code=${
					fundCode || ''
				}&portfolio_code=${portfolioCode || ''}&trade_type_1=${tradeTypes2Selected || ''}&trade_type_2=${
					tradeTypes1Selected || ''
				}`,
				data
			)
		)
	}

export const createTradesLists = data => (dispatch: any) => {
	return dispatch(post(SAVE_TRADE_LISTS, `${getMPSBaseUrl()}/v1/margin-analytics/trades/create-list `, data))
}

export const getOldFiles = () => (dispatch: any) => {
	return dispatch(get(UPLOADED_FILES_LIST, `${getMPSBaseUrl()}/v1/margin-analytics/trades/files`))
}

export const downloadExistingFile = (fileId: string) => (dispatch: any) => {
	return dispatch(
		get(
			DOWNLOAD_OLD_FILE,
			`${getMPSBaseUrl()}/v1/margin-analytics/trades/file/download?fileId=${fileId}`,
			null,
			null,
			null,
			null,
			null,
			'blob'
		)
	)
}

