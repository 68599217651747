import {getALPBaseUrl} from '../../../services/apiService'
import {createFetchActions} from '../../../services/createActions'
import {get} from '../../../services/createAPICall'

export const FETCH_AUTHORIZER_TRADES = createFetchActions('FETCH_AUTHORIZER_TRADES')
export const CLEAR_MAIN_GRID_DATA = createFetchActions('CLEAR_MAIN_GRID_DATA')
export const ALTER_RATE_FIELD_DATA_AUTHORIZER = createFetchActions('ALTER_RATE_FIELD_DATA_AUTHORIZER')

export const fetchAuthorizerTrades = () => (dispatch: any) => {
	return dispatch(get(FETCH_AUTHORIZER_TRADES, `${getALPBaseUrl()}/v1/alp/trades`))
}

export const clearGridData = () => {
	return {
		type: CLEAR_MAIN_GRID_DATA,
	}
}

export const alterRateFieldDataAuthorizer = toggleMode => {
	return {
		type: ALTER_RATE_FIELD_DATA_AUTHORIZER,
		payload: {toggleMode},
	}
}
