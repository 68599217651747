import React from 'react'
import Toast from 'react-bootstrap/Toast'
import {Props, State} from './toastNotificationModel'
import {getToastStat} from './toastNotificationHelper'

const style = {
	position: 'fixed',
	top: 0,
	right: 0,
	minWidth: 270,
} as React.CSSProperties

export default class ToastNotification extends React.Component<Props, State> {
	constructor(props) {
		super(props)
		this.state = {
			showToast: false,
		}
	}

	componentDidUpdate(prevProps) {
		const {toastNotificationData} = this.props

		const {addedNewData, latestData} = toastNotificationData
		const {latestData: prevLatestData} = prevProps.toastNotificationData

		if (prevLatestData.length === 0 && latestData.length === 1) {
			this.setState({showToast: true})
		}

		if (addedNewData === false && latestData.length === prevLatestData.length - 1) {
			this.setState({showToast: true})
		}
	}

	handleOnClose = () => {
		this.setState({showToast: false}, () => {
			setTimeout(() => this.props.setNotificationData(null), 2000)
		})
	}

	render() {
		const {toastNotificationData} = this.props
		const {showToast} = this.state

		const toastStat = getToastStat(toastNotificationData)

		return (
			<>
				{toastStat.message && (
					<Toast
						animation
						autohide
						className={`notification-wrapper ${toastStat.warning ? 'bg-danger' : ''}`}
						delay={toastStat.delayTime}
						show={showToast}
						style={style}
						onClose={this.handleOnClose}
					>
						<Toast.Header>
							<span className='d-block'>{toastStat.title}</span>
							<span className='d-block time'>{toastStat.timeElasped}</span>
						</Toast.Header>
						<Toast.Body style={{whiteSpace: 'pre-line'}}>{toastStat.message}</Toast.Body>
					</Toast>
				)}
			</>
		)
	}
}
