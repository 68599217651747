import {put} from '../../services/createAPICall'
import {createFetchActions} from '../../services/createActions'
import {getPMSBaseUrl} from '../../services/apiService'

export const PRESERVE_DECISION_INPUT = createFetchActions('PRESERVE_DECISION_INPUT')
export const FETCH_DECISION_OUTPUT = createFetchActions('FETCH_DECISION_OUTPUT')
export const FETCH_INDEX_ANALYSIS_OUTPUT = createFetchActions('FETCH_INDEX_ANALYSIS_OUTPUT')
export const DELETE_IOM_DATA = 'DELETE_IOM_DATA'
export const FETCH_TRACKING_ANALYSIS_OUTPUT = createFetchActions('FETCH_TRACKING_ANALYSIS_OUTPUT')
export const DELETE_TRACKING_BASKET_DATA = 'DELETE_TRACKING_BASKET_DATA'

export const preserveData = (filterData: any, inputData: any) => (dispatch: any) => {
	dispatch({
		type: PRESERVE_DECISION_INPUT.triggered,
		filterData,
		inputData,
	})
}

export const fetchRenderingData = (data: any) => (dispatch: any) => {
	return dispatch(
		put(FETCH_DECISION_OUTPUT, `${getPMSBaseUrl()}/v1/decisionMaking`, data, null, 'Internal Server Error')
	)
}

export const fetchIndexAnalysisData = (data: any) => (dispatch: any) => {
	return dispatch(
		put(FETCH_INDEX_ANALYSIS_OUTPUT, `${getPMSBaseUrl()}/v1/decisionMaking`, data, null, 'Internal Server Error')
	)
}

export const deleteIOMData = data => {
	return {
		type: DELETE_IOM_DATA,
	}
}

export const fetchTrackingAnalysisData = (data: any) => (dispatch: any) => {
	return dispatch(
		put(FETCH_TRACKING_ANALYSIS_OUTPUT, `${getPMSBaseUrl()}/v1/decisionMaking`, data, null, 'Internal Server Error')
	)
}

export const deleteTrackingBasket = data => {
	return {
		type: DELETE_TRACKING_BASKET_DATA,
		data,
	}
}
