import {
	CLEAR_MAIN_GRID_DATA,
	FETCH_LOCATE_REQUESTER_DETAILS,
	FETCH_LOCATE_REQUEST_ENTRY_DETAIL,
	SAVE_LOCATE_REQUEST,
	TRIGGER_LOCATE_ACTION,
} from './LocateRequesterActions'
import {modifiedData} from './LocateRequesterHelper'

export interface AlpRequester {
	loading: boolean
	gridLoading: boolean
	locateClientRequestQueueData: any
	selectedLocateRequestEntryDetail: any
}

const initialState: AlpRequester = {
	loading: false,
	gridLoading: false,
	locateClientRequestQueueData: null,
	selectedLocateRequestEntryDetail: {},
}

export function AlpRequesterReducer(state = initialState, action: any) {
	switch (action.type) {
		case SAVE_LOCATE_REQUEST.triggered:
			return {
				...state,
				loading: true,
			}
		case SAVE_LOCATE_REQUEST.failed:
			return {...state, loading: false}
		case SAVE_LOCATE_REQUEST.succeeded:
			return {...state, loading: false}

		case FETCH_LOCATE_REQUESTER_DETAILS.triggered:
			return {...state, locateClientRequestQueueData: null, gridLoading: true}
		case FETCH_LOCATE_REQUESTER_DETAILS.failed:
			return {...state, locateClientRequestQueueData: [], gridLoading: false}
		case FETCH_LOCATE_REQUESTER_DETAILS.succeeded:
			const locateClientRequestQueueData = action.response.data && action.response.data.allRequesterLocateRequestEntry
			return {
				...state,
				locateClientRequestQueueData: modifiedData(locateClientRequestQueueData),
				gridLoading: false,
			}

		case TRIGGER_LOCATE_ACTION.triggered:
			return {...state, loading: true}
		case TRIGGER_LOCATE_ACTION.failed:
			return {...state, loading: false}
		case TRIGGER_LOCATE_ACTION.succeeded:
			return {...state, loading: false}

		case FETCH_LOCATE_REQUEST_ENTRY_DETAIL.triggered:
			return {...state, selectedLocateRequestEntryDetail: {}, loading: true}
		case FETCH_LOCATE_REQUEST_ENTRY_DETAIL.failed:
			return {...state, selectedLocateRequestEntryDetail: {}, loading: false}
		case FETCH_LOCATE_REQUEST_ENTRY_DETAIL.succeeded:
			const selectedLocateRequestEntryDetail = action.response.data && action.response.data.locateRequestEntryDetail
			return {...state, selectedLocateRequestEntryDetail, loading: false}
		case CLEAR_MAIN_GRID_DATA:
			return {
				...state,
				locateClientRequestQueueData: null,
			}
	}
	return state
}
