import React from 'react'
// import FsCircularSpin from '../../styles/svg/fsCircularSpin'

interface Props {
	hideLoader?: boolean
}

export default class FsLoader extends React.Component<Props> {
	constructor(props: any) {
		super(props)
		this.setAppCursorStyle()
	}

	componentDidUpdate(prevProps) {
		const {hideLoader} = this.props
		if (prevProps.hideLoader !== hideLoader) this.setAppCursorStyle()
	}

	componentWillUnmount() {
		document.getElementById('root').style.cursor = 'default'
	}

	setAppCursorStyle = () => {
		const {hideLoader = false} = this.props
		document.getElementById('root').style.cursor = hideLoader ? 'default' : 'progress'
	}

	render() {
		const {hideLoader = false} = this.props
		return (
			<>
				{/* {!hideLoader && (
          <div className="FsLoader">
            <FsCircularSpin />
          </div>
        )} */}
				{!hideLoader && (
					<div className='ajax-loader'>
						<div className='ajax-animator'></div>
					</div>
				)}
			</>
		)
	}
}
