import {GET_CUSIP_SCHEDULE_DATA} from './CusipScheduleActions'
import {transformData} from './CusipScheduleHelper'

export interface CusipSchedule {
	cusipScheduleData: any
	cusipScheduleDataLoading: boolean
}

const initialState: CusipSchedule = {
	cusipScheduleData: [],
	cusipScheduleDataLoading: false,
}

export function CusipScheduleReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_CUSIP_SCHEDULE_DATA.triggered:
			return {...state, cusipScheduleData: [], cusipScheduleDataLoading: true}
		case GET_CUSIP_SCHEDULE_DATA.failed:
			return {...state, cusipScheduleData: [], cusipScheduleDataLoading: false}
		case GET_CUSIP_SCHEDULE_DATA.succeeded:
			const cusipScheduleData = transformData(action.response.data)
			return {...state, cusipScheduleData, cusipScheduleDataLoading: false}
	}
	return state
}
