import {createFetchActions} from '../../services/createActions'
import {getPMSBaseUrl} from '../../services/apiService'
import {put} from '../../services/createAPICall'
export const GET_ABPM_DATA = createFetchActions('GET_ABPM_DATA')

export function getAbpmDataAndColumns() {
	return function (dispatch) {
		dispatch(
			put(
				GET_ABPM_DATA,
				`${getPMSBaseUrl()}/v1/decisionMaking`,
				{
					params: {
						objective: [],
						strategy: 'Collateral Optimization',
					},
					requestType: 'abpm',
				},

				null,
				'Internal Server Error occured while getting Abpm data'
			)
		)
	}
}
