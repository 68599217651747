import {createFetchActions} from '../../../services/createActions'
import {get, post, put} from '../../../services/createAPICall'
import {getConfigBaseUrl} from '../../../services/apiService'
import {getConfigClient} from '../../..'
import {getStrategiesQuery, getModulesQuery} from './userGroupQueries'
import {getCounterpartyId} from '../../../services/authService'

export const GET_USER_GROUPS = createFetchActions('GET_USER_GROUPS')
export const GET_SELECTED_GROUP = createFetchActions('GET_SELECTED_GROUP')
export const SAVE_USER_GROUPS = createFetchActions('SAVE_USER_GROUPS')
export const EDIT_USER_GROUPS = createFetchActions('EDIT_USER_GROUPS')
export const CLEAR_USER_GROUPS = createFetchActions('CLEAR_USER_GROUPS')
export const CLEAR_SELECTED_GROUP_DETAILS = createFetchActions('CLEAR_SELECTED_GROUP_DETAILS')
export const GET_STRATEGIES = createFetchActions('GET_STRATEGIES')
export const GET_MODULES = createFetchActions('GET_MODULES')

export const getUserGroups = () => (dispatch: any) => {
	return dispatch(get(GET_USER_GROUPS, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/groups/`))
}

export const getGroupDetails = (groupId: string) => (dispatch: any) => {
	return dispatch(
		get(GET_SELECTED_GROUP, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/groups/${groupId}`)
	)
}

export const getStrategies = () => {
	return function (dispatch) {
		dispatch({type: GET_STRATEGIES.triggered})
		getConfigClient()
			.query({
				query: getStrategiesQuery,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_STRATEGIES.succeeded,
					response,
				})
			})
	}
}

export const getModules = () => {
	return function (dispatch) {
		dispatch({type: GET_MODULES.triggered})
		getConfigClient()
			.query({
				query: getModulesQuery,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_MODULES.succeeded,
					response,
				})
			})
	}
}

export const saveUserGroup = (data: any) => (dispatch: any) => {
	return dispatch(
		post(SAVE_USER_GROUPS, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/groups/`, data)
	)
}

export const editUserGroup = (data: any) => (dispatch: any) => {
	return dispatch(
		put(EDIT_USER_GROUPS, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/groups/${data.groupId}`, data)
	)
}

export const clearUserGroups = () => {
	return {
		type: CLEAR_USER_GROUPS,
	}
}
export const clearSelectedGroupDetails = () => {
	return {
		type: CLEAR_SELECTED_GROUP_DETAILS,
	}
}
