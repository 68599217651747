/**
 * Text Field: Reusable
 * Features: Creates text field for input or form field
 */

import * as React from 'react'
import {ErrorMessage} from 'formik'

interface Props {
	id?: string
	label?: string
	type?: string
	className?: string
	disabled?: boolean
	placeholder?: string
	onChange?: any
	onBlur?: any
	formClass?: any
	autoComplete?: string
	value?: string
	style?: any
	step?: number
}

interface State {}

export default class FsTextField extends React.Component<Props, State> {
	render() {
		const {
			id = 'default_FsTextField',
			label = '',
			type = 'text',
			className = '',
			placeholder = '',
			autoComplete = 'off',
			step = '1',
			formClass = 'pb-2',
			disabled = false,
		} = this.props

		return (
			<>
				<div className={`form-group ${formClass}`}>
					<label className='mb-1' htmlFor={id}>
						{label}
					</label>
					<input
						id={id}
						type={type}
						className={`form-control ${className} ${disabled ? 'cursor-not-allowed' : ''}`}
						disabled={disabled}
						aria-describedby={type + 'help'}
						placeholder={placeholder}
						onChange={this.props.onChange}
						onBlur={this.props.onBlur}
						autoComplete={autoComplete}
						value={this.props.value}
						style={this.props.style}
						step={step}
					/>
					<ErrorMessage name={`${id}`}>
						{msg => <div style={{fontSize: '80%', color: '#FF695D', width: '100%'}}>{msg}</div>}
					</ErrorMessage>
				</div>
			</>
		)
	}
}
