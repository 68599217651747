import {GET_MY_COUNTERPARTIES, CLEAR_PRODUCT_TYPE_SUBTYPE, GET_PRODUCT_TYPE_AND_SUB_TYPE} from './MarginAnalysisAction'
import {GET_ALL_BASKETS, GET_ALL_TRADES, ADD_FORECAST_TRADES, GET_ALL_BASE_TRADES} from './MarginAnalysisAction'
import {GET_TRADE_BASKET, UPDATE_TRADE_BASKET, CLEAR_TRADE_BASKET} from './MarginAnalysisAction'
import {GET_ALL_MARGIN_COMPARISON, GET_MARGIN_COMPARISON, INVOKE_MARGIN_COMPARISON} from './MarginAnalysisAction'
import {CLEAR_DYNAMIC_FORM_DETAIL, EDIT_DYNAMIC_FORM, UPDATE_FORECAST_TRADES_LIST} from './MarginAnalysisAction'
import {GET_DYNAMIC_FORM_DETAIL, GET_BASE_FORM_DETAIL} from './MarginAnalysisAction'
import {SAVE_DYNAMIC_FORM_DETAIL, SET_MARGIN_COMPARISON_CONTEXT, GET_ALL_SOD_TRADES} from './MarginAnalysisAction'
import {SAVE_BASE_TRADE_FORM_DETAIL, SAVE_FORECAST_TRADE_FORM_DETAIL} from './MarginAnalysisAction'
import {DELETE_TRADE_BY_ID, GET_PRODUCT_RISK_CLASSES} from './MarginAnalysisAction'
import {massageBasketsGridData, massageTradesGridData} from './MarginAnalysisHelper'
import {SOD_FILE_DETAILS} from './SODFiles/SODFileDetailsAction'
import {SAVE_PRE_TRADE_FILE, PRODUCT_DOCUMENT_DETAILS, SAVE_TRADE_LISTS} from './TradeUpload/TradeUploadAction'

export interface MarginAnalysis {
	loading: boolean
	allTrades: any
	baseTrades: any
	tradeBasket: any
	editDynamicForm: any
	allSODTrades: any
	forecastData: any
	allBaskets: any
	comparisonDetails: any
	contextComparisonDetails: any
	deleting: boolean
	dynamicFormDetail: any
	dynamicFormDetailSaved: any[]
	productTypeAndSubType: any[]
	productRiskClasses: any[]
	sodFiledDetails: any
	documentDetails: any
	uploadFileDetails: any
	uploadFileLoading: boolean
	myCounterParties: any[]
	baseTradeFormDetailSaved: any[]
	forecastTradeFormDetailSaved: any[]
	invokemargin: any
	forecastTradesList: any
}

const initialState: MarginAnalysis = {
	loading: false,
	allTrades: [],
	baseTrades: [],
	tradeBasket: [],
	editDynamicForm: {},
	allSODTrades: [],
	forecastData: {},
	allBaskets: [],
	comparisonDetails: [],
	contextComparisonDetails: null,
	deleting: false,
	dynamicFormDetail: null,
	dynamicFormDetailSaved: null,
	baseTradeFormDetailSaved: null,
	forecastTradeFormDetailSaved: null,
	productTypeAndSubType: null,
	productRiskClasses: null,
	sodFiledDetails: {headers: [], trades: []},
	documentDetails: {},
	uploadFileDetails: {},
	uploadFileLoading: false,
	myCounterParties: [],
	invokemargin: null,
	forecastTradesList: [],
}

export function MarginAnalysisReducer(state = initialState, action: any) {
	switch (action.type) {
		case CLEAR_DYNAMIC_FORM_DETAIL:
			return {...state, dynamicFormDetail: null}

		case CLEAR_PRODUCT_TYPE_SUBTYPE:
			return {...state, productTypeAndSubType: null, productRiskClasses: null}

		case GET_ALL_TRADES.triggered:
			return {...state}
		case GET_ALL_TRADES.succeeded:
			const allTrades = action.response && action.response.data ? action.response.data : []
			return {
				...state,
				allTrades: massageTradesGridData(allTrades),
			}
		case GET_ALL_TRADES.failed:
			return {...state, allTrades: []}

		case GET_ALL_BASE_TRADES.triggered:
			return {...state}
		case GET_ALL_BASE_TRADES.succeeded:
			const baseTrades = action.response && action.response.data ? action.response.data : []
			return {
				...state,
				baseTrades: massageTradesGridData(baseTrades),
			}
		case GET_ALL_BASE_TRADES.failed:
			return {...state, baseTrades: []}

		case GET_TRADE_BASKET.triggered:
			return {
				...state,
			}
		case GET_TRADE_BASKET.succeeded:
			const tradeBasket = action.response && action.response.data ? action.response.data : []
			return {
				...state,
				tradeBasket: massageTradesGridData(tradeBasket),
			}
		case GET_TRADE_BASKET.failed:
			return {...state, tradeBasket: []}

		case UPDATE_TRADE_BASKET:
			return {
				...state,
				tradeBasket: massageTradesGridData(action.response),
			}

		case ADD_FORECAST_TRADES:
			return {
				...state,
				forecastData: action.response,
			}

		case UPDATE_FORECAST_TRADES_LIST:
			return {
				...state,
				forecastTradesList: action.response,
			}

		case CLEAR_TRADE_BASKET:
			return {
				...state,
				tradeBasket: [],
				dynamicFormDetailSaved: null,
			}

		case EDIT_DYNAMIC_FORM:
			return {
				...state,
				editDynamicForm: action.value,
			}

		case INVOKE_MARGIN_COMPARISON.triggered:
			return {
				...state,
				invokemargin: null,
			}
		case INVOKE_MARGIN_COMPARISON.succeeded:
			return {
				...state,
				invokemargin: null,
			}
		case INVOKE_MARGIN_COMPARISON.failed:
			return {
				...state,
				invokemargin: action && action.response,
			}

		case GET_DYNAMIC_FORM_DETAIL.triggered:
			return {...state, dynamicFormDetail: null}
		case GET_DYNAMIC_FORM_DETAIL.succeeded:
			return {...state, dynamicFormDetail: action.response.data}
		case GET_DYNAMIC_FORM_DETAIL.failed:
			return {...state, dynamicFormDetail: [], editDynamicForm: {rowId: null, enableEdit: false}}

		case GET_BASE_FORM_DETAIL.triggered:
			return {...state, dynamicFormDetail: null}
		case GET_BASE_FORM_DETAIL.succeeded:
			return {...state, dynamicFormDetail: action.response.data}
		case GET_BASE_FORM_DETAIL.failed:
			return {...state, dynamicFormDetail: [], editDynamicForm: {rowId: null, enableEdit: false}}

		case GET_PRODUCT_TYPE_AND_SUB_TYPE.triggered:
			return {...state, productTypeAndSubType: null}
		case GET_PRODUCT_TYPE_AND_SUB_TYPE.succeeded:
			return {...state, productTypeAndSubType: action.response.data}
		case GET_PRODUCT_TYPE_AND_SUB_TYPE.failed:
			return {...state, productTypeAndSubType: []}

		case SAVE_DYNAMIC_FORM_DETAIL.triggered:
			return {...state, dynamicFormDetailSaved: null}
		case SAVE_DYNAMIC_FORM_DETAIL.succeeded:
			return {...state, dynamicFormDetailSaved: action.response.data}
		case SAVE_DYNAMIC_FORM_DETAIL.failed:
			return {...state, dynamicFormDetailSaved: null}

		case SAVE_BASE_TRADE_FORM_DETAIL.triggered:
			return {...state, baseTradeFormDetailSaved: null}
		case SAVE_BASE_TRADE_FORM_DETAIL.succeeded:
			return {...state, baseTradeFormDetailSaved: action.response.data}
		case SAVE_BASE_TRADE_FORM_DETAIL.failed:
			return {...state, baseTradeFormDetailSaved: null}

		case SAVE_FORECAST_TRADE_FORM_DETAIL.triggered:
			return {...state, forecastTradeFormDetailSaved: null}
		case SAVE_FORECAST_TRADE_FORM_DETAIL.succeeded:
			return {...state, forecastTradeFormDetailSaved: action.response.data}
		case SAVE_FORECAST_TRADE_FORM_DETAIL.failed:
			return {...state, forecastTradeFormDetailSaved: null}

		case GET_ALL_BASKETS.triggered:
			return {...state}
		case GET_ALL_BASKETS.succeeded:
			const allBaskets = action.response && action.response.data ? action.response.data : []
			return {...state, allBaskets: massageBasketsGridData(allBaskets)}
		case GET_ALL_BASKETS.failed:
			return {...state, allBaskets: []}

		case GET_MARGIN_COMPARISON.triggered:
			return {...state}
		case GET_MARGIN_COMPARISON.succeeded:
			// const comparisonDetails = action.response && action.response.data ? action.response.data : []
			return {...state}
		case GET_MARGIN_COMPARISON.failed:
			return {...state, comparisonDetails: []}

		case GET_ALL_MARGIN_COMPARISON.triggered:
			return {...state}
		case GET_ALL_MARGIN_COMPARISON.succeeded:
			const comparisonDetails = action.response && action.response.data ? action.response.data : []
			return {...state, comparisonDetails}
		case GET_ALL_MARGIN_COMPARISON.failed:
			return {...state, comparisonDetails: []}

		case GET_ALL_SOD_TRADES.triggered:
			return {...state}
		case GET_ALL_SOD_TRADES.succeeded:
			const allSODTrades = action.response && action.response.data ? action.response.data : []
			return {...state, allSODTrades: massageTradesGridData(allSODTrades)}
		case GET_ALL_SOD_TRADES.failed:
			return {...state, allSODTrades: []}

		case SET_MARGIN_COMPARISON_CONTEXT:
			const contextComparisonDetails = (action && action.payload && action.payload.response) || null
			return {...state, contextComparisonDetails}

		case GET_PRODUCT_RISK_CLASSES.triggered:
			return {...state, productRiskClasses: null}
		case GET_PRODUCT_RISK_CLASSES.succeeded:
			return {...state, productRiskClasses: action.response.data}
		case GET_PRODUCT_RISK_CLASSES.failed:
			return {...state, productRiskClasses: []}

		case GET_MY_COUNTERPARTIES.triggered:
			return {...state, myCounterParties: null}
		case GET_MY_COUNTERPARTIES.succeeded:
			return {...state, myCounterParties: action.response.data.allClients}
		case GET_MY_COUNTERPARTIES.failed:
			return {...state, myCounterParties: []}

		case DELETE_TRADE_BY_ID.triggered:
			return {...state, deleting: true}
		case DELETE_TRADE_BY_ID.failed:
			return {...state, deleting: false}
		case DELETE_TRADE_BY_ID.succeeded:
			return {
				...state,
				deleting: false,
			}

		case SOD_FILE_DETAILS.triggered:
		case SOD_FILE_DETAILS.failed:
			return {...state, sodFiledDetails: {headers: [], trades: []}}
		case SOD_FILE_DETAILS.succeeded:
			return {...state, sodFiledDetails: action.response.data}

		case PRODUCT_DOCUMENT_DETAILS.triggered:
			return {...state, documentDetails: null}
		case PRODUCT_DOCUMENT_DETAILS.succeeded:
			return {...state, documentDetails: action.response.data}
		case PRODUCT_DOCUMENT_DETAILS.failed:
			return {...state, documentDetails: null}

		case SAVE_PRE_TRADE_FILE.triggered:
			return {...state, uploadFileLoading: true, uploadFileDetails: null}
		case SAVE_PRE_TRADE_FILE.succeeded:
			return {...state, uploadFileLoading: false, uploadFileDetails: action.response.data}
		case SAVE_PRE_TRADE_FILE.failed:
			return {...state, uploadFileLoading: false, uploadFileDetails: null}

		case SAVE_TRADE_LISTS.triggered:
			return {...state, uploadFileDetails: null}
		case SAVE_TRADE_LISTS.succeeded:
			return {...state, uploadFileDetails: action.response.data}
		case SAVE_TRADE_LISTS.failed:
			return {...state, uploadFileDetails: null}
	}
	return state
}

