import gql from 'graphql-tag'

export const fetchSecurityInventory = () => {
	return gql`
		query {
			securityInventory {
				securityId
				quantity
				closingPrice {
					amount
					currency
				}
				allocatedQuantity
				sodQuantity
				totalRequestedQuantity
				modifiedDate
				ticker
				securityName
			}
		}
	`
}

export const fetchAvailNeedCounterpartyDetail = securityId => {
	return gql`
		query {
      availNeedCounterpartyDetail(securityId:"${securityId}"){
        securityId
        counterpartyQuantities{
          counterparty
          quantity
        }
      }
		}
	`
}
