import gql from 'graphql-tag'

export const getSettlementDetailsDataQuery = gql`
	query {
		settlementDetails {
			securityId
			impact {
				day
				impact {
					amount
					currency
				}
				qtyImpact
			}
		}
	}
`
