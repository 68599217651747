import {GET_ORG_INFO, EDIT_ORG_INFO, CLEAR_ORG_INFO_DATA} from './myOrgInfoActions'
import {GET_CURRENCIES, GET_COUNTRIES, GET_TIMEZONES} from './myOrgInfoActions'
import {OrgInfo, Country, Currency, TimeZone} from './myOrgInfoModel'

export interface OrgInfoData {
	loading: boolean
	data: any
	currencies: Currency[]
	countries: Country[]
	timeZones: TimeZone[]
}

const initialState: OrgInfoData = {
	loading: false,
	data: {} as OrgInfo,
	currencies: [],
	countries: [],
	timeZones: [],
}

export function orgInfoReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_ORG_INFO.triggered:
			return {...state, loading: true}
		case GET_ORG_INFO.succeeded:
			let data = action.response.data || {}
			return {
				...state,
				loading: false,
				data,
			}
		case GET_ORG_INFO.failed:
			return {...state, loading: false}

		case EDIT_ORG_INFO.triggered:
			return {...state, loading: true}
		case EDIT_ORG_INFO.succeeded:
		case EDIT_ORG_INFO.failed:
			return {...state, loading: false}

		case GET_CURRENCIES.triggered:
			return {...state, loading: true}
		case GET_CURRENCIES.succeeded:
			const currencies = (action.response.data && action.response.data.allCurrencies) || []
			return {...state, loading: false, currencies}
		case GET_CURRENCIES.failed:
			return {...state, loading: false}

		case GET_COUNTRIES.triggered:
			return {...state, loading: true}
		case GET_COUNTRIES.succeeded:
			const countries = (action.response.data && action.response.data.allCountries) || []
			return {...state, loading: false, countries}
		case GET_COUNTRIES.failed:
			return {...state, loading: false}

		case GET_TIMEZONES.triggered:
			return {...state, loading: true}
		case GET_TIMEZONES.succeeded:
			const timeZones = (action.response.data && action.response.data.allTimeZones) || []
			return {...state, loading: false, timeZones}
		case GET_TIMEZONES.failed:
			return {...state, loading: false}

		case CLEAR_ORG_INFO_DATA:
			return {...initialState}
	}

	return state
}
