import {
	GET_AVAILABILITIES,
	GET_AVAILABILITIES_WITH_SCHEDULE,
	GET_MARGIN_DETAIL,
	GET_MM_ORDER_DETAIL,
	GET_MY_TRADE_BOOK,
	GET_MM_EXTENDABLE_COLL_LOGS,
} from './MarginManagementAction'

export interface MarginManagement {
	loading: boolean
	myTradeBook: any[]
	marginDetail: any
	availabilities: any
	availabilitiesWithSchedule: any
	marginManagementOrderDetail: any
	activityLog: any
}

const initialState: MarginManagement = {
	loading: false,
	myTradeBook: null,
	marginDetail: {},
	availabilities: null,
	availabilitiesWithSchedule: null,
	marginManagementOrderDetail: {},
	activityLog: {},
}

export function MarginManagementReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_MY_TRADE_BOOK.triggered:
			return {...state}
		case GET_MY_TRADE_BOOK.succeeded:
			const myTradeBook = action.response && action.response.marginTrades ? action.response.marginTrades : []
			return {...state, myTradeBook}
		case GET_MY_TRADE_BOOK.failed:
			return {...state, myTradeBook: []}

		case GET_MARGIN_DETAIL.triggered || GET_MARGIN_DETAIL.failed:
			return {...state, marginDetail: state.marginDetail}
		case GET_MARGIN_DETAIL.succeeded:
			const marginDetail = JSON.parse(JSON.stringify(state.marginDetail))
			marginDetail[action.response.data.orderId] = action.response.data
			return {...state, marginDetail}

		case GET_AVAILABILITIES.triggered:
			return {...state, availabilities: null}
		case GET_AVAILABILITIES.succeeded:
			return {...state, availabilities: action.response.portfolio.boxesEnrichDetails.entries}
		case GET_AVAILABILITIES.failed:
			return {...state, availabilities: state.availabilities}

		case GET_AVAILABILITIES_WITH_SCHEDULE.triggered:
			return {...state, availabilitiesWithSchedule: null}
		case GET_AVAILABILITIES_WITH_SCHEDULE.succeeded:
			return {...state, availabilitiesWithSchedule: action.response.boxesEnrichDetailsForSchedule.entries}
		case GET_AVAILABILITIES_WITH_SCHEDULE.failed:
			return {...state, availabilitiesWithSchedule: state.availabilitiesWithSchedule}

		case GET_MM_ORDER_DETAIL.triggered || GET_MM_ORDER_DETAIL.failed:
			return {...state}
		case GET_MM_ORDER_DETAIL.succeeded:
			const marginManagementOrderDetail = JSON.parse(JSON.stringify(state.marginManagementOrderDetail))
			if (action.response) marginManagementOrderDetail[action.orderId] = action.response.order
			return {...state, marginManagementOrderDetail}

		case GET_MM_EXTENDABLE_COLL_LOGS.triggered || GET_MM_EXTENDABLE_COLL_LOGS.failed:
			return {...state}
		case GET_MM_EXTENDABLE_COLL_LOGS.succeeded:
			const activityLog = JSON.parse(JSON.stringify(state.activityLog))
			if (action.response) activityLog[action.orderId] = action.response.extendableOrderResponseCollaborationLogs

			return {...state, activityLog}
	}
	return state
}
