import memoizeOne from 'memoize-one'
import isDeepEqual from 'lodash.isequal'
import CryptoJS from 'crypto-js'
import {ArrayUtil} from 'helper-util'
import {
	formatWithTwoDecimals,
	formatWithFourDecimals,
	formatWithSeparatorAndSixDecimals,
	formatWithSixDecimals,
	formatWithThreeDecimals,
} from '../../utils/formatUtils'
import {formatWithSeparator, formatWithSeparatorAndDecimals} from '../../utils/formatUtils'
import {formatDate, formatWithDateAndTime, formatWithTime} from '../../utils/dateUtil'
import {formatDateArray, formatDateArrayTz} from '../../utils/dateUtil'
import {defaultColDef, statusBar} from './AgGridConstant'
import {Checkbox} from './AgGridModel'

const urlEncryptionKey = 'abc-portfolio-url-encrytion-key'

export const linkClass = {
	cellClass: ['text-link'],
}

export const cellClass = {
	error: {
		'bg-error border-bottom-solid-red': params => Boolean(params.data.error && params.data.error[params.colDef.field]),
	},
	blue: {
		headerClass: ['bg-blue', 'ag-right-aligned-header'],
		cellClass: params => {
			return ['bg-blue', 'ag-right-aligned-cell']
		},
	},
	yellow: {
		headerClass: ['bg-yellow', 'ag-right-aligned-header'],
		cellClass: params => {
			return ['bg-yellow', 'ag-right-aligned-cell']
		},
	},
	cream: {
		headerClass: ['bg-cream', 'ag-right-aligned-header'],
		cellClass: params => {
			return ['bg-cream', 'ag-right-aligned-cell']
		},
	},
}

export const successDangerClass = (reverseColor: boolean = false) => {
	return {
		cellClass: params => {
			const cellClass = ['ag-right-aligned-cell']
			if (Number(params.value) > 0) cellClass.push(reverseColor ? 'text-danger' : 'text-success')
			if (Number(params.value) < 0) cellClass.push(reverseColor ? 'text-success' : 'text-danger')
			return cellClass
		},
	}
}

export const pinnedLeft = {
	pinned: 'left',
	lockPosition: true,
	lockPinned: true,
	rowDrag: false,
	width: 100,
}

export const noColumnAction = {
	filter: false,
	resizable: false,
	sortable: false,
	menuTabs: [],
}

export const iconWidth = {
	minWidth: 55,
	width: 55,
	maxWidth: 55,
}

export const noPinnedFooter = {
	noFooter: true,
	pinnedRowCellRenderer: params => {
		const {style, value} = params
		const eGui = document.createElement('div')
		!ArrayUtil.isEmpty(style) &&
			style.forEach(property => {
				eGui.style[property] = style[property]
			})
		eGui.innerHTML = value
	},
}

export const fontWeight = {
	cellStyle: {'font-weight': 'bold'},
}

/* Number Formatter */
export const commaNoDecimal = {
	type: 'numericColumn',
	cellRenderer: params => {
		return params.value !== null ? formatWithSeparator(Number(params.value)) : null
	},
}
export const commaTwoDecimal = {
	type: 'numericColumn',
	cellRenderer: params => {
		return params.value !== null ? formatWithSeparatorAndDecimals(Number(params.value)) : null
	},
}

export const commaSixDecimal = {
	type: 'numericColumn',
	cellRenderer: params => {
		return params.value !== null ? formatWithSeparatorAndSixDecimals(Number(params.value)) : null
	},
}
export const numberOnly = {
	type: 'numericColumn',
	cellRenderer: params => {
		return Number.isNaN(Number(params.value)) ? null : Number(params.value)
	},
}
export const commaTwoDecimalPercent = {
	type: 'numericColumn',
	cellRenderer: params => {
		return params.value !== null ? formatWithSeparatorAndDecimals(Number(params.value) * 100) : null
	},
}
export const noCommaNoDecimal = {
	type: 'numericColumn',
	cellRenderer: params => {
		return params.value !== null ? Math.round(params.value) : null
	},
}
export const noCommaTwoDecimal = {
	type: 'numericColumn',
	cellRenderer: params => {
		return params.value !== null ? formatWithTwoDecimals(Number(params.value)) : null
	},
}
export const noCommaThreeDecimal = {
	type: 'numericColumn',
	cellRenderer: params => {
		return params.value !== null ? formatWithThreeDecimals(Number(params.value)) : null
	},
}
export const noCommaFourDecimal = {
	type: 'numericColumn',
	cellRenderer: params => {
		return params.value !== null ? formatWithFourDecimals(Number(params.value)) : null
	},
}
export const noCommaTwoDecimalPercent = {
	type: 'numericColumn',
	cellRenderer: params => {
		return params.value !== null ? formatWithTwoDecimals(Number(params.value) * 100) : null
	},
}
export const noCommaSixDecimal = {
	type: 'numericColumn',
	cellRenderer: params => {
		return params.value !== null ? formatWithSixDecimals(Number(params.value)) : null
	},
}

/* Date Time Formatter */
export const yyyyMmDd = {
	cellRenderer: params => {
		return params.value ? formatDate(params.value) : null
	},
}

export const yyyyMMDdHHmmss = {
	cellRenderer: params => {
		return params.value ? formatDateArray(params.value) : null
	},
}

export const yyyyMMDdHHmmssTz = {
	cellRenderer: params => {
		return params.value ? formatDateArrayTz(params.value, params.colDef.zone) : null
	},
}

export const HHmmss = {
	cellRenderer: params => {
		return params.value ? formatWithTime(params.value) : null
	},
}
export const yyyyMmDdHHmmss = {
	cellRenderer: params => {
		return params.value ? formatWithDateAndTime(params.value) : null
	},
}

export const debounce = (func, wait, immediate?) => {
	let timeout
	return function () {
		const later = function () {
			timeout = null
			if (!immediate) func.apply(this, arguments)
		}
		const callNow = immediate && !timeout
		clearTimeout(timeout)
		timeout = setTimeout(later, wait)
		if (callNow) func.apply(this, arguments)
	}
}

export const getMainMenuItems = params => {
	params.defaultItems.splice(3, 4)
	return params.defaultItems
}

export const isRowSelectable = rowNode => {
	const {data} = rowNode
	return data && data.disableCheckbox === true ? false : true
}

export const getDefaultColDef = (editableCell: boolean) => {
	const updatedDefaultColDef = JSON.parse(JSON.stringify(defaultColDef))
	if (editableCell) updatedDefaultColDef.editable = true
	return updatedDefaultColDef
}

export const getDetailCellRendererParams = memoizeOne(
	(detailColumnDefs: any, checkBox: Checkbox, onRowSelected: any, openSingleAccordion: any) => {
		if (!detailColumnDefs) return undefined

		const detailCellRendererParams = {
			detailGridOptions: {
				columnDefs: detailColumnDefs,
				defaultColDef,
				rowSelection: 'multiple',
				getMainMenuItems: params => getMainMenuItems(params),
				suppressCellSelection: true,
				groupMultiAutoColumn: true,
				enableRangeSelection: true,
				statusBar,
			},
			getDetailRowData: params => {
				openSingleAccordion(params.node.parent.rowIndex)
				params.successCallback(params.data.subTableData)
			},
		}

		if (Boolean(checkBox)) {
			detailCellRendererParams.detailGridOptions['isRowSelectable'] = rowNode => isRowSelectable(rowNode)
			detailCellRendererParams.detailGridOptions['onRowSelected'] = debounce(onRowSelected, 500)
		}

		return detailCellRendererParams
	},
	isDeepEqual
)

export const addColumnIdToColumnDefs = memoizeOne((columnDefs: any) => {
	if (!columnDefs) return undefined
	const colDefsWithColIds = columnDefs.map(item => Object.assign({}, item, {colId: item.field}))
	return colDefsWithColIds
}, isDeepEqual)

const calculateTotal = (field: any, gridApi: any, col: any) => {
	let total = 0
	if (col.type === 'numericColumn' && col.noFooter !== true) {
		gridApi.forEachNodeAfterFilterAndSort(rowNode => {
			const value = Number(rowNode.data && rowNode.data[col.field])
			total += isNaN(value) ? 0 : value
		})
	}

	field[col.field] = col.type === 'numericColumn' ? (col.rounding ? total : Math.round(total)) : ''
}

export const getPinnedBottomRowData = (gridApi: any, column: any[]) => {
	const field = {}
	column.forEach(col => {
		if (col.children) {
			col.children.forEach(childCol => {
				calculateTotal(field, gridApi, childCol)
			})
		} else {
			calculateTotal(field, gridApi, col)
		}
	})
	return [field]
}

export const encryptURL = (data: any) => {
	return CryptoJS.AES.encrypt(JSON.stringify(data), urlEncryptionKey).toString()
}
