import gql from 'graphql-tag'

export const getInventoryFileQuery = gql`
	{
		inventoryFileDetails {
			inventoryDocumentId
			counterParty
			clientId
			originalFilename
			createdDate
			createdBy
			totalNotional
			settlementDate
			tradeDate
			maturityDate
			cof
			gcBorrowRate
			interpolatedRate
			basketType
			basketObjective
		}
	}
`
