import {put, post} from '../../services/createAPICall'
import {createFetchActions} from '../../services/createActions'
import {getPMSBaseUrl} from '../../services/apiService'

export const GET_MENU_ITEM = createFetchActions('GET_MENU_ITEM')
export const DELETE_MENU_ITEM = 'DELETE_MENU_ITEM'
export const DELETE_VIEW = createFetchActions('DELETE_VIEW')
export const GET_VIEW = createFetchActions('GET_VIEW')
export const SAVE_VIEW = createFetchActions('SAVE_VIEW')
export const GET_OPTIMIZATION_LIST = createFetchActions('GET_OPTIMIZATION_LIST')

export const getMenuItem = (dataName?: string) => (dispatch: any) => {
	const data = {
		params: {
			objective: [],
			strategy: 'Collateral Optimization',
		},
		requestType: dataName ? 'dataObject' : 'dataList',
		dataName,
	}

	if (!dataName) delete data.dataName

	return dispatch(put(GET_MENU_ITEM, `${getPMSBaseUrl()}/v1/decisionMaking`, data))
}

export const getDataObjectWithOptimizationId = (dataName: string, optimizationId: string) => (dispatch: any) => {
	const data = {
		params: {
			objective: [],
			strategy: 'Collateral Optimization',
		},
		requestType: 'dataObject',
		dataName,
		optimizationId,
	}

	return dispatch(put(GET_MENU_ITEM, `${getPMSBaseUrl()}/v1/decisionMaking`, data))
}

export const getDataListWithOptimizationId = (optimizationId: string) => (dispatch: any) => {
	const data = {
		params: {
			objective: [],
			strategy: 'Collateral Optimization',
		},
		requestType: 'dataList',
		optimizationId,
	}

	return dispatch(put(GET_MENU_ITEM, `${getPMSBaseUrl()}/v1/decisionMaking`, data))
}

export const deleteMenuItem = itemId => {
	return {type: DELETE_MENU_ITEM, payload: itemId}
}

export const deleteView = user => (dispatch: any) => {
	return dispatch(
		post(DELETE_VIEW, `${getPMSBaseUrl()}/deletefmcfg`, user, null, 'Error occured while deleting dashboard tables')
	)
}

export const getView = user => (dispatch: any) => {
	return dispatch(
		post(GET_VIEW, `${getPMSBaseUrl()}/getfmcfg`, user, null, 'Error occured while getting market data layouts')
	)
}

export const saveView = data => (dispatch: any) => {
	return dispatch(
		post(SAVE_VIEW, `${getPMSBaseUrl()}/savefmcfg`, data, null, 'Error occured while saving dashboard layouts')
	)
}
export const getOptimizationList = () => (dispatch: any) => {
	const data = {
		params: {
			objective: [],
			strategy: 'Collateral Optimization',
		},
		requestType: 'optimizationList',
		getParams: {
			date: '',
			name: '',
			user: '',
			category: '',
			numObs: 20,
		},
	}

	return dispatch(put(GET_OPTIMIZATION_LIST, `${getPMSBaseUrl()}/v1/decisionMaking`, data))
}
