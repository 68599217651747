import {
	legalEntitiesQuery,
	repoSecurityDetailsQuery,
	sblSecurityDetailsQuery,
	// securityDetailsQuery,
	tradingTicketMyBooksQuery,
} from './TradingTicketQueries'
import {getConfigClient, getPMSClient} from '../../..'
import {getPMSBaseUrl} from '../../../services/apiService'
import {createFetchActions} from '../../../services/createActions'
import {post} from '../../../services/createAPICall'
import {getCounterpartyId} from '../../../services/authService'
export {getAssets} from '../../accountSettingsRevamp/myClients/myClientsActions'
export {getProducts} from '../../collateralBasketList/collateralBasket/collateralBasketActions'
export const SUBMIT_TRADING_TICKET = createFetchActions('SUBMIT_TRADING_TICKET')
export const FETCH_SECURITY_DETAILS = createFetchActions('FETCH_SECURITY_DETAILS')
export const FETCH_MY_BOOKS_DETAILS = createFetchActions('FETCH_MY_BOOKS_DETAILS')
export const FETCH_LEGAL_ENTITY = createFetchActions('FETCH_LEGAL_ENTITY')

export const RESET_TRADE_TICKET_DATA = 'RESET_TRADE_TICKET_DATA'

export const resetTradeTicketDetails = () => {
	return {type: RESET_TRADE_TICKET_DATA}
}

export const getSecurityDetails = (securityId: string, tradingType: string) => {
	return function (dispatch) {
		dispatch({type: FETCH_SECURITY_DETAILS.triggered})
		getPMSClient()
			.query({
				query: tradingType === 'REPO' ? repoSecurityDetailsQuery(securityId) : sblSecurityDetailsQuery(securityId),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: FETCH_SECURITY_DETAILS.succeeded,
					response,
				})
			})
	}
}

export const getMyBooksDetails = (tradeType: any) => {
	return function (dispatch) {
		dispatch({type: FETCH_MY_BOOKS_DETAILS.triggered})
		getPMSClient()
			.query({
				query: tradingTicketMyBooksQuery(tradeType),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: FETCH_MY_BOOKS_DETAILS.succeeded,
					response,
				})
			})
	}
}
export const getLegalEnityDetails = (securityId: string) => {
	return function (dispatch) {
		dispatch({type: FETCH_LEGAL_ENTITY.triggered})
		getConfigClient()
			.query({
				query: legalEntitiesQuery(getCounterpartyId()),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: FETCH_LEGAL_ENTITY.succeeded,
					response,
				})
			})
	}
}
export const submitTradingTicket = (data: any) => (dispatch: any) => {
	return dispatch(post(SUBMIT_TRADING_TICKET, `${getPMSBaseUrl()}/v1/portfolios/trade-ticket`, data))
}
