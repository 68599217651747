import {getPMSClient} from '../..'
import {createFetchActions} from '../../services/createActions'
import {
	getBasketDetailsQuery,
	requestLoanDetailsQuery,
	getDropdownDataQuery,
	getBasketFileDataQuery,
	getBasketOverviewQuery,
	activityLogQuery,
	fetchSearchSecurityResultQuery,
	getPbQuery,
	getSwapQuery,
} from './needsAvailabilitiesQueries'

import {get, post, put, del, graphQlWithAxios} from '../../services/createAPICall'
import {getPMSBaseUrl, getPMSGraphBaseUrl} from '../../services/apiService'
export const GET_BASKET_DETAILS = createFetchActions('GET_BASKET_DETAILS')
export const UPLOAD_BASKET_DETAILS = createFetchActions('UPLOAD_BASKET_DETAILS')
export const UPLOAD_PB_BASKET = createFetchActions('UPLOAD_PB_BASKET')
export const UPLOAD_SWAP_BASKET = createFetchActions('UPLOAD_SWAP_BASKET')
export const REQUEST_LOAN_DETAILS = createFetchActions('REQUEST_LOAN_DETAILS')
export const SUBMIT_LOAN = createFetchActions('SUBMIT_LOAN')
export const FILTER_BASKET = createFetchActions('FILTER_BASKET')
export const SUBMIT_BASKET = createFetchActions('SUBMIT_BASKET')
export const SHARE_BASKET = createFetchActions('SHARE_BASKET')
export const GET_DROPDOWN_DATA = createFetchActions('GET_DROPDOWN_DATA')
export const GET_BASKET_FILE_DATA = createFetchActions('GET_BASKET_FILE_DATA')
export const SUBMIT_MATCHING_ALGORITHM = createFetchActions('SUBMIT_MATCHING_ALGORITHM')
export const GET_BASKET_OVERVIEW = createFetchActions('GET_BASKET_OVERVIEW')
export const SUBMIT_LOAN_ENTRIES = createFetchActions('SUBMIT_LOAN_ENTRIES')
export const DELETE_BASKET_FILE = createFetchActions('DELETE_BASKET_FILE')
export const DOWNLOAD_BASKET = createFetchActions('DOWNLOAD_BASKET')
export const SET_COLLATERAL_TYPE = createFetchActions('SET_COLLATERAL_TYPE')
export const FETCH_ACTIVITY_LOG = createFetchActions('FETCH_ACTIVITY_LOG')
export const GET_PENDING_TARGET = createFetchActions('GET_PENDING_TARGET')
export const UPDATE_PENDING_TARGET = createFetchActions('UPDATE_PENDING_TARGET')
export const FETCH_LOCATE_SEARCH_RESULT = createFetchActions('FETCH_LOCATE_SEARCH_RESULT')
export const FETCH_LOCATE_SEARCH_OPENED = 'FETCH_LOCATE_SEARCH_OPENED'
export const SET_DATA_FROM_INDEX_ANALYSIS = 'SET_DATA_FROM_INDEX_ANALYSIS'
export const SAVE_LOCATE_SEARCH_RESULT = createFetchActions('SAVE_LOCATE_SEARCH_RESULT')
export const RESET_NEEDS_AVAILIABILTY_DATA = 'RESET_NEEDS_AVAILIABILTY_DATA'

export const setDataFromIndexAnalysis = data => {
	return function (dispatch) {
		dispatch({
			type: SET_DATA_FROM_INDEX_ANALYSIS,
			payload: data,
		})
	}
}

export const openLocateSearch = () => {
	return function (dispatch) {
		dispatch({
			type: FETCH_LOCATE_SEARCH_OPENED,
		})
	}
}

const executePortfolioQuery = async query => {
	if (!query) return null
	return graphQlWithAxios('', '', `${getPMSGraphBaseUrl()}`, query, data => {}, true)
}

export const getBasketDetails = () => {
	return function (dispatch) {
		dispatch({
			type: GET_BASKET_DETAILS.triggered,
		})

		Promise.all([
			executePortfolioQuery(getBasketDetailsQuery()),
			executePortfolioQuery(getPbQuery()),
			executePortfolioQuery(getSwapQuery()),
		]).then(combinedResponse => {
			const response = {
				availability: combinedResponse[0].data.data,
				pbSbl: combinedResponse[1].data.data,
				swapAxe: combinedResponse[2].data.data,
			}
			dispatch({
				type: GET_BASKET_DETAILS.succeeded,
				response,
			})
		})
	}
}

export const getBasketFileData = () => {
	return function (dispatch) {
		dispatch({
			type: GET_BASKET_FILE_DATA.triggered,
		})
		getPMSClient()
			.query({
				query: getBasketFileDataQuery(),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_BASKET_FILE_DATA.succeeded,
					response,
				})
			})
	}
}

export const uploadBasketDetails = req => (dispatch: any) => {
	return dispatch(post(UPLOAD_BASKET_DETAILS, `${getPMSBaseUrl()}/v1/availabilities-needs/upload`, req))
}

export const uploadPbBasket = req => (dispatch: any) => {
	return dispatch(post(UPLOAD_PB_BASKET, `${getPMSBaseUrl()}/v1/pb/upload`, req))
}

export const uploadSwapBasket = req => (dispatch: any) => {
	return dispatch(post(UPLOAD_SWAP_BASKET, `${getPMSBaseUrl()}/v1/swap/upload`, req))
}

export const requestLoanData = (id: string) => {
	return function (dispatch) {
		dispatch({
			type: REQUEST_LOAN_DETAILS.triggered,
		})
		getPMSClient()
			.query({
				query: requestLoanDetailsQuery(id),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: REQUEST_LOAN_DETAILS.succeeded,
					response,
				})
			})
	}
}

export const getDropdownData = (type: string) => {
	return function (dispatch) {
		dispatch({
			type: GET_DROPDOWN_DATA.triggered,
		})
		getPMSClient()
			.query({
				query: getDropdownDataQuery(type),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_DROPDOWN_DATA.succeeded,
					response,
				})
			})
	}
}

export const submitLoan = (data: any, id: string) => (dispatch: any) => {
	const payload = {
		borrowItems: data.map((item: any) => {
			const obj = {
				id: item.id,
				allocatedQuantity: item.allocatedQuantity,
				collateralType: item.collateralType,
			}
			return obj
		}),
	}
	return dispatch(post(SUBMIT_LOAN, `${getPMSBaseUrl()}/v1/availabilities-needs/${id}/orders`, payload))
}

export const submitLoanEntries = (data: any, id: string) => (dispatch: any) => {
	const payload = data.map((item: any) => {
		const obj = {
			orderEntryId: item.orderId,
			quantity: item.allocated,
			action: item.actionTaken,
		}
		return obj
	})
	return dispatch(post(SUBMIT_LOAN_ENTRIES, `${getPMSBaseUrl()}/v1/availabilities-needs/orders`, payload))
}

export const filterBasketData = (payload: any) => (dispatch: any) => {
	return dispatch(post(FILTER_BASKET, `${getPMSBaseUrl()}/v1/availabilities-needs/filter`, payload))
}

export const submitBasketData = (payload: any) => (dispatch: any) => {
	return dispatch(post(SUBMIT_BASKET, `${getPMSBaseUrl()}/v1/availabilities-needs/basket`, payload))
}

export const submitMatchingAlgorithm = (payload: any) => (dispatch: any) => {
	return dispatch(
		put(SUBMIT_MATCHING_ALGORITHM, `${getPMSBaseUrl()}/v1/availabilities-needs/match?algorithm=${payload}`)
	)
}

export const getBasketOverview = () => {
	return function (dispatch) {
		dispatch({
			type: GET_BASKET_OVERVIEW.triggered,
		})
		getPMSClient()
			.query({
				query: getBasketOverviewQuery(),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_BASKET_OVERVIEW.succeeded,
					response,
				})
			})
	}
}

export const deleteBasketFile = (id: string, type: string) => (dispatch: any) => {
	return dispatch(del(DELETE_BASKET_FILE, `${getPMSBaseUrl()}/v1/${type}/delete/${id}`))
}

export const downloadBasketFile = (id: any) => (dispatch: any) => {
	return dispatch(get(DOWNLOAD_BASKET, `${getPMSBaseUrl()}/v1/availabilities-needs/basket/download/${id}`))
}

export const setCollateralType = (cash: number, id: string) => (dispatch: any) => {
	const req = {
		cash,
	}
	return dispatch(put(SET_COLLATERAL_TYPE, `${getPMSBaseUrl()}/v1/availabilities-needs/${id}`, req))
}

export const fetchActivityLog = (counterParty: string, way: string, view: string) => {
	return function (dispatch) {
		dispatch({type: FETCH_ACTIVITY_LOG.triggered})
		getPMSClient()
			.query({
				query: activityLogQuery(counterParty, way, view),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: FETCH_ACTIVITY_LOG.succeeded,
					response,
				})
			})
	}
}

export const getLendingTarget = () => (dispatch: any) => {
	return dispatch(get(GET_PENDING_TARGET, `${getPMSBaseUrl()}/config/lending-target`))
}

export const updateLendingTarget = (data: any) => (dispatch: any) => {
	return dispatch(put(UPDATE_PENDING_TARGET, `${getPMSBaseUrl()}/config/lending-target`, data))
}

export const getLocateSearchResult = data => {
	return function (dispatch) {
		dispatch({
			type: FETCH_LOCATE_SEARCH_RESULT.triggered,
		})
		getPMSClient()
			.query({
				query: fetchSearchSecurityResultQuery(data),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: FETCH_LOCATE_SEARCH_RESULT.succeeded,
					response,
				})
			})
	}
}

export const saveLocateData = (data: any) => (dispatch: any) => {
	return dispatch(post(SAVE_LOCATE_SEARCH_RESULT, `${getPMSBaseUrl()}/v1/availabilities-needs/locatesearch`, data))
}

export const shareBasket = (id, clients) => (dispatch: any) => {
	const payload = {
		availNeedBasketId: id,
		clients,
	}
	return dispatch(put(SHARE_BASKET, `${getPMSBaseUrl()}/v1/availabilities-needs/basket/share`, payload))
}

export const resetNeedsAvailiabilty = () => {
	return {type: RESET_NEEDS_AVAILIABILTY_DATA}
}
