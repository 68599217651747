import {GET_INEFFICIENCIES_CONFIG, POST_INEFFICIENCIES_CONFIG} from './inefficienciesPopoverActions'

export interface InefficienciesPopover {
	loading: boolean
	configData: any
}

const initialState: InefficienciesPopover = {
	loading: false,
	configData: {},
}

export function inefficienciesPopoverReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_INEFFICIENCIES_CONFIG.triggered:
			return {...state, loading: true}
		case GET_INEFFICIENCIES_CONFIG.failed:
			return {...state, loading: false}
		case GET_INEFFICIENCIES_CONFIG.succeeded:
			state.configData = action.response.data
			return {
				...state,
				loading: false,
			}
		case POST_INEFFICIENCIES_CONFIG.triggered:
			return {...state, loading: true}
		case POST_INEFFICIENCIES_CONFIG.failed:
			return {...state, loading: false}
		case POST_INEFFICIENCIES_CONFIG.succeeded:
			return {...state, loading: false}
	}
	return state
}
