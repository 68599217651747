import {UPLOAD_MARGIN_FILE, GET_ALL_UPLOADED_FILES} from './marginAnalyticsActions'

export interface MarginAnalytics {
	loading: boolean
	allFilesData: any
}

const initialState: MarginAnalytics = {
	loading: false,
	allFilesData: {},
}

export function MarginAnalyticsReducer(state = initialState, action: any) {
	switch (action.type) {
		case UPLOAD_MARGIN_FILE.triggered:
			return {...state, loading: true}
		case UPLOAD_MARGIN_FILE.failed:
			return {...state, loading: false}
		case UPLOAD_MARGIN_FILE.succeeded:
			return {
				...state,
				loading: false,
			}

		case GET_ALL_UPLOADED_FILES.triggered:
			return {...state, loading: true}
		case GET_ALL_UPLOADED_FILES.failed:
			return {...state, loading: false}
		case GET_ALL_UPLOADED_FILES.succeeded:
			return {
				...state,
				allFilesData: action.response.data,
				loading: false,
			}
	}
	return state
}
