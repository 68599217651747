import {orderDetailQuery, allOrderQuery, activityLogQuery, indemnificationActivityLogQuery} from './PeerToPeerQuery'
import {getMPSGraphBaseUrl, getMPSBaseUrl, getPMSBaseUrl} from '../../services/apiService'
import {createFetchActions} from '../../services/createActions'
import {get, post, graphQlWithAxios} from '../../services/createAPICall'

export const INDEMNIFICATION_ACTION = createFetchActions('INDEMNIFICATION_ACTION')
export const EDIT_STAGING_ORDER = createFetchActions('EDIT_STAGING_ORDER')
export const GET_ACTIVITY_LOG = createFetchActions('GET_ACTIVITY_LOG')
export const GET_ALL_ORDER = createFetchActions('GET_ALL_ORDER')
export const GET_ORDER_DETAIL = createFetchActions('GET_ORDER_DETAIL')
export const GET_STAGING_ORDER = createFetchActions('GET_STAGING_ORDER')
export const GET_SETTLEMENT_DETAIL = createFetchActions('GET_SETTLEMENT_DETAIL')
export const ORDER_ACTION = createFetchActions('ORDER_ACTION')
export const ORDER_DELETE = createFetchActions('ORDER_DELETE')
export const PUBLISH_ORDER = createFetchActions('PUBLISH_ORDER')
export const GET_P2P_WIDGET_LAYOUT = createFetchActions('GET_P2P_WIDGET_LAYOUT')
export const SAVE_P2P_WIDGET_LAYOUT = createFetchActions('SAVE_P2P_WIDGET_LAYOUT')
export const CREATE_BASKET_FROM_SECURITY = createFetchActions('CREATE_BASKET_FROM_SECURITY')
export const CLEAR_CREATED_BASKET = createFetchActions('CLEAR_CREATED_BASKET')

export const RESET_ALL_ORDERS = 'RESET_ALL_ORDERS'

export const acceptIndemnification = (indemnificationId: string) => (dispatch: any) => {
	const payload = {action: 'ACCEPT'}
	return dispatch(post(ORDER_ACTION, `${getMPSBaseUrl()}/v1/orders/indemnify/${indemnificationId}`, payload))
}

export const acceptOrder = (orderOrResponseId: string, isResponse: boolean) => (dispatch: any) => {
	const payload = {action: 'ACCEPT'}
	return dispatch(
		post(ORDER_ACTION, `${getMPSBaseUrl()}/v1/orders/${isResponse ? 'responses/' : ''}${orderOrResponseId}/`, payload)
	)
}

export const cancelIndemnification = (indemnificationId: string, cancellationReason: string) => (dispatch: any) => {
	const payload = {action: 'CANCEL', cancellationReason}
	return dispatch(post(ORDER_ACTION, `${getMPSBaseUrl()}/v1/orders/indemnify/${indemnificationId}`, payload))
}

export const editStagingOrder = (stagingOrderId: string) => (dispatch: any) => {
	if (!stagingOrderId) return dispatch({type: EDIT_STAGING_ORDER.triggered})
	return dispatch(get(EDIT_STAGING_ORDER, `${getMPSBaseUrl()}/v1/stagingorders/${stagingOrderId}`))
}

export const getOrderActivityLog = orderResponseId => {
	const query = activityLogQuery(orderResponseId)
	const baseURL = `${getMPSGraphBaseUrl()}`
	return function (dispatch) {
		graphQlWithAxios(dispatch, GET_ACTIVITY_LOG, baseURL, query, (response: any) => {
			dispatch({
				type: GET_ACTIVITY_LOG.succeeded,
				responseId: orderResponseId,
				response: response && response.data && response.data.data && response.data.data.orderCollaborationLogs,
			})
		})
	}
}

export const getIndemnificationActivityLog = indemnificationId => {
	const query = indemnificationActivityLogQuery(indemnificationId)
	const baseURL = `${getMPSGraphBaseUrl()}`
	return function (dispatch) {
		graphQlWithAxios(dispatch, GET_ACTIVITY_LOG, baseURL, query, (response: any) => {
			dispatch({
				type: GET_ACTIVITY_LOG.succeeded,
				responseId: indemnificationId,
				response: response && response.data && response.data.data && response.data.data.indemnificationActivityLogs,
			})
		})
	}
}

export const getAllOrder = () => {
	const query = allOrderQuery()
	const baseURL = `${getMPSGraphBaseUrl()}`
	return function (dispatch) {
		graphQlWithAxios(dispatch, GET_ALL_ORDER, baseURL, query, (response: any) => {
			dispatch({
				type: GET_ALL_ORDER.succeeded,
				response: response && response.data && response.data.data,
			})
		})
	}
}

export const resetAllOrders = () => {
	return {
		type: RESET_ALL_ORDERS,
	}
}

export const getSettlementDetail = (orderId: string, filterToday: boolean) => (dispatch: any) => {
	return dispatch(
		get(GET_SETTLEMENT_DETAIL, `${getMPSBaseUrl()}/v1/order-settlements/${orderId}?filterToday=${filterToday}`)
	)
}

export const getOrderDetail = (orderId: string) => {
	const query = orderDetailQuery(orderId)
	const baseURL = `${getMPSGraphBaseUrl()}`
	return function (dispatch) {
		graphQlWithAxios(dispatch, GET_ORDER_DETAIL, baseURL, query, (response: any) => {
			if (response && response.data && response.data.data)
				response.data.data.order.spread = response.data.data.order.spread * 100
			dispatch({
				type: GET_ORDER_DETAIL.succeeded,
				orderId,
				response: response && response.data && response.data.data,
			})
		})
	}
}

export const getStagingOrder = (orderType: string) => (dispatch: any) => {
	return dispatch(get(GET_STAGING_ORDER, `${getMPSBaseUrl()}/v1/orderprogram?orderType=${orderType}`))
}

export const getWidgetLayout = user => (dispatch: any) => {
	return dispatch(post(GET_P2P_WIDGET_LAYOUT, `${getPMSBaseUrl()}/getfmcfg`, user, null))
}

export const saveWidgetLayout = widget => (dispatch: any) => {
	return dispatch(post(SAVE_P2P_WIDGET_LAYOUT, `${getPMSBaseUrl()}/savefmcfg`, widget, null))
}

export const negotiateOrder = (orderOrResponseId: string, isResponse: boolean, data: any) => (dispatch: any) => {
	const payload = {action: 'NEGOTIATE', ...data}
	return dispatch(
		post(ORDER_ACTION, `${getMPSBaseUrl()}/v1/orders/${isResponse ? 'responses/' : ''}${orderOrResponseId}/`, payload)
	)
}

export const negotiateIndemnification = (indemnificationId: string, data: any) => (dispatch: any) => {
	const payload = {action: 'NEGOTIATE', ...data}
	return dispatch(post(ORDER_ACTION, `${getMPSBaseUrl()}/v1/orders/indemnify/${indemnificationId}`, payload))
}

export const publishOrder = (orderId: string) => (dispatch: any) => {
	return dispatch(post(PUBLISH_ORDER, `${getMPSBaseUrl()}/v1/stagingorders/${orderId}/publish`))
}

export const rejectOrder =
	(orderOrResponseId: string, isResponse: boolean, cancellationReason: string) => (dispatch: any) => {
		const payload = {action: 'REJECT', cancellationReason}
		return dispatch(
			post(ORDER_ACTION, `${getMPSBaseUrl()}/v1/orders/${isResponse ? 'responses/' : ''}${orderOrResponseId}/`, payload)
		)
	}

export const deleteOrder = (orderId: string, cancellationReason: string) => (dispatch: any) => {
	const payload = {action: 'REJECT', cancellationReason}
	return dispatch(post(ORDER_DELETE, `${getMPSBaseUrl()}/v1/orders/${orderId}/`, payload))
}

export const rejectIndemnification = (indemnificationId: string, cancellationReason: string) => (dispatch: any) => {
	const payload = {action: 'REJECT', cancellationReason}
	return dispatch(post(ORDER_ACTION, `${getMPSBaseUrl()}/v1/orders/indemnify/${indemnificationId}`, payload))
}

export const createBasketFromSecurity = security => (dispatch: any) =>
	dispatch(post(CREATE_BASKET_FROM_SECURITY, `${getPMSBaseUrl()}/v1/collateral-baskets/from-single-security`, security))

export const clearCreatedBasket = () => ({
	type: CLEAR_CREATED_BASKET,
})
