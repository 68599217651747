import {ArrayUtil} from 'helper-util'
import {formatAllDocs} from './portfolioManagerHelper'
import {
	UPLOAD_PORTFOLIO_DOCS,
	GET_PORTFOLIO_DOCS_INCLUDED,
	GET_PORTFOLIO_DOCS_ALL,
	SAVE_INCLUDED_DOCS,
	CLEAR_PORTFOLIO_MANAGER_STATE,
	GET_PORTFOLIO_UPDATE_DATETIME,
	SET_PROCESSED_PORTFOLIO_OBJECT,
	RESET_PROCESSED_PORTFOLIO_OBJECT,
	SET_PORTFOLIO_DOCUMENT_STATUS,
	UPLOAD_RESTRICTED_PORTFOLIO,
	GET_RESTRICTED_PORTFOLIO,
	PF_UPLOAD_ONGOING,
	GET_ETL_STATUS,
} from './portfolioMangerActions'

export interface PortfolioManager {
	apiIncludedDocs: DocItem[]
	includedDocs: DocItem[]
	allDocs: DocItem[]
	includedIds: string[]
	portfolioUpdatedDateTime: any[]
	loading: boolean
	processedPortfolioObject: any
	currentDoc: {id: string; status: string}
	restrictedStockDetails: any
	pfUploadOngoing: boolean
	isEtlMappingEnable: boolean
}

const initialState: PortfolioManager = {
	apiIncludedDocs: [],
	includedDocs: [],
	allDocs: [],
	includedIds: [],
	portfolioUpdatedDateTime: [],
	loading: false,
	processedPortfolioObject: {},
	currentDoc: null,
	restrictedStockDetails: {},
	pfUploadOngoing: false,
	isEtlMappingEnable: null,
}

export interface DocItem {
	id: string
	tenantId: string
	fileName: string
	completedEnrichmentCount?: number
	errorCount: number
	status: 'SUCCESS' | 'FAILURE' | string
	createdAt: string[]
}

export function portfolioManagerReducer(state = initialState, action: any) {
	let includedIds = []
	switch (action.type) {
		case GET_PORTFOLIO_DOCS_INCLUDED.triggered:
			return {...state, loading: true}
		case GET_PORTFOLIO_DOCS_INCLUDED.succeeded:
			includedIds = (action.response.data || []).map(item => String(item.id))
			const data = action.response.data
			return {
				...state,
				loading: false,
				apiIncludedDocs: data || [],
				includedDocs: data || [],
				includedIds,
			}
		case GET_PORTFOLIO_DOCS_INCLUDED.failed:
			return {...state, includedDocs: [], loading: false}

		case GET_PORTFOLIO_DOCS_ALL.triggered:
			return {...state, loading: true}
		case GET_PORTFOLIO_DOCS_ALL.succeeded:
			return {...state, allDocs: action.response.data || [], loading: false}
		case GET_PORTFOLIO_DOCS_ALL.failed:
			return {...state, loading: false}

		case GET_ETL_STATUS.triggered:
			return {...state, isEtlMappingEnable: null}
		case GET_ETL_STATUS.succeeded:
			return {...state, isEtlMappingEnable: !ArrayUtil.isEmpty(action.response.data)}
		case GET_ETL_STATUS.failed:
			return {...state, isEtlMappingEnable: null}

		case GET_PORTFOLIO_UPDATE_DATETIME.triggered:
			return {...state, loading: true}
		case GET_PORTFOLIO_UPDATE_DATETIME.succeeded:
			return {
				...state,
				portfolioUpdatedDateTime: action.response.data || [],
				loading: false,
			}
		case GET_PORTFOLIO_UPDATE_DATETIME.failed:
			return {...state, loading: false}

		case UPLOAD_PORTFOLIO_DOCS.triggered:
			return {...state, loading: true}
		case UPLOAD_PORTFOLIO_DOCS.succeeded:
		case UPLOAD_PORTFOLIO_DOCS.failed:
			return {...state, loading: false}

		case UPLOAD_RESTRICTED_PORTFOLIO.triggered:
			return {...state, loading: true}
		case UPLOAD_RESTRICTED_PORTFOLIO.succeeded:
			return {...state, loading: false}
		case UPLOAD_RESTRICTED_PORTFOLIO.failed:
			return {...state, loading: false}

		case GET_RESTRICTED_PORTFOLIO.triggered:
			return {...state, loading: true}
		case GET_RESTRICTED_PORTFOLIO.succeeded:
		case GET_RESTRICTED_PORTFOLIO.failed:
			return {...state, loading: false, restrictedStockDetails: action.response.data || {}}

		case SAVE_INCLUDED_DOCS.triggered:
			return {...state, loading: true}
		case SAVE_INCLUDED_DOCS.succeeded:
		case SAVE_INCLUDED_DOCS.failed:
			return {...state, loading: false}

		case CLEAR_PORTFOLIO_MANAGER_STATE:
			return {...initialState}

		case SET_PROCESSED_PORTFOLIO_OBJECT:
			return {
				...state,
				processedPortfolioObject: action.payload.data,
				allDocs: formatAllDocs(state.allDocs, action.payload.data, state.currentDoc),
			}
		case RESET_PROCESSED_PORTFOLIO_OBJECT:
			return {
				...state,
				processedPortfolioObject: initialState.processedPortfolioObject,
			}

		case SET_PORTFOLIO_DOCUMENT_STATUS:
			const docId = action.payload.id
			const docStatus = action.payload.status
			return {
				...state,
				loading: false,
				currentDoc: {
					id: docId,
					status: docStatus,
				},
			}

		case PF_UPLOAD_ONGOING:
			return {
				...state,
				pfUploadOngoing: action.payload.uploading,
			}
	}
	return state
}
