import gql from 'graphql-tag'

export const fetchRulesetListQuery = () => {
	return gql`
		query {
			rulesetList {
				rulesetName
				rulesetId
				createdDate
				rulesetEntry
			}
		}
	`
}

export const fetchRulesetEntriesQuery = (selectedRuleId, selectedTemplateName) => {
	return gql`
		query {
			rulesetEntries(
        rulesetId: ${selectedRuleId}, 
        rulesetName: "${selectedTemplateName}"
      ) {
				rulesetId
				criteriaXml
			}
		}
	`
}

export const fetchTemplateBodyQuery = templateId => {
	const x = '"' + templateId + '"'
	return gql`
		query {
			templateEntry(
        templateId: ${x}
      )
      {
        templateId
        templateBody
      }
		}
	`
}

export const fetchCollateralScheduleDropDowns = () => {
	return gql`
		query {
			collateralScheduleDropDowns {
				assetTypes
				assetSubTypes
				moodyRatings
				snpRatings
				fitchRatings
				sector
				cusip
				hqla
				index
				industry
				isin
				securityId
				ticker
				clientDetails
			}
		}
	`
}

export const fetchtemplateList = () => {
	return gql`
		query {
			templateList {
				templateId
				templateType
				clientName
			}
		}
	`
}
