import React from 'react'
import {ArrayUtil} from 'helper-util'
import {columnDefinition} from '../../constants/columnDefinition'
import {tableHeaderWithTitle, tableHeaderClassName} from '../../utils/reactTableUtil'
// import {formatWithSeparator} from '../../utils/formatUtils'
// import {InlineEditIcon} from '../../styles/svg/svgIcons'

export const toggleIdList = ['open', 'incoming', 'outgoing']

export const Activity = {
	RECALL: 'RECALL',
	RETURN: 'RETURN',
}

export const csvName = `trading_queue_entries`

export const BuySellInd = {
	LOAN_RECALL: 'LOAN',
	BORROW_RETURN: 'BORROW',
	REPO_MATURE: 'REPO',
	REVERSE_MATURE: 'REVERSE',
}

export const actionTypeMapping = {
	'ACTION REQUIRED': 1,
	'IN PROGRESS': 2,
	COMPLETE: 3,
}

export const initialOutgoingColumns = (handleQuantityClick, handleAction, items) => {
	return [
		columnDefinition.incomingEntryStatus,
		{
			Header: tableHeaderWithTitle('Action'),
			accessor: 'actions',
			Cell: props => {
				const isAcknowledge = !ArrayUtil.isEmpty(props.value) && props.value.includes('ACKNOWLEDGE')
				const isAccept = !ArrayUtil.isEmpty(props.value) && props.value.includes('ACCEPT')
				const isReject = !ArrayUtil.isEmpty(props.value) && props.value.includes('REJECT')
				const isNegotiate = !ArrayUtil.isEmpty(props.value) && props.value.includes('NEGOTIATE')
				const hasActions = !ArrayUtil.isEmpty(props.value)
				const actionLabel = props.original.orderId ? items[props.original.orderId] : ''
				return hasActions ? (
					<span className='btn-xs btn-group p-0'>
						{isAccept && (
							<button
								className={`btn btn-tiny ml-2 ${actionLabel === 'ACCEPT' ? 'btn-secondary' : 'btn-outline-secondary'}`}
								onClick={() => handleAction(props.original.orderId, 'ACCEPT')}
							>
								ACCEPT
							</button>
						)}
						{isReject && (
							<button
								className={`btn btn-tiny ml-1 ${actionLabel === 'REJECT' ? 'btn-danger' : 'btn-outline-danger'}`}
								onClick={() => handleAction(props.original.orderId, 'REJECT')}
							>
								REJECT
							</button>
						)}
						{isNegotiate && (
							<button
								className={`btn btn-tiny ml-1 ${actionLabel === 'NEGOTIATE' ? 'btn-dark' : 'btn-outline-dark'}`}
								onClick={() => handleAction(props.original.orderId, 'NEGOTIATE')}
							>
								NEGOTIATE
							</button>
						)}
						{isAcknowledge && (
							<button
								className={`btn btn-tiny ml-1 ${actionLabel === 'ACKNOWLEDGE' ? 'btn-primary' : 'btn-outline-primary'}`}
								onClick={() => handleAction(props.original.orderId, 'ACKNOWLEDGE')}
							>
								ACKNOWLEDGE
							</button>
						)}
					</span>
				) : (
					''
				)
			},
			minWidth: 225,
		},
		columnDefinition.activityType,
		columnDefinition.partial,
		columnDefinition.recallReturnTimeStamp,
		columnDefinition.recallReturnBy,
		columnDefinition.portfolioEntryType,
		columnDefinition.securityId,
		columnDefinition.isRestricted,
		columnDefinition.originalQuantity,
		columnDefinition.priceAmountV2,
		columnDefinition.basePriceAmountV2,
		columnDefinition.notionalAmountV2,
		{
			Header: tableHeaderWithTitle('Recalled/Returned Quantity'),
			accessor: 'quantity',
			minWidth: 160,
			headerClassName: tableHeaderClassName(),
		},
		// 	Cell: props => {
		// 		const canNegotiate = !ArrayUtil.isEmpty(props.original.actions) && props.original.actions.includes('NEGOTIATE')
		// 		const isNegotiateClicked = items[props.original.orderId] === 'NEGOTIATE'
		// 		const quantity = props.value
		// 		return typeof quantity !== 'number' ? (
		// 			<div className='overflow-ellipsis w-100 text-right mr-2'>{quantity}</div>
		// 		) : (
		// 			<>
		// 				<div className='overflow-ellipsis w-100 text-right mr-2'>{formatWithSeparator(Math.round(quantity))}</div>
		// 				{isNegotiateClicked && (
		// 					<span
		// 						className='text-primary d-flex pr-1'
		// 						onClick={() => {
		// 							canNegotiate && isNegotiateClicked && handleQuantityClick(props.original.orderId)
		// 						}}
		// 					>
		// 						<InlineEditIcon />
		// 					</span>
		// 				)}
		// 			</>
		// 		)
		// 	},
		// },
		columnDefinition.recallReturnNotional,
		columnDefinition.collateralAmountV3,
		columnDefinition.notionalCurrencyV2,
		columnDefinition.collateralType,
		columnDefinition.originalCollateralValueAmountV2,
	]
}
