import {
	PRESERVE_DECISION_INPUT,
	FETCH_DECISION_OUTPUT,
	FETCH_INDEX_ANALYSIS_OUTPUT,
	DELETE_IOM_DATA,
	FETCH_TRACKING_ANALYSIS_OUTPUT,
	DELETE_TRACKING_BASKET_DATA,
} from './decisionMakingAction'
import {addTrackingBasketData} from './decisionMakingHelper'
import _ from 'lodash'

export interface DecisionMaking {
	decisionOutputLoading: boolean
	decisionFilterData: any
	decisionInputData: any
	decisionScoringData: any
	decisionFactors: any
	decisionIOMData: any
	indexAnalysisOutputLoading: any
	trackingBasketData: any
	trackingBasketLoading: boolean
}

const initialState: DecisionMaking = {
	decisionOutputLoading: false,
	decisionFilterData: null,
	decisionInputData: null,
	decisionScoringData: {},
	decisionFactors: {},
	decisionIOMData: null,
	indexAnalysisOutputLoading: false,
	trackingBasketData: [],
	trackingBasketLoading: false,
}

export function decisionMakingReducer(state = initialState, action: any) {
	switch (action.type) {
		case PRESERVE_DECISION_INPUT.triggered:
			return {
				...state,
				decisionFilterData: action.filterData,
				decisionInputData: action.inputData,
			}

		case FETCH_DECISION_OUTPUT.triggered:
			return {...state, decisionOutputLoading: true}

		case FETCH_DECISION_OUTPUT.succeeded || FETCH_DECISION_OUTPUT.failed:
			state.decisionIOMData = action.response.data && action.response.data['strdata'] ? action.response.data : null
			return {...state, decisionOutputLoading: false}

		case FETCH_INDEX_ANALYSIS_OUTPUT.triggered:
			return {...state, indexAnalysisOutputLoading: true, decisionOutputLoading: false}

		case FETCH_INDEX_ANALYSIS_OUTPUT.succeeded || FETCH_INDEX_ANALYSIS_OUTPUT.failed:
			return {...state, indexAnalysisOutputLoading: false, decisionOutputLoading: false}
		case DELETE_IOM_DATA:
			return {...state, decisionIOMData: null}
		case FETCH_TRACKING_ANALYSIS_OUTPUT.triggered:
			return {...state, trackingBasketLoading: true}
		case FETCH_TRACKING_ANALYSIS_OUTPUT.failed:
			return {...state, trackingBasketLoading: false}
		case FETCH_TRACKING_ANALYSIS_OUTPUT.succeeded:
			const data = action.response.data && action.response.data
			const resultData = addTrackingBasketData(data, state.trackingBasketData)
			return {...state, trackingBasketLoading: false, trackingBasketData: resultData}
		case DELETE_TRACKING_BASKET_DATA:
			const trackingData = _.cloneDeep(state.trackingBasketData)
			const filteredData = trackingData.filter(item => item.index !== action.data)
			return {...state, trackingBasketData: filteredData}
	}
	return state
}
