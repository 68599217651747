import {FETCH_TARGETED_INVENTORY, CLEAR_MAIN_GRID_DATA} from './AlpSttInventoryMgmtActions'

export interface AlpSttInventoryManager {
	loading: boolean
	gridLoading: boolean
	targetedInventory: any
}

const initialState: AlpSttInventoryManager = {
	loading: false,
	gridLoading: false,
	targetedInventory: null,
}

export function AlpSttInventoryMgmtReducer(state = initialState, action: any) {
	switch (action.type) {
		case FETCH_TARGETED_INVENTORY.triggered:
			return {...state, targetedInventory: null, gridLoading: true}
		case FETCH_TARGETED_INVENTORY.failed:
			return {...state, targetedInventory: null, gridLoading: false}
		case FETCH_TARGETED_INVENTORY.succeeded:
			return {
				...state,
				targetedInventory: action.response && action.response.data,
				gridLoading: false,
			}

		case CLEAR_MAIN_GRID_DATA:
			return {
				...state,
				targetedInventory: null,
			}
	}
	return state
}
