import {createFetchActions} from '../../services/createActions'
import {get, post} from '../../services/createAPICall'
import {getPMSBaseUrl} from '../../services/apiService'

export const GET_INEFFICIENCIES_CONFIG = createFetchActions('GET_INEFFICIENCIES_CONFIG')
export const POST_INEFFICIENCIES_CONFIG = createFetchActions('POST_INEFFICIENCIES_CONFIG')

export const getInefficienciesPopoverData = () => (dispatch: any) => {
	return dispatch(get(GET_INEFFICIENCIES_CONFIG, `${getPMSBaseUrl()}/v1/inefficiencies/`))
}

export const postInefficienciesPopoverData = (req: any) => (dispatch: any) => {
	return dispatch(post(POST_INEFFICIENCIES_CONFIG, `${getPMSBaseUrl()}/v1/inefficiencies/`, req))
}
