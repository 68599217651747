import memoizeOne from 'memoize-one'
import isDeepEqual from 'lodash.isequal'

export const getEpochTimeInSecond = () => {
	const currentTimeStamp = new Date()
	const currentEpochTimeInMs = currentTimeStamp.getTime()
	const currentEpochTimeInSec = Math.round(currentEpochTimeInMs / 1000)
	return currentEpochTimeInSec
}

export const getToastStat = memoizeOne(toastNotificationData => {
	const messageToDisplay = toastNotificationData.latestData[0]
	const currentEpochTimeInSec = getEpochTimeInSecond()

	const epochTimeDifferInSec =
		messageToDisplay && messageToDisplay.epochTimeInSec ? currentEpochTimeInSec - messageToDisplay.epochTimeInSec : 0

	const toastStat = {
		showToast: Boolean(messageToDisplay),
		delayTime: (messageToDisplay && messageToDisplay.delayTime) || 3000,
		title: messageToDisplay && messageToDisplay.title ? messageToDisplay.title : 'Message',
		message: messageToDisplay && messageToDisplay.message ? messageToDisplay.message : '',
		warning: messageToDisplay && messageToDisplay.warning ? messageToDisplay.warning : false,
		timeElasped:
			epochTimeDifferInSec && epochTimeDifferInSec > 0 ? `${epochTimeDifferInSec} second(s) ago` : 'a moment ago',
	}

	return toastStat
}, isDeepEqual)
