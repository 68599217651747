export const filterRightAlign = {
	type: 'leftAligned',
	cellClass: 'ag-right-aligned-cell',
	headerClass: 'ag-left-aligned-header',
}

export const getLayout = [
	{i: 'live-auctions', w: 12, h: 16, x: 0, y: 0, isDraggable: true, isResizable: true},
	{i: 'stage-auctions', w: 12, h: 16, x: 4, y: 0, isDraggable: true, isResizable: true},
	{i: 'closed-auctions', w: 12, h: 16, x: 4, y: 20, isDraggable: true, isResizable: true},
	{i: 'brodcast-errors-auctions', w: 12, h: 16, x: 4, y: 20, isDraggable: true, isResizable: true},
]
