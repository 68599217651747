import {createFetchActions} from '../../services/createActions'
import {getPMSBaseUrl} from '../../services/apiService'
import {put} from '../../services/createAPICall'
export const FETCH_INDEX_PRICE_DATA = createFetchActions('FETCH_INDEX_PRICE_DATA')

export const fetchIndexPriceData = (data: any) => (dispatch: any) => {
	return dispatch(
		put(
			FETCH_INDEX_PRICE_DATA,
			`${getPMSBaseUrl()}/v1/decisionMaking`,
			data,
			null,
			'Error occured while fetching index pricer data'
		)
	)
}
