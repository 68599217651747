import {GET_REPORTS, SAVE_REPORTS, DELETE_REPORTS, SET_TABLE_TYPE} from './reportActions'

export interface Reports {
	reportsLoading: boolean
	reports: any
	selectedTableName: any
}

const initialState: Reports = {
	reportsLoading: false,
	reports: [],
	selectedTableName: {type: 'Portfolio'},
}

export function reportsReducer(state = initialState, action: any) {
	switch (action.type) {
		case SET_TABLE_TYPE:
			state.selectedTableName.type = action.data
			return {...state}
		case GET_REPORTS.triggered:
			return {...state}
		case GET_REPORTS.failed:
			return {...state}
		case GET_REPORTS.succeeded:
			state.reports = action.response.data
			return {
				...state,
			}
		case SAVE_REPORTS.triggered:
			return {...state}
		case SAVE_REPORTS.failed:
			return {...state}
		case SAVE_REPORTS.succeeded:
			state.reports = action.response.data.items
			return {
				...state,
			}
		case DELETE_REPORTS.triggered:
			return {...state, loading: true}
		case DELETE_REPORTS.failed:
			return {...state, loading: false}
		case DELETE_REPORTS.succeeded:
			state.reports = action.response.data.items
			return {
				...state,
				loading: false,
			}
	}

	return state
}
