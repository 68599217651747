import {getLocateClient} from '../../..'
import {createFetchActions} from '../../../services/createActions'
import {post, put} from '../../../services/createAPICall'
import {getLocateBaseUrl, getPMSBaseUrl} from '../../../services/apiService'
import {fetchLocateDetailsQuery, fetchLocateConfigQuery} from './LocateAuthorizerQuery'
import {getLocateRequestEntryDetail, getSecurityListAuditDetailsQuery} from './LocateAuthorizerQuery'
import {fetchSecurityListQuery} from './LocateAuthorizerQuery'
import {calculateMarketFeeAndCentsPerShareQuery, securitySuggestionsQuery} from './LocateAuthorizerQuery'
export const FETCH_LOCATE_AUTHORIZER_DETAILS = createFetchActions('FETCH_LOCATE_AUTHORIZER_DETAILS')
export const FETCH_LOCATE_CONFIG_DETAILS = createFetchActions('FETCH_LOCATE_CONFIG_DETAILS')
export const FETCH_SECURITY_TYPE_LIST = createFetchActions('FETCH_SECURITY_TYPE_LIST')
export const FETCH_FEE_CENTS_PER_SHARE_CALCULATION = createFetchActions('FETCH_FEE_CENTS_PER_SHARE_CALCULATION')
export const FETCH_LOCATE_REQUEST_ENTRY_DETAIL = createFetchActions('FETCH_LOCATE_REQUEST_ENTRY_DETAIL')
export const SEARCH_LOCATE_REQUEST = createFetchActions('SEARCH_LOCATE_REQUEST')
export const AUTHORIZE_LOCATE_REQUEST = createFetchActions('AUTHORIZE_LOCATE_REQUEST')
export const UPDATE_LOCATE_CONFIG = createFetchActions('UPDATE_LOCATE_CONFIG')
export const CREATE_UPDATE_SECURITY_WATCHLIST = createFetchActions('CREATE_UPDATE_SECURITY_WATCHLIST')
export const ADD_AVAILABILITY_FROM_LOCATE = createFetchActions('ADD_AVAILABILITY_FROM_LOCATE')
export const REJECT_LOCATE_REQUEST = createFetchActions('REJECT_LOCATE_REQUEST')
export const FETCH_SECURITY_LIST_AUDIT_DETAIL = createFetchActions('FETCH_SECURITY_LIST_AUDIT_DETAIL')
export const CLEAR_MAIN_GRID_DATA = createFetchActions('CLEAR_MAIN_GRID_DATA')

export const fetchLocateAuthorizerDetails = () => {
	return function (dispatch) {
		dispatch({
			type: FETCH_LOCATE_AUTHORIZER_DETAILS.triggered,
		})
		getLocateClient()
			.query({
				query: fetchLocateDetailsQuery(),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: FETCH_LOCATE_AUTHORIZER_DETAILS.succeeded,
					response,
				})
			})
	}
}

export const fetchLocateConfigDetails = () => {
	return function (dispatch) {
		dispatch({
			type: FETCH_LOCATE_CONFIG_DETAILS.triggered,
		})
		getLocateClient()
			.query({
				query: fetchLocateConfigQuery(),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: FETCH_LOCATE_CONFIG_DETAILS.succeeded,
					response,
				})
			})
	}
}

export const fetchSecurityTypeList = securityType => {
	return function (dispatch) {
		dispatch({
			type: FETCH_SECURITY_TYPE_LIST.triggered,
		})
		getLocateClient()
			.query({
				query: fetchSecurityListQuery(securityType),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: FETCH_SECURITY_TYPE_LIST.succeeded,
					response,
				})
			})
	}
}

export const calculateMarketFeeAndCentsPerShare = (marketPrice, fee, approvedQuantity) => {
	return function (dispatch) {
		dispatch({
			type: FETCH_FEE_CENTS_PER_SHARE_CALCULATION.triggered,
		})
		getLocateClient()
			.query({
				query: calculateMarketFeeAndCentsPerShareQuery(marketPrice, fee, approvedQuantity),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: FETCH_FEE_CENTS_PER_SHARE_CALCULATION.succeeded,
					response,
				})
			})
	}
}

export const fetchSecuritySuggestions = (keyword, page, size) => {
	return getLocateClient().query({
		query: securitySuggestionsQuery(keyword, page, size),
		fetchPolicy: 'network-only',
	})
}

export const fetchLocateRequestEntryDetail = locateRequestEntryId => {
	return function (dispatch) {
		dispatch({
			type: FETCH_LOCATE_REQUEST_ENTRY_DETAIL.triggered,
		})
		getLocateClient()
			.query({
				query: getLocateRequestEntryDetail(locateRequestEntryId),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: FETCH_LOCATE_REQUEST_ENTRY_DETAIL.succeeded,
					response,
				})
			})
	}
}

export const fetchSecurityListAuditDetails = () => {
	return function (dispatch) {
		dispatch({
			type: FETCH_SECURITY_LIST_AUDIT_DETAIL.triggered,
		})
		getLocateClient()
			.query({
				query: getSecurityListAuditDetailsQuery(),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: FETCH_SECURITY_LIST_AUDIT_DETAIL.succeeded,
					response,
				})
			})
	}
}

export const addAvailabilityFromLocate = req => (dispatch: any) => {
	return dispatch(
		post(ADD_AVAILABILITY_FROM_LOCATE, `${getPMSBaseUrl()}/v1/availabilities-needs/add-availability`, req)
	)
}

export const searchLocateRequest = requestBody => (dispatch: any) => {
	return dispatch(post(SEARCH_LOCATE_REQUEST, `${getLocateBaseUrl()}/v1/locate/authorizer/create`, requestBody))
}

export const authorizeLocateRequest = (data: any) => (dispatch: any) => {
	return dispatch(post(AUTHORIZE_LOCATE_REQUEST, `${getLocateBaseUrl()}/v1/locate/authorize`, data))
}

export const updateLocateConfig = (updatedLocateConfig: any) => (dispatch: any) => {
	return dispatch(put(UPDATE_LOCATE_CONFIG, `${getLocateBaseUrl()}/v1/locate/config`, updatedLocateConfig))
}

export const createUpdateSecurityWishlist = (securityType: string, securityObj) => (dispatch: any) => {
	return dispatch(
		put(
			CREATE_UPDATE_SECURITY_WATCHLIST,
			`${getLocateBaseUrl()}/v1/locate/security-list?type=${securityType}`,
			securityObj
		)
	)
}

export const rejectLocateRequest = (rejectPayload: any) => (dispatch: any) => {
	return dispatch(post(REJECT_LOCATE_REQUEST, `${getLocateBaseUrl()}/v1/locate/reject`, rejectPayload))
}

export const clearGridData = () => {
	return {
		type: CLEAR_MAIN_GRID_DATA,
	}
}
