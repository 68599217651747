import {DELETE_TRADE, GET_TRADES, GET_DEPO_OPTION} from './TradeBlotterActions'
import {UPLOAD_TRADE_BLOTTER, RECENT_TRADE_BLOTTER_ERROR, FINALIZE_TRADE_BLOTTER_UPLOAD} from './TradeBlotterActions'

export interface TradeBlotter {
	tradeUploaded: boolean
	tradeDeleted: boolean
	allTrades: any[]
	depoOption: any[]
	uploadErrorData: any[]
	finalizeUpload: boolean
}

const initialState: TradeBlotter = {
	tradeUploaded: null,
	tradeDeleted: null,
	allTrades: null,
	depoOption: null,
	uploadErrorData: null,
	finalizeUpload: null,
}

export function tradeBlotterReducer(state = initialState, action: any) {
	switch (action.type) {
		case UPLOAD_TRADE_BLOTTER.triggered:
			return {...state, tradeUploaded: null}
		case UPLOAD_TRADE_BLOTTER.succeeded:
			return {...state, tradeUploaded: true}
		case UPLOAD_TRADE_BLOTTER.failed:
			return {...state, tradeUploaded: false}

		case RECENT_TRADE_BLOTTER_ERROR.triggered:
			return {...state, uploadErrorData: null}
		case RECENT_TRADE_BLOTTER_ERROR.succeeded:
			return {...state, uploadErrorData: action.response.data}
		case RECENT_TRADE_BLOTTER_ERROR.failed:
			return {...state, uploadErrorData: []}

		case DELETE_TRADE.triggered:
			return {...state, tradeDeleted: null}
		case DELETE_TRADE.succeeded:
			return {...state, tradeDeleted: true}
		case DELETE_TRADE.failed:
			return {...state, tradeDeleted: false}

		case GET_TRADES.triggered:
			return {...state, allTrades: null, finalizeUpload: null}
		case GET_TRADES.succeeded:
			return {...state, allTrades: action.response.data}
		case GET_TRADES.failed:
			return {...state, allTrades: []}

		case GET_DEPO_OPTION.triggered:
			return {...state, depoOption: null}
		case GET_DEPO_OPTION.succeeded:
			return {...state, depoOption: action.response.data}
		case GET_DEPO_OPTION.failed:
			return {...state, depoOption: []}

		case FINALIZE_TRADE_BLOTTER_UPLOAD.triggered:
			return {...state, finalizeUpload: null}
		case FINALIZE_TRADE_BLOTTER_UPLOAD.succeeded:
			return {...state, finalizeUpload: true}
		case FINALIZE_TRADE_BLOTTER_UPLOAD.failed:
			return {...state, finalizeUpload: false}
	}

	return state
}
