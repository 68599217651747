import {createFetchActions} from '../../services/createActions'
import {post} from '../../services/createAPICall'
import {getPMSBaseUrl} from '../../services/apiService'
export const GET_MKT_REPORTS = createFetchActions('GET_MKT_REPORTS')
export const SAVE_MKT_REPORTS = createFetchActions('SAVE_MKT_REPORTS')
export const DELETE_MKT_REPORTS = createFetchActions('DELETE_MKT_REPORTS')
export const SET_MKT_TABLE_TYPE = 'SET_TABLE_TYPE'

export function setTableType(flag: any) {
	return {
		type: SET_MKT_TABLE_TYPE,
		data: flag,
	}
}

export const getReports = user => (dispatch: any) => {
	return dispatch(
		post(GET_MKT_REPORTS, `${getPMSBaseUrl()}/getfmcfg`, user, null, 'Error occured while getting market data reports')
	)
}

export const saveReports = widget => (dispatch: any) => {
	return dispatch(
		post(
			SAVE_MKT_REPORTS,
			`${getPMSBaseUrl()}/savefmcfg`,
			widget,
			null,
			'Error occured while saving market data reports'
		)
	)
}
export const deleteReports = user => (dispatch: any) => {
	return dispatch(
		post(
			DELETE_MKT_REPORTS,
			`${getPMSBaseUrl()}/deletefmcfg`,
			user,
			null,
			'Error occured while deleting market data reports'
		)
	)
}
