import {createFetchActions} from '../../services/createActions'
import {getPMSClient} from '../..'
import {getSettlementDataQuery} from './settlementLadderQueries'

export const GET_SETTLEMENT_DATA = createFetchActions('GET_SETTLEMENT_DATA')
export const CLEAR_SETTLEMENT_DATA = 'CLEAR_SETTLEMENT_DATA'

export const getSettlementData = () => {
	return function (dispatch) {
		dispatch({type: GET_SETTLEMENT_DATA.triggered})
		getPMSClient()
			.query({
				query: getSettlementDataQuery,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_SETTLEMENT_DATA.succeeded,
					response,
				})
			})
	}
}

export const clearSettlementData = () => {
	return {
		type: CLEAR_SETTLEMENT_DATA,
	}
}
