// import { getclient } from '../..'
import {createFetchActions} from '../../services/createActions'
import {getPMSGraphBaseUrl} from '../../services/apiService'
import {abcmResultPivotTableQuery, abcmResultPivotTableQueryForCPTY} from './abcmResultQueries'
import * as axios from 'axios'
import {getAuthToken} from '../../services/authService'
export {getPortfolioUpdateDateTime} from '../portfolioManager/portfolioMangerActions'

export const SET_PIVOT_TABLE_CONFIG = 'SET_PIVOT_TABLE_CONFIG'
export const SET_ABCM_DISPLAY_VIEW = 'SET_ABCM_DISPLAY_VIEW'
export const GET_ABCMRESULT_PIVOT_TABLE = createFetchActions('GET_ABCMRESULT_PIVOT_TABLE')
export const RESET_ABCMRESULT_STATE = 'RESET_ABCMRESULT_STATE'
export const GET_ABCMRESULT_PIVOT_TABLE_FOR_CPTY_SOURCE = createFetchActions(
	'GET_ABCMRESULT_PIVOT_TABLE_FOR_CPTY_SOURCE'
)
export const GET_ABCMRESULT_PIVOT_TABLE_FOR_CPTY_USE = createFetchActions(
	'GET_ABCMRESULT_PGET_ABCMRESULT_PIVOT_TABLE_FOR_CPTY_USEIVOT_TABLE_FOR_CPTY_SOURCE'
)

export function getAbcmResultPivotTable(portfolioId, rankingId, securityId) {
	const query = abcmResultPivotTableQuery(portfolioId, rankingId, securityId)
	return function (dispatch) {
		dispatch({type: GET_ABCMRESULT_PIVOT_TABLE.triggered})
		// getclient()
		//   .query({
		//     query: abcmResultPivotTableQuery(portfolioId, rankingId, securityId),
		//     fetchPolicy: 'network-only'
		//   })
		//   .then(response => {
		//     dispatch({
		//       type: GET_ABCMRESULT_PIVOT_TABLE.succeeded,
		//       response
		//     })
		//   })

		// TODO performance test
		// TODO make single axios instance app level
		const instance = axios.default.create({
			baseURL: `${getPMSGraphBaseUrl()}`,
			timeout: 1000000,
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${getAuthToken()}`,
			},
		})

		instance
			.post(
				'',
				JSON.stringify({
					query: query,
				})
			)
			.then(data => {
				dispatch({
					type: GET_ABCMRESULT_PIVOT_TABLE.succeeded,
					response: data.data,
				})
			})
	}
}

export const resetAbcmResultState = () => {
	return {
		type: RESET_ABCMRESULT_STATE,
	}
}
export function getAbcmResultPivotTableForCPTY(portfolioId, source, cpty) {
	const actionType =
		source === 'SOURCE' ? GET_ABCMRESULT_PIVOT_TABLE_FOR_CPTY_SOURCE : GET_ABCMRESULT_PIVOT_TABLE_FOR_CPTY_USE
	const query = abcmResultPivotTableQueryForCPTY(portfolioId, source, cpty)
	return function (dispatch) {
		dispatch({type: actionType.triggered})
		const instance = axios.default.create({
			baseURL: `${getPMSGraphBaseUrl()}`,
			timeout: 1000000,
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${getAuthToken()}`,
			},
		})

		instance
			.post(
				'',
				JSON.stringify({
					query: query,
				})
			)
			.then(data => {
				dispatch({
					type: actionType.succeeded,
					response: data.data,
				})
			})
	}
}

export const setPivotTableConfig = (data, id, metric) => {
	return {
		type: SET_PIVOT_TABLE_CONFIG,
		response: {
			metric,
			data: {
				id,
				pivotTableConfig: data,
			},
		},
	}
}

export const setAbcmDisplayView = data => {
	return {
		type: SET_ABCM_DISPLAY_VIEW,
		response: {
			data,
		},
	}
}
