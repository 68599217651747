import {
	SAVE_DMN_TEMPLATE,
	LOAD_DMN_TEMPLATE,
	FETCH_DMN_TEMPLATE,
	UPDATE_DMN_TEMPLATE,
	FETCH_COLLATERAL_SCHEDULE_CLIENTS,
	GET_TEMPLATE_BY_CLIENT,
} from './DmnViewerActions'

export interface DMNviewer {
	collateralScheduleClients: any
	selectedTemplate: any
	templateList: any
	templateByClient: any
}

const initialState: DMNviewer = {
	collateralScheduleClients: [],
	selectedTemplate: {},
	templateList: [],
	templateByClient: [],
}

export function DMNReducer(state = initialState, action: any) {
	switch (action.type) {
		case SAVE_DMN_TEMPLATE.triggered || SAVE_DMN_TEMPLATE.failed:
			return {...state}
		case SAVE_DMN_TEMPLATE.succeeded:
			return {...state}

		case LOAD_DMN_TEMPLATE.triggered:
			return {...state, selectedTemplate: {}}
		case LOAD_DMN_TEMPLATE.failed:
			return {...state}
		case LOAD_DMN_TEMPLATE.succeeded:
			const resultOfSelectedTemplate = action.response.data && action.response.data.templateEntry
			return {...state, selectedTemplate: resultOfSelectedTemplate}

		case FETCH_DMN_TEMPLATE.triggered || FETCH_DMN_TEMPLATE.failed:
			return {...state}
		case FETCH_DMN_TEMPLATE.succeeded:
			const templateList = action.response.data && action.response.data.templateList
			return {...state, templateList}
		case UPDATE_DMN_TEMPLATE.triggered || UPDATE_DMN_TEMPLATE.failed:
			return {...state}
		case UPDATE_DMN_TEMPLATE.succeeded:
			return {...state}

		case FETCH_COLLATERAL_SCHEDULE_CLIENTS.triggered || FETCH_COLLATERAL_SCHEDULE_CLIENTS.failed:
			return {...state}
		case FETCH_COLLATERAL_SCHEDULE_CLIENTS.succeeded:
			return {
				...state,
				collateralScheduleClients: action.response,
			}
		case GET_TEMPLATE_BY_CLIENT.triggered || GET_TEMPLATE_BY_CLIENT.failed:
			return {...state}
		case GET_TEMPLATE_BY_CLIENT.succeeded:
			return {
				...state,
				templateByClient: action.response,
			}
	}
	return state
}
