import {applyMiddleware, createStore} from 'redux'
import {routerMiddleware} from 'connected-react-router'
import {createBrowserHistory} from 'history'
import thunk from 'redux-thunk'
import {createMainReducer} from './mainReducer'
import {composeWithDevToolsDevelopmentOnly} from '@redux-devtools/extension'
import {enableBatching} from 'redux-batched-actions'

export const history = createBrowserHistory()

const resetEnhancer = mainReducer => (state, action) => {
	if (action.type !== 'FETCH_DO_LOGOUT_SUCCEEDED') return mainReducer(state, action)
	const newState = mainReducer({}, {})
	newState.router = state.router
	return newState
}

function configureStore(preloadedState: any) {
	const middleware = [routerMiddleware(history), thunk]
	const enhancer = composeWithDevToolsDevelopmentOnly(applyMiddleware(routerMiddleware(history), ...middleware))
	const reducer = resetEnhancer(createMainReducer(history))
	const mainStore = createStore(enableBatching(reducer), preloadedState, enhancer)
	return mainStore
}

export const mainStore = configureStore({})
