import Lodash from 'lodash'
import {ArrayUtil, FormatUtil, ObjectUtil} from 'helper-util'

import {chartConstants} from './dashboardHeatmap/heatmapSliderChart/heatmapSliderChartConstant'
import {getModifiedEntry} from '../../features/tradeDetails/tradeDetailsHelper'
import {formatWithTwoDecimals} from '../../utils/formatUtils'
import {flattenObject, currencyMapping} from '../../utils/featureUtils'
import {portfolioEntities, portfolioGrouping} from './dashboardConstants'
import {paths} from '../../routes/routesConfig'

const nonCashTransfrom = (data, modifier, displayCards) => {
	const transformedData = []
	const portfolio = data && data.portfolio
	const nonCashTrades = portfolio && portfolio.nonCashTrades
	Array.isArray(nonCashTrades) &&
		nonCashTrades.forEach(item => {
			const borrowAmount = item.borrow.amount || 0
			const loanAmount = item.loan.amount || 0

			const borrowShareFraction = borrowAmount / Math.abs(borrowAmount + loanAmount)
			const borrowShare = Number(borrowShareFraction.toFixed(2))

			const tooltipInfo = displayCards[portfolioEntities.nonCash].heatmapConfig.linearGradientColor

			const totalNetValue = borrowAmount + loanAmount * modifier
			const transformDataObject = {
				[chartConstants.chartName]: item.counterParty,
				[chartConstants.chartValue]: Math.abs(totalNetValue) > 0 ? Math.abs(totalNetValue) : 0.1,
				[chartConstants.chartDisplayValue]: FormatUtil.currency(totalNetValue),
				[chartConstants.chartDisplayAbsValue]: FormatUtil.currency(Math.abs(totalNetValue)),
				[chartConstants.chartTooltipDisplayValue]: totalNetValue,
				[chartConstants.chartCurrency]: currencyMapping(item.borrow.currency) || '',
				[`${tooltipInfo[0]}Share`]: borrowShare,
				[tooltipInfo[0]]: borrowAmount,
				[tooltipInfo[1]]: loanAmount,
			}
			transformedData.push(transformDataObject)
		})
	return transformedData
}

const notionalTransform = (data, displayCards) => {
	return nonCashTransfrom(data, 1, displayCards)
}

const exposureTransform = (data, displayCards) => {
	return nonCashTransfrom(data, -1, displayCards)
}

const regularTransfrom = (data, entries, group1Value, group2Value, ...additionalParam) => {
	const maturityTypeParam = additionalParam[0] || null
	const defaultAmountKeyParam = 'baseNotional'
	const amountKeyParam = additionalParam[1] || defaultAmountKeyParam
	const quantityParam = additionalParam[2] || 'quantity'

	const transformedDataAsObject = {}
	let currency = ''
	Array.isArray(entries) &&
		entries.forEach(entry => {
			const modifiedEntry = getModifiedEntry(entry)
			const entryMaturityType = modifiedEntry && modifiedEntry.maturityType
			const maturityTypeMatching =
				FormatUtil.text.toLowerCase(entryMaturityType) === FormatUtil.text.toLowerCase(maturityTypeParam)

			if (!maturityTypeParam || (maturityTypeParam && maturityTypeMatching)) {
				let allowEntry = true
				let modifiedEntryAmount = modifiedEntry[amountKeyParam] && modifiedEntry[amountKeyParam].amount

				if (amountKeyParam === 'effectiveBaseNotional') {
					if (modifiedEntryAmount === 0) {
						allowEntry = false
					} else if (modifiedEntryAmount === null) {
						modifiedEntryAmount =
							modifiedEntry[defaultAmountKeyParam] && modifiedEntry[defaultAmountKeyParam].amount
								? modifiedEntry[defaultAmountKeyParam].amount
								: 0
					}
				} else {
					if (!modifiedEntryAmount) modifiedEntryAmount = 0
				}

				if (allowEntry) {
					if (!transformedDataAsObject[modifiedEntry[group1Value]])
						transformedDataAsObject[modifiedEntry[group1Value]] = {}

					const group1 = transformedDataAsObject[modifiedEntry[group1Value]]
					const groupAmount = group1[modifiedEntry[group2Value]]
						? Number(group1[modifiedEntry[group2Value]].notional)
						: 0
					const groupQuantity = group1[modifiedEntry[group2Value]]
						? Number(group1[modifiedEntry[group2Value]].quantity)
						: 0

					group1[modifiedEntry[group2Value]] = {
						notional: Number(modifiedEntryAmount) + groupAmount,
						priceAmount: Number(modifiedEntry.priceAmount || 0),
						quantity: Number(modifiedEntry[quantityParam] || 0) + groupQuantity,
					}

					currency =
						modifiedEntry[amountKeyParam] && modifiedEntry[amountKeyParam].currency
							? modifiedEntry[amountKeyParam].currency
							: ''
				}
			}
		})

	const transformedData = []
	Object.keys(transformedDataAsObject).forEach(item => {
		const childrenItem = transformedDataAsObject[item]
		const children = []
		let chartValue = 0
		let chartDisplayValue = 0
		Object.keys(childrenItem).forEach(childrenKey => {
			const childrenValue = childrenItem[childrenKey].notional
			chartValue += Math.abs(childrenValue)
			chartDisplayValue += childrenValue
			children.push({
				[chartConstants.chartName]: childrenKey,
				[chartConstants.chartValue]: Math.abs(childrenValue) > 0 ? Math.abs(childrenValue) : 0.1,
				[chartConstants.chartDisplayValue]: FormatUtil.currency(childrenValue),
				[chartConstants.chartDisplayAbsValue]: FormatUtil.currency(Math.abs(childrenValue)),
				[chartConstants.chartTooltipPriceValue]: formatWithTwoDecimals(childrenItem[childrenKey].priceAmount),
				[chartConstants.chartTooltipQuantity]: childrenItem[childrenKey].quantity,
				[chartConstants.chartTooltipDisplayValue]: childrenValue,
				[chartConstants.chartCurrency]: currencyMapping(currency),
			})
		})
		const transformedDataObject = {
			[chartConstants.chartName]: item,
			[chartConstants.chartValue]: Math.abs(chartValue) > 0 ? Math.abs(chartValue) : 0.1,
			[chartConstants.chartDisplayValue]: FormatUtil.currency(chartDisplayValue),
			[chartConstants.chartDisplayAbsValue]: FormatUtil.currency(Math.abs(chartDisplayValue)),
			[chartConstants.chartTooltipDisplayValue]: chartDisplayValue,
			[chartConstants.chartCurrency]: currencyMapping(currency),
			children,
		}
		transformedData.push(transformedDataObject)
	})
	return transformedData
}

const inefficienciesRecallTransform = (data, entries, group1Value, group2Value) => {
	const transformAmountKey = 'recallInefficiencyContribution'
	return regularTransfrom(null, entries, group1Value, group2Value, null, transformAmountKey, 'recallQuantity')
}

const inefficienciesReturnTransform = (data, entries, group1Value, group2Value) => {
	const transformAmountKey = 'returnInefficiencyContribution'
	return regularTransfrom(null, entries, group1Value, group2Value, null, transformAmountKey, 'returnQuantity')
}

const availabilitiesTransform = (data, entries, group1Value, group2Value) => {
	const transformAmountKey = 'availabilitiesContribution'
	return regularTransfrom(null, entries, group1Value, group2Value, null, transformAmountKey, 'availableQuantity')
}

const needsTransform = (data, entries, group1Value, group2Value) => {
	const transformAmountKey = 'needsContribution'
	return regularTransfrom(null, entries, group1Value, group2Value, null, transformAmountKey, 'needQuantity')
}

const termTransform = (data, entries, group1Value, group2Value) => {
	const term = portfolioEntities.term
	return regularTransfrom(data, entries, group1Value, group2Value, term)
}

const overNightTransform = (data, entries, group1Value, group2Value) => {
	const overNight = portfolioEntities.overNight
	return regularTransfrom(data, entries, group1Value, group2Value, overNight)
}

const openTransform = (data, entries, group1Value, group2Value) => {
	const open = portfolioEntities.open
	return regularTransfrom(data, entries, group1Value, group2Value, open)
}

const openTradesTransform = (data, entries, group1Value, group2Value) => {
	return regularTransfrom(data, entries, group1Value, group2Value, null)
}

export const generalTransform = (data, entries, group1Value, group2Value) => {
	return regularTransfrom(null, entries, group1Value, group2Value, null)
}

export const heatmapDataTransformFunctionList = {
	inefficienciesRecallTransform,
	inefficienciesReturnTransform,
	notionalTransform,
	exposureTransform,
	termTransform,
	overNightTransform,
	openTransform,
	openTradesTransform,
	availabilitiesTransform,
	needsTransform,
	generalTransform,
}

export function transformResponse(dashboardSummaryData: any = {}, screenType: any) {
	const data = dashboardSummaryData[screenType || 'main'] || {}
	const keyWord = Object.keys(data)[0]
	const overviewData = data && data[keyWord]
	const overviewKeyword = overviewData && Object.keys(overviewData)[0]
	const overView = overviewData && overviewData[overviewKeyword]

	const entitiesSummmary = {}
	if (overView) entitiesSummmary['createdDate'] = overView.createdDate || ''

	overView &&
		Object.keys(portfolioGrouping).forEach(group => {
			portfolioGrouping[group].forEach(entities => {
				const amountDetail = overView[entities] && overView[entities].amount
				const currency = overView[entities] && overView[entities].currency
				entitiesSummmary[`${entities}Amount`] = currency
					? amountDetail
					: amountDetail && amountDetail.amount
					? amountDetail.amount
					: 0
				entitiesSummmary[`${entities}Currency`] = currency
					? currency
					: amountDetail && amountDetail.currency
					? amountDetail.currency
					: ''
			})
		})
	return entitiesSummmary
}

export const getSBLDetail = dashboardPortfolioAnalytics => {
	const portfolio =
		dashboardPortfolioAnalytics &&
		Object.keys(dashboardPortfolioAnalytics).length > 0 &&
		dashboardPortfolioAnalytics['sblDashboard']

	let detail: any = false
	if (portfolio) {
		detail = portfolio[Object.keys(portfolio)[0]]
	}

	return detail
}

export const getDataQuery = (portfolioEntities: string) => {
	const dataQuery = []
	Array.isArray(portfolioGrouping[portfolioEntities]) &&
		portfolioGrouping[portfolioEntities].forEach(entity => {
			dataQuery.push(`${entity}DataQuery`)
		})
	return dataQuery
}

export const getAmountKeys = (portfolioEntities: string) => {
	const amountKeys = []
	Array.isArray(portfolioGrouping[portfolioEntities]) &&
		portfolioGrouping[portfolioEntities].forEach(entity => {
			amountKeys.push(`${entity}Amount`)
		})
	return amountKeys
}

export const getCurrencyKey = (portfolioEntities: string) => {
	let currencyKey = ''
	if (Array.isArray(portfolioGrouping[portfolioEntities]) && portfolioGrouping[portfolioEntities].length > 0) {
		currencyKey = `${portfolioGrouping[portfolioEntities][0]}Currency`
	}
	return currencyKey
}

export const getDefaultDualPortfolioHeatMapView = () => {
	const dualPortfolioHeatMapView = {}
	Object.keys(portfolioGrouping).forEach(portfolio => {
		dualPortfolioHeatMapView[portfolio] = {}
		portfolioGrouping[portfolio].forEach(chartIncluded => {
			dualPortfolioHeatMapView[portfolio][chartIncluded] = true
		})
	})
	return dualPortfolioHeatMapView
}

export const getHeatMapClass = (drilldownIndex, firstPortfolio, secondPortfolio) => {
	const firstContainer =
		drilldownIndex < 0
			? firstPortfolio
				? secondPortfolio
					? 'w-50 ml-3'
					: 'w-100 mx-3'
				: 'w-100'
			: drilldownIndex === 0
			? 'w-100 mx-3'
			: 'd-none'
	const firstSubTitle =
		drilldownIndex < 0
			? firstPortfolio
				? secondPortfolio
					? 'w-50 mt-3 mx-3 f-14 font-weight-600 text-grey-5'
					: 'mt-3 mx-3 f-14 font-weight-600 invisible'
				: 'mt-3 mx-3 f-14 font-weight-600 invisible'
			: drilldownIndex === 0
			? 'w-100 mt-3 mx-3 f-14 font-weight-600 text-grey-5'
			: 'mt-3 mx-3 f-14 font-weight-600 invisible'

	const secondContainer =
		drilldownIndex < 0
			? secondPortfolio
				? firstPortfolio
					? 'w-50 mr-3 border-left'
					: 'w-100 mx-3'
				: 'd-none'
			: drilldownIndex === 1
			? 'w-100 mx-3'
			: 'd-none'
	const secondSubTitle =
		drilldownIndex < 0
			? secondPortfolio
				? firstPortfolio
					? 'w-50 mt-3 mx-3 f-14 font-weight-600 text-grey-5'
					: 'mt-3 mx-3 f-14 font-weight-600 invisible'
				: 'mt-3 mx-3 f-14 font-weight-600 invisible'
			: drilldownIndex === 1
			? 'w-100 mt-3 mx-3 f-14 font-weight-600 text-grey-5'
			: 'mt-3 mx-3 f-14 font-weight-600 invisible'

	const portfolioClass = {
		firstContainer,
		firstSubTitle,
		secondContainer,
		secondSubTitle,
	}
	return portfolioClass
}

export const getPortfolioEntries = dashboardPortfolioAnalytics => {
	const portfolio =
		dashboardPortfolioAnalytics &&
		Object.keys(dashboardPortfolioAnalytics).length > 0 &&
		dashboardPortfolioAnalytics['portfolio']

	let entries: any = false
	if (portfolio === null) {
		entries = []
	} else if (portfolio && portfolio[Object.keys(portfolio)[0]]) {
		entries = portfolio[Object.keys(portfolio)[0]].entries
	}

	return entries
}

export const getPortfolioDetail = dashboardPortfolioAnalytics => {
	const portfolio =
		dashboardPortfolioAnalytics &&
		Object.keys(dashboardPortfolioAnalytics).length > 0 &&
		dashboardPortfolioAnalytics['portfolio']

	let detail: any = false
	if (portfolio && Array.isArray(portfolio[Object.keys(portfolio)[0]])) {
		detail = portfolio[Object.keys(portfolio)[0]]
	}

	return detail
}

export const getSecondLevelEntries = (props, state) => {
	const {dashboardPortfolioAnalytics} = props
	const {selectedPortfolioEntity, currentDrilldownBy} = state

	const dataQuery = getDataQuery(portfolioEntities[selectedPortfolioEntity.name])
	const index = dataQuery.indexOf(`${currentDrilldownBy}DataQuery`)
	const data = dashboardPortfolioAnalytics[index][dataQuery[index]]

	const entries =
		data && data.portfolio && data.portfolio[currentDrilldownBy] && data.portfolio[currentDrilldownBy].entries

	return entries
}

export const getQueuePayLoad = (entries, props, state) => {
	const {firstLevelTreemapValue} = props
	const {groupOne} = state

	const newEntries = []
	Array.isArray(entries) &&
		entries.forEach(item => {
			const itemKey = Object.keys(item)

			let newItem = item
			itemKey.forEach(key => {
				const splittedValue = flattenObject(key, item[key])
				if (splittedValue) newItem = Object.assign(newItem, splittedValue)
			})

			FormatUtil.text.toLowerCase(item[groupOne]) === FormatUtil.text.toLowerCase(firstLevelTreemapValue) &&
				newEntries.push(newItem.id)
		})

	const payLoad = {
		portfolioEntries: newEntries,
	}
	return payLoad
}

export const formatCsvData = (tableData: any) => {
	const formattedData = []
	const data = Lodash.cloneDeep(tableData)
	data.forEach(item => {
		formattedData.push(...item.subTableData)
	})
	return formattedData
}

export const addAmountsFromObjects = data => {
	if (data && data['all']) {
		return data['all'].entries
			.filter(item => item.portfolioEntryType === 'Intra Day')
			.map(item => {
				if (ObjectUtil.isObject(item['baseNotional'])) {
					item['baseNotional'] = item.baseNotional['amount']
				}
				if (ObjectUtil.isObject(item['needsContribution'])) {
					item['needsContributionAmount'] = item.needsContribution['amount']
				}
				if (ObjectUtil.isObject(item['cash'])) {
					item['cashAmount'] = item.cash['amount']
				}
				if (ObjectUtil.isObject(item['collateral'])) {
					item['collateralAmount'] = item.collateral['amount']
				}
				if (ObjectUtil.isObject(item['notional'])) {
					item['notionalAmount'] = item.notional['amount']
					item['currency'] = item.notional['currency']
				}
				return item
			})
	} else {
		return []
	}
}

export const toggleBlankValue = value => {
	const blank = ''
	const na = 'N/A'
	return value === blank || value === null ? na : value === na ? blank : value
}

export const toggleNullValue = value => {
	const na = 'N/A'
	return value === null ? na : value === na ? null : value
}

export const transformHeatmapData = data => {
	const response = {}
	if (!ArrayUtil.isEmpty(data)) {
		const childrenExist = data[0].count !== 0
		data &&
			data
				.sort((a, b) => a.amount - b.amount)
				.forEach((item, index) => {
					const entryType = item.type || 'matureTrades'
					const responseTypeValue = response[entryType]

					const obj = {
						[chartConstants.chartName]: toggleBlankValue(item.key),
						[chartConstants.chartValue]: Math.abs(item.amount) > 0 ? Math.abs(item.amount) : 0.1,
						[chartConstants.chartDisplayValue]: FormatUtil.currency(item.amount),
						[chartConstants.chartDisplayAbsValue]: FormatUtil.currency(Math.abs(item.amount)),
						[chartConstants.chartTooltipDisplayValue]: item.amount,
						[chartConstants.chartCurrency]: currencyMapping(item.currency),
						[chartConstants.chartChildren]: childrenExist ? Array(item.count).fill('') : Array(index++).fill(''),
					}

					if (responseTypeValue) {
						const currentresvalue = responseTypeValue
						currentresvalue.push(obj)
					} else {
						const type = item.type || 'matureTrades'
						response[type] = [obj]
					}
				})
	}
	return response
}

export const transformPbHeatmapData = (data, selectedPortfolioEntity) => {
	const heatmapData = pbTransformation[selectedPortfolioEntity.name](data)
	const formattedHeatmapData = {}
	if (!ArrayUtil.isEmpty(Object.keys(heatmapData))) {
		Object.keys(heatmapData).forEach(key => {
			formattedHeatmapData[key] = heatmapData[key].map((item, index) => {
				const obj = {
					[chartConstants.chartName]: toggleBlankValue(item.key),
					[chartConstants.chartValue]: Math.abs(item.amount) > 0 ? Math.abs(item.amount) : 0.1,
					[chartConstants.chartDisplayValue]: FormatUtil.currency(item.amount),
					[chartConstants.chartDisplayAbsValue]: FormatUtil.currency(Math.abs(item.amount)),
					[chartConstants.chartTooltipDisplayValue]: item.amount,
					[chartConstants.chartCurrency]: currencyMapping(item.currency),
					[chartConstants.chartChildren]: Array(item.count).fill(''),
				}
				return obj
			})
		})
	}

	return formattedHeatmapData
}

export const transformHeatmapBasedOnGradient = (heatmapData: any, dashboadCardConfig: any) => {
	const response = {}
	if (!ArrayUtil.isEmpty(heatmapData)) {
		heatmapData &&
			heatmapData.forEach(item => {
				const type = item.type
				const counterParty = item.key
				const responseItem = response[counterParty]
				const tooltipInfo = dashboadCardConfig.heatmapConfig.linearGradientColor
				const firstTooltip = tooltipInfo[0]
				const secondTooltip = tooltipInfo[1]
				if (responseItem) {
					const counterPartyAmount = responseItem.amount + item.amount
					const updatedObj = {
						...responseItem,
						[chartConstants.chartValue]: Math.abs(counterPartyAmount) > 0 ? Math.abs(counterPartyAmount) : 0.1,
						[chartConstants.chartDisplayValue]: FormatUtil.currency(counterPartyAmount),
						[chartConstants.chartDisplayAbsValue]: FormatUtil.currency(Math.abs(counterPartyAmount)),
						[chartConstants.chartTooltipDisplayValue]: counterPartyAmount,
						[type]: item.amount,
						amount: counterPartyAmount,
					}
					const firstGroupingData = updatedObj[firstTooltip] || 0
					const secondGroupingData = updatedObj[secondTooltip] || 0
					const firstGroupingShareFraction = firstGroupingData / Math.abs(firstGroupingData + secondGroupingData)
					const firstGroupingShare = Number(firstGroupingShareFraction.toFixed(2))
					updatedObj[`${tooltipInfo[0]}Share`] = firstGroupingShare
					response[counterParty] = updatedObj
				} else {
					const obj = {
						amount: item.amount,
						[chartConstants.chartName]: toggleBlankValue(item.key),
						[chartConstants.chartCurrency]: currencyMapping(item.currency),
						[type]: item.amount,
					}
					response[counterParty] = obj
				}
			})
	}
	return Object.values(response)
}

export const checkDashboardNavigation = (selectedPortfolioEntity: any, screenType: any, history: any) => {
	if (screenType === 'repo') {
		if (selectedPortfolioEntity.name !== portfolioEntities.matureTrades) {
			history.push(paths.repo)
		} else {
			history.push(paths.repoTest)
		}
	} else if (screenType === 'sbl') {
		if (
			![portfolioEntities.matureTrades, portfolioEntities.boxes, portfolioEntities.sblTrades].includes(
				selectedPortfolioEntity.name
			)
		) {
			history.push(paths.sbl)
		} else {
			history.push(paths.sblTest)
		}
	} else if (screenType === 'collateralOptimization') {
		history.push(paths.collateralOptimization)
	} else if (screenType === 'pb') {
		// history.push(paths.pbDashboard)
	} else {
		if (
			[portfolioEntities.all, portfolioEntities.matureTrades, portfolioEntities.boxes].includes(
				selectedPortfolioEntity.name
			)
		) {
			history.push(paths.dashboardTest)
		} else {
			history.push(paths.dashboard)
		}
	}
}

export const formatPositionsData = data => {
	const formattedData = {}
	!ArrayUtil.isEmpty(data) &&
		data.forEach(item => {
			formattedData[item.type] = [...item.groupedValues]
		})
	return formattedData
}

export const formatMarginData = data => {
	const formattedData = {}
	formattedData[portfolioEntities.pbMargin] = (data && data[0] && data[0].groupedValues) || []
	return formattedData
}

export const pbTransformation = {
	[portfolioEntities.pbPositions]: formatPositionsData,
	[portfolioEntities.pbMargin]: formatMarginData,
}
