import {getALPBaseUrl} from '../../../services/apiService'
import {createFetchActions} from '../../../services/createActions'
import {get} from '../../../services/createAPICall'

export const FETCH_REQUESTER_TRADES = createFetchActions('FETCH_REQUESTER_TRADES')
export const CLEAR_MAIN_GRID_DATA = createFetchActions('CLEAR_MAIN_GRID_DATA')
export const ALTER_RATE_FIELD_DATA = createFetchActions('ALTER_RATE_FIELD_DATA')

export const fetchRequesterTrades = () => (dispatch: any) => {
	return dispatch(get(FETCH_REQUESTER_TRADES, `${getALPBaseUrl()}/v1/alp/trades`))
}

export const clearGridData = () => {
	return {
		type: CLEAR_MAIN_GRID_DATA,
	}
}
export const alterRateFieldData = toggleMode => {
	return {
		type: ALTER_RATE_FIELD_DATA,
		payload: {toggleMode},
	}
}
