import gql from 'graphql-tag'

const portfolio_entries_fragment = gql`
	fragment TradingQueuePortfolioEntries on PortfolioEntry {
		acknowledgedBy
		actions
		activityType
		allInRate
		assetClassification
		assetMaturityDate
		assetSubType
		assetType
		availableQuantity
		originalCollateralValue {
			amount
			currency
		}
		originalQuantity
		balanceSheetContribution {
			amount
			currency
		}
		baseNotional {
			amount
			currency
		}
		basePrice {
			amount
			currency
		}
		bbId
		book
		borrowFee
		loanFee
		buySellInd
		collateralType
		collateral {
			amount
			currency
		}
		counterParty
		createdBy
		createdDate
		cusip
		effectiveBaseNotional {
			amount
			currency
		}
		endDate
		exchangeRate
		fitchRating
		fund
		haircut
		hqla
		id
		inefficiencies {
			inefficiency
			abbr
			displayName
		}
		index
		isin
		maturityDate
		maturityType
		moodyRating
		needQuantity
		notional {
			amount
			currency
		}
		originalAllInRate
		poolFactor
		portfolioEntryType
		partial
		price {
			amount
			currency
		}
		priceMultiplier
		quantity
		recallInefficiencyContribution {
			amount
			currency
		}
		recallQuantity
		referenceRate
		returnInefficiencyContribution {
			amount
			currency
		}
		returnQuantity
		recallOrReturnQty
		availabilitiesContribution {
			amount
			currency
		}
		needsContribution {
			amount
			currency
		}
		platformTradeId
		rebate
		originalTradeType
		legalEntity
		ric
		sector
		securityId
		securityIdType
		settlementDate
		snpRating
		sourceSystem
		sourceSystemTradeId
		spread
		startDate
		tenor
		termDate
		tradeDate
		ticker
		tradeType
		status
		orderId
	}
`

export const getTradingQueueDataQuery = gql`
	query {
		tradingQueueNew {
			userActivitiesQueue {
				open {
					...TradingQueuePortfolioEntries
				}
				outgoing {
					...TradingQueuePortfolioEntries
				}
			}
			firmActivitiesQueue {
				incoming {
					...TradingQueuePortfolioEntries
				}
				outgoing {
					...TradingQueuePortfolioEntries
				}
			}
		}
	}
	${portfolio_entries_fragment}
`
