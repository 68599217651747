import gql from 'graphql-tag'
import {ArrayUtil} from 'helper-util'

export const getCalendarEventsQuery = (year, month, counterparty, currency, tradeType) => {
	const counterparties = !ArrayUtil.isEmpty(counterparty) ? '"'.concat(counterparty.join('","'), '"') : ''
	const currencies = !ArrayUtil.isEmpty(currency) ? '"'.concat(currency.join('","'), '"') : ''
	const tradeTypes = !ArrayUtil.isEmpty(tradeType) ? '"'.concat(tradeType.join('","'), '"') : ''
	return gql`
        query
          {
            calendarDay(year: ${year}, month: ${month}, counterparty: [${counterparties}], currency: [${currencies}], tradeType: [${tradeTypes}]) {
                date
                trades {
                  tradeId
                  counterParty
                  cash {
                    amount
                    currency
                  }
                  tradeType
                  notional{
                    amount
                    currency
                  }
                  baseNotional{
                    amount
                    currency
                  }
                  collateralType
                  collateral {
                    amount
                    currency
                  }
                  endCash{
                    amount
                    currency
                  }
                  entries {
                    id
                    cusip
                    securityId
                    isin
                    ticker
                    originalTradeType
                    legalEntity
                    ric
                    bbId
                    quantity
                    maturityDate
                    price {
                      amount
                      currency
                    }
                    assetType
                    assetSubType
                    tradeType
                    book
                    fund
                    buySellInd
                    counterParty
                    sourceSystem
                    sourceSystemTradeId
                    allInRate
                    spread
                    referenceRate
                    haircut
                    notional {
                      amount
                      currency
                    }
                    collateral {
                      amount
                      currency
                    }
                    cash {
                      amount
                      currency
                    }
                    startDate
                    endDate
                    termDate
                    collateralType
                    maturityType
                    abcmCategoryName
                    abcmCategoryDisplayName
                    recallInefficiencyContribution {
                      amount
                      currency
                    }
                    returnInefficiencyContribution {
                      amount
                      currency
                    }
                    tenor
                    hqla
                    moodyRating
                    snpRating
                    fitchRating
                    sector
                    index
                    baseNotional {
                      amount
                      currency
                    }
                    basePrice {
                      amount
                      currency
                    }
                    partial
                    activityType
                    portfolioEntryType
                    actions
                    inefficiencies {
                      inefficiency
                      abbr
                      displayName
                    }
                  }
                }
                cashInflow {
                  amount
                  currency
                }
                cashOutflow {
                  amount
                  currency
                }
                collateralInflow {
                  amount
                  currency
                }
                collateralOutflow {
                  amount
                  currency
                }
                netCashflow{
                  amount
                  currency
                }
                calendarEvents {
                  id
                  name
                  date
                  startTime
                  endTime
                }
            }
          }`
}
