import gql from 'graphql-tag'

export const getSecurities = gql`
	query {
		inventory {
			securityId
			shortName
			longName
			ticker
			quantity
			price
			priceMultiplier
			currency
			availableNotional
			pendingIn
			pendingInNotional
			pendingOut
			pendingOutNotional
		}
	}
`
