export const chartConstants = {
	chartName: 'name',
	chartChildren: 'children',
	chartValue: 'value',
	chartCurrency: 'currency',
	chartDisplayValue: 'displayValue',
	chartDisplayAbsValue: 'displayAbsValue',
	chartTooltipDisplayValue: 'chartTooltipDisplayValue',
	chartTooltipPriceValue: 'chartTooltipPriceValue',
	chartTooltipQuantity: 'chartTooltipQuantity',
	chartTooltipTrader: 'chartTooltipTrader',
	chartDisplayLabel: 'displayLabel',
	chartColor: 'color',
	color: {
		heath: '#460E0E',
		flamingo: '#F46146',
		rajah: '#F5BE65',
		chestnut: '#C34545',
		moongose: '#DECB89',
		white: '#FFFFFF',
	},
}

export const heatmapColorPallete = {
	init: {red: 239, green: 86, blue: 73},
	final: {red: 245, green: 222, blue: 143},
}

export const heatmapDarkColorPallete = {
	init: {red: 195, green: 69, blue: 69},
	final: {red: 222, green: 203, blue: 137},
}

export const heatmapNoDataMsg = {
	matureTrades: 'No maturing trades',
}
