import Lodash from 'lodash'
import {globalColumnDefinition} from '../../../common/AgGrid/AgGridColumn'
import {commaNoDecimal} from '../../../common/AgGrid/AgGridHelper'
import {formatWithThreeDecimals, formatWithSeparator} from '../../../utils/formatUtils'
import {TRIGGER_FIELDS_TO_CLEARED} from './NegotiationModalConstant'
import memoizeOne from 'memoize-one'
import isDeepEqual from 'lodash.isequal'

export const negotiationColumnDef = memoizeOne((onQntyChange, onQntyKeyDown, onQntyClick, isPartial) => {
	let commonColumn: any = [
		Object.assign({}, globalColumnDefinition.status, {
			cellRendererParams: {
				fields: [{key: 'Rejection Reason', value: 'reason'}],
				format: 'status',
			},
			cellRenderer: 'CustomTooltip',
		}),
		Object.assign(
			{},
			{
				headerName: 'Bid Rate (%)',
				field: 'rate',
				type: 'numericColumn',
				cellRendererParams: {
					allowDecimal: true,
					allowNegative: true,
					onKeyDown: onQntyKeyDown,
					onClick: onQntyClick,
					triggerValidationField: true,
					showRateInBadge: true,
					showRateInBadgeClass: 'negotiation-received',
					focusField: 'rateFocus',
					nextFocusField: isPartial ? 'maxReqQtyFocus' : 'requestedQtyFocus',
					validations: [
						{
							sourceField: 'rate',
							operator: '>',
							targetField: 0,
							errorMsg: 'Bid Rate should be greater than zero',
						},
					],
					onChange: (index, value) => onQntyChange('rate', null, value),
					valueFormatFn: formatWithThreeDecimals,
				},
				cellRenderer: 'QuantityCellRenderer',
				cellClass: ['d-flex align-items-center justify-content-end gridcellPadding'],
				minWidth: 150,
				width: 150,
				maxWidth: 150,
			}
		),
		Object.assign(
			{},
			{
				headerName: 'Offer Rate (%)',
				field: 'offerRate',
				type: 'numericColumn',
				cellRenderer: params => {
					return params.value !== null
						? `<span class="offer-rate-badge negotiation-sent">${formatWithThreeDecimals(Number(params.value))}</span>`
						: null
				},
			}
		),
	]
	if (isPartial) {
		commonColumn = commonColumn.concat([
			Object.assign(
				{},
				{
					headerName: 'Request Qty',
					field: 'maxReqQty',
					type: 'numericColumn',
					cellRendererParams: {
						onChange: (index, value) => onQntyChange('maxReqQty', 'maxReqQtyBlur', value),
						onKeyDown: onQntyKeyDown,
						onClick: onQntyClick,
						valueFormatFn: formatWithSeparator,
						triggerValidationField: true,
						focusField: 'maxReqQtyFocus',
						nextFocusField: 'minReqQtyFocus',
						validations: [
							{
								sourceField: 'maxReqQty',
								operator: '>',
								targetField: 0,
								errorMsg: 'Request Qty should be greater than zero',
							},
							{
								sourceField: 'maxReqQty',
								operator: '>',
								targetField: 'minReqQty',
								errorMsg: 'Request Qty should be greater than Min Qty',
							},
						],
					},
					cellRenderer: 'QuantityCellRenderer',
					cellClass: ['d-flex align-items-center justify-content-end gridcellPadding'],
					minWidth: 130,
					width: 130,
					maxWidth: 130,
				}
			),
			Object.assign(
				{},
				{
					headerName: 'Min Qty',
					field: 'minReqQty',
					type: 'numericColumn',
					cellRendererParams: {
						onChange: (index, value) => onQntyChange('minReqQty', 'minReqQtyBlur', value),
						onKeyDown: onQntyKeyDown,
						onClick: onQntyClick,
						valueFormatFn: formatWithSeparator,
						triggerValidationField: true,
						focusField: 'minReqQtyFocus',
						nextFocusField: 'qtyIncrementFocus',
						validations: [
							{
								sourceField: 'minReqQty',
								operator: '>',
								targetField: 0,
								errorMsg: 'Min Qty should be greater than zero',
							},
							{
								sourceField: 'minReqQty',
								operator: '<',
								targetField: 'maxReqQty',
								errorMsg: 'Min Qty should be less than Request Qty',
							},
							{
								sourceField: 'minReqQty',
								addWith: 'qtyIncrement',
								operator: '<=',
								targetField: 'maxReqQty',
								errorMsg: 'Addition of Min Qty and Increment should be less than or equal to Request Qty',
							},
						],
					},
					cellRenderer: 'QuantityCellRenderer',
					cellClass: ['d-flex align-items-center justify-content-end gridcellPadding'],
					minWidth: 120,
					width: 120,
					maxWidth: 120,
				}
			),
			Object.assign(
				{},
				{
					headerName: 'Increment',
					field: 'qtyIncrement',
					type: 'numericColumn',
					cellRendererParams: {
						onChange: (index, value) => onQntyChange('qtyIncrement', 'qtyIncrementBlur', value),
						onKeyDown: onQntyKeyDown,
						onClick: onQntyClick,
						valueFormatFn: formatWithSeparator,
						triggerValidationField: true,
						focusField: 'qtyIncrementFocus',
						nextFocusField: 'requestedQtyFocus',
						validations: [
							{
								sourceField: 'qtyIncrement',
								operator: '>',
								targetField: 0,
								errorMsg: 'Increment should be greater than zero',
							},
							{
								sourceField: 'qtyIncrement',
								addWith: 'minReqQty',
								operator: '<=',
								targetField: 'maxReqQty',
								errorMsg: 'Addition of Min Qty and Increment should be less than or equal to Request Qty',
							},
						],
					},
					cellRenderer: 'QuantityCellRenderer',
					cellClass: ['d-flex align-items-center justify-content-end gridcellPadding'],
					minWidth: 130,
					width: 130,
					maxWidth: 130,
				}
			),
		])
	} else {
		commonColumn = commonColumn.concat([
			Object.assign(
				{},
				{
					headerName: 'Request Qty',
					field: 'requestedQty',
					type: 'numericColumn',
					cellRendererParams: {
						onChange: (index, value) => onQntyChange('requestedQty', 'reqQtyBlur', value),
						onKeyDown: onQntyKeyDown,
						onClick: onQntyClick,
						valueFormatFn: formatWithSeparator,
						triggerValidationField: true,
						focusField: 'requestedQtyFocus',
						nextFocusField: '',
						validations: [
							{
								sourceField: 'requestedQty',
								operator: '>',
								targetField: 0,
								errorMsg: 'Request Qty should be greater than zero',
							},
						],
					},
					cellRenderer: 'QuantityCellRenderer',
					cellClass: ['d-flex align-items-center justify-content-end gridcellPadding'],
					minWidth: 130,
					width: 130,
					maxWidth: 130,
				}
			),
		])
	}

	return commonColumn.concat([
		Object.assign({}, {headerName: 'Available Qty', field: 'availQty', ...commaNoDecimal}),
		Object.assign(
			{},
			{headerName: 'Filled Qty', field: 'bookedQty', type: 'numericColumn'},
			{
				cellRendererParams: {
					fields: [{key: 'Notional Value', value: 'contractValue', type: 'commaTwoDecimal'}],
					format: 'commaNoDecimal',
				},
				cellRenderer: 'CustomTooltip',
			}
		),
		Object.assign({}, {headerName: 'Unfilled Qty', field: 'shortfall', ...commaNoDecimal}),
		Object.assign({}, globalColumnDefinition.modifiedDate, {headerName: 'Time (EST)'}),
	])
}, isDeepEqual)

export const onTradeQtyUpdate = memoizeOne((mode, activityGridData, payload) => {
	const activityGridDataCopy = Lodash.cloneDeep(activityGridData)
	const {field, value, triggerField, focusField} = payload
	const {isPartial, maxReqQty, bookedQty, requestedQty} = activityGridDataCopy[0]
	let firstGridRow = {
		...activityGridDataCopy[0],
		shortfall: isPartial ? maxReqQty - (bookedQty || 0) : requestedQty - (bookedQty || 0),
	}
	switch (mode) {
		case 'UPDATE':
			firstGridRow = {
				...activityGridDataCopy[0],
				[field]: value,
			}
			break
		case 'TRIGGER':
			firstGridRow = {
				...firstGridRow,
				...TRIGGER_FIELDS_TO_CLEARED,
				[triggerField]: true,
			}
			break
		case 'FOCUS':
			firstGridRow = {
				...firstGridRow,
				...TRIGGER_FIELDS_TO_CLEARED,
				[focusField]: true,
			}
			break
	}

	activityGridDataCopy[0] = firstGridRow
	return activityGridDataCopy
}, isDeepEqual)

export const isValid = memoizeOne((isNegotiation, isPartial, data) => {
	const {minReqQty, maxReqQty, qtyIncrement, requestedQty, rate} = data
	if (!isNegotiation) {
		return false
	}
	if (isNegotiation && !rate) {
		return false
	}
	if (isPartial) {
		if (!minReqQty || !maxReqQty || !qtyIncrement) {
			return false
		}
		if (minReqQty > maxReqQty) {
			return false
		}
		if (minReqQty + qtyIncrement > maxReqQty) {
			return false
		}
		return minReqQty > 0 && maxReqQty > 0 && qtyIncrement > 0
	} else {
		return !!requestedQty && requestedQty > 0
	}
}, isDeepEqual)

export const fieldToValueMapper = memoizeOne((selectedTradeData, fieldNode) => {
	const {format, field} = fieldNode
	switch (format) {
		case 'accountType':
			return selectedTradeData[field] === 'MAIN_ACCT' ? 'Generic' : selectedTradeData[field]
		case 'status':
			return selectedTradeData[field] === 'COUNTER' ? 'Counter-Offer Received' : selectedTradeData[field] || ''
		case 'number':
			return selectedTradeData[field] === 0 ? 0 : selectedTradeData[field] || ''
		case 'rate':
			return selectedTradeData[field] || selectedTradeData[field] === 0
				? formatWithThreeDecimals(Number(selectedTradeData[field]))
				: ''
	}
	return selectedTradeData[field] || ''
}, isDeepEqual)
