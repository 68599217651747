import {dropdownOption, portfolioEntities} from '../../../constants/dashboardConstants'
import {PortfolioLongShort} from '../../../styles/svg/svgIcons'

export const displayCards = {
	[portfolioEntities.pbPositions]: {
		icon: PortfolioLongShort,
		entity: portfolioEntities.pbPositions,
		dropdownDefault: [dropdownOption.counterParty.id, dropdownOption.securityId.id],
		modifier: 1,
		dualHeatmap: true,
		filterType: {
			toggle: [],
			dropdown: true,
		},
		titleText: 'Total Notional',
		tradeDetailRoutePath: null,
		heatmapConfig: {
			allowDrilldown: true,
			linearGradientColor: [],
		},
		moveToQueue: false,
		isMock: true,
	},
	[portfolioEntities.pbMargin]: {
		icon: PortfolioLongShort,
		entity: portfolioEntities.pbMargin,
		dropdownDefault: [dropdownOption.counterParty.id, dropdownOption.securityId.id],
		modifier: 1,
		dualHeatmap: true,
		filterType: {
			toggle: [],
			dropdown: true,
		},
		titleText: 'Total Notional',
		tradeDetailRoutePath: null,
		heatmapConfig: {
			allowDrilldown: true,
			linearGradientColor: [],
		},
		moveToQueue: false,
		isMock: true,
	},
}

export const positionsData = {
	longTrades: [
		{
			currency: 'USD',
			key: 'AutomationUser',
			type: 'longTrades',
			amount: 209085797.86,
			count: 24,
		},

		{
			currency: 'USD',
			key: 'TAL',
			type: 'longTrades',
			amount: 1079305772.2,
			count: 52,
		},
		{
			currency: 'USD',
			key: null,
			type: 'longTrades',
			amount: 55578690825.86,
			count: 4809,
		},
	],
	shortTrades: [
		{
			currency: 'USD',
			key: 'AutomationUser',
			type: 'shortTrades',
			amount: 0.0,
			count: 14,
		},
		{
			currency: 'USD',
			key: null,
			type: 'shortTrades',
			amount: -21999900924.62,
			count: 1881,
		},
	],
}

export const positionsDrilldownData = {
	longTrades: [
		{
			currency: 'USD',
			key: 'ACN US',
			type: 'longTrades',
			amount: 60700817.98,
			count: 0,
		},
		{
			currency: 'USD',
			key: 'ADBE US',
			type: 'longTrades',
			amount: 100939816.4,
			count: 0,
		},
		{
			currency: 'USD',
			key: 'ADSK US',
			type: 'longTrades',
			amount: 22100241.27,
			count: 0,
		},
		{
			currency: 'USD',
			key: 'AMGN US',
			type: 'longTrades',
			amount: 58546459.38,
			count: 0,
		},
		{
			currency: 'USD',
			key: 'BKNG US',
			type: 'longTrades',
			amount: 31342046.14,
			count: 0,
		},
		{
			currency: 'USD',
			key: 'CB US',
			type: 'longTrades',
			amount: 22359928.8,
			count: 0,
		},
		{
			currency: 'USD',
			key: 'CTL US',
			type: 'longTrades',
			amount: 4183660.8,
			count: 0,
		},
		{
			currency: 'USD',
			key: 'DAL US',
			type: 'longTrades',
			amount: 6853059.34,
			count: 0,
		},
		{
			currency: 'USD',
			key: 'DGX US',
			type: 'longTrades',
			amount: 5753993.4,
			count: 0,
		},
		{
			currency: 'USD',
			key: 'EA US',
			type: 'longTrades',
			amount: 15785941.79,
			count: 0,
		},
	],
}
