import {RESET_ALL_TRADES, GET_ALL_TRADES, FETCH_LOCATE_SEARCH_RESULT_FOR_SBL} from './boxesActions'

export interface Box {
	loading: boolean
	allBoxEntries: any
	locateSearchData: any
	sblLocateSearchLoading: boolean
}

const initialState: Box = {
	loading: true,
	allBoxEntries: {},
	locateSearchData: [],
	sblLocateSearchLoading: false,
}

export function boxesReducer(state = initialState, action: any) {
	switch (action.type) {
		case RESET_ALL_TRADES:
			return {...state, loading: true, allBoxEntries: {}}

		case GET_ALL_TRADES.triggered:
			return {...state, loading: true}
		case GET_ALL_TRADES.failed:
			return {...state, loading: false}
		case GET_ALL_TRADES.succeeded:
			const responsePortfolio = action.response.data
				? action.dashboardType && action.dashboardType === 'REPO'
					? action.response.data.repoDashboard
					: action.response.data.portfolio
				: {}
			return {
				...state,
				allBoxEntries: {
					...responsePortfolio,
				},
				loading: false,
			}
		case FETCH_LOCATE_SEARCH_RESULT_FOR_SBL.triggered:
			return {...state, sblLocateSearchLoading: true, locateSearchData: []}
		case FETCH_LOCATE_SEARCH_RESULT_FOR_SBL.failed:
			return {...state, locateSearchData: [], sblLocateSearchLoading: false}
		case FETCH_LOCATE_SEARCH_RESULT_FOR_SBL.succeeded:
			const locateSearchData = action.response.data && action.response.data.searchAvailabilitySecurityResults
			return {...state, locateSearchData, sblLocateSearchLoading: false}
	}
	return state
}
