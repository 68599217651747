import React from 'react'
import VerticallyCenteredModal from '../styleGuide/fsModal'
import {WarningIcon} from '../../styles/svg/svgIcons'

interface Props {
	resetError: () => any
	errorMessage: string
}

const defaultMessage = `We are unable to complete your request please try again after
sometime.`

interface State {}
export default class ErrorModal extends React.Component<Props, State> {
	componentDidUpdate() {}

	render() {
		return (
			<>
				<VerticallyCenteredModal
					size='md'
					headerEl={<div></div>}
					bodyEl={
						<div className='text-center mt-4'>
							<span className='d-block m-auto text-danger'>
								<WarningIcon />
							</span>
							<span className='d-block f-18 mt-2 mb-3'>{this.props.errorMessage || defaultMessage}</span>
						</div>
					}
					footerEl={
						<div className='text-center pb-4 pt-3'>
							<button className='btn btn-secondary' onClick={this.props.resetError}>
								Close
							</button>
						</div>
					}
					show={this.props.errorMessage ? true : false}
					onHide={this.props.resetError}
				/>
			</>
		)
	}
}
