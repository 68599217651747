import Lodash from 'lodash'
import {ArrayUtil} from 'helper-util'
import {globalColumnDefinition} from '../../../common/AgGrid/AgGridColumn'
import {formatWithSeparator} from '../../../utils/formatUtils'

export const detailsViewColumnDefs = (
	securityChangeHandler,
	updateAvailableQuantity,
	updateClientName,
	locateClientList
) => [
	Object.assign({}, globalColumnDefinition.ticker, {
		headerName: 'Security',
		cellRendererParams: {
			from: 'Inventory',
			onChange: securityChangeHandler,
		},
		cellRenderer: 'SecurityCellRenderer',
		minWidth: 180,
		maxWidth: 180,
		cellClass: ['d-flex align-items-center gridcellPadding ag-show-dropdown'],
	}),

	Object.assign({}, globalColumnDefinition.clientName, {
		field: 'counterPartyName',
		cellRendererParams: {updateClientName, locateClientList},
		cellRenderer: 'ClientName',
		minWidth: 200,
		cellClass: ['ag-show-dropdown lh-35'],
	}),

	Object.assign({}, globalColumnDefinition.availableQuantity, {
		cellRendererParams: {updateAvailableQuantity},
		cellRenderer: 'AvailableQuantity',
		minWidth: 200,
		cellClass: ['d-flex align-items-center justify-content-end'],
	}),
]

export const flatternInventoryDetails = securityInventory => {
	if (!ArrayUtil.isEmpty(securityInventory)) {
		return sortInventoryByTotalApprovedQty(
			securityInventory.map(securityInventory => {
				return {
					securityId: securityInventory.securityId,
					ticker: securityInventory.ticker,
					securityName: securityInventory.securityName,
					currentAvailableQuantity: securityInventory.quantity,
					price: securityInventory.closingPrice ? securityInventory.closingPrice.amount : null,
					sodAvailableQuantity: securityInventory.sodQuantity,
					totalApprovedQuantity: securityInventory.allocatedQuantity,
					totalRequestedQuantity: securityInventory.totalRequestedQuantity,
					modifiedDate: securityInventory.modifiedDate,
					tooltipData: [],
				}
			})
		)
	} else {
		return []
	}
}

export const flatternAvailabilityNeedsDetails = (securityInventory, availNeedCounterpartyDetail) => {
	if (!ArrayUtil.isEmpty(securityInventory)) {
		const clonedSecurityInventory = Lodash.cloneDeep(securityInventory)
		const selectedSecurityIndex = clonedSecurityInventory.findIndex(
			securityRow => securityRow.securityId === availNeedCounterpartyDetail.securityId
		)
		const securityRowsToBePushed: any = [
			{
				heading: 'INTERNAL',
				tableData: [
					[
						{header: 'Available Qnty', align: 'left', colSpan: '2'},
						{header: 'Rate', align: 'right'},
					],
					[
						{
							value: '-',
							align: 'left',
							colSpan: '2',
						},
						{value: '-', align: 'right'},
					],
				],
			},
			{
				heading: 'EXTERNAL',
				tableData: [
					[
						{header: 'Lender', align: 'left'},
						{header: 'Available Qtny', align: 'right'},
						{header: 'Rate', align: 'right'},
					],
				],
			},
		]

		!ArrayUtil.isEmpty(availNeedCounterpartyDetail.counterpartyQuantities) &&
			availNeedCounterpartyDetail.counterpartyQuantities.forEach(counterParty => {
				securityRowsToBePushed[1].tableData.push([
					{value: counterParty.counterparty, align: 'left'},
					{value: counterParty.quantity ? formatWithSeparator(counterParty.quantity) : null, align: 'right'},
					{value: '-', align: 'right'},
				])
			})
		if (selectedSecurityIndex > -1) {
			clonedSecurityInventory[selectedSecurityIndex].tooltipData = securityRowsToBePushed
		}
		return Lodash.cloneDeep(clonedSecurityInventory)
	}
	return []
}

export const sortInventoryByTotalApprovedQty = (securityInventory: any) => {
	if (!ArrayUtil.isEmpty(securityInventory)) {
		return Lodash.cloneDeep(
			securityInventory.sort((a, b) => {
				if (a.totalApprovedQuantity > b.totalApprovedQuantity) {
					return -1
				} else {
					return 1
				}
			})
		)
	}
	return null
}
