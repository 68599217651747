import {buildQuery} from 'axios-graphql-builder'

export const tradeDetailsEntries = {
	actions: null,
	activityType: null,
	allInRate: null,
	assetMaturityDate: null,
	assetSubType: null,
	assetType: null,
	availabilitiesContribution: {
		amount: null,
	},
	balanceSheetContribution: {
		amount: null,
		currency: null,
	},
	baseNotional: {
		amount: null,
		currency: null,
	},
	bbId: null,
	book: null,
	borrowFee: null,
	buySellInd: null,
	cash: {
		amount: null,
		currency: null,
	},
	collateral: {
		amount: null,
		currency: null,
	},
	collateralType: null,
	counterParty: null,
	createdBy: null,
	createdDate: null,
	cusip: null,
	endDate: null,
	exchangeRate: null,
	fitchRating: null,
	fund: null,
	// fxRate: null,
	haircut: null,
	hqla: null,
	id: null,
	inefficiencies: {
		inefficiency: null,
		abbr: null,
		displayName: null,
	},
	index: null,
	isin: null,
	isRestricted: null,
	legalEntity: null,
	loanFee: null,
	maturityDate: null,
	maturityType: null,
	moodyRating: null,
	needsContribution: {
		amount: null,
	},
	notional: {
		amount: null,
		currency: null,
	},
	originalTradeType: null,
	partial: null,
	poolFactor: null,
	portfolioEntryType: null,
	portfolioEntryTypeOption: null,
	price: {
		amount: null,
		currency: null,
	},
	priceMultiplier: null,
	quantity: null,
	rebate: null,
	recallInefficiencyContribution: {
		amount: null,
	},
	referenceRate: null,
	returnInefficiencyContribution: {
		amount: null,
	},
	ric: null,
	sector: null,
	securityId: null,
	securityIdType: null,
	settlementDate: null,
	snpRating: null,
	sourceSystem: null,
	sourceSystemTradeId: null,
	spread: null,
	startDate: null,
	tenor: null,
	termDate: null,
	ticker: null,
	tradeDate: null,
	tradeType: null,
}
const all = {
	query: {
		portfolio: {
			all: {
				entries: tradeDetailsEntries,
			},
			longTrades: {
				entries: tradeDetailsEntries,
			},
			shortTrades: {
				entries: tradeDetailsEntries,
			},
		},
	},
}
const allQuery = buildQuery(all)
export const graphwidgetsQuery = {
	all: allQuery,
}
