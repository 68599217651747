import lodash from 'lodash'
import {ArrayUtil} from 'helper-util'
// eslint-disable-next-line
import {selectedFilter} from './tradeDetailsModel'
import {
	routingParamKey,
	defaultDropdownItem,
	defaultGroupList,
	groupDropdownConfig,
	pivotParams,
	aggFuncSum,
} from './tradeDetailsConstant'
import {flattenRow, addRestrictedStatus} from '../../utils/featureUtils'
import {history} from '../../store/mainStore'
import {dropdownOption} from '../../constants/dashboardConstants'
import {globalColumnDefinition} from '../../common/AgGrid/AgGridColumn'
import {commaTwoDecimal, noPinnedFooter} from '../../common/AgGrid/AgGridHelper'

export const getDefaultView = viewList => {
	const state = history && history.location && history.location.state
	const selectedView = routingParamKey.selectedView
	const result =
		state && state[selectedView] && viewList[state[selectedView]]
			? viewList[state[selectedView]]
			: Object.values(viewList)[0]
	return result
}

export const getDefaultGroup = (group, defaultGroup) => {
	const state = history && history.location && history.location.state
	let result =
		state && state[group] && defaultGroupList[state[group]]
			? defaultGroupList[state[group]]
			: defaultGroupList[defaultGroup]

	if (group === routingParamKey.selectedGroupTwo) {
		const urlSearchParams = new URLSearchParams(window.location.search)
		const filterValue = urlSearchParams.get('filter')
		if (filterValue) result = dropdownOption[filterValue]
	}

	return result
}

export const getDefaultGroupItem = groupItem => {
	const state = history && history.location && history.location.state
	let result = state && state[groupItem] ? state[groupItem] : null
	if (groupItem === routingParamKey.selectedGroupTwoItem) {
		const urlSearchParams = new URLSearchParams(window.location.search)
		if (urlSearchParams.get('filterValue')) result = urlSearchParams.get('filterValue')
	}
	return result
}

export const getModifiedEntry = entry => {
	const newEntry = flattenRow(JSON.parse(JSON.stringify(entry)))
	newEntry.disableCheckbox = ArrayUtil.isEmpty(newEntry.actions)
	newEntry.actions = ArrayUtil.isEmpty(newEntry.actions) ? '' : newEntry.actions[0]
	newEntry.partial = newEntry.partial === false ? 'Full' : newEntry.partial === true ? 'Partial' : ''
	newEntry.inefficienciesArray = newEntry && newEntry.inefficiencies && newEntry.inefficiencies.map(e => e.abbr)
	newEntry.priceMultiplier = newEntry.priceMultiplier === null ? 1 : newEntry.priceMultiplier
	newEntry.notionalCurrency = newEntry && newEntry.notional && newEntry.notional.currency
	return newEntry
}

const filterBasedOnGroupItem = (portfolioId, selectedFilter: selectedFilter) => {
	const entries = portfolioId.entries
	const {groupOne, groupTwo, groupOneItem, groupTwoItem} = selectedFilter

	const filteredEntries = []
	!ArrayUtil.isEmpty(entries) &&
		entries.forEach(entry => {
			const modifiedEntry = getModifiedEntry(entry)
			let allowEntry = true
			if (groupOne && groupOne.id && groupOneItem && groupOneItem !== defaultDropdownItem) {
				allowEntry = Boolean(modifiedEntry[groupOne.id] === groupOneItem)
			}
			if (groupTwo && groupTwo.id && groupTwoItem && groupTwoItem !== defaultDropdownItem) {
				allowEntry = Boolean(allowEntry && modifiedEntry[groupTwo.id] === groupTwoItem)
			}

			if (allowEntry) {
				if (Object.keys(modifiedEntry).length > 0) filteredEntries.push(modifiedEntry)
			}
		})

	return filteredEntries
}

export const getRowData = (tradeData, selectedFilter: selectedFilter, portfolioRestrictedEntries: any) => {
	const portfolio = tradeData && tradeData.portfolio

	let rowData = []
	if (portfolio) {
		const portfolioId = portfolio[selectedFilter.view.id]
		if (portfolioId) {
			const filteredEntries = filterBasedOnGroupItem(portfolioId, selectedFilter)
			rowData = addRestrictedStatus(filteredEntries, portfolioRestrictedEntries)
		}
	}

	return rowData
}

export const getGroupItemList = (tradeData, selectedFilter: selectedFilter, groupId) => {
	const {groupOne, groupOneItem} = selectedFilter
	const groupDropdownId = Object.keys(groupDropdownConfig)[groupId]

	const groupSelectedFilter = selectedFilter[groupDropdownId]
	if (groupSelectedFilter) {
		const {label, id} = groupSelectedFilter
		const portfolio = tradeData && tradeData.portfolio
		const portfolioId = portfolio && portfolio[selectedFilter.view.id]
		const entries = portfolioId && portfolioId.entries
		if (id && entries) {
			const dropdownList = [defaultDropdownItem]
			!ArrayUtil.isEmpty(entries) &&
				entries.forEach(entry => {
					const modifiedEntry = getModifiedEntry(entry)
					if (Object.keys(modifiedEntry).length > 0) {
						let allowEntry = true
						if (groupOne && groupOneItem && groupOneItem !== defaultDropdownItem && groupId === 1) {
							allowEntry = Boolean(modifiedEntry[groupOne.id] === groupOneItem)
						}
						if (allowEntry) dropdownList.push(modifiedEntry[id])
					}
				})
			const uniqueDropdownList = Array.from(new Set(dropdownList))
			return {label, dropdownList: uniqueDropdownList}
		}
	}

	return null
}

export const getDefaultFormattedFilters = (selectedFilter: any, history: any, groupFilters: any, selectedView: any) => {
	const formattedSelectedFilter = lodash.cloneDeep(selectedFilter)
	if (lodash.isEmpty(history)) {
		formattedSelectedFilter.groupOne = groupFilters.groupOne ? groupFilters.groupOne : selectedFilter.groupOne
		formattedSelectedFilter.groupTwo = groupFilters.groupTwo ? groupFilters.groupTwo : selectedFilter.groupTwo
		formattedSelectedFilter.groupOneItem = groupFilters.groupOneItem
			? groupFilters.groupOneItem
			: selectedFilter.groupOneItem
		formattedSelectedFilter.groupTwoItem = groupFilters.groupTwoItem
			? groupFilters.groupTwoItem
			: selectedFilter.groupTwoItem
		formattedSelectedFilter.view = selectedView ? selectedView : selectedFilter.view
	}
	return formattedSelectedFilter
}

export const columnDefs: any = () => {
	return [
		Object.assign({}, globalColumnDefinition.actions, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.activityType, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.partial, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.portfolioEntryType, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.securityId, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.cusip, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.securityIdType, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.isRestricted, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.inefficienciesArray, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.balanceSheetContributionAmount, {...aggFuncSum}),
		Object.assign({}, globalColumnDefinition.lcrNotionalAmount, {...aggFuncSum}),
		Object.assign({}, globalColumnDefinition.lcrCost, {...aggFuncSum}),
		Object.assign({}, globalColumnDefinition.lcrHaircut, {...aggFuncSum}),
		Object.assign({}, globalColumnDefinition.lcrHqla, {...aggFuncSum}),
		Object.assign({}, globalColumnDefinition.assetBasedCharge, {
			...aggFuncSum,
		}),
		Object.assign({}, globalColumnDefinition.cofReferenceRate, {
			...aggFuncSum,
			...commaTwoDecimal,
			filter: true,
			filterType: 'number',
			filterParams: {
				valueFormatter: commaTwoDecimal.cellRenderer,
			},
			...noPinnedFooter,
		}),

		Object.assign({}, globalColumnDefinition.quantity, {...aggFuncSum}),
		Object.assign({}, globalColumnDefinition.priceAmount, {...aggFuncSum}),
		Object.assign({}, globalColumnDefinition.priceCurrency, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.priceMultiplier, {...aggFuncSum}),
		Object.assign({}, globalColumnDefinition.notionalCurrency, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.notionalAmount, {...aggFuncSum}),
		globalColumnDefinition.exchangeRate,
		Object.assign({}, globalColumnDefinition.baseNotionalAmount, {...aggFuncSum}),
		Object.assign({}, globalColumnDefinition.recallInefficiencyContributionAmount, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.returnInefficiencyContributionAmount, {...pivotParams}),

		Object.assign({}, globalColumnDefinition.counterParty, {...pivotParams}),
		globalColumnDefinition.haircut,
		Object.assign({}, globalColumnDefinition.collateralType, {...pivotParams}),

		Object.assign({}, globalColumnDefinition.collateralAmount, {...aggFuncSum}),
		Object.assign({}, globalColumnDefinition.referenceRate, {...pivotParams}),
		globalColumnDefinition.spread,
		globalColumnDefinition.allInRate,
		Object.assign({}, globalColumnDefinition.rebate, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.borrowFee, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.loanFee, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.tradeType, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.buySellInd, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.maturityType, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.startDate, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.endDate, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.maturityDate, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.termDate, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.tradeDate, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.settlementDate, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.createdBy, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.createdDate, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.fund, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.book, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.sourceSystem, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.sourceSystemTradeId, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.isin, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.ticker, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.originalTradeType, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.legalEntity, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.ric, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.bbId, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.assetClassification, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.assetType, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.assetSubType, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.sector, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.index, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.moodyRating, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.snpRating, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.fitchRating, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.assetMaturityDate, {...pivotParams}),
		Object.assign({}, globalColumnDefinition.poolFactor, {...pivotParams}),
	]
}
