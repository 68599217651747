import {createFetchActions} from '../../../services/createActions'
import {getPMSBaseUrl} from '../../../services/apiService'
import {get, post} from '../../../services/createAPICall'

export const GET_TRADES = createFetchActions('GET_TRADES')
export const CREATE_TRADE = createFetchActions('CREATE_TRADE')

export const getTrades = () => dispatch => {
	return dispatch(get(GET_TRADES, `${getPMSBaseUrl()}/v1/tradeBlotter`))
}

export const createTrade = (data: any) => (dispatch: any) => {
	if (!data) return dispatch({type: CREATE_TRADE.triggered})

	return dispatch(post(CREATE_TRADE, `${getPMSBaseUrl()}/v1/tradeBlotter/create `, data))
}
