export const mandatoryFields = ['buySellInd', 'collateralType', 'counterParty', 'currency', 'maturityType']

export const mandatoryFieldsForRepoSBL = [
	'buySellInd',
	'collateralType',
	'counterParty',
	'currency',
	'maturityType',
	'strategy',
	'security',
	'rateType',
	'legalEntityExternalId',
	'book',
	'allInRate',
	'schedule',
	'quantity',
]

export const mandatoryFieldForOutrightCp = [
	'strategy',
	'buySellInd',
	'counterParty',
	'security',
	'rateType',
	'legalEntityExternalId',
	'book',
	'endCash',
	'maturityType',
	'currency',
]

export const mandatoryFieldsForOutrightTsy = [
	'book',
	'buySellInd',
	'counterParty',
	'depo',
	'legalEntityExternalId',
	'price',
	'quantity',
	'strategy',
	'security',
]

export const strategyOptions = [{id: 'REPO', label: 'Repo', value: 'REPO'}]
