import gql from 'graphql-tag'

export const getMaturingTradesQuery = () => {
	return gql`
		query ($day: Int!, $screen: String) {
			matureTrades(day: $day, screen: $screen) {
				id
				cusip
				securityId
				isin
				ticker
				originalTradeType
				legalEntity
				ric
				bbId
				quantity
				maturityDate
				price {
					amount
					currency
				}
				assetType
				assetSubType
				tradeType
				book
				fund
				buySellInd
				counterParty
				sourceSystem
				sourceSystemTradeId
				allInRate
				spread
				referenceRate
				haircut
				notional {
					amount
					currency
				}
				collateral {
					amount
					currency
				}
				cash {
					amount
					currency
				}
				startDate
				endDate
				termDate
				collateralType
				maturityType
				abcmCategoryName
				abcmCategoryDisplayName
				recallInefficiencyContribution {
					amount
					currency
				}
				returnInefficiencyContribution {
					amount
					currency
				}
				tenor
				hqla
				moodyRating
				snpRating
				fitchRating
				sector
				index
				baseNotional {
					amount
					currency
				}
				basePrice {
					amount
					currency
				}
				activityType
				portfolioEntryType
				actions
				inefficiencies {
					inefficiency
					abbr
					displayName
				}
				balanceSheetContribution {
					amount
					currency
				}
				assetClassification
				exchangeRate
				priceMultiplier
			}
		}
	`
}
