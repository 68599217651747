import Lodash from 'lodash'
import memoizeOne from 'memoize-one'
import isDeepEqual from 'lodash.isequal'
import {ArrayUtil, FormatUtil, ObjectUtil} from 'helper-util'

import {pivotTableHeadings, pivotMetrics, pivotViews, pivotAggregators, columnDefs} from './abcmResultConstants'
import {strategyType} from '../orderEntry/orderEntryConstants'

export const eitherNullCheck = (row, item, item2?) => {
	const sourceItem = row.source && row.source[item]
	const useItem = row.use && row.use[item]

	if (item2) {
		return sourceItem && sourceItem[item2] ? sourceItem[item2] : useItem && useItem[item2] ? useItem[item2] : ''
	}
	return sourceItem ? sourceItem : useItem ? useItem : ''
}

export const nullCheck = (row, type, item, item2?) => {
	if (type) {
		if (item2) return row[type] && row[type][item] && row[type][item][item2] ? row[type][item][item2] : ''
		return row[type] && row[type][item] ? row[type][item] : ''
	} else {
		if (item2) return row && row[item] && row[item][item2] ? row[item][item2] : ''
		return row && row[item] ? row[item] : ''
	}
}

const getSourceValue = (item, key, accesor?) => {
	const {source} = item
	const sourceValue = source && source[key]
	if (sourceValue) return accesor ? sourceValue[accesor] : sourceValue

	return ''
}

const getUseValue = (item, key, accesor?) => {
	const {use} = item
	const useValue = use && use[key]
	if (useValue) return accesor ? useValue[accesor] : useValue

	return ''
}

const getSourceOrUseValue = (item, key, accesor?) => {
	const sourceValue = getSourceValue(item, key, accesor)
	if (sourceValue) return sourceValue

	return getUseValue(item, key, accesor)
}

export function formatPivotTableData(data, type) {
	const abcmResult =
		type === 'abcmResult' ? (data && data.abcmResult) || [] : (data && data.abcmResultCounterParty) || []
	const pivotTableData = []
	const headings = pivotTableHeadings
	pivotTableData.push(headings)
	abcmResult.forEach(item => {
		const data = []

		data.push(item.id) // id

		data.push(eitherNullCheck(item, 'assetType')) // Asset Type

		data.push(eitherNullCheck(item, 'assetSubType')) // Asset Subtype

		const balanceSheetContributionAmount = item.balanceSheetContribution && item.balanceSheetContribution.amount

		data.push(balanceSheetContributionAmount) // balanceSheetContribution
		data.push(item.duration) // duration
		data.push(item.allocatedQuantity) // Quantity

		const sourceAllocatedNotional = item.sourceAllocatedNotional && item.sourceAllocatedNotional.amount
		const useAllocatedNotional = item.useAllocatedNotional && item.useAllocatedNotional.amount

		const notional = sourceAllocatedNotional || useAllocatedNotional || 0
		data.push(notional) // notional
		data.push(sourceAllocatedNotional)
		data.push(useAllocatedNotional)
		data.push((item.source || {}).abcmCategoryDisplayName || 'To be Actioned') // source category
		data.push((item.use || {}).abcmCategoryDisplayName || 'Box') // use category
		data.push((item.source || {}).counterParty || 'To be Actioned') // source counterParty
		data.push((item.use || {}).counterParty || 'Box') // use counterParty

		data.push(eitherNullCheck(item, 'counterParty')) // counterParty
		data.push(eitherNullCheck(item, 'collateralType')) // collateralType
		data.push((item.source || {}).tenor || '') // source Tenor
		data.push((item.use || {}).tenor || '') // use tenor

		data.push(eitherNullCheck(item, 'hqla')) // hqla

		data.push(eitherNullCheck(item, 'cusip')) // cusip
		data.push(eitherNullCheck(item, 'ticker')) // ticker
		data.push(eitherNullCheck(item, 'isin')) // isin
		data.push(eitherNullCheck(item, 'securityId')) // securityId
		data.push(eitherNullCheck(item, 'isRestricted'))
		data.push(eitherNullCheck(item, 'originalTradeType')) // originalTradeType
		data.push(eitherNullCheck(item, 'legalEntity')) // legalEntity
		data.push(eitherNullCheck(item, 'ric')) // ric
		data.push(eitherNullCheck(item, 'bbId')) // bbId
		data.push(getSourceOrUseValue(item, 'price', 'currency'))
		data.push(getSourceOrUseValue(item, 'price', 'amount'))

		data.push(eitherNullCheck(item, 'sector')) // sector
		data.push(eitherNullCheck(item, 'index')) // index
		data.push(eitherNullCheck(item, 'moodyRating')) //moodyRating
		data.push(eitherNullCheck(item, 'snpRating')) // snpRating
		data.push(eitherNullCheck(item, 'fitchRating')) // fitchRating
		data.push((item.source || {}).quantity || 0) // quantity
		data.push(getSourceValue(item, 'balanceSheetContribution', 'amount')) // balanceSheetContribution
		data.push((item.source || {}).duration || 0) // duration
		data.push((item.source || {}).tradeType || 'N/A') // tradeType
		data.push((item.source || {}).book || 'N/A') // book
		data.push((item.source || {}).fund || 'N/A') // fund
		data.push((item.source || {}).allInRate || 0)
		data.push((item.source || {}).spread || 0)
		data.push((item.source || {}).referenceRate || 'N/A')
		data.push((item.source || {}).buySellInd || 'N/A')
		data.push((item.source || {}).sourceSystem || 'N/A')
		data.push((item.source || {}).sourceSystemTradeId || 'N/A')
		data.push((item.source || {}).haircut || 0)
		data.push(getSourceValue(item, 'collateral', 'amount'))
		data.push((item.source || {}).exchangeRate || 0)
		data.push(getSourceValue(item, 'notional', 'currency'))
		data.push(getSourceValue(item, 'notional', 'amount'))
		data.push(getSourceValue(item, 'baseNotional', 'currency'))
		data.push(getSourceValue(item, 'baseNotional', 'amount'))
		data.push(getSourceValue(item, 'cash', 'amount'))
		data.push((item.source || {}).maturityDate || 'N/A')
		data.push((item.source || {}).termDate || 'N/A')
		data.push((item.source || {}).startDate || 'N/A')
		data.push((item.source || {}).endDate || 'N/A')
		data.push((item.source || {}).tradeDate || 'N/A')
		data.push((item.source || {}).settlementStatus || 'N/A')
		data.push((item.source || {}).settlementDate || 'N/A')
		data.push((item.source || {}).collateralType || 'N/A')
		data.push((item.source || {}).maturityType || 'N/A')
		data.push((item.use || {}).quantity || 0) // quantity
		data.push(getUseValue(item, 'balanceSheetContribution', 'amount')) // balanceSheetContribution
		data.push((item.use || {}).duration || 0) // duration
		data.push((item.use || {}).tradeType || 'N/A') // tradeType
		data.push((item.use || {}).book || 'N/A') // book
		data.push((item.use || {}).fund || 'N/A') // fund
		data.push((item.use || {}).allInRate || 0)
		data.push((item.use || {}).spread || 0)
		data.push((item.use || {}).referenceRate || 'N/A')
		data.push((item.use || {}).buySellInd || 'N/A')
		data.push((item.use || {}).sourceSystem || 'N/A')
		data.push((item.use || {}).sourceSystemTradeId || 'N/A')
		data.push((item.use || {}).haircut || 0)
		data.push(getUseValue(item, 'collateral', 'amount'))
		data.push((item.use || {}).exchangeRate || 0)
		data.push(getUseValue(item, 'notional', 'currency'))
		data.push(getUseValue(item, 'notional', 'amount'))
		data.push(getUseValue(item, 'baseNotional', 'currency'))
		data.push(getUseValue(item, 'baseNotional', 'amount'))
		data.push(getUseValue(item, 'cash', 'amount'))
		data.push((item.use || {}).maturityDate || 'N/A')
		data.push((item.use || {}).termDate || 'N/A')
		data.push((item.use || {}).startDate || 'N/A')
		data.push((item.use || {}).endDate || 'N/A')
		data.push((item.use || {}).tradeDate || 'N/A')
		data.push((item.use || {}).settlementStatus || 'N/A')
		data.push((item.use || {}).settlementDate || 'N/A')
		data.push((item.use || {}).collateralType || 'N/A')
		data.push((item.use || {}).maturityType || 'N/A')
		data.push(item.pnl || 0)

		pivotTableData.push(data)
	})
	return pivotTableData
}

export const getPivotMetricMeasure = (item, baseCurrency = '') => {
	const currency = baseCurrency ? `(${baseCurrency})` : ''
	switch (item) {
		case pivotMetrics.notional:
		case pivotViews.margin:
		case pivotViews.notionalSource:
		case pivotViews.notionalUse:
		case pivotMetrics.balanceSheetContribution:
		case pivotMetrics.pnL:
			return currency
		case pivotMetrics.quantity:
			return ''
		case pivotMetrics.ron:
			return '(%)'
		case pivotMetrics.durationGap:
			return '(Days)'
	}
}

export const addRestrictedStatus = (data: any, restrictedEntries: any) => {
	const formattedData = Lodash.cloneDeep(data)
	formattedData &&
		formattedData.forEach((item: any) => {
			item.isRestricted =
				Array.isArray(restrictedEntries) &&
				restrictedEntries.map(entry => entry['securityId']).includes(item['Security Id'])
					? 'Yes'
					: 'No'
		})

	return formattedData
}

export const getValueFromObject = (item, key, selector) => {
	return ObjectUtil.isObject(item[key]) ? item[key][selector] : 0
}

export const csvJSON = csv => {
	const result = []
	const headers = csv[0]

	for (let i = 1; i < csv.length; i++) {
		if (!csv[i]) continue
		const obj = {}
		const currentline = csv[i]

		for (let j = 0; j < headers.length; j++) {
			obj[headers[j]] = currentline[j]
		}
		result.push(obj)
	}
	return result
}

export const calculateFormattedAggregator = (activeAggregator, abcmDisplayView) => {
	if (abcmDisplayView === pivotMetrics.ron || abcmDisplayView === pivotMetrics.durationGap) {
		return pivotAggregators[abcmDisplayView]
	} else {
		return activeAggregator
	}
}

export const getPivotConfig = (pivotTableConfig, abcmDisplayView) => {
	const isMetric = Object.keys(pivotMetrics).find(item => pivotMetrics[item] === abcmDisplayView)
	const isView = Object.keys(pivotViews).find(item => pivotViews[item] === abcmDisplayView)
	let formattedPivotConfig: any = {}
	if (isMetric) {
		formattedPivotConfig = pivotTableConfig['metric'] || {
			id: '',
			pivotTableConfig: {},
		}
	} else if (isView) {
		formattedPivotConfig = pivotTableConfig[isView] || {
			id: '',
			pivotTableConfig: {},
		}
	}
	return formattedPivotConfig
}

export const getRoutedFromValueFilters = memoizeOne((pivotTableData = [], routedFrom = null) => {
	let routedFromValueFliter = {}
	const routedFromEligibleKeys = [strategyType.SBL, FormatUtil.text.toUpperCase(strategyType.REPO)]
	if (routedFromEligibleKeys.includes(routedFrom) && ArrayUtil.getLength(pivotTableData) > 1) {
		const columnIndex = pivotTableData[0].indexOf(columnDefs.sourceTradeType)
		let routedFromValueFliterObj = {}
		pivotTableData.forEach((data, index) => {
			if (index !== 0 && data[columnIndex] !== routedFrom && !routedFromValueFliterObj[data[columnIndex]]) {
				routedFromValueFliterObj[data[columnIndex]] = true
			}
		})
		routedFromValueFliter = {
			[columnDefs.sourceTradeType]: {
				...routedFromValueFliterObj,
			},
		}
	}
	return routedFromValueFliter
}, isDeepEqual)

export const getRoutedDefaultRow = memoizeOne((pivotTableData = [], routedFrom = null) => {
	let routedFromDefaultRow = []
	const routedFromEligibleKeys = [strategyType.SBL, FormatUtil.text.toUpperCase(strategyType.REPO)]
	if (routedFromEligibleKeys.includes(routedFrom) && ArrayUtil.getLength(pivotTableData) > 1) {
		routedFromDefaultRow.push(columnDefs.sourceTradeType)
	}
	return routedFromDefaultRow
}, isDeepEqual)
