import {GET_SETTLEMENT_DETAILS_DATA, CLEAR_SETTLEMENT_DETAILS_DATA} from './settlementLadderDetailsActions'

export interface SettlementDetailsData {
	loading: boolean
	data: any[]
}

const initialState: SettlementDetailsData = {
	loading: true,
	data: [],
}

export function settlementDetailsDataReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_SETTLEMENT_DETAILS_DATA.triggered:
			return {...state, loading: true}
		case GET_SETTLEMENT_DETAILS_DATA.succeeded:
			const data = (action.response.data && action.response.data.settlementDetails) || []
			return {...state, loading: false, data}
		case GET_SETTLEMENT_DETAILS_DATA.failed:
			return {...state, loading: false}

		case CLEAR_SETTLEMENT_DETAILS_DATA:
			return {...initialState}
	}

	return state
}
