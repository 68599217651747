import gql from 'graphql-tag'
import {getCounterpartyId} from '../../../services/authService'

export const getTenantSummaryQuery = () => gql`
	query {
		summaryStats(tenantId: "${getCounterpartyId()}") {
			legalEntityCount
			clientCount
			userCount
			internalGroupCount
			externalGroupCount
			lastUpdated
			tenantId
		}
	}
`
