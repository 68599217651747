import {
	GET_PB_BASKET_DETAILS,
	UPLOAD_PB_BASKET_DETAILS,
	REQUEST_PB_LOAN_DETAILS,
	GET_PB_DROPDOWN_DATA,
	FILTER_PB_BASKET,
	SUBMIT_PB_BASKET,
	GET_PB_BASKET_FILE_DATA,
	SUBMIT_PB_MATCHING_ALGORITHM,
	GET_PB_BASKET_OVERVIEW,
	SUBMIT_PB_LOAN,
	SUBMIT_PB_LOAN_ENTRIES,
	DELETE_PB_BASKET_FILE,
	DOWNLOAD_PB_BASKET,
	FETCH_PB_ACTIVITY_LOG,
	GET_PB_PENDING_TARGET,
	UPDATE_PB_PENDING_TARGET,
	FETCH_PB_LOCATE_SEARCH_RESULT,
	FETCH_PB_LOCATE_SEARCH_OPENED,
	SET_PB_DATA_FROM_INDEX_ANALYSIS,
	RESET_PB_NEEDS_AVAILABILITY_DATA,
} from './pbNeedsAvailabilitiesActions'

export interface PbNeedsAvailabilities {
	pageData: any
	loanData: any
	dropdownData: any
	basketEntriesData: any
	basketFileData: any
	loading: boolean
	dataLoading: boolean
	basketOverview: any
	activityLog: any[]
	lendingTarget: number
	locateSearchData: any[]
	indexAnalysisData: any[]
	locateSearchId: any
}

const initialState: PbNeedsAvailabilities = {
	pageData: {},
	loanData: null,
	dropdownData: {},
	basketEntriesData: {},
	basketFileData: {},
	loading: false,
	dataLoading: false,
	basketOverview: {},
	activityLog: [],
	lendingTarget: 0,
	locateSearchData: [],
	indexAnalysisData: [],
	locateSearchId: '',
}

export function pbNeedsAvailabilitiesReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_PB_BASKET_DETAILS.triggered:
			return {...state, dataLoading: true}
		case GET_PB_BASKET_DETAILS.failed:
			return {...state, dataLoading: false}
		case GET_PB_BASKET_DETAILS.succeeded:
			state.pageData = action.response.data
			return {
				...state,
				dataLoading: false,
			}
		case UPLOAD_PB_BASKET_DETAILS.triggered:
			return {...state}
		case UPLOAD_PB_BASKET_DETAILS.failed:
			return {...state}
		case UPLOAD_PB_BASKET_DETAILS.succeeded:
			return {...state}

		case REQUEST_PB_LOAN_DETAILS.triggered:
			return {...state, loanData: null}
		case REQUEST_PB_LOAN_DETAILS.failed:
			return {...state, loanData: {}}
		case REQUEST_PB_LOAN_DETAILS.succeeded:
			return {...state, loanData: action.response.data}

		case FILTER_PB_BASKET.triggered:
			return {...state}
		case FILTER_PB_BASKET.failed:
			return {...state, basketEntriesData: {}}
		case FILTER_PB_BASKET.succeeded:
			return {
				...state,
				basketEntriesData: action.response.data,
			}
		case SUBMIT_PB_BASKET.triggered:
			return {...state}
		case SUBMIT_PB_BASKET.failed:
			return {...state}
		case SUBMIT_PB_BASKET.succeeded:
			return {...state}
		case GET_PB_DROPDOWN_DATA.triggered:
			return {...state}
		case GET_PB_DROPDOWN_DATA.failed:
			return {...state}
		case GET_PB_DROPDOWN_DATA.succeeded:
			return {...state, dropdownData: action.response.data}
		case GET_PB_BASKET_FILE_DATA.triggered:
			return {...state}
		case GET_PB_BASKET_FILE_DATA.failed:
			return {...state}
		case GET_PB_BASKET_FILE_DATA.succeeded:
			return {
				...state,
				basketFileData: action.response.data,
			}
		case SUBMIT_PB_MATCHING_ALGORITHM.triggered:
			return {...state}
		case SUBMIT_PB_MATCHING_ALGORITHM.failed:
			return {...state}
		case SUBMIT_PB_MATCHING_ALGORITHM.succeeded:
			return {
				...state,
			}
		case GET_PB_BASKET_OVERVIEW.triggered:
			return {...state}
		case GET_PB_BASKET_OVERVIEW.failed:
			return {...state}
		case GET_PB_BASKET_OVERVIEW.succeeded:
			return {...state, basketOverview: action.response.data.portfolio.overView}
		case SUBMIT_PB_LOAN.triggered:
			return {...state}
		case SUBMIT_PB_LOAN.failed:
			return {...state}
		case SUBMIT_PB_LOAN.succeeded:
			return {...state}
		case SUBMIT_PB_LOAN_ENTRIES.triggered:
			return {...state}
		case SUBMIT_PB_LOAN_ENTRIES.failed:
			return {...state}
		case SUBMIT_PB_LOAN_ENTRIES.succeeded:
			return {...state}
		case DELETE_PB_BASKET_FILE.triggered:
			return {...state}
		case DELETE_PB_BASKET_FILE.failed:
			return {...state}
		case DELETE_PB_BASKET_FILE.succeeded:
			return {...state}
		case DOWNLOAD_PB_BASKET.triggered:
			return {...state}
		case DOWNLOAD_PB_BASKET.failed:
			return {...state}
		case DOWNLOAD_PB_BASKET.succeeded:
			return {...state}
		case FETCH_PB_ACTIVITY_LOG.triggered:
			return {...state, activityLog: []}
		case FETCH_PB_ACTIVITY_LOG.succeeded:
			const activityLog = action.response.data && action.response.data.availNeedOrderLogs
			return {...state, activityLog}
		case FETCH_PB_ACTIVITY_LOG.failed:
			return {...state}
		case GET_PB_PENDING_TARGET.triggered:
			return {...state}
		case GET_PB_PENDING_TARGET.failed:
			return {...state}
		case GET_PB_PENDING_TARGET.succeeded:
			return {...state, lendingTarget: action.response.data}
		case UPDATE_PB_PENDING_TARGET.triggered:
			return {...state}
		case UPDATE_PB_PENDING_TARGET.failed:
			return {...state}
		case UPDATE_PB_PENDING_TARGET.succeeded:
			return {...state}
		case FETCH_PB_LOCATE_SEARCH_RESULT.triggered:
			return {...state}
		case FETCH_PB_LOCATE_SEARCH_RESULT.succeeded:
			state.locateSearchData = action.response.data && action.response.data.searchAvailabilitySecurityResults
			return {...state}
		case FETCH_PB_LOCATE_SEARCH_OPENED:
			state.locateSearchData = []
			state.indexAnalysisData = []
			return {...state}
		case SET_PB_DATA_FROM_INDEX_ANALYSIS:
			state.indexAnalysisData = action.payload ? action.payload : []
			return {...state}

		case RESET_PB_NEEDS_AVAILABILITY_DATA:
			return {...initialState}
	}
	return state
}
