import {createFetchActions} from '../../services/createActions'
import {post} from '../../services/createAPICall'
import {getPMSBaseUrl} from '../../services/apiService'
export const GET_REPORTS_DATA_SYNCFUSION = createFetchActions('GET_REPORTS_DATA_SYNCFUSION')
export const SAVE_SF_REPORTS = createFetchActions('SAVE_SF_REPORTS')
export const GET_SF_REPORTS = createFetchActions('GET_SF_REPORTS')
export const DELETE_SF_REPORTS = createFetchActions('DELETE_SF_REPORTS')

export const getReportsData = user => (dispatch: any) => {
	return dispatch(
		post(
			GET_REPORTS_DATA_SYNCFUSION,
			`${getPMSBaseUrl()}/getallportdata`,
			user,
			null,
			'Error occured while getting all tables data'
		)
	)
}
export const getSFReports = user => (dispatch: any) => {
	return dispatch(
		post(
			GET_SF_REPORTS,
			//`http://localhost:8080/postdata`,
			`${getPMSBaseUrl()}/getfmcfg`,
			user,
			null,
			'Error occured while getting syncfusion reports data'
		)
	)
}
export const saveSFReports = widget => (dispatch: any) => {
	return dispatch(
		post(
			SAVE_SF_REPORTS,
			//`http://localhost:8080/postdata`,
			`${getPMSBaseUrl()}/savefmcfg`,
			widget,
			null,
			'Error occured while saving Syncfusion report data'
		)
	)
}
export const deleteSFReports = user => (dispatch: any) => {
	return dispatch(
		post(
			DELETE_SF_REPORTS,
			//`http://localhost:8080/postdata`,
			`${getPMSBaseUrl()}/deletefmcfg`,
			user,
			null,
			'Error occured while getting reports data'
		)
	)
}
