import {
	FETCH_MY_BOOKS_DETAILS,
	FETCH_SECURITY_DETAILS,
	FETCH_LEGAL_ENTITY,
	RESET_TRADE_TICKET_DATA,
} from './TradingTicketActions'

export interface TradingTicket {
	isLoading: boolean
	legalEntities: any[]
	myBooks: any[]
	tradeTicketSecurityDetails: any
}

const initialState: TradingTicket = {
	isLoading: false,
	legalEntities: [],
	myBooks: [],
	tradeTicketSecurityDetails: {},
}

export function tradingTicketReducer(state = initialState, action: any) {
	switch (action.type) {
		case RESET_TRADE_TICKET_DATA:
			return {...state, loading: true, tradeTicketSecurityDetails: {}}

		case FETCH_SECURITY_DETAILS.triggered:
			return {...state, isLoading: true}
		case FETCH_SECURITY_DETAILS.succeeded:
			return {
				...state,
				tradeTicketSecurityDetails: action.response.data.tradeTicketSecurityDetails,
				isLoading: false,
			}
		case FETCH_SECURITY_DETAILS.failed:
			return {...state, isLoading: false}
		case FETCH_MY_BOOKS_DETAILS.triggered:
			return {...state, isLoading: true}
		case FETCH_MY_BOOKS_DETAILS.succeeded:
			return {...state, myBooks: action.response.data.myBooks, isLoading: false}
		case FETCH_MY_BOOKS_DETAILS.failed:
			return {...state, isLoading: false}
		case FETCH_LEGAL_ENTITY.triggered:
			return {...state, isLoading: true}
		case FETCH_LEGAL_ENTITY.succeeded:
			return {...state, legalEntities: action.response.data.allLegalEntities, isLoading: false}
		case FETCH_LEGAL_ENTITY.failed:
			return {...state, isLoading: false}
	}
	return state
}
