import React from 'react'
import lodash from 'lodash'
import {ArrayUtil, FormatUtil} from 'helper-util'

export const tableHeaderClassName = () => {
	return 'th-align-right'
}

export const tableHeaderWithTitle = (headerText, title?: string) => {
	return <span title={title || headerText}>{headerText}</span>
}

export const getHeaderValue = column => {
	return column.Header && column.Header.props && column.Header.props.children
}

export const updateColumnDefination = (columnDefination: any[], updateItem: any) => {
	const newColumnDefination = lodash.cloneDeep(columnDefination)
	const {baseCurrency} = updateItem

	if (baseCurrency) {
		newColumnDefination.forEach(item => {
			const header = getHeaderValue(item)
			if (item.accessor === 'baseNotional') item.Header = tableHeaderWithTitle(`${header} (${baseCurrency})`)
			if (item.accessor === 'baseNotional.amount') item.Header = tableHeaderWithTitle(`${header} (${baseCurrency})`)
			if (item.accessor === 'baseNotionalAmount') item.Header = tableHeaderWithTitle(`${header} (${baseCurrency})`)

			if (item.accessor === 'exchangeRate') item.Header = tableHeaderWithTitle(`${header} (${baseCurrency})`)
			// if (item.accessor === 'fxRate') item.Header = tableHeaderWithTitle(`${header} (${baseCurrency})`)
		})
		return newColumnDefination
	}

	return newColumnDefination
}

export const updateColumnDefinationForABCMDetail = (columnDefination: any[], updateItem: any) => {
	const newColumnDefination = lodash.cloneDeep(columnDefination)
	const {baseCurrency} = updateItem

	if (baseCurrency) {
		newColumnDefination.forEach(item => {
			const {columns} = item
			!ArrayUtil.isEmpty(columns) &&
				columns.forEach(childItem => {
					if (childItem.Header === 'Base Notional Amount') childItem.Header += ` (${baseCurrency})`
				})
		})
		return newColumnDefination
	}

	return newColumnDefination
}

export const updateCsvHeaderForABCMDetail = (header: any[], updateItem: any) => {
	const newHeader = lodash.cloneDeep(header)
	const {baseCurrency} = updateItem

	if (baseCurrency) {
		newHeader.forEach(item => {
			if (item.key === 'sourceBaseNotionalAmount' || item.key === 'useBaseNotionalAmount') {
				item.label += ` (${baseCurrency})`
			}
		})
		return newHeader
	}

	return newHeader
}

export const defaultSortMethodUtil = (a, b, desc) => {
	// force null and undefined to the bottom
	a = a === null || a === undefined ? '' : a
	b = b === null || b === undefined ? '' : b
	// force any string values to lowercase
	a = typeof a === 'string' ? FormatUtil.text.toLowerCase(a) : a
	b = typeof b === 'string' ? FormatUtil.text.toLowerCase(b) : b
	// Return either 1 or -1 to indicate a sort priority
	if (a > b) {
		return 1
	}
	if (a < b) {
		return -1
	}
	// returning 0, undefined or any falsey value will use subsequent sorts or
	// the index as a tiebreaker
	return 0
}
