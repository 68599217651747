import {
	FETCH_AUTHORIZER_TRADES,
	CLEAR_MAIN_GRID_DATA,
	ALTER_RATE_FIELD_DATA_AUTHORIZER,
} from './AlpSttAuthorizerActions'
import {alterRateField, dataMassagingFn} from './AlpSttAuthorizerHelper'

export interface AlpSttAuthorizer {
	loading: boolean
	gridLoading: boolean
	gridData: any
}

const initialState: AlpSttAuthorizer = {
	loading: false,
	gridLoading: false,
	gridData: null,
}

export function AlpSttAuthorizerReducer(state = initialState, action: any) {
	switch (action.type) {
		case FETCH_AUTHORIZER_TRADES.triggered:
			return {...state, gridData: null, gridLoading: true}
		case FETCH_AUTHORIZER_TRADES.failed:
			return {...state, gridData: null, gridLoading: false}
		case FETCH_AUTHORIZER_TRADES.succeeded:
			return {...state, gridData: dataMassagingFn(action.response && action.response.data), gridLoading: false}

		case CLEAR_MAIN_GRID_DATA:
			return {
				...state,
				gridData: null,
			}

		case ALTER_RATE_FIELD_DATA_AUTHORIZER:
			return {
				...state,
				gridData: alterRateField(state.gridData, action.payload.toggleMode),
			}
	}
	return state
}
