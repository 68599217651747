import Lodash from 'lodash'
import {SET_USER, FORCED_LOGOUT, GET_TENANT_CONFIG, CLEAR_TENANT_CONFIG} from './loginActions'

export interface Login {
	clientConfig: any
	forcedLogout: boolean
	loading: boolean
	tenantConfig: any
	user: any
}

const initialState: Login = {
	clientConfig: {},
	forcedLogout: false,
	loading: false,
	tenantConfig: null,
	user: {},
}

export function loginReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_TENANT_CONFIG.triggered:
			return {...state, tenantConfig: null, loading: true}
		case GET_TENANT_CONFIG.succeeded:
			const tenantConfig = Lodash.cloneDeep(action.response.data)
			return {...state, tenantConfig, loading: false}

		case GET_TENANT_CONFIG.failed:
			return {...state, tenantConfig: null, loading: false}
		case CLEAR_TENANT_CONFIG:
			return {...state, tenantConfig: null}

		case SET_USER:
			return {...state, user: action.payload.data}

		case FORCED_LOGOUT:
			return {...state, forcedLogout: !state.forcedLogout}
	}

	return state
}
