export const AllowedDurationValue = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]

export const BorrowersRadioList = [
	{
		id: 'SELECTIVE',
		value: 'SELECTIVE',
		label: '',
	},
	{
		id: 'ALL',
		value: 'ALL',
		label: 'All Borrowers',
	},
]

export const BroadcastConfirmationMsg = 'Are you sure you want to Broadcast ?'
export const CancelConfirmationMsg = 'Are you sure you want to Cancel ?'
export const RejectConfirmationMsg = 'Are you sure you want to Reject ?'
export const RejectBidsConfirmationMsg = 'Are you sure you want to Reject bids ?'
export const ReleaseBidsConfirmationMsg = 'Are you sure you want to Release bids ?'

export const QUEUE = 'Queue'

export enum InventorySourceType {
	Discretionary = 'discretionary',
	PreApprovedClients = 'preApprovedClients',
}

export enum InventorySourceLabel {
	Discretionary = 'Discretionary',
	PreApprovedClients = 'Pre-Approval Clients',
}

export enum AuctionMode {
	LIVE = 'LIVE',
	STAGED = 'STAGED',
	CREATE = 'CREATE',
}

export enum ActionButtonType {
	BROADCAST = 'BROADCAST',
	STAGE = 'STAGE',
	CANCEL = 'CANCEL',
	REJECT = 'REJECT',
	REJECT_BIDS = 'REJECT_BIDS',
	RELEASE_BIDS = 'RELEASE_BIDS',
}

export const BpsToggleEligibleFields = ['reservePrice', 'bidIncrement']

export const MandatoryFields = [
	'selectedSecurity',
	'selectedInventorySource',
	'selectedSetLoc',
	'selectedReclaimRate',
	'selectedCollType',
	'selectedCollCurr',
	'auctionQty',
	'bidIncrement',
	'borrowerTypeSelectedId',
]
