import {
	GET_PRODUCTS,
	CREATE_COLLATERAL_BASKET,
	SAVE_COLLATERAL_BASKET,
	GET_COLLATERAL_BASKET_ITEMS,
	UPDATE_COLLATERAL_BASKET_ITEM,
	ADD_COLLATERAL_BASKET_ITEM,
	DELETE_COLLATERAL_BASKET_ITEM,
	GET_STRATEGIES,
	DELETE_COLLATERAL_BASKET,
	GET_COLLATERAL_BASKET_CONSTRAINTS,
	CLEAR_SEARCH_DATA,
	FETCH_COLLATERAL_SCHEDULE_CLIENTS,
	GET_TEMPLATE_BY_CLIENT,
	GET_BOXES_ENRICH_DETAILS,
	GET_BOXES_ENRICH_DETAILS_FOR_SCHEDULE,
} from './collateralBasketActions'

import {Product, SectorsIndices, Strategies} from './collateralBasketModel'

export interface CollateralBasket {
	assets: []
	collateralData: any
	collateralBasketConstraints: any
	collateralScheduleClients: any
	collateralTemplateClients: any
	dataNow: any
	allBoxEntries: any[]
	allBoxEntriesForSchedule: any[]
	funds: []
	isFormSaved: boolean
	loading: boolean
	products: Product[]
	strategies: Strategies[]
	sectorsIndices: SectorsIndices
}

const initialState: CollateralBasket = {
	assets: [],
	collateralData: {},
	collateralBasketConstraints: null,
	collateralScheduleClients: [],
	collateralTemplateClients: [],
	dataNow: null,
	allBoxEntries: [],
	allBoxEntriesForSchedule: [],
	funds: [],
	isFormSaved: false,
	loading: false,
	products: [],
	strategies: [],
	sectorsIndices: {sectors: [], indices: []},
}

export function collateralBasketReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_PRODUCTS.succeeded:
			return {...state, loading: false, products: action.response.data}

		case GET_COLLATERAL_BASKET_CONSTRAINTS.succeeded:
			return {
				...state,
				loading: false,
				collateralBasketConstraints: action.response.data.collateralBasketDropDowns,
				assets: (action.response.data.collateralBasketDropDowns || {}).assetTypes,
				sectorsIndices: {
					sectors: (action.response.data.collateralBasketDropDowns || {}).sectors,
					indices: (action.response.data.collateralBasketDropDowns || {}).indices,
				},
				funds: (action.response.data.collateralBasketDropDowns || {}).funds,
			}

		case GET_STRATEGIES.triggered:
			return {...state, loading: true}
		case GET_STRATEGIES.succeeded:
			return {...state, loading: false, strategies: action.response.data.strategies}
		case GET_STRATEGIES.failed:
			return {...state, loading: true}

		case GET_BOXES_ENRICH_DETAILS.triggered:
			return {...state, loading: true}
		case GET_BOXES_ENRICH_DETAILS.failed:
			return {...state, loading: false}
		case GET_BOXES_ENRICH_DETAILS.succeeded:
			const response = action.response.data
			const allBoxEntries =
				(response.portfolio &&
					response.portfolio.boxesEnrichDetails &&
					response.portfolio.boxesEnrichDetails.entries) ||
				[]
			return {
				...state,
				allBoxEntries,
				loading: false,
			}

		case GET_BOXES_ENRICH_DETAILS_FOR_SCHEDULE.triggered:
			return {...state, loading: true}
		case GET_BOXES_ENRICH_DETAILS_FOR_SCHEDULE.failed:
			return {...state, loading: false}
		case GET_BOXES_ENRICH_DETAILS_FOR_SCHEDULE.succeeded:
			let boxEntries = action.response.boxesEnrichDetailsForSchedule.entries || []
			return {
				...state,
				allBoxEntriesForSchedule: boxEntries,
				loading: false,
			}

		case CREATE_COLLATERAL_BASKET.triggered:
			return {...state, loading: true, dataNow: null}
		case CREATE_COLLATERAL_BASKET.succeeded:
			return {...state, loading: false, dataNow: action.response.data.allocatedItems}
		case CREATE_COLLATERAL_BASKET.failed:
			return {...state, loading: false, dataNow: []}

		case SAVE_COLLATERAL_BASKET.triggered:
			return {...state, loading: true}
		case SAVE_COLLATERAL_BASKET.succeeded:
			const collateralData = action.response.data || {}
			return {...state, loading: false, isFormSaved: true, collateralData}
		case SAVE_COLLATERAL_BASKET.failed:
			return {...state, loading: false}

		case DELETE_COLLATERAL_BASKET.triggered:
			return {...state, loading: true}
		case DELETE_COLLATERAL_BASKET.succeeded || DELETE_COLLATERAL_BASKET.failed:
			return {...state, loading: false}

		case GET_COLLATERAL_BASKET_ITEMS.triggered:
			return {...state, dataNow: null, loading: true}
		case GET_COLLATERAL_BASKET_ITEMS.succeeded:
			return {
				...state,
				dataNow: ((action.response.data || {}).collateralBasket || {}).collateralBasketItems,
				collateralData: action.response.data || {},
				loading: false,
			}
		case GET_COLLATERAL_BASKET_ITEMS.failed:
			return {...state, dataNow: [], loading: false}

		case UPDATE_COLLATERAL_BASKET_ITEM.triggered:
			return {...state, loading: true}
		case UPDATE_COLLATERAL_BASKET_ITEM.succeeded || UPDATE_COLLATERAL_BASKET_ITEM.failed:
			return {...state, loading: false}

		case ADD_COLLATERAL_BASKET_ITEM.triggered:
			return {...state, loading: true}
		case ADD_COLLATERAL_BASKET_ITEM.succeeded || ADD_COLLATERAL_BASKET_ITEM.failed:
			return {...state, loading: false}

		case DELETE_COLLATERAL_BASKET_ITEM.triggered:
			return {...state, loading: true}
		case DELETE_COLLATERAL_BASKET_ITEM.succeeded || DELETE_COLLATERAL_BASKET_ITEM.failed:
			return {...state, loading: false}

		case CLEAR_SEARCH_DATA:
			return {...state, dataNow: [], collateralData: {}}

		case FETCH_COLLATERAL_SCHEDULE_CLIENTS.triggered || FETCH_COLLATERAL_SCHEDULE_CLIENTS.failed:
			return {...state}
		case FETCH_COLLATERAL_SCHEDULE_CLIENTS.succeeded:
			return {
				...state,
				collateralScheduleClients: action.response,
			}

		case GET_TEMPLATE_BY_CLIENT.triggered || GET_TEMPLATE_BY_CLIENT.failed:
			return {...state}
		case GET_TEMPLATE_BY_CLIENT.succeeded:
			return {
				...state,
				collateralTemplateClients: action.response,
			}
	}
	return state
}
