import {buildQuery} from 'axios-graphql-builder'

export const getSBLReRateDetailQuery = () => {
	const reRateQuery = {
		query: {
			sblDashboard: {
				reRate: {
					borrowThreshold: null,
					loanThreshold: null,
					entries: {
						actions: null,
						abcmCategoryName: null,
						activityType: null,
						allInRate: null,
						assetClassification: null,
						assetMaturityDate: null,
						assetSubType: null,
						assetType: null,
						availabilitiesContribution: {
							amount: null,
							currency: null,
						},
						availableQuantity: null,
						balanceSheetContribution: {
							amount: null,
							currency: null,
						},
						baseNotional: {
							amount: null,
							currency: null,
						},
						basePrice: {
							amount: null,
							currency: null,
						},
						bbId: null,
						book: null,
						borrowFee: null,
						loanFee: null,
						buySellInd: null,
						collateralType: null,
						collateral: {
							amount: null,
							currency: null,
						},
						counterParty: null,
						createdBy: null,
						createdDate: null,
						cusip: null,
						effectiveBaseNotional: {
							amount: null,
							currency: null,
						},
						deviation: null,
						endDate: null,
						exchangeRate: null,
						feeRate: null,
						fitchRating: null,
						fund: null,
						haircut: null,
						hqla: null,
						id: null,
						inefficiencies: {
							inefficiency: null,
							abbr: null,
							displayName: null,
						},
						index: null,
						isin: null,
						marketClassification: null,
						maturityDate: null,
						maturityType: null,
						moodyRating: null,
						needQuantity: null,
						notional: {
							amount: null,
							currency: null,
						},
						poolFactor: null,
						portfolioEntryType: null,
						partial: null,
						platformTradeId: null,
						price: {
							amount: null,
							currency: null,
						},
						priceMultiplier: null,
						quantity: null,
						rebate: null,
						recallInefficiencyContribution: {
							amount: null,
							currency: null,
						},
						recallQuantity: null,
						referenceRate: null,
						returnInefficiencyContribution: {
							amount: null,
							currency: null,
						},
						returnQuantity: null,
						needsContribution: {
							amount: null,
							currency: null,
						},
						ric: null,
						sector: null,
						securityId: null,
						securityIdType: null,
						settlementDate: null,
						snpRating: null,
						sourceSystem: null,
						sourceSystemTradeId: null,
						spread: null,
						startDate: null,
						tenor: null,
						termDate: null,
						tradeDate: null,
						ticker: null,
						tradeType: null,
					},
				},
			},
		},
	}

	return buildQuery(reRateQuery)
}
export const getRepoReRateDetailQuery = () => {
	const reRateQuery = {
		query: {
			repoDashboard: {
				reRate: {
					repoThreshold: null,
					reverseThreshold: null,
					entries: {
						actions: null,
						abcmCategoryName: null,
						activityType: null,
						allInRate: null,
						assetClassification: null,
						assetMaturityDate: null,
						assetSubType: null,
						assetType: null,
						availabilitiesContribution: {
							amount: null,
							currency: null,
						},
						availableQuantity: null,
						balanceSheetContribution: {
							amount: null,
							currency: null,
						},
						baseNotional: {
							amount: null,
							currency: null,
						},
						basePrice: {
							amount: null,
							currency: null,
						},
						bbId: null,
						book: null,
						borrowFee: null,
						loanFee: null,
						buySellInd: null,
						collateralType: null,
						collateral: {
							amount: null,
							currency: null,
						},
						counterParty: null,
						createdBy: null,
						createdDate: null,
						cusip: null,
						effectiveBaseNotional: {
							amount: null,
							currency: null,
						},
						deviation: null,
						endDate: null,
						exchangeRate: null,
						feeRate: null,
						fitchRating: null,
						fund: null,
						haircut: null,
						hqla: null,
						id: null,
						inefficiencies: {
							inefficiency: null,
							abbr: null,
							displayName: null,
						},
						index: null,
						isin: null,
						marketClassification: null,
						maturityDate: null,
						maturityType: null,
						moodyRating: null,
						needQuantity: null,
						notional: {
							amount: null,
							currency: null,
						},
						poolFactor: null,
						portfolioEntryType: null,
						partial: null,
						platformTradeId: null,
						price: {
							amount: null,
							currency: null,
						},
						priceMultiplier: null,
						quantity: null,
						rebate: null,
						recallInefficiencyContribution: {
							amount: null,
							currency: null,
						},
						recallQuantity: null,
						referenceRate: null,
						returnInefficiencyContribution: {
							amount: null,
							currency: null,
						},
						returnQuantity: null,
						needsContribution: {
							amount: null,
							currency: null,
						},
						ric: null,
						sector: null,
						securityId: null,
						securityIdType: null,
						settlementDate: null,
						snpRating: null,
						sourceSystem: null,
						sourceSystemTradeId: null,
						spread: null,
						startDate: null,
						tenor: null,
						termDate: null,
						tradeDate: null,
						ticker: null,
						tradeType: null,
					},
				},
			},
		},
	}

	return buildQuery(reRateQuery)
}
