import {FormatUtil} from 'helper-util'
import gql from 'graphql-tag'
import {buildQuery} from 'axios-graphql-builder'

import {QueryConfig} from './collateralBasketModel'

export const sectorsIndicesQuery = gql`
	query {
		distinctSectors
		indices
	}
`

export const collateralBasketDropDownsQuery = gql`
	{
		collateralBasketDropDowns {
			assetTypes
			assetSubTypes
			indices
			funds
			ratings
			sectors
		}
	}
`

export const strategiesQuery = () => gql`
	query {
		strategies {
			displayName
			code
		}
	}
`

export const securitySuggestionsQuery = (keyword: string, config = {} as QueryConfig) => gql`{
  securitiesWithConstraints(keyword: "${FormatUtil.text.toUpperCase(keyword)}",
  config: {
    index: ${JSON.stringify(config.index)},
    includeDividendsPayingInNext10Days: ${JSON.stringify(config.includeDividendsPayingInNext10Days)},
    sector: ${JSON.stringify(config.sector)},
    includeSpecialAndHTB: ${JSON.stringify(config.includeSpecialAndHTB)},
    minimumRating: ${JSON.stringify(config.minimumRating)},
    assetTypes: ${JSON.stringify(config.assetTypes)},
    includeETF:  ${JSON.stringify(config.includeETF)},
    currency:${JSON.stringify(config.currency)},
    includeRestrictedSecurities:${JSON.stringify(config.includeRestrictedSecurities)}
  }) {
    assetClassification
    assetType
    cusip
    feeRate
    fitchRating
    isin
    moodyRating
    longName
    price
    priceMultiplier
    rebateRate
    securityId
    snpRating
  }
}
`
export const enrichedSecuritySuggestionsQuery = (keyword: string, config = {} as QueryConfig) => gql`{
  enrichedSecuritiesWithConstraints(keyword: "${FormatUtil.text.toUpperCase(keyword)}",
  config: {
    index: ${JSON.stringify(config.index)},
    includeDividendsPayingInNext10Days: ${JSON.stringify(config.includeDividendsPayingInNext10Days)},
    sector: ${JSON.stringify(config.sector)},
    includeSpecialAndHTB: ${JSON.stringify(config.includeSpecialAndHTB)},
    minimumRating: ${JSON.stringify(config.minimumRating)},
    assetTypes: ${JSON.stringify(config.assetTypes)},
    includeETF:  ${JSON.stringify(config.includeETF)},
    currency:${JSON.stringify(config.currency)},
    includeRestrictedSecurities:${JSON.stringify(config.includeRestrictedSecurities)},
    schedule:${JSON.stringify(config.schedule)}
  }) {
    assetClassification
    assetType
    cusip
    feeRate
    fitchRating
    isin
    moodyRating
    longName
    price
    priceMultiplier
    rebateRate
    securityId
    snpRating
    cusipCode
    schedule
    scheduleDescription
    margin
    securityDescription
    ticker
    currency
  }
}
`

export const inventory = (marketSecurityIds: string[]) => gql`
  {
    inventory(marketSecurityIds: "${marketSecurityIds}") {
      availableNotional
      inefficiencies {
        inefficiency
        abbr
        displayName
      }
      quantity
      securityId
      ticker
    }
  }
`
export const fetchCollateralScheduleClients = () => {
	const scheduleClients = {
		query: {
			collateralScheduleDropDowns: {
				clientDetails: null,
			},
		},
	}
	return buildQuery(scheduleClients)
}

export const getTemplateByClientQuery = client => {
	const paramList = [{key: 'templateByClient', param: `clients:["${client}"]`}]
	const getTemplateByClient = {
		query: {
			templateByClient: {
				templateId: null,
				templateType: null,
				templateSubType: null,
			},
		},
	}
	return buildQuery(getTemplateByClient, paramList)
}

export const getBoxesEnrichDetailsQuery = () => gql`
	{
		portfolio {
			boxesEnrichDetails {
				entries {
					securityId
					minPiece
					minIncrement
					ticker
					cusip
					isin
					price
					priceMultiplier
					classification
					rating
					assetType
					assetMaturityDate
					index
					sector
					fund
					quantity
					shortFallQuantity
					availableQuantity
					availableNotional
					rebateRate
					feeRate
					cusipCode
					schedule
					scheduleDescription
					securityMarginPercentage
					securityDescription
					securityConcentration
					maxPercentOfOutstandingAmount
					totalAmountOutstanding
					fitchRating
					maturity
					moodyRating
					minPiece
					minIncrement
					dirtyPrice {
						amount
						currency
					}
					startCash {
						amount
						currency
					}
					allocationInefficiencies {
						inefficiency
						abbr
						displayName
					}
				}
			}
		}
	}
`

export const getBoxesEnrichDetailsForScheduleQuery = (schedule, book: string = null, clientRiskType = null) => {
	const paramList = [
		{
			key: 'boxesEnrichDetailsForSchedule',
			param: `schedule: ${JSON.stringify(schedule)},book: ${JSON.stringify(book)},clientRiskType: ${JSON.stringify(
				clientRiskType
			)}`,
		},
	]

	const getTemplateByClient = {
		query: {
			boxesEnrichDetailsForSchedule: {
				entries: {
					minLotSize: null,
					securityId: null,
					ticker: null,
					cusip: null,
					isin: null,
					price: null,
					priceMultiplier: null,
					classification: null,
					rating: null,
					assetType: null,
					assetMaturityDate: null,
					index: null,
					sector: null,
					fund: null,
					quantity: null,
					shortFallQuantity: null,
					availableQuantity: null,
					availableNotional: null,
					rebateRate: null,
					feeRate: null,
					cusipCode: null,
					schedule: null,
					scheduleDescription: null,
					securityMarginPercentage: null,
					maturity: null,
					securityDescription: null,
					securityConcentration: null,
					maxPercentOfOutstandingAmount: null,
					totalAmountOutstanding: null,
					fitchRating: null,
					moodyRating: null,
					minPiece: null,
					minIncrement: null,
					dirtyPrice: {
						amount: null,
						currency: null,
					},
					startCash: {
						amount: null,
						currency: null,
					},
					allocationInefficiencies: {
						inefficiency: null,
						abbr: null,
						displayName: null,
					},
				},
			},
		},
	}
	return buildQuery(getTemplateByClient, paramList)
}
