import {UPLOAD_GC_FILE, GET_ALL_UPLOADED_FILES} from './GcRatesActions'

export interface GcRates {
	loading: boolean
	allFilesData: any
}

const initialState: GcRates = {
	loading: false,
	allFilesData: {},
}

export function GcRatesReducer(state = initialState, action: any) {
	switch (action.type) {
		case UPLOAD_GC_FILE.triggered:
			return {...state, loading: true}
		case UPLOAD_GC_FILE.failed:
			return {...state, loading: false}
		case UPLOAD_GC_FILE.succeeded:
			return {
				...state,
				loading: false,
			}

		case GET_ALL_UPLOADED_FILES.triggered:
			return {...state, loading: true}
		case GET_ALL_UPLOADED_FILES.failed:
			return {...state, loading: false}
		case GET_ALL_UPLOADED_FILES.succeeded:
			return {
				...state,
				allFilesData: action.response.data,
				loading: false,
			}
	}
	return state
}
