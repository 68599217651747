import {MyClient} from '../myClients/myClientsModel'
import {UserGroup} from '../userGroups/userGroupModel'
import {
	GET_ACTIVE_USERS,
	GET_DRAFT_USERS,
	SAVE_USER,
	EDIT_USER,
	GET_SELECTED_GROUP,
	GET_SELECTED_CLIENT,
	SET_MODIFIED_PRIVILEGES,
	GET_USER_DETAILS,
	DEACTIVATE_USER,
} from './usersListActions'
import {GET_GROUPS, GET_CLIENTS, CLEAR_PRIVILEGES_LIST, CLEAR_USER_DETAILS} from './usersListActions'
import {getPrivilegesListForClients, getPrivilegesListForGroups} from './usersListHelper'
import {PrivilegesObj, User} from './usersListModel'

export interface UsersData {
	loading: boolean
	activeUsersList: User[]
	activeUsersListLoading: boolean
	draftUsersList: User[]
	groups: UserGroup[]
	clients: MyClient[]
	privilegesList: PrivilegesObj
	privilegesLoading: boolean
	selectedUserDetails: User
}

const initialState: UsersData = {
	loading: false,
	activeUsersList: [],
	activeUsersListLoading: true,
	draftUsersList: [],
	groups: [],
	clients: [],
	privilegesList: null,
	privilegesLoading: false,
	selectedUserDetails: null,
}

export function usersReducer(state = initialState, action: any) {
	let privilegesList: PrivilegesObj
	switch (action.type) {
		case GET_ACTIVE_USERS.triggered:
			return {...state, loading: true, activeUsersListLoading: true}
		case GET_ACTIVE_USERS.succeeded:
			const activeUsersList: User[] = action.response.data || []
			return {...state, loading: false, activeUsersListLoading: false, activeUsersList}
		case GET_ACTIVE_USERS.failed:
			return {...state, activeUsersListLoading: false, loading: false}

		case GET_DRAFT_USERS.triggered:
			return {...state, loading: true}
		case GET_DRAFT_USERS.succeeded:
			const draftUsersList: User[] = action.response.data || []
			return {...state, loading: false, draftUsersList}
		case GET_DRAFT_USERS.failed:
			return {...state, loading: false}

		case GET_USER_DETAILS.triggered:
			return {...state, loading: true, selectedUserDetails: null}
		case GET_USER_DETAILS.succeeded:
			const userDetails: User = action.response && action.response.data
			return {...state, loading: false, selectedUserDetails: userDetails}
		case GET_USER_DETAILS.failed:
			return {...state, loading: false, selectedUserDetails: null}

		case GET_GROUPS.triggered:
			return {...state, loading: true}
		case GET_GROUPS.succeeded:
			const groups: UserGroup[] = (action.response && action.response.data) || []
			return {...state, loading: false, groups}
		case GET_GROUPS.failed:
			return {...state, loading: false}

		case GET_SELECTED_GROUP.triggered:
			return {...state, privilegesLoading: true}
		case GET_SELECTED_GROUP.succeeded:
			privilegesList = getPrivilegesListForGroups((action.response && action.response.data) || [])
			return {...state, privilegesLoading: false, privilegesList}
		case GET_SELECTED_GROUP.failed:
			return {...state, privilegesLoading: false}

		case GET_CLIENTS.triggered:
			return {...state, loading: true}
		case GET_CLIENTS.succeeded:
			const clients: MyClient[] = (action.response && action.response.data) || []
			return {...state, loading: false, clients}
		case GET_CLIENTS.failed:
			return {...state, loading: false}

		case GET_SELECTED_CLIENT.triggered:
			return {...state, privilegesLoading: true}
		case GET_SELECTED_CLIENT.succeeded:
			privilegesList = getPrivilegesListForClients((action.response && action.response.data) || [])
			return {...state, privilegesLoading: false, privilegesList}
		case GET_SELECTED_CLIENT.failed:
			return {...state, privilegesLoading: false}

		case SAVE_USER.triggered:
			return {...state, loading: true}
		case SAVE_USER.succeeded:
		case SAVE_USER.failed:
			return {...state, loading: false}

		case EDIT_USER.triggered:
			return {...state, loading: true}
		case EDIT_USER.succeeded:
		case EDIT_USER.failed:
			return {...state, loading: false}

		case DEACTIVATE_USER.triggered:
			return {...state, loading: true}
		case DEACTIVATE_USER.succeeded:
		case DEACTIVATE_USER.failed:
			return {...state, loading: false}

		case CLEAR_PRIVILEGES_LIST:
			return {...state, privilegesList: null}

		case CLEAR_USER_DETAILS:
			return {...state, activeUsersListLoading: true, selectedUserDetails: null}

		case SET_MODIFIED_PRIVILEGES:
			return {...state, privilegesList: action.payload}
	}

	return state
}
