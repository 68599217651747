import gql from 'graphql-tag'
import lodash from 'lodash'

export const buildAbcPortfolioQuery = selectedColumndefs => {
	const updatedColumns = [...selectedColumndefs, 'id', 'quantity', 'securityId', 'actions']
	let uniqueColumns = lodash.uniq(updatedColumns)

	let buildQuery = ''
	let arr = []
	if (updatedColumns.includes('baseNotionalCurrency') || updatedColumns.includes('baseNotionalAmount')) {
		buildQuery += `baseNotional {
			amount
			currency
		}`
		arr.push.apply(arr, ['baseNotionalCurrency', 'baseNotionalAmount'])
	}
	if (updatedColumns.includes('notionalCurrency') || updatedColumns.includes('notionalAmount')) {
		buildQuery += `notional {
			amount
			currency
		}`
		arr.push.apply(arr, ['notionalCurrency', 'notionalAmount'])
	}
	if (
		updatedColumns.includes('balanceSheetContributionCurrency') ||
		updatedColumns.includes('balanceSheetContributionAmount')
	) {
		buildQuery += `balanceSheetContribution {
			amount
			currency
		}`
		arr.push.apply(arr, ['balanceSheetContributionCurrency', 'balanceSheetContributionAmount'])
	}
	if (updatedColumns.includes('collateralCurrency') || updatedColumns.includes('collateralAmount')) {
		buildQuery += `collateral {
			amount
			currency
		}`
		arr.push.apply(arr, ['collateralCurrency', 'collateralAmount'])
	}
	if (updatedColumns.includes('priceCurrency') || updatedColumns.includes('priceAmount')) {
		buildQuery += `price {
			amount
			currency
		}`
		arr.push.apply(arr, ['priceCurrency', 'priceAmount'])
	}
	if (updatedColumns.includes('basePriceCurrency') || updatedColumns.includes('basePriceAmount')) {
		buildQuery += `basePrice {
			amount
			currency
		}`
		arr.push.apply(arr, ['basePriceCurrency', 'basePriceAmount'])
	}
	if (
		updatedColumns.includes('recallInefficiencyContributionCurrency') ||
		updatedColumns.includes('recallInefficiencyContributionAmount')
	) {
		buildQuery += `recallInefficiencyContribution {
			amount
			currency
		}`
		arr.push.apply(arr, ['recallInefficiencyContributionCurrency', 'recallInefficiencyContributionAmount'])
	}
	if (
		updatedColumns.includes('returnInefficiencyContributionCurrency') ||
		updatedColumns.includes('returnInefficiencyContributionAmount')
	) {
		buildQuery += `returnInefficiencyContribution {
			amount
			currency
		}`
		arr.push.apply(arr, ['returnInefficiencyContributionCurrency', 'returnInefficiencyContributionAmount'])
	}
	if (
		updatedColumns.includes('availabilitiesContributionCurrency') ||
		updatedColumns.includes('availabilitiesContributionAmount')
	) {
		buildQuery += `availabilitiesContribution {
			amount
			currency
		}`
		arr.push.apply(arr, ['availabilitiesContributionCurrency', 'availabilitiesContributionAmount'])
	}
	if (updatedColumns.includes('needsContributionCurrency') || updatedColumns.includes('needsContributionAmount')) {
		buildQuery += `needsContribution {
			amount
			currency
		}`
		arr.push.apply(arr, ['needsContributionCurrency', 'needsContributionAmount'])
	}
	if (
		updatedColumns.includes('effectiveBaseNotionalCurrency') ||
		updatedColumns.includes('effectiveBaseNotionalAmount')
	) {
		buildQuery += `effectiveBaseNotional {
			amount
			currency
		}`
		arr.push.apply(arr, ['effectiveBaseNotionalCurrency', 'effectiveBaseNotionalAmount'])
	}
	if (updatedColumns.includes('inefficienciesArray')) {
		buildQuery += `inefficiencies {
			inefficiency
			abbr
			displayName
		}
	`
		arr.push.apply(arr, ['inefficienciesArray'])
	}
	if (updatedColumns.includes('startCashAmount')) {
		buildQuery += `startCash {
			amount
			currency
		}`
		arr.push.apply(arr, ['startCashAmount'])
	}
	if (updatedColumns.includes('endCashAmount')) {
		buildQuery += `endCash {
			amount
			currency
		}`
		arr.push.apply(arr, ['endCashAmount'])
	}
	uniqueColumns = uniqueColumns.filter(e => !arr.includes(e))

	return gql`
		query($page: Int!, $size: Int!, $filters: [AbcFilter!]) {
			abcPortfolio(abcPortfolioSearchParams: {page: $page, size: $size, filters: $filters}) {
				entries {
					${uniqueColumns.join(`\n`)}\n${buildQuery}
				}
			}
		}
	`
}

export const getAbcPortfolioQuery = () => {
	return gql`
		query ($page: Int!, $size: Int!, $filters: [AbcFilter!]) {
			abcPortfolio(abcPortfolioSearchParams: {page: $page, size: $size, filters: $filters}) {
				entries {
					actions
					partial
					activityType
					allInPrice {
						amount
						currency
					}
					allInRate
					assetClassification
					assetMaturityDate
					assetSubType
					assetType
					availableQuantity
					balanceSheetContribution {
						amount
						currency
					}
					baseNotional {
						amount
						currency
					}
					basePrice {
						amount
						currency
					}
					bbId
					book
					borrowFee
					buySellInd
					collateralType
					collateral {
						amount
						currency
					}
					contractValue
					counterParty
					createdBy
					createdDate
					cusip
					depot
					effectiveBaseNotional {
						amount
						currency
					}
					endDate
					exchangeRate
					fitchRating
					fund
					haircut
					hqla
					id
					inefficiencies {
						inefficiency
						abbr
						displayName
					}
					index
					isin
					loanFee
					maturityDate
					maturityType
					moodyRating
					needQuantity
					notional {
						amount
						currency
					}
					poolFactor
					portfolioEntryType
					partial
					price {
						amount
						currency
					}
					priceMultiplier
					quantity
					recallInefficiencyContribution {
						amount
						currency
					}
					recallQuantity
					referenceRate
					returnInefficiencyContribution {
						amount
						currency
					}
					returnQuantity
					availabilitiesContribution {
						amount
						currency
					}
					needsContribution {
						amount
						currency
					}
					platformTradeId
					rebate
					originalTradeType
					legalEntity
					ric
					sector
					securityId
					securityIdType
					settlementDate
					snpRating
					sourceSystem
					sourceSystemTradeId
					spread
					startCash {
						amount
						currency
					}
					endCash {
						amount
						currency
					}
					startDate
					tenor
					termDate
					tradeDate
					ticker
					tradeCurrency
					tradeType
					legalEntity
					originalTradeType
					lcrNotional
					lcrCost
					lcrHaircut
					lcrHqla
					assetBasedCharge
					cofReferenceRate
					callable
					puttable
					callableDate
					puttableDate
					comments
					dailyInterestPayable
					dailyInterestReceivable
					discountRate
					discountAmount
					impliedAllInRate
					proceeds {
						amount
						currency
					}
					par {
						amount
						currency
					}
				}
				totalElements
				totalPages
			}
		}
	`
}

export const getAbcFiltersQuery = (groupKey: any) => {
	if (groupKey === 'groupOne') {
		return gql`
			query ($field: String!) {
				abcPortfolioFiltersValue(field: $field)
			}
		`
	} else {
		return gql`
			query ($field: String!, $filter: [AbcFilter!]) {
				abcPortfolioFiltersValue(field: $field, filter: $filter)
			}
		`
	}
}

export const fetchSearchSecurityResultQuery = security => {
	return gql`
		query {
			searchAvailabilitySecurityResults(
				searchSecurity: {
					searchSecurityEntry: [ 
            ${security}
					]
				}
			) {
				securityId
				  requestedQuantity
        	totalAvailableQuantity
          approvedQuantity
          locateRequestId
          locateRequestedBy
				availabilitiesNeedsDocumentSearchEntries {
          securityId
					availableQuantity
					rebateRate
					feeRate
					counterPartyName
					documentId
					documentEntryId
          clientRank
          approvedQuantity
          filledFlag
          shortFall
          locateRequestId
          id
          name
          price
          exchangeRate
          counterPartyQuantity
          counterPartyNotional
          needsQuantity
          needsNotional
          allocatedQuantity
          allocatedNotional
          isin
          currency
          collateralType
          documentIdForCreateBorrow
				}
			}
		}
	`
}

export const fetchLocateSearchBucketListQuery = id => {
	return gql`
  query {
    locateSearchBucketResult(
      locateRequestId:"${id}"
    )
    {
      locateRequestId
      locateRequestedBy
      locateSearchBucketEntries 
      {
        securityId
        requestedQuantity   
      }
    }
  }
	`
}

export const searchAbcmAndUpdateQueue = securityEntry => {
	return gql`
		query {
			searchAvailabilitiesNeedsDocumentSearchResultAndUpdateQueue(
				locateBucketSearch: {
          includeInclusive: "${securityEntry.includeInclusive}"
          forWhom: ""
          locateRequestId: "${securityEntry.locateRequestId}"
          locateRequestedBy: "${securityEntry.locateRequestedBy}"
					locateSearchEntry: [ 
        				${securityEntry.locateSearchEntry}
					]
				}
			)
			{
				  securityId
				  requestedQuantity
        	totalAvailableQuantity
          approvedQuantity
          locateRequestId
		  locateRequestedBy
		  marketFee
		  myFee
		  detailsView
		  totalFee
		  centsPerStock
		  marketPrice{
			amount
			currency
			}
		  notional{
				amount
				currency
			}
				availabilitiesNeedsDocumentSearchEntries {
					securityId
					availableQuantity
					rebateRate
					feeRate
					counterPartyName
					documentId
					documentEntryId
          clientRank
          approvedQuantity
          filledFlag
          shortFall
		  locateRequestId
				}
			}
		}
	`
}
