import {Auth} from 'aws-amplify'
import {paths} from '../routes/routesConfig'
import {getStringFromStorage, removeItemFromStorage} from '../services/storageService'

export const initializeAmplify = async (data, tenant, executeFedetationSignIn) => {
	if (data.federation) {
		await Auth.configure({
			userPoolId: data.userPoolId,
			userPoolWebClientId: data.clientId,
			authenticationFlowType: 'EMAIL' === data['mfa'] ? 'CUSTOM_AUTH' : undefined,
			oauth: {
				region: 'us-east-2',
				domain: data.federation.domain,
				scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
				redirectSignIn: data.federation.redirectSignIn,
				redirectSignOut: data.federation.redirectSignOut,
				responseType: 'code',
			},
			storage: window.sessionStorage,
		})
		if (executeFedetationSignIn) {
			Auth.federatedSignIn({customProvider: data.federation.providerName, customState: tenant})
		}
	} else {
		await Auth.configure({
			userPoolId: data.userPoolId,
			userPoolWebClientId: data.clientId,
			authenticationFlowType: 'EMAIL' === data['mfa'] ? 'CUSTOM_AUTH' : undefined,
			storage: window.sessionStorage,
		})
	}
	return executeFedetationSignIn
}

export const amplifySentResetOtp = email => {
	return Auth.forgotPassword(email)
}

export const amplifyForgotPasswordSubmit = (email, otp, password) => {
	return Auth.forgotPasswordSubmit(email, otp, password)
}

export const amplifySignIn = (email, password) => {
	return Auth.signIn(email, password)
}

export const amplifyGetAuthenticatedUser = () => {
	return Auth.currentAuthenticatedUser()
}

export const amplifyCompleteNewPassword = async (authenticate, password) => {
	return Auth.completeNewPassword(authenticate, password)
}

export const amplifyLogout = () => {
	return Auth.signOut({global: true})
}

export const amplifySendCustomChallengeAnswer = (cognito, code) => {
	return Auth.sendCustomChallengeAnswer(cognito, code)
}

export const redirectLoginWithTenantCode = history => {
	const fedetation = getStringFromStorage('fedetation')
	if (history) {
		if (fedetation) {
			removeItemFromStorage('fedetation')
			history && redirectLoginWithTenantCode(history)
		} else {
			const tenant = getStringFromStorage('tenant')
			history.push(`${paths.login}${tenant && '?tenant_code=' + tenant}`)
		}
	}
}

export const amplifyCurrentUser = () => {
	return Auth.currentAuthenticatedUser()
}

export const amplifyCurrentSession = () => {
	return Auth.currentSession()
}
