import {getPMSClient, getMDSClient} from '../../..'
import {getMDSBaseUrl, getPMSBaseUrl, getPMSGraphBaseUrl} from '../../../services/apiService'
import {createFetchActions} from '../../../services/createActions'
import {del, get, post, put, graphQlWithAxios} from '../../../services/createAPICall'
import {
	collateralBasketDropDownsQuery,
	fetchCollateralScheduleClients,
	getBoxesEnrichDetailsForScheduleQuery,
} from './collateralBasketQueries'
import {getTemplateByClientQuery, getBoxesEnrichDetailsQuery} from './collateralBasketQueries'
import {strategiesQuery} from '../../orderEntry/orderEntryQueries'
import {collateralBasketQuery} from '../collateralBasketItems/collateralBasketItemQueries'

export const GET_PRODUCTS = createFetchActions('GET_PRODUCTS')
export const GET_COLLATERAL_BASKET_CONSTRAINTS = createFetchActions('GET_COLLATERAL_BASKET_CONSTRAINTS')
export const GET_DATA_FROM_INVENTORY = createFetchActions('GET_DATA_FROM_INVENTORY')
export const CREATE_COLLATERAL_BASKET = createFetchActions('CREATE_COLLATERAL_BASKET')
export const SAVE_COLLATERAL_BASKET = createFetchActions('SAVE_COLLATERAL_BASKET')
export const DELETE_COLLATERAL_BASKET = createFetchActions('DELETE_COLLATERAL_BASKET')
export const GET_COLLATERAL_BASKET_ITEMS = createFetchActions('GET_COLLATERAL_BASKET_ITEMS')
export const UPDATE_COLLATERAL_BASKET_ITEM = createFetchActions('UPDATE_COLLATERAL_BASKET_ITEM')
export const ADD_COLLATERAL_BASKET_ITEM = createFetchActions('ADD_COLLATERAL_BASKET_ITEM')
export const DELETE_COLLATERAL_BASKET_ITEM = createFetchActions('DELETE_COLLATERAL_BASKET_ITEM')
export const GET_STRATEGIES = createFetchActions('GET_STRATEGIES')
export const GET_SECTORS_INDICES = 'GET_SECTORS_INDICES'
export const CLEAR_SEARCH_DATA = 'CLEAR_SEARCH_DATA'
export const FETCH_COLLATERAL_SCHEDULE_CLIENTS = createFetchActions('FETCH_COLLATERAL_SCHEDULE_CLIENTS')
export const GET_TEMPLATE_BY_CLIENT = createFetchActions('GET_TEMPLATE_BY_CLIENT')
export const GET_BOXES_ENRICH_DETAILS = createFetchActions('GET_BOXES_ENRICH_DETAILS')
export const GET_BOXES_ENRICH_DETAILS_FOR_SCHEDULE = createFetchActions('GET_BOXES_ENRICH_DETAILS_FOR_SCHEDULE')

export const getProducts = () => (dispatch: any) => {
	return dispatch(get(GET_PRODUCTS, `${getMDSBaseUrl()}/v1/products`))
}

export const getCollateralBasketConstraints = () => {
	return function (dispatch) {
		dispatch({
			type: GET_COLLATERAL_BASKET_CONSTRAINTS.triggered,
		})
		getPMSClient()
			.query({
				query: collateralBasketDropDownsQuery,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_COLLATERAL_BASKET_CONSTRAINTS.succeeded,
					response,
				})
			})
	}
}

export const getStrategies = () => {
	return function (dispatch) {
		dispatch({
			type: GET_STRATEGIES.triggered,
		})
		getMDSClient()
			.query({
				query: strategiesQuery,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_STRATEGIES.succeeded,
					response,
				})
			})
	}
}

export const createCollateralBasket = data => (dispatch: any) => {
	return dispatch(post(CREATE_COLLATERAL_BASKET, `${getPMSBaseUrl()}/v1/collateral-baskets/filter`, data))
}

export const createCollateralBasketOptimize = data => (dispatch: any) => {
	return dispatch(put(CREATE_COLLATERAL_BASKET, `${getPMSBaseUrl()}/v1/decisionMaking`, data))
}

export const getCollateralBasketItems = basketId => (dispatch: any) => {
	dispatch({type: GET_COLLATERAL_BASKET_ITEMS.triggered})
	getPMSClient()
		.query({
			query: collateralBasketQuery(basketId),
			fetchPolicy: 'network-only',
			errorPolicy: 'all',
		})
		.then(response => {
			dispatch({
				type: GET_COLLATERAL_BASKET_ITEMS.succeeded,
				response,
			})
		})
}

export const updateCollateralBasketItem = (basketId, itemId, data) => (dispatch: any) => {
	return dispatch(
		put(UPDATE_COLLATERAL_BASKET_ITEM, `${getPMSBaseUrl()}/v1/collateral-baskets/${basketId}/items/${itemId}`, data)
	)
}

export const addCollateralBasketItem = (basketId, data) => (dispatch: any) => {
	return dispatch(post(ADD_COLLATERAL_BASKET_ITEM, `${getPMSBaseUrl()}/v1/collateral-baskets/${basketId}/items`, data))
}

export const deleteCollateralBasketItem = (basketId, itemId) => (dispatch: any) => {
	return dispatch(
		del(DELETE_COLLATERAL_BASKET_ITEM, `${getPMSBaseUrl()}/v1/collateral-baskets/${basketId}/items/${itemId}`)
	)
}

export const saveCollateralBasket = data => (dispatch: any) => {
	return dispatch(post(SAVE_COLLATERAL_BASKET, `${getPMSBaseUrl()}/v1/collateral-baskets`, data))
}

export const deleteCollateralBasket = id => (dispatch: any) => {
	return dispatch(del(DELETE_COLLATERAL_BASKET, `${getPMSBaseUrl()}/v1/collateral-baskets/${id}`))
}

export const clearSearchData = () => {
	return {
		type: CLEAR_SEARCH_DATA,
	}
}

export const getCollateralScheduleClients = () => {
	const query = fetchCollateralScheduleClients()
	const baseURL = `${getPMSGraphBaseUrl()}`
	return function (dispatch) {
		graphQlWithAxios(dispatch, FETCH_COLLATERAL_SCHEDULE_CLIENTS, baseURL, query, response => {
			dispatch({
				type: FETCH_COLLATERAL_SCHEDULE_CLIENTS.succeeded,
				response: response.data && response.data.data && response.data.data.collateralScheduleDropDowns.clientDetails,
			})
		})
	}
}

export const getTemplateByClient = (client: any) => {
	const query = getTemplateByClientQuery(client)
	const baseURL = `${getPMSGraphBaseUrl()}`
	return function (dispatch) {
		graphQlWithAxios(dispatch, GET_TEMPLATE_BY_CLIENT, baseURL, query, response => {
			dispatch({
				type: GET_TEMPLATE_BY_CLIENT.succeeded,
				client,
				response: response.data && response.data.data && response.data.data.templateByClient,
			})
		})
	}
}

export const getBoxesEnrichDetails = () => (dispatch: any) => {
	dispatch({type: GET_BOXES_ENRICH_DETAILS.triggered})
	getPMSClient()
		.query({
			query: getBoxesEnrichDetailsQuery(),
			fetchPolicy: 'network-only',
			errorPolicy: 'all',
		})
		.then(response => {
			dispatch({
				type: GET_BOXES_ENRICH_DETAILS.succeeded,
				response,
			})
		})
}

export const getBoxesEnrichDetailsForSchedule = (schedule, book: string = null, clientRiskType = null) => {
	const query = getBoxesEnrichDetailsForScheduleQuery(schedule, book, clientRiskType)
	const baseURL = `${getPMSGraphBaseUrl()}`
	return function (dispatch) {
		dispatch({type: GET_BOXES_ENRICH_DETAILS_FOR_SCHEDULE.triggered})
		graphQlWithAxios(dispatch, GET_BOXES_ENRICH_DETAILS_FOR_SCHEDULE, baseURL, query, (response: any) => {
			dispatch({
				type: GET_BOXES_ENRICH_DETAILS_FOR_SCHEDULE.succeeded,
				response: response && response.data && response.data.data,
			})
		})
	}
}
