import {createFetchActions} from '../../services/createActions'
import {post} from '../../services/createAPICall'
import {graphwidgetsQuery} from './graphwidgetsQueries'
import * as axios from 'axios'
import {getAuthToken} from '../../services/authService'
import {getPMSGraphBaseUrl, getPMSBaseUrl} from '../../services/apiService'

export const GET_WIDGETS = createFetchActions('GET_WIDGETS')
export const SAVE_WIDGETS = createFetchActions('SAVE_WIDGETS')
export const DELETE_WIDGETS = createFetchActions('DELETE_WIDGETS')
export const GET_ALL_TRADES_FOR_WIDGETS = createFetchActions('GET_ALL_TRADES_FOR_WIDGETS')

export const DEFAULT_ACTION = 'DEFAULT_ACTION'

export const getWidgets = user => (dispatch: any) => {
	return dispatch(post(GET_WIDGETS, `${getPMSBaseUrl()}/getfmcfg`, user, null, 'Error occured while getting ODR data'))
}
export const saveWidgets = widget => (dispatch: any) => {
	return dispatch(
		post(SAVE_WIDGETS, `${getPMSBaseUrl()}/savefmcfg`, widget, null, 'Error occured while saving ODR data')
	)
}
export const deleteWidgets = user => (dispatch: any) => {
	return dispatch(
		post(DELETE_WIDGETS, `${getPMSBaseUrl()}/deletefmcfg`, user, null, 'Error occured while getting ODR data')
	)
}

export const getAllTrades = () => {
	const query = graphwidgetsQuery.all
	return function (dispatch) {
		dispatch({type: GET_ALL_TRADES_FOR_WIDGETS.triggered})

		const instance = axios.default.create({
			baseURL: `${getPMSGraphBaseUrl()}`,
			timeout: 1000000,
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${getAuthToken()}`,
			},
		})

		instance.post('', query).then(data => {
			dispatch({
				type: GET_ALL_TRADES_FOR_WIDGETS.succeeded,
				response: data.data,
			})
		})
	}
}
