import gql from 'graphql-tag'

export const getMyLegalEntitiesQuery = (tenentId: string) => gql`
	query {
		allLegalEntities(tenantId:"${tenentId}") {
      legalEntityId
      id
      fullName
      shortName
      legalEntityExternalId
      bbgGlobalIdentifier
      externalProviderRefId
      entityType
      entitySubType
      businessSector
      country
      orgId
      tenantId
      internalCode1
			internalCode2
			internalCode3
			internalCode4
			internalCode5
		}
	}
`

export const sectorsIndicesQuery = gql`
	query {
		distinctSectors
		indices
	}
`
