export const getStartCash = (quantity = 0, dirtyPrice, priceMultiplier = 1, enableRounding = true) => {
	priceMultiplier = priceMultiplier === null ? 1 : priceMultiplier
	const startCash = quantity * dirtyPrice * priceMultiplier
	return enableRounding ? Math.round(startCash) : startCash
}

export const getStartCashRepoBlotter = (quantity = 0, allInPrice, enableRounding = true) => {
	const startCash = (allInPrice * quantity) / 100
	return enableRounding ? Math.round(startCash) : startCash
}

export const getNotional = (price = 0, priceMultiplier = 1, quantity = 0, enableRounding = true) => {
	priceMultiplier = priceMultiplier === null ? 1 : priceMultiplier
	const notional = price * priceMultiplier * quantity
	return enableRounding ? Math.round(notional) : notional
}

export const getRepoInterest = (startCash = 0, allInRate = 0, duration = 0) => {
	return Math.round(startCash * (allInRate / (360 * 100)) * duration)
}

export const getEndCash = (startCash = 0, repoInterest = 0, enableRounding = true) => {
	const endCash = startCash + repoInterest
	return enableRounding ? Math.round(endCash) : endCash
}

export const getAllInPrice = (marketPrice = 0, margin) => {
	const haircut = margin - 100
	return haircut < 0 ? marketPrice : marketPrice * (100 / margin)
}
export const getAllInPriceRepoBlotter = (marketPrice = 0, haircut) => {
	return (100 - haircut) * (marketPrice / 100)
}

export const getQuantity = (startCash = 0, dirtyPrice = 0, priceMultiplier) => {
	return Math.round(startCash / (dirtyPrice * priceMultiplier))
}
export const getQuantityRepoBlotter = (startCash = 0, allInPrice = 0) => {
	return Math.round((startCash / allInPrice) * 100)
}

export const getQuantityFromNotional = (notional, price, priceMultiplier) => {
	return Math.round(notional / (price * priceMultiplier))
}
