import {getALPBaseUrl} from '../../../services/apiService'
import {createFetchActions} from '../../../services/createActions'
import {get} from '../../../services/createAPICall'

export const FETCH_TARGETED_INVENTORY = createFetchActions('FETCH_TARGETED_INVENTORY')
export const CLEAR_MAIN_GRID_DATA = createFetchActions('CLEAR_MAIN_GRID_DATA')

export const getTargetedInventory = () => (dispatch: any) => {
	return dispatch(get(FETCH_TARGETED_INVENTORY, `${getALPBaseUrl()}/v1/alp/targeted-inventory`))
}

export const clearGridData = () => {
	return {
		type: CLEAR_MAIN_GRID_DATA,
	}
}
