import {
	GET_PB_ABC_PORTFOLIO,
	GET_PB_ABC_PORTFOLIO_FILTER,
	SET_PB_ABC_PORTFOLIO_GROUP_FILTERS,
	SET_PB_ABC_PORTFOLIO_SELECTED_VIEW,
	RESET_PB_ABC_PORTFOLIO_DATA,
	RESET_PB_ABC_PORTFOLIO_FILTERS,
	RESET_PB_ABC_PORTFOLIO_LOADER,
	STOP_PB_ABC_PORTFOLIO_LOADER,
} from './pbAbcPortfolioActions'

export interface PbAbcPortfolio {
	loading: boolean
	abcPortfolio: any
	groupOneFilterValues: any
	groupTwoFilterValues: any
	selectedView: any
	groupFilters: any
	tableLoading: boolean
}

const initialState: PbAbcPortfolio = {
	loading: false,
	abcPortfolio: {},
	groupOneFilterValues: [],
	groupTwoFilterValues: [],
	selectedView: '',
	groupFilters: {},
	tableLoading: true,
}

export function pbAbcPortfolioReducer(state = initialState, action: any) {
	switch (action.type) {
		case SET_PB_ABC_PORTFOLIO_GROUP_FILTERS:
			return {...state, groupFilters: action.response.data}
		case SET_PB_ABC_PORTFOLIO_SELECTED_VIEW:
			return {...state, selectedView: action.response.data}
		case GET_PB_ABC_PORTFOLIO.triggered:
			return {...state, loading: true, tableLoading: true}
		case GET_PB_ABC_PORTFOLIO.failed:
			return {...state, loading: false, tableLoading: false}
		case GET_PB_ABC_PORTFOLIO.succeeded:
			const abcPortfolio = action && action.response && action.response.data && action.response.data.abcPortfolio
			return {
				...state,
				abcPortfolio,
				loading: false,
				tableLoading: false,
			}
		case GET_PB_ABC_PORTFOLIO_FILTER.triggered:
			return {...state, loading: true}
		case GET_PB_ABC_PORTFOLIO_FILTER.failed:
			return {...state, loading: false}
		case GET_PB_ABC_PORTFOLIO_FILTER.succeeded:
			let filterParams: any = {}
			if (action.groupKey === 'groupOne') {
				filterParams.groupOneFilterValues = action.response.data && action.response.data.abcPortfolioFiltersValue
			} else {
				filterParams.groupTwoFilterValues = action.response.data && action.response.data.abcPortfolioFiltersValue
			}
			return {
				...state,
				...filterParams,
				loading: false,
			}
		case RESET_PB_ABC_PORTFOLIO_DATA:
			return {
				...state,
				abcPortfolio: [],
			}
		case RESET_PB_ABC_PORTFOLIO_FILTERS:
			return {
				...state,
				groupOneFilterValues: [],
				groupTwoFilterValues: [],
			}
		case RESET_PB_ABC_PORTFOLIO_LOADER:
			return {
				...state,
				tableLoading: true,
			}
		case STOP_PB_ABC_PORTFOLIO_LOADER:
			return {
				...state,
				tableLoading: false,
			}
	}
	return state
}
