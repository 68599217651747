import {GET_ABCM_RERUN_TIMING, SET_ABCM_RERUN_TIMING} from './abcmStatusActions'
import {setObjectInStorage} from '../../services/storageService'

export interface AbcmStatus {
	loading: Boolean
	pendingAbcmStatus: any
}

const initialState: AbcmStatus = {
	loading: false,
	pendingAbcmStatus: {},
}

export function AbcmStatusReducer(state = initialState, action: any) {
	switch (action.type) {
		case SET_ABCM_RERUN_TIMING:
			const pendingAbcmStatus = action.response.data
			return {...state, pendingAbcmStatus}
		case GET_ABCM_RERUN_TIMING.triggered:
			return {
				...state,
				loading: true,
			}
		case GET_ABCM_RERUN_TIMING.succeeded:
			setObjectInStorage('pendingAbcmStatus', action.response.data.pendingAbcmStatus)
			return {
				...state,
				pendingAbcmStatus: action.response.data.pendingAbcmStatus,
				loading: false,
			}
		case GET_ABCM_RERUN_TIMING.failed:
			return {...state, loading: false}
	}
	return state
}
