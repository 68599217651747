import {
	CREATE_MENU_CONFIG,
	GET_ALL_MENU_CONFIG,
	UPDATE_MENU_CONFIG,
	DELETE_MENU_CONFIG,
	GET_ACTIVE_MENU_CONFIG,
	RESET_MENU_DATA,
} from './customiseMenuActions'

export interface CustomiseMenu {
	loading: boolean
	allMenuData: any
	activeMenuData: any
}

const initialState: CustomiseMenu = {
	loading: false,
	allMenuData: [],
	activeMenuData: {},
}

export function customiseMenuReducer(state = initialState, action: any) {
	switch (action.type) {
		case CREATE_MENU_CONFIG.triggered:
			return {...state, loading: true}
		case CREATE_MENU_CONFIG.failed:
			return {...state, loading: false}
		case CREATE_MENU_CONFIG.succeeded:
			return {
				...state,
				loading: false,
			}

		case GET_ALL_MENU_CONFIG.triggered:
			return {...state, loading: true}
		case GET_ALL_MENU_CONFIG.failed:
			return {...state, loading: false}
		case GET_ALL_MENU_CONFIG.succeeded:
			return {
				...state,
				allMenuData: action.response.data,
				loading: false,
			}

		case UPDATE_MENU_CONFIG.triggered:
			return {...state, loading: true}
		case UPDATE_MENU_CONFIG.failed:
			return {...state, loading: false}
		case UPDATE_MENU_CONFIG.succeeded:
			return {
				...state,
				loading: false,
			}

		case DELETE_MENU_CONFIG.triggered:
			return {...state, loading: true}
		case DELETE_MENU_CONFIG.failed:
			return {...state, loading: false}
		case DELETE_MENU_CONFIG.succeeded:
			return {
				...state,
				loading: false,
			}

		case GET_ACTIVE_MENU_CONFIG.triggered:
			return {...state, loading: true}
		case GET_ACTIVE_MENU_CONFIG.failed:
			return {...state, loading: false}
		case GET_ACTIVE_MENU_CONFIG.succeeded:
			return {
				...state,
				activeMenuData: action.response.data,
				loading: false,
			}

		case RESET_MENU_DATA:
			return {
				...state,
				allMenuData: [],
				activeMenuData: [],
			}
	}
	return state
}
