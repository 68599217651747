import Lodash from 'lodash'
import {ArrayUtil, ObjectUtil} from 'helper-util'

import {layeredColumnChartConstant} from '../../common/chart/chartConstant'

export const transformAssetExposureData = (data: any[]) => {
	return ArrayUtil.isEmpty(data)
		? []
		: data.map(item => {
				return {
					[layeredColumnChartConstant.title]: item.assettype,
					[layeredColumnChartConstant.value]: item.current_exp,
					[layeredColumnChartConstant.maxValue]: item.limit,
				}
		  })
}

export const transformDurationExposureData = (data: any[]) => {
	return ArrayUtil.isEmpty(data)
		? []
		: data.map(item => {
				return {
					[layeredColumnChartConstant.title]: item.assettype,
					[layeredColumnChartConstant.value]: item.current_dur,
					[layeredColumnChartConstant.maxValue]: item.limit,
				}
		  })
}
export const transformCounterPartyExposureData = (data: any[]) => {
	return ArrayUtil.isEmpty(data)
		? []
		: data.map(item => {
				return {
					[layeredColumnChartConstant.title]: item.cpty,
					[layeredColumnChartConstant.value]: item.current_exp,
					[layeredColumnChartConstant.maxValue]: item.limit,
				}
		  })
}
export const addUtmostValueToData = (data: any[]) => {
	if (ArrayUtil.isEmpty(data)) {
		return []
	} else {
		let values = Lodash.map(data, 'value')
		let maxvalues = Lodash.map(data, 'maxValue')
		let utmost = Math.max(...values, ...maxvalues)
		data.map(item => {
			return (item['utmost'] = utmost)
		})
	}
}
export const addAmountsFromObjects = (data: any[]) => {
	if (data && data['all']) {
		data['all'].entries.forEach(item => {
			if (ObjectUtil.isObject(item['baseNotional'])) {
				item['baseNotionalAmount'] = item.baseNotional['amount']
			}
			if (ObjectUtil.isObject(item['needsContribution'])) {
				item['needsContributionAmount'] = item.needsContribution['amount']
			}
			if (ObjectUtil.isObject(item['cash'])) {
				item['cashAmount'] = item.cash['amount']
			}
			if (ObjectUtil.isObject(item['collateral'])) {
				item['collateralAmount'] = item.collateral['amount']
			}
			if (ObjectUtil.isObject(item['notional'])) {
				item['notionalAmount'] = item.notional['amount']
			}
		})
		return data
	} else {
		return []
	}
}
