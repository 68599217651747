import {createFetchActions} from '../../services/createActions'
import {getLocateBaseUrl, getPMSBaseUrl} from '../../services/apiService'
import {put, post} from '../../services/createAPICall'

export const FETCH_INDEX_ANALYSIS_DATA = createFetchActions('FETCH_INDEX_ANALYSIS_DATA')
export const FETCH_TRACKING_ANALYSIS_DATA = createFetchActions('FETCH_TRACKING_ANALYSIS_DATA')
export const DELETE_TRACKING_BASKET_ENTITY_DATA = createFetchActions('DELETE_TRACKING_BASKET_ENTITY_DATA')
export const FETCH_IOM_DATA = createFetchActions('FETCH_IOM_DATA')
export const DELETE_INDEX_ANALYSIS_IOM_DATA = createFetchActions('DELETE_INDEX_ANALYSIS_IOM_DATA')
export const FETCH_INDEX_ANALYSIS_LONG_DATA = createFetchActions('FETCH_INDEX_ANALYSIS_LONG_DATA')
export const FETCH_INDEX_ANALYSIS_SHORT_DATA = createFetchActions('FETCH_INDEX_ANALYSIS_SHORT_DATA')
export const LOCATE_DATA_QUEUE = createFetchActions('LOCATE_DATA_QUEUE')

export const fetchIndexAnalysisData =
	(data: any, flag = null) =>
	(dispatch: any) => {
		const actionType = !flag
			? FETCH_INDEX_ANALYSIS_DATA
			: flag === 'assetLong'
			? FETCH_INDEX_ANALYSIS_LONG_DATA
			: FETCH_INDEX_ANALYSIS_SHORT_DATA
		return dispatch(put(actionType, `${getPMSBaseUrl()}/v1/decisionMaking`, data, null, 'Internal Server Error'))
	}

export const fetchTrackingAnalysisData = (data: any) => (dispatch: any) => {
	return dispatch(
		put(FETCH_TRACKING_ANALYSIS_DATA, `${getPMSBaseUrl()}/v1/decisionMaking`, data, null, 'Internal Server Error')
	)
}

export const fetchIomData = (data: any) => (dispatch: any) => {
	return dispatch(put(FETCH_IOM_DATA, `${getPMSBaseUrl()}/v1/decisionMaking`, data, null, 'Internal Server Error'))
}

export const deleteTrackingBasket = data => {
	return {
		type: DELETE_TRACKING_BASKET_ENTITY_DATA,
		data,
	}
}

export const deleteIOMData = data => {
	return {
		type: DELETE_INDEX_ANALYSIS_IOM_DATA,
	}
}

export const setLocateDataIntoQueue = (counterparty, data: any) => (dispatch: any) => {
	return dispatch(
		// post(
		// 	LOCATE_DATA_QUEUE,
		// 	`${getPMSBaseUrl()}/v1/locate/queue`,
		// 	data,
		// 	null,
		// 	'Error occured while setting locate data into queue'
		// )
		post(LOCATE_DATA_QUEUE, `${getLocateBaseUrl()}/v1/locate/authorizer/create`, data)
	)
}
