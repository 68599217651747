import {SET_TOAST_NOTIFICATION_DATA} from './toastNotificationActions'
import {getEpochTimeInSecond} from './toastNotificationHelper'

export interface ToastNotification {
	data: any
}

const initialState: ToastNotification = {
	data: {
		addedNewData: false,
		latestData: [],
	},
}

export function toastNotificationReducer(state = initialState, action: any) {
	switch (action.type) {
		case SET_TOAST_NOTIFICATION_DATA:
			const data = JSON.parse(JSON.stringify(state.data))
			const addedNewData = Boolean(action.data)

			if (addedNewData) {
				const messageExist = data.latestData.find(item => Boolean(item.message === action.data.message))
				if (!messageExist) {
					action.data.epochTimeInSec = getEpochTimeInSecond()
					data.latestData.push(action.data)
				}
			} else {
				data.latestData.shift()
			}
			return {
				data: {
					addedNewData,
					latestData: data.latestData,
				},
			}
	}
	return state
}
