export const REQUEST_TYPE = [
	{
		key: 'singleStock',
		label: 'Single Security',
	},
	{
		key: 'basket',
		label: 'Basket',
	},
]

export const AUTHORIZER = 'authorizer'
export const REQUESTER = 'requester'
export const REQUESTTYPE = 'requestType'
export const WAYTYPE = 'wayType'
export const SINGLESTOCK = 'singleStock'
export const BASKET = 'basket'
export const COLLATERAL_TYPE = 'collateralType'
export const SETTLEMENT_DATE = 'settlementDate'
export const COLLATERAL_DATE = 'collateralDate'
export const IS_PARTIAL = 'isPartial'
export const IS_DIVIDEND = 'isDivident'
export const DIVIDEND_VALUE = 'dividentValue'
export const SECURITY = 'security'
export const SUBACCOUNT = 'subAccount'
export const BORROWER = 'borrower'
export const COLLATERAL_CCY = 'collateralCurrency'
export const BORROW = 'BORROW'
