import {GET_ALL_DATAVISUALIZATION_DETAILS_NEW, CREATE_DATAVISUALIZATION_DETAILS_NEW} from './DataVisualizationAction'
import {UPDATE_DATAVISUALIZATION_DETAILS_NEW} from './DataVisualizationAction'
import {isEmpty} from 'lodash'
export interface dataVisualization {
	loading: boolean
	configData: any
}

const initialState: dataVisualization = {
	loading: false,
	configData: {},
}

export function dataVisualizationReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_ALL_DATAVISUALIZATION_DETAILS_NEW.triggered:
			return {...state, loading: true}

		case GET_ALL_DATAVISUALIZATION_DETAILS_NEW.succeeded:
			const actionRes = action && action.response && action.response.data
			const screenId = !isEmpty(actionRes) && actionRes[0].screenId
			let configData = {...state.configData}
			if (screenId) {
				configData[screenId] = action.response.data ? action.response.data : {}
			}
			return {
				...state,
				loading: false,
				configData,
			}

		case GET_ALL_DATAVISUALIZATION_DETAILS_NEW.failed:
			return {...state, loading: false}

		case CREATE_DATAVISUALIZATION_DETAILS_NEW.triggered:
			return {...state, loading: true}

		case CREATE_DATAVISUALIZATION_DETAILS_NEW.succeeded:
			const actionResp = action && action.response && action.response.data
			const createdScreenId = !isEmpty(actionResp) && actionResp.screenId
			let configData1 = {...state.configData}
			if (createdScreenId) {
				configData1[createdScreenId] = action.response.data ? [action.response.data] : {}
			}
			return {
				...state,
				loading: false,
				configData: configData1,
			}

		case CREATE_DATAVISUALIZATION_DETAILS_NEW.failed:
			return {...state, loading: false}

		case UPDATE_DATAVISUALIZATION_DETAILS_NEW.triggered:
			return {...state, loading: true}

		case UPDATE_DATAVISUALIZATION_DETAILS_NEW.succeeded:
			const actionRespn = action && action.response && action.response.data
			const updatedScreenId = !isEmpty(actionRespn) && actionRespn.screenId
			let configData2 = {...state.configData}
			if (updatedScreenId) {
				configData2[updatedScreenId] = action.response.data ? [action.response.data] : {}
			}
			return {
				...state,
				loading: false,
				configData: configData2,
			}

		case UPDATE_DATAVISUALIZATION_DETAILS_NEW.failed:
			return {...state, loading: false}
	}

	return state
}
