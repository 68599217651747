export interface ILocateType {
	LOCATE: string
	PTH: string
	BORROW: string
}

export interface ISecurityType {
	WTC: string
	HTB: string
	WARM: string
	RES: string
}

export interface SettingsConfig {
	gcRate: number
	inventoryPercentage: number
	isAutoLocate: boolean
	isClientConfirmationRequired: boolean
	maximumQuantity: number
}

export const LocateType: ILocateType = {
	LOCATE: 'LOCATE',
	PTH: 'PTH',
	BORROW: 'BORROW',
}

export const SecurityType: ISecurityType = {
	WTC: 'Watch List',
	HTB: 'Hard To Borrow',
	WARM: 'Warm List',
	RES: 'Restricted List',
}

export const RejectedReasons = {
	noInventoryAvailable: 'No Inventory available',
	inRestrictedList: 'Security in restricted list',
	noLongerNeeded: 'No Longer Needed',
	feesAreTooHigh: 'Fees are too high',
	other: 'Other',
}
