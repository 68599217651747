import {FormatUtil} from 'helper-util'
import gql from 'graphql-tag'

import {QueryConfig} from '../collateralBasketList/collateralBasket/collateralBasketModel'

export const getBasketProgressDataQuery = (orderId: string, isScheduleAllowed) => gql`
query
  {
    order(orderId: "${orderId}") {
      orderId
      allInRate
      referenceId
      durationInSec
      strategy
      rateType
      spread
      assetType
      assetSubType
      startCash {
        amount
      }
      endCash {
        amount
      }
      repoInterest
      index
      responses {
        responseId
      }
      price{
        amount
        currency
      }
      bid {
        type
        cash {
          amount
          currency
          tradeDate
          maturityDate
          durationInSec
        }
        singleStock {
          cusip
          isin
          price
          priceMultiplier
          quantity
          securityId
        }
        actions
        way
        collateral {
          collateralBasketId
          includeETF
          name
          state
          currency
          strategy
          assetTypes
          schedule
          index
          notionalAmount
          targetAmount
			    targetAmountField
          weighting
          distinctSecurities
          isConstraintPool
          minimumRating
          includeSpecialAndHTB
          includeDividendsPayingInNext10Days
          sector
          schedule
          items {
            assetType
            assetMaturityDate
            collateralBasketItemId
            status
            ticker
            cusip
            isin
            price
            priceMultiplier
            classification
            quantity
            basketQuantity
            securityId
            schedule
            cusipCode
            scheduleDescription
            margin
            securityDescription
            fitchRating
            moodyRating
            dirtyPrice {
              amount
            }
            startCash {
              amount
            }
            endCash {
              amount
            }
            repoInterest
          }
        }
      }
      offer {
        type
        cash {
          amount
          currency
          tradeDate
          maturityDate
          durationInSec
        }
        singleStock {
          cusip
          isin
          price
          priceMultiplier
          quantity
          securityId
        }
        actions
        way
        collateral {
          collateralBasketId
          name
          state
          currency
          strategy
          assetTypes
          index
          notionalAmount
          targetAmount
			    targetAmountField
          weighting
          distinctSecurities
          isConstraintPool
          minimumRating
          includeSpecialAndHTB
          includeDividendsPayingInNext10Days
          sector
          includeETF
          schedule
          items {
            assetType
            assetMaturityDate
            collateralBasketItemId
            status
            ticker
            cusip
            isin
            price
            priceMultiplier
            classification
            quantity
            basketQuantity
            securityId
            schedule
            cusipCode
            scheduleDescription
            margin
            securityDescription
            fitchRating
            moodyRating
            dirtyPrice {
              amount
            }
            startCash {
              amount
            }
            endCash {
              amount
            }
            repoInterest
          }
        }
      }
    }
  }`

export const securitySuggestionsQuery = (keyword: string, schedule, book: string = null, clientRiskType = null) => gql`
query {
  securitySuggestions(keyword: "${FormatUtil.text.toUpperCase(keyword)}",
  schedule:${JSON.stringify(schedule)},
  book:${JSON.stringify(book)}
  clientRiskType:${JSON.stringify(clientRiskType)},
  ) {
    minLotSize
    securityId
    cusip
    ticker
    assetType
    assetSubtype
    isin
    availableQuantity
    currency
    margin
    price
    priceMultiplier
    sedol
    ric
    minPiece
    minIncrement
    cusipCode
    schedule
    scheduleDescription
    margin
    securityDescription
  }
}
`
export const enrichedSecuritySuggestionsQuery = (keyword: string, config: QueryConfig) => gql`
query {
  enrichedSecuritiesWithConstraints(keyword: "${FormatUtil.text.toUpperCase(keyword)}",
   schedule:${JSON.stringify(config.schedule)}
  ) {
    securityId
    cusip
    ticker
    assetType
    assetSubtype
    isin
    availableQuantity
    currency
    margin
    price
    priceMultiplier
    sedol
    ric
    minPiece
    minIncrement
    cusipCode
    schedule
    scheduleDescription
  }
}
`

export const getCollateralBasketsQuery = (flag: boolean) => gql`
	{
		openCollateralBaskets {
			book
			collateralBasketId
			name
			orderId
			sector
			weighting {
				weight
				code
			}
			distinctSecurities
			isConstraintPool
			minimumRating
			includeSpecialAndHTB
			includeDividendsPayingInNext10Days
			includeRestrictedSecurities
			strategy {
				strategy
				code
			}
			currency
			assetTypes
			index
			notionalAmount
			createdDate
			schedule
			counterParty
			collateralBasketItems {
				assetMaturityDate
				collateralBasketItemId
				securityId
				classification
				ticker
				cusip
				isin
				price
				priceMultiplier
				quantity
				availableQuantity
				schedule
				cusipCode
				scheduleDescription
				securityDescription
				assetType
				margin
				fitchRating
				moodyRating
				dirtyPrice {
					amount
				}
				startCash {
					amount
				}
				endCash {
					amount
				}
				repoInterest
			}
		}
	}
`
