import React from 'react'
import ErrorModal from './errorModal'

interface Props {}
interface State {
	hasError: boolean
}
export default class ErrorBoundary extends React.Component<Props, State> {
	constructor(props) {
		super(props)
		this.state = {hasError: false}
	}

	static getDerivedStateFromError(error) {
		return {hasError: true}
	}

	componentDidCatch(error, errorInfo) {
		console.log(error, errorInfo)
	}

	render() {
		if (this.state.hasError) {
			return <ErrorModal errorMessage={''} resetError={() => {}} />
		}
		return this.props.children
	}
}
