import {createFetchActions} from '../../../services/createActions'
import {getPMSClient} from '../../../index'
import {getSettlementDetailsDataQuery} from './settlementLadderDetailsQueries'

export const GET_SETTLEMENT_DETAILS_DATA = createFetchActions('GET_SETTLEMENT_DETAILS_DATA')
export const CLEAR_SETTLEMENT_DETAILS_DATA = 'CLEAR_SETTLEMENT_DETAILS_DATA'

export const getSettlementDetailsData = () => {
	return function (dispatch) {
		dispatch({type: GET_SETTLEMENT_DETAILS_DATA.triggered})
		getPMSClient()
			.query({
				query: getSettlementDetailsDataQuery,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_SETTLEMENT_DETAILS_DATA.succeeded,
					response,
				})
			})
	}
}

export const clearSettlementDetailsData = () => {
	return {
		type: CLEAR_SETTLEMENT_DETAILS_DATA,
	}
}
