import {
	FETCH_LOCATE_AUTHORIZER_DETAILS,
	SEARCH_LOCATE_REQUEST,
	FETCH_LOCATE_CONFIG_DETAILS,
	UPDATE_LOCATE_CONFIG,
	FETCH_SECURITY_TYPE_LIST,
	CREATE_UPDATE_SECURITY_WATCHLIST,
	FETCH_FEE_CENTS_PER_SHARE_CALCULATION,
	FETCH_LOCATE_REQUEST_ENTRY_DETAIL,
	ADD_AVAILABILITY_FROM_LOCATE,
	FETCH_SECURITY_LIST_AUDIT_DETAIL,
	AUTHORIZE_LOCATE_REQUEST,
	CLEAR_MAIN_GRID_DATA,
} from './LocateAuthorizerActions'
import {embedShortfall} from './LocateAuthorizerHelper'

export interface AlpAuthorizer {
	loading: boolean
	gridLoading: boolean
	locateSearchData: any[]
	locateRequestQueueData: any
	locateSearchBucketData: any
	locateCompletedRequestQueueData: any[]
	locateClientList: any[]
	searchEntriesData: any
	viewAllData: any[]
	wishlist: any[]
	isConfigLoading: boolean
	config: any
	isSecurityWishListLoading: boolean
	securityWishlist: any
	isSelectedRequestRateLoading: boolean
	selectedRequestRate: any
	selectedLocateRequestEntryDetail: any
	securityListAuditDetails: any
}

const initialState: AlpAuthorizer = {
	loading: false,
	gridLoading: false,
	locateSearchData: [],
	locateRequestQueueData: null,
	locateSearchBucketData: [],
	locateCompletedRequestQueueData: [],
	locateClientList: [],
	searchEntriesData: {searchEntries: [], locateFieldsData: {}},
	viewAllData: [],
	wishlist: [],
	isConfigLoading: false,
	config: [],
	isSecurityWishListLoading: false,
	securityWishlist: [],
	isSelectedRequestRateLoading: false,
	selectedRequestRate: {},
	selectedLocateRequestEntryDetail: {},
	securityListAuditDetails: [],
}

export function AlpAuthorizerReducer(state = initialState, action: any) {
	switch (action.type) {
		case FETCH_LOCATE_AUTHORIZER_DETAILS.triggered:
			return {...state, locateRequestQueueData: null, gridLoading: true}
		case FETCH_LOCATE_AUTHORIZER_DETAILS.failed:
			return {...state, locateRequestQueueData: [], gridLoading: false}
		case FETCH_LOCATE_AUTHORIZER_DETAILS.succeeded:
			const locateRequestQueueData: any[] = action.response.data && action.response.data.allAuthorizerLocateRequestEntry
			return {...state, locateRequestQueueData: embedShortfall(locateRequestQueueData), gridLoading: false}

		case SEARCH_LOCATE_REQUEST.triggered:
			return {...state, loading: true}
		case SEARCH_LOCATE_REQUEST.failed:
			return {...state, loading: false}
		case SEARCH_LOCATE_REQUEST.succeeded:
			return {...state, loading: false}

		case FETCH_LOCATE_CONFIG_DETAILS.triggered:
			return {...state, isConfigLoading: true}
		case FETCH_LOCATE_CONFIG_DETAILS.failed:
			return {...state, isConfigLoading: false}
		case FETCH_LOCATE_CONFIG_DETAILS.succeeded:
			const configData = action.response.data && action.response.data.locateConfig
			return {...state, config: configData, isConfigLoading: false}

		case UPDATE_LOCATE_CONFIG.triggered:
			return {...state, isConfigLoading: true}
		case UPDATE_LOCATE_CONFIG.failed:
			return {...state, isConfigLoading: false}
		case UPDATE_LOCATE_CONFIG.succeeded:
			return {...state, isConfigLoading: false}

		case AUTHORIZE_LOCATE_REQUEST.triggered:
			return {...state, loading: true}
		case AUTHORIZE_LOCATE_REQUEST.failed:
			return {...state, loading: false}
		case AUTHORIZE_LOCATE_REQUEST.succeeded:
			return {...state, loading: false}

		case FETCH_SECURITY_TYPE_LIST.triggered:
			return {...state, isSecurityWishListLoading: true}
		case FETCH_SECURITY_TYPE_LIST.failed:
			return {...state, isSecurityWishListLoading: false}
		case FETCH_SECURITY_TYPE_LIST.succeeded:
			return {
				...state,
				isSecurityWishListLoading: false,
				securityWishlist: action.response.data && action.response.data.securityList,
			}

		case CREATE_UPDATE_SECURITY_WATCHLIST.triggered:
			return {...state, loading: true}
		case CREATE_UPDATE_SECURITY_WATCHLIST.failed:
			return {...state, loading: false}
		case CREATE_UPDATE_SECURITY_WATCHLIST.succeeded:
			return {...state, loading: false}

		case FETCH_FEE_CENTS_PER_SHARE_CALCULATION.triggered:
			return {...state, selectedRequestRate: {}, isSelectedRequestRateLoading: true}
		case FETCH_FEE_CENTS_PER_SHARE_CALCULATION.failed:
			return {...state, isSelectedRequestRateLoading: false}
		case FETCH_FEE_CENTS_PER_SHARE_CALCULATION.succeeded:
			const selectedRequestRate = action.response.data && action.response.data.rate
			return {...state, selectedRequestRate, isSelectedRequestRateLoading: false}

		case FETCH_LOCATE_REQUEST_ENTRY_DETAIL.triggered:
			return {...state, selectedLocateRequestEntryDetail: {}, loading: true}
		case FETCH_LOCATE_REQUEST_ENTRY_DETAIL.failed:
			return {...state, selectedLocateRequestEntryDetail: {}, loading: false}
		case FETCH_LOCATE_REQUEST_ENTRY_DETAIL.succeeded:
			const selectedLocateRequestEntryDetail = action.response.data && action.response.data.locateRequestEntryDetail
			return {...state, selectedLocateRequestEntryDetail, loading: false}

		case ADD_AVAILABILITY_FROM_LOCATE.triggered ||
			ADD_AVAILABILITY_FROM_LOCATE.failed ||
			ADD_AVAILABILITY_FROM_LOCATE.succeeded:
			return {...state}

		case FETCH_SECURITY_LIST_AUDIT_DETAIL.triggered:
			return {...state}
		case FETCH_SECURITY_LIST_AUDIT_DETAIL.failed:
			return {...state}
		case FETCH_SECURITY_LIST_AUDIT_DETAIL.succeeded:
			const securityListAuditDetails = action.response.data && action.response.data.securityListAuditDetails
			return {...state, securityListAuditDetails}

		case CLEAR_MAIN_GRID_DATA:
			return {
				...state,
				locateRequestQueueData: null,
				config: null,
			}
	}
	return state
}
