import {createFetchActions} from '../../services/createActions'
import {get, post} from '../../services/createAPICall'
import {getPMSBaseUrl} from '../../services/apiService'
import {getPMSClient} from '../..'
import {getInventoryFileQuery} from './inventoryOptimizationQueries'

export const POST_INVENTORY_OPTIMIZATION_FILE = createFetchActions('POST_INVENTORY_OPTIMIZATION_FILE')
export const POST_INVENTORY_OPTIMIZATION_COMPUTATION = createFetchActions('POST_INVENTORY_OPTIMIZATION_COMPUTATION')
export const POST_INVENTORY_OPTIMIZATION_COMPUTATION_SUMMARY = createFetchActions(
	'POST_INVENTORY_OPTIMIZATION_COMPUTATION_SUMMARY'
)
export const SAVE_INVENTORY_OPTIMIZATION_COMPUTATION = createFetchActions('SAVE_INVENTORY_OPTIMIZATION_COMPUTATION')
export const GET_INVENTORY_FILE_DATA = createFetchActions('GET_INVENTORY_FILE_DATA')
export const GET_INVENTORY_FILE_DETAILS = createFetchActions('GET_INVENTORY_FILE_DETAILS')
export const SET_BASKET_TYPE = 'SET_BASKET_TYPE'
export const SET_PRESERVE_DATA = 'SET_PRESERVE_DATA'

export const postInventoryOptimizationFile = req => (dispatch: any) => {
	return dispatch(
		post(
			POST_INVENTORY_OPTIMIZATION_FILE,
			`${getPMSBaseUrl()}/v1/inventory/optimization/upload`,
			req,
			null,
			'Invalid basket file uploaded'
		)
	)
}

export const postInventoryOptimizationComputation = req => (dispatch: any) => {
	return dispatch(post(POST_INVENTORY_OPTIMIZATION_COMPUTATION, `${getPMSBaseUrl()}/v1/inventory/optimization`, req))
}

export const postInventoryOptimizationComputationSummary = req => (dispatch: any) => {
	return dispatch(post(POST_INVENTORY_OPTIMIZATION_COMPUTATION_SUMMARY, `${getPMSBaseUrl()}/v1/inventory/summary`, req))
}

export const saveComputation = req => (dispatch: any) => {
	return dispatch(post(SAVE_INVENTORY_OPTIMIZATION_COMPUTATION, `${getPMSBaseUrl()}/v1/inventory/save`, req))
}

export const getInventoryFileData = () => {
	return function (dispatch) {
		dispatch({
			type: GET_INVENTORY_FILE_DATA.triggered,
		})
		getPMSClient()
			.query({
				query: getInventoryFileQuery,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_INVENTORY_FILE_DATA.succeeded,
					response,
				})
			})
	}
}

export const getFileDetails = req => (dispatch: any) => {
	return dispatch(get(GET_INVENTORY_FILE_DETAILS, `${getPMSBaseUrl()}/v1/inventory/${req}`))
}

export const setBasketType = type => {
	return {
		type: SET_BASKET_TYPE,
		response: {
			data: type,
		},
	}
}

export const preserveData = data => {
	return {
		type: SET_PRESERVE_DATA,
		response: {
			data: data,
		},
	}
}
