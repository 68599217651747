import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import CustomPopover from './customPopover'

import {MainState} from '../../../store/mainReducer'

const mapStateToProps = (state: MainState) => {
	return {
		isHelpOn: state.app.suggestion,
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomPopover)
