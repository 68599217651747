import {
	POST_INVENTORY_OPTIMIZATION_FILE,
	POST_INVENTORY_OPTIMIZATION_COMPUTATION,
	SET_BASKET_TYPE,
	SET_PRESERVE_DATA,
	POST_INVENTORY_OPTIMIZATION_COMPUTATION_SUMMARY,
	GET_INVENTORY_FILE_DATA,
	GET_INVENTORY_FILE_DETAILS,
} from './inventoryOptimizationActions'

export interface InventoryOptimization {
	loading: boolean
	loadingSummaryData: boolean
	loadingPageData: boolean
	computedData: any
	pageData: any
	basketType: string
	summaryData: any
	preservedData: any
	inventoryFileData: any
}

const initialState: InventoryOptimization = {
	loading: false,
	loadingSummaryData: true,
	loadingPageData: true,
	computedData: {},
	pageData: {},
	basketType: '',
	summaryData: [],
	preservedData: {},
	inventoryFileData: {},
}

export function inventoryOptimizationReducer(state = initialState, action: any) {
	switch (action.type) {
		case POST_INVENTORY_OPTIMIZATION_FILE.triggered:
			return {...state, loading: true}
		case POST_INVENTORY_OPTIMIZATION_FILE.failed:
			return {...state, loading: false}
		case POST_INVENTORY_OPTIMIZATION_FILE.succeeded:
			state.computedData = action.response.data
			return {...state, loading: false}
		case GET_INVENTORY_FILE_DETAILS.triggered:
			return {...state, loading: true}
		case GET_INVENTORY_FILE_DETAILS.failed:
			return {...state, loading: false}
		case GET_INVENTORY_FILE_DETAILS.succeeded:
			state.computedData = action.response.data
			return {...state, loading: false}
		case POST_INVENTORY_OPTIMIZATION_COMPUTATION.triggered:
			return {...state, loadingPageData: true, loading: true}
		case POST_INVENTORY_OPTIMIZATION_COMPUTATION.failed:
			state.pageData = {}
			return {...state, loadingPageData: false, loading: false}
		case POST_INVENTORY_OPTIMIZATION_COMPUTATION.succeeded:
			state.pageData = action.response.data
			return {...state, loadingPageData: false, loading: false}

		case POST_INVENTORY_OPTIMIZATION_COMPUTATION_SUMMARY.triggered:
			return {...state, loadingSummaryData: true, loading: true}
		case POST_INVENTORY_OPTIMIZATION_COMPUTATION_SUMMARY.failed:
			state.summaryData = []
			return {...state, loadingSummaryData: false, loading: false}
		case POST_INVENTORY_OPTIMIZATION_COMPUTATION_SUMMARY.succeeded:
			state.summaryData = action.response.data
			return {...state, loadingSummaryData: false, loading: false}
		case GET_INVENTORY_FILE_DATA.triggered:
			return {...state, loading: true}
		case GET_INVENTORY_FILE_DATA.failed:
			return {...state, loading: false}
		case GET_INVENTORY_FILE_DATA.succeeded:
			state.inventoryFileData = action.response.data
			return {...state, loading: false}
		case SET_BASKET_TYPE:
			state.basketType = action.response.data
			return {...state}

		case SET_PRESERVE_DATA:
			state.preservedData = action.response.data
			return {...state}
	}
	return state
}
