import memoizeOne from 'memoize-one'
import isDeepEqual from 'lodash.isequal'
import {ArrayUtil} from 'helper-util'

import {UserGroup} from './userGroupModel'
import {globalColumnDefinition} from '../../../common/AgGrid/AgGridColumn'

export const userGroupIntialise = (): UserGroup => {
	return {
		accounts: [],
		accountNames: '',
		clientId: null,
		componentGroups: [],
		componentGroupNames: '',
		fullName: '',
		groupId: '',
		id: 0,
		isComposite: false,
		isInternal: false,
		modules: [],
		orgId: 0,
		strategies: [],
		selectedComponentGroupId: [],
		selectedAccountId: [],
		selectedStrategiesId: [],
		shortName: '',
		selectedModuleId: [],
		tenantId: '',
	}
}

export const getColumns = memoizeOne((isWritePermission, setFormData) => {
	return [
		Object.assign({}, globalColumnDefinition.fullName, {
			headerName: 'Group Name',
			onCellClicked: params => {
				isWritePermission && setFormData(params.data)
			},
			cellClass: [isWritePermission ? 'cursor-pointer text-link' : ''],
		}),
		globalColumnDefinition.shortName,
		globalColumnDefinition.accountNames,
		globalColumnDefinition.componentGroupNames,
	]
}, isDeepEqual)

export const getRowData = memoizeOne(data => {
	!ArrayUtil.isEmpty(data) &&
		data.forEach(item => {
			if (!item.accountNames) item.accountNames = ''
			if (!item.componentGroupNames) item.componentGroupNames = ''
		})
	return data
}, isDeepEqual)

export const isFormValidFn = userGroup => {
	const {fullName, shortName} = userGroup
	const {selectedModuleId, selectedComponentGroupId, isComposite} = userGroup
	let valid = !!(fullName && fullName.trim()) && !!(shortName && shortName.trim())
	const isComponentGroupValid = !ArrayUtil.isEmpty(selectedModuleId)
	const isCompositeGroupValid = !ArrayUtil.isEmpty(selectedComponentGroupId)
	valid = valid && isComposite ? isCompositeGroupValid : isComponentGroupValid
	return valid
}

export const modifyData = (userGroupData: UserGroup[]) => {
	return (
		!ArrayUtil.isEmpty(userGroupData) &&
		userGroupData.map(data => {
			const accountNames = !data.isComposite && data.accounts && data.accounts.join(', ')
			const componentGroupNames = data.isComposite && data.componentGroups && data.componentGroups.join(', ')
			return {
				...data,
				accountNames,
				componentGroupNames,
			}
		})
	)
}

export const modifyOptions = memoizeOne((optionsData: any[], id, name) => {
	const data = []
	!ArrayUtil.isEmpty(optionsData) &&
		optionsData.forEach(item => {
			const option = {
				...item,
				defaultId: item.id,
				id: item[id],
				label: item[name],
				value: item[id],
			}
			data.push(option)
		})
	return data
}, isDeepEqual)

export const getUserGroupsDataOptions = memoizeOne(data => {
	return modifyOptions(
		data.filter(group => !group.isComposite),
		'groupId',
		'fullName'
	)
}, isDeepEqual)

