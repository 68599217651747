export const defaultColDef = {
	minWidth: 90,
	flex: 1,
	filter: true,
	resizable: true,
	sortable: true,
	headerCheckboxSelectionFilteredOnly: true,
	menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
}

export const statusBar = {
	statusPanels: [
		{
			statusPanel: 'agTotalAndFilteredRowCountComponent',
			align: 'left',
		},
		{
			statusPanel: 'agTotalRowCountComponent',
			align: 'left',
		},
		{statusPanel: 'agFilteredRowCountComponent'},
		{statusPanel: 'agSelectedRowCountComponent'},
		{statusPanel: 'agAggregationComponent'},
	],
}

export const overlayLoadingTemplate = 'Please wait for data to load'

export const overlayNoRowsTemplate = 'No rows to show'
