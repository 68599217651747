import {UPLOAD_BENCHMARK_WEIGHTS_FILE, GET_ALL_UPLOADED_BENCHMARK_WEIGHTS_FILES} from './BenchmarkWeightsActions'

export interface BenchmarkWeights {
	loading: boolean
	allFilesData: any
}

const initialState: BenchmarkWeights = {
	loading: false,
	allFilesData: {},
}

export function BenchmarkWeightsReducer(state = initialState, action: any) {
	switch (action.type) {
		case UPLOAD_BENCHMARK_WEIGHTS_FILE.triggered:
			return {...state, loading: true}
		case UPLOAD_BENCHMARK_WEIGHTS_FILE.failed:
			return {...state, loading: false}
		case UPLOAD_BENCHMARK_WEIGHTS_FILE.succeeded:
			return {
				...state,
				loading: false,
			}

		case GET_ALL_UPLOADED_BENCHMARK_WEIGHTS_FILES.triggered:
			return {...state, loading: true}
		case GET_ALL_UPLOADED_BENCHMARK_WEIGHTS_FILES.failed:
			return {...state, loading: false}
		case GET_ALL_UPLOADED_BENCHMARK_WEIGHTS_FILES.succeeded:
			return {
				...state,
				allFilesData: action.response.data,
				loading: false,
			}
	}
	return state
}
