import {GET_ALL_MATURING_TRADES, RESET_ALL_TRADES} from './maturingTradesActions'

export interface MaturingTrades {
	loading: boolean
	maturingTradeEntries: any
}

const initialState: MaturingTrades = {
	loading: true,
	maturingTradeEntries: [],
}

export function maturingTradesReducer(state = initialState, action: any) {
	switch (action.type) {
		case RESET_ALL_TRADES:
			return {...state, loading: true, maturingTradeEntries: {}}

		case GET_ALL_MATURING_TRADES.triggered:
			return {...state, loading: true}
		case GET_ALL_MATURING_TRADES.failed:
			return {...state, loading: false}
		case GET_ALL_MATURING_TRADES.succeeded:
			const maturingTradeEntries =
				action && action.response && action.response.data && action.response.data.matureTrades
			return {
				...state,
				maturingTradeEntries,
				loading: false,
			}
	}
	return state
}
