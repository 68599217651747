import gql from 'graphql-tag'

export const fetchLocateDetailsQuery = () => {
	return gql`
		query {
			allRequesterLocateRequestEntry {
				locateRequestEntryId
				securityId
				securityListTypes
				requestedQuantity
				businessDate
				locateRequestType
				security
				securityIdType
				status
				endDate
				counterParty
				authorizer
				actions
				createdDate
				modifiedDate
				totalApprovedQuantity
				description
				marketPrice {
					amount
					currency
				}
				indicativeRateBps
				centsPerShare
				totalMarketFee
				userEmail
				notional {
					amount
					currency
				}
				ticker
			}
		}
	`
}

export const getLocateRequestEntryDetail = locateRequestEntryId => {
	return gql`
		query {
			locateRequestEntryDetail(locateRequestEntryId: "${locateRequestEntryId}") {
				approvedQuantity
				availableQuantity
				marketPrice {
					amount
					currency
				}
				indicativeRateBps
				centsPerShare
				totalMarketFee
				notional {
					amount
					currency
				}
			}
		}
	`
}
