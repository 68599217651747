import gql from 'graphql-tag'

export const cashPositionAndFlowQuery = gql`
	query {
		cashOverview {
			cashPosition {
				value {
					amount
					currency
				}
				isStale
			}
			cashFlow {
				netCashFlow {
					amount
					currency
				}
				netCashIn {
					amount
					currency
				}
				netCashOut {
					amount
					currency
				}
			}
		}
	}
`
