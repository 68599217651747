import {connect} from 'react-redux'
import {MainState} from '../../store/mainReducer'
import {bindActionCreators} from 'redux'
import NavigationBar from './navigationBarComponent'
import {getUser} from '../../services/authService'
import {getPortfolioUpdateDateTime} from '../../pages/portfolioManager/portfolioMangerActions'
import {
	setDarkTheme,
	setSuggestions,
	getExecutionNotifications,
	getLocateNotifications,
	getLocateClientNotifications,
	authLogout,
} from '../../app/appActions'
import {getActiveMenu} from '../customiseMenu/customiseMenuActions'
import {clearTenantConfig} from '../../pages/login/loginActions'
import {getBaseCurrency} from '../../services/clientConfigurationService'

const mapStateToProps = (state: MainState) => {
	return {
		user: getUser(),
		location: state.router.location,
		portfolioDetails: state.portfolioManager.portfolioUpdatedDateTime,
		baseCurrency: getBaseCurrency(),
		pfUploadOngoing: state.portfolioManager.pfUploadOngoing,
		executionNotifications: state.app.executionNotifications,
		activeMenuData: state.customiseMenu.activeMenuData,
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return bindActionCreators(
		{
			authLogout,
			getPortfolioUpdateDateTime,
			setDarkTheme,
			setSuggestions,
			getExecutionNotifications,
			getActiveMenu,
			getLocateNotifications,
			getLocateClientNotifications,
			clearTenantConfig,
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar)
