import gql from 'graphql-tag'

export const intraDayActivitiesQuery = gql`
	query {
		portfolio {
			intraDayActivities {
				actions
				abcmCategoryName
				activityType
				allInRate
				assetClassification
				assetMaturityDate
				assetSubType
				assetType
				availableQuantity
				balanceSheetContribution {
					amount
					currency
				}
				baseNotional {
					amount
					currency
				}
				basePrice {
					amount
					currency
				}
				bbId
				book
				borrowFee
				loanFee
				buySellInd
				collateralType
				collateral {
					amount
					currency
				}
				counterParty
				createdBy
				createdDate
				cusip
				effectiveBaseNotional {
					amount
					currency
				}
				endDate
				exchangeRate
				fitchRating
				fund
				haircut
				hqla
				id
				inefficiencies {
					inefficiency
					abbr
					displayName
				}
				index
				isin
				legalEntity
				maturityDate
				maturityType
				moodyRating
				needQuantity
				notional {
					amount
					currency
				}
				originalTradeType
				poolFactor
				portfolioEntryType
				partial
				price {
					amount
					currency
				}
				priceMultiplier
				quantity
				recallInefficiencyContribution {
					amount
					currency
				}
				recallQuantity
				referenceRate
				returnInefficiencyContribution {
					amount
					currency
				}
				returnQuantity
				availabilitiesContribution {
					amount
					currency
				}
				needsContribution {
					amount
					currency
				}
				platformTradeId
				rebate
				originalTradeType
				legalEntity
				ric
				sector
				securityId
				securityIdType
				settlementDate
				settlementStatus
				snpRating
				sourceSystem
				sourceSystemTradeId
				spread
				startCash {
					amount
					currency
				}
				startDate
				tenor
				termDate
				tradeDate
				ticker
				tradeType
			}
		}
	}
`
