import gql from 'graphql-tag'
import {buildQuery} from 'axios-graphql-builder'

export const fetchDMNTemplateBasedOnId = id => {
	return gql`
  query {
    templateEntry(
      templateId:"${id}"
    )
    {
        templateId
        templateBody
    }
  }
	`
}

export const fetchAllDMNTemplatesQuery = () => {
	return gql`
		query {
			templateList {
				templateId
				templateType
				templateSubType
				clientName
			}
		}
	`
}

export const fetchCollateralScheduleClients = () => {
	const scheduleClients = {
		query: {
			collateralScheduleDropDowns: {
				clientDetails: null,
				clientDetailsShortNames: null,
			},
		},
	}
	return buildQuery(scheduleClients)
}
