import React from 'react'
import {AccessDeniedIcon} from '../../styles/svg/svgIcons'
import {RouteComponentProps} from 'react-router-dom'
import Header from '../../features/Header/HeaderComponent'

export default class AccessDenied extends React.Component<RouteComponentProps> {
	render() {
		const pageTitle = this.props.location && this.props.location.state && this.props.location.state.pageTitle
		return (
			<>
				<Header title='' />
				<div className='d-flex flex-fill m-3 overflow-auto' id='main-content'>
					<div className='d-flex flex-column justify-content-center align-items-center flex-grow-1 card'>
						<AccessDeniedIcon />
						<h3 className='error-page-text'>
							<span className='error-page-text'>{pageTitle || ''}</span> Access Denied
						</h3>
						<div className='f-16 error-page-text'>
							Sorry, but you do not have the permission to access this page or perform this action{' '}
						</div>
						<div className='f-16 error-page-text'>Please contact your administrator </div>
					</div>
				</div>
			</>
		)
	}
}
