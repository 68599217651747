import React, {useMemo} from 'react'
import {useSelector} from 'react-redux'

import {getConstraints} from './AdditionalDetailsHelper'
import {MainState} from '../../../../store/mainReducer'
// import FsMultiSelectDropdown from '../../../../common/dropdowns/fsMultiSelectDropdown'
import FsSingleSelectDropdown from '../../../../common/dropdowns/fsSingleSelectDropdown'

interface Props {
	allData: any
	allowedSelectedStep: any
	mandatory: any[]
	extra?: any
	onChange: (key: string, value: any) => any
}

export const AdditionalDetails = (props: Props) => {
	const {collateralBasketConstraints} = useSelector((state: MainState) => state.collateralBasket)

	const {allData, allowedSelectedStep, onChange} = props
	const data = allData[allowedSelectedStep.id]

	const constraints = useMemo(() => getConstraints(collateralBasketConstraints), [collateralBasketConstraints])

	return (
		<div className='col'>
			<div className='row h-100 px-4'>
				<div className='col-10 pl-3'>
					<div className='w-100'>
						<FsSingleSelectDropdown
							title='Asset Type'
							id='assetType'
							titleClass='mandatory*'
							ddClass='form-sm mt-3'
							noSelectionText='Select'
							selectedItem={data['assetType'] ? data['assetType'].label : ''}
							dropdownItems={constraints['assetTypes'] || []}
							keyField='id'
							valueField='label'
							onSelect={value => onChange('assetType', value)}
							disabled={!Boolean(constraints['assetTypes'])}
						/>
						{/* <FsMultiSelectDropdown
							data={data['assetTypes'] || []}
							label='Asset Type'
							options={constraints['assetTypes'] || []}
							isMandatory
							isDisabled={!Boolean(constraints['assetTypes'])}
							onChange={value => onChange('assetTypes', value)}
						/> */}
						<FsSingleSelectDropdown
							title='Asset Subtype'
							id='assetSubtype'
							titleClass='mandatory*'
							ddClass='form-sm mt-3'
							noSelectionText='Select'
							selectedItem={data['assetSubType'] ? data['assetSubType'].label : ''}
							dropdownItems={constraints['assetSubTypes'] || []}
							keyField='id'
							valueField='label'
							onSelect={value => onChange('assetSubType', value)}
							disabled={!Boolean(constraints['assetSubTypes'])}
						/>
						{/* <FsMultiSelectDropdown
							data={data['assetSubTypes'] || []}
							label='Asset Subtype'
							options={constraints['assetSubTypes'] || []}
							isMandatory
							isDisabled={!Boolean(constraints['assetSubTypes'])}
							onChange={value => onChange('assetSubTypes', value)}
						/> */}
						<FsSingleSelectDropdown
							title='Index'
							id='index'
							titleClass='mandatory*'
							ddClass='form-sm mt-3'
							noSelectionText='Select'
							selectedItem={data['index'] ? data['index'].label : ''}
							dropdownItems={constraints['indices'] || []}
							keyField='id'
							valueField='label'
							onSelect={value => onChange('index', value)}
							disabled={!Boolean(constraints['indices'])}
						/>
						{/* <FsMultiSelectDropdown
							data={data['indices'] || []}
							label='Index'
							options={constraints['indices'] || []}
							isMandatory
							isDisabled={!Boolean(constraints['indices'])}
							onChange={value => onChange('indices', value)}
						/> */}
						{/* <FsMultiSelectDropdown
							data={data['funds'] || []}
							label='Fund'
							options={constraints['funds'] || []}
							isMandatory
							isDisabled={!Boolean(constraints['funds'])}
							onChange={value => onChange('funds', value)}
						/>
						<FsMultiSelectDropdown
							data={data['ratings'] || []}
							label='Rating'
							options={constraints['ratings'] || []}
							isMandatory
							isDisabled={!Boolean(constraints['ratings'])}
							onChange={value => onChange('ratings', value)}
						/>
						<FsMultiSelectDropdown
							data={data['sectors'] || []}
							label='Sector'
							options={constraints['sectors'] || []}
							isMandatory
							isDisabled={!Boolean(constraints['sectors'])}
							onChange={value => onChange('sectors', value)}
						/> */}
					</div>
				</div>
			</div>
		</div>
	)
}
