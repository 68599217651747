import {GET_PREDICTED_CURVE_DETAILS} from './breakEvenPricerActions'

export interface BreakEvenPricer {
	loading: boolean
	pageData: any
}

const initialState: BreakEvenPricer = {
	loading: false,
	pageData: null,
}

export function breakEvenPricerReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_PREDICTED_CURVE_DETAILS.triggered:
			return {...state, pageData: null, loading: true}
		case GET_PREDICTED_CURVE_DETAILS.succeeded:
			return {...state, pageData: action.response.data, loading: false}
		case GET_PREDICTED_CURVE_DETAILS.failed:
			return {...state, pageData: {}, loading: false}
	}
	return state
}
