import gql from 'graphql-tag'

export const getCurrenciesQuery = gql`
	query {
		allCurrencies {
			fullName
			abbr
			code
		}
	}
`
export const getCountriesQuery = gql`
	query {
		allCountries {
			country {
				fullName
				abbr
				code
			}
			phoneCodes
		}
	}
`
export const getTimezoneQuery = gql`
	query {
		allTimeZones {
			fullName
			abbr
			zoneIdCode
			gmtOffset
			code
		}
	}
`
