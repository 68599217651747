import {
	GET_BASKET_DETAILS,
	UPLOAD_BASKET_DETAILS,
	REQUEST_LOAN_DETAILS,
	GET_DROPDOWN_DATA,
	FILTER_BASKET,
	SUBMIT_BASKET,
	GET_BASKET_FILE_DATA,
	SUBMIT_MATCHING_ALGORITHM,
	GET_BASKET_OVERVIEW,
	SUBMIT_LOAN,
	SUBMIT_LOAN_ENTRIES,
	DELETE_BASKET_FILE,
	DOWNLOAD_BASKET,
	FETCH_ACTIVITY_LOG,
	GET_PENDING_TARGET,
	UPDATE_PENDING_TARGET,
	FETCH_LOCATE_SEARCH_RESULT,
	FETCH_LOCATE_SEARCH_OPENED,
	SET_DATA_FROM_INDEX_ANALYSIS,
	RESET_NEEDS_AVAILIABILTY_DATA,
	UPLOAD_PB_BASKET,
	UPLOAD_SWAP_BASKET,
} from './needsAvailabilitiesActions'

export interface NeedsAvailabilities {
	pageData: any
	loanData: any
	dropdownData: any
	basketEntriesData: any
	basketFileData: any
	loading: boolean
	dataLoading: boolean
	basketOverview: any
	activityLog: any[]
	lendingTarget: number
	locateSearchData: any[]
	indexAnalysisData: any[]
	locateSearchId: any
}

const initialState: NeedsAvailabilities = {
	pageData: {},
	loanData: null,
	dropdownData: {},
	basketEntriesData: {},
	basketFileData: {},
	loading: true,
	dataLoading: false,
	basketOverview: {},
	activityLog: [],
	lendingTarget: 0,
	locateSearchData: [],
	indexAnalysisData: [],
	locateSearchId: '',
}

export function needsAvailabilitiesReducer(state = initialState, action: any) {
	switch (action.type) {
		case GET_BASKET_DETAILS.triggered:
			return {...state, dataLoading: true}
		case GET_BASKET_DETAILS.failed:
			return {...state, dataLoading: false}
		case GET_BASKET_DETAILS.succeeded:
			state.pageData = {
				...action.response.availability,
				...action.response.pbSbl,
				...action.response.swapAxe,
			}
			return {
				...state,
				dataLoading: false,
			}

		case UPLOAD_BASKET_DETAILS.triggered || UPLOAD_PB_BASKET.triggered || UPLOAD_SWAP_BASKET.triggered:
			return {...state, loading: true}
		case UPLOAD_BASKET_DETAILS.failed || UPLOAD_PB_BASKET.failed || UPLOAD_SWAP_BASKET.failed:
			return {...state, loading: false}
		case UPLOAD_BASKET_DETAILS.succeeded || UPLOAD_PB_BASKET.succeeded || UPLOAD_SWAP_BASKET.succeeded:
			return {...state, loading: false}

		case REQUEST_LOAN_DETAILS.triggered:
			return {...state, loanData: null, loading: true}
		case REQUEST_LOAN_DETAILS.failed:
			return {...state, loanData: {}, loading: false}
		case REQUEST_LOAN_DETAILS.succeeded:
			return {...state, loanData: action.response.data, loading: false}

		case FILTER_BASKET.triggered:
			return {...state, loading: true}
		case FILTER_BASKET.failed:
			return {...state, loading: false, basketEntriesData: {}}
		case FILTER_BASKET.succeeded:
			return {
				...state,
				basketEntriesData: action.response.data,
				loading: false,
			}
		case SUBMIT_BASKET.triggered:
			return {...state, loading: true}
		case SUBMIT_BASKET.failed:
			return {...state, loading: false}
		case SUBMIT_BASKET.succeeded:
			return {...state, loading: false}
		case GET_DROPDOWN_DATA.triggered:
			return {...state, loading: true}
		case GET_DROPDOWN_DATA.failed:
			return {...state, loading: false}
		case GET_DROPDOWN_DATA.succeeded:
			return {...state, loading: false, dropdownData: action.response.data}
		case GET_BASKET_FILE_DATA.triggered:
			return {...state, loading: true}
		case GET_BASKET_FILE_DATA.failed:
			return {...state, loading: false}
		case GET_BASKET_FILE_DATA.succeeded:
			return {
				...state,
				loading: false,
				basketFileData: action.response.data,
			}
		case SUBMIT_MATCHING_ALGORITHM.triggered:
			return {...state, loading: true}
		case SUBMIT_MATCHING_ALGORITHM.failed:
			return {...state, loading: false}
		case SUBMIT_MATCHING_ALGORITHM.succeeded:
			return {
				...state,
				loading: false,
			}
		case GET_BASKET_OVERVIEW.triggered:
			return {...state, loading: true}
		case GET_BASKET_OVERVIEW.failed:
			return {...state, loading: false}
		case GET_BASKET_OVERVIEW.succeeded:
			return {...state, loading: false, basketOverview: action.response.data.portfolio.overView}
		case SUBMIT_LOAN.triggered:
			return {...state, loading: true}
		case SUBMIT_LOAN.failed:
			return {...state, loading: false}
		case SUBMIT_LOAN.succeeded:
			return {...state, loading: false}
		case SUBMIT_LOAN_ENTRIES.triggered:
			return {...state, loading: true}
		case SUBMIT_LOAN_ENTRIES.failed:
			return {...state, loading: false}
		case SUBMIT_LOAN_ENTRIES.succeeded:
			return {...state, loading: false}
		case DELETE_BASKET_FILE.triggered:
			return {...state, loading: true}
		case DELETE_BASKET_FILE.failed:
			return {...state, loading: false}
		case DELETE_BASKET_FILE.succeeded:
			return {...state, loading: false}
		case DOWNLOAD_BASKET.triggered:
			return {...state, loading: true}
		case DOWNLOAD_BASKET.failed:
			return {...state, loading: false}
		case DOWNLOAD_BASKET.succeeded:
			return {...state, loading: false}
		case FETCH_ACTIVITY_LOG.triggered:
			return {...state, activityLog: [], loading: true}
		case FETCH_ACTIVITY_LOG.succeeded:
			const activityLog = action.response.data && action.response.data.availNeedOrderLogs
			return {...state, activityLog, loading: false}
		case FETCH_ACTIVITY_LOG.failed:
			return {...state, loading: false}
		case GET_PENDING_TARGET.triggered:
			return {...state, loading: true}
		case GET_PENDING_TARGET.failed:
			return {...state, loading: false}
		case GET_PENDING_TARGET.succeeded:
			return {...state, lendingTarget: action.response.data, loading: false}
		case UPDATE_PENDING_TARGET.triggered:
			return {...state, loading: true}
		case UPDATE_PENDING_TARGET.failed:
			return {...state, loading: false}
		case UPDATE_PENDING_TARGET.succeeded:
			return {...state, loading: false}
		case FETCH_LOCATE_SEARCH_RESULT.triggered:
			return {...state, loading: true}
		case FETCH_LOCATE_SEARCH_RESULT.succeeded:
			state.locateSearchData = action.response.data && action.response.data.searchAvailabilitySecurityResults
			return {...state, loading: false}
		case FETCH_LOCATE_SEARCH_OPENED:
			state.locateSearchData = []
			state.indexAnalysisData = []
			return {...state}
		case SET_DATA_FROM_INDEX_ANALYSIS:
			state.indexAnalysisData = action.payload ? action.payload : []
			return {...state}

		case RESET_NEEDS_AVAILIABILTY_DATA:
			return {...initialState}
	}
	return state
}
