import {getPMSBaseUrl} from '../../../../services/apiService'
import {createFetchActions} from '../../../../services/createActions'
import {put, post} from '../../../../services/createAPICall'
export const FETCH_COLLATERAL_OPTIMIZATION_DETAILS_DATA = createFetchActions(
	'FETCH_COLLATERAL_OPTIMIZATION_DETAILS_DATA'
)
export const EXECUTE_COLLATERAL_OPTIMIZATION = createFetchActions('EXECUTE_COLLATERAL_OPTIMIZATION')

export function getCollateralOptimizationDetailsData() {
	return function (dispatch) {
		dispatch(
			put(
				FETCH_COLLATERAL_OPTIMIZATION_DETAILS_DATA,
				`${getPMSBaseUrl()}/v1/decisionMaking`,
				{
					params: {
						objective: [],
						strategy: 'Collateral Optimization',
					},
					requestType: 'details',
				},

				null,
				'Internal Server Error occured while getting collateral optimization details data'
			)
		)
	}
}

export const executeCollateralOptimazation = (data: any) => (dispatch: any) => {
	return dispatch(post(EXECUTE_COLLATERAL_OPTIMIZATION, `${getPMSBaseUrl()}/v1/portfolios/margin-activity`, data))
}
