import {isEmpty} from 'lodash'
import {
	RESET_ALL_TRADES,
	GET_ALL_TRADES,
	EXECUTE_TRADE_ACTION,
	SET_TRADE_DETAILS_GROUP_FILTERS,
	SET_TRADE_DETAILS_SELECTED_VIEW,
} from './tradeDetailsActions'

export interface TradeDetails {
	loading: boolean
	tradeData: any
	selectedView: any
	groupFilters: any
}

const initialState: TradeDetails = {
	loading: true,
	tradeData: {},
	selectedView: '',
	groupFilters: {},
}

export function tradeDetailsReducer(state = initialState, action: any) {
	switch (action.type) {
		case SET_TRADE_DETAILS_GROUP_FILTERS:
			return {...state, groupFilters: action.response.data}
		case SET_TRADE_DETAILS_SELECTED_VIEW:
			return {...state, selectedView: action.response.data}
		case RESET_ALL_TRADES:
			return {...state, tradeData: {}, loading: true}

		case GET_ALL_TRADES.triggered:
			return {...state, loading: true}
		case GET_ALL_TRADES.failed:
			return {...state, loading: false}
		case GET_ALL_TRADES.succeeded:
			const keyword = isEmpty(action.response.data) ? '' : Object.keys(action.response.data)[0]
			const statePortfolio = state.tradeData[keyword] ? state.tradeData[keyword] : {}
			const responsePortfolio =
				action.response.data && action.response.data[keyword] ? action.response.data[keyword] : {}
			return {
				...state,
				tradeData: {
					portfolio: {...statePortfolio, ...responsePortfolio},
				},
				loading: false,
			}

		case EXECUTE_TRADE_ACTION.triggered:
			return {...state, loading: true}
		case EXECUTE_TRADE_ACTION.failed:
			return {...state, loading: false}
		case EXECUTE_TRADE_ACTION.succeeded:
			return {...state, loading: false}
	}
	return state
}
