import _ from 'lodash'

export const addTrackingBasketData = (incomingData, stateData) => {
	const tempData = _.cloneDeep(stateData)
	if (!tempData.length) {
		tempData.push(incomingData)
		return tempData
	} else {
		tempData.forEach(item => {
			if (item.index === incomingData.index) {
				item = incomingData
			} else {
				tempData.push(incomingData)
			}
		})
		return tempData
	}
}

export const deleteTrackingBasketData = (stateData, action) => {
	const trackingData = _.cloneDeep(stateData)
	const filteredData = action === 'all' ? [] : trackingData.filter(item => item.index !== action.data)
	return filteredData
}
