import {getPMSGraphBaseUrl} from '../../../../services/apiService'
import {createFetchActions} from '../../../../services/createActions'
import {graphQlWithAxios} from '../../../../services/createAPICall'
import {getSBLReRateDetailQuery, getRepoReRateDetailQuery} from './ReRateQuery'

export const GET_RE_RATE_DETAIL = createFetchActions('GET_RE_RATE_DETAIL')

export const getReRateDetail = dashboardType => {
	const query =
		dashboardType === 'SBL'
			? getSBLReRateDetailQuery()
			: dashboardType === 'REPO'
			? getRepoReRateDetailQuery()
			: getSBLReRateDetailQuery()
	const baseURL = `${getPMSGraphBaseUrl()}`
	return function (dispatch) {
		dispatch({
			type: GET_RE_RATE_DETAIL.triggered,
		})

		graphQlWithAxios(dispatch, GET_RE_RATE_DETAIL, baseURL, query, response => {
			dispatch({
				type: GET_RE_RATE_DETAIL.succeeded,
				response: response.data.data,
			})
		})
	}
}
