import React from 'react'
import {menuLayers, menuValues} from './customiseMenuConstants'
import _ from 'lodash'
import {ArrowCircleLeft, ArrowCircleRight} from '../../styles/svg/svgIcons'
import PerfectScrollbar from 'react-perfect-scrollbar'

export const getUniqueLayers = options => {
	const uniqueLayers = {}
	options &&
		options.forEach(item => {
			const menuEntry = menuValues[item] || {}
			const {menuLayer} = menuEntry
			if (menuEntry && menuLayer) {
				if (uniqueLayers[menuLayer]) {
					uniqueLayers[menuLayer].push(menuEntry)
				} else {
					uniqueLayers[menuLayer] = [menuEntry]
				}
			}
		})
	return uniqueLayers
}

export const getAvailableMenuOptions = (availableMenu, selectedMenu) => {
	return _.cloneDeep(availableMenu).filter(item => selectedMenu && !selectedMenu.includes(item))
}

export const getLayerBasedOptions = (uniqueLayers, arrowDirection, handleArrowClick) => {
	return (
		<>
			<PerfectScrollbar>
				<div className='overflow-auto'>
					{Object.keys(uniqueLayers).map(item => {
						const menuLayerItem = menuLayers[item]
						if (menuLayerItem.isLayer) {
							return (
								<div className={'selected-menu-list'}>
									<div className='border-bottom selected-menu-subheader px-22 py-30 py-2'>
										{menuLayerItem.layerTitle}
									</div>
									{uniqueLayers[item].map(entry => {
										return (
											<div className='selected-menu-item px-22 py-30 py-3 border-bottom d-flex justify-content-start'>
												{arrowDirection === 'right' ? (
													//SELECTED COLUMN ITEM
													<div className='d-flex justify-content-between w-100 selected-menu-item-inner'>
														<div>
															<span className='menu-item-icon selected-menu-item-icon'>{entry.icon}</span>
															<span className='pl-2 menu-label'>{entry.label}</span>
														</div>
														<span
															className='pl-3 arrow-add-remove cursor-pointer'
															onClick={() => handleArrowClick(entry.value)}
														>
															<ArrowCircleRight />
														</span>
													</div>
												) : (
													//AVAILABLE COLUMN ITEMS
													<div className='d-flex justify-content-start w-100 available-menu-item-inner'>
														<span
															className='pr-3 arrow-add-remove cursor-pointer'
															onClick={() => handleArrowClick(entry.value)}
														>
															<ArrowCircleLeft />
														</span>
														<span className='menu-item-icon'>{entry.icon}</span>
														<span className='pl-2 menu-label'>{entry.label}</span>
													</div>
												)}
											</div>
										)
									})}
								</div>
							)
						} else {
							return (
								<div className='selected-menu-list'>
									<div className='selected-menu-subheader border-bottom  px-22 py-30 py-2'>
										{menuLayerItem.options.label}
									</div>
									<div className='selected-menu-item px-22 py-30 py-2 d-flex justify-content-between'>
										{arrowDirection === 'right' ? (
											//SELECTED COLUMN ITEM
											<div className='d-flex justify-content-between w-100 selected-menu-item-inner'>
												<div>
													<span className='menu-item-icon  selected-menu-item-icon'>{menuLayerItem.options.icon}</span>
													<span className='pl-2 menu-label'>{menuLayerItem.options.label}</span>
												</div>
												<span
													className='arrow-add-remove cursor-pointer'
													onClick={() => handleArrowClick(menuLayerItem.options.value)}
												>
													<ArrowCircleRight />
												</span>
											</div>
										) : (
											//AVAILABLE COLUMN ITEMS
											<div className='d-flex justify-content-start w-100  available-menu-item-inner'>
												<span
													className='pr-3 arrow-add-remove cursor-pointer'
													onClick={() => handleArrowClick(menuLayerItem.options.value)}
												>
													<ArrowCircleLeft />
												</span>
												<span className='menu-item-icon'>{menuLayerItem.options.icon}</span>
												<span className='pl-2 menu-label'>{menuLayerItem.options.label}</span>
											</div>
										)}
									</div>
								</div>
							)
						}
					})}
				</div>
			</PerfectScrollbar>
		</>
	)
}

export const getDefaultAvailableMenuOptions = () => {
	return Object.keys(menuValues).map(item => {
		return menuValues[item].value
	})
}
