/**
 * Link: Reusable
 * Features:
 * Creates links (anchor tag)
 * On click, pushes the url in history to load related component
 *
 * Note: Use this istead of plane <a></a> tag
 */

import * as React from 'react'
import {Link} from 'react-router-dom'

interface Props {
	to?: string
	label?: string
	replace?: boolean
	innerRef?: React.Ref<HTMLAnchorElement>
}

export default function FsLink(props: Props) {
	const {to = '#', label = 'Link'} = props
	return (
		<Link to={to} className='nav-link-label overflow-ellipsis max-w-120px ml-1 pr-2'>
			{label}
		</Link>
	)
}
